<template>
    <div class="info_wrap s-overflow-wrap">
        <h2 class="so-exclude">
{{ current_step.name }}
</h2>
        <div class="so-overflow description">
            {{ current_step.description }}
        </div>
        <div class="options">
            <button type="button" @click="openUrl">
{{ $t('quest.open') }}
</button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "UrlStep",
        computed: {
            current_step() {
                return this.$parent.current_step;
            }
        },
        methods: {
            openUrl() {
                this.$parent.openUrl();
            }
        }
    }
</script>