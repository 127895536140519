<template>
    <div>
        <div id="presentationModal" ref="presentationModal" class="ui tiny suimodal" :class="{isMobile}" :style="cssVars">
            <i class="close inside icon"></i>

            <div class="header">
                <div class="header-wrapper">
                    <h2>{{ $t('slides.title') }}</h2>
                </div>
            </div>

            <div v-if="list.length" class="header-secondary">
                <div class="p-left">
                    <select ref="selectPresentation" @change="handleChange">
                        <option value="" disabled :selected="!selectedPresentation">{{ $t('slides.select') }}</option>
                        <option v-for="xr in list" :key="xr.id" :value="xr.id">{{ xr.name }}</option>
                    </select>
                </div>
                <div class="p-right">
                    <button class="ui button" :disabled="!slides.length || loading" @click="restart">
                        <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 2.84375C6.22917 2.84375 6.91667 2.97917 7.5625 3.25C8.20833 3.52083 8.77083 3.90625 9.25 4.40625C9.75 4.88542 10.1354 5.44792 10.4062 6.09375C10.6979 6.73958 10.8438 7.42708 10.8438 8.15625C10.8438 9.07292 10.6354 9.91667 10.2188 10.6875C9.80208 11.4375 9.23958 12.0625 8.53125 12.5625C7.82292 13.0417 7.03125 13.3438 6.15625 13.4688V12.0938C6.80208 11.9896 7.375 11.7604 7.875 11.4062C8.375 11.0312 8.77083 10.5625 9.0625 10C9.35417 9.4375 9.5 8.82292 9.5 8.15625C9.5 7.42708 9.3125 6.76042 8.9375 6.15625C8.58333 5.55208 8.10417 5.07292 7.5 4.71875C6.89583 4.34375 6.22917 4.15625 5.5 4.15625V6.15625L2.84375 3.5L5.5 0.84375V2.84375ZM1.5 8.15625C1.5 8.82292 1.64583 9.4375 1.9375 10C2.22917 10.5625 2.625 11.0312 3.125 11.4062C3.625 11.7604 4.19792 11.9896 4.84375 12.0938V13.4688C3.96875 13.3438 3.17708 13.0417 2.46875 12.5625C1.76042 12.0625 1.19792 11.4375 0.78125 10.6875C0.364583 9.91667 0.15625 9.07292 0.15625 8.15625C0.15625 7.42708 0.291667 6.73958 0.5625 6.09375C0.854167 5.44792 1.23958 4.88542 1.71875 4.40625L2.6875 5.34375C2.3125 5.69792 2.02083 6.125 1.8125 6.625C1.60417 7.10417 1.5 7.61458 1.5 8.15625Z" fill="white"/>
                        </svg>
                        {{ $t('slides.restart') }}
                    </button>
                </div>
            </div>
            
            <div class="content scrolling">
                <div v-if="list.length" class="content-wrapper">
                    <div v-if="loading" class="content-loader"></div>
                    <div v-for="(slide,index) in slides" :key="slide.id" class="slide-item" :class="{ active: index===currentSlide, busy: loading}" @click="goTo(index)">
                        <h3><span>{{ index + 1 }}</span> {{ slide.content.title }}</h3>
                        <!--
                        <div v-if="slide.file_url && slide.type === 'image'" class="ui image">
                            <img :src="slide.file_url" alt="">
                        </div>
                        -->
                        <div class="img-wrapper" :style="{ 'background-image': slide.file_url ? 'url(' + slide.file_url + ')' : 'none' }"></div>
                    </div>
                </div>
                <div v-else class="no-result">
                    {{ $t('slides.no_presentation') }}
                </div>
            </div>

            <div v-if="slides.length" class="actions">
                <div class="actions-wrapper">
                    <button class="ui button" :class="{blue: currentSlide > 0}" :disabled="loading || currentSlide === null || currentSlide === 0" @click="goBack">
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.28125 1.4375L2.21875 4.5L5.28125 7.5625L4.34375 8.5L0.34375 4.5L4.34375 0.5L5.28125 1.4375Z" fill="white"/>
                        </svg>
                        {{ $t('slides.prev') }}
                    </button>
                    <div class="feedback-count">Slide {{ currentSlide + 1 }} {{ $t('slides.of') }} {{ slides.length }}</div>
                    <button class="ui button" :class="{blue: currentSlide < slides.length - 1}" :disabled="loading || currentSlide === null || currentSlide >= slides.length - 1" @click="goNext">
                        {{ $t('slides.next') }}
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.15625 0.5L5.15625 4.5L1.15625 8.5L0.21875 7.5625L3.28125 4.5L0.21875 1.4375L1.15625 0.5Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    
    import axios from 'axios';
    import { mapGetters} from "vuex";
    
    export default {
        name: 'ModeratorSlides',
        computed: {
            ...mapGetters([
                'logged', 'token', 'id', 'nickname', 'sessions', 'groups','design'
            ]),
            isMobile(){
                return this.$store.getters.isMobile && this.$app.windowWidth < 1000;
            },
            cssVars(){
                let fontColor = this.design?.color?.fonts_color || this.$app.getCookie("design_color_fonts_color") || "#000000";
                fontColor = this.$app.hexToRgb(fontColor);
                return {
                    '--fonts_color_50': `rgba(${fontColor.r},${fontColor.g},${fontColor.b},0.5)`,
                }
            }
        },
        props: [],
        data() {
            return {
                loading: false,
                list: [],
                selectedPresentation: null,
                currentSlide: null,
                slides: [],
            }
        },
        watch: {
            loading: {
                handler(newVal) {
                    if( newVal ) {
                        window.$(this.$refs.selectPresentation).prop('disabled', true);
                        // window.$(this.$refs.presentationModal).find('button').prop('disabled', true);
                        window.$(this.$refs.presentationModal).find('i.close.icon').css('display', 'none');
                    } else {
                        window.$(this.$refs.selectPresentation).prop('disabled', false);
                        // if( this.slides.length ) { window.$(this.$refs.presentationModal).find('button').prop('disabled', false); }
                        window.$(this.$refs.presentationModal).find('i.close.icon').css('display', 'block');
                    }
                },
            }
        },
        created() {
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                let vm = this;
                vm.$app.wait(true);
                vm.getPresentations().then(() => {
                    window.$(vm.$refs.presentationModal).suimodal({
                        closable: false,
                        centered: true,
                        observeChanges: true,
                        // useFlex: true,              // nao funciona!
                        dimmerSettings: {
                            variation: 'dimmer-no-color'
                        },
                        onApprove: () => {
                            return false
                        },
                        onHidden: () => {
                            vm.destroyModal();
                        },
                    }).suimodal('show');
                }).catch(() => {

                }).finally(() => {
                    vm.$app.wait(false);
                });
            },
            handleChange(event) {
                let id = parseInt(event.target.value, 10);
                this.selectedPresentation = this.list.find(slide => slide.id === id);
                this.getSlides(id);
            },

            goBack() {
                this.$app.wait(true);
                this.sendBroadcast({room_id:this.selectedPresentation.room_id, subtype:'previous_event'}).then(() => {
                    this.currentSlide--
                }).finally(()=>{
                    this.$app.wait(false);
                })
            },
            goNext() {
                this.$app.wait(true);
                this.sendBroadcast({room_id:this.selectedPresentation.room_id, subtype:'next_event'}).then(() => {
                    this.currentSlide++
                }).finally(()=>{
                    this.$app.wait(false);
                })
            },
            restart() {
                this.$app.wait(true);
                this.sendBroadcast({room_id:this.selectedPresentation.room_id, subtype:'restart_event'}).then(() => {
                    this.currentSlide = 0
                }).finally(()=>{
                    this.$app.wait(false);
                })
            },
            goTo(position) {
                this.$app.wait(true);
                this.sendBroadcast({room_id:this.selectedPresentation.room_id, subtype:'go_to_event', data:position}).then(() => {
                    this.currentSlide = position
                }).finally(()=>{
                    this.$app.wait(false);
                })
            },
            destroyModal() {
                window.$(this.$refs.presentationModal).suimodal('hide').suimodal('destroy');
                window.$('.ui.dimmer').find('#presentationModal').remove();
                this.$app.moderatorComponent    = null;
                this.selectedPresentation       = null;
                this.currentSlide               = null;
            },
            
            // api related
            getPresentations(){
                return new Promise((resolve, reject) => {
                    axios.get(this.$url_api + 'api/slides/xr', {
                        headers: {
                            'Content-Type'  : 'application/json;charset=UTF-8',
                            'Access'        : 'application/json',
                            language        : this.$i18n.locale, Authorization: `Bearer ${this.token}`
                        }
                    }).then((response) => {
                        this.list = response.data.data.slidesXR;
                        resolve(response.data.data)
                    }).catch((error) => {
                        console.log(error)
                        reject(error)
                    });
                })
            },
            getSlides(id){
                this.currentSlide   = null;
                this.loading        = true;
                return new Promise((resolve, reject) => {
                    axios.get(this.$url_api + 'api/slides/xr/' + id, {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Access': 'application/json',
                            language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                        }
                    }).then((response) => {
                        this.slides = response.data.data.slides;
                        resolve(response.data.data)
                    }).catch((error) => {
                        console.log(error)
                        reject(error)
                    }).finally(() => {
                        this.loading = false;
                    });
                })
            },
            sendBroadcast(data) {
                return new Promise((resolve, reject) => {
                    axios.post(this.$url_api + 'api/mod/broadcast', data,{
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Access': 'application/json',
                            language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                        }
                    }).then((response) => {
                        resolve(response.data)
                    }).catch((error) => {
                        reject(error)
                    })
                })
            },
        },
    }
</script>

<style>
.dimmer-no-color{
    background-color: rgba(0,0,0,0) !important;
}
</style>
<style scoped lang="scss">
.ui.suimodal {
    /*
    background-color: var(--headers_color_logged);
    color: var(--fonts_color);
    .header,.content,.actions{
        background-color: transparent;
        color: var(--fonts_color);
    }
    .close.icon{
        float: right;
    }
    .actions h3{
        margin: 0;
        font-size: 1.25rem;
        text-align: center;
    }
    &.isMobile {
        position: fixed;
        top: 0 !important;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0 !important;
        padding: 0;
        z-index: 1000;

        .content {
            height: calc(80vh - 4rem);
            max-height: calc(80vh - 4rem);
            overflow-y: auto;
        }
    }
    .slide {
        margin-bottom: 1rem;
        cursor: pointer;
        color: var(--fonts_color_50);
        &.active {
            background-color: rgba(240, 240, 240, 0.2);
            color: var(--fonts_color);
            font-weight: bold;
        }
        .ui.image {
            height: 200px;
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        .button {
            margin: 0;
        }
    }
    .ui.button {
        margin: 0;
        border-radius: 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        svg {
            height: 1rem;
            width: 1rem;
            path {
                fill: var(--fonts_color);
            }
        }
        &.primary {
            background-color: var(--buttons_color);
            color: var(--fonts_color);
        }
        &.basic {
            &.primary {
                background-color: transparent;
                box-shadow: 0 0 0 1px var(--buttons_color) inset;
                color: var(--fonts_color);
            }
        }
    }
    */


    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    color: #fff;
    .header,.content,.actions{
        background-color: transparent;
        color: var(--fonts_color);
    }
    .close{
        color: #fff;
    }
    .header{
        color: inherit;
        // flex-shrink: 0;
        .header-wrapper{
            text-align: center;
            h2{
                font-family: Exo;
                font-size: 28px;
                font-weight: 700;
                line-height: 30px;
                margin: 0;
            }
        }
    }
    .header-secondary{
        padding: 1.25rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 10px 0;
        .p-left{
            width: 300px;
        }
        .p-right{
            position: relative;
        }
    }
    .scrolling.content {
        max-height: calc(80vh - 13rem);
        color: inherit;
        padding: 0 1.5rem;
        scrollbar-color: #05B1EB rgba(255, 255, 255, 0.2);  // firefox
        scrollbar-width: thin;                                      // firefox
        &::-webkit-scrollbar {                                      // WebKit browsers (Chrome, Safari, Edge)
            width: 12px;
            // height: 12px;
        }
        &::-webkit-scrollbar-track{
            background: rgba(255, 255, 255, 0.2);
        }
        &::-webkit-scrollbar-thumb{
            background: #05B1EB;
            border-radius: 6px;
            // border: 3px solid transparent;      // Adds space inside the scrollbar
            // background-clip: padding-box;       // Prevents background from overlapping the border
        }
        &::-webkit-scrollbar-thumb:hover{
            background: #05B1EB;
        }
        .no-result{
            text-align: center;
            font-family: Exo;
            font-size: 16px;
            line-height: 20px;
            padding-bottom: 20px;
            padding-top: 10px;
        }
    }
    .content-wrapper{
        position: relative;
        .content-loader{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0,0,0,0);
            z-index: 2;
        }
        .slide-item{
            position: relative;
            cursor: pointer;
            padding: 20px;
            h3{
                font-family: Exo;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                margin: 0;
                span{
                    margin-right: 10px;
                }
            }
            .image{
                margin: 10px auto 0 auto;
                border-radius: 6px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                max-width: 80%;
            }
            .img-wrapper{
                width: 300px;
                height: 130px;
                max-width: 90%;
                margin: 10px auto 0 auto;
                border-radius: 6px;
                // border: 1px solid #D6D6D6;
                // background-color: #D6D6D6;
                background-color: #eee;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
            }
            &.active{
                background: rgba(255, 255, 255, 0.15);
                h3{
                    font-weight: 600;
                }
            }
            &.busy{
                opacity: .4;
            }
        }
    }
    .actions{
        color: inherit;
        padding: 1.25rem 1.5rem;
        .actions-wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .feedback-count{
                position: relative;
            }
        }
    }
    
    button{
        display: flex !important;
        gap: 10px !important;
        align-items: center !important;
        font-family: Exo;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        color: #fff;
        padding: 12px 18px 12px 16px;
        border-radius: 24px !important;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: none;
        outline: none;
        transition: all 0.3s ease;
        margin: 0;
        svg path {
            fill: #fff !important;
        }
    }
    select{
        padding: 12px 18px 12px 18px;
        color: #343434;
        font-family: Exo;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid transparent;
        background: #FFF;
        transition: all 0.3s ease;
        option {
            font-family: Exo, sans-serif;
            font-size: 16px;
            color: #343434;
        }
    }

}

// media queries (mobile last)
@media only screen and (max-width: 1470px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {
    .ui.suimodal {
        .header{
            .header-wrapper{
                h2{
                    font-size: 22px;
                    line-height: 24px;
                }
            }
        }
        .header-secondary{
            .p-left{
                width: 160px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {

}
</style>