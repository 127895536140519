<template>
    <div v-if="userHasPermission">
        <div class="container container-lesson">
            <div class="left-container non-mobile" id="left-menu">
                <div class="top-section">
                    <span class="hide-menu" @click="hideLeftMenu"><i class="bi bi-list-task"></i><span class="top-text">Esconder menu </span></span>
                </div>
                <div v-if="!isCourseScorm" class="accordion" id="sessionsList">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="sessionsListHeader">
                            <button class="accordion-button" data-bs-toggle="collapse" data-bs-target=".session" aria-controls="collapseOne">
                                {{section.title}}
                            </button>
                        </h2>
                        <div class="sectionList">
                            <div class="accordion-collapse collapse show session" @click="goToLesson(lesson)" v-for="(lesson, index) in section.lessons"
                                 :key="lesson.id + '_' + Math.random().toString().substr(2, 5) + '_' + index"
                                 :class="{'_selected': lesson.id === selectedLesson.id, '_finished': lesson.is_finished}"
                                 aria-labelledby="sessionsListHeader" data-bs-parent="#sessionsList">
                                <div class="accordion-body lesson-desc">
                                    <p>{{lesson.title}}</p>
                                    <svg v-show="lesson.is_finished" xmlns="http://www.w3.org/2000/svg" height="1.0em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="var(--buttons_color)"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion" id="notesList">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="notesListHeader">
                            <button class="accordion-button" data-bs-toggle="collapse" data-bs-target=".note-content" aria-controls="collapseOne">
                                Minhas anotações
                            </button>
                        </h2>
                        <div class="accordion-collapse collapse show action-button note-content" aria-labelledby="notesListHeader" data-bs-parent="#notesList">
                            <div class="accordion-body">
                                <span @click="createNewNote"><i class="fas fa-plus"></i> Criar post-it</span>
                            </div>
                        </div>
                        <draggable v-model="notesOrder" @end="onDragEnd" class="notesList">
                            <div class="accordion-collapse collapse show note note-content"
                                 :data-id="note.id" v-for="(note, index) in notesOrder"
                                 :key="index" aria-labelledby="notesListHeader"
                                 data-bs-parent="#notesList">
                                <div class="accordion-body">
                                    <div class="anotation" :style="'background-color:'+ note.color">
                                        <div class="action-anotation-buttons">
                                            
                                            <span @click="editNote(note, 'edit')" v-if="!note.isEditing"><i class="fas fa-edit"></i> Editar nota</span>
                                            <span @click="editNote(note, 'save')" v-if="note.isEditing"><i class="fas fa-check"></i> Salvar</span>
                                            <span @click="editNote(note, 'cancel')" v-if="note.isEditing">Cancelar</span>
                                            <span @click="deleteNote(note)"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></span>
                                        </div>
                                        <div class="video-times" v-if="note.track_videos != null && note.track_videos.length > 0">
                                            <div class="video-time" v-for="(track, index) in note.track_videos" :key="index">
                                                <span class="video-flex">Video {{index + 1}}
                                                  <span style="color: var(--buttons_color)">
                                                    <i class="fas fa-play-circle"></i> {{formatTrack(track.current_time)}}
                                                  </span>
                                                </span>
                                            </div>
                                        </div>
                                        <textarea v-model="noteText" v-if="note.isEditing" cols="30" rows="10"></textarea>
                                        <div v-else>{{note.note}}</div>
                                    </div>
                                </div>
                            </div>
                        </draggable>
                        <div id="ordenation">
                            <input :id="'order_' + note.id" :data-id="note.id" v-for="(note, index) in notesOrder" :key="index"
                                name="order[]" type="hidden" :value="index" />
                        </div>
                    </div>
                </div>
                <div class="accordion" id="iaMentor" v-if="course.ai && course.ai_active">
                    <div v-if="!isMobileDevice()" class="accordion-item">
                        <h2 class="accordion-header" id="notesListHeader">
                            <button class="accordion-button" data-bs-toggle="collapse" data-bs-target=".iaMentor-content" aria-controls="collapseOne">
                                Mentor IA
                            </button>
                        </h2>
                        <div class="accordion-collapse collapse show action-button iaMentor-content" aria-labelledby="notesListHeader" data-bs-parent="#iaMentor">
                            <div class="accordion-body">
                                <iframe :src="`${course.ai.embed_url}?lms=true&minimize_chat=true&user_id=${user_id}`" allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;"
                                        allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="no" frameborder="0" height="400" width="100%"></iframe>
                            </div>
                            <div>
                              <a :href="`${course.ai.embed_url}?lms=true&user_id=${user_id}`" class="ai-link-fullscreen" target="_blank">
                                  <p class="talktome">Conversar em tela cheia</p>
                              </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-container" id="right-container">
                <div class="lesson-header">
                    <span class="show-menu"
                          @click="showLeftMenuAction">
                        <i class="bi bi-list-task"></i>
                        <span class="top-text">Abrir o menu</span>
                    </span>
                    <div class="right-container-breadcrumb">
                        <span @click="$router.push({path: `/lms/${$route.params.lmsSlug}`})">
                            {{ this.lms.title }}
                        </span>
                        <i class="fas fa-chevron-right"></i>
                        <span @click="$router.push({path: `/lms/${lms.slug}/${course.slug}`})">
                            {{ this.course.title }}
                        </span>
                        <i class="fas fa-chevron-right"></i>
                        <span class="selected">
                            {{ this.section.title }}
                        </span>
                        
                    </div>
                    <div class="content-actions">
                        <button class="content-button" @click="previousLesson()" v-show="this.selectedLesson.id != this.section.lessons[0].id">
                            <i class="fas fa-chevron-left"></i> Aula anterior
                        </button>
                        <button v-if="!isCourseScorm" class="content-button finish" @click="finishLesson()" v-show="!this.selectedLesson.is_finished">
                            <i class="far fa-check-circle"></i> Concluir aula
                        </button>
                        <button class="content-button" @click="nextLesson()" v-show="this.selectedLesson.id != this.section.lessons.slice(-1)[0].id && (this.course.view_order && this.selectLesson.is_finished || (!this.course.view_order)) ">
                            <i class="fas fa-chevron-right"></i> Próxima aula
                        </button>
                    </div>
                </div>
                <div class="lesson-content">
                  <div v-if="isCourseScorm" class="scorm-container">
                    <div class="iframe-container content">
                      <iframe ref="scormIframe" :src="iframeScormUrl" frameborder="0" @load="listenPostMessage()"></iframe>
                    </div>
                  </div>
                  <div v-else>
                    <h3>{{selectedLesson.title}}</h3>
                    <!-- Usando Math.random() na key faz com que o conteúdo seja recarregado quando o usuário edita o post-it -->
                    <!-- <div v-for="(content, index) in selectedLesson.contents" :key="content.id + '_' + Math.random().toString().substr(2, 5) + '_' + index">-->
                    <div v-for="(content) in selectedLesson.contents" :key="content.id">
                        <div class="video-container content" v-if="content.action_type == 'video'">
                            <VideoPlayer :video_id="content.id" :video_url="content.action_value"></VideoPlayer>
                        </div>
                        <div class="image-container content" v-if="content.action_type === 'image'">
                            <img :src="content.action_value" :alt="selectLesson.title">
                        </div>
                        <div class="text-container content" v-if="content.action_type == 'text'">
                            <div v-html="makeHTML(content.action_value)"></div>
                        </div>
                        <div class="audio-container content" v-if="content.action_type == 'audio'">
                            <AudioPlayer :audio_id="content.id" :audio_url="content.action_value"></AudioPlayer>
                        </div>
                        <div class="title-container content" v-if="content.action_type == 'title'">
                            <h2>{{content.action_value}}</h2>
                        </div>
                        <div class="pdf-container content" v-if="content.action_type == 'pdf'">
                            <PdfViewer :pdf_id="content.id" :pdf_url="content.action_value"></PdfViewer>
                        </div>
                        <div class="iframe-container content" v-if="content.action_type == 'iframe' || content.action_type == 'video_public'">
                            <iframe :src="content.action_value" frameborder="0"></iframe>
                        </div>
                        <div class="iframe-container content" v-if="content.action_value_front && (content.action_type == 'quiz' || content.action_type == 'form')">
                            <!-- <div class="replay-btn-container" :class="content.action_options.multiple_times && content.answered ? '' : '_wait'" :ref="'replay-btn-'+content.id" :id="'replay-btn-'+content.id">
                                <button class="replay-btn" @click="replayQuizForm(content.id)">Deseja refazer {{content.action_type == 'quiz' ? 'o quiz?' : 'a avaliação?'}}</button>
                            </div>
                            <iframe :class="content.action_options.multiple_times && content.answered ? '_wait' : ''" :src="formatQuizUrl(content.action_value, (content.action_options.multiple_times && content.answered) ? true : false)" :ref="'form-quiz-'+content.id" frameborder="0"></iframe> -->
                            <iframe :src="content.action_value_front" :ref="'form-quiz-'+content.id" frameborder="0"></iframe>
                        </div>
                        <div class="iframe-container content" v-if="content.action_value_front && content.action_type == 'template'">
                            <iframe :src="content.action_value_front" :ref="'template-room-'+content.id" frameborder="0" allowvr="yes" allow="display-capture *;camera *; microphone *;vr; xr; xr-spatial-tracking; accelerometer; magnetometer; gyroscope; autoplay;" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          <div class="bottom-container mobile" >
            <div class="row">
              <div class="content-actions" style="width: 100%; margin-bottom: 20px; justify-content: center;">
                <button class="content-button" @click="previousLesson()" v-show="this.selectedLesson.id != this.section.lessons[0].id">
                  <i class="fas fa-chevron-left"></i> Aula anterior
                </button>
                <button class="content-button finish" @click="finishLesson()" v-show="!this.selectedLesson.is_finished">
                  <i class="far fa-check-circle"></i> Concluir aula
                </button>
                <button class="content-button" @click="nextLesson()" v-show="this.selectedLesson.id != this.section.lessons.slice(-1)[0].id">
                  <i class="fas fa-chevron-right"></i> Próxima aula
                </button>
              </div>
            </div>
            <div class="row">
              <div class="accordion" id="sessionsList">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="sessionsListHeader">
                    <button class="accordion-button" data-bs-toggle="collapse" data-bs-target=".session" aria-controls="collapseOne">
                      {{section.title}}
                    </button>
                  </h2>
                  <div class="sectionList">
                    <div class="accordion-collapse collapse show session" @click="goToLesson(lesson, index)" v-for="(lesson, index) in section.lessons" :key="index" :class="lesson.id == selectedLesson.id || lesson.is_finished ? '_selected' : ''" aria-labelledby="sessionsListHeader" data-bs-parent="#sessionsList">
                      <div class="accordion-body lesson-desc">
                        <p>{{lesson.title}}</p>
                        <svg v-show="lesson.is_finished" xmlns="http://www.w3.org/2000/svg" height="1.0em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="var(--buttons_color)"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="accordion" id="iaMentor" v-if="course.ai && course.ai_active">
                  <div v-if="isMobileDevice()" class="accordion-item">
                      <h2 class="accordion-header" id="notesListHeader">
                          <button class="accordion-button" data-bs-toggle="collapse" data-bs-target=".iaMentor-content" aria-controls="collapseOne">
                              Mentor IA
                          </button>
                      </h2>
                      <div class="accordion-collapse collapse show action-button iaMentor-content" aria-labelledby="notesListHeader" data-bs-parent="#iaMentor">
                          <div class="accordion-body">
                              <iframe :src="`${course.ai.embed_url}?lms=true&user_id=${user_id}`" allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;"
                                      allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="no" frameborder="0" height="400" width="100%"></iframe>
                          </div>
                          <a :href="`${course.ai.embed_url}?lms=true&user_id=${user_id}`" class="ai-link-fullscreen" target="_blank">
                              <p class="talktome">Conversar em tela cheia</p>
                          </a>
                      </div>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="accordion" id="notesList">
              <div class="accordion-item">
                <h2 class="accordion-header" id="notesListHeader">
                  <button class="accordion-button" data-bs-toggle="collapse" data-bs-target=".note-content" aria-controls="collapseOne">
                    Minhas anotações
                  </button>
                </h2>
                <div class="accordion-collapse collapse show action-button note-content" aria-labelledby="notesListHeader" data-bs-parent="#notesList">
                  <div class="accordion-body">
                    <span @click="createNewNote"><i class="fas fa-plus"></i> Criar post-it</span>
                  </div>
                </div>
                <draggable v-model="notesOrder" @end="onDragEnd" class="notesList">
                  <div class="accordion-collapse collapse show note note-content"
                       :data-id="note.id" v-for="(note, index) in notesOrder"
                       :key="index" aria-labelledby="notesListHeader"
                       data-bs-parent="#notesList">
                    <div class="accordion-body">
                      <div class="anotation" :style="'background-color:'+ note.color">
                        <div class="action-anotation-buttons">

                          <span @click="editNote(note, 'edit')" v-if="!note.isEditing"><i class="fas fa-edit"></i> Editar nota</span>
                          <span @click="editNote(note, 'save')" v-if="note.isEditing"><i class="fas fa-check"></i> Salvar</span>
                          <span @click="editNote(note, 'cancel')" v-if="note.isEditing">Cancelar</span>
                          <span @click="deleteNote(note)"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></span>
                        </div>
                        <div class="video-times" v-if="note.track_videos != null && note.track_videos.length > 0">
                          <div class="video-time" v-for="(track, index) in note.track_videos" :key="index">
                                                <span class="video-flex">Video {{index + 1}}
                                                  <span style="color: var(--buttons_color)">
                                                    <i class="fas fa-play-circle"></i> {{formatTrack(track.current_time)}}
                                                  </span>
                                                </span>
                          </div>
                        </div>
                        <textarea v-model="noteText" v-if="note.isEditing" cols="30" rows="10"></textarea>
                        <div v-else>{{note.note}}</div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div id="ordenation">
                  <input :id="'order_' + note.id" :data-id="note.id" v-for="(note, index) in notesOrder" :key="index"
                         name="order[]" type="hidden" :value="index" />
                </div>
              </div>
            </div>
            </div>
          </div>

    </div>
  </div>

        
</template>
<script>
    import { mapGetters } from "vuex";
    import Draggable from 'vuedraggable'
    import VideoPlayer from './components/VideoPlayer'
    import AudioPlayer from './components/AudioPlayer'
    import PdfViewer from './components/PdfViewer'
    // import VueScormPlayer from 'vue-scorm-player';
    import $ from 'jquery';
    import { gsap } from "gsap";
    const emitter = require('tiny-emitter/instance');
    // import Iframe from "../Iframe.vue";
    import { makeHTML} from "../../helper";

    export default {
        name: 'LmsLessonContent',
        data() {
            return {
                colorNotes: [
                    '#ffd10f', '#dee365', '#87e0fe', '#ffa3e3'
                ],
                notesOrder: [],
                noteText: '',
                noteToDelete: {},
                showLeftMenu: true,
                userHasPermission: false,
                scormUserInfo: [],
                scormUrl: '',
                iframeScormUrl: '',
                selectedLesson2: null
            }
        },
        computed: {
            ...mapGetters({
                lms: ['LMS/getLms'],
                section: ['LMS/getSection'],
                course: ['LMS/getCourse'],
                notes: ['LMS/getUserNotes'],
                selectedLesson: ['LMS/getLesson'],
                user_id: ['id']
            }),
            isCourseScorm() {
              return this.course.scorm_url;
            }
        },
        components: {
            // Iframe,
            Draggable,
            VideoPlayer,
            AudioPlayer,
            PdfViewer,
            // VueScormPlayer
        },
        beforeMount(){
            let vm = this
            let route = vm.$route.params;
            if(route?.lmsSlug){
                vm.$store.dispatch('LMS/getListLMS', { slug: route.lmsSlug });
            }
            this.selectedLesson2 = JSON.parse(JSON.stringify(this.selectedLesson));
        },
        mounted() {

            //title, text, audio, image, video, video_public, pdf, iframe, quiz, form
            let vm = this
            vm.$parent.wait(true)
            vm.$store.dispatch('LMS/getCourse', { id: vm.$route.query.course_id }).then(course => {

              // Se o curso for do tipo Scorm, precisa recuperar as informações que o aluno já possa ter
              if(vm.isCourseScorm) {
                let payload = {
                  'user_id': vm.user_id,
                  'course_id': vm.course.id
                };

                vm.$store.dispatch('LmsCourse/getScormUserInfo', payload)
                    .then(data => {

                      vm.scormUserInfo = data;

                    });

                vm.$store.dispatch('LMS/getApiUrl')
                    .then(url => {
                      vm.iframeScormUrl = url + 'lms/scorm/view/' + course.id;
                    })
              }

              vm.$store.dispatch('LMS/getSection', { id:vm.$route.query.section_id }).then(() => {
                if(vm.course.view_order && vm.course.next_lesson != null) {
                  if(vm.$route.params.sectionSlug != vm.course.next_lesson.slug && vm.section.lessons.find(l => l.id == vm.$route.query.lesson_id).is_finished == false) {
                    vm.$parent.wait(false)
                    vm.$swal({
                      title: 'Atenção!',
                      text: `Você precisa concluir as aulas na ordem correta para prosseguir.`,
                      icon: 'warning'
                    }).then(() => {
                      //vm.$router.push({path: `/lms/${vm.$route.params.lmsSlug}/${vm.$route.params.courseSlug}/${vm.course.next_lesson.slug}/${vm.course.next_lesson.lesson_slug}`})
                      vm.$router.push({
                        name: "LmsLessonContent",
                        params: {
                          lmsSlug: vm.$route.params.lmsSlug,
                          courseSlug: vm.$route.params.courseSlug,
                          sectionSlug: vm.$route.params.sectionSlug,
                          lessonSlug: vm.course.next_lesson.lesson_slug
                        },
                        query: {
                          lesson_id: vm.course.next_lesson.lesson_id,
                          section_id: vm.course.next_lesson.section_id,
                          course_id: vm.$route.query.course_id
                        }
                      });
                    })
                  } else if (vm.$route.params.lessonSlug != vm.course.next_lesson.lesson_slug && vm.section.lessons.find(l => l.id == vm.$route.query.lesson_id).is_finished == false) {
                    vm.$parent.wait(false)
                    vm.$swal({
                      title: 'Atenção!',
                      text: `Você precisa concluir a aula "${vm.section.lessons.find(l => l.slug == vm.course.next_lesson.lesson_slug).title}"  para prosseguir.`,
                      icon: 'warning'
                    }).then(() => {
                      // vm.$router.push({path: `/lms/${vm.$route.params.lmsSlug}/${vm.$route.params.courseSlug}/${vm.$route.params.sectionSlug}/${vm.course.next_lesson.lesson_slug}`})
                      vm.$router.push({
                        name: "LmsLessonContent",
                        params: {
                          lmsSlug: vm.$route.params.lmsSlug,
                          courseSlug: vm.$route.params.courseSlug,
                          sectionSlug: vm.$route.params.sectionSlug,
                          lessonSlug: vm.course.next_lesson.lesson_slug
                        },
                        query: {
                          lesson_id: vm.course.next_lesson.next_lesson.lesson_id,
                          section_id: vm.$route.query.section_id,
                          course_id: vm.$route.query.course_id
                        }
                      });
                    })
                  } else {
                    vm.selectLesson(vm.section.lessons.find(l => l.id == vm.$route.query.lesson_id))
                  }
                } else {
                  vm.selectLesson(vm.section.lessons.find(l => l.id == vm.$route.query.lesson_id))
                }
              }).then(() => {
                let videos = document.getElementsByClassName("content-video");
                emitter.on('play-video', function (video_id) {
                  for (var i = 0; i < videos.length; i++) {
                    if (videos[i].dataset.id != video_id) {
                      videos[i].pause()
                    }
                  }
                })
              })
            });

            $(window).scroll(function(){
                let $el = $('.left-container');
                let isPositionFixed = ($el.css('position') == 'fixed');
                if ($(this).scrollTop() > 200 && !isPositionFixed){
                    $el.css({'position': 'sticky', 'top': '84px'});
                }
                if ($(this).scrollTop() < 200 && isPositionFixed){
                    $el.css({'position': 'relative'});
                }
            });

        },
        methods: {
            listenPostMessage() {

              window.addEventListener('message', this.handleMessageScorm);

              // Envia as informações do usuário para o iframe
              let payload = {
                'origin': 'LmsLessonContent',
                'type': 'user_info',
                'data': this.scormUserInfo
              }

              this.sendMessageToScormIframe(payload);

            },
            isMobileDevice() {
              return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            showLeftMenuAction() {
                let vm = this;
                const menu = document.getElementById('left-menu');
                const contentArea = document.getElementById('right-container');

                // Ativa o menu
                vm.showLeftMenu = true;

                // Defina a largura do menu imediatamente, antes de iniciar a animação
                gsap.set(menu, { autoAlpha: 0, x: '-500', width: '400px' }); // Esconde o menu para a esquerda da tela com opacidade 0

                let timeline = gsap.timeline({
                    defaults: { duration: 1, ease: "power2.inOut" },
                    onComplete: () => {
                        gsap.to('.show-menu', { display: 'none' }, "-=0.5");
                    }
                });

                timeline
                    .to('.show-menu', { autoAlpha: 0, duration: 1 })
                    .to(menu, { autoAlpha: 1, x: "0", duration: 2, width: '23%' }, "-=2") // Anima o menu entrando com a largura já definida
                    .to(contentArea, { width: '74%', duration: '0.5' }, "-=1.45")
            },
            hideLeftMenu() {
                let vm = this;
                const menu = document.getElementById('left-menu');
                const contentArea = document.getElementById('right-container');


                let timeline = gsap.timeline({
                    defaults: { duration: 1, ease: "power2.inOut" },
                    onComplete: () => {
                        gsap.to('.show-menu', { display: 'flex' }, "-=0.5");
                        vm.showLeftMenu = false;
                    }
                });


                timeline
                    .to('.show-menu', { autoAlpha: 1, duration: 1, immediateRender: true })
                    .to(menu, { autoAlpha: 0, x: "-500", immediateRender: true })
                    .set(menu, { width: '0' }, "-=0.5") // Overlap de 1 segundo antes de terminar a animação do menu.
                    .to(contentArea, { width: '100%' }, "-=0.65"); // Começa a aumentar a width da contentArea 1.5s antes do fim da animação do menu.
            },
            makeHTML,
            createNewNote() {
                let vm = this
                let track_videos = vm.getVideosCurrentTime()
                vm.$parent.wait(true)
                let payload = {
                    "lesson_id": vm.selectedLesson.id,
                    "note": 'Texto da anotação',
                    "color": vm.colorNotes[Math.floor(Math.random() * 4)],
                    "pinned": false,
                    "order": 0,
                    "track_videos": track_videos
                }
                vm.$store.dispatch('LMS/createUserNote', payload).then(() => {
                    vm.updateNotes()
                    vm.$parent.wait(false)
                }).catch((error) => {
                    console.log(error.response)
                    vm.$parent.wait(false)
                })
            },
            replayQuizForm(id) {
                this.$refs['replay-btn-'+id][0].classList.add('_wait')
                this.$refs['form-quiz-'+id][0].classList.remove('_wait')
                console.log('replay-btn', this.$refs['replay-btn-'+id][0])
            },
            selectLesson(lesson) {
                let vm = this
                vm.$parent.wait(true)
                vm.$store.dispatch('LMS/getLesson', {id: lesson.id}).then(() => {
                    this.getUserNotes()
                    vm.userHasPermission = true;
                    console.log('selectedLesson =>', this.selectedLesson)
                }).then(() => {
                    this.updateNotes()
                }).catch((error) => {
                    console.log(error.user)
                    vm.userHasPermission = false;
                    if (error.user == "User doesn't have permission.") {
                        vm.$parent.wait(false);
                        // if(vm.lms.courses_packages) {
                          // vm.$router.push({ path: `/lms/${this.$route.params.lmsSlug}/checkout/add-to-cart?object=coursePackage&object_id=${vm.lms.courses_packages[0]}`});
                        // } else {
                          if(vm.course.is_free) {
                            let payload = [];
                            payload = vm.course;
                            payload["object"] = "course";
                            payload["quantity"] = 1;
                            emitter.emit('root::loader', true)
                            vm.$store.dispatch("LmsCart/removeAllItemsFromCart").then(() => {
                              vm.$store.dispatch("LmsCart/toggleItemToCart", payload).then(() => {

                                let payload = {
                                  "gateway": "coins"
                                }
                                payload.voucher = vm.voucherInfo?.code ?? "";
                                vm.$store.dispatch("LmsCart/cartCheckout", payload).then(() => {
                                  vm.navigateTo({
                                    name: "LmsLessonContent",
                                    params: {
                                      lmsSlug: vm.course.lms.slug,
                                      courseSlug: vm.course.slug,
                                      sectionSlug: vm.course.next_lesson.slug,
                                      lessonSlug: vm.course.next_lesson.lesson_slug
                                    },
                                    query: {
                                      lesson_id: vm.course.next_lesson.lesson_id,
                                      section_id: vm.course.next_lesson.section_id,
                                      course_id: vm.course.next_lesson.course_id
                                    }
                                  })
                                })
                              })
                            })
                          } else {
                            vm.$router.push({ path: `/lms/${this.$route.params.lmsSlug}/checkout/add-to-cart?object=course&object_id=${this.course.id}`});
                          }
                        // }
                    }
                    vm.$parent.wait(false)
                })

            },

          navigateTo(routeObj) {
            const currentRoute = this.$router.currentRoute;
            emitter.emit('root::loader', true);
            // Verifica se o nome da rota e os parâmetros/consulta são diferentes
            const isDifferentRoute = currentRoute.name !== routeObj.name ||
              JSON.stringify(currentRoute.params) !== JSON.stringify(routeObj.params) ||
              JSON.stringify(currentRoute.query) !== JSON.stringify(routeObj.query);

            if (isDifferentRoute) {
              // Se for diferente, navegue para o novo destino
              this.$router.push(routeObj).catch(() => {
                // Tratar erros de navegação aqui, se necessário
              });
            } else {
              // Se for a mesma rota, recarregue a página
              window.location.reload();
            }
          },

          goToLesson(lesson) {
              // Pode ser feito dessa forma para não usar let vm = this
              const {
                selectedLesson,
                course,
                $router,
                $route,
                $swal
              } = this;


              // If they are equal, do nothing
              if (selectedLesson.id === lesson.id) {
                return false;
              }

              // Default, use it if view_order is false
              const goToLessonConfig = {
                name: "LmsLessonContent",
                params: {
                  lmsSlug: $route.params.lmsSlug,
                  courseSlug: $route.params.courseSlug,
                  sectionSlug: $route.params.sectionSlug,
                  lessonSlug: lesson.slug
                },
                query: {
                  lesson_id: lesson.id,
                  section_id: lesson.section_id,
                  course_id: course.id
                }
              };


              if (course.view_order) {
                if (lesson.id === course.next_lesson.lesson_id && selectedLesson.is_finished || // If they are equal and student finished, can proceed
                    lesson.order < selectedLesson.order || lesson.is_finished) { // If student want to see previous lesson or the lesson is already finished, can proceed
                  $router.push(goToLessonConfig);
                } else {
                  if (selectedLesson.id === course.next_lesson.lesson_id) {
                    $swal({
                      title: 'Atenção!',
                      text: 'Você precisa concluir a aula atual para prosseguir.',
                    });
                  } else {
                    $swal({
                      title: 'Atenção!',
                      text: 'Você precisa concluir as aulas na sequência correta para prosseguir.',
                      icon: 'warning',
                      okButtonText: 'Voltar para a sequência',
                    }).then(() => {
                      $router.push({
                        name: "LmsLessonContent",
                        params: {
                          lmsSlug: $route.params.lmsSlug,
                          courseSlug: $route.params.courseSlug,
                          sectionSlug: course.next_lesson.slug,
                          lessonSlug: course.next_lesson.lesson_slug
                        },
                        query: {
                          lesson_id: course.next_lesson.lesson_id,
                          section_id: course.next_lesson.section_id,
                          course_id: $route.query.course_id
                        }
                      });
                    });
                  }
                }
              } else {
                $router.push(goToLessonConfig);
              }

              return false;
          },
          getUserNotes() {
                let vm = this
                vm.$parent.wait(true);
                console.log("getUserNotes: ", vm.selectedLesson);
                vm.$store.dispatch('LMS/getUserNotes', {lesson_id: vm.selectedLesson.id}).then(() => {
                    this.updateNotes()
                    vm.$parent.wait(false)
                })
            },
            editNote(note, action) {
                let vm = this
                if (action == 'edit') {
                    vm.noteText = note.note
                    note.isEditing = true
                } else if (action == 'cancel') {
                    note.isEditing = false
                } else if (action == 'save') {
                    vm.$parent.wait(true)
                    vm.$store.dispatch('LMS/updateNote', {note_id: note.id, ...note, note: vm.noteText}).then(() => {
                        vm.updateNotes()
                        vm.$parent.wait(false)
                    })
                }
            },
            deleteNote(note) {
                this.noteToDelete = note
                this.$parent.modal("confirmation", this.$i18n.t('app.atencao'), 'Deseje excluir esta nota?', this.deleteNoteConfirmation);
            },
            deleteNoteConfirmation(r) {
                if (r === 1) {
                    let vm = this
                    vm.$parent.wait(true)
                    vm.$store.dispatch('LMS/deleteNote', {note_id: this.noteToDelete.id}).then(() => {
                        
                        vm.getUserNotes()
                    })
                }
            },
            previousLesson() {
                let lessonIndex = this.section.lessons.filter(l => l.id == this.selectedLesson.id)
                let previousLessonIndex = this.section.lessons.indexOf(lessonIndex[0])
                let previousLesson = this.section.lessons[previousLessonIndex - 1]
                this.$router.push({
                    name: "LmsLessonContent",
                    params: {
                        lmsSlug: this.$route.params.lmsSlug,
                        courseSlug: this.$route.params.courseSlug,
                        sectionSlug: this.$route.params.sectionSlug,
                        lessonSlug: previousLesson.slug
                    },
                    query: {
                        lesson_id: previousLesson.id,
                        section_id: this.$route.query.section_id,
                        course_id: this.$route.query.course_id
                    }
                });
            },
            nextLesson() {
                let lessonIndex = this.section.lessons.filter(l => l.id == this.selectedLesson.id)
                let nextLessonIndex = this.section.lessons.indexOf(lessonIndex[0])
                let nextLesson = this.section.lessons[nextLessonIndex + 1]
                this.$router.push({
                    name: "LmsLessonContent",
                    params: {
                        lmsSlug: this.$route.params.lmsSlug,
                        courseSlug: this.$route.params.courseSlug,
                        sectionSlug: this.$route.params.sectionSlug,
                        lessonSlug: nextLesson.slug
                    },
                    query: {
                        lesson_id: nextLesson.id,
                        section_id: this.$route.query.section_id,
                        course_id: this.$route.query.course_id
                    }
                });
            },
            finishLesson() {
                let vm = this
                vm.$parent.wait(true)
                vm.$store.dispatch('LMS/finishLesson', { lesson_id: vm.selectedLesson.id }).then((res) => {
                    if(res.data.next_lesson != null) {
                        console.log("final count down", res.data)
                        vm.$router.push({
                            name: "LmsLessonContent",
                            params: {
                                lmsSlug: vm.$route.params.lmsSlug,
                                courseSlug: vm.$route.params.courseSlug,
                                sectionSlug: res.data.next_lesson.slug,
                                lessonSlug: res.data.next_lesson.lesson_slug
                            },
                            query: {
                                lesson_id: res.data.next_lesson.lesson_id,
                                section_id: res.data.next_lesson.section_id,
                                course_id: vm.$route.query.course_id
                            }
                        });
                    } else {
                        this.$swal({
                            title: 'Parabéns!',
                            text: 'Você concluiu este curso!',
                            icon: "success",
                            allowOutsideClick: false,
                            confirmButtonText: "Vamos para próxima !"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$router.push({
                                    name: "LmsStudentProgress"
                                })
                            }
                        });

                        vm.$parent.wait(false)                
                    }
                }).catch((error) => {
                    console.log(error)
                    vm.$parent.wait(false)
                })

            },
            finishLessonScorm() {
              let vm = this
              vm.$store.dispatch('LMS/finishLesson', { lesson_id: vm.selectedLesson.id });

          },
            updateNotes() {
                this.notesOrder = this.notes
            },
            // formatQuizUrl(quizId) {
            //     const url = this.$url_api + `form/lms/view/${quizId}`
            //     return url
            // },
            formatTrack(track) {
                let totalSeconds = Math.round(track)
                let hoursLeft = Math.round(totalSeconds / 3600)
                let min = Math.round((totalSeconds - hoursLeft * 3600) / 60)
                let secondsLeft = totalSeconds - hoursLeft * 3600 - min * 60
                secondsLeft = Math.round(secondsLeft * 100) /100
                let formattedTime = ''
                if (hoursLeft > 0) {
                    formattedTime += (hoursLeft < 10 ? "0"+hoursLeft : hoursLeft)
                    formattedTime += ":"+(min < 10 ? "0"+min : min)
                    formattedTime += ":"+(secondsLeft < 10 ? "0"+secondsLeft : secondsLeft)
                } else if  (hoursLeft <= 0) {
                    formattedTime += (min < 10 ? "0"+min : min)
                    formattedTime += ":"+(secondsLeft < 10 ? "0"+secondsLeft : secondsLeft)
                }
                return formattedTime
            },
            onDragEnd() {
                console.log("onDragEnd");
                var slides = document.getElementsByClassName("note");
                var order = {};

                for (var i = 0; i < slides.length; i++) {
                    var id = slides[i].dataset.id;
                    document.getElementById("order_" + id).value = i;
                }

                var inputs = document.getElementsByName("order[]");

                for (var j = 0; j < inputs.length; j++) {
                    var id_ = inputs[j].dataset.id;
                    var value = inputs[j].value;
                    order[id_] = value;
                }
                let vm = this
                vm.$store.dispatch('LMS/ordenateUserNotes', {order: order, lesson_id: vm.selectedLesson.id}).then(() => {
                    vm.updateNotes()
                    vm.$parent.wait(false)
                }).catch((error) => {
                    console.log(error.response)
                    vm.$parent.wait(false)
                })
            },
            getVideosCurrentTime() {
                let videos  = document.getElementsByClassName("content-video");
                let array   = [];
                for (var i = 0; i < videos.length; i++) {
                    var object = {};
                    object['lesson_content_id'] = videos[i].dataset.id;
                    object['current_time']      = videos[i].currentTime;
                    array.push(object);
                }
                return array;
            },
            handleMessageScorm(event) {
              // Recebe PostMessage

              if(typeof event.data === 'string') {

                let data = JSON.parse(event.data);

                let vm = this;

                // Verifica se vem do curso scorm
                if(data.type === 'app_scorm') {


                  let payload = {
                    'course_id': vm.course.id,
                    'user_id'  : vm.user_id
                  }

                  if (Object.prototype.hasOwnProperty.call(data.data, 'lesson_location')) {
                    // Salva em que parte do curso o usuário esta
                    payload.lesson_location = data.data.lesson_location;

                  }
                  if (Object.prototype.hasOwnProperty.call(data.data, 'suspend_data')) {

                    if(data.data.suspend_data.includes('completedAt')) {
                      vm.finishLessonScorm();
                    }

                    payload.suspend_data = data.data.suspend_data;
                  }

                  vm.$store.dispatch('LmsCourse/updateScormUserInfo', payload);

                }

              }
            },
            sendMessageToScormIframe(message) {
              let vm = this;

              const iframe = vm.$refs['scormIframe'];

              iframe.contentWindow.postMessage(JSON.stringify(message), vm.iframeScormUrl);

            }
        },
      beforeDestroy() {

        if(this.isCourseScorm) {
          window.removeEventListener('message', this.handleMessageScorm);
        }


      }
    }
</script>
<style lang="scss" scoped>
    .talktome{
        padding: 0 20px 20px 20px !important; 
    }
    .top-section {
        cursor: pointer;
        height: 44px;
        padding: 0 0 0 2px;
        .hide-menu {
            color: #B1B1B1;
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .top-text {
            margin-left: 5px;
        }
    }

    .show-menu {
        color: #B1B1B1;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: none;
        align-items: center;
        cursor: pointer;
        width: 115px;
        .top-text {
            margin-left: 5px;
        }
    }

    .ai-link-fullscreen {
        text-decoration: none;
        cursor: pointer;
        p {
          padding-left: 20px;
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 16px;
          color: #B1B1B1;
        }
    }

    .mobile {
      display: none;
    }

    .container-lesson {
      flex-direction: row;
      margin-bottom: 50px; /* adjust footer */
    }

    .video-flex {
        display: flex;
        flex-direction: column;
    }

    .video-container {
      margin: 0;
      min-width: 100%;
      margin-bottom: 30px !important;
    }

    .title-container {
      margin: 0;
      min-width: 100%;
      margin-bottom: 10px !important;
      color: var(--buttons_color);
      h2 {
        font-family: 'Open Sans';
        margin-bottom: 0;
        font-size: 1.75rem;
      }
    }

    #notesList,#sessionsList {
      .accordion-item {
        border: 2px solid var(--letzgo-pallet-system-support-support-1, #F6F6F6);
        border-radius: 10px 10px 10px 10px;
      }
    }

    // Change colour of icon when collapsed
    .accordion-button::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    // Change colour of icon when expanded
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg);
    }

    #notesList .accordion-header .accordion-button:not(.collapsed),
    #sessionsList .accordion-header .accordion-button:not(.collapsed),
    #iaMentor .accordion-header .accordion-button:not(.collapsed) {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 50px;
      background-color: var(--buttons_color) !important;
      color: white;
    }

    #sessionsListHeader, #notesListHeader, #iaMentor {
      font-family: "Open Sans";
      .accordion-button:not(.collapsed)::after {
        color: white;
      }
      .accordion-button.collapsed {
        border-radius: 10px 10px 10px 10px;
        font-weight: 400;
      }
      button {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 50px;
        background-color: var(--buttons_color);
        color: white;
        border-radius: 10px 10px 0px 0px;
        font-weight: 400;
      }
    }

    .container {
        display: flex;
        margin-top: 8rem;
        justify-content: space-between;
    }
    .right-container {
        width: 74%;
        height: 100%;
    }
    .left-container {
        width: 23%;
        height: 100%;
    }

    .anotations-buttons {
        padding: 10px;
        display: flex;
        justify-content: flex-end;
    }

    #notesList {
        .accordion-body {
            display: flex;
            justify-content: flex-end;
        }
    }

    .anotation {
        width: 100%;
        display: flex;
        align-self: center;
        flex-direction: column;
        height: 266px;
        line-break: anywhere;
        overflow-y: auto;
        margin-bottom: 10px;
        padding: 20px;
        .action-anotation-buttons {
            display: flex;
            justify-content: flex-end;
            span {
                margin-left: 10px;
                cursor: pointer;
                font-size: 13px;
            }
        }
        textarea{
            background-color: transparent;
            resize: none;
        }
    }

    .video-times {
        display: flex
    }
    .video-time {
        width: 50px;
        display: flex;
        width: 200px;
    }
    .lesson-header {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 24px;
    }

    .lessonidesc {
        display: flex;
        justify-content: space-between;
    }


    #notesList, #sessionsList {
        margin-bottom: 20px;
        .accordion-header {
            .accordion-button{
                &:not(.collapsed){
                background: #f6f6f6;
                }
            }
        }
        .accordion-collapse._selected {
            background-color: #f6f6f6;
        }
        .accordion-collapse.action-button {
            opacity: .6;
            span {
                cursor: pointer;
            }
        }


        /* WebKit and Chromiums */
        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: white;
        }

        ::-webkit-scrollbar-thumb {
            background: var(--buttons_color);
            border-radius: 5px;
        }
    }

    .notesList {
        max-height: 500px;
        overflow-y: auto;
    }

    .sectionList {
        max-height: 300px;
        overflow-y: auto;
        .accordion-body {
            p {
                margin: 0;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    
    .session {
        cursor: pointer;
    }

    .right-container-breadcrumb {
        font-family: "Open Sans";
        display: flex;
        align-items: center;
        justify-content:flex-start;
        width: 40%;
        font-size: 14px;
        padding: 0;
        font-weight: 500;
        i {
            margin-right: 15px;
        }
        span {
            border: none;
            background-color: transparent;
            cursor: pointer;
            color: #706F6F;
            font-weight: 500;
            width: auto;
            margin-right: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
                color: #000;
            }
            &.selected {
                color: var(--buttons_color);
                cursor: default;
            }
        }
        .fa-chevron-right {
            color: #706F6F;
            padding-right: 8px;
            font-size: 16px;
        }
    }

    .content-actions {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        opacity: .6;
        button {
            margin-right: 25px;
        }
    }

    .lesson-desc{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:p {
            max-width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:svg {
            align-self: flex-end;
        }
    }

    .content-button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 20px;
        color: #b1b1b1;
    }
    .content-button:hover {
        color: #000;
    }
    .content-button.finish:hover {
        color: var(--buttons_color);
    }

    .lesson-content {
        font-family: 'Open Sans';
        color: var(--letzgo-pallet-body-text, #706F6F);
        h3 {
            font-family: 'Open Sans';
            margin-bottom: 15px;
            font-size: 1.75rem;
            color: var(--buttons_color);;
        }

        .content {
            margin-bottom: 16px;
            line-break: anywhere;
        }

        .text-container {
            max-width: 100%;
            line-break: auto;
            text-align: justify;
            .quote {
                color: red;
            }
        }

        .audio-container {
            max-width: 100%;
        }

        .image-container {
            width: 100%;
            img {
                width: 100%;
            }
        }

        .iframe-container {
            width: 100%;
            border-radius: 20px;
            iframe {
                width: 100%;
                border-radius: 20px;
                height: 550px;
            }

            ._wait {
                display: none;
            }

            .replay-btn-container {
                width: 100%;
                height: 100%;
                button{
                    padding: 15px 20px;
                    background-color: transparent;
                    border-radius: 10px;
                    border: 2px solid rgb(10, 170, 10);
                    cursor:pointer;
                    margin-left: 300px;
                    &:hover {
                        background-color: rgb(10, 170, 10);
                        color: #fff;
                    }
                }
            }
        }

        audio {
            width: 100%;
            color: purple;
        }

        .scorm-container {
          height: 500px;

        }
    }
    .lesson-description {
        padding: 15px;
        p {
            font-size: 15px;
        }
    }

    blockquote {
        color: red !important;
    }

    @media (max-width: 768px) {
      .container-lesson {
        flex-direction: column;
      }
      .non-mobile {
        display: none;
      }
      .right-container {
        width: 100%;
      }
      .mobile  {
        display: flex;
        flex-direction: column;
      }
      #iaMentor {
        margin-bottom: 20px;
      }
      .lesson-header {
        flex-direction: column;
        width: 100%;
        height: 90px;
        .right-container-breadcrumb {
          width: 100%;
          justify-items: center;
          justify-content: center;
        }
        .content-actions {
          width: 100%;
          justify-items: center;
          justify-content: center;
          button {
            margin-right: 0;
          }
        }
      }
    }
</style>
