<template>
    <section class="lives">
        <div v-if="this.$parent.modal_lives" style="position: fixed; top: 73px; left: 0px;overflow-y:scroll;height: 90vh;">
            <div class="modals__content--header alignVertical">
                <p>{{ title }} </p>
                <a href="#" class="close" data-response="0" v-on:click="this.$parent.modal_lives = false">
                    <img src="assets/icons/modals/x.svg" alt="" >
                </a>
            </div>
            <iframe :src="url" v-if="url != ''" style="z-index: 10000; width:100vw; height:100vh;" frameborder="0" allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="yes"></iframe>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "LiveModal",
        props: ['title', 'live_id' ],
        computed: {
            ...mapGetters([
                'token', 'id', 'is_streamer'
            ])
        },
        data() {
            return { 
                url: '',
            }
        },
        mounted() {
            if (!this.is_streamer) {
                this.$parent.modal_lives = false;
            }
            this.url = this.$url_api + 'cockpit/front/'+ this.live_id + '?t='+this.token
            console.log(this.token)
        },
        methods: {
            
        }
    }
</script>