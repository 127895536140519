<template>
    <div id="reset-password"
         ref="reset-password"
         class="ui translucent suimodal"
         :class="{ small: !checkMobile, overlay : checkMobile, fullscreen : checkMobile }">
        <i class="close icon" />
        <div class="header">
            {{ $t('resetpassword.validarEmail') }}
        </div>
        <div class="content">
            <form id="login-form"
                  class="ui form"
                  ref="reset-password-form"
                  @submit.prevent="send">
                <input name="token" type="hidden" :value="token_">
                <div class="field">
                    <div class="ui right labeled input">
                        <input id="password"
                               v-model="password"
                               :type="passwordFieldType"
                               class="col-12 text"
                               :placeholder="$t('resetpassword.senha')">
                        <div class="ui label" @click="togglePasswordVisibility">
                            <i :class="passwordFieldIcon"></i>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div class="ui right labeled input">
                        <input id="password_confirmation"
                               v-model="password_confirmation"
                               :type="passwordFieldTypeConfirm"
                               class="col-12 text"
                               :placeholder="$t('resetpassword.confirmeASenha')">
                        <div class="ui label" @click="togglePasswordVisibilityConfirm">
                            <i :class="passwordFieldIconConfirm"></i>
                        </div>
                    </div>
                </div>
                <button ref="btn-submit"
                        class="fluid ui button positive"
                        type="submit"
                        :disabled="isButtonDisabled"
                        v-text="btn_submit_value" />
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";

export default {
    name: 'ResetPassword',
    data() {
        return {
            btn_submit: false,
            btn_submit_value: this.$i18n.t('resetpassword.recadastrarSenha'),
            error: null,
            message: null,
            token_: null,
            password: '',
            password_confirmation: '',
            passwordFieldType: 'password',
            passwordFieldIcon: 'eye icon',
            passwordFieldTypeConfirm: 'password',
            passwordFieldIconConfirm: 'eye icon'
        }
    },
    computed: {
        ...mapGetters(['checkMobile']),
        isButtonDisabled() {
            return !(this.password && this.password_confirmation);
        }
    },
    watch: {
        password() {
            this.updateButtonState();
        },
        password_confirmation() {
            this.updateButtonState();
        }
    },
    methods: {
        block(v) {
            this.btn_submit_value = (v ? this.$i18n.t('resetpassword.aguarde') : this.$i18n.t('resetpassword.entrar'));
            this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
            this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
        },
        updateButtonState() {
            if (this.password && this.password_confirmation) {
                this.block(false);
            } else {
                this.block(true);
            }
        },
        togglePasswordVisibility() {
            if (this.passwordFieldType === 'password') {
                this.passwordFieldType = 'text';
                this.passwordFieldIcon = 'eye slash icon';
            } else {
                this.passwordFieldType = 'password';
                this.passwordFieldIcon = 'eye icon';
            }
        },
        togglePasswordVisibilityConfirm() {
            if (this.passwordFieldTypeConfirm === 'password') {
                this.passwordFieldTypeConfirm = 'text';
                this.passwordFieldIconConfirm = 'eye slash icon';
            } else {
                this.passwordFieldTypeConfirm = 'password';
                this.passwordFieldIconConfirm = 'eye icon';
            }
        },
        send(e) {
            this.error = null;
            if (this.password.length < 8) {
                this.error = this.$i18n.t('resetpassword.minimoOito');
            } else if (this.password !== this.password_confirmation) {
                window.$.toast({
                    position: 'top right',
                    title: this.$i18n.t('resetpassword.erro'),
                    message: this.$i18n.t('resetpassword.minimoOito'),
                    showProgress: 'bottom',
                    classProgress: 'white',
                    className: {
                        toast: ' ui toast transparent-toast'
                    },
                    displayTime: 60000,
                    showIcon: 'times',
                    closeOnClick: true
                });
                return;
            }

            if (this.error) {
                window.$.toast({
                    position: 'top right',
                    title: this.$i18n.t('resetpassword.erro'),
                    message: "Senhas não coincidem",
                    showProgress: 'bottom',
                    classProgress: 'white',
                    className: {
                        toast: ' ui toast transparent-toast'
                    },
                    displayTime: 60000,
                    showIcon: 'times',
                    closeOnClick: true
                });
                e.preventDefault();
                return;
            } else {
                this.sendReset(e);
            }
        },
        sendReset(e) {
            if (!this.submit) {
                this.block(true);

                let payload = {
                    token: this.token_,
                    password: this.password,
                    password_confirmation: this.password_confirmation
                }

                axios.post(
                  this.$url_api + 'api/changepwd',
                  payload,
                  { headers: {'language': this.$i18n.locale,} })
                  .then(response => this.resetResponse(response))
                  .catch(error => this.resetError(error.response));
            }
            e.preventDefault();
        },
        resetResponse(obj) {
            if (obj.data.status == 'success') {
                this.message = obj.data.message;
                this.$app.modal('success', this.$i18n.t('resetpassword.sucesso'), this.message, null, null, null, "OK");
                this.$app.link('Login');
            }

            this.block(false);
        },
        resetError(obj) {
            this.block(false);
            let str = '';
            for (let o in obj.data.errors) {
                str += obj.data.errors[o] + '\n';
            }
            this.error = str;
            // this.$app.modal('error', this.$i18n.t('resetpassword.erro'), this.error, null, null, null, "OK");
            window.$.toast({
                position: 'top right',
                title: this.$i18n.t('resetpassword.erro'),
                message: this.error,
                showProgress: 'bottom',
                classProgress: 'white',
                className: {
                    toast: ' ui toast transparent-toast'
                },
                displayTime: 60000,
                showIcon: 'times',
                closeOnClick: true
            });
            return;
        },
        openModal() {
            window.$('#reset-password').suimodal({
                dimmerSettings: {
                    opacity: 0.5
                },
                observeChanges: true,
                closable: false,
                allowMultiple: true
            }).suimodal('show');
            this.block(true);
        }
    },
    mounted() {
        const root = this;
        this.token_ = this.$route.query.token;
        root.openModal();
    },
    updated() {
        this.btn_submit_value = this.$i18n.t('resetpassword.recadastrarSenha');
    },
    destroyed() {
    }
}
</script>

<style scoped>
#reset-password.suimodal {
    padding: 64px 44px;

    .icon.close {
        top: 54px;
        right: 44px;
        color: #fff;
    }

    .text-left {
        text-align: left;
    }

    .ui.label {
        border-radius: 0 10px 10px 0;
    }

    .eye.icon {
        margin: 0.15em 0 0 0 !important;
    }

    input {
        &:focus {
            border-radius: 10px 0 0 10px !important;
        }
    }
}
</style>
