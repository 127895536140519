<template>
    <div v-if="logged" id="vt">
        <div class="cart-controller-header">
            <!-- <a id="cart-icon" :to="{ name:'carrinho' }" v-on:click="cart=true"><img alt="Meu carrinho" :src="$url + 'assets/img/oracle/cart-32.png'" style="cursor: pointer"></a> -->
            <div class="cart-icon" style="filter:none !important" v-on:click="cart=true" v-html="header_buttons.cart_button"/>
            <span class="cart-info">{{ counter }}</span>
        </div>
        <!--<a href="#" v-on:click="teste">teste</a>-->


        <Cart v-if="cart" ref="cart"></Cart>
        <Orders v-if="orders"></Orders>
        <Product v-if="product" :product_sku="product"></Product>
        <AddSuccess v-if="add_success"></AddSuccess>
        <UserAddress v-if="user_address"></UserAddress>
        <!-- <KashStore ref="kash-store"></KashStore> -->
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddSuccess               from '@/components/vt/AddSuccess';
import Cart                     from '@/components/vt/Cart';
// import KashStore                from '@/components/vt/KashStore';
import Orders                   from '@/components/vt/Orders';
import Product                  from '@/components/vt/Product';
import UserAddress              from '@/components/vt/UserAddress';
    export default {
        name: "VT",
        computed: {
            ...mapGetters([
                'token', 'logged', 'counter', 'id', 'first_name', 'last_name', 'email', 'points', 'level', 'level_next', 'score_needed_to_reach', 'thumb', 'check_log',
                'header_buttons'
            ]),
        },
        data() {
            return {
                add_success: null,
                cart: null,
                orders: null,
                product: null,
                user_address: null
            }
        },
        components: {
            // AddSuccess, Cart, KashStore, Orders, Product, UserAddress
            AddSuccess, Cart, Orders, Product, UserAddress
        },
        methods: {
            ...mapActions([
                'update', 'update_cart_products', 'update_profile', 'update_points', 'update_check_log'
            ]),

            setProduct(sku)
            {
                this.product = sku;
                console.log('this.product => ', sku);
                this.$forceUpdate();
            },

            teste()
            {
                this.$refs['kash-store'].login();
            }
        },
        mounted() {
            //this.product = '4bdd04c441';
            //this.product = '7e4ecbff62';

            let cart_store = JSON.parse(localStorage.getItem('cart_store'));
            for (let o in cart_store)
            {
                this.update_cart_products(cart_store[o]);
            }
        }
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');
    #vt
    {
        color: #696A70;
        display: block;
        font-family: 'Roboto', sans-serif;;
        height: auto;
        z-index: 1000;
        width: auto;

        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    

    a         { color:#000; text-decoration:none; transition: color .2s;}
    a:link    { color:#000; text-decoration:none; }
    a:visited { color:#000; text-decoration:none; }
    a:active  { color:#000; text-decoration:none; }
    a:focus   { color:#000; outline-color:var(--buttons_color); text-decoration:none; }
    a:hover   { color: var(--buttons_color); text-decoration:none; transition: color .2s;}
    ul, li, main, header, footer, menu, a
    {
        display: block;
        font-family: inherit;
        font-size: 16px;
        margin: 0;
        padding: 0;
    }
    h1, h2, h3
    {
        margin: 0;
        padding: 0;
    }
    hr
    {
        border: none;
        border-top: dashed 1px #ccc;
        box-sizing: border-box;
        padding: 10px 0;
    }

    /*---- class -----*/
    .box
    {
        /*
        background-color: #fff;
        border-radius: 14px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .1);
        box-sizing: border-box;
        display: block;
        padding: 10px;
        */

        background-color: #fff;
        border: solid 1px #f9f9f9;
        border-radius: 8px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.03);
        border-radius: 16px;
        box-sizing: border-box;
        height: auto;
        left: 50%;
        max-height: 100%;
        min-width: 200px;
        overflow-y: auto;
        padding: 49px 53px 49px 53px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .box a
    {
        text-decoration: none;
    }
    .riscado
    {
        text-decoration: line-through;
    }
    .fm
    {
        font-size: 13px;
    }
    .boldt
    {
        font-weight: bold !important;
    }
    /*---- \class\ -----*/


    /*----- cart -----*/
    .cart-controller-header
    {
        color: var(--fonts_color);
        position: relative;
        .cart-info
        {
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: var(--buttons_color);
            border-radius: 50%;
            box-sizing: border-box;
            color: white;
            font-weight: bold;
            font-size: .75rem;
            padding: 5px;
            height: 1.5rem;
            width: 1.5rem;
            text-align: center;
        }
        .cart-icon
        {
            cursor: pointer;
            svg{
                width: 1.5rem;
                height: 1.5rem;
                fill: var(--fonts_color);
                path{
                    fill: var(--fonts_color);
                }
            }
        }
    }
    /*----- \cart\ -----*/

    /*----- form -----*/
    /*----- placeholder -----*/
    ::-webkit-input-placeholder
    {
        /* Edge */
        color: #666;
    }

    :-ms-input-placeholder
    {
        /* Internet Explorer 10-11 */
        color: #666;
    }

    ::placeholder
    {
        color: #666;
    }
    /*----- \placeholder\ -----*/


    button, input, label, textarea, a
    {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        outline: none;
    }

    textarea
    {
        resize: vertical;
    }


    label
    {
        box-sizing: border-box;
        color: #000;
        display: inline-block;
        font-family: inherit;
        letter-spacing: 1px;
        padding: 10px 0;
    }


    input[type="date"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="password"],
    input[type="text"],
    input[type="number"],
    textarea
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        border-radius: 25px;
        box-shadow: inset 2px 2px 0 rgba(0, 0, 0, .03), 0 1px 0 rgba(255, 255, 255, .5);
        box-sizing: border-box;
        color: #000;
        font-family: inherit;
        font-size: inherit;
        padding: 16px 14px 16px 14px;
        width: 100%;
    }

    input[type="date"]:focus,
    input[type="datetime-local"]:focus,
    input[type="email"]:focus,
    input[type="password"]:focus,
    input[type="text"]:focus,
    input[type="number"]:focus,
    textarea:focus
    {
        background-color: #fff;
    }


    /*----- btns -----*/
    input[type="submit"], .btn-0, .btn-1, .btn-2, .btn-3
    {
        background-color: var(--buttons_color);
        border: none;
        border-radius: 24px;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        // font-size: 15px;
        font-weight: 600;
        letter-spacing: .6px;
        padding: 13px 12px;
        text-align: center;
        width: 100%;
    }
    .btn-0
    {
        background-color: #fff;
        // border: solid 1px #999;
        color: #696A70 !important;
    }
    .btn-2
    {
        // background-color: #4e4e4e;
        border: none;
        color: white !important;
        padding: 6px;
    }
    .btn-2 img
    {
        float: left;
        height: 42px;
    }
    .btn-2 span
    {
        display: inline-block;
        margin: 15px 0 0 -20px;
    }

    .btn-3
    {
        background-color: rgba(0, 0, 0, .7);
        border: none;
        color: white !important;
    }

    input[type="submit"]:focus, .btn-0:focus, .btn-facebook:focus
    {
        opacity: 1;
    }
    /*----- \btns\ -----*/


    /*----- select -----*/
    select
    {
        -webkit-appearance:none;
        appearance:none;
        background: url('../../assets/img/seta_1.png') calc(100% - 10px) calc(100% - 14px) no-repeat #f9f9f9;
        border: solid 1px #ccc;
        border-radius: 20px;
        box-shadow: 2px 2px 0 rgba(0, 0, 0, .03);
        box-sizing: border-box;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: normal;
        
        outline: none;
        padding: 16px 40px 16px 20px;
        width: 100%;
    }
    select:focus
    {
        background-color: #fff;
        /*border: solid 1px rgba(135, 206, 235, 1);*/
    }
    /*----- \select\ -----*/



    /*----- checkbox -----*/
    .checkbox-container
    {
        box-sizing: border-box;
        padding: 10px 0;
    }
    .styled-checkbox
    {
        opacity: 0;
        position: absolute;
    }
    .styled-checkbox + label
    {
        cursor: pointer;
        letter-spacing: 0;
        padding: 0;
        position: relative;
    }
    .styled-checkbox + label:before
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, .06);
        content: '';
        display: inline-block;
        height: 16px;
        margin-right: 10px;
        vertical-align: bottom;
        width: 16px;
    }
    .styled-checkbox:focus + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    .styled-checkbox:hover + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    .styled-checkbox:checked + label:after
    {
        content: '';
        background-color: #fea044;
        position: absolute;
        left: 5px;
        top: 5px;
        height: 8px;
        width: 8px;
    }
    .styled-checkbox:checked + label:before
    {
        background-color: #fff;
    }
    .styled-checkbox:disabled + label
    {
        color: #b8b8b8;
        cursor: auto;
    }
    .styled-checkbox:disabled + label:before
    {
        background-color: #f5f5f5;
        border-color: #e5e5e5;
        box-shadow: none;
    }
    /*----- \checkbox\ -----*/



    /*----- radio -----*/
    .styled-radio
    {
        opacity: 0;
        position: absolute;
    }
    .styled-radio + label
    {
        cursor: pointer;
        letter-spacing: 0;
        padding: 0;
        position: relative;
    }
    .styled-radio + label:before
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        border-radius: 50%;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, .06);
        content: '';
        display: inline-block;
        height: 16px;
        margin-right: 10px;
        vertical-align: bottom;
        width: 16px;
    }
    .styled-radio:focus + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    .styled-radio:hover + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    .styled-radio:checked + label:before
    {
        background-color: #fff;
    }
    .styled-radio:checked + label:after
    {
        content: '';
        background-color: #bbb;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 5px;
        height: 8px;
        width: 8px;
    }
    .styled-radio:disabled + label
    {
        color: #b8b8b8;
        cursor: auto;
    }
    .styled-radio:disabled + label:before
    {
        background-color: #f5f5f5;
        border-color: #e5e5e5;
        box-shadow: none;
    }
    /*----- \radio\ -----*/

    /*----- \form\ -----*/

    @media screen and (max-width: 1000px)
    {
        .box
        {
            width: 100%;
        }
    }
}
</style>