<template>
    <section class="container courses-cards-wrapper">
        <div class="swiper-pagination-lms"></div>
        <div class="swiper-container featured-courses px-0">
            <div class="swiper-wrapper">

                <div class="swiper-slide" v-for="(course, idx) in showCourses" :key="idx" @click="goToCourse(course.slug)">
                    <div class="featured-cards"
                         :data-src="course.card_file_thumb"
                         :data-hover="course.card_file_thumb_hover"
                         :style="`background-image:url('${course.card_file_thumb}') ;`"
                    >
                        <div class="course-name d-flex flex-column justify-content-end h-100 pb-5 ps-4 fs-3" v-if="course.show_title_card">
                            <span class="subtitle w-75">{{ getSubtitleAndTitle(course.title).subtitle.toUpperCase() }}</span>
                            <span class="title w-75">{{ getSubtitleAndTitle(course.title).title.toUpperCase() }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!--        <div class="swiper-button-prev swiper-button-prev-featured-courses mt-0">-->
            <!--          <i class="bi bi-chevron-left"></i>-->
            <!--        </div>-->
            <!--        <div class="swiper-button-next swiper-button-next-featured-courses mt-0">-->
            <!--          <i class="bi bi-chevron-right"></i>-->
            <!--        </div>-->
        </div>
    </section>
</template>

<script>

import Swiper from "swiper";
import $ from "jquery";
// Import Swiper styles
import "swiper/swiper.min.css";

export default {
    name: "FeaturedCurses",
    props: ["courses", "lmsSlug"],
    setup() {
        const onSwiper = (swiper) => {
            swiper;
            // console.log(swiper);
        };
        const onSlideChange = () => {
            // console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
        };
    },
    data() {
        return {
            showCourses: []
        }
    },
    watch: {
        courses() {
            let vm = this;
            let private_courses = vm.courses;
            for(let course of private_courses) {
                if(!course.thumbnail_hover) {
                    course.thumbnail_hover = course.thumbnail;
                }

                if(!course.card_file_thumb) {
                    course.card_file_thumb = course.thumbnail;
                }

                if(!course.card_file_thumb_hover && !course.card_file_thumb) {
                    course.card_file_thumb_hover = course.thumbnail;
                }

                if(!course.card_file_thumb_hover && course.card_file_thumb) {
                    course.card_file_thumb_hover = course.card_file_thumb;
                }

                if(course.card_home_enabled === 1) {
                    vm.showCourses.push(course)
                }
            }
            if(vm.showCourses.length < 1) {
                let courseSection = document.getElementById('course-section');
                courseSection.style.marginTop = '250px';
            }

            $().ready(function () {
                $('.featured-cards').hover(function () {
                    $(this).css('background-image', 'url(' + $(this).data('hover') + ')');
                    $(this).css('box-shadow', '0 4px 25px 0 rgba(0, 0, 0, 0.1)');
                }, function () {
                    $(this).css('background-image', 'url(' + $(this).data('src') + ')');
                    $(this).css('box-shadow', '0 4px 25px 0 rgba(0, 0, 0, 0.1)');
                });
            });
        }
    },
    mounted() {
        this.buildSwiper();
    },
    methods: {
        changeBackground(index, isHovered) {
            this.$set(this.showCourses[index], 'isHovered', isHovered);
        },
        goToCourse(slug) {
            this.$router.push({path: '/lms/' + this.lmsSlug + '/' + slug});
        },
        getSubtitleAndTitle(fulltitle) {
            let titleSplit = fulltitle.split(' ');
            let subtitle = titleSplit.length > 1 ? titleSplit[0] : '';
            let title = titleSplit.length > 1 ? titleSplit[1] : titleSplit[0];

            return {subtitle, title};
        },
        buildSwiper() {
            let root_ = this;

            let options = {
                slidesPerView: 5,
                spaceBetween: 20,
                autoplay: false,
                loop: false,
                allowTouchMove: true,
                // autoHeight       : true,
                observer: true,
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1.5
                    },
                    640: {
                        slidesPerView: 2.5
                    },
                    1024: {
                        slidesPerView: 3.5
                    },
                    1280: {
                        slidesPerView: 3.5
                    },
                    1366: {
                        slidesPerView: 3.5
                    },
                    1400: {
                        slidesPerView: 5
                    }
                },
                pagination: {
                    el: '.swiper-pagination-feature-courses',
                    clickable: true,
                    // type   : root_.slideshowData?.pagination?.type ?? 'bullets',
                },
                navigation: {
                    nextEl: ".swiper-button-next-featured-courses.swiper-button-next",
                    prevEl: ".swiper-button-prev-featured-courses.swiper-button-prev"
                },
            };

            root_.swiper = new Swiper(".swiper-container.featured-courses", options);
        },

    }
}
</script>

<style scoped lang="scss">

.course-name {
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #B0E000;
    word-wrap: break-word;
    overflow: hidden;

    .title {
        color: #FFFFFF;
        line-height: 60px;
    }
}

.course-name span{
    line-height: 34px !important;
}

.subtitle {
    color: var(--buttons_color);
}

.featured-cards {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: saturation;
    height: 385px;
    border-radius: 30px;
    transition: all 0.5s ease-in-out;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.15);
}

.swiper-slide {
    cursor: pointer;
}

.swiper-button-next-featured-courses, .swiper-button-prev-featured-courses {
    position: absolute;
    height: 100%;
    top: 0;
    background: #000;
    opacity: .3;

    .bi {
        color: #FFFFFF;
        font-size: 35px;
    }

}

.swiper-button-prev-featured-courses {
    left: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.swiper-button-next-featured-courses {
    right: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.courses-cards-wrapper {
    padding-left: 0;
    padding-right: 0;
}


@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {
    .container.courses-cards-wrapper,
    .container-md.courses-cards-wrapper,
    .container-sm.courses-cards-wrapper {
        max-width: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
    .featured-cards{
        height: 330px;
    }
    .course-name{
        padding-left: 10px !important;
        padding-bottom: 25px !important;
    }
    .course-name span{
        font-size: 18px !important;
        line-height: 20px !important;
    }
}
@media only screen and (max-width: 480px) {

}

</style>