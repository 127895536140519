<template>
    <div></div>
</template>

<script>
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import Cookie from 'universal-cookie';
import axios from "axios";
import {mapGetters} from "vuex";
// import { RecognitionResult } from "microsoft-cognitiveservices-speech-sdk";
export default
{
    name: 'Speech',
    data()
    {
        return {
            audio_file             : null,
            input_source_file_radio: false,
            microphone_sources     : 'Default Microphone',
            use_detailed_results   : false,
            reco                   : null,
            listening              : false,
            interval               : null,
            started                : false,
        }
    },
    computed:
    {
        ...mapGetters(['token'])
    },
    mounted()
    {

    },
    beforeDestroy() {
        if (this.reco) {
            this.reco.close();
            this.reco = undefined;
        }
        clearInterval(this.interval);
    },
    methods:
    {
        start()
        {
            switch (this.$parent.avia.scenario)
            {
                case 'Single-shot speech-to-text':
                case 'speechRecognizerRecognizeOnce':
                    this.doRecognizeOnceAsync();
                break;
                case 'speechRecognizerContinuous':
                    this.doContinuousRecognition();
                break;
                case 'intentRecognizerRecognizeOnce':
                    // doRecognizeIntentOnceAsync();
                break;
                case 'translationRecognizerContinuous':
                    // doContinuousTranslation();
                break;
                case 'pronunciationAssessmentOnce':
                    // doPronunciationAssessmentOnceAsync();
                break;
                case 'pronunciationAssessmentContinuous':
                    // doContinuousPronunciationAssessment();
                break;
            }
        },
        getAudioConfig()
        {
            if (this.microphone_sources)
            {
                return SpeechSDK.AudioConfig.fromMicrophoneInput(this.microphone_sources);
            }
            else
            {
                return SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
            }
        },
        async getSpeechConfig(sdkConfigType)
        {
            try {
                let speech_config;
                const tokenObj = await this.getTokenOrRefresh();
                speech_config = sdkConfigType.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);

                /*
                if (this.use_detailed_results && sdkConfigType != SpeechSDK.SpeechConfig)
                {
                    console.log('Detailed results are not supported for this scenario.\r\n');
                }
                else if (this.use_detailed_results)
                {
                    speech_config.outputFormat = SpeechSDK.OutputFormat.Detailed;
                }

                if (sdkConfigType == SpeechSDK.SpeechTranslationConfig)
                {
                    console.log('esssssss');
                    speech_config.addTargetLanguage('es');
                }
                */
                speech_config.speechRecognitionLanguage = this.$parent.avia.recognition_language;
                return speech_config;
            } catch (error) {
                console.log('Error => ', error);
                return null;
            }
        },
        getTokenOrRefresh(){
            return new Promise((resolve, reject) => {
                const cookie = new Cookie();
                const speechToken = cookie.get('speech-token');
                if (speechToken === undefined) {
                    axios.get(`${this.$url_api}api/get-speech-token`,{
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + this.token,
                        }
                    }).then((response)=>{
                        const token = response.data.data.token;
                        const region = response.data.data.region;
                        cookie.set('speech-token', region + ':' + token, {maxAge: 9*60, path: '/'});
                        resolve({ authToken: token, region: region });
                    }).catch((err)=>{
                        reject({ authToken: null, error: err.response.data });
                    });
                } else {
                    const idx = speechToken.indexOf(':');
                    resolve( { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) });
                }
            });
        },
        applyCommonConfigurationTo(recognizer)
        {
            recognizer.recognizing    = this.onRecognizing;
            recognizer.recognized     = this.onRecognized;
            recognizer.canceled       = this.onCanceled;
            recognizer.sessionStarted = this.onSessionStarted;
            recognizer.sessionStopped = this.onSessionStopped;
            if (this.$parent.avia.phrases)
            {
                let phrases = this.$parent.avia.phrases;
                let phraseListGrammar = SpeechSDK.PhraseListGrammar.fromRecognizer(recognizer);
                phraseListGrammar.addPhrases(phrases.split(";"));
            }
        },
        onRecognized(sender, recognitionEventArgs)
        {
            console.log('onRecognized');
            this.onRecognizedResult(recognitionEventArgs.result);
        },
        onRecognizedResult(result)
        {
            this.$parent.listening = false;
            if (result.privText) {
                this.$parent.addMessage(result.privText);
                this.$parent.$refs?.chat?.send(result.privText);
            }else{
                this.$parent.audioStop()
            }
        },
        onSessionStarted(sender, sessionEventArgs)
        {
            console.log('onSessionStarted => ', sessionEventArgs.sessionId);
        },
        onSessionStopped(sender, sessionEventArgs)
        {
            console.log('onSessionStopped => ', sessionEventArgs.sessionId);
            this.started = false;
        },
        onCanceled(sender, cancellationEventArgs)
        {
            console.log('onCanceled => ', cancellationEventArgs);
            if (cancellationEventArgs.reason === SpeechSDK.CancellationReason.Error)
            {
                console.log('Error => ', cancellationEventArgs.errorDetails);
                switch (cancellationEventArgs.errorCode)
                {
                    case SpeechSDK.CancellationErrorCode.ConnectionFailure:
                        if (this.$parent.avia.scenario === 'speechRecognizerContinuous') {
                            this.started = false;
                            this.start()
                        }
                    break;
                    case SpeechSDK.CancellationErrorCode.ServiceError:
                        console.log('Service error');
                    break;
                }
            }
        },
        async doRecognizeOnceAsync() {
            console.log('doRecognizeOnceAsync');
            let root_ = this;
            let audioConfig = this.getAudioConfig();
            let speechConfig = await this.getSpeechConfig(SpeechSDK.SpeechConfig);
            if (!audioConfig || !speechConfig) return;
            let reco = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
            this.applyCommonConfigurationTo(reco);
            reco.recognized = undefined;
            reco.recognizeOnceAsync(
                function (successfulResult) {
                    console.log('here => ', successfulResult);
                    root_.onRecognizedResult(successfulResult);
                    reco.close();
                    speechConfig.close();
                },
                function (err) {
                    console.log(err);
                });
        },
        async doContinuousRecognition() {
            // resetUiForScenarioStart();
            if (this.started) return;
            this.started = true;
            clearTimeout(this.interval);
            let audioConfig = this.getAudioConfig();
            let speechConfig = await this.getSpeechConfig(SpeechSDK.SpeechConfig);
            if (!speechConfig) return;
            let root = this;
            this.reco = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
            if (this.$parent.avia.scenario === 'speechRecognizerContinuous')
            {
                this.interval = setInterval(async () => {
                    const tokenObj = await root.getTokenOrRefresh();
                    speechConfig.authorizationToken = tokenObj.authToken;
                    root.reco.authorizationToken = tokenObj.authToken;
                }, 10 * 60 * 1000);
            }
            this.applyCommonConfigurationTo(this.reco);
            this.toggleReco();
        },
        toggleReco() {
            if (this.reco) {
                if (this.listening !== this.$parent.mic_on) {
                    let root = this;
                    if (this.listening) {
                        this.reco.stopContinuousRecognitionAsync(function () {
                            root.listening = false;
                        }, function (err) {
                            console.trace("Error stopping continuous recognition: " + err);
                        });
                    } else {
                        this.reco.startContinuousRecognitionAsync(function () {
                            root.listening = true;
                        }, function (err) {
                            console.trace("Error starting continuous recognition: " + err);
                        });
                    }
                }
            }else {
                this.start();
            }
        }
    }
}
</script>