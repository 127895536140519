<template>
  <div>
    <div id="theMentor" class="personal-advisor-expanded" v-if="expanded">
      <iframe :src="`${url}?lms=true&minimize_chat=true&user_id=${user_id}`" 
        allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;"
        allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="no" frameborder="0" 
        height="400" width="400">
      </iframe>

      <div class="options-wrapper">
        <button @click="maximizeMentor">
          <i v-if="maximized" class="material-icons" style="margin:0;">close_fullscreen</i>
          <i v-else class="material-icons" style="margin:0;">open_in_full</i>
        </button>

        <button @click="toggleMentor">
          <i class="material-icons">close</i>
          <span class="text">Fechar Mentor</span>
        </button>
      </div>

    </div>

    <button v-else-if="!expanded" class="personal-advisor" @click="toggleMentor">
      <i class="material-icons">support_agent</i>
      <span>Fale com seu Mentor Pessoal</span>
    </button>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: "PersonalAdvisor",
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        expanded: false,
        maximized: false,
      }
    },
    computed: {
            ...mapGetters({
                user_id: ['id']
            }),
        },
    methods: {
      toggleMentor() {
        this.expanded = !this.expanded
      },
      maximizeMentor() {
        let mentor = document.getElementById('theMentor');
        mentor.classList.toggle('max');
        if( mentor.classList.contains('max') ) {
          this.maximized = true;
        } else {
          this.maximized = false;
        }
      }
    },
  }
</script>

<style scoped lang="scss">

  .personal-advisor-expanded {
    position: fixed;
    bottom: 48px;
    right: 220px;
    background-color: #FFFFFF;
    color: #706F6F;
    padding: 20px;
    display: flex;
    font-size: 20px;
    z-index: 1;
    flex-direction: column;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    transition: all .2s ease;
    width: 440px;
    height: 498px;

    iframe {
      width: 400px;
      height: 400px;
      border: none;
      border-radius: 30px 30px 0 0;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      width: 100%;
      height: 100%;
    }

    button {
      align-self: flex-end;
      align-items: center;

      background-color: #FFFFFF;
      color: #706F6F;
      padding: 10px;
      margin-top: 14px;
      display: flex;
      font-size: 16px;

      border: none;
      border-radius: 30px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);

      .material-icons {
        color: var(--buttons_color);
        margin-right: 12px;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
      }
    }

    .options-wrapper{
      display: flex;
      justify-content: space-between;
    }

    &.max {
      width: calc(100% - 50px);
      height: calc(100% - 50px);
      right: 25px;
      bottom: 25px;
      z-index: 999;
    }
  }
  .personal-advisor {
    position: fixed;
    bottom: 48px;
    right: 220px;
    background-color: #FFFFFF;
    color: #706F6F;
    padding: 16px 30px;
    display: flex;
    font-size: 20px;
    z-index: 1;

    border: none;
    border-radius: 30px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

    .material-icons {
      color: var(--buttons_color);
      margin-right: 12px;
    }
  }

  @media screen and (max-width: 1200px) {
    .personal-advisor-expanded {
      right: 10px;
    }
    .personal-advisor[data-v-0a23f974] {
      right: 10px;
    }
  }
  @media screen and (max-width: 992px) {
    
  }
  @media screen and (max-width: 768px) {
    .personal-advisor-expanded {
      position: fixed;
      bottom: 35px;
      right: 5px;
      padding: 16px;
      display: flex;
      font-size: 16px;
      border-radius: 20px;
      width: calc(100% - 10px);
      height: calc(100% - 110px);

      &.max {
        width: calc(100% - 10px);
        height: calc(100% - 20px);
        right: 5px;
        bottom: 10px;
      }
      
      iframe {
        // width: 100%;
        // height: 400px;
        border: none;
        border-radius: 20px 20px 0 0;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      }

      button {
        align-self: flex-end;
        align-items: center;

        background-color: #FFFFFF;
        color: #706F6F;
        padding: 10px;
        margin-top: 14px;
        display: flex;
        font-size: 16px;

        border: none;
        border-radius: 30px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);

        .material-icons {
          color: var(--buttons_color);
          margin-right: 12px;
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
          transition: all 0.2s ease-in-out;
        }
      }
    }
    .personal-advisor {
      position: fixed;
      bottom: 35px;
      right: 5px;
      padding: 12px;
      display: flex;
      font-size: 14px;

      align-items: center;

      border: none;
      border-radius: 30px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

      .material-icons {
        color: var(--buttons_color);
        margin-right: 12px;
      }
    }
  }
  @media screen and (max-width: 480px) {

  }

</style>