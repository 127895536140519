<template>
    <div id="add-success">
        <div class="box" id="container">Produto adicionado com sucesso.</div>
    </div>
</template>

<script>
    export default {
        name: "AddSuccess",
        data() {
            return {

            }
        },
        methods: {
            ok() {
                let root_ = this;
                setTimeout(function(){
                    document.getElementById('add-success').style.display = 'none';
                    root_.$parent.add_success = false;
                }, 3000);
            }
        },
        mounted() {
            this.ok();
        }
    }
</script>

<style scoped>
    #add-success
    {
        position: fixed;
        right: 10px;
        top: 90px;
        width: 300px;
    }
    #container
    {
        animation: fadeinout 2s linear 1 forwards;
        background-color: var(--buttons_color);
        border-radius: 25px;
        display: block;
        color: white;
        font-weight: bold;
        height: 50px;
        line-height: 30px;
        opacity: 0;
        text-align: center;
        width: 300px;
    }
    @keyframes fadeinout {
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
    }
</style>