import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from "./state";

export const TemplateHTML = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

export default TemplateHTML;