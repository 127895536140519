export default {
    update_cart(state, cart) {
        state.cart = cart;
    },
    update_cart_totals(state, cartTotals) {
        state.cartTotals = cartTotals;
    },
    update_processing(state, process){
        state.processing = process;
    },
    update_gateways(state, gateways) {
        state.gateways = gateways;
    },
    update_public_key(state, publickey) {
        state.publicKey = publickey;
    },
    update_voucher(state, voucher) {
        state.voucher = voucher;
    },
    update_gateways_min_values(state, gateway_min_values) {
        state.gatewaysMinValues = gateway_min_values;
    },
    update_voucher_valid(state, valid) {
        state.isVoucherValid = valid;
    }
};