<template>
    <div></div>
</template>

<script>
import axios from 'axios';
export default
{
    name: 'Chat',
    props: ['messages_chat'],
    data()
    {
        return {
            messages: this.messages_chat??[]
        }
    },
    computed: { },
    methods:
    {
        send(txt, user__ = true, openai_token_ = '', openai_model_ = '')
        {
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access'      : 'application/json',
                // language   : this.$i18n.locale, Authorization: `Bearer ${this.token}`
            }
            if (user__)
            {
                this.messages.push({ "role": "user", "content": txt });
            }
            
            let form_data = new FormData();
            form_data.append('avia_id', this.$parent.avia ? this.$parent.avia.id : null);
            form_data.append('openai_token', openai_token_);
            form_data.append('openai_model', openai_model_);
            form_data.append('personality', JSON.stringify(this.$parent.personality));
            form_data.append('description', 'Personalidade da IA');
            form_data.append('max_tokens', (this.$parent.avia && this.$parent.avia.max_tokens) ? this.$parent.avia.max_tokens : 10000);
            // form_data.append('max_tokens', 10000);
            form_data.append('messages', JSON.stringify(this.messages));

            axios.post(this.$url_api + "api/ai/chat", form_data, { headers: headers })
                .then((response) => this.sendResponse(response))
                .catch((error) => this.sendError(error.response));
        },
        sendResponse(obj)
        {
            if (obj.data.data.error)
            {
                alert(obj.data.data.error.message);
                this.$parent?.block(false)
            }
            else
            {
                try
                {
                    this.messages.push( { "role": "assistant", "content": obj.data.data.choices[0].message.content } );
                    this.$parent.addMessage(obj.data.data.choices[0].message.content, true);
                }
                catch(e) { console.log('sendResponse error -> ', e); this.$parent?.block(false)}
            
            
            
                //https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them
                /*
                1 token ~= 4 chars in English
                1 token ~= ¾ words
                100 tokens ~= 75 words
                Or 
                1-2 sentence ~= 30 tokens
                1 paragraph ~= 100 tokens
                1,500 words ~= 2048 tokens
                */
                /*
                if ((this.messages) && (this.messages.length > 6))
                {
                    // this.messages.shift();
                    let length_ = this.messages.length;
                    this.messages = this.messages.slice((length_ - 6), length_);
                }
                */
                
                //
                //ok
                /*
                if (obj.data.data.usage.total_tokens > 3000)
                {
                    let clear_length = this.$parent.count;
                
                    if (clear_length)
                        this.messages = this.messages.slice(0, clear_length);
                    else
                        this.messages = [];
                }
                */
            }
        },
        sendError(obj)
        {
            obj
            // this.messages = this.$parent.$refs['personality'].setPersonality();
            alert('Não foi possível conversar com a IA nesse momento. Tente novamente por favor.');
            this.$parent?.block(false)
        }
    },
    mounted() { }
}
</script>