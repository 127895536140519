import axios from 'axios'

export default {
    fetchFiles({commit, rootState}, payload) {
        let url = this._vm.$url_api +'api/lms/user/files/list?per_page=10&page='+payload.page;
        return new Promise((resolve, reject) => {
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };
            axios
                .get(url,{
                    headers:headers,
                    params: {
                        extension: payload.extensions,
                        misc_files: payload.misc
                    },
                })
                .then((response) => {
                    commit('fetchFilesData', response.data.data.files.data)
                    commit('fetchPagination', {
                        currentPage: response.data.data.files.current_page,
                        lastPage: response.data.data.files.last_page,
                        prevPageUrl: response.data.data.files.prev_page_url,
                        nextPageUrl: response.data.data.files.next_page_url,
                        path: response.data.data.files.path,
                    })
                    resolve();
                })
                .catch((error) => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                });
        });
    },
    onFileUpload({rootState}, payload) {
        let url = this._vm.$url_api +'api/lms/user/files/upload';
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.post(url, payload.formData, {headers: headers}).then((res) => {
                if (res.data.status === 200) {
                    let result = []
                    result['file_id'] = res.data.data.file.id

                    result['thumbnail'] = res.data.data.file.url
                    // this.$emit('export_file', result)
                    resolve(result);
                } else {
                    console.log('Falha no upload do arquivo');
                    reject();
                }
            }).catch(error => {
                console.log(error.response.data.data);
                reject(error.response.data.data);
            });
        })
    },
    selectFiles({commit}, payload) {
        return new Promise((resolve) => {
            commit('selectFiles', payload)
            resolve();
        });

    },
    emptySelectedFiles({commit}) {
        return new Promise((resolve) => {
            resolve(commit('emptySelectedFiles'));
        });
    },
    mementoMemory({commit}) {
        return new Promise((resolve) => {
            resolve(commit('mementoMemory'));
        });
    },
    onFileDelete({rootState}, payload) {
        let url = this._vm.$url_api +'api/lms/user/files/delete';
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };

        return new Promise((resolve, reject) => {
            axios.delete(
                url,
                {
                    headers: headers,
                    params: payload
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data);
                        resolve();
                    } else {
                        console.log('Falha ao deletar arquivo');
                        reject();
                    }
                })
                .catch(error => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                });

        })
    },
}