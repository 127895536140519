export default {
    setOpenedTranscription(state, payload) {
        state.opened_transcription = payload;
    },
    setMessagesChat(state, payload) {
        state.messages_chat = payload;
    },
    setLoader(state, payload) {
        state.loader = payload;
    }
};