import axios from 'axios'

export default {
    getUser({commit, rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/profile'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {slug: payload.slug, user_id: payload.user_id}}).then((res) => {
                commit('update_user_information', res.data.data)
                resolve()
            }).catch((error) => {
                console.log(error.response)
                reject(error.response)
            })
        })
    },
    updateUser({rootState}, form) {
        let url = this._vm.$url_api + 'api/lms/user/update'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.post(url, form, {headers: headers}).then(() => {
                resolve()
            }).catch((error) => {
                console.log(error.response)
                reject(error.response)
            })
        })
    }
}
