<template>
 <div></div>
</template>

<script>
export default {
  name: "LmsCourseSection",
  beforeMount() {

    let root_ = this;
    let payload = {
      "slug": root_.$route.params.sectionSlug
    };

    root_.$store.dispatch("LmsCourse/getCourseSections", payload)
        .then(data => {

          if(data?.lessons?.length){

            this.$router.push({
              name: "LmsLessonContent",
              params: {
                lmsSlug: root_.$route.params.lmsSlug,
                courseSlug: root_.$route.params.courseSlug,
                sectionSlug: root_.$route.params.sectionSlug,
                lessonSlug: data.lessons[0].slug
              }
            });

          } else {
            root_.$router.push({
              name: "LMSCourses",
              params: {
                lmsSlug: root_.$route.params.lmsSlug,
                courseSlug: root_.$route.params.courseSlug,
              }
            });
          }
        });

  }
}

</script>

<style scoped>

</style>