import axios from 'axios'

export default {
    fetchCourses({rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/journey'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {slug: payload}}).then((res) => {
                resolve(res.data)
            }).catch((error) => {
                console.log(error.response)
                reject(error.response)
            })
        })
    },
    fetchCourseSections({rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/journey/list'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {id: payload}}).then((res) => {
                resolve(res.data)
            }).catch((error) => {
                console.log(error.response)
                reject(error.response)
            })
        })
    },
    fetchSectionLessons({rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/journey/list'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: payload}).then((res) => {
                resolve(res.data)
            }).catch((error) => {
                console.log(error.response)
                reject(error.response)
            })
        })
    },
}
