<template>
    <section class="change-mobile-out" id="mobile" ref="mobile">
        <div id="mobile--closeDesktop" v-on:click="mobileHandler(true)" />
        <div>
            <div id="nav">
                <div class="phone alignVertical">
                    <div id="mobile--close" v-on:click="mobileHandler()">
                        <img
                            id="mobile--close--img"
                            src="assets/img/mobile/closed.svg"
                        >
                    </div>
                    <div class="phone__content" ref="phone__content">
                        <div v-on:click="$router.push({name:'Map'})" style="cursor: pointer" class="phone__content--banner alignVertical" ref="phone__content--banner">
                            <div class="phone__content--banner---img" :style="'background-image:url(\''+(this.smartphone?this.smartphone:'assets/images/mobile/mapa_celularzinho.png')+'\')'"></div>
                            <div class="phone__content--banner---text col-10 alignVertical">
                                <p>
                                    {{ $t('mobile.MAPA') }}
                                </p>
                            </div>
                        </div>

                        <div class="phone__content--menu" ref="phone__content--menu">
                            <div class="swiper menuMobile" ref="menuMobile">
                                <div class="swiper-wrapper">
                                    <div v-for="(slides, indexS) in menuSlides" :key="indexS" class="swiper-slide">
                                        <div class="box" :key="indexSlide" v-for="(slide,indexSlide) in slides">
                                            <div :class="{'box__icon':true, 'autofmt':slide.autofmt}"
                                                 v-on:click="mobileHandler();mobileRedirect(slide)"
                                                 :style="slide.autofmt?'background-image:url(\''+$url_api + 'storage/smartphone/' + slide.thumb+'\')':''">
                                                <img v-if="!slide.autofmt" width="57" v-bind:src="$url_api + 'storage/smartphone/' + slide.thumb">
                                            </div>
                                            <p>{{ slide.title }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import Swiper from 'swiper/bundle';
import {mapGetters} from "vuex";

export default {
    name: 'Mobile',
    data() {
        return {
            menuSwiper: null,
        }
    },
    computed: {
        ...mapGetters([
            'switch','avatar','smartphone_options','smartphone', 'checkIOS', 'checkMobile'
        ]),
        btn_actual(){
            return this.$refs['btn-fan'];
        },
        phoneContent(){
            return this.$refs["phone__content"];
        },
        phoneContentBaner(){
            return this.$refs["phone__content--banner"];
        },
        phoneContentMenu(){
            return this.$refs["phone__content--menu"];
        },
        menuMobile(){
            return this.$refs['menuMobile'];
        },
        mobile(){
            return this.$refs['mobile'];
        },
        menuSlides(){
            let tempSlides = []
            let rows = Math.ceil(this.smartphone_options.length / 3);
            for(let i=0;i<rows;i++){
                tempSlides.push(this.smartphone_options.slice(i*3, i*3+3));
            }
            return [...tempSlides];
        }
    },
    components:{
    },
    methods: {
        mobileHandler(closed=false) {
            const mobile = this.mobile;
            if (mobile?.classList[0] == 'change-mobile-out') {
                this.$app.analytics('visit_menu');
                if (closed)
                    return;
            }
            mobile?.classList.toggle("change-mobile-out");
            mobile?.classList.toggle("change-mobile-in");
        },
        mobileRedirect(btn) {
            let url = btn.url;
            console.log('teste => ', btn )
            let ios = this.checkIOS;
            let android = this.checkMobile;
            if ((ios || android) && btn.urlmobile != '')
                url = btn.urlmobile;
            switch (btn.opens_in) {
                case"modal":
                    if(!btn.urlexterna) {
                        url = `${this.$url}#/${url}`
                    }
                    this.$app.modalI("",url,null,"OK");
                    break;
                case"same_page":
                    if (!btn.urlexterna) {
                        if(url.startsWith("e/")) {
                            url = '/'+url;
                        }
                        
                        this.$router.push({path: `${url}`})
                    } else {
                        window.open(url,"_top")
                    }

                    break;
                case"new_page":
                    if (!btn.urlexterna) {
                        url = `${this.$url}#/${url}`
                    }
                    window.open(url,"_blank")
                    break;
            }
        },
        _redirect(url) {
            if (!/\.(https|http)$/i.test(url)) {
                window.location.href = '//' + url;
            } else {
                window.location.href = url;
            }

        },
        updateRows: function () {
            if (!this.phoneContent || !this.phoneContentBaner)
                return;
            let height = this.phoneContent.getBoundingClientRect().height -
                this.phoneContentBaner.getBoundingClientRect().height
                - 20;//padding
            this.phoneContentMenu.style.height = height + "px";
            if (this.menuSwiper) {
                let rows = Math.ceil(height / 142)
                rows = Math.max(rows,1)
                rows = Math.min(rows,3)
                this.menuSwiper.params.slidesPerColumn=rows;
                let fr = "1fr ".repeat(rows);
                let wrapper = this.menuMobile.querySelector(".swiper-wrapper");
                if (wrapper)
                    wrapper.style.gridTemplateRows = fr;
                if (this.menuSwiper.slides>0)
                    this.menuSwiper.update();
            }
        }
    },
    mounted() {
        const root = this;
        root.isMobile = this.checkIOS || this.checkMobile;
        //this.btn_actual.classList.toggle("btn-actual");
        root.menuSwiper = new Swiper(".menuMobile", {
            cssWidthAndHeight: true,
            slidesPerView: 1,
            slidesPerColumn:3,
            slidesPerColumnFill:"column",
            visibilityFullFit: true,
            autoResize: false,
            width:228,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            on: {
                beforeInit: function () {root.updateRows()},
                orientationchange: function(){root.updateRows()},
                beforeResize: function(){root.updateRows()},

            },
        });
        root.updateRows();
    },
    updated() {
        if (this.menuSwiper)
            this.menuSwiper.update();
    }
}
</script>
