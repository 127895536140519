<template>
    <div id="cart">
        <div class="box" id="cart-container">
            <a href="#" id="btn-close" ref="btn-close" v-on:click="exit"><img :src="$url + 'assets/img/oracle/x_24.png'"></a>
            <div class="row" style="display: flex;">
                <div class="col-4">
                    <h2 v-text="$ml.get('cart.title')"></h2>
                </div>
                <div class="col-1">

                </div>
                <div v-if="products.length && !this.isMobile" class="col-6 alignVertical" style="margin-left: 5px;">
                    <a class="btn-2" id="btn-payments" href="#" v-on:click="my_payments">Meus pagamentos</a>
                </div>
                <div class="col-1">

                </div>
            </div>
            <p v-if="products.length" id="myCartCounter">{{ $ml.get('cart.meus_itens') }}&nbsp;({{ counter }})</p>

            <div style="text-align: center;" class="info myCartInfos" v-if="!products.length && !loading" v-html="$ml.get('cart.nenhum_produto')"></div>
            <div class="info" v-if="loading" v-html="$ml.get('cart.carregando_itens')"></div>
            <ul>
                <li v-for="i in this.products" :key="i.id">
                    <ul class="alignVertical">
                        <li>
                            <img :src="i.files" width="70">
                        </li>
                        <li>{{ i.description }}</li>
                        <li class="product-list">
                            <a class="decrement" v-on:click="decrement" :data-id="i.id"><b>-</b></a>
                            <p id="product-count">{{ i.quantity  }}</p>
                            <a class="increment" style="font-size: 25px" v-on:click="increment" :data-id="i.id"><b>+</b></a>
                        </li>
                        <li style="text-align: center">
                            <div v-bind:class="{ fm : (i.sale_price), riscado:(i.sale_price)  }">R$ {{ String(Number(i.price).toFixed(2)).replace(".", ",") }}</div>
                            <div v-if="i.sale_price" class="boldt">R$ {{ String(Number(i.sale_price).toFixed(2)).replace(".", ",") }}</div>
                        </li>
                        <li>R$ {{ String(Number(i.price * i.quantity).toFixed(2)).replace(".", ",") }}</li>
                        <li><a class="icon-trash" href="#" v-on:click="del" :data-id="i.id" :title="$ml.get('cart.excluir_item')"><img :src="$url + 'assets/img/oracle/trash.png'"></a></li>
                    </ul>
                </li>
            </ul>

            <input id="product_temp" name="product_temp" ref="product_temp" type="hidden" value="" style="display: none">
            <div id="cart-change-open" v-if="change_cart_open">

                <ul>
                    <li><a class="btn-0" href="#" v-on:click="cartChangeDiscard" v-text="$ml.get('cart.descartar')"></a></li>
                    <li><a class="btn-1" href="#" v-on:click="cartChangeSave" v-text="$ml.get('cart.confirmar')"></a></li>
                </ul>
            </div>
            <hr v-show="products.length">
            <div v-show="products.length" id="cart-details" v-bind:class="{ 'cart-change-blocked': this.change_cart_open}">
                <div id="subtotal">
                    <p>{{ $ml.get('cart.subtotal_compra') }} <span style="font-weight: bold;">R$ {{ String(Number(subtotal).toFixed(2)).replace(".", ",") }}</span></p>
                </div>
                <div id="total">
                    <p v-if="this.hasDiscount">{{ $ml.get('cart.total_compra_desconto') }} <span style="font-weight: bold;">R$ {{ String(Number(total).toFixed(2)).replace(".", ",") }}</span></p>
                    <p v-else>{{ $ml.get('cart.total_compra') }} <span style="font-weight: bold;">R$ {{ String(Number(total).toFixed(2)).replace(".", ",") }}</span></p>
                </div>
            </div>
            <div id="cart-info" v-bind:class="{ 'cart-change-blocked': this.change_cart_open }">
                <ul>
                    <li>
                        <ul>
                            <li>
                                <ul>
                                    <li v-if="this.point_checked" style="vertical-align: middle">{{ $ml.get('cart.utilizacao_de_pontos')}} <b>{{ point_value }}</b></li>
                                    <li v-if="this.point_checked" style="vertical-align: middle">
                                        <!--<input id="point-field" ref="point-field" min="0" :max="(points > total ? Math.floor(total) : points)" readonly placeholder="Pontos" style="width: 100px;height: 40px" type="number" value="0" v-model="point_value" v-on:keyup="checkInputValue" v-on:change="pointInputHandler">-->
                                        <input id="point-field" ref="point-field" min="0" :max="(points > total ? Math.floor(total) : points)" readonly placeholder="Pontos" style="width: 100px;height: 40px" type="text" value="0" v-model="point_value" v-on:keyup="checkInputValue" v-on:change="pointInputHandler">
                                    </li>
                                    <li v-show="false" v-text="$ml.get('cart.total_pagar')"></li>
                                    <li v-show="false"><b>R$ {{ String(Number(total_temp).toFixed(2)).replace(".", ",") }}</b></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div v-show="products.length" id="cart-controller" v-bind:class="{ 'cart-change-blocked': this.change_cart_open }">
                <ul>
                    <li><button class="btn-0" type="button" v-on:click="clearCart" v-text="$ml.get('cart.esvaziar_carrinho')"></button></li>
                    <!--<li><a class="btn-1" v-on:click="checkout" v-text="$ml.get('cart.pagar')"></a></li>-->
                    <li><a class="btn-1" :href="`${this.store_urls.url_api}/checkout/?orderFormId=${this.cart_token}#/cart`" target="_blank" v-text="$ml.get('cart.pagar')"></a></li>
                </ul>
            </div>
            <div v-if="this.isMobile || !products.length">
                <hr>
                <a class="btn-2" id="btn-payments" href="#" v-on:click="my_payments">Meus pagamentos</a>
            </div>
        </div>


        <div v-if="stores_active" id="stores">
            <div class="box" id="stores-container">
                <ul>
                    <li><h3>{{ $ml.get('cart.escolha_cidade') }}</h3></li>
                    <li>
                        <select name="locationId" id="locationId">
                            <option value="">{{ $ml.get('cart.escolha_cidade') }}</option>
                            <option  v-for="i in this.stores" :key="i.locationId" :value="i.locationId">{{ i.address1 + ' - ' + i.city }}</option>
                        </select>
                    </li>
                    <li><button class="btn-1" type="button"  v-on:click="storeChoice">{{ $ml.get('cart.choice') }}</button></li>
                    <li><button class="btn-0" type="button" v-on:click="storeCancel">{{ $ml.get('cart.cancel') }}</button></li>
                </ul>
            </div>
        </div>

    </div>

</template>

<script>
    import axios from 'axios';
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "Cart",
        computed: {
            ...mapGetters([
                'counter', 'token', 'logged', 'products', 'products_ids', 'products_n', 'points', 'id', 'email', 'first_name', 'last_name', 'store_urls', 'cart_token', 'checkIOS', 'checkMobile'
            ])
        },
        data() {
            return {
                change_cart_open: false,
                count           : 0,
                delivery        : 'home',
                error           : null,
                l_              : null,
                loading         : false,
                location_id     : null,
                point_checked   : false,
                point_value     : 0,
                shippingGroups  : [],
                shoppingCart    : [],
                stores          : [],
                stores_active   : false,
                total           : 0,
                total_temp      : 0,
                subtotal        : 0,
                hasDiscount     : false,
                isMobile        : false
            }
        },
        methods: {
            ...mapActions([
                'update', 'update_cart', 'update_cart_products','update_cart_products_array', 'del_product_cart', 'update_points', "update_cart_token"
            ]),
            exit(e) {
                if (this.change_cart_open) {
                    this.cartChangeDiscard(null);
                }
                this.$parent.cart = false;
                if (e)
                    e.preventDefault();
            },
            my_payments(e) {
                window.open("https://www.lojapayot.com.br/_secure/account/orders#/orders");
                e.preventDefault();
            },
            /*----- cart-change -----*/
            cartChangeDiscard(e) {
                this.shoppingCart = JSON.parse(this.$refs['product_temp'].value);
                for (let o in this.shoppingCart) {
                    this.update_cart_products(this.shoppingCart[o]);
                }
                this.$forceUpdate();
                this.refreshTotal();
                this.change_cart_open = false;
                this.$refs['product_temp'].value = '';
                if (e)
                    e.preventDefault();
            },
            cartChangeSave(e) {
                this.update();
                e.preventDefault();
            },
            increment(e) {
                if (!this.change_cart_open) {
                    this.$refs['product_temp'].value = JSON.stringify(this.products);
                }
                this.change_cart_open = true;
                let index = this.products.find(_=>_.id===e.currentTarget.getAttribute("data-id"));
                index.quantity++;
                e.preventDefault();
            },
            decrement (e) {
                let index = this.products.find(_=>_.id===e.currentTarget.getAttribute("data-id"));
                if (index.quantity > 1) {
                    if (!this.change_cart_open) {
                        this.$refs['product_temp'].value = JSON.stringify(this.products);
                    }
                    this.change_cart_open = true;
                    index.quantity--;
                }
                e.preventDefault();
            },
            /*----- \cart-change\ -----*/

            clearCart(e) {
                let form_data = this.products;
                let cart = this.cart_token;
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.$url_api + 'api/store/vtex-clear-cart-full', {cart_token: cart, products: form_data},{ headers: headers })
                    .then(response => {
                        console.log(response);
                        localStorage.removeItem('cart_token');
                        localStorage.removeItem('cart_store');
                        this.update_cart_products(null);
                        this.shippingGroups = [];
                        this.products = [];
                        this.shoppingCart = [];
                        this.total = 0;
                        this.total_temp = 0;
                        this.point_value = 0;
                        this.refreshTotal();
                        this.$forceUpdate();
                        if (e)
                            e.preventDefault();
                    });
            },
            update()
            {
                this.$app.wait(true);
                let form_data = this.products;
                let cart = this.cart_token;
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.$url_api + 'api/store/vtex-update-cart', {cart_token: cart, products: form_data}, { headers: headers })
                    .then(response => {
                        this.updateProductResponse(response);
                        this.change_cart_open = false;
                        this.refreshTotal();
                        this.$forceUpdate();
                        this.$app.wait(false);
                    });
            },

            refreshTotal() {
                let c = 0;
                let subtotal = 0;
                for (let o in this.products) {
                    c += this.products[o].amount;
                    subtotal += (this.products[o].price) * (this.products[o].quantity);
                }
                console.log('Total dos produtos => ', subtotal);
                console.log('Total da compra => ', c);
                if(c < subtotal) {
                    this.hasDiscount = true;
                } else {
                    this.hasDiscount = false;
                }
                this.total = Number(c).toFixed(2);
                this.subtotal = Number(subtotal).toFixed(2);
                //this.pointInputHandler();
            },
            del (e) {
                this.$app.wait(true);
                let product_id = e.currentTarget.getAttribute("data-id");
                let form_data = this.products;
                let cart = this.cart_token;
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.$url_api + 'api/store/vtex-clear-product', {cart_token: cart, products: form_data, product: product_id}, { headers: headers })
                    .then(response => {
                        this.updateProductResponse(response);
                        this.change_cart_open = false;
                        this.refreshTotal();
                        this.$forceUpdate();
                        this.$app.wait(false);
                    });
                e.preventDefault();
            },
            updateCartLocal(e) {
                this.shoppingCart = this.products;
                this.$forceUpdate();
                this.refreshTotal();
                this.point_value = 0;
                this.change_cart_open = false;

                if (e)
                    e.preventDefault();
            },
           
            checkOutStart(e) {
                e.preventDefault();
                this.$parent.cart = false;
                this.$parent.user_address = true;

                /*
                if (this.delivery == 'locker') {
                    this.count = 0;
                    this.getStock();
                } else {
                    this.checkout(e);
                }
                */
            },
            checkout (e) {
                if (this.total > 0) {
                    this.$parent.wait(true);
                    let form_data = new FormData();
                    form_data.append('token',      this.token);
                    form_data.append('id',         this.id);
                    form_data.append('email',      this.email);
                    form_data.append('first_name', this.first_name);
                    form_data.append('last_name',  this.last_name);
                    form_data.append('product_id', this.products_ids.join(","));
                    form_data.append('product_n',  this.products_n.join(","));
                    form_data.append('orderId',    this.products[0].orderId);
                    form_data.append('delivery_type', this.delivery);
                    form_data.append('total',      this.total);
                    form_data.append('locationId', this.location_id);
                    form_data.append('_paymentWithPoints', document.getElementById('remember').checked);

                    axios.post(this.$url_api + 'store/oracle/checkout', form_data).then(response => this.checkoutResponse(response));
                } else {
                    alert(this.$ml.get('cart.alert_value_pay'));
                }
                if (e)
                    e.preventDefault();
            },
            updateProductResponse({data}){
                data = data.data;
                this.update_cart_token(data.orderFormId);
                var products = data.products.map(function(item) {
                    return {
                        'id': item.id,
                        'quantity': item.quantity,
                        'description': '',
                        'price': item.price/100,
                        'amount': item.priceDefinition/100,
                        'files': item.imageUrl,
                    }
                });

               this.update_cart_products_array(products);
            },
            checkoutResponse(obj) {
                this.$parent.wait(false);
                if (obj.data.errorCode)
                {
                    this.$parent.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    this.update_cart_products(null);
                    this.shippingGroups = [];
                    this.shoppingCart = [];
                    if (obj.data.uuid) {
                        if (this.point_checked) {
                            this.deductPoints();
                        } else {
                            //this.$router.push('/sucesso');
                            this.$parent.cart = false;
                            this.$parent.success = true;
                        }
                    } else {
                        alert(this.$ml.get('cart.alert_error_buy'));
                    }
                }
            }
        },
        mounted() {
            var ios = this.checkIOS;
            var android = this.checkMobile;
            if(ios || android) {
                this.isMobile = true;
            }
            this.shippingGroups = [];
            this.shoppingCart = [];
            this.total = 0;
            this.subtotal = 0;
            this.total_temp = 0;
            this.point_value = 0;

            /*
            for (let o in this.products)
            {
                //this.total += (this.products[o].price * this.products[o].quantity);
            }
            */
            
            this.shoppingCart = this.products;
            this.refreshTotal();
        }
    }
</script>

<style scoped>
    #btn-payments
    {
        box-sizing: border-box;
        margin: 10px 15px;
        padding: 15px;
        width: auto;
    }
    #locker-address
    {
        background-color: #f5f5f5;
        border-radius: 10px;
        box-sizing: border-box;
        display: none;
        padding: 10px;
    }
    #cart
    {
        background-color: rgba(0, 0, 0, .6);
        color: black;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    #cart #cart-container::-webkit-scrollbar {
        width: 8px;
    }

    #cart #cart-container::-webkit-scrollbar-track {
        background: rgba(0,0,0,.2);
    }

    #cart #cart-container::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.2);
    }
    #cart-container
    {
        display: block;
        margin:  auto;
        max-height: 80%;
        /* max-width: 800px; */
        overflow-y: auto;
        background: white;
        padding: 25px;
    }
    #cart-container > ul
    {
        box-sizing: border-box;
        padding: 15px 0;
        list-style: none;
    }
    #cart-container > ul > li > ul > li
    {
        box-sizing: border-box;
        padding: 10px 15px;
        vertical-align: middle;
    }
    #cart-container > ul > li > ul > li:nth-child(1)
    {
        width: 10%;
    }
    #cart-container > ul > li > ul > li:nth-child(1) img
    {
        border: solid 1px #e5e5e5;
        border-radius: 10px;
    }
    #cart-container > ul > li > ul > li:nth-child(2)
    {
        width: 29%;
    }
    #cart-container > ul > li > ul > li:nth-child(3)
    {
        width: 22%;
    }
    #cart-container > ul > li > ul > li:nth-child(4)
    {
        width: 13%;
    }
    #cart-container > ul > li > ul > li:nth-child(5)
    {
        width: 13%;
    }
    #cart-container > ul > li > ul > li:nth-child(6)
    {
        width: 6%;
    }


    /*----- stores -----*/
    #stores
    {
        background-color: rgba(0, 0, 0, .7);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    #stores-container
    {
        height: auto;
        left: calc(50% - 225px);
        padding: 25px;
        position: fixed;
        top: 100px;
        width: 450px;
    }
    #stores-container > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        padding: 10px 0;
    }
    #stores-container select
    {
        width: 100%;
    }
    /*----- \stores\ -----*/




    /*----- cart-container -----*/
    #cart-container > ul > li > ul > li > ul > li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 10px 0px;
        vertical-align: top;
    }

    /*----- \cart-container\ -----*/


    #cart-controller
    {
        box-sizing: border-box;
        padding: 10px;
        text-align: center;
        width: 100%;
    }
    #cart-controller > ul > li
    {
        box-sizing: border-box;
        display: inline-block;
        padding: 0 5px;
        width: calc((100% / 2) - 30px);
    }


    /*----- cart-change-open -----*/
    #cart-change-open
    {
        text-align: center;
    }
    #cart-change-open > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        display: inline-block;
        padding: 5px;
        text-align: center;
    }
    .cart-change-blocked
    {
        opacity: .3;
        pointer-events: none;
    }
    /*----- cart-change-open -----*/

    #myCartCounter {
        font-size: 16px;
        color: #4e4e4e9a;
        margin: 1px 4px;
    }
    
    /*----- Cart Details -----*/
    #cart-details {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
    }

    #cart-details p {
        font-size: 15px;
    }

    /*----- cart-info -----*/
    #cart-info > ul > li
    {
        display: inline-block;
        vertical-align: top;
    }
    #cart-info > ul > li:nth-child(1) > ul > li > button
    {
        border-radius: 10px;
        margin: 5px;
        width: calc(50% - 20px);
    }
    #cart-info > ul > li > ul >  li > ul > li:nth-child(odd)
    {
        text-align: right;
    }

    #cart-info > ul > li > ul >  li > ul > li > span
    {
        font-size: 10px;
    }
    /*----- \cart-info\ -----*/


    h1
    {
        box-sizing: border-box;
        text-align: center;
        padding: 10px 0;
    }
    h3
    {
        font-weight: normal;
    }

    #btn-close
    {
        box-sizing: border-box;
        float: right;
        padding: 15px;
    }
    .info{
        box-sizing: border-box;
        padding: 10px;
    }
    .active
    {
        border: none;
        color: white !important;
        background-color: #fea044;
    }

    #vtex .decrement, .increment
    {
        color: black !important;
        display: block;
        font-size: 30px;
        outline: none;
        text-align: center;
        user-select: none;
    }
    #vtex #product-count
    {
        color: var(--buttons_color);
        font-size: 35px;
        height: auto;
        font-weight: bold;
        margin: 0 5px;
    }
    @media screen and (max-width: 850px)
    {
        #cart-container
        {
            width: calc(100% - 40px);
        }
        #cart-container > ul > li
        {
            border-bottom: solid 1px #e5e5e5;
        }
        #cart-container > ul > li > ul > li
        {

            display: block;
            text-align: center;
            width: 100% !important;
        }
        #cart-container > ul > li > ul > li > img
        {
            width: 150px;
        }

        #cart-info > ul > li
        {

            display: block;
            text-align: center;
            width: 100% !important;
        }
        #cart-info > ul > li:nth-child(1) > ul > li > button
        {
            width: 100%;
        }

        .icon-trash
        {
            border: solid 1px #ccc;
            box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
            box-sizing: border-box;
            display: inline-block;
            padding: 10px;
        }
    }
    .product-list
    {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
</style>