<template>
    <div id="resetPassword" ref="login" class="ui translucent suimodal"
         :class="{ mini: !checkMobile, overlay : checkMobile, fullscreen : checkMobile } ">
        <i class="close icon"/>
        <div class="header">
            {{ $t('rememberpassword.esqueceuSuaSenha') }}
        </div>
        <div class="content">
            <form id="login-form" class="ui form" ref="remember-password-form" @submit.prevent="send">
                <div class="field">
                    <input id="email" ref="email" v-model="logIn.email_" name="email" class="text" type="text"
                           :placeholder="$t('rememberpassword.seuEmail')">
                </div>
                <div v-if="show_captcha && this.switch.captcha_client" class="field" ref="recaptcha_parent">
                    <vue-recaptcha ref="recaptcha" :sitekey="this.switch.captcha_client"
                                   @render="checkCaptcha = false" @verify="checkCaptcha = true;"
                                   @expired="checkCaptcha = false;"/>
                </div>
                <button v-if="show_captcha && !checkCaptcha" ref="btn-submit" class="fluid ui button positive" type="button"
                        disabled v-text="btn_submit_value"/>

                <button v-else ref="btn-submit" class="fluid ui button positive" type="submit"
                        v-text="btn_submit_value"/>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { VueRecaptcha } from 'vue-recaptcha';
import { mapGetters } from "vuex";
export default {
    name: 'RememberPassword',
    computed: {
        ...mapGetters(
          ['switch', 'checkMobile']
        ),
        show_captcha(){
            return (this.switch && this.switch.captcha && this.switch.captcha == 1)
        },
        logIn() {
            var response = this.$store.getters['signup/cadastro']
            return response
        },
    },
    data() {
        return {
            btn_submit      : false,
            btn_submit_value: this.$i18n.t('rememberpassword.reiniciarSenha'),
            error           : null,
            message         : null,
            captcha:null,
            checkCaptcha: false,
            modalShow: false,
            isSubmitting: false // Verificação de envio duplicado
        }
    },
    components: {
        VueRecaptcha
    },
    methods: {
        block(v) {
            this.btn_submit_value = (v ? (this.$i18n.t('rememberpassword.aguarde')) : this.$i18n.t('rememberpassword.entrar'));
            this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
            this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
        },
        send(e) {
            this.error = null;

            const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9.])+\.([A-Za-z]{2,})$/;

            if (!emailRegex.test(this.$refs["email"].value)) this.error = this.$i18n.t('rememberpassword.enderecoInvalido');

            if (this.error) {
                e.preventDefault();
                window.$.toast({
                    position: 'top right',
                    title: this.$i18n.t('resetpassword.erro'),
                    message: this.$i18n.t('rememberpassword.enderecoInvalido'),
                    showProgress: 'bottom',
                    classProgress: 'white',
                    className: {
                        toast: ' ui toast transparent-toast'
                    },
                    displayTime: 6000,
                    showIcon: 'times',
                    closeOnClick: true
                });
                return;
                // this.$app.modal('error', this.$i18n.t('rememberpassword.erro'), this.error, null, null, null, "OK");
            } else {
                this.sendRemember(e);
            }
        },
        async sendRemember(e) {
            if (this.isSubmitting) return;
            this.isSubmitting = true;

            if (!this.submit) {
                this.block(true);
                this.$app.analytics('forgot_password');

                let form_data = new FormData(this.$refs["remember-password-form"]);

                try {
                    const res = await axios.post(this.$url_api + 'api/resetpwd', form_data,{headers:{'language': this.$i18n.locale,}});
                    this.rememberResponse(res);
                } catch (error) {
                    this.rememberError(error.response);
                }
            }

            e.preventDefault();
            this.isSubmitting = false;
        },
        rememberResponse() {
            window.$.toast({
                position: 'top right',
                title: this.$i18n.t('rememberpassword.tituloResetado'),
                message: this.$i18n.t('rememberpassword.mensagemResetada'),
                showProgress: 'bottom',
                classProgress: 'white',
                className: {
                    toast: ' ui toast transparent-toast'
                },
                displayTime: 60000,
                showIcon: 'check',
                closeOnClick: true
            });
            this.block(false);
        },
        rememberError(obj) {
            this.block(false);
            let str = '';
            for (let o in obj.data.errors) {
                str += obj.data.errors[o] + '\n';
            }
            this.error = str;
            this.$app.modal('error', this.$i18n.t('rememberpassword.erro'), this.error, null, null, null, "OK");
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
                this.checkCaptcha = false
            }
        },
        rescaleCaptcha() {
            if (this.$refs.recaptcha) {
                let width = this.$refs.recaptcha_parent?.offsetWidth??300;
                let scale = width / 302;
                this.$refs.recaptcha.$refs.root.style.transform = 'scale(' + scale + ')';
                this.$refs.recaptcha.$refs.root.style.transformOrigin = '0 0';
                this.$refs.recaptcha.$refs.root.style.webkitTransform = 'scale(' + scale + ')';
                this.$refs.recaptcha.$refs.root.style.webkitTransformOrigin = '0 0';
            }
        },
        openModal() {
            const root = this
            if (this.$app.modal_RememberPassword && !this.show_captcha)
                this.checkCaptcha = true;
            window.$('#resetPassword').suimodal({
                dimmerSettings: {
                    opacity: 0.5
                },
                observeChanges: true,
                closable: true,
                allowMultiple: true,
                onShow() {
                },
                onHidden() {
                    root.$router.push({ name: 'Login' });
                },
                onVisible() {
                    root.$refs['email'].focus();
                    root.rescaleCaptcha(true);
                }
            }).suimodal('show');
            window.$(window).on('resize', this.rescaleCaptcha);
        }
    },
    mounted() {
        const root = this;
        if(!root.modalShow) {
            root.openModal();
            root.modalShow = true;
        }
    },
    updated() {
        if (this.$app.modal_RememberPassword && !this.show_captcha)
            this.checkCaptcha = true;
        this.btn_submit_value = this.$i18n.t('rememberpassword.reiniciarSenha');
    },
    beforeDestroy() {
        window.$(window).off('resize', this.rescaleCaptcha);
    }
}
</script>

<style lang="scss" scoped>
#resetPassword.suimodal {
    padding: 64px 44px;

    .icon.close {
        top: 54px;
        right: 44px;
        color: #fff;
    }

    .text-left {
        text-align: left;
    }
}
</style>
<style lang="scss">
.transparent-toast {
    background-color: rgba(0, 0, 0, 0.8) !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25) !important;
    backdrop-filter: blur(20px) !important;
    border-radius: 20px !important;
    padding: 20px;
    display: flex;
    z-index: 9999 !important;
    .content, .header {
        color: white !important;
    }
    .header {
        margin-bottom: 7px !important;
    }
    .check {
        color: darkgreen;
        margin-top: 0px;
        margin-right: 25px;
    }
    .times {
        color: darkred;
        margin-top: 0px;
        margin-right: 25px;
    }
}
.ui.attached.active.progress.white.bottom.compact {
    width: 90% !important;
    position: absolute !important;
    left: 50% !important;
    transform: translate(-50%) !important;
}

</style>
