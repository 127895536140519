<template>
    <div class="campaigns-wrapper operator" :style="typeof configuration.bg === 'object' && configuration.bg.hasOwnProperty('url') ? { 'background-image': 'url(' + configuration.bg.url + ')' } : { 'background-image': '' }">

        <!-- HEAD -->
        <header v-if="(typeof configuration.banner_desk === 'object' && configuration.banner_desk.hasOwnProperty('url')) || (typeof configuration.banner_mobi === 'object' && configuration.banner_mobi.hasOwnProperty('url'))" class="header">
            <img v-if="(typeof configuration.banner_desk === 'object' && configuration.banner_desk.hasOwnProperty('url'))" :src="configuration.banner_desk.url" />
            <img v-if="(typeof configuration.banner_mobi === 'object' && configuration.banner_mobi.hasOwnProperty('url'))" class="mobi" :src="configuration.banner_mobi.url" />
        </header>
        <!-- /HEAD -->

        <section class="main padding-v">
            <div class="container">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="main-wrapper">

                            <div class="detail-wrapper"><img src="/assets/images/campaigns/apito.png" /></div>

                            <h1>INSCRIÇÃO DO PROFESSOR</h1>
                            <div v-if="!success" class="form-wrapper">

                                <h2 class="liners">DADOS PESSOAIS</h2>
                                
                                <form ref="subscribe" id="subscribe" method="post" @submit.prevent="submitter">

                                    <!-- NAME -->
                                    <div class="form__input icons">
                                        <span class="icon-wrap"><i class="fas fa-user"></i></span>
                                        <input v-model="form.name" @focusout="errorHandler" required data-check="1" type="text" name="name" placeholder="Nome Completo" value="" />
                                        <span class="form__error"></span>
                                    </div>

                                    <!-- EMAIL -->
                                    <div class="form__input icons">
                                        <span class="icon-wrap"><i class="fas fa-envelope"></i></span>
                                        <input v-model="form.email" @focusout="errorHandler" required data-check="1" type="email" name="email" placeholder="E-mail" value="" />
                                        <span class="form__error"></span>
                                    </div>

                                    <!-- GENDER -->
                                    <div class="form__input icons">
                                        <span class="icon-wrap"><i class="fas fa-venus-mars"></i></span>
                                        <select v-model="form.gender" required data-check="1" name="gender">
                                            <option value="" disabled selected hidden>Gênero?</option>
                                            <option value="Masculino">Masculino</option>
                                            <option value="Feminino">Feminino</option>
                                            <option value="Prefiro não responder">Prefiro não responder</option>
                                        </select>
                                        <span class="form__error"></span>
                                    </div>

                                    <!-- BIRTH -->
                                    <div class="form__input icons">
                                        <span class="icon-wrap"><i class="fas fa-calendar-alt"></i></span>
                                        <input v-model="form.birth_date" @focusout="errorHandler" required data-check="1" type="text" v-mask="'##/##/####'" name="birth_date" placeholder="Data de Nascimento" value="" />
                                        <span class="form__error"></span>
                                    </div>

                                    <!-- PHONE -->
                                    <div class="form__input icons">
                                        <span class="icon-wrap"><i class="fas fa-mobile-alt"></i></span>
                                        <input v-model="form.phone" @focusout="errorHandler" required data-check="1" type="text" v-mask="'(##) # ####-####'" name="phone" placeholder="Celular" value="" />
                                        <span class="form__error"></span>
                                    </div>

                                    <!-- CPF -->
                                    <div class="form__input icons">
                                        <span class="icon-wrap"><i class="fas fa-id-card"></i></span>
                                        <input v-model="form.cpf" @focusout="errorHandler" required data-check="1" type="text" v-mask="'###.###.###-##'" name="cpf" placeholder="CPF" value="" />
                                        <span class="form__error"></span>
                                    </div>

                                    <!-- ACTING -->
                                    <!-- <div class="form__input w-100 icons">
                                        <span class="icon-wrap"><i class="fas fa-briefcase"></i></span>
                                        <input v-model="form.acting" @focusout="errorHandler" required data-check="1" type="text" name="acting" placeholder="Atuação" value="" />
                                        <span class="form__error"></span>
                                    </div> -->

                                    <!-- TEAM -->
                                    <div class="form__input w-100 icons">
                                        <span class="icon-wrap"><i class="fas fa-basketball-ball"></i></span>
                                        <select v-model="form.nba_team" name="nba_team">
                                            <option value="" disabled selected hidden>Para qual time você torce?</option>
                                            <option v-for="(team, index) in teams" :key="index" :value="team.label">{{ team.label }}</option>
                                        </select>
                                        <span class="form__error"></span>
                                    </div>


                                    <!-- ADDRESSES -->
                                    <div class="address-wrapper" v-for="address in addresses" :key="address" :data-index="address" :class="address">

                                        <button v-if="address>0" type="button" class="del-address" @click="delAddress(address)"><i class="fas fa-times"></i></button>

                                        <div class="form__input w-100 icons">
                                            <span class="icon-wrap"><i class="fas fa-school"></i></span>
                                            <input @focusout="errorHandler" required data-check="1" type="text" :name="'addresses['+address+'][title]'" placeholder="Escola" value="" />
                                            <span class="form__error"></span>
                                        </div>

                                        <div class="form__input icons">
                                            <span class="icon-wrap"><i class="fas fa-map-marker-alt"></i></span>
                                            <input @focusout="errorHandler" required data-check="1" type="text" :name="'addresses['+address+'][district]'" placeholder="Bairro" value="" />
                                            <span class="form__error"></span>
                                        </div>

                                        <div class="state-city">
                                            <div class="loader-spinner"></div>

                                            <div class="form__input smally icons">
                                                <span class="icon-wrap"><i class="fas fa-map-marker-alt"></i></span>
                                                <select required data-check="1" :name="'addresses['+address+'][state_id]'" @change="handleState($event, address)">
                                                    <option value="" disabled selected hidden>UF</option>
                                                    <option v-for="state in states" :key="state.id" :value="state.id">{{ state.uf }}</option>
                                                </select>
                                                <span class="form__error"></span>
                                            </div>

                                            <div class="form__input biggy icons">
                                                <span class="icon-wrap"><i class="fas fa-map-marker-alt"></i></span>
                                                <select class="findme" required data-check="1" :name="'addresses['+address+'][city_id]'" @change="errorHandler">
                                                    <option v-if="!cities[address]" value="" disabled selected hidden>Escolha um estado</option>
                                                    <option v-else value="" disabled selected hidden>Escolha uma cidade</option>
                                                    <option v-for="city in cities[address]" :key="city.id" :value="city.id">{{ city.name }}</option>
                                                </select>
                                                <span class="form__error"></span>
                                            </div>
                                            
                                        </div>

                                    </div>
                                    <!-- /ADDRESSES -->
                                    <button class="add-address" type="button" @click="addAddress">ADICIONAR ESCOLA</button>

                                    <!-- PASSWORD -->
                                    <div class="form__input icons">
                                        <span class="icon-wrap"><i class="fas fa-lock"></i></span>
                                        <input v-model="form.password" @focusout="errorHandler" required data-check="1" type="password" name="password" placeholder="Senha" value="" />
                                        <span class="form__error"></span>
                                    </div>

                                    <!-- PASSWORD 2 -->
                                    <div class="form__input icons">
                                        <span class="icon-wrap"><i class="fas fa-lock"></i></span>
                                        <input v-model="form.password_confirmation" @focusout="errorHandler" required data-check="1" type="password" name="password_confirmation" placeholder="Repetir senha" value="" />
                                        <span class="form__error"></span>
                                    </div>

                                    <div class="w-100 disclaimer">
                                        <p>Para uma senha segura, certifique-se de incluir pelo menos 8 caracteres, dentre eles números, letra maiúscula e caracteres especiais.</p>
                                    </div>

                                    <!-- ACCEPT -->
                                    <div class="form__checkbox">
                                        <input type="checkbox" required data-check="1" name="accept_optins" value="1" />
                                        <!-- <p>Eu aceito os <a @click="openModal('#terms')">JR. NBA* e seus Termos e Política de Privacidade.</a></p> -->
                                        <p>Eu aceito os Termos JR. NBA* e seus <a href="https://www.nba.com/termsofuse" target="_blank">Termos</a> e <a href="https://www.nba.com/privacy-policy" target="_blank">Política de Privacidade</a>.</p>
                                        <span class="form__error"></span>
                                    </div>

                                    <!-- NEWSLETTER -->
                                    <div class="form__checkbox">
                                        <input type="checkbox" name="newsletter" value="1" />
                                        <p>Use minhas informações pessoais registradas na JR. NBA e me envie notificação sobre produtos e iniciativas da JR. NBA.</p>
                                    </div>

                                    <div id="form__response"></div>
                                    <div class="form__submit">
                                        <button type="submit" id="form-subscribe-submitter" class="btn w-loader">
                                            <span>FINALIZAR</span>
                                            <div class="loader-spinner"></div>
                                        </button>
                                    </div>

                                </form>
                            </div>

                            <div v-else class="success-wrapper">
                                <div class="icon-wrapper"><i class="fas fa-check"></i></div>
                                <div class="info-wrapper">
                                    <h4>SUCESSO</h4>
                                    <p>Sua inscrição foi inicializada!<br/>Você receberá um e-mail quando for aprovado.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>

            <!-- MODAL PP & TERMS -->
            <div id="terms" class="ui suimodal">
                <div class="header">
                    Termos e Condições
                </div>
                <div class="content">
                    Lorem Ipsum
                </div>
            </div>
        </section>


        <section v-if="configuration.sponsors.length > 0" class="sponsors padding-v">
            <div class="container">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <h2>PATROCINADOR OFICIAL</h2>
                        <div class="sponsors-wrapper">
                            <div class="img-wrapper" v-for="(sponsor, index) in configuration.sponsors" :key="index">
                                <img :src="sponsor.url" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </section>


        <footer v-if="configuration.footer_email" class="footer">
            <p>PRECISA DE AJUDA? <span class="theButton" @click.prevent="openEmail">FALE CONOSCO</span></p>
        </footer>


    </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
    name: "CampaignOperator",
    computed: {},
    data() {
        return {
            success                     : false,
            states                      : {},
            cities                      : [],
            addresses                   : [0],
            configuration               : {
                footer_email    : '',
                bg              : [],
                banner_desk     : [],
                banner_mobi     : [],
                sponsors        : [],
            },
            form: {
                name                    : '',
                email                   : '',
                gender                  : '',
                phone                   : '',
                cpf                     : '',
                birth_date              : '',
                acting                  : '',
                password                : '',
                password_confirmation   : '',
                nba_team                : '',
            },
            fieldErrors: {
                name                    : 'Escreva seu nome completo.',
                email                   : 'Escreva um e-mail válido.',
                gender                  : 'Selecione seu gênero.',
                phone                   : 'Escreva seu telefone com DDD.',
                cpf                     : 'Escreva um CPF válido.',
                birth_date              : 'Escreva uma data válida (dd/mm/aaaa).',
                acting                  : 'Escreva sua atuação.',
                accept_optins           : 'Você deve concordar com os termos e política de privacidade.',
                nba_team                : 'Selecione seu time favorito.',
                addresses_title         : 'Escreva o nome da Escola.',
                addresses_district      : 'Escreva o Bairro.',
                addresses_state_id      : 'Selecione um Estado.',
                addresses_city_id       : 'Selecione uma Cidade.',
                password                : 'A senha deve conter no mínimo 8 caracteres.',
                password_confirmation   : 'Você deve repetir a senha.',
            },
            teams: [
                { "label": "Atlanta Hawks" },
                { "label": "Boston Celtics" },
                { "label": "Brooklyn Nets" },
                { "label": "Charlotte Hornets" },
                { "label": "Chicago Bulls" },
                { "label": "Cleveland Cavaliers" },
                { "label": "Dallas Mavericks" },
                { "label": "Denver Nuggets" },
                { "label": "Detroit Pistons" },
                { "label": "Golden State Warriors" },
                { "label": "Houston Rockets" },
                { "label": "Indiana Pacers" },
                { "label": "LA Clippers" },
                { "label": "Los Angeles Lakers" },
                { "label": "Memphis Grizzlies" },
                { "label": "Miami Heat" },
                { "label": "Milwaukee Bucks" },
                { "label": "Minnesota Timberwolves" },
                { "label": "New Orleans Pelicans" },
                { "label": "New York Knicks" },
                { "label": "Oklahoma City Thunder" },
                { "label": "Orlando Magic" },
                { "label": "Philadelphia 76ers" },
                { "label": "Phoenix Suns" },
                { "label": "Portland Trail Blazers" },
                { "label": "Sacramento Kings" },
                { "label": "San Antonio Spurs" },
                { "label": "Toronto Raptors" },
                { "label": "Utah Jazz" },
                { "label": "Washington Wizards" }
            ],
        };
    },
    beforeMount() {
        // let vm = this;
        document.body.classList.add('campaigns');
    },
    mounted() {
        let vm = this;
        vm.$app.wait(true);
        // vm.getStates();
        vm.getConfig();
    },
    methods: {
        getConfig() {
            let vm = this;
            let headers = {
                "Content-Type": "application/json;charset=UTF-8",
                Accept: "application/json",
                language: this.$i18n.locale,
            };
            axios.get(vm.$url_api + "api/campaigns-config", headers)
                .then((response) => {
                    // console.log("CONFIG => ", response.data.data.config);
                    vm.configuration = response.data.data.config;
                    vm.getStates();
                    // console.log("CONFIG => ", vm.configuration);
                })
                .catch((error) => {
                    console.log("ERROR CONFIG => ", error);
                })
                .finally(() => {
                    vm.$app.wait(false);
                });
        },
        getStates() {
            let vm = this;
            let headers = {
                "Content-Type": "application/json;charset=UTF-8",
                Accept: "application/json",
                language: this.$i18n.locale,
            };
            axios.get(vm.$url_api + "api/states", headers)
                .then((response) => {
                    vm.states = response.data.data.result;
                    // console.log("STATES => ", vm.states);
                })
                .catch((error) => {
                    console.log("ERROR STATES => ", error);
                    vm.states = null;
                })
                .finally(() => {
                    vm.$app.wait(false);
                });
        },
        getCities(index, state_id, fieldWrapper = null) {
            let vm = this;
            let headers = {
                "Content-Type": "application/json;charset=UTF-8",
                Accept: "application/json",
                language: this.$i18n.locale,
            };            
            if( fieldWrapper ) {
                fieldWrapper.classList.add('busy');
                const findmeCity = fieldWrapper.querySelector('.findme');
                findmeCity.value = '';
            }
            axios.get(vm.$url_api + "api/states/" + state_id, headers)
                .then((response) => {
                    vm.$set(vm.cities, index, response.data.data.result);
                    console.log("Cities => ", vm.cities);
                })
                .catch((error) => {
                    // vm.cities.splice(index, 1);
                    console.log("ERROR CITIES => ", error);
                })
                .finally(() => {
                    if( fieldWrapper ) { fieldWrapper.classList.remove('busy'); }
                });
        },
        handleState(e, index) {
            let vm              = this;
            const fieldName     = e.target.name;
            const fieldValue    = e.target.value;
            const fieldWrapper  = e.target.closest('.address-wrapper');
            const match         = fieldName.match(/\d+/);
            if( match && fieldValue ) {
                vm.errorHandler(e);
                vm.getCities(index, fieldValue, fieldWrapper);
            }
        },
        addAddress() {
            let vm = this;
            const last = vm.addresses[vm.addresses.length - 1];
            vm.addresses.push(last + 1);
        },
        delAddress(number) {
            let vm = this;
            // console.log('ADDRESS NUMBER', number);
            // console.log('ADDRESS BEFORE', vm.addresses);
            const indexToRemove = vm.addresses.indexOf(number);
            if (indexToRemove !== -1) {
                vm.addresses.splice(indexToRemove, 1);
            }
            // console.log('ADDRESS AFTER', vm.addresses);
        },
        submitter() {

            const vm            = this;
            const form          = document.getElementById('subscribe');
            const formSubmitter = document.getElementById('form-subscribe-submitter');
            const formResponse  = $('#form__response');


            // const formData = new FormData(this.$refs.subscribe);  // get the form data
            // formData.append('hash', vm.hash);                     // append hash for API
            // console.log('FORM DATA =>', formData);


            if( form && !form.classList.contains('busy') ) {

                form.classList.add('busy');                           // add busy class to show loading
                formSubmitter.classList.add('disabled');              // prevent multiple clicks
                formResponse.html('');                                // clean api response errors

                // check for errors
                if( vm.submitterCheckError(form) > 0 ) {              // check for errors
                    form.classList.remove('busy');                      // remove busy to hide the loading
                    formSubmitter.classList.remove('disabled');         // remove class so user can try again
                    return false;
                }

                const formData = new FormData(this.$refs.subscribe);  // get the form data
                formData.append('hash', vm.hash);                     // append hash for API
                // console.log('FORM DATA =>', formData);
                const headers = {
                    "Content-Type": "application/json;charset=UTF-8",
                    Accept: "application/json",
                    language: vm.$i18n.locale,
                };
                axios.post(vm.$url_api + "api/campaigns/operator", formData, headers)
                    .then((response) => {
                        response;
                        // console.log("RESPONSE => ", response.data);
                        vm.success = true;
                    })
                    .catch((errors) => {
                        // console.log("ERROR SUBSCRIBER => ", errors);
                        console.log("ERROR RESPONSE ERRORS => ", errors.response.data.errors);  // good
                        console.log("ERROR RESPONSE DATA => ", errors.response.data.data);      // good
                        vm.showApiResponseErrors(formResponse, errors);
                    })
                    .finally(() => {
                        form.classList.remove('busy');                      // remove busy to hide the loading
                        formSubmitter.classList.remove('disabled');         // remove class so user can try again 
                    });

            }

        },
        submitterCheckError(form) {
            const fields      = form.querySelectorAll('[data-check="1"]');  // get all required fields
            let   formErrors  = 0;
            fields.forEach(field => {
                if (field) {
                    let event = { target: field };          // Create a synthetic event object with the field as target
                    if (!this.errorHandler(event)) {        // Call the errorHandler() function for each field
                        formErrors++;                       // Increment formErrors if an error is found
                    }
                }
            });
            // console.log('TOTAL ERRORS', formErrors);
            return formErrors;
        },
        errorHandler(e) {
            let vm            = this;
            let fieldName     = e.target.name;
            // START check if field is addresses
            let match = fieldName.match(/\[\d+\]\[\w+\]/);
            if( match ) {
                fieldName = fieldName.replace(/\[(\d+)\]\[(\w+)\]/, '_$2');
            }
            // END check if field is addresses
            // let fieldValue    = e.target.value;
            let fieldParent   = e.target.closest('.form__input, .form__checkbox');
            let fieldError    = fieldParent.querySelector('.form__error');
            let fieldFunction = 'validate' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);  // name of the function
            // console.log('FNAME',fieldName, fieldFunction);

            if( typeof vm[fieldFunction] === 'function' ) {             // check if it's a function
                if( !vm[fieldFunction](e.target) ) {                    // call the function (always bollan)
                    fieldParent.classList.add('error');                 // on error add error class
                    fieldError.textContent = vm.fieldErrors[fieldName]; // on error place the text
                    return false;
                } else {
                    fieldParent.classList.remove('error');              // on success remove error class
                    fieldError.textContent = '';                        // on success remove the text
                    return true;
                }
            }

        },
        showApiResponseErrors(formResponse, errors) {

            formResponse.append('<div class="response-wrapper"><div class="icon-wrapper"><i class="fas fa-exclamation-triangle"></i></div><div class="response"></div></div>');

            if( errors.response && errors.response.data && errors.response.data.errors ) {
                const errorData = errors.response.data.errors;
                for (let field in errorData) {
                    const errorMessages = errorData[field];
                    errorMessages.forEach(errorMessage => {
                        // console.log(`Error in field ${field}: ${errorMessage}`);
                        formResponse.find('.response').append('<p>'+errorMessage+'</p>');
                    });
                }
            }

            else if( errors.response && errors.response.data && errors.response.data.data.errors ) {
                const errorData = errors.response.data.data.errors;
                for (let field in errorData) {
                    formResponse.find('.response').append('<p>'+errorData[field]+'</p>');
                }
            }
            // else {
            //   // console.error("Unknown error occurred:", errors);
            //   formResponse.find('.response').append('<p>'+errors+'</p>');
            // }

        },
        openModal(modal){
            window.$(modal).suimodal('show');
        },
        validateName(target){
            return Boolean(String(target.value.trim()).length > 4 && String(target.value.trim()).split(' ').length > 1);
        },
        validateEmail(target) {
            return Boolean(/\S+@\S+\.\S+/.test(target.value));
        },
        validateGender(target) {
            return Boolean(String(target.value).length > 1);
        },
        validatePhone(target) {
            let numbers = target.value.replace(/\D/g, '');
            return Boolean(String(numbers).length > 9);
        },
        validateCpf(target) {
            let strCPF = target.value.replace(/\D/g, '');

            if (strCPF.length !== 11 || /^(\d)\1{10}$/.test(strCPF)) {
                return false;
            }

            let sum = 0;
            let rest = 0;

            for (let i = 0; i < 9; i++) {
                sum += parseInt(strCPF.charAt(i)) * (10 - i);
            }
            rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) rest = 0;
            if (rest !== parseInt(strCPF.charAt(9))) {
                return false;
            }

            sum = 0;
            for (let i = 0; i < 10; i++) {
                sum += parseInt(strCPF.charAt(i)) * (11 - i);
            }
            rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) rest = 0;
            return rest === parseInt(strCPF.charAt(10));
        },
        validateBirth_date(target) {
            // Regular expression to match date in format dd/mm/yyyy
            var dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

            // Check if the date string matches the regular expression
            if (!dateRegex.test(target.value)) {
                return false; // Date format is incorrect
            }

            // Extract day, month, and year parts from the date string
            var [, day, month, year] = target.value.match(dateRegex);

            // Convert day, month, and year to numbers
            day = parseInt(day, 10);
            month = parseInt(month, 10);
            year = parseInt(year, 10);

            // Check if month is valid (between 1 and 12)
            if (month < 1 || month > 12) {
                return false;
            }

            // Check if day is valid for the given month and year
            var maxDay = new Date(year, month, 0).getDate();
            if (day < 1 || day > maxDay) {
                return false;
            }

            // Check if year is valid
            if (year < 0 || year > 9999) {
                return false;
            }

            // Date is valid
            return true;
        },
        validateActing(target) {
            return Boolean(String(target.value).length > 1);
        },
        validateNba_team(target) {
            return Boolean(String(target.value).length > 1);
        },
        validatePassword(target) {
            return Boolean(String(target.value).length >= 8);
        },
        validatePassword_confirmation(target) {
            const password = document.getElementsByName("password")[0];
            if( target.value.length < 8 ) {
                return false;
            }
            return target.value === password.value;
        },
        validateAccept_optins(target) {
            return target.checked;
        },
        validateAddresses_title(target) {
            return Boolean(String(target.value).length > 1);
        },
        validateAddresses_district(target) {
            return Boolean(String(target.value).length > 1);
        },
        validateAddresses_state_id(target) {
            return Boolean(String(target.value).length >= 1);
        },
        validateAddresses_city_id(target) {
            return Boolean(String(target.value).length >= 1);
        },
        openEmail() {
            let vm = this;
            window.location.href = "mailto:"+vm.configuration.footer_email;
        },
    },
}
</script>
<style lang="scss">
body.campaigns{
    .detail-wrapper{
        margin: 20px auto 40px auto;
        text-align: center;
        max-width: 77px;
        img{
            width: 100%;
        }
    }
    h2.liners{
        font-family: "Action NBA Condensed", sans-serif;
        color: #fff;
        text-align: center;
        width: 100%;
        font-size: 50px;
        line-height: 40px;
        margin: -20px 0 30px 0;
        position: relative;
        &:before{
            content: '';
            position:absolute;
            top: 50%;
            left: 0;
            background: #fff;
            height: 1px;
            width:38%;
            opacity: .4;
        }
        &:after{
            content: '';
            position:absolute;
            top: 50%;
            right: 0;
            background: #fff;
            height: 1px;
            width:38%;
            opacity: .4;
        }
    }
    .add-address{
        flex-basis: 100%;
        position: relative;
        border: 2px solid rgba(0, 0, 0, 0);
        border-radius: 6px;
        padding: 15px 60px;
        text-align: center;
        margin: 0;
        line-height: 20px;
        font-size: 34px;
        color: #fff;
        font-weight: 400;
        background: #244FBD;
        outline: none;
        box-shadow: none;
        transition: all 0.3s ease;
        font-family: "Action NBA Condensed", sans-serif;
        margin-top: -15px;
    }
    .del-address{
        position: absolute;
        top: -13px;
        right: -13px;
        border-radius: 100%;
        border: 1px solid transparent;
        width: 26px;
        height: 26px;
        line-height: 0;
        color: #fff;
        background: #fe1230;
        box-shadow: 3px 0px 4px 0 rgba(0,0,0,.9);
    }
    .address-wrapper{
        display: flex;
        flex-basis: 100%;
        gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 25px;
        border: 2px solid #fff;
        border-radius: 6px;
        background: rgba(255,255,255,.1);
        position: relative;
        .form__input{
            margin:0;
        }
        .state-city{
            position: relative;
            display: flex;
            gap: 10px;
            // flex-direction: row;
            // flex-wrap: wrap;
            flex-basis: calc(50% - 10px);
            &:after{
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #000;
                z-index: 1;
                border-radius: 6px;
                transition: all .3s ease;
                opacity: 0;
                visibility: hidden;
            }
            .loader-spinner{
                border-width: 3px;
                width: 20px;
                height: 20px;
                margin-left: -10px;
                margin-top: -10px;
                z-index: 2;
                border-color:#777;
                border-top-color: #fff;
                border-right-color: #fff;
                border-bottom-color: #fff;
                left: auto;
                right: 20px;
                animation: ldr-spin 0.4s linear infinite;
                opacity: 0;
                visibility: hidden;
            }
            .smally{
                max-width: 100px;
                max-width: 120px;
                select{
                    padding: 15px 20px 15px 44px;
                }
            }
            .biggy{
                flex-basis: calc(50% + 99px);
            }
        }
        &.busy{
            .state-city{
                &:after{
                    opacity: .2;
                    visibility: visible;
                }
                .loader-spinner{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .campaigns-wrapper{
        &.operator{
            // background-image: url('/assets/images/campaigns/bg.jpeg');
            background-color: #222;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            &:before{
                display: none;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                // background: linear-gradient(180deg, rgba(23, 42, 91, 0.7) 0%, rgba(129, 20, 37, 0.7) 100%);
                background: linear-gradient(0deg, rgba(23, 42, 91, 0.60) 0%, rgba(23, 42, 91, 0.60) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%), url('/assets/images/campaigns/bg.jpeg') lightgray 50% / cover no-repeat;
            }
        }
    }
    .disclaimer{
        margin-top: -15px;
        p {
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            color: #fff;
            line-height: 20px;
        }
    }
    .sponsors{
        margin-top: 10px;
        border-top: 1px solid rgba(255, 255, 255, .4);
        position: relative;
        h2{
            font-family: "Action NBA Condensed", sans-serif;
            color: #fff;
            text-align: center;
            font-size: 60px;
            line-height: 60px;
            margin: 0 0 40px;
        }
        .sponsors-wrapper{
            display: flex;
            justify-content: center;
            gap: 45px;
        }
        .img-wrapper{
            position: inherit;
            img{
                position: inherit;
            }
        }
    }
    footer.footer{
        position: relative;
        background: #172A5B;
        padding: 30px 20px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.06em;
        p{
            font-family: "Action NBA Condensed", sans-serif;
            font-weight: 300;
            margin:0;
            font-size: 64px; 
            line-height: 54px;
        }
        span.theButton{
            background: transparent !important;
            font-family: "Action NBA Condensed", sans-serif;
            font-weight: 700;
            margin: 0;
            color: #fff;
            text-decoration: none;
            opacity: 1;
            display: inline-block;
            cursor: pointer;
        }
    }
}

// media queries (mobile last)
@media only screen and (max-width: 1470px) {

}

@media only screen and (max-width: 1200px) {
    body.campaigns{
        h2.liners{
            &:before{
                width:34%;
            }
            &:after{
                content: '';
                width:34%;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    body.campaigns{
        h2.liners{
            &:before{
                width:33%;
            }
            &:after{
                content: '';
                width:33%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    body.campaigns{
        .detail-wrapper{
            margin: 0px auto 30px auto;
            max-width: 60px;
        }
        h2.liners{
            &:before{
                width:30%;
            }
            &:after{
                width:30%;
            }
        }
        .address-wrapper{
            padding: 10px;
            .state-city{
                flex-basis: 100%;
                .biggy{
                    flex-basis: 100%;
                }
            }
        }
        .sponsors {
            .sponsors-wrapper{
                flex-wrap: wrap;
                gap: 30px;
                .img-wrapper{
                    flex-basis: 100%;
                    text-align: center;
                }
            }
        }
        footer.footer{
            p{
                font-size: 50px;
                line-height: 44px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    body.campaigns{
        h2.liners{
            &:before{
                width:23%;
            }
            &:after{
                content: '';
                width:23%;
            }
        }
    }
}

</style>