<template>
  <section class="page-banner slideshow"><!-- full -->
    <div class="page-banner-wrapper">

      <div class="swiper-container">
        <div class="swiper-wrapper">

          <!-- SLIDES -->
          <div class="swiper-slide" v-for="(slide, idx) in slideshowData.slides" :key="idx">
            <div class="media-wrap">
              <!-- IMG -->
              <div v-if="slide.type === 'image'" class="img-wrapper" :style="{ 'background-image': 'url(' + slide.file_url + ')' }"></div>
              <!-- VIDEO -->
              <video class="slideshow-video" v-if="slide.type === 'video'" playsinline loop muted autoplay poster=""><source :src="slide.file_url" type="video/mp4"></video>
              <!-- OVERLAY -->
              <div v-if="slide.content.use_color_filter" class="overlay" :style="{ 'background-color': slide.content.overlay_color, 'opacity': getSlideOverlayOpacity(slide.content)}"></div>
            </div>

            <a v-if="slide.content.is_slide_link" class="gen" :href="slide.content.slide_link" :target="getSlideLinkTarget(slide.content.slide_link_new)"></a>  <!-- LINK DO SLIDE INTEIRO -->
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="info-wrap">
                    <div class="infos">
                      <span v-if="slide.content.subtitle" class="sub-title">{{ slide.content.subtitle }}</span>
                      <h1 v-if="slide.content.title && idx === 0">{{ slide.content.title }}</h1>
                      <h2 v-if="slide.content.title && idx >= 1">{{ slide.content.title }}</h2>
                      <p v-if="slide.content.description" class="description-auto" v-html="makeHTML(slide.content.description)"></p>
                    </div>
                    <div class="actions" v-if="slide.content.is_slide_link === false">
                      <button class="btn watch" @click="goSlideLink(slide.content)">
<!--                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9V344c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z"/></svg>-->
                        {{ slide.content.button_label }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-load"><div></div></div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>

    </div>
  </section>
</template>

<script>

import Swiper from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import { makeHTML } from "../../../helper";

export default {
    name: "Slideshow",
    props: ['slideshowData'],
    setup() {
      const onSwiper = (swiper) => {
        swiper;
        // console.log(swiper);
      };
      const onSlideChange = () => {
        // console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
      };
    },
    mounted(){
      this.buildSwiper();
    },
    methods: {
      makeHTML,
      buildSwiper(){
        let root_ = this;
        if(root_.slideshowData === null ) { return; }
        // default options
        let options    = {
          slidesPerView       : root_.slideshowData?.config?.slidesPerView  ?? 1,
          spaceBetween        : root_.slideshowData?.config?.spaceBetween   ?? 0,
          navigation          : root_.slideshowData?.config?.navigation     ?? false,
          autoplay            : false,
          loop                : root_.slideshowData?.config?.loop           ?? false,
          allowTouchMove      : root_.slideshowData?.config?.draggable      ?? true,
          // autoHeight       : root_.slideshowData?.config?.autoHeight     ?? true,
          observer            : root_.slideshowData?.config?.observer       ?? true,
          pagination          : {
            el        : '.swiper-pagination-lms',
            clickable : root_.slideshowData?.pagination?.clickable ?? true,
            // type   : root_.slideshowData?.pagination?.type ?? 'bullets',
          },
          on                  : {
            init    : function () {
              // console.log('INIT slideshow');
              // // get all videos and force muted and pause
              // let videos          = document.getElementsByClassName("slideshow-video");
              // let firstSlide      = document.querySelectorAll('.swiper-slide-active[data-swiper-slide-index="0"]');
              // if( videos.length ) {
              //   for (var i = 0; i < videos.length; i++) {
              //     videos[i].pause();          // force pause
              //     videos[i].muted = true;     // force muted
              //   }
              //   // if has a video in the first slide, play it
              //   if( firstSlide.length ) {
              //     let video = firstSlide[0].querySelector('video');
              //     if( video.length ) { video.play(); }
              //   }
              // }
            },
            observerUpdate: function (swiper) {
              swiper;
              // console.log("observerUpdate DELME: ", swiper)
              // _root.updateSlide(swiper);
            },
            slideChange: function (swiper) {
              swiper;
              // console.log("slideChange DELME: ", swiper)
              // _root.updateSlide(swiper);
            },
            slideChangeTransitionEnd: function (swiper) {
              // console.log('slideChangeTransitionEnd', swiper);

              let videos        = document.getElementsByClassName("slideshow-video");
              let activeSlide   = document.querySelectorAll('.swiper-slide-active[data-swiper-slide-index="'+swiper.realIndex+'"]');

              if( videos.length ) {
                for (var i = 0; i < videos.length; i++) {
                  videos[i].pause();          // force pause
                  videos[i].muted = true;     // force muted
                }
                // if has a video in the first slide, play it
                if( activeSlide.length ) {
                  // let video = null;
                  // for (var ii=0; ii < activeSlide.length; ii++) {
                  //   if( activeSlide[ii].querySelector('video') ) {
                  //     video = activeSlide[ii].querySelector('video');
                  //     break;
                  //   }
                  // }

                  let video = activeSlide[0].querySelector('video');
                  if( video ) { video.play(); }
                }
              }
            },
          }
        };

        if(root_.slideshowData?.config?.autoplay){
          options.autoplay = {
            delay: root_.slideshowData?.config?.delay ?? 5000
          }
        }
        // todo: customized options for slider
        // let config    = $('#page-banner-config');
        // let is_drag   = config.attr('data-drag');
        // let is_loop   = config.attr('data-loop');
        // let is_auto   = config.attr('data-auto');
        // let is_delay  = config.attr('data-delay');
        // if( is_drag == '1' ) { options.allowTouchMove = true; }
        // if( is_loop == '1' ) { options.loop = true; }
        // if( is_auto == '1' ) {
        //   options.autoplay = {};
        //   options.autoplay.delay = is_delay;
        // }
        root_.swiper = new Swiper(".page-banner .swiper-container", options);
      },
      getSlideLinkTarget(slide_link_new) {
        return slide_link_new ? '_blank' : '_self';
      },
      getSlideOverlayOpacity(content) {
        return content?.overlay_opacity ?? 0.3;
      },
      goSlideLink(content) {
        if(content.button_link_new) {
          window.open(content.button_link, "_blank");
        } else {
          window.open(content.button_link, "_self");
        }
      }
    }
  }

</script>

<style lang="scss">
body.lms {

  // pagination
  .swiper-pagination-lms{
    top: -30px;
    position: relative;
  }
  .swiper-pagination-lms .swiper-pagination-bullet{
    width: 16px;
    height: 16px;
    background: #5F5F5F;
    margin: 0px 4px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }

  .swiper-pagination-lms .swiper-pagination-bullet.swiper-pagination-bullet-active,
  .swiper-pagination-lms .swiper-pagination-bullet.swiper-pagination-bullet-active:hover{
    background: #B0E000;
  }

  // pagination active
  .page-banner.slideshow .swiper-slide-active .info-wrap,
  .page-banner.slideshow .swiper-slide-active .info-wrap .infos{
    opacity: 1;
  }
}
</style>

<style lang="scss" scoped>

  @media (min-width: 1400px) {
    .lms-content .container {
      // max-width: 1470px;
      // max-width: 1200px;  // bootstrap oldschooll
    }
  }

  .description-auto{
    max-height: 168px;
    position: fixed;
    overflow: auto;
    max-width: 55%;
    margin-top: 120px !important;
    padding: 21px;
    border-radius: 12px;
    background-color: rgba(0,0,0, 0.65);
  }

  // core
  .page-banner{
    position: relative;
    min-height: 840px;
    // min-height: 400px;
    display: flex;
    flex-direction: column;
    background: #eee;
    box-shadow: inset 1px 1px 13px 1px rgba(0, 0, 0, .2);
  }
  .page-banner.full{
    min-height: 100vh;
    // min-height: calc(100vh - 34px);
  }
  .page-banner .page-banner-wrapper{
    height: 100%;
    flex: 1;
    display: grid;
    min-height: 100%;
  }
  .page-banner .container,
  .page-banner .row{
    height:100%;
  }
  .page-banner div[class^='col-'],
  .page-banner div[class*=' col-']{
    align-content: center;
    display: flex;
    align-items: center;
  }


  // swiper
  .page-banner.slideshow .swiper-container{
    height: 100%;
    width: 100%;
  }
  .page-banner.slideshow .swiper-load {
    display: none;
  }


  // media (bg)
  .page-banner .media-wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .page-banner .media-wrap .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000;
    opacity: .3;
  }
  .page-banner .media-wrap video {
    position: relative;
    left: 50%;
    top: 50%;
    display: block;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  .page-banner .media-wrap .img-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }


  // infos
  .page-banner .info-wrap{
    width: 50%;
    // margin: 100px 0;
    margin-bottom: 250px;
    margin-bottom: 270px;
    margin-top: 100px;
    -webkit-transition: all 2.3s ease;
    -moz-transition: all 2.3s ease;
    -ms-transition: all 2.3s ease;
    -o-transition: all 2.3s ease;
    transition: all 2.3s ease;
  }
  .page-banner .info-wrap .infos{
    max-width: 100%;
    color: #fff;
    -webkit-transition: all 2.3s ease;
    -moz-transition: all 2.3s ease;
    -ms-transition: all 2.3s ease;
    -o-transition: all 2.3s ease;
    transition: all 2.3s ease;
  }
  .page-banner .info-wrap h1,
  .page-banner .info-wrap h2{
    font-family: 'Space Grotesk', sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 13px;
  }
  .page-banner .info-wrap .sub-title{
    font-family: 'Space Grotesk', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    width: 100%;
    display: inline-block;
    text-align: left;
  }
  .page-banner .info-wrap p{
    font-family: 'Hanken Grotesk';
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    margin-top: 20px;
  }


  // actions
  .actions {
    display: flex;
    gap: 18px;
    align-items: baseline;
    margin-top: 37px;

    .btn {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        fill:#ffffff;
      }
      &.watch {
        background: var(--buttons_color);
        border-radius: 30px;
        padding: 15px 39px;
      }

      &.info {
        border-radius: 30px;
        padding: 15px 40px;
        background: #5F5F5F;
      }

      &.favorite {
        height: 35px;

        svg {
          fill: #FFFFFF80;
        }

      }

    }
  }

  // link for whole slide (it will not let inner link to be clicked)
  .page-banner a.gen{
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
  }

  // swiper
  .page-banner.slideshow .info-wrap{
    opacity: 0;
  }
  .page-banner.slideshow .info-wrap .infos{
    opacity: 0;
  }

    // animation
    .page-banner.slideshow .swiper-slide-active .media-wrap video{
      // animation:vzoom 80s ease infinite;
    }
    .page-banner.slideshow .swiper-slide-active .media-wrap .img-wrapper{
      animation:izoom 80s ease infinite;
    }
    .page-banner.single .media-wrap video{
      // animation:vzoom 80s ease infinite;
    }
    .page-banner.single .media-wrap .img-wrapper{
      animation:izoom 80s ease infinite;
    }


  /* media page-banner */
  @media only screen and (max-width: 1400px) {
    // #app .page-banner .container,
    // .container-md, .container-sm {
    //   max-width: 100%;
    //   width: 100%;
    // }
  }

  @media only screen and (max-width: 1200px) {
    #app .page-banner .container,
      .container-md, .container-sm {
        max-width: 100%;
        width: 100%;
      }
  }

  @media only screen and (max-width: 992px) {

    .page-banner.full{
      min-height: 100vh;
      min-height: calc(100vh - 30px);
    }


    .page-banner .info-wrap h1,
    .page-banner .info-wrap h2{
      font-size: 34px;
      line-height: 38px;
    }
    .page-banner .info-wrap h3{
      margin-top: 15px;
    }
    .page-banner .info-wrap a.in{
      margin-top: 25px;
    }
    .page-banner .info-wrap .infos .infos-sep{
      max-width: 780px;
    }

    .page-banner .info-wrap{
      text-align: center;
      width: 70%;
    }

  }
  @media only screen and (max-width: 768px) {

    .page-banner .info-wrap{
      text-align: center;
      width: 90%;
    }
    .page-banner .info-wrap .infos{
      max-width: 100%;
    }
    .page-banner .info-wrap .infos .infos-sep{
      width: 100%;
      max-width: 100%;
    }

    .page-banner .info-wrap h1,
    .page-banner .info-wrap h2{
      font-size: 28px;
      line-height: 32px;
    }
    .page-banner .info-wrap h3{
      font-size: 22px;
      line-height: 26px;
    }

    .page-banner .info-wrap p{
      font-size: 16px;
      line-height: 20px;
      margin-top: 15px;
    }

    .page-banner .info-wrap .sub-title{
      font-size: 18px;
      line-height: 22px;
    }

    .page-banner .actions{
      margin-top: 25px;
    }
    .page-banner .actions .btn{
      font-size: 14px;
    }
    .page-banner .actions .btn.watch{
      padding: 10px 30px;
    }

  }
  @media only screen and (max-width: 480px) {
    .page-banner .info-wrap h3{
      font-size: 20px;
      line-height: 24px;
    }
  }


  // video zoom (no firefox fica dando uns flashes)
  @keyframes vzoom{
    0%		{transform: scale(1) translate(-50%,-50%);}
    50%   {transform: scale(1.3) translate(-50%,-50%);}
    100%	{transform: scale(1) translate(-50%,-50%);}
  }
  @-moz-keyframes vzoom{
    0%		{transform: scale(1) translate(-50%,-50%);}
    50%   {transform: scale(1.3) translate(-50%,-50%);}
    100%	{transform: scale(1) translate(-50%,-50%);}
  }
  @-webkit-keyframes vzoom{
    0%		{transform: scale(1) translate(-50%,-50%);}
    50%   {transform: scale(1.3) translate(-50%,-50%);}
    100%	{transform: scale(1) translate(-50%,-50%);}
  }

  // imge zoom
  @keyframes izoom{
    0%		{transform: scale(1);}
    50%   {transform: scale(1.3);}
    100%	{transform: scale(1);}
  }
  @-moz-keyframes izoom{
    0%		{transform: scale(1);}
    50%   {transform: scale(1.3);}
    100%	{transform: scale(1);}
  }
  @-webkit-keyframes izoom{
    0%		{transform: scale(1);}
    50%   {transform: scale(1.3);}
    100%	{transform: scale(1);}
  }

</style>