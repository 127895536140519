<template>
    <div id="cart">
        <div class="box" id="cart-container">
            <!-- <a href="#" id="btn-close" ref="btn-close" v-on:click="exit"><img :src="$url + 'assets/img/oracle/x_24.png'"></a> -->
            <span v-on:click="exit" class="material-icons md-grey" style="color: #696A70;font-size: 24px;font-style: normal;font-weight: 400;line-height: normal;float:right;cursor: pointer;">close</span>
            <!-- <h2 v-text="$ml.get('cart.title')"></h2> -->
            <h3>{{ $ml.get('cart.title') }}({{ counter }})</h3>
            <div class="info" v-if="!products.length && !loading" v-html="$ml.get('cart.nenhum_produto')"></div>
            <div class="info" v-if="loading" v-html="$ml.get('cart.carregando_itens')"></div>
            <ul>
                <li v-for="i in this.products" :key="i.id">
                    <!-- {{  i }} -->
                    <ul>
                        <li>
                            <!--<img :src="$url + 'produtos/' + i.id + '.webp'" width="70">-->
                            <img :src="store_urls.url_api + 'storage/product/' + i.id + '/' + i.files[0].name + '_100.' + i.files[0].ext" width="70">
                        </li>
                        <li>{{ i.description }}</li>
                        <!--<li>Quantidade: {{ i.quantity }}</li>-->
                        <li>
                            <ul>
                                <li><a class="less" v-on:click="decrement" :data-id="i.id" :data-cart-id="i.cart_id">-</a></li>
                                <li id="product-count">{{ i.quantity  }}</li>
                                <li><a class="more" v-on:click="increment" :data-id="i.id" :data-cart-id="i.cart_id">+</a></li>
                            </ul>
                        </li>
                        <!--<li>$ {{ String(i.price.toFixed(2)).replace(".", ",") }}</li>-->




                        
                        <li style="text-align: center">

                            <div>
                                <div v-if="i.price" v-bind:class="{ fm : (i.price), riscado:(i.promo && i.promo_price)  }">R$ {{ String(Number(i.price).toFixed(2)).replace(".", ",") }}</div>
                                <div v-if="i.promo && i.promo_price" class="boldt">R$ {{ String(Number(i.promo_price).toFixed(2)).replace(".", ",") }}</div>
                            </div>

                            <div v-if="i.price && i.coins" class="or">ou</div>
                            
                            <div>
                                <div v-if="i.coins" v-bind:class="{ fm : (i.coins), riscado:(i.promo && i.promo_coins) }">
                                    <svg width="30" height="30" style="fill: var(--buttons_color);vertical-align: middle;" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    {{ i.coins }}
                                </div>
                                <div v-if="i.promo && i.promo_coins" class="boldt">
                                    <svg width="30" height="30" style="fill: var(--buttons_color);vertical-align: middle;" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    {{ i.promo_coins }}
                                </div>
                            </div>
                            
                        </li>

                        <li>
                            <div v-if="i.price">
                                R$ {{ String(Number(i.amount).toFixed(2)).replace(".", ",") }}
                            </div>
                            <div v-if="i.price && i.coins" class="or">ou</div>
                            <div v-if="i.coins">
                                <svg width="30" height="30" style="fill: var(--buttons_color);vertical-align: middle;" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                {{ i.amount_coins }}
                            </div>
                        </li>






                        <li>
                            <!-- <a class="icon-trash" href="#" v-on:click="del" :data-id="i.id" :data-cart-id="i.cart_id" :title="$ml.get('cart.excluir_item')"><img :src="$url + 'assets/img/oracle/trash.png'"></a> -->
                            <span v-on:click="del" class="material-icons md-grey delete" :data-id="i.id" :data-cart-id="i.cart_id" :title="$ml.get('cart.excluir_item')" style="color: #696A70;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;float:right;cursor: pointer;">delete</span>
                        </li>
                    </ul>
                </li>
            </ul>

            <input id="product_temp" name="product_temp" ref="product_temp" type="hidden" value="" style="display: none">
            <div id="cart-change-open" v-if="change_cart_open">

                <ul>
                    <li><a class="btn-0" href="#" v-on:click="cartChangeDiscard" v-text="$ml.get('cart.descartar')"></a></li>
                    <li><a class="btn-1" href="#" v-on:click="cartChangeSave" v-text="$ml.get('cart.confirmar')" style="color: white;"></a></li>
                </ul>
            </div>
            <!-- <hr> -->
            <div id="cart-info" v-bind:class="{ 'cart-change-blocked': this.change_cart_open }">
                <ul>
                    
                    <li><!--
                        <ul>
                            <li><h3 v-text="$ml.get('cart.tipo_entrega')"></h3></li>
                            <li>
                                <button class="btn-0" type="button" v-bind:class="{ 'active': (delivery === 'home') }" v-on:click="deliveryHandler" data-v="home" v-text="$ml.get('cart.em_casa')"></button>
                                <button class="btn-0" type="button" v-bind:class="{ 'active': (delivery === 'store') }" v-on:click="deliveryHandler" data-v="store" v-text="$ml.get('cart.retirada_loja')"></button>
                                <button class="btn-0" type="button" v-bind:class="{ 'active': (delivery === 'locker') }" v-on:click="deliveryHandler" data-v="locker" v-text="$ml.get('cart.pickup_in_locker')"></button>
                                <div id="locker-address" ref="locker-address">

                                </div>
                            </li>
                        </ul>-->
                    </li>
                    <li>
                        <ul>
                            <!--<li><h3 v-text="$ml.get('cart.pagamento')"></h3></li>-->
                            <li>
                                <!--
                                <ul>
                                    <li style="text-align: left">{{ $ml.get('cart.cartao_credito') }}<br><br><b>**** **** **** 3788</b></li>
                                    <li>
                                        {{ $ml.get('cart.utilizar_pontos') }}<br>
                                        <b>{{ points }}</b>
                                        <div class="checkbox-container" style="display: inline-block;margin-left: 15px">
                                            <input class="styled-checkbox" type="checkbox" name="remember" id="remember" v-on:change="pointHandler">
                                            <label class="form-check-label" for="remember"></label>
                                        </div>
                                    </li>
                                </ul>-->
                            </li>
                            <li>
                                <ul>
                                    <li v-text="$ml.get('cart.total_compra')"></li>
                                    <li v-if="Number(total) > 0"><b style="font-size: 24px;">R$ {{ String(Number(total).toFixed(2)).replace(".", ",") }}</b></li>
                                    <li v-if="Number(total) > 0 && Number(total_coins > 0)" class="or">ou</li>
                                    <li v-if="Number(total_coins) > 0">
                                        <svg width="30" height="30" style="fill: var(--buttons_color);vertical-align: middle;" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <b style="font-size: 24px;">{{ total_coins }}</b>
                                    </li>
                                </ul>
                            </li>

                            <!-- <li style="width: 100%;"><hr></li> -->
                           
                            <li>
                                
                                <ul>
                                    <li v-if="this.point_checked" style="vertical-align: middle">{{ $ml.get('cart.utilizacao_de_pontos')}} <b>{{ point_value }}</b></li>
                                    <li v-if="this.point_checked" style="vertical-align: middle">
                                        <!--<input id="point-field" ref="point-field" min="0" :max="(points > total ? Math.floor(total) : points)" readonly placeholder="Pontos" style="width: 100px;height: 40px" type="number" value="0" v-model="point_value" v-on:keyup="checkInputValue" v-on:change="pointInputHandler">-->
                                        <input id="point-field" ref="point-field" min="0" :max="(points > total ? Math.floor(total) : points)" readonly placeholder="Pontos" style="width: 100px;height: 40px" type="text" value="0" v-model="point_value" v-on:keyup="checkInputValue" v-on:change="pointInputHandler">
                                    </li>
                                    <li v-show="false" v-text="$ml.get('cart.total_pagar')"></li>
                                    <li v-show="false"><b>R$ {{ String(Number(total_temp).toFixed(2)).replace(".", ",") }}</b></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div id="cart-controller" v-bind:class="{ 'cart-change-blocked': this.change_cart_open }">
                <ul>
                    <li>
                        <a class="btn-0" id="btn-payments" href="#" v-on:click="my_payments" style="border: 1px solid #E1E1E1;">Meus pagamentos</a>
                    </li>
                    <li><button class="btn-0" type="button" v-on:click="clearCart" v-text="$ml.get('cart.esvaziar_carrinho')" style="border: 1px solid var(--buttons_color);color:var(--buttons_color) !important;"></button></li>
                    <!--<li><a class="btn-1" v-on:click="checkout" v-text="$ml.get('cart.pagar')"></a></li>-->
                    <li v-show="products.length"><a class="btn-1" v-on:click="checkOutStart" v-text="$ml.get('cart.pagar')"></a></li>
                </ul>
            </div>
            <!-- <div>
                <hr>
                <h3><a class="btn-2" id="btn-payments" href="#" v-on:click="my_payments">Meus pagamentos</a></h3>
            </div> -->
        </div>


        <div v-if="stores_active" id="stores">
            <div class="box" id="stores-container">
                <ul>
                    <li><h3>{{ $ml.get('cart.escolha_cidade') }}</h3></li>
                    <li>
                        <select name="locationId" id="locationId">
                            <option value="">{{ $ml.get('cart.escolha_cidade') }}</option>
                            <option  v-for="i in this.stores" :key="i.locationId" :value="i.locationId">{{ i.address1 + ' - ' + i.city }}</option>
                        </select>
                    </li>
                    <li><button class="btn-1" type="button"  v-on:click="storeChoice">{{ $ml.get('cart.choice') }}</button></li>
                    <li><button class="btn-0" type="button" v-on:click="storeCancel">{{ $ml.get('cart.cancel') }}</button></li>
                </ul>
            </div>
        </div>

    </div>

</template>

<script>
    import axios from 'axios';
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "Carrinho",
        computed: {
            ...mapGetters([
                'counter', 'token', 'logged', 'products', 'products_ids', 'products_n', 'points', 'id', 'email', 'first_name', 'last_name', 'store_urls'
            ])
        },
        data() {
            return {
                change_cart_open: false,
                count           : 0,
                delivery        : 'home',
                error           : null,
                l_              : null,
                loading         : false,
                location_id     : null,
                point_checked   : false,
                point_value     : 0,
                shippingGroups  : [],
                shoppingCart    : [],
                stores          : [],
                stores_active   : false,
                total           : 0,
                total_coins     : 0,
                total_temp      : 0,
                total_coins_temp: 0
            }
        },
        methods: {
            ...mapActions([
                'update', 'update_cart', 'update_cart_products', 'del_product_cart', 'update_points'
            ]),

            exit(e) {
                if (this.change_cart_open) {
                    this.cartChangeDiscard(null);
                }
                this.$parent.cart = false;
                if (e)
                    e.preventDefault();
            },

            my_payments(e)
            {
                e.preventDefault();
                // this.$parent.orders = true;
                this.$parent.cart = false;
                this.$router.push({ path: '/payments'} );
            },


            /*----- cart-change -----*/
            cartChangeDiscard(e) {
                this.shoppingCart = JSON.parse(this.$refs['product_temp'].value);
                for (let o in this.shoppingCart) {
                    this.update_cart_products(this.shoppingCart[o]);
                }
                this.$forceUpdate();
                this.refreshTotal();
                this.change_cart_open = false;
                this.$refs['product_temp'].value = '';
                if (e)
                    e.preventDefault();
            },
            cartChangeSave(e) {
                this.updateCartLocal(null);

                e.preventDefault();

                // let form_data = new FormData();
                // form_data.append('cart', JSON.stringify(this.shoppingCart));
                let json = { cart: this.shoppingCart };
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.$url_api + 'api/cart/updateall', json, { headers: headers }).then(response => this.cartChangeSaveResponse(response));
            },
            cartChangeSaveResponse(obj)
            {
                console.log('cartChangeSaveResponse => ', obj);
                if (this.$route.name == 'Checkout')
                {
                    window.location.reload();
                }
            },
            increment(e) {
                if (!this.change_cart_open) {
                    this.$refs['product_temp'].value = JSON.stringify(this.products);
                }
                this.change_cart_open = true;
                let index = this.products_ids.indexOf(Number(e.currentTarget.getAttribute("data-cart-id")));

                this.shoppingCart[index].quantity++;
                this.shoppingCart[index].amount = ((this.shoppingCart[index].promo && this.shoppingCart[index].promo_price ? this.shoppingCart[index].promo_price : this.shoppingCart[index].price) * this.shoppingCart[index].quantity);
                this.shoppingCart[index].amount_coins = ((this.shoppingCart[index].promo && this.shoppingCart[index].promo_coins ? this.shoppingCart[index].promo_coins : this.shoppingCart[index].coins) * this.shoppingCart[index].quantity);
                this.$forceUpdate();
                this.update_cart_products(this.shoppingCart[index]);
                this.refreshTotal();
                e.preventDefault();
            },
            decrement (e) {
                let index = this.products_ids.indexOf(Number(e.currentTarget.getAttribute("data-cart-id")));
                if (this.shoppingCart[index].quantity > 1) {
                    if (!this.change_cart_open) {
                        this.$refs['product_temp'].value = JSON.stringify(this.products);
                    }
                    this.change_cart_open = true;
                    this.shoppingCart[index].quantity--;
                    this.shoppingCart[index].amount = ((this.shoppingCart[index].promo && this.shoppingCart[index].promo_price ? this.shoppingCart[index].promo_price : this.shoppingCart[index].price) * this.shoppingCart[index].quantity);
                    this.shoppingCart[index].amount_coins = ((this.shoppingCart[index].promo && this.shoppingCart[index].promo_coins ? this.shoppingCart[index].promo_coins : this.shoppingCart[index].coins) * this.shoppingCart[index].quantity);
                    this.$forceUpdate();
                    this.update_cart_products(this.shoppingCart[index]);
                    this.refreshTotal();
                }
                e.preventDefault();
            },
            /*----- \cart-change\ -----*/


            clearCart(e) {
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.delete(this.store_urls.url_api + 'api/cart/removeall', { headers: headers }).then(response => this.clearCartResponse(response));


                this.update_cart_products(null);
                this.shippingGroups = [];
                this.shoppingCart = [];
                this.total = 0;
                this.total_coins = 0;
                this.total_temp = 0;
                this.total_coins_temp = 0;
                this.point_value = 0;
                if (e)
                    e.preventDefault();

            },
            clearCartResponse(obj)
            {
                console.log('clearCartResponse => ', obj);
                if (this.$route.name == 'Checkout')
                {
                    window.location.reload();
                }
            },
            refreshTotal() {
                let c = 0;
                let c1 = 0;
                for (let o in this.products) {
                    console.log(this.products[o].promo);
                    c += ((this.products[o].promo && this.products[o].promo_price ? this.products[o].promo_price : this.products[o].price) * this.products[o].quantity);
                    c1 += ((this.products[o].promo && this.products[o].promo_coins ? this.products[o].promo_coins : this.products[o].coins) * this.products[o].quantity);
                }
                console.log('c => ', c, c1);
                this.total = Number(c).toFixed(2);
                this.total_coins = c1;
                //this.pointInputHandler();
            },




        


            del (e) {
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.delete(this.store_urls.url_api + 'api/cart/remove/' + e.currentTarget.getAttribute("data-cart-id"), { headers: headers }).then(response => this.delResponse(response));

                // let l_ = { id:e.currentTarget.getAttribute("data-id") };
                let l_ = { cart_id:e.currentTarget.getAttribute("data-cart-id") };
                this.del_product_cart(l_);

                if (this.products_n.length > 0) {
                    this.updateCartLocal(null);
                } else {
                    this.clearCart(null);
                }
                e.preventDefault();
            },
            delResponse(obj)
            {
                console.log(obj);
            },

            updateCartLocal(e) {

                
                this.shoppingCart = this.products;
                this.$forceUpdate();
                this.refreshTotal();
                this.point_value = 0;
                this.change_cart_open = false;

                if (e)
                    e.preventDefault();
            },
           
            checkOutStart(e) {
                e.preventDefault();
                this.$parent.cart = false;
                // this.$parent.user_address = true;
                this.$router.push({ path:'/checkout' });

                /*
                if (this.delivery == 'locker') {
                    this.count = 0;
                    this.getStock();
                } else {
                    this.checkout(e);
                }
                */
            },
            checkout (e) {
                //aqui
                if (this.total > 0) {
                    this.$parent.wait(true);
                    let form_data = new FormData();
                    form_data.append('token',      this.token);
                    form_data.append('id',         this.id);
                    form_data.append('email',      this.email);
                    form_data.append('first_name', this.first_name);
                    form_data.append('last_name',  this.last_name);
                    form_data.append('product_id', this.products_ids.join(","));
                    form_data.append('product_n',  this.products_n.join(","));
                    form_data.append('orderId',    this.products[0].orderId);
                    form_data.append('delivery_type', this.delivery);
                    form_data.append('total',      this.total);
                    form_data.append('locationId', this.location_id);
                    form_data.append('_paymentWithPoints', document.getElementById('remember').checked);

                    axios.post(this.$url_api + 'store/oracle/checkout', form_data).then(response => this.checkoutResponse(response));
                } else {
                    alert(this.$ml.get('cart.alert_value_pay'));
                }
                if (e)
                    e.preventDefault();
            },
            checkoutResponse(obj) {
                this.$parent.wait(false);
                if (obj.data.errorCode)
                {
                    this.$parent.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    this.update_cart_products(null);
                    this.shippingGroups = [];
                    this.shoppingCart = [];
                    if (obj.data.uuid) {
                        if (this.point_checked) {
                            this.deductPoints();
                        } else {
                            //this.$router.push('/sucesso');
                            this.$parent.cart = false;
                            this.$parent.success = true;
                        }
                    } else {
                        alert(this.$ml.get('cart.alert_error_buy'));
                    }
                }
            }
        },
        mounted() {
            console.log('cart mounted')
            this.shippingGroups = [];
            this.shoppingCart = [];
            this.total = 0;
            this.total_temp = 0;
            this.point_value = 0;

            /*
            for (let o in this.products)
            {
                //this.total += (this.products[o].price * this.products[o].quantity);
            }
            */
            
            this.shoppingCart = this.products;
            this.refreshTotal();
        }
    }
</script>

<style scoped>
    #btn-payments
    {
        box-sizing: border-box;
        /* margin: 10px 15px; */
        padding: 15px;
        width: auto;
    }
    #locker-address
    {
        background-color: #f5f5f5;
        border-radius: 10px;
        box-sizing: border-box;
        display: none;
        padding: 10px;
    }
    #cart
    {
        background-color: rgba(0, 0, 0, .6);
        /* color: black; */
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    #cart-container
    {
        display: block;
        /* margin: 150px auto; */
        /* max-height: 80%; */
        max-width: 1200px;
        overflow-y: auto;
        width: auto;
    }
    #cart-container > ul
    {
        box-sizing: border-box;
        padding: 15px 0;
    }
    #cart-container > ul > li:nth-child(odd)
    {
        background-color: #FAFAFA;;
    }
    #cart-container > ul > li > ul > li
    {
        box-sizing: border-box;
        display: inline-block;
        padding: 10px 15px;
        vertical-align: middle;
    }
    #cart-container > ul > li > ul > li:nth-child(1)
    {
        width: 10%;
    }
    #cart-container > ul > li > ul > li:nth-child(1) img
    {
        /* border: solid 1px #e5e5e5;
        border-radius: 10px; */
    }
    #cart-container > ul > li > ul > li:nth-child(2)
    {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 21%;
    }
    #cart-container > ul > li > ul > li:nth-child(3)
    {
        width: 30%;
    }
    #cart-container > ul > li > ul > li:nth-child(4)
    {
        width: 13%;
    }
    #cart-container > ul > li > ul > li:nth-child(5)
    {
        width: 13%;
    }
    #cart-container > ul > li > ul > li:nth-child(6)
    {
        width: 6%;
    }


    /*----- stores -----*/
    #stores
    {
        background-color: rgba(0, 0, 0, .7);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    #stores-container
    {
        height: auto;
        left: calc(50% - 225px);
        padding: 25px;
        position: fixed;
        top: 100px;
        width: 450px;
    }
    #stores-container > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        padding: 10px 0;
    }
    #stores-container select
    {
        width: 100%;
    }
    /*----- \stores\ -----*/




    /*----- cart-container -----*/
    #cart-container > ul > li > ul > li > ul > li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        margin: 0 4px;
        padding: 0;
        vertical-align: middle;
    }
    .less, .more
    {
        /*
        background-color: black;
        border-radius: 10px;
        color: white !important;
        display: block;
        font-size: 25px;
        font-weight: bold;
        outline: none;
        height: 40px;
        line-height: 40px;
        text-align: center;
        user-select: none;
        width: 40px;
        */
        border-radius: 7px;
        border: 1px solid #E1E1E1;
        background: #F6F6F6;
        color: #B1B1B1 !important;
        display: block;
        font-family: inherit;
        font-size: 20px !important;
        height: 38px;
        line-height: 34px;
        /* font-weight: bold; */
        outline: none;
        /* height: 50px; */
        /* line-height: 50px; */
        /* padding: 4px; */
        text-align: center;
        user-select: none;
        width: 40px;
    }
    #product-count
    {
        /*
        color: var(--buttons_color);
        font-size: 35px;
        font-weight: bold;
        height: 50px;
        text-align: center;
        width: 50px;
        */

        border-radius: 7px;
        border: 1px solid #E1E1E1;
        /* color: var(--buttons_color); */
        font-family: inherit;
        /* font-size: 35px; */
        font-weight: bold;
        height: 38px;
        line-height: 38px;
        text-align: center;
        width: 80px;
    }
    /*----- \cart-container\ -----*/


    #cart-controller
    {
        box-sizing: border-box;
        padding: 10px;
        text-align: center;
        width: 100%;
    }
    #cart-controller > ul > li
    {
        box-sizing: border-box;
        display: inline-block;
        padding: 0 25px;
        width: calc((100% / 3) - 30px);
    }


    /*----- cart-change-open -----*/
    #cart-change-open
    {
        text-align: center;
    }
    #cart-change-open > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        display: inline-block;
        padding: 5px;
        text-align: center;
    }
    .cart-change-blocked
    {
        opacity: .3;
        pointer-events: none;
    }
    /*----- cart-change-open -----*/



    /*----- cart-info -----*/
    #cart-info > ul > li
    {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2);
    }
    #cart-info > ul > li > ul >  li
    {
        box-sizing: border-box;
        display: block;
        padding: 10px;

    }
    #cart-info > ul > li:nth-child(1) > ul > li > button
    {
        border-radius: 10px;
        margin: 5px;
        width: calc(50% - 20px);
    }
    #cart-info > ul > li > ul >  li > ul > li
    {
        box-sizing: border-box;
        display: inline-block;
        padding: 6px;
        vertical-align: top;
        width: calc(100% / 2);
    }
    #cart-info > ul > li > ul >  li > ul > li:nth-child(even)
    {

    }
    #cart-info > ul > li > ul >  li > ul > li:nth-child(odd)
    {
        text-align: right;
    }

    #cart-info > ul > li > ul >  li > ul > li > span
    {
        font-size: 10px;
    }
    /*----- \cart-info\ -----*/


    h1
    {
        box-sizing: border-box;
        text-align: center;
        padding: 10px 0;
    }
    h3
    {
        font-weight: 700;
    }

    #btn-close
    {
        box-sizing: border-box;
        float: right;
        padding: 15px;
    }
    .info{
        box-sizing: border-box;
        padding: 10px;
    }
    .active
    {
        border: none;
        color: white !important;
        background-color: #fea044;
    }
    .or
    {
        display: flex; 
        flex-direction: row; 
        gap: 20px;
    }
    .or:before, 
    .or:after { 
            content: ""; 
            flex: 1 1; 
            border-bottom: 2px solid #f1f1f1; 
            margin: auto; 
        } 
    @media screen and (max-width: 850px)
    {
        #cart-container
        {
            width: calc(100% - 40px);
        }
        #cart-container > ul > li
        {
            border-bottom: solid 1px #e5e5e5;
        }
        #cart-container > ul > li > ul > li
        {

            display: block;
            text-align: center;
            width: 100% !important;
        }
        #cart-container > ul > li > ul > li > img
        {
            width: 150px;
        }

        #cart-info > ul > li
        {

            display: block;
            text-align: center;
            width: 100% !important;
        }
        #cart-info > ul > li:nth-child(1) > ul > li > button
        {
            width: 100%;
        }

        .icon-trash
        {
            border: solid 1px #ccc;
            box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
            box-sizing: border-box;
            display: inline-block;
            padding: 10px;
        }

        #cart-controller > ul > li
        {
            margin: 5px 0;
            width: 100%;
        }
        .delete
        {
            float: none !important;
        }
    }
</style>