<template>
    <div>
        <button v-on:click="logInWithFacebook" type="button">
            <img alt="Facebook icon" width="30px" height="30px" src="../../../public/assets/img/facebook.png">
        </button>
    </div>
</template>
<script>

    export default {
        name:"LoginFacebook",
        data() {
            return {
            }
        },
        props: ['origin'],
        methods: {
            logInWithFacebook() { 
                let root_ = this;
                window.FB.login(function(response) { 
                    //console.log('response 00 =>', response);
                    if (response.authResponse) {
                        window.FB.api('/me?fields=name,email', function(response) {
                            //console.log('response facebook => ', response);
                            let data = { type:'facebook', origin: root_.origin, id:response.id, email:response.email, name:response.name };
                            root_.$parent.loginSocial(data);
                        });

                    } else {
                        //multiple modal
                        window.$.suimodal({
                            title: 'Erro!',
                            content: 'Você cancelou ou não autorizou o login.',
                            className: {
                                suimodal: 'ui suimodal mini translucent background-transparent'
                            },
                            autoShow: true,
                            closable: true,
                            allowMultiple: true,
                            onDeny: function () {
                                return true;
                            },
                            onApprove: function () {
                                return true;
                            }
                        })
                    }
                }, 
                { scope: 'public_profile,email', return_scopes: true }
                );
                return false;
            },
            initFacebook() {
                this.$app.decryptUnity(
                    this.$app.keyUnityConf,
                    this.$app.switch.fb_conf.i,
                    this.$app.switch.fb_conf.c
                ).then(objDec => {
                    window.fbAsyncInit = function() {
                        window.FB.init({
                            appId      : objDec.app_id,
                            cookie     : true,
                            xfbml      : true,
                            version    : objDec.version
                        });
                        window.FB.AppEvents.logPageView();
                    };
                });
            },
            loadFacebookSDK(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }
        },
        mounted() {

            this.loadFacebookSDK(document, "script", "facebook-jssdk");
            this.initFacebook();
        }
    }
</script>

<style scoped>
    button, picture
    {
        border: none;
        display: block;
        padding: 0;
        background: transparent;
    }
    button{
        border-radius: 10px;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        border: 1px solid #dadce0;
        overflow: hidden;
        background-color: #fff;
        &:hover{
            box-shadow: 0 0 5px 0 rgba(0,0,0,.8);
        }
    }
    img{
        margin: 0!important;
        max-width: 30px;
        max-height: 30px;
    }
</style>