export default {
    update_init(state, init) {
        state.init = init;
    },
    update_selected_book(state, book) {
        state.selectedBook = book;
    },
    update_book_created_now(state, status) {
        state.bookCreatedNow = status;
    }
}