<template>
    <div class="popup-notification" ref="popupNotification">
        <div v-if="popup_notifications.length>0" class="popup-position">
            <div v-for="(notification, key) in popup_notifications" :key="key">
                <div class="notification alignVertical col-12"
                   v-if="notification.call_to_action">
                    <div class="popup-close" v-on:click="readNotification(notification)">X</div>
                    <div style="cursor: pointer;" class="alignVertical col-12" v-on:click="readNotification(notification,true)">
                        <div class="notification-img alignVertical col-4">
                            <img v-if="notification.icon" :src="notification.icon">
                            <img v-else :src="'https://via.placeholder.com/300'">
                        </div>
                        <div class="notification-text alignVertical col-8">
                            <div class="col-10">
                                <p class="notification-title">{{notification.title}}</p>
                                <p v-html="notification.text.replaceAll('\n','<br>')"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
        name: 'Popup',
        computed: {
            ...mapGetters([
                'logged', 'token','switch'
            ]),
        },
        props: ['notifications'],
        data() {
            return {
                popup_notifications:[],
            }
        },  
        components: {
            
        },
        methods: {
            ...mapActions([
                'update_notification_with_id'
            ]),
            async readNotification(notification,open=false){
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                let form_data = new FormData();
                form_data.append('notification', notification.notification_id);
                try {
                    await axios.post(this.$url_api + 'api/ViewNotification', form_data, {headers: headers});
                    notification.show_popup = false;
                    this.update_notification_with_id(notification)
                    this.$app.updatePopupNotifications();
                    if (open) {
                        window.open(notification.link_cta, notification.same_page_cta ? '_self' : '_blank')
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            updateNotifications(to){
                this.popup_notifications = [];
                for (let i = 0; i < to.length; i++) {
                    if (this.popup_notifications.length===3)
                        continue;
                    this.popup_notifications.push({...to[i],index:i})
                }
            }
        },
        created() {
            
        },
        mounted() {
        },
        updated() {
        },
        beforeDestroy() {
        },
        watch: {
            notifications(to, from) {
                to, from;
                this.updateNotifications(to);
            }
        }
    }
</script>

<style scoped>
    .popup-position {
        position: relative;
        top: var(--headers_height);
        right: 10px;
        max-width: 350px;
        float: right;
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    .notification {
        width: 100%;
        cursor: pointer;
        max-height: 100px;
        height: 20%;
        position: relative;
        margin: 10px 0;
        background: rgba(1, 1, 1, 0.5);
        padding: .5rem 1rem;
        float: right;
    }
    .popup-close{
        position: absolute;
        top: 3px;
        right: 10px;
        color: black;
        font-family: cursive;
    }

    .notification-img img {
        width: 100%
    }
    .notification-text{
        color: #e2e2e2;
    }
    .notification-text .notification-title {
        font-weight: 900;
    }
    @media screen and (max-width: 500px)
    {

    }
</style>