<template>
    <div>
        <button v-if="['login','register'].includes(this.origin)" class="azure" :disabled="!this.active" v-on:click="logInWithAzure" type="button">
            <div>
                <img v-if="!image" alt="Azure icon" :src="$url + '/assets/img/azure.webp'">
                <img v-else alt="Azure icon" :src="image">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.42578 3.07422L8.32422 6L5.42578 8.92578L4.60547 8.10547L6.10938 6.57422H0.175781V5.42578H6.10938L4.60547 3.89453L5.42578 3.07422ZM10.6758 10.0742V1.92578H6V0.75H10.6758C10.9857 0.75 11.25 0.86849 11.4688 1.10547C11.7057 1.32422 11.8242 1.59766 11.8242 1.92578V10.0742C11.8242 10.4023 11.7057 10.6849 11.4688 10.9219C11.25 11.1406 10.9857 11.25 10.6758 11.25H6V10.0742H10.6758Z" fill="white"/>
                </svg>
            </div>
            <span>{{ this.text?this.text:$t('login.azure')}}</span>
        </button>
        <button v-else :disabled="!this.active" v-on:click="logInWithAzure" type="button">
            <picture>
                <img v-if="!image" alt="Azure icon" width="30px" height="30px" :src="$url + '/assets/img/azure.webp'">
                <img v-else alt="Azure icon" width="30px" height="30px" :src="image">
            </picture>
        </button>
    </div>
</template>
<script>
    import {PublicClientApplication} from "@azure/msal-browser";

    export default {
        name:"LoginAzure",
        data() {
            return {
                image:null,
                text:null,
                msalInstance: {},
            }
        },
        props: ['origin','active'],
        methods: {
            async logInWithAzure() {
                let root_ = this;
                root_.$app.wait(true);
                await root_.msalInstance
                .loginPopup({scopes: ["user.read"],})
                .then((account) => {
                    let data = { type:'azure', origin: root_.origin, id:account.account.localAccountId,token:account.accessToken, email:account.account.username, name:account.account.username.split("@")[0] };
                    root_.$parent.loginSocial(data);
                    root_.$app.wait(false);
                    })
                    .catch(error => {
                        console.error(`error during authentication: ${error}`);
                        root_.$app.wait(false);
                    });
                return false;
            },
            initAzure(){
                const _root = this;
                this.$app.decryptUnity(
                    this.$app.keyUnityConf,
                    this.$app.switch.azure_conf.i,
                    this.$app.switch.azure_conf.c
                ).then(objDec => {
                    _root.image = objDec.image;
                    _root.text = objDec.text;
                    _root.msalInstance = new PublicClientApplication({
                        auth: {
                            clientId: objDec.client_id,
                            authority: "https://login.microsoftonline.com/"+objDec.directory_id,
                        },
                        cache: {
                            cacheLocation: 'localStorage',
                        },
                    });
                });
            }
        },
        mounted() {
            this.initAzure();
        }
    }
</script>

<style lang="scss" scoped>
    picture
    {
        font-size: 0;
        background: transparent;
        border: none;
        display: block;
    }
    button:not(.azure){
        background: #fdfdfc;
        display: block;
        border-radius: 10px;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        border: 1px solid #dadce0;
        overflow: hidden;
        &:hover{
            box-shadow: 0 0 5px 0 rgba(0,0,0,.8);
        }
        img{
            margin: 0!important;
            width: 30px;
            height: 30px;
        }
    }
    button:disabled{
        cursor: not-allowed;
        opacity: 0.8;
    }
    button.azure{
        width: 100%;
        display: flex;
        padding: 18px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: rgba(255, 255, 255, 0.08);
        margin-bottom: 12px;
        &:hover{
            background: rgba(255, 255, 255, 0.16);
            box-shadow: 0 0 5px 0 rgba(0,0,0,.8);
        }
        >div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            img{
                width: 90%;
                height: 30px;
                object-fit: contain;
                object-position: left;
            }
        }
        span{
            color: #fff;
            font-family: Titillium Web, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
</style>