<template>
    <div class id="app">
        <header v-if="false">
            <div id="user-info" v-if="logged">
                <img  v-if="thumb" :src="thumb">
                <ul>
                    <li>{{ $ml.get('home.ola') }}, <b>{{ user_info }}</b></li>
                    <li v-if="level">{{ $ml.get('home.nivel_atual') }}: <b>{{ level }}</b></li>
                </ul>
            </div>

            <picture>
                <source :srcset="$url + 'assets/img/oracle/logo.webp'" type="image/webp">
                <source :srcset="$url + 'assets/img/oracle/logo.png'" type="image/png"> 
                <img alt="Logomarca" id="logo" :src="$url + 'assets/img/oracle/logo.webp'">
            </picture>

            
            <div v-if="logged" id="points-controller">
                <ul>
                    <li>{{ $ml.get('home.meus_pontos') }}: <span id="points-info">{{ points }}</span></li>
                    <li v-if=" score_needed_to_reach && level_next" v-html="$ml.get('infoPoints')"></li>
                </ul>
            </div>
            <div id="cart-controller">
                <a id="cart-icon" :to="{ name:'carrinho' }" v-on:click="cartHandler"><img alt="Meu carrinho" :src="$url + 'assets/img/oracle/cart-32.png'" style="cursor: pointer"></a>
                <span id="cart-info">{{ counter }}</span>
                <!--
                <br><br><br>
                <a href="#" v-on:click="vtex">VTEX</a>
                -->
            </div>
            <div id="menu-icon" v-on:click="menuHandler">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
            </div>
            <nav class="box" id="header-nav-right" ref="header-nav-right">
                <ul>
                    <!--<li v-if="logged"><router-link :to="{ name:'produtos' }">Produtos</router-link></li>-->
                    <!--
                    <li v-if="logged"><router-link :to="{ name:'compras' }">{{ $ml.get('home.minhas_compras') }}</router-link></li>
                    <li v-if="!logged"><router-link :to="{ name:'entrar' }">{{ $ml.get('home.entrar') }}</router-link></li>
                    <li v-if="!logged"><router-link :to="{ name:'cadastro' }">{{ $ml.get('home.cadastrar') }}</router-link></li>
                    <li v-if="logged"><a href="#" title="Sair" v-on:click="logOut">{{ $ml.get('home.sair') }}</a></li>-->

                    <li v-if="logged"><a href="#" v-on:click="purchases = true">{{ $ml.get('home.minhas_compras') }}</a></li>
                    <li v-if="!logged"><a href="#" v-on:click="login = true">{{ $ml.get('home.entrar') }}</a></li>
                    <li v-if="!logged"><a href="#" v-on:click="signup = true">{{ $ml.get('home.cadastrar') }}</a></li>
                    <li v-if="logged"><a href="#" title="Sair" v-on:click="logOut">{{ $ml.get('home.sair') }}</a></li>
                </ul>
            </nav>
        </header>

        <!--<router-view ref="container"/>-->

        <Produto v-if="product" :product_id="product_id"></Produto>
        <Carrinho v-if="cart"></Carrinho>
        <Compras v-if="purchases"></Compras>
        <AddSuccess v-if="add_success"></AddSuccess>
        <Sucesso v-if="success"></Sucesso>
        <Cadastro v-if="signup"></Cadastro>
        <Entrar v-if="login"></Entrar>
        <Language></Language>

        <!-- loader -->
        <div id="loader" ref="loader">
            <div id="loader-ani"></div>
        </div>
        <!-- -->

    </div>
</template>

<script>
    /*
    Vue.prototype.$url = 'https://oracle.vrglass.com/';
    Vue.prototype.$url_oracle = 'https://ademosrv0033c1dev-store.demoservices011.commerce.oraclepdemos.com';
    Vue.prototype.$url_zapt = 'https://us-central1-zapt-bigdata.cloudfunctions.net/userLocation';
    */

    import {mapActions, mapGetters} from "vuex";
    import axios from "axios";

    import { MLBuilder } from 'vue-multilanguage';
    import AddSuccess    from '@/components/oracle/AddSuccess';
    import Cadastro      from '@/components/oracle/Cadastro';
    import Carrinho      from '@/components/oracle/Carrinho';
    import Compras       from '@/components/oracle/Compras';
    import Entrar        from '@/components/oracle/Entrar';
    import Language      from '@/components/oracle/Language';
    import Produto       from '@/components/oracle/Produto';
    import Sucesso       from '@/components/oracle/Sucesso';


    export default {
        name: "App",
        computed: {
            ...mapGetters([
                'token', 'logged', 'counter', 'id', 'first_name', 'last_name', 'email', 'points', 'level', 'level_next', 'score_needed_to_reach', 'thumb', 'check_log'
            ]),

            //----- language -----//
            mlmyMessage () {
                return new MLBuilder('msg').with('f', this.friends).with('l', 406);
            },
            mlinfoPoints() {
                
                return new MLBuilder('home.info_points').with('score_needed_to_reach', this.score_needed_to_reach).with('level_next', this.level_next);
            }
            //----- \language\ -----//
        },
        data() {
            return {
                add_success: false,
                cart       : false,
                login      : false,
                menu_active: false,
                product    : false,
                product_id : null,
                purchases  : false,
                signup     : false,
                success    : false,
                url_oracle : 'https://ademosrv0033c1dev-store.demoservices011.commerce.oraclepdemos.com',
                url_zapt   : 'https://us-central1-zapt-bigdata.cloudfunctions.net/userLocation',
                user_info  : null
            }
        },
        components: {
            AddSuccess, Cadastro, Carrinho, Compras, Entrar, Language, Produto, Sucesso
        },
        methods: {
            vtex(e)
            {
                e;
                let form_data = new FormData();
                //form_data.append("token_type", 'bearer');
                //form_data.append("grant_type", 'password');

                let myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Content-Type", "application/json");
                //myHeaders.append("X-VTEX-API-AppKey", "");
                //myHeaders.append("X-VTEX-API-AppToken", "");
                   
                let data = { method: 'POST', headers : myHeaders, body: form_data, redirect: 'follow' };
                fetch('https://local.wl.com.brphp/vtex/get_skus.php', data).then(r => r.json()).then(r => console.log("Response => ", r)).catch(e => console.log("Error => ", e));
            },



            ...mapActions([
                'update', 'update_cart_products', 'update_profile', 'update_points', 'update_check_log'
            ]),
            wait(b) {
                this.$refs['loader'].style.display = b ? 'block' : 'none';
            },
            menuHandler(e) {
                e.currentTarget.classList.toggle("change");
                this.menu_active = !this.menu_active;
                this.$refs['header-nav-right'].style.display = this.menu_active ? 'block' : 'none';
            },
            logOut(e) {
                this.wait(true);
                let form_data = new FormData();
                form_data.append('token', this.token);
                axios.post(this.$url_api + 'store/oracle/logout', form_data).then(response => this.logOutResponse(response));

                e.preventDefault();
            },
            logOutResponse(r) {
                if (r.data.result === true) {
                    this.update(null);
                    this.update_points(null);
                    this.update_cart_products(null);
                    this.user_info = null;
                }
                this.wait(false);
            },


            cartHandler(e)
            {
                e.preventDefault();
                this[this.logged ? 'cart' : 'login'] = true;
            },

            
            getUserInfo() {
                this.update_check_log(true);
                this.wait(true);
                let form_data = new FormData();
                form_data.append('token', this.token);
                axios.post(this.$url_api + 'api/store/oracle/get_profile', form_data).then(response => this.getUserInfoResponse(response));
            },
            getUserInfoResponse(obj) {
                this.wait(false);
                if (obj.data.errorCode)
                {
                    this.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    if (!obj.data.error) {

                        this.update_profile({ id: obj.data.id, email: obj.data.email, first_name: obj.data.firstName, last_name: obj.data.lastName });

                        this.user_info = obj.data.firstName + " " + obj.data.lastName;
                        if (!this.token) {
                            this.update(obj.data.token);
                            this.getPoints();
                            this.getCart();
                        }
                    }
                    /*else
                        this.$router.push('/entrar');*/
                }
                this.update_check_log(false);
            },



            getCart() {
                this.wait(true);
                let form_data = new FormData();
                form_data.append('token', this.token);
                axios.post(this.$url_api + 'store/oracle/get_cart', form_data).then(response => this.getCartResponse(response));
            },
            getCartResponse(obj) {
                this.wait(false);
                if (obj.data.errorCode)
                {
                    this.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    if (obj.data) {
                        this.shoppingCart = [];
                        for (let o in obj.data.shoppingCart.items) {
                            this.l_ = { id:obj.data.shoppingCart.items[o].productId, quantity:obj.data.shoppingCart.items[o].quantity, description:obj.data.shoppingCart.items[o].displayName, price:obj.data.shoppingCart.items[o].listPrice, orderId:obj.data.orderId, amount:obj.data.shoppingCart.items[o].rawTotalPrice };
                            this.update_cart_products(this.l_);
                        }
                    }
                }
                
            },


            getPoints() {
                let form_data = new FormData();
                form_data.append('id', this.id);
                let locale = '';
                switch(this.$ml.current)
                {
                    case 'portuguese':
                        locale = 'pt-br';
                    break;
                    case 'english':
                        locale = 'en';
                    break;
                    case 'spanish':
                        locale = 'es';
                    break;
                }
                form_data.append('locale', locale);
                axios.post(this.$url_api + 'store/oracle/get_points', form_data).then(response => this.getPointsResponse(response));
            },
            getPointsResponse(obj) {
                if (obj.data.errorCode)
                {
                    this.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    if (obj.data) {
                        this.update_points(obj.data);
                    }
                }
            },


            //----- stats -----//
            statsResponse(obj) {
                console.log('statsResponse => ', obj);
            },
            statsError(obj) {
                console.log('statsError => ', obj);
            },
            //----- \stats\ -----//

            /*
            krpanoHandler(e) {
                //recebendo do xml
                if (e.detail.info === 'cart') {
                    this.$router.push('/carrinho');
                } else if (e.detail.info === 'hotspot') {
                    //
                    //
                    //
                    const getUA = () => {
                        let device = "Unknown";
                        const ua = {
                            "Generic Linux": /Linux/i,
                            "Android": /Android/i,
                            "BlackBerry": /BlackBerry/i,
                            "Bluebird": /EF500/i,
                            "Chrome OS": /CrOS/i,
                            "Datalogic": /DL-AXIS/i,
                            "Honeywell": /CT50/i,
                            "iPad": /iPad/i,
                            "iPhone": /iPhone/i,
                            "iPod": /iPod/i,
                            "macOS": /Macintosh/i,
                            "Windows": /IEMobile|Windows/i,
                            "Zebra": /TC70|TC55/i,
                        }
                        Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
                        return device;
                    }
                    //

                    let visitable_id = '-mp4zuby_oqxhebkwpf0';
                    let form_data = new FormData();
                    form_data.append('x', e.detail.x);
                    form_data.append('y', e.detail.y);
                    form_data.append('context', 'web');
                    form_data.append('timestamp', +new Date);
                    form_data.append('visitableId', visitable_id);
                    form_data.append('mapId', visitable_id + '-floor1');
                    form_data.append('floorId', '1');
                    form_data.append('device', getUA());
                    form_data.append('userId', this.id);

                    let _root = this;
                    axios.post(this.$url + 'php/oracle/stats.php', form_data).then(response => _root.statsResponse(response)).catch(error => _root.statsError(error.response));
                } else {
                    this.$router.push('/produto/' + e.detail.info);
                }
            }
            */
        },
        mounted() {
            /*
            if (this.$route.query.token) {
                this.update(this.$route.query.token);
            }
            */
            //comentei aqui
            this.getUserInfo();

            let root_ = this;
            document.addEventListener('mousedown', function () {
                if (root_.menu_active) {
                    setTimeout(function() {
                        if (root_.menu_active)
                            document.getElementById('menu-icon').click();
                    }, 200);
                }
            })

            //document.addEventListener('krpano', this.krpanoHandler);


            console.log('logged => ', this.logged);
        }
    }
</script>
<!--
<style scoped>
    @import "../../assets/css/oracle/oracle_core.css";
    @import "../../assets/css/oracle/oracle_form.css";
</style>

<style scoped>
    #app
    {
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    header
    {
        background-color: rgba(0, 0, 0, .5);
        display: block;
        height: 80px;
        width: 100%;
    }

    #logo
    {
        box-sizing: border-box;
        display: block;
        filter: brightness(0) invert(1);
        margin: 0 auto;
        padding-top: 15px;
    }


    /*---- user-info -----*/
    #user-info
    {
        box-sizing: border-box;
        color: white;
        padding: 15px;
        position: absolute;
    }
    #user-info > ul > li
    {
        box-sizing: border-box;
        padding: 3px 0;
    }
    /*---- \user-info\ -----*/



    /*---- menu-icon -----*/
    #menu-icon
    {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        padding: 10px 20px;
        position: absolute;
        top: 15px;
        right: 5px;
    }
    .bar1, .bar2, .bar3
    {
        background-color: #fff;
        border-radius: 50%;
        height: 4px;
        margin-top: 5px;
        transition: 0.2s;
        width: 4px;
    }
    .change .bar1
    {
        border-radius: 0;
        height: 2px;
        width: 28px;
        transform: rotate(-45deg) translate(-5px, 6px);
    }
    .change .bar2
    {
        opacity: 0;
    }
    .change .bar3
    {
        border-radius: 0;
        height: 2px;
        width: 28px;
        transform: rotate(45deg) translate(-5px, -6px);
    }
    /*---- \menu-icon\ -----*/



    /*----- cart -----*/
    #cart-controller
    {
        right: 120px;
        position: absolute;
        top: 24px;
    }
    #cart-icon
    {
        filter: brightness(0) invert(1);
        position: absolute;
    }
    #cart-controller > span
    {
        background-color: #e55944;
        border-radius: 50%;
        box-sizing: border-box;
        color: white;
        font-weight: bold;
        height: 26px;
        margin-left: 25px;
        margin-top: -5px;
        padding: 5px;
        position: absolute;
        text-align: center;
        width: 26px;
    }
    /*----- \cart\ -----*/



    /*----- points -----*/
    #points-controller
    {
        border-right: solid 1px white;
        box-sizing: border-box;
        color: white;
        font-size: 18px;
        right: 140px;
        position: absolute;
        padding-right: 20px;
        top: 15px;
    }
    #points-controller > ul > li
    {
        box-sizing: border-box;
        padding: 3px 0;
        text-align: right;
    }
    #points-controller > ul > li > span
    {
        font-weight: bold;
    }
    /*----- \points\ -----*/



    /*----- header-nav-right -----*/
    #header-nav-right
    {
        display: none;
        position: absolute;
        right: 15px;
        top: 60px;
    }
    #header-nav-right > ul > li
    {
        list-style: none;
    }
    #header-nav-right > ul > li > a
    {
        box-sizing: border-box;
        display: block;
        padding: 10px 10px;
    }
    /*----- \header-nav-right\ -----*/



    /*----- loader -----*/
    #loader
    {
        background-color: rgba(0, 0, 0, .8);
        display         : none;
        height          : 100%;
        left            : 0;
        position        : fixed;
        top             : 0;
        width           : 100%;
        z-index         : 99999;
    }
    #loader-ani
    {
        -webkit-animation: spin 2s linear infinite;
        animation        : spin 2s linear infinite;
        border           : 6px solid #f5f5f5;
        border-radius    : 50%;
        border-top       : 6px solid #e55944;
        height           : 40px;
        left             : 50%;
        margin-left      : -20px;
        margin-top       : -20px;
        position         : fixed;
        top              : 50%;
        width            : 40px;
    }
    @-webkit-keyframes spin
    {
        0%   { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin
    {
        0%   { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    /*----- \loader\ -----*/


    @media screen and (max-width: 1050px)
    {
        #points-controller
        {
            background-color: rgba(0, 0, 0, .5);
            position: absolute;
            right: 10px;
            top: 90px;
        }
    }
</style>-->