import collect from 'collect.js';

export default {
    fetchFilesData(state, filesList) {
        return state.files = filesList;
    },
    fetchPagination(state, pagesData) {
        return state.pagination = pagesData
    },
    selectFiles(state, payload) {
        let collection = collect(state.selectedFiles);
        let findFile = collection.contains(payload);
        if(!findFile) {
            return state.selectedFiles.push(payload);
        } else {
            return state.selectedFiles = collection.filter((value) => value.id !== payload.id).all();
        }
    },
    emptySelectedFiles(state) {
        return state.selectedFiles = [];
    },
    mementoMemory(state) {
        return state.mementoSelectedFiles = state.selectedFiles;
    }
}