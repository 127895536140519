<template>
  <div id="landing" ref="landing">
    <div class="landing">
      <div class="contentBanner">
        <div class="swiper-container landing__banner">
          <div class="swiper-wrapper">
            <div
              :key="index"
              v-for="(spotlight, index) in spotlightsBanner"
              :data-text="spotlight.description"
              class="swiper-slide"
            >
              <div>
                <h1 v-text="spotlight.title"></h1>
                <p v-text="spotlight.description"></p>
              </div>
              <img :src="spotlight.image" />
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination-bullets"></div>
        </div>
      </div>
      <div class="landing__form">
        <div class="wrapper">
          <p>{{ $t('landingpreevento.loremIpsumDolor') }}</p>
          <form id="register-form" ref="register-form" v-on:submit="login">
            <div class="form-field">
              <input
                id="email"
                name="email"
                ref="email"
                type="text"
                :placeholder="$t('landingpreevento.email')"
              />
            </div>
            <div class="form-field">
              <input
                id="password"
                name="password"
                ref="password"
                type="password"
                :placeholder="$t('landingpreevento.senha')"
              />
            </div>
            <div class="checkbox">
              <input class="checkbox" type="checkbox" name="noticias" ref="noticias" id="noticias" />
              <label for="noticias">{{ $t('landingpreevento.euAceitoReceber') }}</label>
            </div>
            <div class="button">
              <button type="submit" >{{ btn_submit_value }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/bundle";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Home",
  computed: {
    ...mapGetters(["webview"]),
    spotlightsBanner: function () {
      return this.spotlights.filter(function (spotlight) {
        return spotlight.banner;
      });
    },
  },
  data() {
    return {
      agendaGroups: [],
      sponsorsGroup: [],
      spotlights: [],
      swiper_banner: null,
      swiper_agenda: null,
      swiper_destaque: null,
      swiper_destaqueMobile: null,
      swiper_swiper: null,
      time: 0,
      interval: null,

      btn_submit_value: this.$i18n.t('landingpreevento.enviar'),
      isActive: false,
      show_captcha: false,
      captcha: null,
      ref: null,
      email_error: null,
      password_error: null,
    };
  },
  components: {},
  methods: {
    ...mapActions([
      "update_logged",
      "update_token",
      "update_email_verified",
      "update_id",
      "update_login_social",
    ]),
    getSpotlights() {
      axios
        .get(this.$url_api + "api/getSpotlights",{headers:{'language': this.$i18n.locale,}})
        .then((response) => this.spotlightResponse(response))
        .catch((error) => this.spotlightError(error.response));
    },
    spotlightResponse(obj) {
      if (obj.data) {
        this.spotlights = [];
        this.spotlights = obj.data.data;
      }
    },
    spotlightError(obj) {
      obj;
    },
    login(e) {
      this.error = null;
      if (!/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9.])+\.([A-Za-z]{2,})$/.test(this.$refs["email"].value)) {
          this.error = this.$i18n.t('landingpreevento.oCampoEmail');
      } else if (this.$refs["password"].value.length < 8) {
          this.error = this.$i18n.t('landingpreevento.oCampoSenha');
      }else if(this.show_captcha && !this.IsRecapchaValid()){
          this.error = this.$i18n.t('landingpreevento.oCampoSenha');
      }
      if (this.error) {
          this.$app.modal('error', this.$i18n.t('landingpreevento.erro'), this.error, null, null, null, "OK");
          e.preventDefault();
      } else {
          this.sendLogin(e);
      }
    }
  },
  mounted() {
    var root = this;
    this.swiper_banner = new Swiper(".landing__banner", {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
      },
      on: {
        slideChange: function () {
          root.time = 0;
        },
      },
    });
    this.getSpotlights();
  },
  updated() {
    this.swiper_banner.update();
  },
  metaInfo() {
    return {
      title: "Landing",
      meta: [
        {
          name: "url",
          content: this.$url,
        },
        {
          name: "title",
          content: this.$i18n.t('landingpreevento.landingPreEvento'),
        },
        {
          name: "description",
          content: this.$i18n.t('landingpreevento.assista'),
        },
        {
          name: "image",
          content: this.$url + "assets/images/metas/home.png",
        },
        {
          property: "og:url",
          content: this.$url,
        },
        {
          property: "og:title",
          content: this.$i18n.t('landingpreevento.finaisAoVivo'),
        },
        {
          property: "og:description",
          content: this.$i18n.t('landingpreevento.assista'),
        },
        {
          property: "og:image",
          content: this.$url + "assets/images/metas/home.png",
        },
        {
          property: "twitter:url",
          content: this.$url,
        },
        {
          property: "twitter:title",
          content: this.$i18n.t('landingpreevento.finaisAoVivo'),
        },
        {
          property: "twitter:description",
          content: this.$i18n.t('landingpreevento.assista'),
        },
        {
          property: "twitter:image",
          content: this.$url + "assets/images/metas/home.png",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>