<template>
  <div></div>
</template>

<script>
  export default {
    name: "WhiteboardCreateByTemplate",
    mounted() {

      // To create new books with template, use:
      // /lms/LMS_SLUG/whiteboard/template/create?template_id=TEMPLATE_ID

      let root_ = this;

      root_.$store.dispatch("LMS/toggleLoading", true);

      let template_id = root_.$route.query.template_id ?? null;

      if(template_id === null) {
        root_.$store.dispatch("LMS/toggleLoading", false);
        root_.$router.push({
          name: 'LmsStudentBook',
          params: {
            lmsSlug: root_.$route.params.lmsSlug
          }
        });
        return;
      }

      let payload = {
        "slug": root_.$route.params.lmsSlug,
        "template_id": template_id
      }

      root_.$store.dispatch("Whiteboard/createBook", payload)
          .then(() => {

            root_.$router.push({
              name: 'Whiteboard',
              params: {
                lmsSlug: root_.$route.params.lmsSlug
              }
            });

          })
          .catch(error => {
            console.error("create book error => ", error);
            root_.$parent.wait(false);
          })
          .finally(() => {
            root_.$store.dispatch("LMS/toggleLoading", false);
          });


    }
  }
</script>

<style scoped>

</style>