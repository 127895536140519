<template>
    <section class="lives">
        <div class="lives_container">
            <div class="title">
                <h1>Lives</h1>
                <span>Selecione uma live para iniciar a apresentar</span>
            </div>
            <div class="live_options">
                <div v-if="this.lives.lenght <= 0" class="no_lives">
                    <span>Não há lives disponíveis</span>
                </div>
                <div class="live" :key="index" v-for="(live, index) in lives">
                    <div v-if="live.started && live.streamer_online != id" class="live_unavailable" title="Esta live já está sendo transmitida por outro usuário">
                        <span>{{live.title}}</span>
                        <div class="live_background" :style="'background: center center/cover url(' + live.background_image_url+')'">
                            <img v-if="live.background_image_url == null" src="../assets/img/no_image.png" style="margin-right: 9%;" alt="">
                        </div>
                    </div>
                    <div v-else class="live_available" v-on:click="startLive(live)">
                        <span>{{live.title}}</span>
                        <div class="live_background" :style="'background: center center/cover url(' + (live.background_image_url != null ? live.background_image_url : './assets/img/no_image.png') +')'">
                            <img v-if="live.background_image_url == null" src="../assets/img/no_image.png" style="margin-right: 9%;" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="this.modal_lives" style="position: fixed; top: 73px; left: 0px;overflow-y:scroll;height: 90vh;">
            <div class="modals__content--header alignVertical">
                <p>{{ title }} </p>
                <a href="#" class="close" data-response="0" v-on:click="modal_lives = false">
                    <img src="assets/icons/modals/x.svg" alt="" >
                </a>
            </div>
            <iframe :src="url" v-if="url != ''" style="z-index: 10000; width:100vw; height:100vh;" frameborder="0" allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="yes"></iframe>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex';
    import axios from 'axios';

    export default {
        name: "Lives",
        computed: {
            ...mapGetters([
                'token', 'id', 'is_streamer'
            ])
        },
        data() {
            return { 
                url: '',
                lives: '',
                title: '',
                modal_lives: false
            }
        },
        mounted() {
            if (!this.is_streamer) {
                this.$router.push({name:'Map'});
            }
            console.log(this.token)
            this.$app.wait(true)
            let headers = {
                "Content-Type": "application/json;charset=UTF-8",
                Access: "application/json",
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
            }
            axios.get(this.$url_api + 'api/streamer_lives', {headers: headers}).then((res) => {
                this.lives = res.data.lives
                this.$app.wait(false)
                console.log(this.lives)
            }).catch((error) => {
                console.log(error)
            })
        },
        methods: {
            startLive(live) {
                this.url = this.$url_api + 'cockpit/front/'+ live.id + '?t='+this.token
                this.title = live.title
                this.modal_lives = true
                console.log(this.url)
            }
        }
    }
</script>