<template>
    <div v-show="enabled">
        <iframe class="chat-front-iframe" id="chat-front-iframe" ref="chat-front-iframe" :src="$url_api + 'embed/chat/' + this.template_id + '/front'" allow="fullscreen *;microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer *; magnetometer *; gyroscope *; autoplay *;" scrolling="no"></iframe>
    </div>
</template>
<script>
export default
{
    name: 'AviaController',
    props: ['template_id', 'unity_id'],
    data()
    {
        return {
            enabled: false
        }
    },
    methods:
    {
        start()
        {
            this.enabled = true;
            // document.getElementById('chat-front-iframe').contentWindow.postMessage( { message_type: 'external', type: 'ai_message', subtype: 'speech_start' }, "*");
            this.$refs['chat-front-iframe'].contentWindow.postMessage( { message_type: 'external', type: 'ai_message', subtype: 'speech_start', unity_id: this.unity_id }, "*");
        },
        close()
        {
            this.$parent.currentAvia = null;
            this.$parent.sendToUnity("AviaChatClosed", {});
            this.enabled = false;
        },
        enterBalloon()
        {
            this.sendToUnity("EnterBalloon", Number.parseInt(this.unity_id));
        },
        audio(unityData)
        {
            this.sendToUnity('SetVoice', { audioclip: unityData.audioclip, txt: unityData.txt, unity_id: this.unity_id } );
        },
        audioStop()
        {
            // this.$parent.currentAvia = null;
            // this.$parent.sendToUnity("AviaChatClosed",{});
        },
        sendToUnity(event_type, unity_data)
        {
            this.$parent.sendToUnity(event_type, unity_data);
        }
    }
}
</script>
<style lang="scss" scoped>
    .chat-front-iframe
    {
        height: calc(100% - 75px);
        left: 0;
        position: fixed;
        top: 75px;
        width: 350px;
        white-space: nowrap;
        z-index: 999;
    }
</style>