<template>

  <main class="master-container-library">
    <section class="container-library-list">
      <div class="container-spacing">
        <h3 class="title-page">{{ $t('biblioteca.biblioteca') }}</h3>
        <section class="container-list-card">
          <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>
          <!-- daqui pra baixo é tudo genérico -->
           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>

           <div class="card-library">
            <div class="container-image">
              <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
            </div>
            <p class="file-title">{{ $t('biblioteca.loremIpsumDolorSit') }}</p>
            <p class="file-author">{{ $t('biblioteca.jooGomes') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>
        </section>
        <div class="schedule--events--eventCount">
                <img src="assets/images/schedule/left.svg">
                <div class="schedule--events--eventCount--textCount">
                    <p class="schedule--events--eventCount--textCount--active">
                        01
                    </p>
                    <p class="schedule--events--eventCount--textCount--bar">
                        /
                    </p>
                    <p class="schedule--events--eventCount--textCount--max">
                        04
                    </p>
                </div>
                <img src="assets/images/schedule/right.svg">
            </div> 
      </div>
  </section>

    <section class="container-especification-library">
      <div class="card-especification">
        <h2 class="title-especification">{{ $t('biblioteca.loremIpsumDolorSit') }}</h2> 
        <div class="container-especification">
                <img src="../../public/assets/images/biblioteca/icone-generic.svg" alt="ícone genérico">
        </div>
        <div class="container-user-especification">
          <img src="../../public/assets/images/biblioteca/user-icon.svg" alt="icone do usuário">
          <div>
            <p class="file-author-name">{{ $t('biblioteca.jooDaSilva') }}</p>
            <p class="file-date">{{ $t('biblioteca.h') }}</p>
          </div>
        </div>
          <p class="details-especification">{{ $t('biblioteca.detalhes') }}</p>
        <div class="container-file-especification">
          <span class="generic-especification">{{ $t('biblioteca.tipo') }}</span>
          <span class="generic-especification">{{ $t('biblioteca.powerPoint') }}</span>
        </div>
         <div class="container-file-especification">
          <span class="generic-especification">{{ $t('biblioteca.tamanho') }}</span>
          <span class="generic-especification">{{ $t('biblioteca.mb') }}</span>
        </div>
      </div>
    </section>
  </main>

</template>

<script>

  export default {
    name: 'Biblioteca'
  }
</script>