<template>
    <div id="tickets" class="tickets" ref="tickets">
        <div class="container">
            <div class="container-content">
                <main>
                    <section class="spacing-master">
                        <div class="filtro" style="display:none">
                            <div :class="{'opcao':true, 'left': true, 'selected':this.currentFilter=='ind'}" v-on:click="changeFilter('ind')">
                                Individuais
                            </div>
                            <div :class="{'opcao':true, 'right': true, 'selected':this.currentFilter=='vip'}" v-on:click="changeFilter('vip')">
                                VIP Room
                            </div>
                        </div>
                        <div v-if="mode=='buy'" class="buy_tip">
                            <div>{{this.$i18n.t('ticket.useMoedas')}}</div>
                            <div class="link" v-on:click="como_funciona=true">
                                {{this.$i18n.t('ticket.comoFunciona')}}
                            </div>
                        </div>
                        <div v-if="mode=='buy'" class="buy_order">
                            <div class="option right">
                                <label><input type="radio" v-on:click="setOrder('date_start')" name="order" checked style="margin-right:10px;transform: scale(2);">{{this.$i18n.t('ticket.dataRecente')}}</label>
                            </div>
                            <div class="option left">
                                <label><input type="radio" v-on:click="setOrder('coin_price')" name="order">{{this.$i18n.t('ticket.precoBarato')}}</label>
                            </div>
                        </div>
                        <div class="new-list-wrap">
                            <div v-if="$app.user_already_have_ticket" class="new-list notifyuser">
                                <div class="fmodel-content">
                                    <div class="content-panel">{{ $t('ticket.jaPossui') }}</div>
                                    <div class="botoes">
                                        <button class="button__blue" v-on:click="$parent.closeModal()">
                                            {{ $t('ticket.voltar') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="$app.ticket_gate_doesnt_exist" class="new-list notifyuser">
                                <div class="fmodel-content">
                                    <div class="content-panel">{{ $t('ticket.naoExiste') }}</div>
                                    <div class="botoes">
                                        <button class="button__blue" v-on:click="$parent.closeModal()">
                                            {{ $t('ticket.voltar') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="anyTicketToShow()" class="new-list">
                                    <div v-for="(ticket, keyTicketResult) in tickets" :key="keyTicketResult">
                                        <div v-if="!$app.ticket_gate_id || $app.ticket_gate_id == ticket.id">
                                            <div v-if="canShowThisTicket(ticket)" class="ticket">
                                                <div class="img" :style="'background-image: url(' + ticket.thumbnail + ');'"></div>
                                                <div class="ticket-content">
                                                    <div class="content-panel">
                                                        <div class="ticket-title">{{ticket.title}}</div>
                                                        <div >{{ticket.subtitle}}</div>
                                                        <div class="detail">
                                                            <div class="panel">
                                                                <div class="item">                                               
                                                                    <div class="icon">
                                                                        <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.92188 10.7487C7.50521 11.2956 8.19792 11.5691 9 11.5691C9.80208 11.5691 10.4766 11.2956 11.0234 10.7487C11.6068 10.1654 11.8984 9.47271 11.8984 8.67062C11.8984 7.86854 11.6068 7.19406 11.0234 6.64719C10.4766 6.06385 9.80208 5.77219 9 5.77219C8.19792 5.77219 7.50521 6.06385 6.92188 6.64719C6.375 7.19406 6.10156 7.86854 6.10156 8.67062C6.10156 9.47271 6.375 10.1654 6.92188 10.7487ZM3.20312 2.92844C4.80729 1.32427 6.73958 0.522186 9 0.522186C11.2604 0.522186 13.1745 1.32427 14.7422 2.92844C16.3464 4.49614 17.1484 6.41021 17.1484 8.67062C17.1484 9.80083 16.8568 11.0951 16.2734 12.5534C15.7266 14.0118 15.0521 15.379 14.25 16.655C13.4479 17.931 12.6458 19.1342 11.8438 20.2644C11.0781 21.3581 10.4219 22.2331 9.875 22.8894L9 23.8191C8.78125 23.5639 8.48958 23.2357 8.125 22.8347C7.76042 22.3972 7.10417 21.5586 6.15625 20.3191C5.20833 19.043 4.36979 17.8217 3.64062 16.655C2.94792 15.4519 2.3099 14.1029 1.72656 12.6081C1.14323 11.1133 0.851562 9.80083 0.851562 8.67062C0.851562 6.41021 1.63542 4.49614 3.20312 2.92844Z" fill="#404040"/>
                                                                        </svg>                                                        
                                                                    </div>
                                                                    <div class="txt">{{getTicketSessionName(ticket)}}</div>
                                                                </div>
                                                                <div class="item">                                               
                                                                    <div class="icon">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.6016 5.89041V12.0154L17.8516 15.1326L16.9766 16.6092L10.8516 12.8904V5.89041H12.6016ZM5.38281 18.3592C7.24219 20.1821 9.44792 21.0935 12 21.0935C14.5521 21.0935 16.7396 20.1821 18.5625 18.3592C20.4219 16.4998 21.3516 14.2941 21.3516 11.742C21.3516 9.18989 20.4219 7.00239 18.5625 5.17947C16.7396 3.3201 14.5521 2.39041 12 2.39041C9.44792 2.39041 7.24219 3.3201 5.38281 5.17947C3.5599 7.00239 2.64844 9.18989 2.64844 11.742C2.64844 14.2941 3.5599 16.4998 5.38281 18.3592ZM3.74219 3.53885C6.03906 1.24197 8.79167 0.0935364 12 0.0935364C15.2083 0.0935364 17.9427 1.24197 20.2031 3.53885C22.5 5.79927 23.6484 8.53364 23.6484 11.742C23.6484 14.9503 22.5 17.7029 20.2031 19.9998C17.9427 22.2602 15.2083 23.3904 12 23.3904C8.79167 23.3904 6.03906 22.2602 3.74219 19.9998C1.48177 17.7029 0.351562 14.9503 0.351562 11.742C0.351562 8.53364 1.48177 5.79927 3.74219 3.53885Z" fill="#404040"/>
                                                                        </svg>                            
                                                                    </div>
                                                                    <div class="txt">{{getTicketTime(ticket)}}</div>
                                                                </div>
                                                            </div>
                                                            <div class="panel">
                                                                <div class="item">                                               
                                                                    <div class="icon">
                                                                        <img src="assets/icons/myPerfil/coin.png" height=25 alt=""/>
                                                                    </div>
                                                                    <div class="txt">
                                                                        {{ticket.coin_price}} {{$i18n.t('package.coins')}}
                                                                    </div>
                                                                </div>
                                                                <div class="item">                                               
                                                                    <div class="icon">
                                                                        <svg width="24" height="22" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M21.3516 23.5768V8.42838H2.64844V23.5768H21.3516ZM21.3516 2.57681C21.9714 2.57681 22.5 2.81379 22.9375 3.28775C23.4115 3.76171 23.6484 4.30858 23.6484 4.92838V23.5768C23.6484 24.1966 23.4115 24.7435 22.9375 25.2174C22.5 25.6914 21.9714 25.9284 21.3516 25.9284H2.64844C2.02865 25.9284 1.48177 25.6914 1.00781 25.2174C0.570312 24.7435 0.351562 24.1966 0.351562 23.5768V4.92838C0.351562 4.30858 0.570312 3.76171 1.00781 3.28775C1.48177 2.81379 2.02865 2.57681 2.64844 2.57681H3.85156V0.22525H6.14844V2.57681H17.8516V0.22525H20.1484V2.57681H21.3516Z" fill="#404040"/>
                                                                        </svg>
                                                                    </div>
                                                                    <div class="txt">{{getTicketDateRange(ticket)}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="botoes">
                                                            <div v-if="mode=='buy'">
                                                                <button v-if="!tickets_user.includes(ticket.id)" :data-id="ticket.id" :data-coin="ticket.coin_price" :data-title="ticket.title" class="button__blue" v-on:click="buyWithCoinConfirm(ticket)">
                                                                    {{ $t('ticket.comprar') }}
                                                                </button>
                                                            </div>
                                                            <div v-else>
                                                                <button v-if="tickets_user.includes(ticket.id)" :data-id="ticket.id" :data-coin="ticket.coin_price" :data-title="ticket.title" 
                                                                    :class="{'button__blue':true, 'disabled':!canEnterTicketEvent(ticket)}" onclick="alert('ir')" :disabled="!canEnterTicketEvent(ticket)">{{$t('ticket.entrarEvento')}}</button>
                                                                <div v-if="!canEnterTicketEvent(ticket)" class="txt_breve">{{GetTicketInicioString(ticket)}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="!loading" class="none-ticket" style="text-align:center;margin-top:100px;">
                                    ({{ mode == 'buy' ? $t('ticket.nenhumIngressoVenda') : $t('ticket.voceNenhumIngresso') }})
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>

        <div v-if="modal_confirm" id="modal-confirm-buyticket" :class="['modals','bgBlack']">
            <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="modal_confirm = false"></div>
            <div class="modalGerais">
                <div class="modals__content">
                    <div class="link_close" v-on:click="modal_confirm = false">
                        <svg width="18" height="18" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.5443 3.16797L13.7122 12L22.5443 20.832L20.3177 23.0586L11.4857 14.2266L2.65363 23.0586L0.427069 20.832L9.2591 12L0.427069 3.16797L2.65363 0.941406L11.4857 9.77344L20.3177 0.941406L22.5443 3.16797Z" fill="#6D6D6D"/>
                        <path d="M22.5443 3.16797L23.2514 3.87508L23.9585 3.16797L23.2514 2.46086L22.5443 3.16797ZM13.7122 12L13.0051 11.2929L12.298 12L13.0051 12.7071L13.7122 12ZM22.5443 20.832L23.2514 21.5391L23.9585 20.832L23.2514 20.1249L22.5443 20.832ZM20.3177 23.0586L19.6106 23.7657L20.3177 24.4728L21.0248 23.7657L20.3177 23.0586ZM11.4857 14.2266L12.1928 13.5195L11.4857 12.8123L10.7786 13.5195L11.4857 14.2266ZM2.65363 23.0586L1.94652 23.7657L2.65363 24.4728L3.36074 23.7657L2.65363 23.0586ZM0.427069 20.832L-0.280038 20.1249L-0.987145 20.832L-0.280038 21.5391L0.427069 20.832ZM9.2591 12L9.96621 12.7071L10.6733 12L9.96621 11.2929L9.2591 12ZM0.427069 3.16797L-0.280038 2.46086L-0.987145 3.16797L-0.280038 3.87508L0.427069 3.16797ZM2.65363 0.941406L3.36074 0.2343L2.65363 -0.472807L1.94652 0.2343L2.65363 0.941406ZM11.4857 9.77344L10.7786 10.4805L11.4857 11.1877L12.1928 10.4805L11.4857 9.77344ZM20.3177 0.941406L21.0248 0.2343L20.3177 -0.472807L19.6106 0.2343L20.3177 0.941406ZM21.8371 2.46086L13.0051 11.2929L14.4193 12.7071L23.2514 3.87508L21.8371 2.46086ZM13.0051 12.7071L21.8371 21.5391L23.2514 20.1249L14.4193 11.2929L13.0051 12.7071ZM21.8371 20.1249L19.6106 22.3515L21.0248 23.7657L23.2514 21.5391L21.8371 20.1249ZM21.0248 22.3515L12.1928 13.5195L10.7786 14.9337L19.6106 23.7657L21.0248 22.3515ZM10.7786 13.5195L1.94652 22.3515L3.36074 23.7657L12.1928 14.9337L10.7786 13.5195ZM3.36074 22.3515L1.13418 20.1249L-0.280038 21.5391L1.94652 23.7657L3.36074 22.3515ZM1.13418 21.5391L9.96621 12.7071L8.55199 11.2929L-0.280038 20.1249L1.13418 21.5391ZM9.96621 11.2929L1.13418 2.46086L-0.280038 3.87508L8.55199 12.7071L9.96621 11.2929ZM1.13418 3.87508L3.36074 1.64851L1.94652 0.2343L-0.280038 2.46086L1.13418 3.87508ZM1.94652 1.64851L10.7786 10.4805L12.1928 9.06633L3.36074 0.2343L1.94652 1.64851ZM12.1928 10.4805L21.0248 1.64851L19.6106 0.2343L10.7786 9.06633L12.1928 10.4805ZM19.6106 1.64851L21.8371 3.87508L23.2514 2.46086L21.0248 0.2343L19.6106 1.64851Z" fill="#6D6D6D"/>
                        </svg>                        
                    </div>
                    <div class="modals__content--body modal_confirm_ticket">
                        <div class="ticket">
                            <div class="img" :style="'background-image: url(' + this.ticket_confirm.thumbnail + ');'"></div>
                            <div class="ticket-content">
                                <div class="content-panel">
                                    <div class="modal-title">{{this.$i18n.t('ticket.buyConfirma')}}</div>
                                    <div class="ticket-title">{{this.ticket_confirm.title}}</div>
                                    <div >{{this.$i18n.t('ticket.buySure')}}</div>
                                    <div class="detail">
                                        <div class="item">                                               
                                            <div class="label">
                                                {{this.$i18n.t('ticket.ingressoValue')}}:
                                            </div>
                                            <div class="icon">
                                                <img src="assets/icons/myPerfil/coin.png" height=25 alt=""/>
                                            </div>
                                            <div class="txt">
                                                {{this.ticket_confirm.coin_price}} {{this.$i18n.t('package.coins')}}
                                            </div>
                                        </div>
                                        <div class="item">                                               
                                            <div class="label">
                                                {{this.$i18n.t('ticket.moedasDisponiveis')}}:
                                            </div>
                                            <div class="icon">
                                                <img src="assets/icons/myPerfil/coin.png" height=25 alt=""/>
                                            </div>
                                            <div class="txt">
                                                {{TotalCoins}} {{this.$i18n.t('package.coins')}}
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-top:30px">
                                        <button v-if="this.buy_package_on" :data-ticket="this.ticket_confirm.id" v-on:click="buyWithCoin()" class="button__blue">
                                            {{ getBuyButtonTxt() }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modals__content--footer flex"></div>
                </div>
            </div>
        </div>
        <div v-if="modal_notification" id="modal-notifications" :class="['modals','bgBlack']">
            <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="como_funciona=false"></div>
            <div class="modalGerais">
                <div class="modals__content">
                    <div class="modals__content--header alignVertical">
                        <p>{{this.$i18n.t('ticket.compraRealizada')}}</p>
                        <a class="btn-0" v-on:click="closeModalNotification" ref="btn-close">
                            <img src="assets/icons/modals/x.svg" alt="">
                        </a>
                    </div>
                    <div class="modals__content--body flex flex-wrap text-center">
                        <div class="col-12 flex alignVertical">
                            <p>{{ this.ticket_buy_response.message }}</p>
                        </div>
                        <div class="col-12 flex alignVertical" style="margin-top:20px">
                            <a class="button__blue" v-on:click="closeModalNotification">Ok</a>
                        </div>
                    </div>
                    <div class="modals__content--footer flex"></div>
                </div>
            </div>
        </div>

        <div id="modal_how_works" :class="['modals','bgBlack',{'d-none':!this.como_funciona}]">
            <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="como_funciona=false"></div>
            <div class="modalGerais">
                <div class="modals__content">
                    <div style="overflow:hidden">
                        <div class="top">
                            <a class="close" data-response="0" v-on:click="como_funciona=false">
                                <svg width="18" height="18" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.5443 3.16797L13.7122 12L22.5443 20.832L20.3177 23.0586L11.4857 14.2266L2.65363 23.0586L0.427069 20.832L9.2591 12L0.427069 3.16797L2.65363 0.941406L11.4857 9.77344L20.3177 0.941406L22.5443 3.16797Z" fill="#6D6D6D"/>
                                <path d="M22.5443 3.16797L23.2514 3.87508L23.9585 3.16797L23.2514 2.46086L22.5443 3.16797ZM13.7122 12L13.0051 11.2929L12.298 12L13.0051 12.7071L13.7122 12ZM22.5443 20.832L23.2514 21.5391L23.9585 20.832L23.2514 20.1249L22.5443 20.832ZM20.3177 23.0586L19.6106 23.7657L20.3177 24.4728L21.0248 23.7657L20.3177 23.0586ZM11.4857 14.2266L12.1928 13.5195L11.4857 12.8123L10.7786 13.5195L11.4857 14.2266ZM2.65363 23.0586L1.94652 23.7657L2.65363 24.4728L3.36074 23.7657L2.65363 23.0586ZM0.427069 20.832L-0.280038 20.1249L-0.987145 20.832L-0.280038 21.5391L0.427069 20.832ZM9.2591 12L9.96621 12.7071L10.6733 12L9.96621 11.2929L9.2591 12ZM0.427069 3.16797L-0.280038 2.46086L-0.987145 3.16797L-0.280038 3.87508L0.427069 3.16797ZM2.65363 0.941406L3.36074 0.2343L2.65363 -0.472807L1.94652 0.2343L2.65363 0.941406ZM11.4857 9.77344L10.7786 10.4805L11.4857 11.1877L12.1928 10.4805L11.4857 9.77344ZM20.3177 0.941406L21.0248 0.2343L20.3177 -0.472807L19.6106 0.2343L20.3177 0.941406ZM21.8371 2.46086L13.0051 11.2929L14.4193 12.7071L23.2514 3.87508L21.8371 2.46086ZM13.0051 12.7071L21.8371 21.5391L23.2514 20.1249L14.4193 11.2929L13.0051 12.7071ZM21.8371 20.1249L19.6106 22.3515L21.0248 23.7657L23.2514 21.5391L21.8371 20.1249ZM21.0248 22.3515L12.1928 13.5195L10.7786 14.9337L19.6106 23.7657L21.0248 22.3515ZM10.7786 13.5195L1.94652 22.3515L3.36074 23.7657L12.1928 14.9337L10.7786 13.5195ZM3.36074 22.3515L1.13418 20.1249L-0.280038 21.5391L1.94652 23.7657L3.36074 22.3515ZM1.13418 21.5391L9.96621 12.7071L8.55199 11.2929L-0.280038 20.1249L1.13418 21.5391ZM9.96621 11.2929L1.13418 2.46086L-0.280038 3.87508L8.55199 12.7071L9.96621 11.2929ZM1.13418 3.87508L3.36074 1.64851L1.94652 0.2343L-0.280038 2.46086L1.13418 3.87508ZM1.94652 1.64851L10.7786 10.4805L12.1928 9.06633L3.36074 0.2343L1.94652 1.64851ZM12.1928 10.4805L21.0248 1.64851L19.6106 0.2343L10.7786 9.06633L12.1928 10.4805ZM19.6106 1.64851L21.8371 3.87508L23.2514 2.46086L21.0248 0.2343L19.6106 1.64851Z" fill="#6D6D6D"/>
                                </svg>                        
                            </a>
                        </div>
                    </div>
                    <div class="title">
                        {{this.$i18n.t('ticket.comoFunciona')}}
                    </div>
                    <div class="modals__content--body alignVertical">
                        <div>
                            <div class="col-12 txt" v-html="this.$i18n.t('ticket.comoFuncionaTxt')"></div>
                            <div class="alignVertical col-12">
                                <button class="button__blue" v-on:click="como_funciona=false">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        

    </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'TicketNew',
    props: ['mode'],
    computed: {
        ...mapGetters([
            'logged', 'token', 'TotalCoins', "id", "age", 'nickname', 'switch'
        ]),
    },
    data() {
        return {
            currentFilter: 'ind',
            order: 'date_start',
            como_funciona: false,
            tickets: [],
            tickets_user: [],
            ticket_confirm: null,
            ticket_buy_response: [],
            modal_confirm: false,
            modal_notification: false,
            loading: false,
            buy_package_on: true
        }
    },
    components: {

    },
    methods: {
        ...mapActions([
            'update_coin'
        ]),
        changeFilter(f){
            this.currentFilter = f;
        },
        setOrder(order){
            this.order = order;
            this.loadTickets();
        },
        GetTicketInicioString(t){
            if (t.date_start && t.date_end)
            {
                return 'Início dia '+t.date_start.substr(8,2)+'/'+t.date_start.substr(5,2)+' às '+t.date_start.substr(11,5);
            }
            return '';
        },
        canEnterTicketEvent(t){
            if (t.date_start && t.date_end)
            {
                let agora = new Date();
                let ini = new Date(t.date_start.replace(' ','T'));
                console.log(t.date_start.replace(' ','T'));
                let fim = new Date(t.date_end);
                return (agora >= ini && agora <= fim);
            }
            return true;
        },
        tryCatchWait(b){
            try {
                this.$parent.wait(b);
            } catch(e){
                this.$app.wait(b);
            }
        },
        loadUserTickets() {
            this.tickets = [];
            this.loading = true;
            // this.$parent.wait(true);
            this.tryCatchWait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetTicketsUser', { headers:headers, params:{'order': this.order} }).then(response => this.loadUserTicketsResponse(response)).catch(error => this.loadUserTicketsError(error.response));
        },
        loadUserTicketsResponse(obj) {
            if (obj.data.tickets.length > 0) {
                this.tickets    = obj.data.tickets;
            }
            this.tickets = Object.values(this.tickets);
            console.log(this.tickets);
            this.loading = false;
            // this.$parent.wait(false);
            this.tryCatchWait(false);
        },
        loadUserTicketsError(obj) {
            console.log(obj);
            // this.$parent.wait(false);
            this.tryCatchWait(false);
        },
        loadTickets() {
            this.tickets = [];
            this.loading = true;
            // this.$parent.wait(true);
            this.tryCatchWait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            // axios.get(this.$url_api + 'api/GetTickets', { headers:headers, params:{'show_all_dates':1,'order': this.order} }).then(response => this.loadTicketsResponse(response)).catch(error => this.loadTicketsError(error.response));
            axios.get(this.$url_api + 'api/GetTickets', { headers:headers, params:{'order': this.order} }).then(response => this.loadTicketsResponse(response)).catch(error => this.loadTicketsError(error.response));
        },
        loadTicketsResponse(obj) {
            if (obj.data.tickets.length > 0) {
                this.tickets = obj.data.tickets;
            }
            this.tickets = Object.values(this.tickets);
            this.tickets_user = Object.values(obj.data.user_tickets);
            this.loading = false;
            // this.$parent.wait(false);
            this.tryCatchWait(false);
        },
        loadTicketsError(obj) {
            console.log(obj);
            // this.$parent.wait(false);
            this.tryCatchWait(false);
        },
        canShowThisTicket(t){
            if (this.mode == 'buy'){
                return !this.tickets_user.includes(t.id);
            } 
            else {
                // return this.tickets_user.includes(t.id)
                return this.tickets;
            }
        },
        getBuyButtonTxt(){
            if (this.ticket_confirm)
            {
                if (parseInt(this.TotalCoins) < this.ticket_confirm.coin_price)
                    return this.$i18n.t('package_buy.title');
                else
                
                    return this.$i18n.t('ticket.comprar');
            }               
            else
                return '';      
        },
        getTicketDateRange(t){
            if (t.date_start && t.date_end)
            {
                return t.date_start.substr(8,2)+'/'+t.date_start.substr(5,2)+
                        ' a '+
                            t.date_end.substr(8,2)+'/'+t.date_end.substr(5,2);
            }
            return '-';
        },
        getTicketSessionName(t){
            if (t.rooms && t.rooms.length > 0)
            {
                if (t.rooms[0].title && t.rooms[0].title.length > 0)
                    return t.rooms[0].title;
                else
                    return t.rooms[0].name;
            }
            return '-';
        },
        getTicketTime(t){
            if (t.date_start)
            {
                return t.date_start.substr(11,2)+'h'+t.date_start.substr(14,2);
            }
            return '-';
        },
        anyTicketToShow(){
            if (this.mode == 'buy'){
                if (this.tickets.length > 0)
                {
                    var res = false;
                    var _root = this;
                    this.tickets.forEach(t=>{
                        if (!_root.tickets_user.includes(t.id))
                            res = true;
                    })
                    return res;
                }
                else 
                    return false;
            }
            else {
                // return this.tickets_user.length > 0;
                return this.tickets.length > 0;
            }
        },
        buyWithCoinConfirm(t) {
            this.ticket_confirm = t;
            this.modal_confirm = true;
            if(!this.switch.finance_profile_menu && parseInt(this.TotalCoins) < this.ticket_confirm.coin_price) {
                this.buy_package_on = false;
            }
        },
        buyWithCoin() {
            if (parseInt(this.TotalCoins) < this.ticket_confirm.coin_price)
            {
                this.modal_confirm = false;
                try{
                    this.$parent.changeTab('finance');
                } catch(e) {
                    e;
                    this.$app.modal_ticket_gate = false;
                }
                this.$app.modal_package = true;
            }
            else
            {
                this.loading = true;
                // this.$parent.wait(true);
                this.tryCatchWait(true);
                let id = this.ticket_confirm.id;
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale,
                    Authorization: `Bearer ${this.token}`
                };
                let form_data = new FormData();
                form_data.append('ticket_id', id);
                axios.post(`${this.$url_api}api/BuyTicket`, form_data, { headers: headers })
                    .then(response => this.buyWithCoinResponse(response))
                    .catch(error => this.buyWithCoinError(error.response));
            }
            //e.preventDefault();
        },
        buyWithCoinResponse(obj) {
            this.loading = false;
            // this.$parent.wait(false);
            this.tryCatchWait(false);
            this.modal_confirm = false;
            this.modal_notification = true;
            this.ticket_buy_response.title = this.$i18n.t('ticket.comprar');
            this.ticket_buy_response.message = obj.data.success;
            this.update_coin(Number(obj.data.user.coins));
            //this.updateTicketCard(obj);
            this.loadTickets();
        },
        buyWithCoinError(obj) {
            this.loading = false;
            // this.$parent.wait(false);
            this.tryCatchWait(false);
            this.modal_confirm = false;
            this.modal_notification = true;
            this.ticket_buy_response.title = this.$i18n.t('ticket.erro');
            this.ticket_buy_response.message = '';
            for (const key in obj.data.errors) {
                this.ticket_buy_response.message += obj.data.errors[key];
            }
            // console.log(obj);
            // console.log(obj.data.errors);
        },
        // updateTicketCard(obj) {
        //     let el = document.getElementById("card_"+obj.data.ticket.id).querySelector('.container-img-ticket');
        //     let span = document.createElement('span');
        //     let text = document.createTextNode(`${this.$t("ticket.comprado")}`);
        //     span.classList.add('bought');
        //     span.appendChild(text);
        //     el.appendChild(span);
        //     document.getElementById("button_remove_"+obj.data.ticket.id).remove();
        // }
        closeModalNotification() {
            this.modal_notification = false
            // if( this.$parent.openModal ) {
            //     this.$parent.closeModal();
            // }
            // if( this.$app.modal_ticket_gate ) {
            //     this.$parent.closeModal();
            // }

            var hash = window.location.hash;
            if( hash.includes('?') ) {
                // Extract the query string part (after ?)
                var queryString = hash.split('?')[1];

                // Use URLSearchParams to parse the query string
                var params = new URLSearchParams(queryString);

                // Get the 'r' parameter if it exists
                var redirectParam = params.get('r');

                // If 'r' exists, handle your logic
                if (redirectParam) {
                    window.location.href = `#${redirectParam}`;
                }
            } else {
                if( this.$app.modal_ticket_gate ) {
                    this.$parent.closeModal();
                }
            }
        }
    },
    created() {

    },
    mounted() {
        //this.loadTickets();
    },
    beforeDestroy() {

    },
}
</script>

<style lang="scss" scoped>
.ticket-gate-modal-wrap .tickets .new-list-wrap{
    max-height: 40vh !important;
}
</style>
