<template>
    <div id="product">
        <div class="box" id="product-container">
            <div>
                <!--<img width="140" :src="$url + 'produtos/' + id + '.webp'">-->
                <img v-if="this.list.thumb" width="140" :src="$parent.url_oracle + this.list.thumb">
            </div>
            <div>
                <!--let html = '';
                 html += '<li>';
                html += '<div>';
                    html += '<img src="produtos/' + id + '.webp" width="200">';
                    html += '</div>';
                html += '<div>';
                    html += '<h3>' + r.description + '</h3><br>';
                    html += '<h3>$ ' + r.price + '</h3>';
                    html += '<button class="btn-1" style="margin:10px 0;" onclick="createCart(\'' + r.id + '\', 1)" type="button">Adicionar ao carrinho</button>';
                    html += '</div>';
                html += '</li>';-->

                <h2>{{ list.description }}</h2>
                <h2 v-bind:class="{ fm : (list.sale_price), riscado:(list.sale_price)  }">$ {{ list.price ? String(Number(list.price).toFixed(2)).replace(".", ",") : '...' }}</h2>
                <h2 v-if="list.sale_price">$ {{ list.sale_price ? String(Number(list.sale_price).toFixed(2)).replace(".", ",") : '...' }}</h2>
                <br>
                <div v-if="loading" v-text="$ml.get('produto.carregando')"></div>
                <h3 v-text="$ml.get('produto.quantidade')"></h3>
                <ul>
                    <li><a class="less" v-on:click="decrement">-</a></li>
                    <li id="product-count">{{ count }}</li>
                    <li><a class="more" v-on:click="increment">+</a></li>
                </ul>
                <!--<div v-if="in_cart">
                    <b>{{ in_cart }}</b> {{ ((Number(in_cart) > 1) ? 'ítens' : 'ítem') }} desse produto já {{ ((Number(in_cart) > 1) ? 'adicionados' : 'adicionado') }} no carrinho.
                </div>-->
            </div>
            <div id="product-button">
                <ul>
                    <li>
                        <!--<router-link class="btn-0" to="/" ref="btn-close" v-text="$ml.get('produto.cancelar')"></router-link>-->
                        <a class="btn-0" href="#" v-on:click="$parent.product_id = null;$parent.product = false" v-text="$ml.get('produto.cancelar')"></a>
                    </li>
                    <li><button class="btn-1" ref="btn-submit" type="button" v-on:click="add">{{ btn_submit_value }}</button></li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import axios from "axios";

    export default {
        name: "Produto",
        props: ['product_id'],
        computed: {
            ...mapGetters([
                'token', 'logged', 'products', 'products_ids', 'products_n'
            ])
        },
        data() {
            return {
                btn_submit_value: this.$ml.get('produto.add'),
                count           : 1,
                //id              : this.$route.params.id,
                id              : this.product_id,
                in_cart         : null,
                loading         : false,
                list            : []
            }
        },
        methods: {
            ...mapActions([
                'update', 'update_cart_products'
            ]),
            increment(e) {
                this.count++;

                e.preventDefault();
            },
            decrement (e) {
                if (this.count > 1)
                    this.count--;

                e.preventDefault();
            },
            block(v) {
                this.btn_submit_value =(v ? (this.$ml.get('produto.wait') + "...") : this.$ml.get('produto.add'));
                this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
                this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
            },
            add(e) {
                console.log('this.list aham -> ', this.list)
                if (this.list) {
                    this.block(true);

                    //let l_ = { id:this.list.id, quantity:this.count, description:this.list.description, price:this.list.price };
                    //arrumar aqui
                    //let l_ = { id:this.list.id, quantity:this.count, description:this.list.description, price:this.list.price, orderId:'', amount:(this.count * this.list.price) };
                    let l_ = { id:this.list.id, quantity:this.count, description:this.list.description, price:this.list.price, orderId:'', amount:(this.count * this.list.price) };
                    this.update_cart_products(l_);


                    console.log('[ ' + this.list.id + ' ], [ ' + this.products_ids.join(","), ' ], [ ' + this.products_n.join(",") + ' ]');
                    let form_data = new FormData();
                    form_data.append('token', this.token);
                    form_data.append('product_id', this.products_ids.join(","));
                    form_data.append('product_n', this.products_n.join(","));

                    axios.post(this.$url_api + 'store/oracle/add_cart', form_data).then(response => this.addResponse(response));
                }
                else
                    alert('Listagem vazia.');
                e.preventDefault();
            },
            addResponse(obj) {
                console.log(obj);
                this.block(false);
                if (obj.data.errorCode)
                {
                    this.$parent.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    if (obj.data.orderId) {

                        console.log(obj.data);
                        //let l_ = { id:this.list.id, quantity:this.count, description:this.list.description, price:this.list.price, orderId:obj.data.orderId, amount:(this.count * this.list.price) };
                        let l_ = { id:this.list.id, quantity:this.count, description:this.list.description, price:this.list.price, orderId:obj.data.orderId, amount:(this.count * this.list.price) };
                        this.update_cart_products(l_);

                        //this.$router.push('/produto-adicionado-com-sucesso');
                        this.$parent.add_success = true;
                        this.$parent.product_id = null;
                        this.$parent.product = false;
                    } else if (obj.data.errorCode) {
                        alert(obj.data.message);
                    }
                }
            },
            checkCart() {
                let index = this.products_ids.indexOf(this.list.id);
                if (index !== -1) {
                    this.in_cart = this.products[index].quantity;
                    this.count = this.in_cart;
                }
            },
            load() {
                /*
                let index = this.products_ids.indexOf(this.id);
                if (index !== -1) {
                    this.list = this.products[index];
                    this.checkCart();
                } else {*/
                    this.$parent.wait(true);
                    this.loading = true;
                    let form_data = new FormData();
                    form_data.append('id', this.id);
                    form_data.append('l', this.$ml.current);
                    axios.post(this.$url_api + 'store/oracle/produto', form_data).then(response => this.loadResponse(response));
                //}
            },
            loadResponse(obj) {
                if (obj.data.errorCode)
                {
                    //this.$parent.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    console.log('Produto => ', obj.data);
                    //this.list = { id:this.id, description: obj.data.description, price:obj.data.listPrice, thumb:obj.data.primarySmallImageURL};
                    this.list = { id:this.id, description: obj.data.displayName, price:obj.data.childSKUs[0].listPrice, sale_price:obj.data.childSKUs[0].salePrice, thumb:obj.data.primarySmallImageURL};
                    this.loading = false;
                    this.checkCart();
                }
                this.$parent.wait(false);
            }
        },
        mounted() {
            this.load();
        },
        updated() {
            this.btn_submit_value = this.$ml.get('produto.add');
        }
    }
</script>

<style scoped>
    #product
    {
        background-color: rgba(0, 0, 0, .6);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    h1
    {
        box-sizing: border-box;
        text-align: center;
        padding: 10px 0;
    }
    h3
    {
        font-weight: normal;
    }
    #product #product-container
    {
        display: block;
        margin: 150px  auto;
        max-height: 80%;
        max-width: 500px;
        overflow-y: auto;
    }
    #product #product-container div
    {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
    }
    #product #product-container div img
    {
        display: initial;
    }
    @media screen and (max-width: 500px)
    {
        #product-container
        {
            width: calc(100% - 40px);
        }
    }

    #product ul
    {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    #product ul li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 10px;
    }
    #product .less, #product .more
    {
        background-color: black;
        border-radius: 10px;
        color: white !important;
        display: block;
        font-size: 30px;
        font-weight: bold;
        outline: none;
        height: 50px;
        line-height: 50px;
        text-align: center;
        user-select: none;
        width: 70px;
    }
    #product #product-count
    {
        color: #e55944;
        font-size: 35px;
        font-weight: bold;
        height: 50px;
        text-align: center;
        width: 50px;
    }


    #product-button
    {
        width: 100%;
    }
    #product-button > ul > li
    {
        list-style: none;
        display: inline-block;
        width: calc(100% / 2);
    }
</style>