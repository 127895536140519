<template>
    <div v-if="securityCookie" id="security" ref="security">

        <div ref="container_flyout" class="container_flyout">
            <div class="content_flyout">
                <div class="ui bottom flyout start" id="security-flyout">
                    <div class="content">
                        <h3>{{ title }}</h3>
                        <h4 style="margin: 16px 0">{{ $i18n.t('security.privacy_txt') }}</h4>
                        <div id="txt">
                            {{ $i18n.t('security.start_txt') }}
                        </div>
                        <div id="btns">
                            <a href="#" @click="config" class="ui button positive btn-default">{{ $i18n.t('security.btn_config')
                                }}</a>
                            <a href="#" @click="refuse" class="ui button positive btn-default">{{ $i18n.t('security.btn_refuse')
                                }}</a>
                            <a href="#" @click="accept" class="ui button positive btn-default">{{ $i18n.t('security.btn_accept')
                                }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="security-modal" class="ui suimodal large">
            <i class="close icon inside"></i>
            <div class="content">
                <h2 class="p-3">{{ $i18n.t('security.modal_title') }}</h2>
                <div class="ui stackable two column grid">
                    <div class="five wide left attached column">
                        <div class="ui vertical fluid tabular menu" id="security-menu">
                            <a class="item" data-type="about_privacy" @click="btnModalHandler">
                                {{ $i18n.t('security.btn_about_privacy') }}
                            </a>
                            <a class="item" data-type="strictly_necessary" @click="btnModalHandler">
                                {{ $i18n.t('security.btn_strictly_necessary') }}
                            </a>
                            <a v-if="cookiePerformance && cookiePerformance.checked" class="item"
                                data-type="performance" @click="btnModalHandler">
                                {{ $i18n.t('security.btn_performance') }}
                            </a>
                            <a class="item" data-type="functionality" @click="btnModalHandler">
                                {{ $i18n.t('security.btn_functionality') }}
                            </a>
                            <a v-if="cookieAdvertising && cookieAdvertising.checked" class="item"
                                data-type="advertising" @click="btnModalHandler">
                                {{ $i18n.t('security.btn_advertising') }}
                            </a>
                        </div>
                    </div>
                    <div class="eleven wide stretched right attached column p-3 txt">
                        <div class="ui seamless right attached segment modal-body">
                            <div>
                                <div class="ui stackable grid">
                                    <div class="thirteen wide column txt-actions">
                                        <h5>{{ txt.title }}</h5>
                                    </div>
                                    <div class="three wide column txt-actions">
                                        <div v-show="currentType == 'performance' || currentType == 'functionality' || currentType == 'advertising'"
                                            class="ui toggle checkbox">
                                            <input id="security-check" ref="security-check" type="checkbox"
                                                name="public" @change="securityCheckHandler">
                                            <label for="security-check">{{ check_text }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin: 32px 0" v-html="txt.txt" class="security-txt"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions">
                <a id="btn-save-choices" class="ui button left floated btn-default" @click="save">{{
        $i18n.t('security.btn_save') }}</a>
                <a class="ui button btn-default" @click="refuse">{{ $i18n.t('security.btn_refuse') }}</a>
                <a class="ui button btn-default" @click="accept">{{ $i18n.t('security.btn_accept') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'Security',
    computed: {
        ...mapGetters(['logged', 'token', 'name', 'nickname', 'event_id', 'securityCookie', 'switch']),
        title() {
            return (this.switch.Nome_Aba || this.getCookie("switch_Nome_Aba") || "Metaverso");
        }
    },
    data() {
        return {
            adminConfig: null,
            btnModalActive: null,
            btnStartActive: null,
            check_text: null,
            cookieAdvertising: null,
            cookiePerformance: null,
            currentType: null,
            currentTypeStatus: { "performance": true, "functionality": true, "advertising": true },
            data: null,
            txt: []
        }
    },
    components: {},
    methods: {
        ...mapActions(['update_security_cookie', 'update_security_container']),
        /*
        "analytics",
        "analytics_code",
        "matomo_analytics",
        "matomo_analytics_code",
        "comscore",
        "comscore_code",
        "google_tagmanager",
        "google_tagmanager_code"
        */
        addMatomo() {
            if (document.getElementById("cookie-matomo"))
                return;

            if ((this.adminConfig.matomo) && (this.adminConfig.matomo_code != "") && (this.currentTypeStatus['performance'])) {
                let script = document.createElement('script');
                script.innerHTML = `
                    var _mtm = window._mtm = window._mtm || [];
                    var _paq = window._paq = window._paq || [];
                    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                    if(JSON.parse(localStorage.getItem('user'))?.email){
                        _paq.push(["setUserId", JSON.parse(localStorage.getItem('user'))?.email]);
                    }
                    (function() {
                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                        g.async=true; g.id='cookie-matomo-1'; g.src='https://analytics.virtual.town/js/container_${this.adminConfig.matomo_code}.js'; s.parentNode.insertBefore(g,s);
                    })();`;
                script.id = "cookie-matomo";
                document.body.append(script);
            }
        },
        addGoogleTagManager() {
            if (document.getElementById("cookie-google-tag-manager"))
                return;

            if ((this.adminConfig.google_tagmanager) && (this.adminConfig.google_tagmanager_code != "") && (this.currentTypeStatus['performance'])) {
                let script = document.createElement('script');
                script.id = "cookie-google-tag-manager";
                script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.id='cookie-google-tag-manager-1';j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${this.adminConfig.google_tagmanager_code}');`;
                let noscript = document.createElement('noscript');
                let iframe = document.createElement('iframe');
                iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.adminConfig.google_tagmanager_code}`;
                iframe.height = 0;
                iframe.width = 0;
                iframe.style = "display:none;visibility:hidden";
                noscript.append(iframe);
                document.body.append(script, noscript);
            }
        },
        addComScore() {
            if (document.getElementById("cookie-comscore"))
                return;

            if ((this.adminConfig.comscore) && (this.adminConfig.comscore_code != "")) {
                let script = document.createElement('script');
                script.id = "cookie-comscore";
                script.innerHTML = `var _comscore = _comscore || [];
                _comscore.push({ c1: "2", c2: "${this.adminConfig.comscore_code}" });
                (function() {
                var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true; s.id = 'cookie-comscore-1';
                s.src = "https://sb.scorecardresearch.com/cs/${this.adminConfig.comscore_code}/beacon.js";
                el.parentNode.insertBefore(s, el);
                })();`;
                let noscript = document.createElement('noscript')
                let img = document.createElement('img')
                img.src = `https://sb.scorecardresearch.com/p?c1=2&amp;c2=${this.adminConfig.comscore_code}&amp;cv=3.6.0&amp;cj=1`
                noscript.append(img);
                document.body.append(script, noscript);
            }
        },
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        getSecurity() {
            // this.$parent.wait(true);

            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', 'Authorization': 'Bearer ' + this.token, language: this.$i18n.locale };
            axios.post(this.$url_api + "api/cookie-security/info", { event_id: this.event_id }, { headers: headers }).then((response) => this.getSecurityResponse(response)).catch((error) => this.getSecurityError(error.response));
        },
        getSecurityResponse(obj) {
            this.data = obj.data;

            this.update_security_container(obj.data);

            this.adminConfig = this.data.admin_config;
            console.log('this.adminConfig => ', this.adminConfig);
            this.cookieAdvertising = this.data.event_security.cookies.find((element) => element.name == 'cookie_analytics');
            this.cookiePerformance = this.data.event_security.cookies.find((element) => element.name == 'cookie_matomo');

            window.$('#security-flyout').flyout({ closable: false, silent: true, delaySetup: false, context:this.$refs.container_flyout, blurring: true, exclusive: true,
                onHidden() {
                    window.$('#security-flyout').flyout('destroy'); window.$('#security-modal').remove();
                }
            }).flyout('toggle');
            if (document.getElementById('security-menu'))
                document.getElementById('security-menu').getElementsByTagName('a')[0].click();

            this.addMatomo();
            this.addGoogleTagManager();
            this.addComScore();

            // this.$parent.wait(false);
        },
        getSecurityError(obj) {
            obj;
            // this.$parent.wait(false);
        },
        activeHandler(e) {
            if (this.btnStartActive) {
                this.btnStartActive.classList.remove('btn-start-active_content');
            }

            this.btnStartActive = e.currentTarget;
            this.btnStartActive.classList.add('btn-start-active_content');
        },
        activeModalHandler(e) {
            if (this.btnModalActive) {
                this.btnModalActive.classList.remove('active');
            }

            this.btnModalActive = e.currentTarget;
            this.btnModalActive.classList.add('active');
        },
        config(e) {
            e.preventDefault();
            this.activeHandler(e);

            // window.$('#security-modal').suimodal({ detachable: true, observeChanges: false, allowMultiple: false, onHidden(){ console.log('hidden modaL'); window.$('#security-modal').remove() } }).suimodal('show');
            window.$('#security-modal').suimodal({ detachable: true, observeChanges: false, allowMultiple: false, onHidden() { } }).suimodal('show');
        },
        removeHTMLScript() {
            let tmp = document.getElementsByTagName('html')[0].getElementsByTagName('script');
            for (let o = 0; o < tmp.length; o++) {
                if (tmp[o].src.indexOf('googletagmanager') !== -1) {
                    tmp[o].remove();
                }
            }
        },
        refuse(e) {
            e.preventDefault();
            this.activeHandler(e);

            if (document.getElementById('cookie-google-tag-manager'))
                document.getElementById('cookie-google-tag-manager').remove();
            if (document.getElementById('cookie-google-tag-manager-1'))
                document.getElementById('cookie-google-tag-manager-1').remove();
            // if (document.getElementById('cookie-matomo'))
            //     document.getElementById('cookie-matomo').remove();
            if (document.getElementById('cookie-matomo-1'))
                document.getElementById('cookie-matomo-1').remove();
            if (document.getElementById('cookie-comscore'))
                document.getElementById('cookie-comscore').remove();
            if (document.getElementById('cookie-comscore-1'))
                document.getElementById('cookie-comscore-1').remove();

            this.removeHTMLScript();

            this.currentTypeStatus = {};
            this.save();
        },
        accept(e) {
            e.preventDefault();
            this.activeHandler(e);

            this.currentTypeStatus = { "performance": true, "functionality": true, "advertising": true };
            this.addMatomo();
            this.addGoogleTagManager();
            this.addComScore();
            this.save();
        },
        save(e = null) {
            if (e) {
                e.preventDefault();
                this.activeHandler(e);
            }


            
            if (this.currentTypeStatus['functionality']) {
                //
            }
            if (this.currentTypeStatus['advertising']) {
                this.addGoogleTagManager();
                this.addComScore();
            }
            else {
                if (document.getElementById('cookie-google-tag-manager'))
                    document.getElementById('cookie-google-tag-manager').remove();
                if (document.getElementById('cookie-google-tag-manager-1'))
                    document.getElementById('cookie-google-tag-manager-1').remove();
                if (document.getElementById('cookie-comscore'))
                    document.getElementById('cookie-comscore').remove();
                if (document.getElementById('cookie-comscore-1'))
                    document.getElementById('cookie-comscore-1').remove();

                this.removeHTMLScript();
            }

            let cookie_data = JSON.stringify(this.currentTypeStatus);
            localStorage.setItem('security_cookie', cookie_data);

            if (this.currentTypeStatus['performance']) {
                this.addMatomo();
            }
            else {
                if (document.getElementById('cookie-matomo'))
                {
                    document.getElementById('cookie-matomo').remove();
                    window.location.reload();
                }
                if (document.getElementById('cookie-matomo-1'))
                    document.getElementById('cookie-matomo-1').remove();

                
            }

            this.close();
        },
        securityCheckHandler(e) {
            e.preventDefault();
            this.currentTypeStatus[this.currentType] = e.currentTarget.checked;
            this.check_text = e.currentTarget.checked ? this.$i18n.t('security.active_txt') : this.$i18n.t('security.inactive_txt');
        },
        btnModalHandler(e) {
            e.preventDefault();

            this.currentType = e.currentTarget.dataset.type;
            this.activeModalHandler(e);

            let tmp = this.data.event_optins.find((element) => element.name == 'accept_policy');
            // this.txt = { title: e.currentTarget.innerText, txt: ((this.$i18n.t('security.' + e.currentTarget.dataset.type) != 'security.' + e.currentTarget.dataset.type) ? this.$i18n.t('security.' + e.currentTarget.dataset.type) : ((tmp ? tmp.event_option[0].title : '') + '<br>' + (tmp ? tmp.event_option[0].description : ''))) };
            this.txt = { title: e.currentTarget.innerText, txt: ((this.$i18n.t('security.' + e.currentTarget.dataset.type) != 'security.' + e.currentTarget.dataset.type) ? this.$i18n.t('security.' + e.currentTarget.dataset.type) : (tmp && tmp.event_option[0] ? tmp.event_option[0].description : '')) };

            switch (this.currentType) {
                case 'performance':
                case 'functionality':
                case 'advertising':
                    this.$refs['security-check'].checked = this.currentTypeStatus[this.currentType] ? true : false;
                    this.check_text = this.currentTypeStatus[this.currentType] ? this.$i18n.t('security.active_txt') : this.$i18n.t('security.inactive_txt');
                    break;
            }
        },
        close() {
            window.$('.ui.flyout').flyout('hide');
            window.$('#security-modal').suimodal('hide');
            this.update_security_cookie(false);
        }
    },
    watch:
    {
        event_id(val, oldVal) {
            val, oldVal;
            if (!this.logged)
                this.getSecurity();
        },
        securityCookie(val, oldVal) {
            val, oldVal;
            if (val)
                this.getSecurity();
        }
    },
    created() {
        let c = localStorage.getItem("security_cookie");
        if (!c) {
            this.update_security_cookie(true);
        }
        else {
            this.currentTypeStatus = JSON.parse(c);
        }
    },
    mounted() {
        console.log('security mounted! => ', this.adminConfig);
        if (this.logged) {
            this.getSecurity();
        }
    }
}
</script>

<style>
.security-txt,
.security-txt p {
    font-size: var(--bs-body-font-family) !important;
    line-height: 1.5;
}
</style>
<style scoped>
#security {
    font-family: var(--bs-body-font-family);
}

.start {
    box-sizing: border-box;

}

.content {
    max-width: 1200px;
    padding: 38px 38px;
}

#txt {
    display: inline-block;
    vertical-align: top;
    width: 60%;
}

#btns {
    display: inline-block;
    vertical-align: top;
    width: 40%;
}

.ui.positive.button,
.ui.positive.buttons .button,
.ui .button {
    background-color: transparent;
    border: solid 1px var(--buttons_color);
    color: var(--buttons_color);
    border-radius: 10px;
    text-align: center;
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
}

.ui.vertical.tabular.menu .item {
    border-bottom: solid 1px rgba(15, 11, 11, 0.20);
    border-radius: 0;
    padding: 24px 0;
}

.ui.vertical.tabular.menu .item::after {
    background-color: transparent !important;
    border: none !important;
    bottom: auto;
    color: rgba(15, 11, 11, 0.20);
    font-family: FontAwesome;
    font-size: 20px;
    font-weight: 100;
    /* content: "\f178" !important; */
    content: "\279C" !important;
    left: auto;
    transform: none !important;
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
}

.ui.vertical.tabular.menu .active.item {
    border: none;
    border-bottom: solid 1px rgba(15, 11, 11, 0.20);
    border-radius: 0 !important;
}

.ui.toggle.checkbox label {
    color: #B1B1B1 !important;
}

.ui.toggle.checkbox input:checked~label {
    color: var(--buttons_color) !important;
}

/* .ui.toggle.checkbox label::before
    {
        border-radius: 45px;
        padding: 16px;
    }
    .ui.toggle.checkbox label::after
    {
        margin: 6px;
    }
    .ui.toggle.checkbox input:checked~label::after
    {
        left: 15px;
    } */

.ui.toggle.checkbox input:checked~label::before {
    background-color: var(--buttons_color);
}

.suimodal .ui.segment,
.suimodal .ui.vertical.tabular.menu {
    border: none;
    border-right: none;
}

.ui.suimodal>.content {
    padding: 48px;
}

.ui.suimodal>.actions {
    background-color: transparent;
    box-sizing: border-box;
    padding-bottom: 30px !important;
}

.txt {
    line-height: 24px !important;
}

.btn-start-active_content {
    background-color: var(--buttons_color) !important;
    color: var(--fonts_color) !important;
}

.active {
    border-bottom: solid 1px #0F0B0B !important;
}

.active::after {
    color: #0F0B0B !important;
}

.txt-actions * {
    font-weight: 700;
}
.container_flyout{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
}

@media screen and (max-width: 1000px) {

    #txt,
    #btns,
    .btn-default {
        display: block;
        width: 100%;
    }

    .btn-default {
        margin-top: 14px !important;
    }

    .ui.suimodal {
        border: none !important;
        border-radius: 0;
        box-sizing: border-box;
        height: auto;
        margin: 0 !important;
        min-height: 100% !important;
        position: absolute;
        top: 0 !important;
        width: 100% !important;
    }

    .content h2 {
        text-align: center;
    }

    #btn-save-choices {
        float: none;
    }

    .ui.suimodal .actions>.button:not(.fluid) {
        margin: 0;
    }

    .ui.segment {
        padding: 0;
    }

    .txt-actions {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
</style>
