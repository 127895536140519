<template>
    <div v-if="openModal" class="modals bgAzul">
        <div v-on:click="openModal = false" style="z-index: -1;width: 100%; height: 100%; position: fixed; top: 0px; left: 0px;"></div>
        <div class="share" ref="share">
            <div class="modals__content">
                <div class="modals__content--header alignVertical">
                    <div class="modals__content--header-title">
                        COMPARTILHE SUA CRIAÇÃO
                    </div>
                </div>
                <div class="modals__content--body alingVertical">
                    <div class="row">
                        <span>Link da sua gravação</span>
                    </div>
                    <div class="row">
                        <input class="share-input" ref="share-input" type="text" readonly :value="url" name="share-gravacao" id="share-gravacao">
                        <div @click="copy()">
                            <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.66699 4.33341V20.3334C6.66699 21.0407 6.94794 21.7189 7.44804 22.219C7.94814 22.7191 8.62641 23.0001 9.33366 23.0001H20.0003C20.7076 23.0001 21.3858 22.7191 21.8859 22.219C22.386 21.7189 22.667 21.0407 22.667 20.3334V8.65608C22.6669 8.30083 22.5959 7.94916 22.4581 7.62174C22.3202 7.29432 22.1184 6.99774 21.8643 6.74941L17.4443 2.42675C16.9461 1.93962 16.2771 1.66683 15.5803 1.66675H9.33366C8.62641 1.66675 7.94814 1.9477 7.44804 2.4478C6.94794 2.94789 6.66699 3.62617 6.66699 4.33341V4.33341Z" stroke="#E2B35B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.334 23.0002V25.6668C17.334 26.3741 17.053 27.0524 16.5529 27.5524C16.0528 28.0525 15.3746 28.3335 14.6673 28.3335H4.00065C3.29341 28.3335 2.61513 28.0525 2.11503 27.5524C1.61494 27.0524 1.33398 26.3741 1.33398 25.6668V11.0002C1.33398 10.2929 1.61494 9.61464 2.11503 9.11454C2.61513 8.61445 3.29341 8.3335 4.00065 8.3335H6.66732" stroke="#E2B35B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div class="row">
                        <div @click="share('email')">
                            <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27 0C28.3261 0 29.5979 0.526784 30.5355 1.46447C31.4732 2.40215 32 3.67392 32 5V21C32 22.3261 31.4732 23.5979 30.5355 24.5355C29.5979 25.4732 28.3261 26 27 26H5C3.67392 26 2.40215 25.4732 1.46447 24.5355C0.526784 23.5979 0 22.3261 0 21V5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0H27ZM30 7.922L16.508 15.862C16.3804 15.9369 16.2379 15.9826 16.0905 15.9957C15.9432 16.0088 15.7948 15.9891 15.656 15.938L15.492 15.862L2 7.926V21C2 21.7956 2.31607 22.5587 2.87868 23.1213C3.44129 23.6839 4.20435 24 5 24H27C27.7956 24 28.5587 23.6839 29.1213 23.1213C29.6839 22.5587 30 21.7956 30 21V7.922ZM27 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V5.604L16 13.84L30 5.6V5C30 4.20435 29.6839 3.44129 29.1213 2.87868C28.5587 2.31607 27.7956 2 27 2V2Z" fill="white"/>
                            </svg>
                        </div>
                        <div @click="share('face')">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.74744 0C2.11757 0 0 2.11757 0 4.74744V21.2526C0 23.8825 2.11757 26 4.74744 26H13.6931V15.8356H11.0053V12.1761H13.6931V9.04964C13.6931 6.59329 15.2811 4.33794 18.9394 4.33794C20.4206 4.33794 21.5158 4.48014 21.5158 4.48014L21.4297 7.89753C21.4297 7.89753 20.3127 7.88697 19.0938 7.88697C17.7745 7.88697 17.563 8.4948 17.563 9.50386V12.1762H21.5345L21.3614 15.8357H17.563V26.0001H21.2526C23.8824 26.0001 26 23.8825 26 21.2526V4.74747C26 2.1176 23.8824 2.6e-05 21.2526 2.6e-05H4.74742L4.74744 0Z" fill="white"/>
                            </svg>
                        </div>
                        <div @click="share('twitter')">
                            <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.6133 3.3483C23.7087 3.74913 22.737 4.01996 21.7154 4.14238C22.7695 3.51165 23.5581 2.51897 23.9341 1.34955C22.9437 1.93779 21.8598 2.35184 20.7296 2.57371C19.9695 1.76215 18.9627 1.22423 17.8656 1.04348C16.7684 0.86272 15.6423 1.04924 14.6621 1.57407C13.6818 2.0989 12.9022 2.93269 12.4444 3.94598C11.9865 4.95926 11.876 6.09536 12.1301 7.17788C10.1234 7.07713 8.16032 6.55556 6.36826 5.64703C4.57621 4.7385 2.99521 3.4633 1.72789 1.90421C1.29455 2.65171 1.04539 3.51838 1.04539 4.44138C1.0449 5.27229 1.24952 6.09048 1.64109 6.82334C2.03265 7.5562 2.59906 8.18109 3.29005 8.64255C2.48869 8.61705 1.705 8.40051 1.00422 8.01096V8.07596C1.00414 9.24135 1.40726 10.3709 2.14517 11.2729C2.88308 12.1749 3.91034 12.7938 5.05264 13.0246C4.30924 13.2258 3.52984 13.2555 2.7733 13.1113C3.09559 14.114 3.72338 14.9909 4.56878 15.6191C5.41419 16.2474 6.43488 16.5955 7.48797 16.6148C5.70029 18.0182 3.49251 18.7794 1.2198 18.776C0.817216 18.7762 0.414969 18.7526 0.0151367 18.7056C2.32207 20.1889 5.0075 20.9761 7.75014 20.973C17.0343 20.973 22.1097 13.2835 22.1097 6.61455C22.1097 6.39788 22.1043 6.17905 22.0946 5.96238C23.0818 5.24843 23.934 4.36435 24.6111 3.35155L24.6133 3.3483V3.3483Z" fill="white"/>
                            </svg>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
        name: 'Share',
        computed: {
            ...mapGetters([
                'logged', 'token','switch'
            ]),
        },
        data() {
            return {
                openModal:false,
                url:"",
            }
        },  
        components: {
            
        },
        methods: {
            ...mapActions([
                'update_notification_with_id'
            ]),
            share(platform){
                let urlPlatform = "";
                switch (platform){
                    case 'whats':
                        urlPlatform ="https://api.whatsapp.com/send?text="+this.url;
                        break;
                    case 'face':
                        urlPlatform = "https://www.facebook.com/sharer.php?kid_directed_site=0&display=popup&ref=plugin&src=share_button&u="+this.url;
                        break;
                    case 'twitter':
                        urlPlatform = "http://twitter.com/share?url="+this.url+"&hashtags=";
                        break;
                    case 'email':
                        urlPlatform = "mailto:?Body="+this.url;
                }
                urlPlatform = encodeURI(urlPlatform);
                urlPlatform = urlPlatform.replace(/#/g, '%23');
                window.open(urlPlatform,'_blank');
            },
            copy(){
                this.$refs['share-input'].select();
                document.execCommand('copy');
                this.$app.modal('success', "Sucesso", "Link copiado para sua área de transferência");
            },
            open(value){
                this.openModal = true;
                this.url = value;
            }
        },
        created() {
            
        },
        mounted() {
        },
        updated() {
        },
        beforeDestroy() {
        },
        watch: {
            notifications(to, from) {
                to, from;
                this.updateNotifications(to);
            }
        }
    }
</script>

<style scoped>
    .popup-position {
        position: relative;
        top: var(--headers_height);
        right: 10px;
        max-width: 350px;
        float: right;
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    .notification {
        width: 100%;
        cursor: pointer;
        max-height: 100px;
        height: 20%;
        position: relative;
        margin: 10px 0;
        background: rgba(1, 1, 1, 0.5);
        padding: .5rem 1rem;
        float: right;
    }
    .popup-close{
        position: absolute;
        top: 3px;
        right: 10px;
        color: black;
        font-family: cursive;
    }

    .notification-img img {
        width: 100%
    }
    .notification-text{
        color: #e2e2e2;
    }
    .notification-text .notification-title {
        font-weight: 900;
    }
    @media screen and (max-width: 500px)
    {

    }
</style>