<template>
    <div class="search-wrapper d-flex justify-content-end col-sm-12 col-md-4 float-right" :class="getClasses()">

      <!-- <div class="d-flex justify-content-end col-sm-12 col-md-4 float-right"> -->

        <div class="input-group search">
          <span class="input-group-text bg-transparent border-end-0 py-3 ps-4">
            <i class="bi bi-search"></i>
          </span>
          <input type="text" class="form-control border-start-0" :placeholder="searchPlaceholder" v-model="searchText" @input="handleInput">
          <span :class="{'show': showSearchError}" class="text-danger">{{ $i18n.t('lms.basic_template.search_min') }}</span>
        </div>

      <!-- </div> -->

    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
let emitter = require('tiny-emitter/instance');
// import {mapGetters} from "vuex";
export default {
  name: "SearchFields",
  components: {},
  props: {
    ismob: {
      default: false
    },
    searchPlaceholder: {
      default: "Pesquisar..."
    },
  },
  data() {
    return {
      searchText: '',
      showSearchError: false,
    }
  },
  beforeMount() {},
  mounted() {
    emitter.on("clear-search-text", this.clearSearchText);
  },
  computed: {},
  methods: {
    wait(b, unity_ = null) {
      b;
      unity_;
    },
    clearSearchText(){
      this.searchText = '';

      emitter.emit('filter-courses', this.searchText);
    },
    handleInput() {
      emitter.emit('filter-courses', this.searchText);

      this.showSearchError = this.searchText.trim().length < 4 && this.searchText.trim().length > 0;
    },
    getClasses() {
      let classes = '';
      classes += (this.ismob) ? 'mob' : 'desk';
      return classes;
    }
  }

}
</script>
<style lang="scss" scoped>

.search {
  position: relative;
  .input-group-text {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }
  .form-control {
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
  }
  .text-danger {
    font-family: "Space Grotesk", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-40%, 120%);

    opacity: 0;
    transition: opacity 0.3s ease;

    &.show {
      opacity: 1;
    }
  }
}

.search-wrapper.mob{
  display: none !important;
  margin: 30px 0;
  float: none;
}
@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
  // .search-wrapper{
  //   padding: 0 !important;
  // }
  .search-wrapper.mob{
    display: block !important;
  }
  .search-wrapper.desk{
    display: none !important;
  }


}
@media only screen and (max-width: 480px) {
  
}
</style>