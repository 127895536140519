<template>
  <div></div>
</template>

<script>


import { mapGetters } from "vuex";

export default {
  name: "LmsCheckoutAddToCart",
  mounted() {

    let root_ = this;

    root_.$store.dispatch("LMS/toggleLoading", true);

    let object    = root_.$route.query.object ?? null;
    let object_id = root_.$route.query.object_id ?? null;
    let quantity  = root_.$route.query.quantity ?? 1;

    let payload = {
      id    : parseInt(object_id),
      object: object,
      quantity: quantity,
      processing: false
    };

    if(isNaN(payload.id) || payload.object === null) {

      let title = root_.$i18n.t("lms.checkout.product_not_found");
      let text = root_.$i18n.t("lms.checkout.check_values_at_url");

      this.$swal({
        title: title,
        text: text,
      })
          .then(() => {
            root_.$router.push({
              name: "LmsHome",
              params: {
                lmsSlug: root_.$route.params.lmsSlug
              }
            });

          });

      return;
    }


    root_.$store.dispatch("LmsCart/addItemToCart", payload)
        .then(data => {
          data;

          root_.$router.push({
            name: "LmsCheckout",
            params: {
              lmsSlug: root_.$route.params.lmsSlug
            }
          });

        })
        .catch(error => {

          if(error.object_id === 'Item already in cart.') {

            root_.$router.push({
              name: "LmsCheckout",
              params: {
                lmsSlug: root_.$route.params.lmsSlug
              }
            });

          } else {

            let title = root_.$i18n.t("lms.checkout.product_not_found");
            let text = root_.$i18n.t("lms.checkout.check_values_at_url");

            this.$swal({
              title: title,
              text: text,
            })
                .then(() => {
                  root_.$router.push({
                    name: "LmsHome",
                    params: {
                      lmsSlug: root_.$route.params.lmsSlug
                    }
                  });

                });

          }

        })
        .finally(() => {
          root_.$store.dispatch("LMS/toggleLoading", false);
        })



  },
  computed: {
    ...mapGetters("LmsCart", [
        "getCart"
    ])
  }
}
</script>

<style scoped>

.menu {
  display: none;
}

</style>