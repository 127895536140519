import { render, staticRenderFns } from "./LoginAzure.vue?vue&type=template&id=01610163&scoped=true"
import script from "./LoginAzure.vue?vue&type=script&lang=js"
export * from "./LoginAzure.vue?vue&type=script&lang=js"
import style0 from "./LoginAzure.vue?vue&type=style&index=0&id=01610163&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01610163",
  null
  
)

export default component.exports