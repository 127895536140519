<template>
    <div>
        <div class="scene" id="language" ref="language" v-on:click="changeSide">
            <div class="cube">
                <div class="cube__face cube__face--front"><img :src="$url + 'assets/img/oracle/portuguese.png'"></div>
                <div class="cube__face cube__face--back"></div>
                <div class="cube__face cube__face--right"><img :src="$url + 'assets/img/oracle/english.png'"></div>
                <div class="cube__face cube__face--left"><img :src="$url + 'assets/img/oracle/spanish.png'"></div>
                <div class="cube__face cube__face--top"></div>
                <div class="cube__face cube__face--bottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import axios from "axios";
    export default {
        name: 'Language',
        computed: {
            ...mapGetters([
                'token', 'logged', 'counter', 'id', 'first_name', 'last_name', 'email', 'points', 'level', 'level_next', 'score_needed_to_reach', 'thumb', 'check_log'
            ])
        },
        data() {
            return {
                count: 0,
                cube: null,
                radioGroup: null, 
                currentClass: null,
                side:['front', 'right', 'left'],
                languages: ['portuguese', 'english', 'spanish']
            }
        },
        methods: {
            refreshLanguage(locale_) {
                //this.$parent.wait(true);
                let form_data = new FormData();
                form_data.append('token', this.token);
                form_data.append('id', this.id);
                form_data.append('locale', locale_);
                console.log(this.id);
                axios.post(this.$url_api + 'store/oracle/refresh_profile', form_data).then(response => this.refreshLanguageResponse(response));
            },
            refreshLanguageResponse(obj) {
                obj;
                //this.$parent.wait(false);



                /*
                if (obj.data.errorCode)
                {
                    this.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    if (!obj.data.error) {

                        this.update_profile({ id: obj.data.id, email: obj.data.email, first_name: obj.data.firstName, last_name: obj.data.lastName });

                        this.user_info = obj.data.firstName + " " + obj.data.lastName;
                        if (!this.token) {
                            this.update(obj.data.token);
                            this.getPoints();
                            this.getCart();
                        }
                    }
                }
                this.update_check_log(false);
                */
            },

            set() {
                var showClass = 'show-' + this.side[this.count];
                if (this.currentClass) {
                    this.cube.classList.remove(this.currentClass);
                }
                this.cube.classList.add(showClass);
                this.currentClass = showClass;
            },
            changeSide(e) {
                //var checkedRadio = this.radioGroup.querySelector(':checked');

                if (this.side[this.count + 1] !== undefined)
                    this.count++;
                else
                    this.count = 0;

                switch(this.count) {
                    case 0:
                        this.$ml.change('portuguese')
                        this.refreshLanguage('pt_BR');
                    break;
                    case 1:
                        this.$ml.change('english')
                        this.refreshLanguage('en');
                    break;
                    case 2:
                        this.$ml.change('spanish')
                        this.refreshLanguage('es');
                    break;
                }
                this.$parent.getPoints();
                /*
                if (this.$route.name == 'produto')
                {
                    this.$parent.$refs['container'].load();
                }
                */
                
                this.set();

                e.preventDefault();
            },

        },
        mounted() {
            this.cube = document.querySelector('.cube');
            this.radioGroup = document.querySelector('.radio-group');
            this.currentClass = '';

            this.count = this.languages.indexOf(this.$ml.current);
            this.set();


            //this.changeSide();

            //this.radioGroup.addEventListener( 'change', this.changeSide );
            //this.$refs['language'].addEventListener( 'change', this.changeSide);
        }
    }
</script>
<style scoped>
* { box-sizing: border-box; }

.scene {
  width: 45px;
  height: 45px;
  left: 10px;
  perspective: 400px;
  position: fixed;
  bottom: 10px;
  z-index: 99;
}
.scene img
{
    box-sizing: border-box;
    height: 45px;
    width: 45px;
    padding: 5px;
}

.cube {
  width: 50px;
  height: 50px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-25px);
  transition: transform 1s;
}

.cube.show-front  { transform: translateZ(-25px) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(-25px) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(-25px) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(-25px) rotateY(  90deg); }
.cube.show-top    { transform: translateZ(-25px) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(-25px) rotateX(  90deg); }

.cube__face {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-align: center;
}

/*.cube__face--front  { background: hsla(  0, 100%, 50%, 0.7); }
.cube__face--right  { background: hsla( 60, 100%, 50%, 0.7); }
.cube__face--back   { background: hsla(120, 100%, 50%, 0.7); }
.cube__face--left   { background: hsla(180, 100%, 50%, 0.7); }
.cube__face--top    { background: hsla(240, 100%, 50%, 0.7); }
.cube__face--bottom { background: hsla(300, 100%, 50%, 0.7); }*/

.cube__face--front  { background: rgba(0, 0, 0, .4) }
.cube__face--right  { background: rgba(0, 0, 0, .4) }
.cube__face--back   { background: rgba(0, 0, 0, .4) }
.cube__face--left   { background: rgba(0, 0, 0, .4) }
.cube__face--top    { background: rgba(0, 0, 0, .4) }
.cube__face--bottom { background: rgba(0, 0, 0, .4) }

.cube__face--front  { transform: rotateY(  0deg) translateZ(25px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(25px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(25px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(25px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(25px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(25px); }

label { margin-right: 10px; }
</style>

