<template>
    <div id="friends-invited-new">
        <div class="container">
            <div class="ui grid">
                <div class="sixteen wide column top-elements">
                    <router-link v-if="this.switch.Habilitar_Amigos"  class="no-underline link" :to="`/amigos`">
                        <i class="large arrow left icon back" style="visibility: visible;"></i>
                    </router-link>
                    <span class="title">{{ $t('friendsinvited.convidarAmigos') }}</span>
                </div>
                <div class="sixteen wide column">
                    <div class="ui segment segment-share">
                        <div class="ui padded grid stackable" style="align-items: center;">
                            <div class="four wide column" style="width: 19.5% !important">
                                <span class="label-text" style="width: 100%">{{ $t('friendsinvited.compartilheSeuCdigo') }}</span>
                            </div>
                            <div class="eight wide column">
                                <div class="ui input" style="width: 100%" data-tooltip="Clique para copiar" data-position="bottom center">
                                    <input type="text" class="disabled" :value="$url + '#/?ref=' + ref" id="link-url" @click="copyURL">
                                    <i class="copy icon" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;" @click="copyURL"></i>
                                </div>
                            </div>
                            <div class="four wide column share-icons">
                                <i class="large whatsapp icon" @click="send('whats')"></i>
                                <i class="large instagram icon" @click="send('insta')"></i>
                                <i class="large facebook f icon" @click="send('face')"></i>
                                <i class="large qrcode icon" @click="send('qrcode')"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="total_invited > 0" class="invited--friends-new ui grid">
                    <div class="sixteen wide column">
                        <div class="ui segment">
                            <i class="icon green check circle"></i>
                            {{ $t('friendsinvited.voceConvidou')}} <span>{{ list_invited.length }}</span> {{ invitedFriendsText }}
                        </div>
                    </div>
                    <div
                        class="four wide column cards-container"
                        v-for="(friend, keyFriends) in list_invited" :key="keyFriends"
                        :class="{ 'sixteen wide column': isMobile }">
                        <div class="ui card">
                            <div class="content">
                                <div class="location-content">
                                    <div class="description">
                                        <div class="user-information">
                                            <router-link :to="`/profile/${friend.invited.id}`" class="no-underline">
                                                <img
                                                    class="ui tiny circular image"
                                                    :src="friend.invited.avatar || `https://ui-avatars.com/api/?rounded=true&background=random&name=${friend.name}`"
                                                    @error="handleImageError($event, `https://ui-avatars.com/api/?rounded=true&background=random&name=${friend.name}`)"
                                                    alt="Avatar">
                                                <div class="texts">
                                                    <span class="name">{{ formatName(friend.invited.name) }}</span>
                                                    <span class="nickname">@{{friend.invited.nickname}}</span>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui translucent suimodal"
             id="qr-code-modal" :class="{ mini: !checkMobile, overlay : checkMobile, fullscreen : checkMobile } "
            style="padding-bottom: 20px;">
            <div class="header">
                Compartilhe
            </div>
            <div class="image content">
                <div class="description">
                    <QrcodeVue :titleHeader="$t('app.compartilheLink')" :value="qrcodeUrl" size="200" level="H"/>
                </div>
            </div>
            <div class="actions">
                <div class="ui button close-button"
                     style="background-color: var(--buttons_color); color: var(--headers_color);"
                     @click="closeModalQRCode">Fechar</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// import QrcodeGen from "@/components/Modals/QrcodeGen";
import QrcodeVue from "qrcode.vue";
import {capitalize, isMobile} from "@/helper";

export default {
    name: 'FriendsInvited',
    data() {
        return {
            list_invited: [],
            loading: false,
            total_invited: 0,
            coins: 0,
            points: 0,
            qrcodeOpen:false,
            qrcodeUrl:""
        }
    },

    created() {
        
    },
    mounted() {
        this.displayPage()
        this.invitedRequest();
    },
    computed: {
        ...mapGetters([
            'logged', 'token', 'ref', 'switch', 'checkMobile'
        ]),
        isMobile() {
            return isMobile(); // Chama a função isMobile do helper
        },
        invitedFriendsText() {
            let prefixText = this.list_invited > 1 ? this.$i18n.t('friendsinvited.amigosSeCadastraram') : this.$i18n.t('friendsinvited.amigoSeCadastrou');

            // console.log("aqui");
            // console.log(`${prefixText} ${this.$i18n.t('friendsinvited.peloSeuLink')}`);

            return `${prefixText} ${this.$i18n.t('friendsinvited.peloSeuLink')}`;
        }
    },
    components: {
        QrcodeVue
    },
    methods: {
        formatName(name) {
            if (typeof name === 'string' && name.includes(' ')) {
                const nameParts = name.split(' ');
                if (nameParts.length > 2) {
                    return `${capitalize(nameParts[0])} ${capitalize(nameParts[nameParts.length - 1])}`;
                }
                return nameParts.map(capitalize).join(' ');
            }
            return name; // Return the original name if it can't be split
        },
        closeModalQRCode() {
            const modalElement = window.$('#qr-code-modal');
            if (modalElement.length) {
                modalElement.suimodal('hide');
            } else {
                console.error('Elemento #qr-code-modal não encontrado no DOM.');
            }
        },
        copyURL(event) {
            event;
            const input = document.getElementById('link-url');
            input.select();
            document.execCommand('copy');

            // Exibe o modal de sucesso
            this.$app.modal(
                'success',
                this.$i18n.t('friendsinvited.sucesso'),
                this.$i18n.t('friendsinvited.urlCopiada'),
                this.r,
                null,
                null,
                'Ok'
            );
        },
        openModalQRCode() {
            console.log('Abrindo QRCode');
            const modalElement = window.$('#qr-code-modal');

            if (modalElement.length) {
                modalElement.suimodal({
                    dimmerSettings: {
                        opacity: 0.5
                    },
                    observeChanges: true,
                    closable: true,
                    allowMultiple: true
                }).suimodal('show');
            } else {
                console.error('Elemento #qr-code-modal não encontrado no DOM.');
            }
        },
        send(platform) {
            let urlPlataforma;
            const linkUrl = document.getElementById('link-url').value; // Obter o valor do input

            switch (platform) {
                case 'whats':
                    urlPlataforma = "https://api.whatsapp.com/send?text=" + linkUrl;
                    break;
                case 'face':
                    urlPlataforma = "https://www.facebook.com/sharer.php?kid_directed_site=0&display=popup&ref=plugin&src=share_button&u=" + linkUrl;
                    break;
                case 'twitter':
                    urlPlataforma = "http://twitter.com/share?url=" + linkUrl + "&hashtags=";
                    break;
                case 'insta':
                    urlPlataforma = "https://www.instagram.com/?url=" + linkUrl;
                    break;
                case 'qrcode':
                    this.qrcodeUrl = linkUrl;
                    // this.qrcodeOpen = true;
                    this.openModalQRCode();
                    return;
            }

            urlPlataforma = encodeURI(urlPlataforma);
            urlPlataforma = urlPlataforma.replace(/#/g, '%23');
            window.open(urlPlataforma, '_blank');
        },
        //----- invited -----//
        invitedRequest() {
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetInvitedFriend', { headers:headers }).then(response => this.invitedResponse(response)).catch(error => this.invitedError(error.response));
        },
        invitedResponse(obj)
        {
            console.log('Invited =>' + obj.data.data);
            if (obj.data.status == 'success') {
                this.total_invited = obj.data.data.length;
                this.list_invited = obj.data.data;
                this.coins = obj.data.coins;
                this.points = obj.data.points;
            }
            
            this.$app.wait(false);
        },
        displayInvitedFriends() {
            if (!this.switch.Habilitar_convidar_amigos) {
                this.$router.push({name:'Map'});
            }
        },
        invitedError(obj)
        {
            obj;
            this.$app.wait(false);
        },
        //----- \invited\ -----//
        userProfile(avatarURL) {
            return avatarURL ? `background: url('${avatarURL}') center center / cover;` : 'background-color: #000;';
        },
        displayPage() {
            if (!this.switch.Habilitar_convidar_amigos || !this.switch.Habilitar_Amigos) {
                this.$router.push({name:'Map'});
            }
        }
    },
}
</script>
<style lang="scss" scoped>
#friends-invited-new {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin-top: 10rem;
    .top-elements {
        display: flex;
        align-items: center;
        .title {
            font-size: 2rem;
            color: var(--buttons_color);
            font-family: 'Exo';
            font-weight: 500;
        }
        .back {
            color: var(--buttons_color);
            margin-right: 1rem;
            height: 100%;
            margin-bottom: 22px;
            cursor: pointer;
        }
    }

    .no-underline {
        text-decoration: none; /* Remove o underline de todos os router-links */
        &.link {
            height: 0;
            display: inline-flex;
            align-content: center;
            align-items: center;
        }
    }

    .segment-share {
        border-radius: 18px;
        background: #FFF;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
        border: none;
        padding: 2rem 2rem 2rem 2rem;
        input {
            border-radius: 10px;
            background: #FFF;
            &:focus {
                border: 1px solid var(--buttons_color);
            }
        }
        .label-text {
            color: var(--fonts_color);
            leading-trim: both;

            text-edge: cap;
            font-family: "Open Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }

        .share-icons {
            display: flex;
            justify-content: center; /* Mantém os ícones centralizados */
            gap: 10px; /* Controla o espaço entre os ícones */
            width: 30.5% !important;
            i {
                color: var(--buttons_color);
                margin: 0 20px;
                cursor: pointer;
            }
        }

        // MOBILE
        @media only screen and (max-width: 768px) {
            .share-icons {
                width: 100% !important;
            }
        }
    }
    .invited--friends-new {
        width: 100%;
        padding-right: 0;
        .column {
            padding-right: 0;
        }
        .segment {
            padding-right: 0;
            background: rgba(76, 175, 80, 0.12);
            border-radius: 18px;
            box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
            border: none;
            width: 100%;
            padding-left: 2rem;
        }
    }

    .card {
        min-height: 220px;
        border-radius: 18px;
        width: 100%;
        background: #FFF;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;


        .header {
            display: flex;
            align-items: center;
        }

        &:hover {
            transform: translateY(-3px);
            z-index: 10; /* Traz o card para frente */
            position: relative;
        }

        .user-information {
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .texts {
            padding-top: 18px;
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            text-align: center;
        }

        // MOBILE
        @media only screen and (max-width: 768px) {
            .card {
                width: 100%;
            }
            .share-icons {
                width: 100% !important;
            }
        }
    }
}
</style>