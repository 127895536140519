<template>
    <div class="package-payments container">
        <div class="container-content">
            <main>
                <section class="spacing-master">
                    <div id="options-finance">
                        <ul class="item-center">
                            <li>
                                <input checked id="historic_" name="type" type="radio" value="0"
                                    v-on:change="optionsHandler">
                                <label class="border-historic" for="historic_">{{$t('payments.history')}}</label>
                            </li>
                            <li>
                                <input id="assigns_" name="type" type="radio" value="1" v-on:change="optionsHandler">
                                <label class="border-assign" for="assigns_">{{$t('payments.signatures')}}</label>
                            </li>
                        </ul>
                    </div>

                    <div v-if="this.historicFilter">
                        <div class="tabs" v-for="(l, index) in signatures" :key="index">
                            <div class="tab">
                                <input type="checkbox" :id="'check-' + index">
                                <label class="tab-label" :for="'check-' + index">
                                    <span class="flex tab-flex">
                                        <span class="col-sm-12 col-6 spacing-mobile">
                                            <span class="signature-plain-text">{{l.active ? $t('payments.currentPlan') : $t('payments.canceledPlan')}}<br /><span
                                                    class="signature-plain-name">{{ l.name }}</span></span>
                                        </span>
                                        <span class="col-sm-12 col-6 spacing-mobile">
                                            <span class="flex spacing-mobile-img">
                                                <img width=70 height=50
                                                    src="assets/icons/packagePayments/coins-canceled.png" alt="" />
                                                <span>{{ l.amount }}<span class="signature-plain-week"><br />{{$t('payments.perWeek')}}</span></span>
                                            </span>
                                        </span>
                                        <span class="col-sm-12 col-5 spacing-mobile">
                                            <button v-if="l.active" class="button__secondary button-cancel"
                                                type="button" v-on:click="cancelPlan()">{{$t('payments.cancelSubscription')}}</button>
                                        </span>
                                    </span>
                                </label>
                                <div class="tab-content">
                                    <table cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(h, i) in l.history" :key="i">
                                                <td>
                                                    <span v-if="h.status == 'WAITING'"
                                                        class="ball-status ball-status-waiting"></span>
                                                    <span v-else-if="h.status == 'PAID'"
                                                        class="ball-status ball-status-paid"></span>
                                                    <span v-else class="ball-status ball-status-canceled"></span>
                                                </td>
                                                <td>{{ h.date }}</td>
                                                <td>R$ {{ maskMoney(h.value) }}</td>
                                                <td class="tab-cell-amount">+ {{ h.amount }}</td>
                                                <td>
                                                    <span v-if="h.uuid" class="cursor-svg" :data-value="h.uuid"
                                                        v-on:click="downloadNF">
                                                        <svg width="10" xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 384 512">
                                                            <path
                                                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                                                                fill="#33BCEE" />
                                                        </svg>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <ul>
                            <li class="box-payments" v-for="(l, index) in payments" :key="index">
                                <div class="flex flex-wrap">
                                    <div class="col-sm-12 col-4 div-box-payment-icon">
                                        <img class="box-payment-icon" v-if="l.status == 'WAITING'"
                                            src="assets/icons/packagePayments/coins-waiting.png" height=100 alt="" />
                                        <img class="box-payment-icon" v-else-if="l.status == 'PAID'"
                                            src="assets/icons/packagePayments/coins-paid.png" height=100 alt="" />
                                        <img class="box-payment-icon" v-else
                                            src="assets/icons/packagePayments/coins-canceled.png" height=100 alt="" />
                                    </div>
                                    <div class="col-sm-12 col-4">
                                        <p class="box-payment-name">{{ l.name }}</p>
                                        <p v-if="l.status == 'WAITING'" class="box-payment-status status-waiting"><span
                                                class="ball-status ball-status-waiting"></span>{{$t('payments.pendent')}}</p>
                                        <p v-else-if="l.status == 'PAID'" class="box-payment-status status-paid"><span
                                                class="ball-status ball-status-paid"></span> {{$t('payments.approved')}}</p>
                                        <p v-else class="box-payment-status status-canceled"><span
                                                class="ball-status ball-status-canceled"></span> {{$t('payments.canceled')}}</p>
                                        <div class="flex div-amount">
                                            <img src="assets/icons/myPerfil/coin.png" height=15 alt="" />
                                            <span class="box-payment-amount"> {{ l.amount }} {{
                                                l.amount === 1 ? $t('payments.oneCoin') :
                                                    $t('payments.multipleCoins')
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-4">
                                        <div class="flex" style="margin-top: 27px;">
                                            <div class="col-sm-12">
                                                <div class="flex div-date">
                                                    <svg width="18" height="20" viewBox="0 0 26 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M23.0195 25.25V9.01953H2.98047V25.25H23.0195ZM23.0195 2.75C23.6836 2.75 24.25 3.00391 24.7188 3.51172C25.2266 4.01953 25.4805 4.60547 25.4805 5.26953V25.25C25.4805 25.9141 25.2266 26.5 24.7188 27.0078C24.25 27.5156 23.6836 27.7695 23.0195 27.7695H2.98047C2.31641 27.7695 1.73047 27.5156 1.22266 27.0078C0.753906 26.5 0.519531 25.9141 0.519531 25.25V5.26953C0.519531 4.60547 0.753906 4.01953 1.22266 3.51172C1.73047 3.00391 2.31641 2.75 2.98047 2.75H4.26953V0.230469H6.73047V2.75H19.2695V0.230469H21.7305V2.75H23.0195Z"
                                                            fill="#404040" />
                                                    </svg>
                                                    <span class="box-payment-date">{{ l.created_at }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex" style="margin-top: 20px;">
                                            <div class="col-sm-12">
                                                <div class="flex div-value">
                                                    <svg width="18" height="20" viewBox="0 0 14 25" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M7.75 11.1606C8.66667 11.4106 9.41667 11.6606 10 11.9106C10.625 12.1606 11.2708 12.5148 11.9375 12.9731C12.6042 13.3898 13.1042 13.9315 13.4375 14.5981C13.8125 15.2648 14 16.0565 14 16.9731C14 18.2648 13.5625 19.3273 12.6875 20.1606C11.8542 20.9523 10.7292 21.4731 9.3125 21.7231V24.5981H5.3125V21.7231C3.9375 21.4315 2.79167 20.8481 1.875 19.9731C1 19.0981 0.520833 17.9731 0.4375 16.5981H3.375C3.54167 18.4731 4.85417 19.4106 7.3125 19.4106C8.60417 19.4106 9.52083 19.1815 10.0625 18.7231C10.6458 18.2231 10.9375 17.6606 10.9375 17.0356C10.9375 15.5356 9.60417 14.4523 6.9375 13.7856C2.77083 12.8273 0.6875 10.994 0.6875 8.28564C0.6875 7.07731 1.125 6.05648 2 5.22314C2.875 4.34814 3.97917 3.76481 5.3125 3.47314V0.598145H9.3125V3.53564C10.6875 3.86898 11.7292 4.49398 12.4375 5.41064C13.1875 6.32731 13.5833 7.38981 13.625 8.59814H10.6875C10.6042 6.72314 9.47917 5.78564 7.3125 5.78564C6.22917 5.78564 5.35417 6.01481 4.6875 6.47314C4.0625 6.93148 3.75 7.53564 3.75 8.28564C3.75 9.49398 5.08333 10.4523 7.75 11.1606Z"
                                                            fill="#404040" />
                                                    </svg>
                                                    <span class="box-payment-value">
                                                        R${{ maskMoney(l.value) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex" style="margin-top: 20px;">
                                            <div class="col-sm-12">
                                                <div class="flex div-nf">
                                                    <span v-if="l.uuid" class="cursor-svg" :data-value="l.uuid"
                                                        v-on:click="downloadNF">
                                                        <svg width="18" height="20" xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 384 512">
                                                            <path
                                                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                                                                fill="#404040" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </main>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";
export default
    {
        name: 'PackagePayments',
        data() {
            return {
                payments: null,
                signatures: {},
                historicFilter: null
            }
        },
        computed: {
            ...mapGetters(['token', 'package']),
        },
        components: {},
        methods:
        {
            ...mapActions([]),
            just_number(v) {
                return String(v).replace(/\D/g, '');
            },
            maskMoney(value) {
                let v = this.just_number(value);
                v = (v / 100).toFixed(2);
                v = v.replace(".", ",");
                v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
                v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
                return v;
            },
            getPayments() {
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                let form_data = new FormData();
                axios.post(this.$url_api + "api/package/payments", form_data, { headers: headers }).then((response) => this.getPaymentsResponse(response)).catch((error) => this.getPaymentsError(error.response));
            },
            getPaymentsResponse(obj) {
                this.payments = obj.data;
                console.log('getPaymentsResponse => ', obj);
                this.$app.wait(false);
                let comparison = [];
                
                this.payments.forEach(element => {
                    const date = element.created_at;
                    element.created_at = date.substr(0, 10).split('-').reverse().join('/');

                    if (element.payment_type == 1) {

                        if (comparison.includes(element.package_id)) {
                            this.signatures[element.package_id].history[element.id] = {
                                'date': element.created_at,
                                'status': element.status,
                                'value': element.value,
                                'amount': element.amount,
                                'uuid': element.uuid
                            }
                        } else {
                            comparison.push(element.package_id);
                            this.signatures[element.package_id] = {
                                'name': element.name,
                                'amount': element.amount,
                                'package_id': element.package_id,
                                'active': element.active,
                                'history': {}
                            };
                            this.signatures[element.package_id].history[element.id] = {
                                'date': element.created_at,
                                'status': element.status,
                                'value': element.value,
                                'amount': element.amount,
                                'uuid': element.uuid
                            }
                        }
                        
                    }
                });
            },
            getPaymentsError(obj) {
                console.log('getPaymentsError => ', obj);
                this.$app.wait(false);
            },
            optionsHandler(e) {
                e.preventDefault();
                if (e.currentTarget.value == 1) {
                    this.historicFilter = true;
                } else {
                    this.historicFilter = null;
                }
            },
            cancelPlan() {
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                let form_data = null;
                axios.post(this.$url_api + "api/package/payments/cancel-recurrence", form_data, { headers: headers }).then((response) => this.cancelPlanResponse(response)).catch((error) => this.getPaymentsError(error.response));
            },
            cancelPlanResponse(obj) {
                if (obj.data.active == 0) {
                    console.log('signatures => ', this.signatures[obj.data.package_id]);
                    let temp = this.signatures[obj.data.package_id];
                    temp.active = obj.data.active;
                    this.signatures[obj.data.package_id] = temp;
                    this.$forceUpdate();
                    console.log('signatures temp => ', this.signatures[obj.data.package_id]);
                }
            },
            cancelPlanError(obj) {
                console.log('cancelPlanError => ', obj);
            },
            downloadNF(e) {
                console.log('VALOR DO CLICK =>', e.currentTarget.dataset.value);
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                let form_data = new FormData();
                form_data.append('uuid', e.currentTarget.dataset.value);
                axios.post(this.$url_api + "api/package/payments/download-nf", form_data, { headers: headers }).then((response) => this.downloadNFResponse(response)).catch((error) => this.getPaymentsError(error.response));
            },
            downloadNFResponse(obj) {
                console.log('downloadNFResponse => ', obj);
                if (obj.data.success) {
                    // window.location.href = obj.download[0];
                    window.open(obj.data.download[0], '_blank', 'download=nota.pdf');

                }
            },
            downloadNFError(obj) {
                console.log('downloadNFError => ', obj);
            }
        },
        mounted() {
            this.$app.wait(true);
            this.getPayments();
        }
    }
</script>

<style scoped>
#package-payments-form ul,
#package-payments-form li {
    margin: 0;
    padding: 0;
    list-style: none;
}

#package-payments-form>ul>li {
    box-sizing: border-box;
    display: block;
    padding: 10px;
    width: 100%;
}

#package-payments-form>ul>li>ul>li {
    box-sizing: border-box;
    display: inline-block;
    padding: 10px;
    width: auto;
}
</style>