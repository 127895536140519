<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" pageSubTitle="Caixa de entrada" :searchField="false"
                    searchPlaceholder="Pesquisar notificação...">

    <template v-slot:navigation-side>
      <ProfileNavigation></ProfileNavigation>

      <section class="section-mycommunities mt-3">
        <span class="mycommunities">{{navigationConfig.navigationTitle}}</span>
        <div class="menu-buttons mt-3 d-flex flex-column gap-3">
          <button v-for="(button, idx) in navigationConfig.navigation" :key="idx" type="button" class="btn py-3" @click.prevent="goTo(button.slug)">
            <i v-if="button.icon" :class="button.icon"></i>
            {{ button.name }}
          </button>
        </div>
      </section>
    </template>

    <template v-slot:content-side>
      <section class="new-notifications" :class="{ open: isOpen }">
        <transition-group name="notification-slide" mode="out-in">

          <Notification v-for="(item) in newNotificationsList" :key="item.id" :new-notification="true"
                               :data="item" ></Notification>
        </transition-group>
      </section>

      <section class="notifications">

        <Notification v-for="(item) in notificationsList" :key="item.id"
                             :data="item" ></Notification>

      </section>

      <pagination class="mt-4" v-if="paginationOptions.show" v-model="paginationOptions.page" @paginate="paginateNotifications"
                  :records="paginationOptions.total" :per-page="paginationOptions.per_page" :options="paginationOptions" />
    </template>
  </LmsBasicTemplate>
</template>
<script>

import { mapGetters } from "vuex";
import LmsBasicTemplate from "@/components/lms/LmsBasicTemplate.vue";
import ProfileNavigation from "@/components/lms/components/ProfileNavigation.vue";
import CustomPagination from "@/components/lms/components/LmsHomeComponents/CustomPagination.vue";
import Notification from "@/components/lms/components/LmsNotificationsComponents/Notification.vue";
let emitter = require('tiny-emitter/instance');

export default {
  name: "LmsNotifications",
  components: {
    LmsBasicTemplate,
    ProfileNavigation,
    Notification
  },
  data() {
    return {
      selectedCategory: null,
      selectedCategoryId: 0,
      navigationConfig: [],
      notificationsList: [],
      newNotificationsList: [],
      paginationOptions: {
        paginationKey: 0,
        show: true,
        total: 8,
        page: 1,
        per_page: 8,
        template: CustomPagination
      },
      isOpen: false
    }
  },
  beforeMount() {
    let root_ = this;

    root_.navigationConfig = root_.getPageByName("LmsNotifications");


    this.getUserNotifications(1);
  },
  mounted() {
    let root_ = this;
    
    emitter.on('notifications::new-notifications-available', function(userNotification){

      userNotification.id = parseInt(Math.random() * 999999999);

      let hasSameNotificationIdNFlagPendent = root_.newNotificationsList.some(item => {
        return item.object?.notification_id === userNotification.object?.notification_id;
      });


      if( hasSameNotificationIdNFlagPendent && userNotification.subtype === 'private_message') {
        let removedNotification = false;
        root_.newNotificationsList = root_.newNotificationsList.filter(obj => {
          if (obj.subtype === "private_message" && obj.object.flag === "reed"
              && obj.object.friend_id === userNotification.object.friend_id) {
            removedNotification = true;
            return false;
          }
          return true;
        });

        if(!removedNotification){
          return;
        }

      }

      if (root_.newNotificationsList.length === 3) {
        root_.newNotificationsList.pop();
      }


      root_.newNotificationsList.unshift(userNotification);

      root_.isOpen = true;

    });

  },
  methods: {
    paginateNotifications(page){

      this.getUserNotifications(page);
    },
    getUserNotifications(page) {

      let root_ = this;

      root_.newNotificationsList = [];
      root_.notificationsList = [];

      let payload = {
        page: page,
        per_page: 8
      }

      this.$store.dispatch("LMS/getUserNotifications", payload)
          .then(data => {

            root_.notificationsList = data.data;

            root_.paginationOptions.current_page  = data.current_page;
            root_.paginationOptions.total         = data.total;
            root_.paginationOptions.per_page      = parseInt(data.per_page);
            root_.paginationOptions.page          = data.current_page;
          })
    }
  },
  computed:{
    ...mapGetters("LMS", [
      "getLms", "getPageByName", "getNotificationsList"
    ]),
    ...mapGetters(["notifications_pendent_ids"])
  },
  beforeDestroy() {
    emitter.off('notifications::new-notifications-available');
  }

}

</script>

<style scoped lang="scss">

.notification-slide-enter-active,
.notification-slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.notification-slide-leave-active {
  display:none !important;
}

.notification-slide-enter, .notification-slide-leave-to /* .notification-slide-leave-active no <2.1.8 */ {
  transform: translateY(-100%);
  opacity: 0;
}

.new-notifications {

  margin: 10px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;

  &.open {
    max-height: fit-content;
  }

}

.section-mycommunities {

  .mycommunities {
    color: #706F6F;
    font-size: 16px;
    font-family: "Hanken Grotesk", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .menu-buttons {

    .btn {
      color: #706F6F;
      background-color: #F6F6F6;
      font-size: 18px;
      font-family: "Hanken Grotesk", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left;

      .bi {
        color: var(--buttons_color);
      }
    }
  }
}


.VuePagination {
  display: flex;
  justify-content: center;
}
</style>
