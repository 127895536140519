<template>
  <div class="custom-col col-12 col-sm-6 col-md-4 mb-3">
    <div class="course-card card text-center">
      <h5 class="card-title mt-4" @click="viewCourse()">{{coursedata.title}}</h5>
      <div class="card-body infos d-flex flex-column p-0 mt-4">
        <!-- <div class="course-status d-flex justify-content-center mb-3">
          <div class="checklist w-100">
            <i class="bi bi-check2"></i>
            <span class="d-block">Checklist</span>
          </div>
          <div class="notebook w-100">
            <i class="bi bi-journal-text"></i>
            <span class="d-block">My notebook</span>
          </div>
        </div> -->
        <div class="course-action">
          <button type="button" @click="viewCourse()">{{ (coursedata.user_progress == '100') ? 'Revise o conteúdo' : 'Continue estudando' }} </button>
        </div>
        <div class="content-progress d-flex align-items-center h-100">
          <div id="chart" >
            <apexchart ref="chart" :options="chartOptions" :series="chartSeries" type="radialBar" height="160" />
          </div>
          <span class="content-completed">Conclusão de conteúdo</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: "CourseCard",
  props: [
    "coursename",
    "coursedata"
  ],
  watch:{
    coursedata: function(newValue) {
      this.updateChart(newValue);
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 160,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5, // 15
              size: '40%'
            },
            dataLabels: {
              name: {
                show: true,
                color: '#5F5F5F',
                offsetY: 5,
                fontSize: '18px'
              },
              value: {
                show: false
              }
            },
            track: { // Track left
              background: ['#5F5F5F'],
              strokeWidth: '87%',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 3,
                opacity: 0.3
              }
            },
          },
        },
        fill: {
          colors: ['#A18AE5'],
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['0%'],
        responsive: [
          {
            breakpoint: 1025, // sempre 1px a mais do objetivo
            options: {
              chart: {
                height: 150
              },
            }
          },
          {
            breakpoint: 769,
            options: {
              chart: {
                height: 150
              }
            }
          },
          {
            breakpoint: 481,
            options: {
              chart: {
                height: 150
              },
            }
          }
        ]
      },
      chartSeries: [0],
    }
  },
  mounted(){
    this.chartSeries = [this.coursedata.user_progress];
    this.chartOptions.labels = [`${this.coursedata.user_progress}%`];
    this.$refs.chart.updateOptions(this.chartOptions);
    this.$refs.chart.updateSeries([this.coursedata.user_progress]);
      console.log(this.coursedata)
  },
  methods: {
    updateChart(newValue){
      this.chartOptions.labels = [`${newValue.user_progress}%`];
      this.chartSeries = [newValue.user_progress];
      this.$refs.chart.updateOptions(this.chartOptions);
      this.$refs.chart.updateSeries([this.coursedata.user_progress]);
    },
    viewCourse() {
      if(this.coursedata.next_unfinished == null) {
        this.$router.push({
          name: "LMSCourses",
          params: {
            lmsSlug: this.$route.params.lmsSlug,
            courseSlug: this.coursedata.slug
          }
        });
      } else {
        this.$router.push({
          name: "LmsLessonContent",
          params: {
            lmsSlug: this.$route.params.lmsSlug,
            courseSlug: this.coursedata.slug,
            sectionSlug: this.coursedata.next_unfinished.slug,
            lessonSlug: this.coursedata.next_unfinished.lesson_slug
          },
            query: {
                lesson_id: this.coursedata.next_unfinished.lesson_id,
                section_id: this.coursedata.next_unfinished.section_id,
                course_id: this.coursedata.id
            }
        });
      }
    },
  }
}
</script>


<style lang="scss">

</style>

<style lang="scss" scoped>
.course-card {
  height: 335px;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 0 10px 5px #706F6F30;
  }

  &.card {
    border-radius: 20px;
    border: 2px solid #F6F6F6;
    background: #FFF;
    transition: all .3s ease;
    max-width: 330px;
  }

  .card-title {
    color: #706F6F;
    font-size: 18px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-wrap: nowrap;
    overflow: hidden;
    cursor: pointer;
    padding: 0 20px;
    text-align: left;
  }

  .course-action {
    text-align: center;
    margin-bottom: 30px;
    padding: 0 25px;

    button{
      background: var(--buttons_color);
      margin: 0;
      color: #fff;
      width: 100%;
      border: none;
      padding: 15px 20px;
      border-radius: 30px;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      box-shadow: 0px 5px 1px -2px rgba(0, 0, 0, 0.2);
    }
  }

  .card-body {
    .content-progress {
      background-color: #F6F6F6;
      height: 100%;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      .content-completed {
        //margin-left: -55px;
        width: 50%;
        color: #706F6F;
        font-size: 16px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-left: -9rem;
      }

    }

  }


  .bi {
    font-size: 2rem;
  }

  .checklist {
    color: #706F6F;

  }

  .notebook {
    color: #706F6F;

  }

  .infos {
    span {
      color: #706F6F;
      text-align: center;
      font-size: 14px;
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

  }

  .apexcharts-canvas {
    right: 4rem;
  }

  // @media(min-width: 768px){
  //   .card-body {
  //     .content-progress {
  //       .content-completed {
  //         font-size: 12px;
  //       }
  //     }
  //   }
  // }
  // @media(min-width: 1024px){
  //   .card-body {
  //     .content-progress {
  //       .content-completed {
  //         font-size: 16px;
  //       }
  //     }
  //   }
  // }

}

.custom-col{
  width:33.33% !important;
}



@media(min-width: 768px){
  .card-body {
    .content-progress {
      .content-completed {
        font-size: 12px;
      }
    }
  }
}
@media(min-width: 1024px){
  .card-body {
    .content-progress {
      .content-completed {
        font-size: 16px;
      }
    }
  }
}



@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {
  // .container.courses-cards-wrapper,
  // .container-md.courses-cards-wrapper,
  // .container-sm.courses-cards-wrapper {
  //   max-width: 100%;
  //   width: 100%;
  // }
  .custom-col{
    width:50% !important;
  }
}

@media only screen and (max-width: 992px) {
  
  
}
@media only screen and (max-width: 768px) {
  .course-card.card{
    margin: 0 auto;
  }
}
@media only screen and (max-width: 480px) {
  .custom-col{
    width:100% !important;
  }
}



</style>