<template>
    <div id="tabber" :class="getClasses()">

      <div v-if="tabgroup === 'showGateways'" class="gateways">
        <div class="nav justify-content-center" id="nav-tab" role="tablist">
          <button v-for="(gate, idxGate) in gateways" class="nav-link align-items-center d-flex gap-2 px-5 py-3" :class="{'active': idxGate === 0 }"
                  :id="`nav-${gate}-tab`" data-bs-toggle="tab" :data-bs-target="`#nav-${gate}`" type="button"
                  role="tab" :aria-controls="`nav-${gate}`" :aria-selected="idxGate === 0" :key="idxGate" @click="clickTab(gate)">
            <span class="material-symbols-outlined">
              {{ getGatewayIcon(gate)}}
            </span>
            <span v-text="getGatewayName(gate)"></span>
          </button>
        </div>
      </div>

      <!-- <div v-if="showEtalent" class="gateways col-sm-12 col-md-9"> -->
      <div v-if="tabgroup === 'showEtalent'" class="gateways">
        <div class="nav" id="nav-tab" role="tablist">
          <button v-for="(etalent, idxEtalent) in etalent" class="nav-link align-items-center d-flex gap-2 px-5 py-3" :class="{'active': idxEtalent === 0 }"
                  :id="`nav-${etalent}-tab`" data-bs-toggle="tab" :data-bs-target="`#nav-${etalent}`" type="button"
                  role="tab" :aria-controls="`nav-${etalent}`" :aria-selected="idxEtalent === 0" :key="idxEtalent">
            <span class="material-symbols-outlined">
              {{ getEtalentIcon(etalent)}}
            </span>
            <span v-text="getEtalentName(etalent)"></span>
          </button>
        </div>
      </div>

    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import {mapGetters} from "vuex";
let emitter = require('tiny-emitter/instance');
export default {
  name: "NavTabs",
  components: {},
  props: {
    tabgroup: {
      default: ''
    },
    ismob: {
      default: false
    },
    etalent: {
      default: function() {
        return [
          'courses',
          'etalent'
        ]
      }
    },
    gateways: {
      default: function(){
        return []
      }
    },
  },
  data() {
    return {
      
    }
  },
  beforeMount() {},
  mounted() {
    if( !this.getLms.configs.etalent_active ) {
      this.etalent.splice(1, 1);
    }
  },
  computed: {
    ...mapGetters('LMS', [
      "getCoinsName", "getLoading", "getLms"
    ]),
  },
  methods: {
    clickTab(gate) {
      emitter.emit("checkGateway", gate);
    },
    getGatewayName(gatewayName) {
      let name = gatewayName;

      if(gatewayName.toLowerCase() === "pagseguro"){
        name = "Cartão de Crédito";
      } else if(gatewayName.toLowerCase() === "coins"){
        name = this.getCoinsName;
      } else if(gatewayName.toLowerCase() === "free"){
        name = "Gratuito";
      }

      return name;
    },
    getEtalentIcon(etalentName) {
      etalentName = etalentName.toLowerCase();
      var icon = "";

      if(etalentName === "courses") {
        icon = "local_library"
      } else if (etalentName === "etalent") {
        icon = "equalizer"
      }

      return icon;
    },
    getEtalentName(etalentName) {
      etalentName = etalentName.toLowerCase();
      var name = "";
    
      if(etalentName === "courses"){
        name = "Meus Cursos";
      } else if(etalentName === "etalent"){
        name = "Pessoal";
      }

      return name;
    },
    getGatewayIcon(gatewayName){

      gatewayName = gatewayName.toLowerCase();
      var icon = "";

      if(gatewayName === "pagseguro"){
        icon = "credit_card";
      } else if(gatewayName === "coins"){
        icon = "toll";
      } else if(gatewayName === "pix"){
        icon = "monetization_on";
      } else if(gatewayName === "debito"){
        icon = "payments";
      } else if(gatewayName === "free"){
        icon = "money_off";
      }


      return icon;

    },
    getClasses() {
      let classes = '';
      if( this.tabgroup === 'showEtalent' ) {
        classes = 'col-md-9';
      }
      else if( this.tabgroup === 'showGateways' ) {
        classes = 'col-sm-12 col-md-9';
      }


      classes += (this.ismob) ? ' mob' : ' desk';


      return classes;
    }
  }

}
</script>
<style lang="scss" scoped>

.gateways {

  #nav-tab {
    button {
      border: 1px solid #F6F6F6;

      span {
        color: #B1B1B1;
      }

      &.active {

        background-color: #F6F6F6;

        .material-symbols-outlined {
          color: var(--buttons_color);
        }

        span {
          color: #706F6F;
        }
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

    }
  }




}

#tabber.mob{
  display: none;
}
#tabber.mob #nav-tab{
  justify-content: center;
  margin-top: 40px;
}
@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
  #tabber{
    padding: 0 !important;
  }
  #tabber.mob{
    display: block;
  }
  #tabber.desk{
    display: none;
  }

  .gateways #nav-tab button{
    padding: 8px !important;
  }
  .gateways #nav-tab button span{
    font-size: 14px;
    line-height: 16px;
  }
}
@media only screen and (max-width: 480px) {
  
}
</style>