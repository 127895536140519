export default {
    getCart(state) {
        return state.cart;
    },
    getProcessing(state) {
        return state.processing;
    },
    getGateways(state) {
        return state.gateways;
    },
    getCartTotals(state) {
        return state.cartTotals;
    },
    getPublicKey(state) {
        return state.publicKey;
    },
    getGatewaysMinValues(state) {
        return state.gateways_min_values;
    }

}
