<template>

  <div class="card">
    <div class="thumbnail">
      <img v-if="book.book.thumbnail" :src="book.book.thumbnail" class="card-img-top" alt="Imagem do Card">
      <div v-else-if="book.book.template.configs.isPdf"
           class="d-flex align-items-center justify-content-center thumbnail-placeholder pdf">
        <i class="bi bi-file-pdf-fill"></i>
      </div>
      <div v-else class="thumbnail-placeholder">
      </div>
    </div>

    <div class="card-body">
      <h5 class="card-title">{{ book.book.title }}</h5>
      <p class="card-text">{{ $i18n.t('lms.student_book.updated_at', { updated: book.book.updated_at }) }}</p>
    </div>
    <div class="card-footer d-flex justify-content-center py-3">
      <div class="action d-flex gap-1 align-items-center" @click="editBook">
        <span class="material-icons-sharp edit">
          edit
        </span>
        <span>{{ $i18n.t('lms.student_book.book.edit')}}</span>
      </div>
<!--      <div class="action d-flex gap-1 align-items-center">-->
<!--        <span class="material-icons-sharp content_copy">-->
<!--          content_copy-->
<!--        </span>-->
<!--        <span>{{ $i18n.t('lms.student_book.book.copy')}}</span>-->
<!--      </div>-->

<!--      <div class="action d-flex gap-1 align-items-center">-->
<!--        <span class="material-icons-sharp share">-->
<!--          share-->
<!--        </span>-->
<!--        <span>{{ $i18n.t('lms.student_book.book.share')}}</span>-->
<!--      </div>-->
    </div>

    <div class="delete">
      <span class="material-icons-sharp"
            @click="openModal('.js-modal-delete-'+book.id)">
        delete
      </span>
    </div>

    <!-- Modal delete -->
    <div class="modal" id="modal-delete-book"
         tabindex="-1"
         role="dialog"
         :class="'js-modal-delete-'+book.id"
         @click="closeModalOverlay('.js-modal-delete-'+book.id, $event)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title">{{ $i18n.t('lms.student_book.delete_book')}}</h5>
            <span class="material-icons-sharp close" @click="closeModal('.js-modal-delete-'+book.id)">
                close
              </span>

          </div>

          <div class="modal-body text-center w-100">
            <span>{{ $i18n.t("lms.student_book.confirm_delete_book") }}</span>
          </div>

          <div class="modal-footer">
            <div class="close-modal" @click="closeModal('.js-modal-delete-'+book.id)">
              <span>{{ $i18n.t('lms.student_book.cancel') }}</span>
            </div>
            <div class="delete-book" @click="deleteBook()">
              <span class="accept">{{ $i18n.t('lms.student_book.accept') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

let emitter = require('tiny-emitter/instance');

export default {
  name: "LmsBook",
  props: {
    book: Object
  },
  beforeMount() {
    let root_ = this;

    root_.book.book = JSON.parse(root_.book.book);

    if(root_.book.book.template === "em branco") {
      root_.book.book.template = {
        "configs": {
          "isPdf": false
        }
      }
    } else {
      if (typeof root_.book.book.template.configs === 'string') {
        root_.book.book.template.configs = JSON.parse(root_.book.book.template.configs);
      }

    }


  },
  methods: {
    editBook() {

      this.$store.dispatch("Whiteboard/setSelectedBook", this.book);
      this.$store.dispatch("Whiteboard/setBookCreatedNow", false);

      this.$router.push({
        name: 'Whiteboard',
        params: {
          lmsSlug: this.$route.params.lmsSlug
        }
      });

    },
    deleteBook() {

      let root_ = this;

      let payload = {
        "book_id": root_.book.id
      }

      root_.$store.dispatch("Whiteboard/deleteBook", payload)
        .then(() => {
          emitter.emit("studentbook::book_deleted", root_.book.id);
        })
        .finally(() => {
          root_.closeModal('.js-modal-delete-'+root_.book.id);
        })
    },
    openModal(modalClass) {
      document.querySelector(modalClass).style.display = 'block';
    },
    closeModal(modalClass) {
      document.querySelector(modalClass).style.display = 'none';
    },
    closeModalOverlay(modalClass, e){
      if(e.target.id === "modal-delete-book") {
        this.closeModal(modalClass);
      }
    }
  }

}
</script>

<style scoped lang="scss">


  #modal-delete-book {
    color: var(--buttons_color);
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: #00000080;


    .modal-dialog {
      position: absolute;
      top: 50%;
      left: 50%;
    }
    .modal-content {
      position: unset !important;
      width: 955px;
      min-height: fit-content;
      max-height: 550px;
    }

    .modal-body {
      span {
        color: #706F6F;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .material-icons-sharp.close {
      color: #706F6F;
      font-family: Material Icons;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }

    .modal-footer {

      .close-modal {
        border-radius: 20px;
        background: var(--buttons_color);
        cursor: pointer;

        span {
          display: flex;
          padding: 10px 28px;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;
          font-size: 16px;
        }

      }

      .delete-book {
        color: #E82E2E;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }
    }


  }

  .card {
    max-width: 312px;
    border-radius: 27px;
    border: none;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.10);
    &:hover {
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
    }

    .thumbnail {
      .thumbnail-placeholder {
        width: 310px;
        height: 213px;
        background-color: #858796;
        border-radius: 27px 27px 0 0;

        &.pdf {
          background-color: transparent;

          .bi.bi-file-pdf-fill {
            font-size: 100px;
          }
        }
      }


    }

    .card-img-top {
      height: 213px;
    }

    .card-body {

      border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);

      .card-title {
        color: #706F6F;
        font-family: "Open Sans", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .card-text {
        color: #706F6F;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .card-footer {

      background-color: transparent;

      .action {
        cursor: pointer;
      }

      .material-icons-sharp.edit {

        color: #2EA5E8;
        font-family: Material Icons;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .material-icons-sharp.content_copy {
        color: #B1B1B1;
        font-family: Material Icons;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .material-icons-sharp.share {
        color: var(--buttons_color);
        font-family: Material Icons;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }




    }


    .delete {
      cursor: pointer;
      border-radius: 100px;
      background: #FFF;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
      line-height: 1px;
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 5px;

      .material-icons-sharp {
        color: #E82E2E;
        font-family: Material Icons;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

  }

  @media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
  .books {
    justify-content: center;
  }


.modal-dialog {
    position: absolute;
    top: 50%;
    left: 48% !important;
  }

  .modal-header {
    .modal-title {
      font-size: 22px;
    }
  }

  .modal-content {
    position: unset !important;
    width: 100vw !important;
    min-height: fit-content;
    padding: 15px 15px;
    justify-content: center;
  }

}
@media only screen and (max-width: 480px) {
  
}
</style>
