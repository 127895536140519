<template>
    <div id="modal" ref="modal" class="modals bgBlack">
        <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="close"></div>
        <div ref="buy-handler-content" class="modalGerais" style="max-width:450px;">
            <div class="modals__content alignVertical">
                <div class="modals__content--header alignVertical reward_header">
                    <p>PARABÉNS</p>
                    <a href="#" class="close" data-response="0" v-on:click="$app.popUpPoints = false">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                    
                    <!-- <span class="close" v-on:click="close"><svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.09654 8.00002L15.5443 1.55224C15.6717 1.40345 15.7383 1.21206 15.7308 1.01631C15.7232 0.820559 15.642 0.634873 15.5035 0.496355C15.365 0.357837 15.1793 0.27669 14.9836 0.269129C14.7878 0.261568 14.5964 0.328151 14.4476 0.455571L7.99987 6.90335L1.55209 0.447793C1.40563 0.301335 1.20699 0.219055 0.999871 0.219055C0.792747 0.219055 0.594107 0.301335 0.447648 0.447793C0.30119 0.594252 0.21891 0.792892 0.21891 1.00002C0.21891 1.20714 0.30119 1.40578 0.447648 1.55224L6.9032 8.00002L0.447648 14.4478C0.366229 14.5175 0.300102 14.6033 0.253418 14.6998C0.206733 14.7963 0.180498 14.9014 0.176361 15.0085C0.172223 15.1156 0.190272 15.2225 0.229375 15.3223C0.268477 15.4221 0.327789 15.5127 0.403587 15.5885C0.479385 15.6643 0.570033 15.7236 0.669842 15.7627C0.76965 15.8018 0.876464 15.8199 0.983579 15.8157C1.09069 15.8116 1.1958 15.7854 1.29229 15.7387C1.38879 15.692 1.47459 15.6259 1.54431 15.5445L7.99987 9.09668L14.4476 15.5445C14.5964 15.6719 14.7878 15.7385 14.9836 15.7309C15.1793 15.7233 15.365 15.6422 15.5035 15.5037C15.642 15.3652 15.7232 15.1795 15.7308 14.9837C15.7383 14.788 15.6717 14.5966 15.5443 14.4478L9.09654 8.00002Z" fill="black"/>
                    </svg></span> -->
                </div>
                <div class="modals__content--body alignVertical">
                    <div class="reward_message" v-if="message != ''">
                        {{ message }}
                    </div>
                    <a v-if="ctaButton != null && ctaLabel != null" :href="ctaButton" class="cta_button">{{ ctaLabel }}</a>
                </div>
            </div>
    </div>
    </div>
</template>
<script>
    export default {
        name: 'ModalPopupPoints',
        props: ['ctaButton','message','ctaLabel'],
        data: function() {
            return {

            }

        },
        methods: {
            close(e) {
                this.$app.modal_rewards = false;
                e.preventDefault()
            }
        },
        created() {

        },
        mounted() {
            console.log('cta =>', this.ctaButton)
        },

    }
</script>

<style scoped>
.reward_message {
    width: 100%;
    text-align: center;
    font-size: 17px;
    margin-bottom: 40px;
    word-break: break-word;
    font-family: "Roboto";
}

.reward_items {
    font-size: 17px;
    font-family: "Roboto";
    margin-bottom: 5px;
}

.cta_button {
    background-color: var(--buttons_color);
    color: var(--fonts_color);
    max-width: 409px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 24px;
    border-radius: 6px;
}

.reward_header a {
    margin-left: 21px;
}
.reward_header p {
    width: 76px;
    margin-left: 19px;
}

.close{
cursor: pointer;
position: relative;
right: -165px;
top: 0;
}
</style>