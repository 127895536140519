<template>
    <section class="schedule">

        <div class="schedule--year">
            <div class="schedule--year--left">
            </div>
<!--            <div class="schedule&#45;&#45;year&#45;&#45;right">
                <p>{{ $t('agenda.selecioneUmAmbiente') }}</p>
                <select name="cars" id="cars">
                    <option value="volvo">{{ $t('agenda.roca') }}</option> 
                </select>
            </div>-->
        </div>

        <div class="schedule--rodape">
            <div class="schedule_header">

                <p>Agenda {{eventName}}</p>
                <div class="choose_month">
                    <div class="calendar_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M128 0c13.3 0 24 10.7 24 24V64H296V24c0-13.3 10.7-24 24-24s24 10.7 24 24V64h40c35.3 0 64 28.7 64 64v16 48V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 144 128C0 92.7 28.7 64 64 64h40V24c0-13.3 10.7-24 24-24zM400 192H48V448c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V192zM329 297L217 409c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 95-95c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    </div>
                    <p>{{this.currentMonth}}</p>
                    <div class="month_buttons">
                        <svg xmlns="http://www.w3.org/2000/svg" class="swipe" v-if="monthsWithEvents.length > 0 && currentMonth != monthsWithEvents[0][1]" @click="agendasLoad((monthsWithEvents.filter((m) => m[1] == currentMonth)[0][0])-1)" height="1.5em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#E1E1E1" v-else height="1.5em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" class="swipe" v-if="monthsWithEvents.length > 0 && currentMonth != monthsWithEvents.slice(-1)[0][1]" @click="agendasLoad((monthsWithEvents.filter((m) => m[1] == currentMonth)[0][0])+1)" fill="var(--buttons_color)"  height="1.5em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#E1E1E1" v-else height="1.5em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </div>
                </div>

                <!-- <div class="choose_month">
                    <img class="schedule--year--left--image" src="assets/images/schedule/year.svg" />
                    <p>{{ $t('agenda.8752', { '1': currentMonth }) }}</p>
                </div> -->
            </div>
            <div class="schedule--rodape--content swiper-container">
                <div class="swiper-wrapper">
                    <div v-for="(event, index) in events"
                        :class="'swiper-slide schedule--rodape--content--element ' + (event.day==currentDay ? 'active' : 'deactive')"
                        :key="index" v-on:click="currentDay=event.day">
                        <div class="schedule_title">
                            <span>{{ event.day }}</span><span>{{currentMonth}}</span>
                        </div>
                        <div class="events_container">
                            <div class="event_data" v-for="(e, indexE) in eventsSchedules(event.events)"
                            :key="'event'+indexE">
                                <span >{{e[0]}}</span>
                                <div class="details_container">
                                    <div class="details_content" v-for="(f, indexF) in e[1]" :key="indexF">
                                        <div class="event_bar"></div>
                                        <div class="event_details">
                                            <span>{{f[0]}}</span>
                                            <span>{{f[1]}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="see_more" v-if="event.events.length > 3">Ver mais...</span>
                    </div>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </div>
        <div class="swiper-pagination"></div>

        <div class="schedule--events">
            <p>Confira a agenda de {{currentDay}} de {{currentMonth}}</p>
            <div v-for="(event, index) in eventsOfDay" class="schedule--events--event" :key="index"
                :class="'schedule--rodape--content--event ' + (index % 2 === 0 ? 'left' : 'right')">
                <!-- <div class="schedule--events--event--image" v-if="event.breakouts && event.breakouts[0]" :style="'background-image: url('+event.breakouts[0].image+')'">

                </div> -->
                <!--                <img class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;image" src="assets/images/schedule/eventDefaultImage.svg" />  -->
                <div
                    class="schedule--events--event--div"
                >
                    <div class="schedule--events--event--div--hour">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                        <p
                            class="schedule--events--event--div--hour--text"
                        >
                            {{ event.initialHour }} às {{ event.endHour }}
                        </p>
                    </div>
                    <h3 class="schedule--events--event--div--title">{{ event.title }}</h3>
                    <p class="schedule--events--event--div--details">
                        {{ event.details }}
                    </p>
                    <button v-if="event.breakouts && event.breakouts[0] && event.breakouts[0].link!==''" v-on:click="goToRoom(event.breakouts[0].link)" class="schedule--events--event--div--button" >
                        {{ $t('agenda.acessar') }}
                    </button>
                </div>
                <img v-if="event.breakouts && event.breakouts[0]" class="schedule--events--event--image" :src="event.breakouts[0].image" />

            </div>
            <!--            <div v-for="(event, index) in eventsOfDay" :key="index">
                <div class="schedule&#45;&#45;events&#45;&#45;event">
                    &lt;!&ndash;                <img class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;image" src="assets/images/schedule/eventDefaultImage.svg" />  &ndash;&gt;
                    <div class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div" style="background-color: rgba(0,0,0,.2);flex-direction: row;margin: 0 auto;width: 100%;justify-content: space-evenly;">
                        <div class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;hour" style="margin: 0;display: flex;align-items: center;">
                            <img class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;hour&#45;&#45;image" src="assets/images/schedule/hour.svg" />
                            <p class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;hour&#45;&#45;text" >
                                {{ event.initialHour }} às {{ event.endHour }}
                            </p>
                        </div>
                        <div style="margin: 0;display: flex;flex-direction: column;align-items: flex-start; width: 70%;">
                            <h3 class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;title">{{ event.title }}</h3>
                            <p class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;details">
                                {{ event.details }}
                            </p>
                        </div>
                    </div>
                </div>
                <div v-for="(breakout, indexb) in event.breakouts" class="schedule&#45;&#45;events&#45;&#45;event" :key="indexb"
                     :class="'schedule&#45;&#45;rodape&#45;&#45;content&#45;&#45;event ' + (indexb % 2 === 0 ? 'left' : 'right')">
                    <img v-if="breakout.image" class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;image" :src="breakout.image" />
                    &lt;!&ndash;                <img class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;image" src="assets/images/schedule/eventDefaultImage.svg" />  &ndash;&gt;
                    <div class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div">
                        <div class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;hour">
                            <img class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;hour&#45;&#45;image" src="assets/images/schedule/hour.svg" />
                            <p class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;hour&#45;&#45;text" >
                                {{ event.initialHour }} às {{ event.endHour }}
                            </p>
                        </div>
                        <h3 class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;title">{{ event.title }}</h3>
                        <p class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;details">
                            {{ event.details }}
                        </p>
                        <button v-if="breakout.link!==''" v-on:click="goToRoom(breakout.link)" class="schedule&#45;&#45;events&#45;&#45;event&#45;&#45;div&#45;&#45;button" >ACESSAR</button>
                    </div>
                </div>
            </div>-->
        </div>

        <div class="footer">
            <img
                src="assets/images/footer.svg" 
                alt="background do footer (menu mapa)"
            >
        </div>
    </section>
</template>

<script>
    import { mapGetters } from "vuex";
    import axios from "axios";
    import Swiper from 'swiper/bundle';

    export default {
        name: 'Support',
        computed: {
            ...mapGetters([
                'logged', 'token','sessions', 'event_id'
            ]),
            eventsOfDay() {
                const today = this.currentDay;
                const month = this.events?.find((month) => month?.day === today);
                const events = month?.events; 
                return events;
            }
        },
        data() {
            return {
                time_ini: '00:00',
                time_fin: '23:59',
                events: [],
                swiper: null,
                currentDay:0,
                swiperMonths: null,
                eventName: '',
                currentMonth:"",
                eventHour: "",
                checkDate: [],
                selectedMonth: {},
                months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                monthsWithEvents: []
            };
        },
        methods: {
            eventsSchedules(schedules) {
                let result = []
                // console.log('schedules =>', schedules)
                const keyValuePairs = {}
                for(let i = 0; i < schedules.length; i++) {

                    const key = schedules[i].initialHour
                    const value = [schedules[i].title, schedules[i].details]
                    if (keyValuePairs[key]) {
                        keyValuePairs[key].push(value)
                    } else {
                        keyValuePairs[key] = [value]
                    }
                }
                result = Object.entries(keyValuePairs)
                // result[s.initialHour] = {'title': s.title}
                // result[s.initialHour] = {'details': s.details}
                
                // console.log('result =>', result)
                return result
            },
            goToRoom(ambient) {
                const sessions = JSON.parse(JSON.stringify(this.sessions));

                const session = sessions.find(obj => {
                    return obj.name === ambient;
                });

                if (session === undefined) this.$router.push({name: 'Map'});
                this.$router.push({ name: 'Iframe', params:{sessionName: session.name,eventName:localStorage.getItem("eventmap")} });
            },
            async agendasLoad(month){
                this.loading = true;
                this.$app.wait(true);
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                const url = new URL(this.$url_api + 'api/getAgendasDetailed');
                url.searchParams.append("month",month);
                url.searchParams.append("ambient","");
                url.searchParams.append("event_id",this.$app.event_id);
                return await axios.get(url.toString(), { headers:headers }).then(response => this.agendasLoadResponse(response)).catch(error => this.agendasLoadError(error.response));
            },
            agendasLoadResponse(obj){
                // console.log('Sucesso->',obj);
                this.events   = obj.data.data;
                this.time_ini = obj.data.earliestTimeIni;
                this.time_fin = obj.data.latestTimeFin;
                this.currentMonth = obj.data.month;
                this.currentDay = obj.data.data[0]?.day;
                this.eventName = obj.data.event_name;
                obj.data.monthsWithEvents.forEach((m) => {
                    if (!this.monthsWithEvents.includes([m, this.months[m - 1]])) {
                        this.monthsWithEvents.push([m, this.months[m - 1]])
                    }

                })
                // this.items = obj.data.data;
                this.monthsWithEvents.sort((a,b) => a[0] - b[0]);
                this.$app.wait(false);
                // console.log('current month =>', this.currentMonth)
                // console.log('months with events =>', this.monthsWithEvents)
                // console.log('events =>', this.events)
            },
            agendasLoadError(obj){
                console.log('Entrou no erro->',obj);
                this.$app.wait(false);
            }
        },
        mounted() {
            this.agendasLoad((new Date()).getMonth()+1)
            this.swiper = new Swiper(".schedule--rodape--content",{
                slidesPerView:'auto',
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            })
        },
        updated() {
            this.swiper.update();
        }
    }
</script>