<template>
    <div class="modals bgTransparent bg-brown" ref="camera">
        <div v-if="!galeria && foto">
            <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" v-on:click="$app.camera=false"></div>
            <div class="tirarFoto">
                <div class="modals__content">
                    <div class="modals__content--header alignVertical">
                        <p>{{ $t('camera.cmera') }}</p>
                        <a class="btn-0" v-on:click="$app.camera=false;$app.open_gallery=false;">
                            <img src="assets/icons/modals/x.svg" alt="">
                        </a>
                    </div>
                    <div class="modals__content--body"> 
                        
                        <div class="alignVertical">
                            <div class="alignVertical foto col-6" v-on:click="print();foto=false">
                                <div class="alignVertical col-12">
                                    <div class="box alignVertical">
                                        <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.9141 4.37891H21.5312L20.4871 1.45273C20.415 1.2525 20.2828 1.07943 20.1086 0.957147C19.9344 0.834865 19.7267 0.769343 19.5139 0.769532H9.62676C9.1917 0.769532 8.80176 1.04346 8.65674 1.45273L7.60938 4.37891H3.22656C1.80215 4.37891 0.648438 5.53262 0.648438 6.95703V21.6523C0.648438 23.0768 1.80215 24.2305 3.22656 24.2305H25.9141C27.3385 24.2305 28.4922 23.0768 28.4922 21.6523V6.95703C28.4922 5.53262 27.3385 4.37891 25.9141 4.37891ZM14.5703 19.0742C11.7215 19.0742 9.41406 16.7668 9.41406 13.918C9.41406 11.0691 11.7215 8.76172 14.5703 8.76172C17.4191 8.76172 19.7266 11.0691 19.7266 13.918C19.7266 16.7668 17.4191 19.0742 14.5703 19.0742ZM11.4766 13.918C11.4766 14.7385 11.8025 15.5254 12.3827 16.1056C12.9629 16.6858 13.7498 17.0117 14.5703 17.0117C15.3908 17.0117 16.1777 16.6858 16.7579 16.1056C17.3381 15.5254 17.6641 14.7385 17.6641 13.918C17.6641 13.0975 17.3381 12.3105 16.7579 11.7304C16.1777 11.1502 15.3908 10.8242 14.5703 10.8242C13.7498 10.8242 12.9629 11.1502 12.3827 11.7304C11.8025 12.3105 11.4766 13.0975 11.4766 13.918Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>

                                <p>{{ $t('camera.tirarFoto') }}</p>

                                <!-- <div class="alignVertical col-12">
                                    <button v-if="foto" v-on:click="print();$app.camera=false">{{ $t('camera.tirarFoto') }}</button>
                                </div> -->
                            </div>
                            <div class="alignVertical col-6" v-on:click="openGallery">
                                <div class="alignVertical col-12">
                                    <div class="box alignVertical">
                                        <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M28.442 0.166687H2.55536C2.09831 0.166687 1.65997 0.348252 1.33678 0.67144C1.0136 0.994627 0.832031 1.43296 0.832031 1.89002V18.61C0.832031 19.0671 1.0136 19.5054 1.33678 19.8286C1.65997 20.1518 2.09831 20.3334 2.55536 20.3334H28.442C28.8991 20.3334 29.3374 20.1518 29.6606 19.8286C29.9838 19.5054 30.1654 19.0671 30.1654 18.61V1.89002C30.1654 1.43296 29.9838 0.994627 29.6606 0.67144C29.3374 0.348252 28.8991 0.166687 28.442 0.166687ZM6.84536 3.32919C7.38926 3.32919 7.92095 3.49047 8.37318 3.79265C8.82542 4.09482 9.17789 4.52431 9.38603 5.02681C9.59417 5.5293 9.64863 6.08224 9.54252 6.61569C9.43641 7.14913 9.1745 7.63914 8.78991 8.02373C8.40531 8.40833 7.91531 8.67024 7.38186 8.77635C6.84842 8.88246 6.29548 8.828 5.79298 8.61986C5.29049 8.41171 4.861 8.05924 4.55882 7.60701C4.25665 7.15477 4.09536 6.62309 4.09536 6.07919C4.09536 5.34984 4.3851 4.65037 4.90082 4.13464C5.41655 3.61892 6.11602 3.32919 6.84536 3.32919ZM26.4987 16.6667H4.4987L11.337 9.81919C11.4589 9.69828 11.6237 9.63043 11.7954 9.63043C11.9671 9.63043 12.1318 9.69828 12.2537 9.81919L15.627 13.1925L20.2745 8.41669C20.3964 8.29578 20.5612 8.22793 20.7329 8.22793C20.9046 8.22793 21.0693 8.29578 21.1912 8.41669L26.4987 13.7242V16.6667Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>

                                <p>{{ $t('camera.galeria') }}</p>

                                <!-- <div class="alignVertical col-12">
                                    <button v-on:click="openGallery">{{ $t('camera.galeria') }}</button>
                                </div> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div :style="((galeria || !foto)?'display:flex':'display:none')" class="modals bgBlack galeria-selfies">
            <div class="container-master-galery" v-on:click="$app.camera=false;$app.open_gallery=false;"></div>
            <div id="galeria" class="galeria">
                <div class="modals__content">
                    <div class="modals__content--header alignVertical container-header">
                        <p >{{ $t('camera.galeriaDeFotos') }}</p>
                        
           
                        <a class="btn-0" v-on:click="$app.camera=false;$app.open_gallery=false;">
                            <img src="assets/icons/modals/x.svg" alt="">
                        </a>
                    </div>

                        <div class="swiper-container modalGaleria">
                            <div class="swiper-wrapper">
                                 <div v-for="(item, index) in selfies" :id="index+'selfieSlide'" :key="index" class="swiper-slide">
                                    <img :src="item.path">
                                </div>
                                <div class="nenhuma" :style="(selfies.length==0?'':'display:none')">
                                    <img src="../../public/assets/images/modals/bi_image.png" alt="imagem default para quando não haver selfies">
                                    <h3>{{ $t('camera.nenhumaFoto') }}</h3>
                                </div>
                            </div>
                            <div :class="{'swiper-button-next':true, 'd-none':selfies.length<=1}">
                                <svg width="24" height="44" viewBox="0 0 24 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.9979 21.9997C21.9993 22.7006 21.7551 23.3799 21.3079 23.9197L6.3079 41.9197C5.79868 42.5323 5.06695 42.9176 4.27367 42.9907C3.48039 43.0639 2.69055 42.8189 2.0779 42.3097C1.46525 41.8004 1.07997 41.0687 1.00684 40.2754C0.933696 39.4822 1.17868 38.6923 1.6879 38.0797L15.1279 21.9997L2.1679 5.91967C1.9187 5.6128 1.7326 5.25972 1.62031 4.8807C1.50801 4.50168 1.47173 4.10421 1.51355 3.71112C1.55537 3.31804 1.67446 2.93709 1.86398 2.59018C2.0535 2.24327 2.30972 1.93724 2.6179 1.68967C2.92636 1.41493 3.28824 1.20685 3.68086 1.07848C4.07348 0.950096 4.48838 0.90419 4.89957 0.943619C5.31076 0.983047 5.70938 1.10696 6.07045 1.30761C6.43152 1.50826 6.74726 1.78132 6.9979 2.10967L21.4879 20.1097C21.8644 20.6649 22.044 21.3304 21.9979 21.9997Z" fill="var(--buttons_color)"/>
                                </svg>
                            </div>
                            <div :class="{'swiper-button-prev':true, 'd-none':selfies.length<=1}" >
                                <svg width="24" height="44" viewBox="0 0 24 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00211 22.0003C1.00074 21.2994 1.24486 20.6201 1.6921 20.0803L16.6921 2.08034C17.2013 1.46769 17.9331 1.08242 18.7263 1.00928C19.5196 0.936137 20.3095 1.18112 20.9221 1.69034C21.5348 2.19955 21.92 2.93129 21.9932 3.72457C22.0663 4.51785 21.8213 5.30769 21.3121 5.92034L7.87211 22.0003L20.8321 38.0803C21.0813 38.3872 21.2674 38.7403 21.3797 39.1193C21.492 39.4983 21.5283 39.8958 21.4864 40.2889C21.4446 40.682 21.3255 41.0629 21.136 41.4098C20.9465 41.7567 20.6903 42.0628 20.3821 42.3103C20.0736 42.5851 19.7118 42.7931 19.3191 42.9215C18.9265 43.0499 18.5116 43.0958 18.1004 43.0564C17.6892 43.017 17.2906 42.893 16.9296 42.6924C16.5685 42.4917 16.2527 42.2187 16.0021 41.8903L1.51211 23.8903C1.13556 23.3351 0.955994 22.6696 1.00211 22.0003V22.0003Z" fill="var(--buttons_color)"/>
                                </svg>
                            </div>
                        </div>
                        <footer v-if="selfies.length!=0"  class="container-footer">
                            <div class="container-especification-image">
                                <button title="Excluir selfie" v-on:click="destroySelfie()" style="cursor:pointer;border:none;" >
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="17.5" cy="17.5" r="17.5" fill="#E00000"/>
                                        <path d="M24 9.94444H20.75L19.8214 9H15.1786L14.25 9.94444H11V11.8333H24M11.9286 24.1111C11.9286 24.6121 12.1242 25.0925 12.4725 25.4468C12.8208 25.801 13.2932 26 13.7857 26H21.2143C21.7068 26 22.1792 25.801 22.5275 25.4468C22.8758 25.0925 23.0714 24.6121 23.0714 24.1111V12.7778H11.9286V24.1111Z" fill="white"/>
                                    </svg>
                                </button>

                                <div class="identifier-image">
                                    <span class="text-generic">{{ $t('camera.foto') }}&nbsp;<span class="swiper-pagination">{{ this.currentImg+1 }}/{{ this.selfies.length }}</span></span>
                                </div>
                                <span class="text-generic date-mobile">{{ this.currentSelfie.created_at }}</span>
                            </div>
                            <div class="container-social-network">
                                <button title="Gerar QRcode" v-on:click="printGaleria('qrcode')" style="cursor: pointer; border: none;">
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="17.5" cy="17.5" r="17.5" fill="var(--buttons_color)"/>
                                        <g clip-path="url(#clip0_1641_14340)">
                                            <path d="M11.8333 11.8333H16.0833V16.0833H11.8333V11.8333ZM23.1667 11.8333V16.0833H18.9167V11.8333H23.1667ZM18.9167 19.625H20.3333V18.2083H18.9167V16.7917H20.3333V18.2083H21.75V16.7917H23.1667V18.2083H21.75V19.625H23.1667V21.75H21.75V23.1667H20.3333V21.75H18.2083V23.1667H16.7917V20.3333H18.9167V19.625ZM20.3333 19.625V21.75H21.75V19.625H20.3333ZM11.8333 23.1667V18.9167H16.0833V23.1667H11.8333ZM13.25 13.25V14.6667H14.6667V13.25H13.25ZM20.3333 13.25V14.6667H21.75V13.25H20.3333ZM13.25 20.3333V21.75H14.6667V20.3333H13.25ZM11.8333 16.7917H13.25V18.2083H11.8333V16.7917ZM15.375 16.7917H18.2083V19.625H16.7917V18.2083H15.375V16.7917ZM16.7917 13.25H18.2083V16.0833H16.7917V13.25ZM10.4167 10.4167V13.25H9V10.4167C9 10.0409 9.14926 9.68061 9.41493 9.41493C9.68061 9.14926 10.0409 9 10.4167 9H13.25V10.4167H10.4167ZM24.5833 9C24.9591 9 25.3194 9.14926 25.5851 9.41493C25.8507 9.68061 26 10.0409 26 10.4167V13.25H24.5833V10.4167H21.75V9H24.5833ZM10.4167 21.75V24.5833H13.25V26H10.4167C10.0409 26 9.68061 25.8507 9.41493 25.5851C9.14926 25.3194 9 24.9591 9 24.5833V21.75H10.4167ZM24.5833 24.5833V21.75H26V24.5833C26 24.9591 25.8507 25.3194 25.5851 25.5851C25.3194 25.8507 24.9591 26 24.5833 26H21.75V24.5833H24.5833Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1641_14340">
                                                <rect width="17" height="17" fill="white" transform="translate(9 9)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                                <button title="Compartilhe por e-mail" v-on:click="printGaleria('mailto')" style="cursor: pointer; border: none;">
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="17.5" cy="17.5" r="17.5" fill="var(--buttons_color)"/>
                                        <path d="M26.8985 21.95V12.05C26.8985 11.137 26.1615 10.4 25.2485 10.4H9.8375C8.9245 10.4 8.1875 11.137 8.1875 12.05V21.95C8.1875 22.863 8.9245 23.6 9.8375 23.6H25.2485C26.1615 23.6 26.8985 22.863 26.8985 21.95ZM25.4575 11.929C25.8205 12.292 25.6225 12.666 25.4245 12.853L20.9585 16.945L25.2485 21.411C25.3805 21.565 25.4685 21.807 25.3145 21.972C25.1715 22.148 24.8415 22.137 24.6985 22.027L19.8915 17.924L17.5375 20.069L15.1945 17.924L10.3875 22.027C10.2445 22.137 9.9145 22.148 9.7715 21.972C9.6175 21.807 9.7055 21.565 9.8375 21.411L14.1275 16.945L9.6615 12.853C9.4635 12.666 9.2655 12.292 9.6285 11.929C9.9915 11.566 10.3655 11.742 10.6735 12.006L17.5375 17.55L24.4125 12.006C24.7205 11.742 25.0945 11.566 25.4575 11.929V11.929Z" fill="white"/>
                                    </svg>
                                </button>
                                <button title="Faça o download da sua selfie" v-on:click="printGaleria('download')" style="cursor:pointer;border:none;" >
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="17.5" cy="17.5" r="17.5" fill="var(--buttons_color)"/>
                                        <path d="M17.4987 19.875V10.375M17.4987 19.875L14.332 16.7083M17.4987 19.875L20.6654 16.7083M9.58203 21.4583L10.0737 23.4256C10.1593 23.7682 10.3569 24.0723 10.6352 24.2896C10.9135 24.5069 11.2564 24.6249 11.6095 24.625H23.3879C23.741 24.6249 24.0839 24.5069 24.3622 24.2896C24.6405 24.0723 24.8381 23.7682 24.9237 23.4256L25.4154 21.4583" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" fill="var(--buttons_color)"/>
                                    </svg>
                                </button>
                                <button title="Compartilhe no Facebook"  v-on:click="printGaleria('face')" style="cursor:pointer;border:none;" >
                                    <svg width="35" height="35" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_844_16166)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 18.5549C0 27.2767 6.33453 34.5294 14.6193 36V23.3295H10.2335V18.4569H14.6193V14.558C14.6193 10.1722 17.4452 7.73663 21.4421 7.73663C22.7081 7.73663 24.0735 7.93107 25.3396 8.1255V12.6092H23.0984C20.9538 12.6092 20.467 13.6808 20.467 15.0463V18.4569H25.1451L24.3659 23.3295H20.467V36C28.7517 34.5294 35.0862 27.2782 35.0862 18.5549C35.0862 8.85208 27.1918 0.913818 17.5431 0.913818C7.8944 0.913818 0 8.85208 0 18.5549Z" fill="var(--buttons_color)"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_844_16166">
                                        <rect width="35.0862" height="35.0862" fill="white" transform="translate(0 0.913818)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                                <button title="Compartilhe no Whatsapp" v-on:click="printGaleria('whats')" style="cursor:pointer;border:none;" >
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_844_16168)">
                                        <circle cx="17.5" cy="17.5" r="17.5" fill="var(--buttons_color)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5871 29.8271C15.4963 29.8169 13.4421 29.2816 11.6156 28.2711L5 30L6.74095 23.5684C5.61823 21.695 5.01738 19.5588 5 17.3788C5.00693 14.9288 5.74374 12.5356 7.11762 10.5006C8.49151 8.46568 10.441 6.87997 12.7206 5.94321C15.0003 5.00645 17.508 4.76054 19.9281 5.23646C22.3482 5.71238 24.5723 6.88884 26.3204 8.61767C28.0685 10.3465 29.2624 12.5504 29.7517 14.9517C30.241 17.3531 30.0038 19.8445 29.07 22.1123C28.1362 24.38 26.5476 26.3227 24.5042 27.6955C22.4608 29.0684 20.0541 29.81 17.5871 29.8271ZM8.56598 12.4782C9.4594 10.8741 10.7649 9.5338 12.3495 8.59369C13.1553 8.04116 15.3273 6.9361 17.5697 6.9361C20.34 6.9361 22.9969 8.02903 24.9559 9.97445C26.9148 11.9199 28.0154 14.5584 28.0154 17.3097C28.0154 20.0609 26.9148 22.6995 24.9559 24.6449C22.9969 26.5903 20.34 27.6832 17.5697 27.6832C15.7319 27.6695 13.9303 27.1745 12.3468 26.2482L11.9638 26.0235L7.99443 27.0954L9.039 23.309L8.79527 22.9114C7.83223 21.3476 7.30366 19.5582 7.26338 17.7255C7.22311 15.8928 7.67256 14.0823 8.56598 12.4782Z" fill="white"/>
                                        <path d="M23.6804 21.8568C23.4524 22.2345 23.147 22.5604 22.7842 22.8136C22.4213 23.0667 22.009 23.2414 21.5738 23.3263C20.9237 23.4444 20.2543 23.4028 19.624 23.2053C19.0309 23.0212 18.4496 22.8018 17.883 22.5483C15.7308 21.4758 13.8953 19.8674 12.5557 17.8802C11.8278 16.9595 11.3861 15.8479 11.2848 14.6817C11.2745 14.1972 11.3667 13.716 11.5557 13.2692C11.7446 12.8225 12.026 12.4201 12.3816 12.0883C12.4864 11.9704 12.6149 11.8756 12.7588 11.8101C12.9028 11.7445 13.0589 11.7098 13.2173 11.7079H13.7048C13.8963 11.7079 14.1574 11.7079 14.4011 12.2439C14.5217 12.5091 14.7403 13.0367 14.941 13.521C15.1459 14.0155 15.3321 14.4648 15.3761 14.5434C15.4188 14.6263 15.4411 14.7182 15.4411 14.8114C15.4411 14.9046 15.4188 14.9964 15.3761 15.0794C15.2991 15.2672 15.1934 15.4422 15.0627 15.598C15.0264 15.6421 14.9892 15.688 14.9517 15.7342C14.8272 15.8877 14.6997 16.045 14.5926 16.1513C14.4534 16.2896 14.2793 16.4625 14.4534 16.7737C14.927 17.5676 15.5134 18.2896 16.1943 18.9176C16.9358 19.5683 17.7836 20.0886 18.7013 20.4563C19.0146 20.6119 19.2062 20.5946 19.3802 20.4563C19.5543 20.318 20.1637 19.5573 20.3726 19.2461C20.5815 18.9349 20.7904 18.9867 21.069 19.0905C21.3475 19.1942 22.897 19.9376 23.2103 20.0932C23.2699 20.1228 23.3251 20.1493 23.376 20.1737C23.5928 20.2777 23.7318 20.3443 23.8023 20.4563C23.8778 20.9088 23.8358 21.373 23.6804 21.8049V21.8568Z" fill="white"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_844_16168">
                                        <rect width="35" height="35" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                                <button title="Compartilhe no Twitter" v-on:click="printGaleria('twitter')" style="cursor:pointer;border:none;" >
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5431 0.913818C7.85524 0.913818 0 8.76906 0 18.4569C0 28.1448 7.85524 36 17.5431 36C27.231 36 35.0862 28.1448 35.0862 18.4569C35.0862 8.76906 27.231 0.913818 17.5431 0.913818ZM25.974 14.1377C25.9857 14.3218 25.9857 14.5136 25.9857 14.7016C25.9857 20.4501 21.6078 27.0719 13.6077 27.0719C11.1407 27.0719 8.85379 26.3553 6.92718 25.1218C7.27961 25.1609 7.61638 25.1766 7.97664 25.1766C10.0129 25.1766 11.8847 24.4874 13.3766 23.3204C11.4657 23.2813 9.86017 22.0282 9.31195 20.3052C9.98156 20.4031 10.5846 20.4031 11.2738 20.2269C10.2898 20.027 9.40544 19.4926 8.77084 18.7145C8.13624 17.9364 7.79058 16.9627 7.79259 15.9586V15.9038C8.36822 16.2288 9.04567 16.4285 9.75444 16.4559C9.15861 16.0588 8.66997 15.5209 8.33185 14.8897C7.99373 14.2585 7.81657 13.5537 7.81608 12.8377C7.81608 12.0271 8.02754 11.287 8.40738 10.6448C9.49953 11.9892 10.8624 13.0888 12.4073 13.8721C13.9523 14.6554 15.6447 15.1048 17.3747 15.1911C16.7599 12.2346 18.9685 9.84201 21.6235 9.84201C22.8765 9.84201 24.0043 10.3667 24.7992 11.2126C25.7821 11.0285 26.7219 10.6604 27.5599 10.167C27.2349 11.1734 26.5535 12.0232 25.649 12.5596C26.5261 12.4656 27.372 12.2229 28.1551 11.8822C27.5638 12.7515 26.8237 13.5229 25.974 14.1377Z" fill="var(--buttons_color)"/>
                                    </svg>
                                </button>
                            </div>
                        </footer>

                    </div>
                </div>
            </div>
            <QrcodeGen style="z-index: 999" v-if="qrcodeOpen" titleHeader="COMPARTILHE SUA IMAGEM" :url="qrcodeUrl"/>
        </div>
</template>
<script>
import axios from 'axios';
import Swiper from 'swiper/bundle';
import { mapActions, mapGetters } from "vuex";
import QrcodeGen from "@/components/Modals/QrcodeGen";

export default {
    name: 'Camera',
    computed: {
        ...mapGetters([
            'token','current_session', 'email', 'switch'
        ]),
        currentSelfie() {
            if(this.modalGaleria){
                let currentFoto = this.selfies[Number(this.currentImg)];
                return currentFoto;
            } else {
                return {
                    created_at:""
                };
            }
        },
    },
    props: ['open_gallery'],
    data() {
        return {
            foto:false,
            galeria:this.open_gallery,
            modalGaleria:null,
            selfies: [],
            currentImg: 0,
            qrcodeOpen:false,
            qrcodeUrl:"",
        }
    },
    components: {QrcodeGen},
    methods: {
        ...mapActions([]),

        onSwiper(swiper) {
            swiper;
      },

        destroySelfie() {
            let selfie = this.selfies[Number(this.currentImg)];
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            let form_data = new FormData();
            form_data.append('selfie', selfie.id);
            axios.post(this.$url_api + 'api/DestroySelfie', form_data, { headers:headers }).then(response => this.load(response)).catch(error => this.loadError(error.response));
        },
        printGaleria(plataforma){
            this.$app.analytics('share_selfie');

            let urlPlataforma = "";
            let galeriaElemento = document
                .querySelector(".modalGaleria .swiper-slide.swiper-slide-active img")
                ?.getAttribute('src');
                
            switch (plataforma){
                case 'qrcode':
                    this.qrcodeUrl = galeriaElemento
                    this.qrcodeOpen=true;
                    return;
                case 'mailto':
                    urlPlataforma = "mailto:"+this.email+"?Subject=Seu avatar em "+ this.switch.Nome_Aba +"&Body="+galeriaElemento;
                    break;
                case 'download':
                    fetch(galeriaElemento)
                        .then(response=> response.blob())
                        .then(blob => {
                            const a = document.createElement('a');
                            a.href = URL.createObjectURL(blob);
                            a.download = galeriaElemento.substr(galeriaElemento.lastIndexOf('/') + 1);
                            a.click();
                        })
                        .catch(
                            console.error
                        );
                    return;
                case 'whats':
                    urlPlataforma ="https://api.whatsapp.com/send?text="+galeriaElemento;
                    break;
                case 'face':
                    urlPlataforma = "https://www.facebook.com/sharer.php?kid_directed_site=0&display=popup&ref=plugin&src=share_button&u="+galeriaElemento;
                break;
                case 'twitter':
                    urlPlataforma = "http://twitter.com/share?url="+galeriaElemento+"&hashtags=";
                break;
            }
            urlPlataforma = encodeURI(urlPlataforma);
            urlPlataforma = urlPlataforma.replace(/#/g, '%23');
            window.open(urlPlataforma,'_blank');
        },
        getBase64Image(img) {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        },
        print() {
            let canvas;
            let img;
            //let krpano;
            if(this.$route.name ==='Iframe'){
                if(this.current_session !== null && this.current_session.can_self){
                    canvas = document.getElementById('iframe').contentWindow.canvas;
                    img = canvas.toDataURL("image/png");
                    this.selfie(img);
                }
            }
        },

        //----- load -----//
        load() {
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/selfie', { headers:headers }).then(response => this.loadResponse(response)).catch(error => this.loadError(error.response));
        },
        loadResponse(obj) {
            if (obj.data.status == 'success')
            {
                for (let o in obj.data.data) {
                    let t = obj.data.data[o].path.substring(0, obj.data.data[o].path.lastIndexOf("."));
                    obj.data.data[o].path = (t + '_thumb.png');
                }
                this.selfies = obj.data.data;

                this.setConf();
            }
            this.$app.wait(false);
        },
        loadError(obj) {
            obj;
            this.$app.wait(false);
        },
        //----- \load\ -----//


        //----- change -----//
        selfie(img_) {
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            let form_data = new FormData();
            form_data.append('selfie', img_);
            axios.post(this.$url_api + 'api/selfie', form_data, { headers:headers }).then(response => this.selfieResponse(response)).catch(error => this.selfieError(error.response));
        },
        selfieResponse(obj)
        {
            if (obj.data.status == 'success')
            {
                this.$app.analytics('take_selfie');
                this.load();
                this.galeria = true;
            }
            this.$app.wait(false);
        },
        selfieError(obj)
        {
            //console.log('changeError -> ', obj);
            if (obj.data.status == 'error')
            {
                let st = '';
                for (let o in obj.data.errors.selfie) {
                    st += obj.data.errors.selfie[o];
                }
                this.$app.modal('error', 'Sucesso!', st, null, null, null, "OK");
            }
            this.$app.wait(false);
        },
        openGallery() {
            this.$app.analytics('visit_selfie_gallery');
            this.galeria=true;
            this.setConf();
        },
        setConf() {
                var root_ = this;
                //this.galeria = true;
                //console.log('dentro => ', this.galeria, this.foto);
    
                if (this.galeria || !this.foto) {
                    this.modalGaleria = new Swiper(".modalGaleria", {
                        observer: true,
                        observeParents: true,
                        pagination: {
                            el: ".swiper-pagination",
                            type: "fraction",
                        },
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        on: {
                            activeIndexChange(swiper) {
                                root_.currentImg = swiper.activeIndex
                            }
                        }
                    });
                }
        }
        //----- \change\ -----//


    },
    mounted() {

        this.load();
        console.log(this.current_session);
        if(this.$route.name ==='Iframe'){
            if(this.current_session.can_self)
                this.foto = true;
        }
        this.$app.bodyOverflow();

    },
    created() {
        
        
    },
    updated() {
    },
    beforeDestroy() {
        this.$app.bodyOverflow(false)
    },
    destroyed() {
    },
}
</script>
