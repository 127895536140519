/**
 * Inserts HTML, including <script> tags, into a web page and executes the scripts contained within the HTML string.
 *
 * @param {string} htmlString - The HTML string containing the code to be inserted and executed.
 * @param divId
 * @param callback
 */
export function insertHtmlWithScript(htmlString, divId = null) {
    // Create a temporary div element to handle the HTML
    let tempDiv = document.createElement('div');

    divId ?  (tempDiv.id = divId) : (tempDiv.id = 'vt_template_html');

    // Set the HTML content of the div element with the provided HTML string
    tempDiv.innerHTML = htmlString;

    // Find all <script> tags within the div element
    let scripts = tempDiv.querySelectorAll('script');

    // For each <script> tag found, create a new <script> element in the current document
    scripts.forEach(function (script) {
        let newScript = document.createElement('script');
        if (script.src) {
            // If the <script> tag has a src attribute, set it in the new <script> element
            newScript.src = script.src;
        } else {
            // If there is no src attribute, copy the script's content to the new <script> element
            newScript.textContent = script.textContent;
        }
        // Append the new <script> element to the end of the body for execution
        document.body.appendChild(newScript);
    });

    // Insert the HTML content (without <script> tags) wherever you want on the page
    // For example, you can insert it in the body or in a specific element.
    document.body.appendChild(tempDiv);
}


/**
 * Extracts the scene name from a URL.
 *
 * @param {string} url - The URL from which to extract the scene name.
 * @returns {string|null} - The extracted scene name or null if there is no "/" in the URL.
 */
export function extractSceneNameFromURL(url) {
    // Find the position of the last "/" in the URL
    let lastSlashPosition = url.lastIndexOf("/");

    // Check if the last "/" was found
    if (lastSlashPosition !== -1) {
        // Extract the part of the URL after the last "/"
        let sceneName = url.substring(lastSlashPosition + 1);
        // Replace "%20" with spaces
        sceneName = sceneName.replace(/%20/g, " ");
        return sceneName;
    } else {
        return null; // Returns null if there is no "/" in the URL
    }
}


/**
 * Removes a specific div element from the DOM by its ID.
 *
 * @param {string} elementId - The ID of the div element to be removed.
 * @returns {boolean} - True if the element was successfully removed, false if not found.
 */
export function removeDivById(elementId) {
    // Find the div element by its ID
    let elementToRemove = document.getElementById(elementId);

    // Check if the element exists
    if (elementToRemove !== null) {
        // Remove the element from the DOM
        elementToRemove.parentNode.removeChild(elementToRemove);
    }
}
/**
 *  Focus iframe to enable keyboard input
 *
 * @param {boolean} isMobile - true if mobile device
 */
export function focusUnity(isMobile) {
    if (!isMobile && document.getElementById("iframe")){
        document.getElementById("iframe").focus();
    }
}

/**
 * Converts a string containing HTML data into a modified version with specific styles applied.
 * This function parses the input HTML data, applies styles to <blockquote> elements and <p> elements,
 * and then returns the styled HTML as a string. It focuses on enhancing the appearance of blockquotes
 * by adjusting their visual styling and adding pseudo-elements for decorative quotes. Paragraphs also
 * receive style adjustments for better readability.
 *
 * @param {string} htmlData - The HTML content as a string to be styled.
 * @returns {string} The styled HTML content as a string.
 *
 * ### Usage
 * ```javascript
 * const styledHTML = makeHTML('<blockquote>Your quote here</blockquote><p>Your paragraph here.</p>');
 * document.body.innerHTML = styledHTML;
 * ```
 *
 * ### Styling Details
 * - **Blockquotes**:
 *   - Font size increased to 1.2em
 *   - Width set to 100% for full container width
 *   - Margins set to auto for center alignment, with 25px top and bottom margin
 *   - Italic font style for emphasis
 *   - Font color set to a moderate grey (#555555)
 *   - Padding applied for inner spacing, with a significant left padding
 *   - A solid left border for visual separation and emphasis
 *   - Relative positioning to allow for pseudo-element positioning
 *   - Background color set to a light grey for distinction
 *   - Decorative pseudo-elements (::before) added for quotation marks
 *
 * - **Paragraphs (p)**:
 *   - Font size set to 14px for readability
 *   - Line height set to 1.6 for improved text flow and readability
 *
 * Note: This function requires the DOM environment to operate, as it uses `DOMParser` and `XMLSerializer`.
 */
export function makeHTML(htmlData) {
    if(!htmlData || htmlData === "null") {
        return "";
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlData, 'text/html');

    // Localize todos os elementos <blockquote> dentro do elemento
    let blockquotes = doc.querySelectorAll('blockquote');
    // Aplique estilos a cada elemento <blockquote>
    blockquotes.forEach((blockquote) => {
        // Aplique estilos CSS
        blockquote.style.fontSize = '1.2em';
        blockquote.style.width = '100%';
        blockquote.style.margin = '25px auto';
        blockquote.style.fontStyle = 'italic';
        blockquote.style.color = '#555555';
        blockquote.style.padding = '1.2em 30px 1.2em 75px';
        blockquote.style.borderLeft = '8px solid var(--buttons_color)';
        blockquote.style.lineHeight = '1.6';
        blockquote.style.position = 'relative';
        blockquote.style.background = '#EDEDED';

        // Crie elementos ::before e ::after
        const quoteBefore = document.createElement('span');
        const quoteAfter = document.createElement('span');

        // Aplique estilos para ::before
        quoteBefore.style.fontFamily = 'Open Sans';
        quoteBefore.innerHTML = '"';
        quoteBefore.style.color = 'var(--buttons_color)';
        quoteBefore.style.fontSize = '4em';
        quoteBefore.style.position = 'absolute';
        quoteBefore.style.left = '10px';
        quoteBefore.style.top = '-10px';

        // Aplique estilos para ::after
        quoteAfter.style.content = '';

        // Adicione os elementos ::before e ::after ao bloco de citação
        blockquote.appendChild(quoteBefore);
        blockquote.appendChild(quoteAfter);
    });

    // Definição do parágrafo
    let p = doc.querySelectorAll('p');
    p.forEach((p) => {
        p.style.fontSize = '14px';
        p.style.lineHeight = '1.6';
    });

    // Converta o documento de volta para uma string HTML
    return new XMLSerializer().serializeToString(doc);

}


/**
 * Darkens a given color by a specified percentage.
 * This function takes a hexadecimal color code and darkens it by the given percentage,
 * returning the new, darker color as a hexadecimal string.
 *
 * @param {string} color - The original color in hexadecimal format (e.g., '#3498db').
 * @param {number} percent - The percentage by which to darken the color (e.g., 10 for 10% darker).
 * @returns {string} The darkened color as a hexadecimal string.
 */
export function darkenColor(color, percent) {
    // Convert hex color to RGB components
    let R = parseInt(color.slice(1, 3), 16);
    let G = parseInt(color.slice(3, 5), 16);
    let B = parseInt(color.slice(5, 7), 16);

    // Darken each component by reducing it by the specified percentage
    R = Math.max(0, R - Math.round(R * (percent / 100)));
    G = Math.max(0, G - Math.round(G * (percent / 100)));
    B = Math.max(0, B - Math.round(B * (percent / 100)));

    // Convert RGB components back to hex and return the darkened color
    const darkenedColor = `#${R.toString(16).padStart(2, '0')}${G.toString(16).padStart(2, '0')}${B.toString(16).padStart(2, '0')}`;
    return darkenedColor;
}

/**
 * Converts an RGB color string to a hexadecimal color string.
 * This function takes an RGB color string (e.g., 'rgb(52, 152, 219)') and converts it
 * into a hexadecimal color string (e.g., '#3498db'). It is particularly useful when
 * working with color values retrieved from `getComputedStyle`, which often returns colors in RGB format.
 *
 * @param {string} rgb - The RGB color string to be converted (e.g., 'rgb(52, 152, 219)').
 * @returns {string} The equivalent color in hexadecimal format (e.g., '#3498db').
 *
 * ### Usage
 * ```javascript
 * const hexColor = rgbToHex('rgb(52, 152, 219)');
 * console.log(hexColor); // Outputs '#3498db'
 * ```
 *
 * ### How it Works
 * - The function uses a regular expression to extract the numeric RGB values from the string.
 * - Each RGB component is converted from a decimal number to a hexadecimal number.
 * - The components are then concatenated to form the full hexadecimal color string.
 *
 * Note: The function assumes that the input string is in the correct 'rgb(x, y, z)' format.
 */
export function rgbToHex(rgb) {
    const result = rgb.match(/\d+/g).map(function(x) {
        return parseInt(x).toString(16).padStart(2, '0');
    });
    return `#${result.join('')}`;
}

/**
 * Applies a darkened color on hover to all elements with the specified class.
 * This function works with elements that use a CSS variable for their background color.
 * It retrieves the current color, darkens it by a specified percentage, and applies
 * the darkened color on hover.
 *
 * @param {string} className - The CSS class name of the elements to target.
 * @param {string} cssVar - The CSS variable name for the background color (e.g., '--buttons_color_2nd').
 * @param {number} percent - The percentage by which to darken the color (e.g., 20 for 20% darker).
 *
 * ### Usage
 * ```javascript
 * applyDarkenedColorOnHoverWithCSSVar('my-button-class', '--buttons_color_2nd', 20);
 * ```
 *
 * ### How it Works
 * - The function retrieves the value of the CSS variable using `getComputedStyle`.
 * - It then converts the RGB color (if needed) to hexadecimal.
 * - The color is darkened using the `darkenColor` function and applied on hover.
 *
 * Note: The function assumes the CSS variable is set and the color is in a valid format.
 */
export function applyDarkenedColorOnHoverWithCSSVar(className, cssVar, percent) {
    const elements = document.querySelectorAll(`.${className}`);

    elements.forEach(element => {
        const computedStyle = getComputedStyle(element);
        const originalColor = computedStyle.getPropertyValue(cssVar).trim();

        // Convert RGB to Hex if necessary
        const hexColor = originalColor.startsWith('rgb') ? rgbToHex(originalColor) : originalColor;

        // Darken the color
        const darkenedColor = darkenColor(hexColor, percent);

        // Apply darkened color on hover
        element.addEventListener('mouseover', () => {
            element.style.backgroundColor = darkenedColor;
        });

        // Revert back to the original color on mouseout
        element.addEventListener('mouseout', () => {
            element.style.backgroundColor = `var(${cssVar})`;
        });
    });
}


/**
 * Checks if the current window width is below or equal to a specified breakpoint, indicating a mobile device.
 *
 * @param {number} [breakpoint=767] - The maximum width (in pixels) to consider as mobile. Defaults to 767px.
 * @returns {boolean} True if the window width is below or equal to the breakpoint, false otherwise.
 */
export function isMobile(breakpoint = 767) {
    return window.innerWidth <= breakpoint;
}

/**
 * Capitalizes the first letter of a word and converts the rest to lowercase.
 *
 * @param {string} word - The word to be capitalized.
 * @returns {string} The word with the first letter capitalized and the remaining letters in lowercase.
 *
 * @example
 * // returns "Panda"
 * capitalize("panda");
 *
 * @example
 * // returns "Sato"
 * capitalize("SATO");
 */
export function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}