<template>
    <div id="partners" class="partners" ref="partners" style="padding-top: 0px;">
        <div class="container">
            <h1>{{ $t('partners.parceiros') }}</h1>
            <div class="container-content">
                <main>
                    <div class="partners--grid">
                        <div class="item" v-for="(item, index) in items" :key="index">
                            <div class="item--logo" :style="partnerLogo(index)"></div>
                            <div class="item--title">
                                    {{ item.name }}
                            </div>
                            <div class="item--description">
                                {{ item.description }}
                            </div>
                            <div class="item--actions">
                                <a class="item--action action--store" @click="goToStore(item.link)">{{ $t('partners.loja') }}</a>
                                <a class="item--action action--gallery" @click="goToGallery(item.galery)">{{ $t('partners.galeria') }}</a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import axios from "axios";
    export default {
        name: 'Support',
        computed: {
            ...mapGetters([
                'logged', 'token'
            ]),
        },
        data() {
            return {
                items: [
                ],
            };
        },
        methods: {
            partnerLogo(index) {
                const item = this.items[index];
                return item.logo ? `background: url('${item.logo}') center center / cover;` : 'background-color: #c4c4c4;';
            },
            goToStore(url) {return url},
            goToGallery(url) {return url},
            listLoadEvents() {
                this.$app.wait(true);
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                axios.get(this.$url_api + 'api/getSponsors', { headers:headers }).then(response => this.listLoadResponse(response)).catch(error => this.listLoadError(error.response));
            },
            listLoadResponse(obj)
            {
                //console.log(obj);
                if (obj.data.data.length > 0)
                {
                    this.items = obj.data.data;
                }

                this.$app.wait(false);
            },
            listLoadError(obj)
            {
                obj;
                this.$app.wait(false);
            },
        },
        mounted() {
            this.listLoadEvents()
        }
    }
</script>