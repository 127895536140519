import Vue from "vue";
import Router from "vue-router";
import { store } from "@/store/vuex";

import Home from "@/components/Home";
import Iframe from "@/components/Iframe";
import Account from "@/components/Account";
import Age from "@/components/Age";
import Agenda from "@/components/Agenda";
import Friends from "@/components/Friends";
import FriendsInvited from "@/components/FriendsInvited";
import BlockedUsers from "@/components/BlockedUsers";
import Support from "@/components/Support";
import Map from "@/components/Map";
//import MapEvent from "@/components/MapEvent";
import Lives from "@/components/Lives";
// import Ticket from '@/components/Ticket'
import TicketPurchased from "@/components/TicketPurchased";
import Partners from "@/components/Partners";
import Ranking from "@/components/Ranking";
import Biblioteca from "@/components/Biblioteca";
import VipRoom from "@/components/VipRoom";
import PoliticaDePrivacidade from "@/components/PoliticaDePrivacidade";
import LandingPreEvento from "@/components/LandingPreEvento";
import Badges from "@/components/pages/Badges";
import PublicRanking from "@/components/PublicRanking";
import EmailConfirmation from "@/components/EmailConfirmation";
import TicketGate from "@/components/Modals/TicketGate";
import ListBadges from "@/components/pages/ListBadges";
import i18n from "@/i18n";
/* LMS */
// import LMS from '@/components/lms/Controller'
import LmsHome from "@/components/lms/LmsHome";
import LmsCourse from "@/components/lms/LmsCourse";
import LmsStudentProgress from "@/components/lms/LmsStudentProgress";
import LmsCheckoutSuccess from "@/components/lms/LmsCheckoutSuccess.vue";
import LmsLibrary from "@/components/lms/LmsLibrary.vue";
import LmsMyJourney from "@/components/lms/LmsMyJourney.vue";
import LmsFriends from "@/components/lms/LmsFriends.vue";
import LmsNotifications from "@/components/lms/LmsNotifications.vue";
import LmsCourseSection from "@/components/lms/LmsCourseSection.vue";
import LmsLessonContent from "@/components/lms/LmsLessonContent.vue";
import LmsCheckout from "@/components/lms/LmsCheckout.vue";
import LmsCertificates from "@/components/lms/LmsCertificates.vue";
import LmsStudentClasses from "@/components/lms/LmsStudentClasses.vue";
import LmsPayments from "@/components/lms/LmsPayments.vue";
import LmsCheckoutAddToCart from "@/components/lms/LmsCheckoutAddToCart.vue";
import LmsFeedSocial from "@/components/lms/feed/LmsFeedSocial.vue";
import LmsMyFeedSocial from "@/components/lms/feed/LmsMyFeedSocial.vue";
import LmsStudentBook from "@/components/lms/LmsStudentBook.vue";
import LmsErrorScreen from "@/components/lms/LmsErrorScreen.vue";
import Whiteboard from "@/components/lms/components/LmsStudentBookComponents/Whiteboard.vue";
import WhiteboardCreateByTemplate
    from "@/components/lms/components/LmsStudentBookComponents/WhiteboardCreateByTemplate.vue";

import Checkout from "@/components/vt/Checkout";
import Payments from "@/components/vt/Payments";
import Campaign from "@/components/Campaign.vue";
import CampaignOperator from "@/components/CampaignOperator.vue";

Vue.use(Router);

// const prefix = "RIR -";

// Array com rotas abertas para iterar

const routes = [
    {
        name: "Home",
        path: "/",
        props: (route) => ({award: route.query.award}),
        component: Home,
        meta: {
            title: `Home`, //${prefix}
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "CampaignOperator",
        path: "/campaign/operator",
        component: CampaignOperator,
        props: true,
        meta: {
            title: `Operador de Campanha`,
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "Campaign",
        path: "/campaign/:campaign?",
        component: Campaign,
        props: true,
        meta: {
            title: `Campanha`,
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "Login",
        path: "/login",
        props: route => ({award: route.query.award}),
        component: Home,
        meta: {
            title: `Login`, //${prefix}
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "RememberPassword",
        path: "/requisitar-senha",
        props: route => ({award: route.query.award}),
        component: Home,
        meta: {
            title: i18n.t("login.titleEsqueciSenha"),
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "ResetPassword",
        path: i18n.t('resetpassword.url'),
        props: route => ({award: route.query.award}),
        component: Home,
        meta: {
            title: i18n.t("resetpassword.recadastrarSenhaTitle"),
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "Signup",
        path: "/cadastro",
        props: route => ({award: route.query.award}),
        component: Home,
        meta: {
            title: `Cadastro`,
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        }, //${prefix}
    },
    {
        name: "EmailConfirmation",
        path: "/confirmacao-de-email",
        component: Map,
        meta: {
            title: i18n.t("app.confirmeSeuEmail"),
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "EmailConfirmationCode",
        path: "/confirmacao-de-email/:codeNumber?",
        props: true,
        component: EmailConfirmation,
        meta: {
            title: i18n.t("app.confirmeSeuEmail"),
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "Age",
        path: "/confirme-sua-idade",
        component: Age,
        meta: {
            title: `Confirme sua idade`, //${prefix}
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: false,
        },
    },
    {
        name: "Account",
        path: "/minha-conta",
        component: Account,
        meta: {
            title: `Minha conta`, //${prefix}
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "Profile-old",
        path: "/meu-perfil",
    //    redirect: "/profile",
        redirect: {name: "Profile"},
    },
    {
        name: "Profile",
        path: "/profile/:userId?",
        component: ()=>import("@/components/lms/LmsUserProfile.vue"),
        meta: {
            title: i18n.t("profile.meuPerfil"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "Friends",
        path: "/amigos",
        component: Friends,
        meta: {
            title: i18n.t("friends.meusAmigos"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "FriendsInvited",
        path: "/usuarios-convidados",
        component: FriendsInvited,
        meta: {
            title: i18n.t("friends.usuariosConvidados"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "BlockedUsers",
        path: "/usuarios-bloqueados",
        component: BlockedUsers,
        meta: {
            title: i18n.t("blockedusers.usuriosBloqueados"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "Support",
        path: "/suporte",
        component: Support,
        meta: {
            title: i18n.t("support.suporte"),
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "Partners",
        path: "/parceiros",
        component: Partners,
        meta: {
            title: `Parceiros`, //${prefix}
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "Agenda",
        path: "/agenda",
        component: Agenda,
        meta: {
            title: `Agenda`, //${prefix}
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "VipRoom",
        path: "/administrar-sala-vip",
        component: VipRoom,
        meta: {
            title: `Administrar sala VIP`, //${prefix}
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "Map",
        path: "/map",
        component: Map,
        meta: {
            title: i18n.t("app.mapa"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },

    // /* LMS */
    // {
    //   name: "LMS",
    //   path: "/lms",
    //   props: true,
    //   component: LMS,
    //   meta: {
    //     //title: `VT`,//${prefix}
    //     requiresLogin: true,
    //     requiresEmail: true,
    //     requiresAge: true,
    //   },
    // },
    //Badges
    {
        name: "Badges",
        path: "/badges",
        component: Badges,
        meta: {
            title: `Badges`, //${prefix}
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "Biblioteca",
        path: "/biblioteca",
        component: Biblioteca,
        meta: {
            title: `Biblioteca`,
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        }, //${prefix}
    },
    {
        name: "TicketNew",
        path: "/ingressos",
        component: ()=>import("@/components/lms/LmsUserProfile.vue"),
        meta: {
            title: i18n.t("ticket.ingressos"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
            tab: "mytickets",
        },
    },
    {
        name: "TicketPurchased",
        path: "/meus-ingressos",
        component: TicketPurchased,
        meta: {
            title: i18n.t("ticketpurchased.meusIngressos"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "Ranking",
        path: "/ranking",
        component: Ranking,
        meta: {
            title: `Ranking`, //${prefix}
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "PublicRanking",
        path: "/public_ranking",
        component: PublicRanking,
        meta: {
            title: i18n.t("ranking.publicRanking"),
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "TermosPage",
        path: "/termos/:txt",
        component: Home,
        meta: {
            title: i18n.t("signup.termosECondicoesTitle"),
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "PoliticaPage",
        path: "/politica",
        component: PoliticaDePrivacidade,
        meta: {
            title: i18n.t("app.politica"),
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "LandingPreEventoPage",
        path: "/landing",
        component: LandingPreEvento,
        meta: {
            title: `Landing`, //${prefix}
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "TicketGate",
        path: "/bilheteria",
        component: TicketGate,
        meta: {
            title: i18n.t("profile.Bilheteria"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "ConquistarBadges",
        path: "/conquistar-badges",
        component: ListBadges,
        meta: {
            title: i18n.t("badges.conquistarBadge"),
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    {
        name: "Lives",
        path: "/lives",
        component: Lives,
        meta: {
            title: `Lives`,
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: false
        }
    },

    // LMS EVENTO (LMS)
    {
        name: "LmsHome",
        path: "/lms/:lmsSlug",
        props: true,
        component: LmsHome,
        meta: {
            title: i18n.t("lms.home.title"),
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "LmsFeedSocial",
        path: "/lms/:lmsSlug/feed",
        component: LmsFeedSocial,
        props: true,
        meta: {
            title: 'Feed Social',
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false
        }
    },
    {
        name: 'LmsErrorScreen',
        path: '/lms/:lmsSlug/error',
        component: LmsErrorScreen,
        props: true,
        meta: {
            title: 'Erro!',
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false
        }
    },
    {
        name: 'LmsCommunityFeed',
        path: '/lms/:lmsSlug/feed/:communitySlug',
        component: LmsFeedSocial,
        props: true,
        meta: {
            title: 'Feed de Comunidade',
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "LmsMyFeedSocial",
        path: "/lms/:lmsSlug/myFeed",
        component: LmsMyFeedSocial,
        props: true,
        meta: {
            title: 'Minhas Publicações',
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "LmsUserProfile",
        path: "/lms/:lmsSlug/profile/:userId?",
        component: ()=>import("@/components/lms/LmsUserProfile.vue"),
        props: true,
        meta: {
            title: i18n.t("lms.profile.title"),
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    // Essa rota precisa ficar acima da rota LMSCourses por conta do path :courseSlug
    {
        name: "LmsStudentProgress",
        path: "/lms/:lmsSlug/studentprogress",
        props: true,
        component: LmsStudentProgress,
        meta: {
            title: i18n.t("lms.studentprogress.title"),
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        }
    },
    {
        name: "LmsCheckoutSuccess",
        path: "/lms/:lmsSlug/checkout/success",
        props: true,
        component: LmsCheckoutSuccess,
        meta: {
            title: i18n.t('lms.checkout.success_title'),
            requiresLogin: true
        }
    },
    {
        name: "LmsCertificates",
        path: "/lms/:lmsSlug/certificates",
        props: true,
        component: LmsCertificates,
        meta: {
            title: i18n.t("lms.certificates.title"),
            requiresLogin: true,
        },
    },
    {
        name: "LmsClasses",
        path: "/lms/:lmsSlug/userClasses",
        props: true,
        component: LmsStudentClasses,
        meta: {
            title: i18n.t("lms.classes.title"),
            requiresLogin: true,
        },
    },
    {
        name: "LmsLibrary",
        path: "/lms/:lmsSlug/library",
        props: true,
        component: LmsLibrary,
        meta: {
            title: i18n.t("lms.library.title"),
            requiresLogin: true,
        },
    },
    {
        name: "LmsMyJourney",
        path: "/lms/:lmsSlug/myJourney",
        props: true,
        component: LmsMyJourney,
        meta: {
            title: "Minha Jornada",
            requiresLogin: true,
        },
    },
    {
        name: "LmsFriends",
        path: "/lms/:lmsSlug/myFriends",
        props: true,
        component: LmsFriends,
        meta: {
            title: "Amigos",
            requiresLogin: true,
        },
    },
    {
        name: "LmsNotifications",
        path: "/lms/:lmsSlug/notifications",
        props: true,
        component: LmsNotifications,
        meta: {
            title: i18n.t("lms.notifications.title"),
            requiresLogin: true,
        },
    },
    {
        name: "LmsCheckout",
        path: "/lms/:lmsSlug/checkout",
        props: true,
        component: LmsCheckout,
        meta: {
            title: i18n.t("lms.checkout.title"),
            requiresLogin: true,
        },
    },
    {
        name: "LmsCheckoutAddToCart",
        path: "/lms/:lmsSlug/checkout/add-to-cart",
        props: true,
        component: LmsCheckoutAddToCart,
        meta: {
            title: i18n.t("lms.checkout.add_to_cart_title"),
            requiresLogin: true,
        },
    },
    {
        name: "LmsPayments",
        path: "/lms/:lmsSlug/payments",
        props: true,
        component: LmsPayments,
        meta: {
            title: i18n.t("lms.payments.title"),
            requiresLogin: true,
        },
    },
    {
        name: "LmsStudentBook",
        path: "/lms/:lmsSlug/student_book",
        props: true,
        component: LmsStudentBook,
        meta: {
            title: i18n.t('lms.student_book.title'),
            requiresLogin: true
        },
    },
    {
        name: "WhiteboardCreateByTemplate",
        path: "/lms/:lmsSlug/whiteboard/template/create",
        props: true,
        component: WhiteboardCreateByTemplate,
        meta: {
            title: i18n.t('lms.student_book.whiteboard_create_template'),
            requiresLogin: true
        },
    },
    {
        name: "Whiteboard",
        path: "/lms/:lmsSlug/whiteboard",
        props: true,
        component: Whiteboard,
        meta: {
            title: i18n.t('lms.student_book.title'),
            requiresLogin: true
        },
    },
    // LMS SESSOES (cursos)
    {
        name: "LMSCourses",
        path: "/lms/:lmsSlug/:courseSlug",
        props: true,
        component: LmsCourse,
        meta: {
            title: i18n.t("lms.course.title"),
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "LMSCourseSection",
        path: "/lms/:lmsSlug/:courseSlug/:sectionSlug",
        props: true,
        component: LmsCourseSection,
        meta: {
            title: i18n.t("lms.section.title"),
            requiresLogin: true,
        },
    },
    {
        name: "LmsLessonContent",
        path: "/lms/:lmsSlug/:courseSlug/:sectionSlug/:lessonSlug",
        props: true,
        component: LmsLessonContent,
        meta: {
            title: i18n.t("lms.content.title"),
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },


    },
    {
        name: "Checkout",
        path: "/checkout",
        // props: true,
        component: Checkout,
        meta: {
            title: 'Checkout',
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "Payments",
        path: "/payments",
        // props: true,
        component: Payments,
        meta: {
            title: 'Pagamentos',
            requiresLogin: true,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "EventMap",
        path: "/:eventName",
        props: true,
        component: Home,
        meta: {
            title: `Home`, //${prefix}
            requiresLogin: false,
            requiresEmail: false,
            requiresAge: false,
        },
    },
    {
        name: "Iframe",
        path: "/:eventName/:sessionName",
        props: true,
        component: Iframe,
        meta: {
            //title: `VT`,//${prefix}
            requiresLogin: true,
            requiresEmail: true,
            requiresAge: true,
        },
    },
    //   {
    //     name: "Package",
    //     path: "/pacotes",
    //     component: Package,
    //     meta: {
    //       title: `Pacotes`,//${prefix}
    //       requiresLogin: true,
    //       requiresEmail: false,
    //       requiresAge: false,
    //     },
    //   },
];


const router = new Router({routes});
router.beforeEach((to, from, next) => {
    let uri_qrcode = to.fullPath.split('?')[1];
    let params_qrcode = new URLSearchParams(uri_qrcode);
    if (params_qrcode.get('award')) {
        store.state.reward_slug = params_qrcode.get('award')
    }
    if(to.name === 'Campaign' || to.name === 'CampaignOperator') {
        next();
    } else if (["PoliticaPage", "Support", "TermosPage", "EventMap", "PublicRanking"].includes(to.name)) {
        next();
    } else if (store.state.started) {
        if (to.name === "Iframe" && !store.state.logged && !store.state.isGuest) {
            next();
        } else if (to.matched.some((record) => record.meta.requiresLogin) && !store.state.logged && !store.state.isGuest) {
            localStorage.setItem('redirectPath', to.fullPath);
            next({name: "Login"});
        } else if (to.matched.some((record) => record.meta.requiresEmail) && !store.state.email_verified && store.state.name != "") {
            if ((to.name != "EmailConfirmation") && (to.name != "EmailConfirmationCode")) {
                next({name: "EmailConfirmation"});
            }
        } else if (to.matched.some((record) => record.meta.requiresAge) && !store.state.age_verified && store.state.name != "") {
            if (to.name != "Age") {
                next({name: "Age"});
            }
        } else if (to.matched.some((record) => record.meta.requiresLogin) == false && (store.state.logged || store.state.isGuest)) {
            next({name: "Map"});
        } else if (to.fullPath.includes("/lms/")) {
            router.app.$store.dispatch("LmsCart/getCart");
            router.app.$store.dispatch("LMS/updateRouteHeader", to);
            next();
        } else {
            next();
        }
    } else {
        next();
    }
});

// After each navigation, send the URL change event
router.afterEach((to, from) => {
    const userId = JSON.parse(localStorage.getItem('user')).id;  // Adjust this according to where your user ID is stored
    const title = to.params.sessionName || to.meta.title || to.name || 'Non-shareable';  // You can also get this from `document.title`
    const url = to.fullPath;
    const url_api = localStorage.getItem('url_api');
    let token = JSON.parse(localStorage.getItem('user')).token;

    const data = {
        url: url,
        title: title,
        userId: userId
    };



    // fetch(`${url_api}api/trackUser`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${token}`  // Adiciona o token como Bearer no cabeçalho
    //     },
    //     body: JSON.stringify(data)  // Converte o objeto data para JSON
    // }).then(response => {
    //     console.log('Navigation tracked successfully:', response);
    // }).catch(error => {
    //     console.error('Error tracking navigation:', error);
    // });

});

export default router;
