import axios from "axios";

export default {
    getMinigamesRanking({ rootState }, payload) {


        let endpoint = this._vm.$url_api + 'api/minigames/ranking';

        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: payload
                })
                .then(response => {

                    resolve(response.data.data);
                })
                .catch(error => {
                    console.error(error.response.data);
                    reject(error.response.data);
                })
                .finally(() =>{

                });
        });
    },
    getMinigamesPoints({ rootState, commit }, payload) {

        let endpoint = this._vm.$url_api + 'api/minigames/user-total-score';

        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.post(
                endpoint,
                payload, { headers: headers })
                .then(response => {
                    resolve(response.data.data);
                    commit("updateMinigameScore", response.data.data.score);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });

        })



    }

};