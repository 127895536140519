<template>
    <div>
        <div class="g_id_signin" data-type="standard" :data-locale="$i18n.locale"></div>
    </div>
</template>
<script>
        export default {
        name:"LoginGoogle",
        data() {
            return {}
        },
        props: ['origin'],
        methods: {
            parseJwt(token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            },
            onSignIn(googleUser) {
                if(googleUser && googleUser.credential) {
                    const profile = this.parseJwt(googleUser.credential);
                    let data = { type:'google', origin: this.origin, id:profile.sub, email:profile.email, name:profile.name };
                    this.$parent.loginSocial(data);
                }
            },
            loadGoogleSDK(d, s, id) {
                this.$app.decryptUnity(
                    this.$app.keyUnityConf,
                    this.$app.switch.google_conf.i,
                    this.$app.switch.google_conf.c
                ).then(objDec => {
                    window.onSignIn = this.onSignIn;
                    
                    let js = d.getElementsByTagName(s)[0];

                    const div = d.createElement('div');
                    div.id = 'g_id_onload';
                    div.setAttribute('data-client_id', objDec);
                    div.setAttribute('data-callback', 'onSignIn');
                    d.head.appendChild(div);

                    js = d.createElement(s);
                    js.id = id;
                    js.src = `https://accounts.google.com/gsi/client?h1=${this.$i18n.locale}`;
                    js.async = true;
                    js.defer = true;
                    d.head.appendChild(js);
                });
            }
        },
        mounted() {
            this.loadGoogleSDK(document, "script", "google-jssdk");
        }
    }
</script>

<style lang="scss">
    .g_id_signin {

        a, picture {
            display: block;
        }
        picture {
            font-size: 0;
        }
        span{
            display: none;
        }
        div[role="button"]{
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            padding: 0;
            border-radius: 10px;
            position: relative;
            border: 1px solid #dadce0;
            &:hover{
              box-shadow: 0 0 5px 0 rgba(0,0,0,.8);
            }
        }
        svg{
            position: absolute;
            top: 9px;
            left: 9px;
            width: 30px;
            height: 30px;
        }
    }
</style>
