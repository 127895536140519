export default {
    setOpenedTranscription({commit}, payload) {
        commit('setOpenedTranscription', payload);
    },
    setMessagesChat({commit}, payload) {
        commit('setMessagesChat', payload);
    },
    setLoader({commit}, payload) {
        commit('setLoader', payload);
    }
};