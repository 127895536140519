<template>
    <div class="info_wrap s-overflow-wrap">
        <h2 class="so-exclude">
{{ current_step.name }}
</h2>
        <div class="so-overflow description">
            {{ current_step.description }}<br>
            <iframe :src="current_step.action_value" frameborder="0" />
        </div>
        <div v-if="!is_fullscreen" class="options">
            <button type="button" @click="userProgress()">
{{ $t('quest.understood') }}
</button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "FileStep",
        computed: {
            current_step() {
                return this.$parent.current_step;
            },
            is_fullscreen() {
                return this.$parent.is_fullscreen;
            }
        },
        methods: {
            userProgress() {
                this.$parent.userProgress();
            }
        }
    }
</script>