import axios from "axios";

export default {
    update_lms: (state, data) => {
        state.commit('update_lms', data);
    },

    disableLms(state) {
        return new Promise((resolve) => {
            state.commit('disable_lms');
            resolve()
        });
    },

    enableIsLMS(state) {
        return new Promise((resolve) => {
            state.commit('enable_lms');
            resolve()
        });
    },

    checkEtalentResult({rootState}, payload) {
        let endpoint;
        if(payload.user_id) {
            endpoint = this._vm.$url_api+'api/etalent/results/'+payload.user_id;
        } else {
            endpoint = this._vm.$url_api+'api/etalent/results';
        }

        return new Promise((resolve, reject) => {
            let params = {
                "lms_id" : payload.lms_id,
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    registerEtalentUser({rootState}, payload) {
        let endpoint = this._vm.$url_api+'api/etalent/register';

        return new Promise((resolve, reject) => {
            let params = {
                "lms_id" : payload.lms_id,
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    favoriteItem({rootState}, payload){
        let endpoint = this._vm.$url_api + 'api/user/favorites/toggle';

        return new Promise((resolve, reject) => {
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization: `Bearer ${rootState.token}`
            }
      
            const requestData = {
              object: payload.object,
              object_id: payload.object_id
            };
      
            axios.post(endpoint, requestData, {headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        });
    },

    getListLMS({commit, rootState}, payload) {
        let endpoint = this._vm.$url_api + 'api/lms/welcome';

        return new Promise((resolve, reject) => {

            let params = {
                "slug" : payload.slug,
                // "id": 1 // send slug or id
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: payload.language, // this.$i18n.locale,
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    commit("update_lms", response.data.data.lms);
                    commit("update_slideshow", response.data.data.slideshow);
                    commit("update_communities", response.data.data.communities);
                    commit("update_continue_journey", response.data.data.continue_journey);
                    resolve(response.data.data);
                })
                .catch(error => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                });


        });
    },

    

    getUserProgress({rootState}, payload){
        let endpoint = this._vm.$url_api + 'api/lms/user/progress';

        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: payload
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                });
        });
    },

    getUserCertificates({rootState}, payload) {
        let endpoint = this._vm.$url_api + 'api/lms/user/certificates';
        return new Promise((resolve, reject) => {
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };
            axios.get(endpoint, {headers: headers, params: payload}).then((res) => {
                resolve(res.data.data)
            }).catch((error) => {
                console.log(error.response.data.data)
                reject(error.response.data.data)
            })
        })
    },

    searchCourses({rootState}, payload) {
        let endpoint = this._vm.$url_api + 'api/lms/courses/search';

        return new Promise((resolve, reject) => {

            let params = {
                "slug"          : payload.slug,
                "search"        : payload.search ?? "",
                "page"          : payload.page,
                "category_id"   : payload.category_id
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                });
        });
    },
    getCourse({commit, rootState}, payload) {
        let url = this._vm.$url_api + `api/lms/welcome/course`
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {id: payload.id}}).then((res) => {
                commit('update_course', res.data.data.course)
                resolve(res.data.data.course);
            }).catch((error) => {
                console.log(error.response.data.data ?? error);
                reject(error.response.data.data ?? error);
            })
        })
    },
    getSection({commit, rootState}, payload) {
        let url = this._vm.$url_api + `api/lms/welcome/section`
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {id: payload.id}}).then((res) => {
                commit('update_section', res.data.data.section)
                resolve()
            }).catch((error) => {
                console.log(error.response.data.data)
                reject(error.response.data.data)
            })
        })
    },
    getLesson({commit, rootState}, payload) {
        let url = this._vm.$url_api + `api/lms/welcome/lesson`
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {id: payload.id}}).then((res) => {
                commit('update_lesson', res.data.data.lesson)
                resolve()
            }).catch((error) => {
                console.log(error.response.data.data);
                reject(error.response.data.data);
            })
        })
    },
    getUserNotes({commit, rootState}, payload) {
        let url = this._vm.$url_api + `api/lms/user/lesson/notes`
        let headers = { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params:{lesson_id: payload.lesson_id}}).then((res) => {
                commit('update_user_notes', res.data.data.notes)
                resolve()
            }).catch((error) => {
                console.log(error.response.data.data)
                reject(error.response.data.data)
            })
        })
    },
    getUserCourses({commit, rootState}, payload) {
        let url = this._vm.$url_api + `api/lms/user/courses`
        let headers = { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {slug: payload.slug}}).then((res) => {
                commit('update_user_courses', {courses: res.data.data.courses, classes: res.data.data.user_classes})
                resolve()
            }).catch((error) => {
                console.log(error.response.data.data)
                reject(error.response.data.data)
            })
        })
    },
    getClassMembers({rootState}, payload) {
        let url = this._vm.$url_api + `api/lms/classes/students`
        let headers = { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {id: payload.id, search: payload.search, page: payload.page}}).then((res) => {
                resolve(res.data.data)
            }).catch((error) => {
                console.log(error.response)
                reject(error)
            })
        })
    },
    createUserNote({commit, rootState}, payload) {
        let url = this._vm.$url_api + `api/lms/user/lesson/notes/create`
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers:headers}).then((res) => {
                commit('update_user_notes_add', res.data.data.notes)
                console.log('res notes =>', res.data.data.notes)
                resolve()
            }).catch((error) => {
                console.log(error.response.data.data)
                reject(error.response.data.data)
            })
        })
    },
    ordenateUserNotes({rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/lesson/notes/ordenate'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then(() => {
                resolve()
            }).catch((error) => {
                console.log(error.response)
                reject(error)
            })
        })
    },
    updateNote({commit, rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/lesson/notes/update'
        let headers = { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.put(url, payload, {headers:headers}).then((res) => {
                commit('update_user_notes_note_updated', res.data.data.notes)
                resolve()
            }).catch((error) => {
                console.log(error.response)
                reject(error)
            })
        })
    },
    deleteNote({rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/lesson/notes/delete'
        let headers = { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.delete(url, {headers: headers, params: {note_id: payload.note_id}}).then(() => {
                resolve()
            }).catch((error) => {
                console.log(error.message)
                reject(error)
            })
        })
    },
    finishLesson({rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/lesson/finished'
        let headers = { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res.data)
            }).catch((error) => {
                console.log(error.response)
                reject(error)
            })
        })
    },

    update_course_selected({ commit }, payload){
        commit("update_course_selected", payload)
    },
    downloadCertificate({rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/user/certificate/download'
        let headers = { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }
        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res)
            }).catch((error) => {
                console.log(error.response)
                reject(error)
            })
        })
    },
    toggleLoading({commit}, loading) {
        commit("toggleLoading", loading);
    },

    getUserInfo({commit, rootState}) {
        let endpoint = this._vm.$url_api + 'api/welcome';

        return new Promise((resolve, reject) => {

            let params = {
                eventMap: localStorage.getItem("eventmap")
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    commit("update_welcome_user", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                });


        });
    },

    getUserNotifications({commit, rootState}, payload) {
        let endpoint = this._vm.$url_api + 'api/user/notifications/list';

        return new Promise((resolve, reject) => {

            let params = {
                page:       payload.page ?? 1,
                per_page:   payload.per_page ?? 5
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    commit("update_notifications_list", response.data.data.notifications.data);
                    resolve(response.data.data.notifications);
                })
                .catch(error => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                });


        });
    },

    updateNotificationAsViewed({ rootState }, payload){

        console.log("updateNotificationAsReed");
        let endpoint = this._vm.$url_api + 'api/user/notifications/viewed';


        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            let form_data = new FormData();
            form_data.append('notification', payload.id);

            axios.post(
                endpoint,
                form_data,
                { headers:headers })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject();
                });

        });

    },

    updateNotificationAsReed({ rootState }, payload){

        let endpoint = this._vm.$url_api + 'api/user/notifications/reed';

        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            let form_data = new FormData();
            form_data.append('notification', payload.id);

            axios.post(
                endpoint,
                form_data,
                { headers:headers })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject();
                });

        });
    },

    updateRouteHeader({commit}, route) {
        commit("update_route_header", route);
    },
    getApiUrl() {
        // No getter não tem _vm, por isso esta nas actions
        return this._vm.$url_api;
    }
};


