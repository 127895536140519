<template>
    <div>
        <div id="dimmerTermos" @click="openModal(false)" class="ui modals page dimmer">
            <div id="termosModal" ref="termosModal" @click.stop class="ui translucent suimodal" :class="{small:!checkMobile,overlay:checkMobile,fullscreen:checkMobile}">
                <i class="close icon" />
                <!-- <div class="header terms-title" v-html="title != '' ? title : $i18n.t('signup.termosECondicoes')">
                </div> -->
                <div class="scrolling content">
                    <div v-if="iframe_description" class="video-container">
                        <iframe :src="url_description" frameborder="0" style="width:100%;height:100%;" />
                    </div>
                    <div v-else v-html="terms" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import axios from "axios";

export default {
    name: 'Termos',
    computed: {
        ...mapGetters([
            'logged', 'token','switch','checkMobile'
        ]),
    },
    data() {
        return {
            title:"",
			terms: "",
            iframe_description:false,
            url_description:"",
            started: false,
		}
    },
    methods: {
        backToRegister()
        {
            if (!this.started) {
                this.started = true;
                try {
                    let lastRoute = this.$route.name;
                    this.$router.go(-1);
                    console.log(this.$route.name, lastRoute)
                    if(this.$route.name == lastRoute){
                        this.$router.push({name: 'Home'});
                    }
                } catch (e) {
                    this.$router.push({name: 'Home'});
                }
            }
        },
		getTerms(){
            axios.get(this.$url_api + 'api/getTerms', {headers: {'language': this.$i18n.locale,},
                params:{event_name: this.switch.Nome_Aba??"",regulamento:this.$route.params.txt}}).then(response => this.getTermsResponse(response));
		},
        getTermsResponse(obj)
        {
            this.title = obj.data.data.title;
            this.terms = obj.data.data.description;
            this.iframe_description = obj.data.data.iframe_description;
            this.url_description = obj.data.data.url_description;
        },
        async openModal(open){
            let root = this;
            /* global $ */
            if (open){
                this.started = false;
                this.title= "";
                this.terms = "";
                this.iframe_description = false;
                this.url_description = "";
                $('#dimmerTermos').dimmer('show');
                $('#termosModal').suimodal({
                    dimmerSettings: { opacity: 0 },
                    // observeChanges: true,
                    context: '#dimmerTermos',
                    onShow(){
                    },
                    onHidden(){
                        root.backToRegister();
                    },
                    onVisible(){
                        root.$app.analytics('visit_terms');
                        root.getTerms();
                    }
                }).suimodal('show');
            } else {
                $('#termosModal').suimodal('hide');
            }
        },
    },
    mounted() {
    },
	updated() {
    },
    beforeDestroy() {
        this.$app.regulamento=false;
    },
    watch: {
        $route(to, from) {
            if (from.name== 'TermosPage' && to.name == 'TermosPage' && to.params.txt != from.params.txt) {
                this.openModal(true);
                this.getTerms();
            }
        }
    }
}
</script>
<style>
.terms-title, .terms-title *
{
    font-size: 2rem !important;
}
</style>
<style scoped lang="scss">
#termosModal.suimodal{
    padding: 54px 44px;
    .icon.close{
        top: 44px;
        right: 44px;
        color: #fff;
    }
}
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>