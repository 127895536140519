<template>
    <div class="video-wrapper">
        <video class="content-video" @mouseover="showControls" :data-id="video_id" :id="'video_'+video_id" @mouseout="hideControlsTimeout" :ref="'video_'+video_id">
            
        </video>
        <div class="play_video" v-if="!isPlaying" @click="playVideo">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z"/></svg>
        </div>
        <div :id="'video-controls_'+video_id" @mouseover="showVideoControls = true" :ref="'video-controls_'+video_id" class="controls" v-show="showVideoControls || !isPlaying">
            <div class="progress" @click="selectPointInVideo">
                <div :id="'progress_'+video_id" :ref="'progress_'+video_id"></div>
            </div>
            <div class="control_buttons">
                <div class="control-left-side">
                    <button :id="'play_'+video_id" :ref="'play_'+video_id" type="button" v-if="!isPlaying" @click="playVideo">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                    </button>
                    <button :id="'stop_'+video_id" :ref="'stop_'+video_id" type="button" v-else @click="pauseVideo">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"/></svg>
                    </button>
                    <div class="audio_button" @click="muteAndUnmute" @mouseover="showVolume">
                        <button v-if="!isMuted && volumeTotal <= 1 && volumeTotal >= .4" :id="'mute_'+video_id" :ref="'mute_'+video_id" type="button" data-state="mute">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"/></svg>
                        </button>
                        <button v-else-if="!isMuted && volumeTotal > 0 && volumeTotal < .4" :id="'low_volume_'+video_id" :ref="'low_volume_'+video_id" type="button" data-state="low_volume">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM412.6 181.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5z"/></svg>
                        </button>
                        <button v-else-if="isMuted || volumeTotal == 0" :id="'unmute_'+video_id" :ref="'unmute_'+video_id" type="button" data-state="unmute">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"/></svg>
                        </button>
                    </div>
                    
                    <div class="volume_container" v-show="showVolumeBar" @mouseover="showVolume">
                        <div class="volume" @click="selectPointInAudio">
                            <div class="volume_bar" :id="'volume_'+video_id" :ref="'volume_'+video_id"></div>
                        </div>
                    </div>
                    <span>{{ currentVideoTime }} / {{totalVideoLength}}</span>
                </div>
                
                <div class="control-right-side">
                    <button v-if="!isIOS()" :id="'fs_'+video_id" :ref="'fs_'+video_id" type="button" data-state="go-fullscreen" @click="fullscreen">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"/></svg>
                    </button>
                </div>
            </div>

            <!-- <button :id="'volinc_'+video_id" :ref="'volinc_'+video_id" type="button" data-state="volup">Vol+</button>
            <button :id="'voldec_'+video_id" :ref="'voldec_'+video_id" type="button" data-state="voldown">Vol-</button> -->

        </div>
    </div>
</template>
<script>
    const emitter = require('tiny-emitter/instance');
    import Hls from 'hls.js' 
    export default {
        name: 'VideoPlayer',
        props: ['video_id', 'video_url'],
        data: () => ({
            totalVideoLength: '',
            currentVideoTime: '',
            videoControls: '',
            isPlaying: false,
            showVideoControls: true,
            showVolumeBar: false,
            volumeTotal: 1,
            isMuted: 0,
            timeOut: '',
            fullscreenLMS: false
        }),
        mounted() {
            let video = this.$refs['video_'+this.video_id];
            this.videoControls = this.$refs['video-controls_'+this.video_id];
            if (Hls.isSupported()) {
                let hls = new Hls({debug: true});
                hls.loadSource(this.video_url);
                hls.attachMedia(video);
                hls.on(Hls.Events.MEDIA_ATTACHED, function () {});
            }
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = this.video_url;
                video.addEventListener('canplay', function () {
                    // video.play();
                });
            }
            video.addEventListener("click", () => {
                if (video.paused || video.ended) {
                    this.playVideo()
                    
                } else {
                    this.pauseVideo()
                }
            })
            video.addEventListener('ended',() => {
                this.isPlaying = false
                let progressBar = this.$refs['progress_'+this.video_id]
                progressBar.style.width = '100%'
            })
            video.addEventListener('canplay', () => {
                this.totalVideoLength = this.getTotalVideoLength(video.duration)
                this.currentVideoTime = this.getTotalVideoLength(video.currentTime)
                
                setInterval(() => {
                    if(!video.paused) {
                        this.currentVideoTime = this.getTotalVideoLength(video.currentTime)
                        this.updateProgressBar(video.duration, video.currentTime)
                        this.isPlaying = true
                    } else {
                        this.isPlaying = false
                    }
                }, 10)
                if (video.currentTime == video.duration) {
                    this.currentVideoTime = this.getTotalVideoLength(0)
                }
            });

        },
        methods: {
            playVideo() {
                let video = this.$refs['video_'+this.video_id]
                if (video.currentTime == video.duration) {
                    this.currentVideoTime = this.getTotalVideoLength(0)
                }
                video.play()
                this.updateVolumeBar()
                this.isPlaying = true
                emitter.emit('play-video', this.video_id)
            },
            selectPointInVideo(event) {
                let totalBar = this.$refs['progress_'+this.video_id]
                totalBar.style.width = '100%'
                let totalBarPosition = Math.round(totalBar.getBoundingClientRect().x)
                console.log('totalBar width =>', totalBar.clientWidth)
                let barSize = ((event.clientX - totalBarPosition) / totalBar.clientWidth) * 100
                totalBar.style.width = barSize+'%'
                let video = this.$refs['video_'+this.video_id]
                let videoTime = (video.duration / 100) * barSize
                video.currentTime = videoTime
            },
            pauseVideo() {
                let video = this.$refs['video_'+this.video_id];
                video.pause();
                this.isPlaying = false;
            },
            getTotalVideoLength(track) {
                let totalSeconds = Math.round(track)
                let hoursLeft = Math.round(totalSeconds / 3600)
                let min = Math.round((totalSeconds - hoursLeft * 3600) / 60)
                let secondsLeft = totalSeconds - hoursLeft * 3600 - min * 60
                secondsLeft = Math.round(secondsLeft * 100) / 100
                if(secondsLeft < 0) {
                  min = min - 1
                  secondsLeft = 60 + secondsLeft;
                }
                let formattedTime = ''
                if (hoursLeft > 0) {
                    formattedTime += (hoursLeft < 10 ? "0"+hoursLeft : hoursLeft)
                    formattedTime += ":"+(min < 10 ? "0"+min : min)
                    formattedTime += ":"+(secondsLeft < 10 ? "0"+secondsLeft : secondsLeft)
                } else if (hoursLeft <= 0) {
                    formattedTime += (min < 10 ? "0"+min : min)
                    formattedTime += ":"+(secondsLeft < 10 ? "0"+secondsLeft : secondsLeft)
                }
                return formattedTime
            },
            updateProgressBar(videoDuration, currentVideoTime) {
                let bar = this.$refs['progress_'+this.video_id]
                let barSize = Math.round((currentVideoTime / videoDuration) * 100)
                bar.style.width = barSize+'%'
            },
            selectPointInAudio(event) {
                this.isMuted = false;
                let totalBar = this.$refs['volume_'+this.video_id]
                let video = this.$refs['video_'+this.video_id]
                totalBar.style.width = '100%';
                let totalBarPosition = Math.round(totalBar.getBoundingClientRect().x)
                let barSize = ((event.clientX - totalBarPosition) / totalBar.clientWidth) * 100
                totalBar.style.width = barSize+'%'
                let videoVolume = (1 / 100) * barSize
                this.volumeTotal = videoVolume
                video.volume = videoVolume
            },
            muteAndUnmute() {
                this.isMuted = !this.isMuted
                let video = this.$refs['video_'+this.video_id]
                video.muted = this.isMuted
                let volume = this.$refs['volume_'+this.video_id]
                if (this.isMuted) {
                    volume.style.width = '0%'
                } else {
                    this.updateVolumeBar()
                }
            },
            hideControlsTimeout() {
                this.timeOut = setTimeout(() => {
                    this.showVideoControls = false
                    this.showVolumeBar = false
                    
                }, 2000)
            },
            showControls() {
                clearTimeout(this.timeOut)
                this.showVideoControls = true
                if (this.showVolumeBar) {
                    this.updateVolumeBar()
                }
            },
            showVolume() {
                this.showVolumeBar = true
                this.updateVolumeBar()
            },
            updateVolumeBar() {
                let volume = this.$refs['volume_'+this.video_id]
                let video = this.$refs['video_'+this.video_id]
                if (this.isMuted) {
                    volume.style.width = '0%'
                } else {
                    let bar = (video.volume / 1) * 100
                    volume.style.width = bar+'%'
                }
            },
            fullscreen() {
              // No iOS tanto fullScreenFunction como exitFullScreenFunction ficam undefined
                const elem = this.$refs['video_'+this.video_id];
                //on off full screen
                if (!document.fullscreenElement) {
                    const fullScreenFunction = elem.requestFullscreen || elem.webkitRequestFullscreen || elem.mozRequestFullScreen || elem.msRequestFullscreen;
                    if(fullScreenFunction) {
                        fullScreenFunction.call(elem).then(() => {
                            // elem.classList.add('full');
                        }).catch(err => {
                            console.log(err)
                        });
                    }
                } else {
                    const exitFullScreenFunction = document.exitFullscreen() || document.webkitExitFullscreen() || document.mozCancelFullScreen() || document.msExitFullscreen();
                    if (exitFullScreenFunction) {
                        exitFullScreenFunction.then(()=>{
                            // elem.classList.remove('full');
                        }).catch(err => {
                            console.log(err)
                        });
                    }
                }
            },
            isIOS() {
              return /iPhone|iPad|iPod/i.test(navigator.userAgent) && !window.MSStream;
            }
        }
    }
</script>
<style lang="scss" scoped>

    .video-wrapper {
        position: relative;
    }

    video {
        width: 100%;
        height: 500px;
        border: 1px solid #f6f6f6;
        border-radius: 20px;
        background-color: #000;
    }
    svg {
        fill: #f6f6f6;
    }
    .controls {
        width: 100%;
        margin-top: -67px;
        height: 50px;
        position: relative;
        display: block;
        border-radius: 20px;
        padding: 0 20px;
        padding-bottom: 10px;
        padding-top: 0;
    }
    .controls[data-state="hidden"] {
        display: none;
    }
    .controls[data-state="visible"] {
        display: block;
    }
    .controls > * {
        float: left;
        width: 3.90625%;
        height: 100%;
        margin-left: 0.1953125%;
        display: block;
    }
    .controls > *:first-child {
        margin-left: 0;
    }
    
    .play_video {
        position: absolute;
        width: 200px;
        height: 200px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    .play_video svg {
        width: 100%;
        height: 100%;
        opacity: .7
    }

    .controls button {
        border: none;
        cursor: pointer;
        background: transparent;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .controls button:hover,
    .controls button:focus {
        opacity: 0.5;
    }

    .controls .progress {
        display: block;
        width: 100%;
        height: 15%;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 5px;
        background-color: #e9ecef;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .controls .progress div {
        width: 0%;
        height: 100%;
        border-radius: 5px;
        display: inline-block;
        background-color: var(--buttons_color);
    }

    .controls .volume_container {
        height: 25px;
        width: 80px;
        margin-top:5px;
        display: flex;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
    }

    .controls .volume_container .volume {
        height: 20%;
        width:90%;
        background-color: #e9ecef;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .controls .volume .volume_bar {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        display: inline-block;
        background-color: var(--buttons_color);
    }

    .control_buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .control-right-side {
        display: flex;
    }
    .control-left-side {
        display: flex;
        align-items: center;
    }
    .control-left-side span {
        margin-top: 6px;
        color: #f6f6f6;
    }
    .control-left-side .audio_button {
        width: 30px;
    }
    .controls progress::-moz-progress-bar {
        background-color: var(--buttons_color);
    }
    .controls progress::-webkit-progress-value {
        background-color: var(--buttons_color);
    }

    // MOBILE
    @media (max-width: 768px) {
      video {
        height: auto;
      }
      .play_video {
        position: absolute;
        //margin-left: 33.5%;
        width: 80px;
        height: 80px;
        //margin-top: -177px;
      }
      .controls {
        margin-top: -60px;
        padding-bottom: 5px;
      }
    }

</style>