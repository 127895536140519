import axios from "axios";

export default {

    toggleItemToCart({getters, dispatch}, payload){

        let cart = getters.getCart;

        let item = cart.filter(course => course.item.id === payload.id );

        if(item.length) {
            // remove
            return dispatch('removeItemFromCart', item[0]);
        } else {
            // add
            return dispatch('addItemToCart', payload);
        }

    },

    getCart({ state, rootState, commit, dispatch }) {

        let endpoint = this._vm.$url_api + `api/cart/list`;

        // Avoid two requests about cart at the same time
        if(state.processing){
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {

            commit("update_processing", true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers
                })
                .then(response => {
                    console.log('aqui juligo');
                    commit("update_cart", response.data.data.cart);
                    commit("update_cart_totals", response.data.data.cart_totals);
                    
                    dispatch('updateGateways', response.data.data);

                    commit("update_gateways_min_values", response.data.data.gateways_min_values);
                    resolve(response.data.data.cart);

                    
                })
                .catch(error => {
                    console.log(error.response.data);
                    reject(error.response.data.data);
                })
                .finally(() =>{
                    commit("update_processing", false);
                });
        });
    },

    addItemToCart({state, commit, rootState}, data){
        let endpoint = this._vm.$url_api + 'api/cart/add';

        let dataProcessing = data.processing ?? true;
        // Avoid two requests about cart at the same time
        if(state.processing && dataProcessing){
            return Promise.reject();
        }
        
        return new Promise((resolve, reject) => {

            commit("update_processing", true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            let payload = {
                "object": data.object,
                "object_id": data.id,
                "quantity": data.quantity
            }

            axios.post(
                endpoint,
                payload, { headers: headers })
                .then(response =>{
                    commit("update_cart", response.data.data.cart)
                    resolve(response);
                })
                .catch(error => {
                    console.log(error.response.data.errors || error.response.data.data);
                    reject(error.response.data.errors || error.response.data.data);
                })
                .finally(() =>{
                    commit("update_processing", false);
                });

        });

    },
    removeItemFromCart({state, commit, rootState, dispatch}, cartItem){
        let endpoint = this._vm.$url_api + `api/cart/remove/${cartItem.id}`;

        // Avoid two requests about cart at the same time
        if(state.processing){
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {

            commit("update_processing", true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.delete(
                endpoint,
                { headers: headers })
                .then(response =>{
                    commit("update_cart", response.data.data.cart);
                    commit("update_cart_totals", response.data.data.cart_totals);
                    commit("update_gateways_min_values", response.data.data.gateways_min_values);
                    dispatch('updateGateways', response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                })
                .finally(() =>{
                    commit("update_processing", false);
                });

        });

    },

    removeAllItemsFromCart({commit, rootState}){
        let endpoint = this._vm.$url_api + `api/cart/removeall`;

        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.delete(
                endpoint,
                { headers: headers })
                .then(response =>{
                    console.log('RESPONSE CART =>', response.data.data.cart);
                    console.log('RESPONSE CART TOTALS => ', response.data.data.cart_totals);
                    commit("update_cart", response.data.data.cart);
                    commit("update_cart_totals", response.data.data.cart_totals);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data);
                })

        });

    },

    cartCheckout({commit, state, rootState, dispatch}, payload){
        let endpoint = this._vm.$url_api + 'api/cart/checkout';

        // Avoid two requests about cart at the same time
        if(state.processing){
            return Promise.reject();
        }

        if(state.isVoucherValid){
            payload.voucher = state.voucher.code;
        }

        return new Promise((resolve, reject) => {

            commit("update_processing", true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.post(
                endpoint,
                payload,
                { headers: headers })
                .then(response =>{

                    if( response.data.data.order && (response.data.data.order.engine_status == 'DECLINED' ||  response.data.data.order.engine_status == 'CANCELED') ) {
                        reject(response.data.data.order.engine_status);
                    } else {
                        commit("update_voucher_valid", false);
                        commit("update_voucher", []);
                    }

                    commit("update_cart", response.data.data.cart)
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error.response.data.data);
                    reject(error.response.data.data.errors);
                })
                .finally(() =>{
                    commit("update_processing", false);
                    dispatch("getCart");
                });

        });
    },

    getPublicKey({ state, rootState, commit }) {

        let endpoint = this._vm.$url_api + 'api/package/publickey';

        // Avoid two requests about cart at the same time
        if(state.processing){
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {

            commit("update_processing", true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            let form_data = new FormData();

            axios.post(endpoint,
                form_data,
                {
                    headers:headers
                })
                .then(response => {
                    resolve(response);
                    commit("update_public_key", response.data.public_key);
                })
                .catch(error => {
                    console.error(error);
                    reject(error.response.data.data);
                })
                .finally(() =>{
                    commit("update_processing", false);
                });
        });
    },

    validateVoucher({commit, rootState, state}, voucher) {

        let endpoint = this._vm.$url_api + "api/cart/vouchers/validate";

        // Avoid two requests about cart at the same time
        if(state.processing){
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {

            commit("update_processing", true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            let payload = {
                "code": voucher
            }

            axios.get(endpoint,
                {
                    headers:headers,
                    params: payload
                })
                .then(response => {
                    commit("update_voucher", response.data.data.vouchers);
                    commit("update_voucher_valid", true);
                    resolve(response.data.data.vouchers);
                })
                .catch(error => {
                    console.error(error.response.data.data.voucher[0]);
                    commit("update_voucher_valid", false);
                    reject(error.response.data.data.voucher[0]);
                })
                .finally(() =>{
                    commit("update_processing", false);
                });
        });



    },

    emptyVoucher({ commit }) {
        commit("update_voucher", "");
        commit("update_voucher_valid", false);
    },

    updateGateways({commit}, cart){

        let gateways = [];

        let fiduciary = cart.cart.some( course => ((course.item.price > 0) || (course.item.promo === true && course.item.promo_price > 0)) && course.item.is_free === false);
        if(fiduciary && cart.gateways.includes("pagseguro")) {
            gateways.push("pagseguro");
        }

        let coins = cart.cart.some( course => ((course.item.coins > 0) || (course.item.promo === true && course.item.promo_coins > 0)) && course.item.is_free === false);
        if(coins && cart.gateways.includes("coins")) {
            gateways.push("coins");
        }

        let allFreeCourses = cart.cart.some(item => item.item.is_free === false);
        if(gateways.length === 0 && !allFreeCourses) {
            gateways.push("free");
        }
        
        commit("update_gateways", gateways);
    },

    getPaymentsHistory({commit, rootState}, payload) {

        let endpoint = this._vm.$url_api + "api/cart/orders";

        return new Promise((resolve, reject) => {

            commit("update_processing", true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: payload
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data.data);
                }).finally(() =>{
                    commit("update_processing", false);
                });
        });



    },

    downloadInvoice({commit, rootState}, payload) {
        let endpoint = this._vm.$url_api + "api/cart/orders/invoice/download";

        return new Promise((resolve, reject) => {

            commit;
            // commit("update_processing", true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.post(endpoint, payload,
                {
                    headers:headers,
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    // reject(error.response.data.data);
                    reject(error.response);
                });
        });
    },

};

