<template>
    <div id="orders">
        <div class="box" id="orders-container">
            <h1 v-text="$ml.get('compras.title')"></h1>

            <div v-if="loading" v-text="$ml.get('compras.loading')"></div>
            <div v-if="!loading && !list.length" v-text="$ml.get('compras.nenhuma_compra')"></div>

            <ul>
                <li v-for="l in list" :key="l.id">
                    <ul>
                        <li>{{ $ml.get('compras.order_id') }} <span>{{ l.orderId }}</span></li>
                        <li>{{ $ml.get('compras.total') }} <span>$ {{ l.total.toFixed(2).replace(".", ",") }}</span></li>
                        <li>{{ $ml.get('compras.status') }} <span>{{ l.status }}</span></li>
                    </ul>
                </li>
            </ul>

            <div id="orders-controller">
                <!--<router-link class="btn-1" to="/" ref="btn-close" v-text="$ml.get('compras.close')"></router-link>-->
                <a class="btn-1" href="#" ref="btn-close" v-on:click="$parent.purchases = false" v-text="$ml.get('compras.close')"></a>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "Compras",
        computed: {
            ...mapGetters([
                'token', 'logged'
            ])
        },
        data() {
            return {
                loading: false,
                list   : []
            }
        },
        methods: {
            ...mapActions([
                'update'
            ]),
            load() {
                this.loading = true;
                let form_data = new FormData();
                form_data.append('token', this.token);
                axios.post(this.$url_api + 'store/oracle/get_orders', form_data).then(response => this.loadResponse(response));
            },
            loadResponse(obj) {
                if (obj.data.errorCode)
                {
                    this.$parent.logOut(null);
                    alert(obj.data.message);
                }
                else
                {
                    this.list = obj.data.items;
                }
                this.loading = false;
            }
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>
    #orders
    {
        background-color: rgba(0, 0, 0, .6);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    #orders-container
    {
        display: block;
        margin: 150px  auto;
        max-height: 80%;
        max-width: 800px;
        overflow-y: auto;
    }
    h1
    {
        box-sizing: border-box;
        text-align: center;
        padding: 10px 0;
    }
    #orders-container > ul
    {
        box-sizing: border-box;
        padding: 20px 0;
    }
    #orders-container > ul > li
    {
        box-sizing: border-box;
        padding: 10px;
    }
    #orders-container > ul > li > ul > li
    {
        display: inline-block;
        width: calc(100% / 3);
    }
    #orders-container > ul > li > ul > li > span
    {
        color: #e55944;
        font-weight: bold;
    }

    @media screen and (max-width: 800px)
    {
        #orders-container
        {
            width: calc(100% - 40px);
        }
    }
    @media screen and (max-width: 500px)
    {
        #orders-container > ul > li > ul > li:nth-child(1), #orders-container > ul > li > ul > li:nth-child(2)
        {
            width: 50%;
        }
        #orders-container > ul > li > ul > li:nth-child(3)
        {
            display: none;
        }
    }
</style>