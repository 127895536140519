<template>
    <div id="political" ref="political">
        <div id="political-modal" class="ui suimodal large">
            <!-- <i class="close icon inside"></i> -->
            <div class="header">
                <div class="ui stackable grid">
                    <div class="eleven wide column txt-actions">
                        <h2 id="political-title">Política de cookies</h2>
                    </div>
                    <div class="five wide column">
                        <a class="ui button btn-default btn-cookies-config" @click="cookieHandler">{{ $i18n.t('security.changePreferences') }} <i class="pen icon"></i></a>
                    </div>
                </div>
                <!-- <SocialNetwork></SocialNetwork> -->
            </div>
            <div class="content" v-html="html"></div>
            <div class="actions left">
                <!-- <SocialNetwork></SocialNetwork> -->
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import { mapActions, mapGetters } from "vuex";
// import SocialNetwork from "@/components/security/SocialNetwork";
export default {
    name: 'Political',
    computed: {
        ...mapGetters(['logged', 'token', 'name', 'nickname', 'event_id', 'switch']),
    },
    data() {
        return {
            html: null
        }
    },
    // components: { SocialNetwork },
    methods: {
        ...mapActions([
            'update_age_verified', 'update_age', 'update_security_cookie'
        ]),
        cookieHandler(e)
        {
            e.preventDefault();
            this.close();
            this.update_security_cookie(true);
        },
        /*
        refuse(e)
        {
            e.preventDefault();

            if (document.getElementById('cookie-google-tag-manager'))
                document.getElementById('cookie-google-tag-manager').remove();
            if (document.getElementById('cookie-matomo'))
                document.getElementById('cookie-matomo').remove();
            if (document.getElementById('cookie-comscore'))
                document.getElementById('cookie-comscore').remove();
            
            this.save();
        },
        save()
        {
            let cookie_data = JSON.stringify(this.currentTypeStatus);
            localStorage.setItem('security_cookie', cookie_data);

            this.close();
        },
        */
        close()
        {
            window.$('#political-modal').suimodal('hide');
        }
    },
    mounted()
    {
        this.html = this.switch.cookie_policies.text;
        let vm = this;
        window.$('#political-modal').suimodal({ observeChanges: true, onHidden: function() { vm.$parent.political = false;window.$('#political-modal').remove() } }).suimodal('show');
    }
}
</script>

<style scoped>
    #political-title
    {
        padding: 0 0 24px 0;
    }
    
    .ui.suimodal > .content, .ui.suimodal > .header
    {
        max-width: 100%;
        padding: 20px 0 !important;
    }
    .ui.large.suimodal
    {
        box-sizing: border-box;
        padding: 65px;
    }
    .ui.suimodal > .actions
    {
        background-color: transparent;
        box-sizing: border-box;
        padding-bottom: 30px !important;
    }
    .ui.suimodal > .content 
    {
        color: var(--fonts_color);
        line-height: 24px;
    }
    .ui.positive.button, .ui.positive.buttons .button, .ui .button
    {
        background-color: var(--buttons_color) !important;
        border: solid 1px var(--buttons_color);
        color: var(--fonts_color) !important;
        border-radius: 10px;
        text-align: center;
        font-size: var(--bs-body-font-size);
        font-weight: var(--bs-body-font-weight);
        line-height: var(--bs-body-line-height);
    }

    
    @media screen and (max-width: 1000px)
    {
        .ui.large.suimodal
        {
            padding: 20px;
        }
        #political-title
        {
            padding: 0;
        }
        .btn-cookies-config
        {
            margin-bottom: 24px;
            width: 100%;
        }
    }

</style>
