
<template>
    <div>
        <div class="viewer_actions">

        </div>
        <div class="pdf_content">
            <!-- <vue-pdf-embed @rendered="handleDocumentRender"  :source=pdf_url :id="'pdf_'+pdf_id" :page="actualPage" :ref="'pdf_'+pdf_id"></vue-pdf-embed> -->
            <iframe :src="pdf_url" frameborder="0"></iframe>
        </div>
    </div>
</template>
<script>
    // import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
    
    export default {
        name: 'PdfViewer',
        data: () => ({
            actualPage: 1,
            totalPages: 0,
            zoom: 400
        }),
        components: {
            // VuePdfEmbed
        },
        props: ['pdf_id', 'pdf_url'],
        methods: {
            handleDocumentRender() {
                this.totalPages = this.$refs['pdf_'+this.pdf_id].pageCount
            }
        }
    }
</script>
<style scoped>
.pdf_content {
    border: 7px solid #323639;
    height: 500px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.pdf_content .vue-pdf-embed {
    width: 600px;
    height: 600px;
}
.pdf_content iframe {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 20px;
}

.pdf_content iframe #document{
    width: 100%;
    height: 100%;
}
.pdf_content .vue-pdf-embed > div {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
}
.viewer_actions {
    height: 50px;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #323639;
}
</style>