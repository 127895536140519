<template>
    <div id="group" ref="group">
        <div id="group-content" ref="group-content">

            <a href="#" v-on:click="close" style="filter: grayscale(1) invert(1);float:right;">
                <img src="assets/icons/modals/x.svg" alt="">
            </a>

            <h3>Controle dos grupos</h3>

            <label for="id_group">Grupo</label>
            <select class="select-groups" name="id_group" id="id_group" v-on:change="choiceHandler">
                <option value="">Escolha o grupo</option>
                <!--<option value="">Sem grupo</option>-->
                <option v-for="(a, index) in groups" :key="index" :value="a.id" :data-on="a.on" :data-id-room="a.id_room">{{ a.name }}</option>
            </select>
            
            <br><br>
            <div v-bind:class="{ disabled_: (count == 0) }">
                <label for="id_session">Ambiente</label>
                <select class="select-session" name="id_session" id="id_session" v-on:change="choiceHandler">
                    <option value="">Escolha o ambiente</option>
                    <option v-for="(a, index) in sessions_groups" :key="index" :value="a.room_id">{{ a.name }} </option>
                </select>
            </div>
            
            <br><br>
            <div v-bind:class="{ disabled_: (count < 2) }" style="display:inline-block;vertical-align:top">
                <label>DIRECIONAR GRUPO</label><br>
                <input name="on" id="groups_on" type="radio" value="1">
                <label for="groups_on">ON</label>
                &nbsp;&nbsp;&nbsp;
                <input name="on" id="groups_off" type="radio" value="0">
                <label for="groups_off">OFF</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div v-bind:class="{ disabled_: (count < 2) }" style="display:inline-block;vertical-align:top">
                <!--
                <input id="acompanhar" name="acompanhar" type="checkbox" value="1"><label for="acompanhar">ACOMPANHAR GRUPO</label>
                -->
                
            </div>

            <br><br>
            <div class="button__red" id="btn-save-g" v-on:click="save">SALVAR E DIRECIONAR</div>
            <button type="button" class="button__red" id="btn-go-to" v-on:click="goTo" style="float:right">IR AO LOCAL</button>
        </div>
    </div>
</template>

<script>
    
    import axios from 'axios';
    import { mapGetters} from "vuex";
    
    export default {
        name: 'ModeratorGroup',
        computed: {
            ...mapGetters(['token','sessions', 'groups']),
        },
        props: [],
        data() {
            return {
                count:0,
                sessions_groups:[],
                go_to: false
            }
        },  
        components: {
            
        },
        methods: {
            close() {
                this.$app.moderatorComponent = false;
            },
            choiceHandler(e) {
                //e.preventDefault();
                this.count++

                console.log(e.currentTarget.id);
                if (e.currentTarget.id == 'id_group')
                {
                    console.log('dataset => ', e.currentTarget.getElementsByTagName('option')[e.currentTarget.selectedIndex].dataset.idRoom);
                    //if (e.currentTarget.getElementsByTagName('option')[e.currentTarget.selectedIndex].dataset.idRoom != undefined);
                    if ((e.currentTarget.value != ""))
                    {
                        if (e.currentTarget.getElementsByTagName('option')[e.currentTarget.selectedIndex].dataset.idRoom != undefined)
                        {
                            console.log('inside');
                            document.getElementById('id_session').value = e.currentTarget.getElementsByTagName('option')[e.currentTarget.selectedIndex].dataset.idRoom;
                            this.count++;
                        }

                        if (e.currentTarget.getElementsByTagName('option')[e.currentTarget.selectedIndex].dataset.on == 1)
                        {
                            document.getElementById('groups_on').checked = true;
                        }
                        else
                        {
                            document.getElementById('groups_off').checked = true;
                        }
                    }
                    else
                    {
                        this.count = 0;
                        document.getElementById('id_session').value = '';
                        document.getElementById('groups_on').checked = false;
                        document.getElementById('groups_off').checked = false;
                    }
                    
                }
            },
            goTo(e)
            {
                this.go_to = true;
                document.getElementById('btn-save-g').click();
                e.preventDefault();
            },
            save(e) {
                e;
                if ((document.getElementById('id_session').value != "") && (document.getElementById('id_group').value != "") && (document.querySelector('input[name="on"]:checked').value != ""))
                {
                    let headers = {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*',
                        'Access': 'application/json',
                        language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                    };

                    let form_data = new FormData(this.$refs["age-form"]);
                    form_data.append('id_session', document.getElementById('id_session').value);
                    form_data.append('id_group',   document.getElementById('id_group').value);
                    form_data.append('on',         document.querySelector('input[name="on"]:checked').value);
                    form_data.append('goto',       (this.go_to ? 1 : 0));

                    axios.post(this.$url_api + 'api/refreshGroup', form_data, { headers: headers }).then(response => this.saveResponse(response)).catch(error => this.saveError(error.response));
                }
                else
                {
                    this.$app.modal('error', 'Erro!', 'Todos os campos são obrigatórios.', null, null, null, "OK");
                }
            },
            saveResponse(obj) {
                console.log('saveResponse => ', obj);
                if (obj.data.status == 'success') {
                    if (this.go_to)
                    {
                        this.$router.push({path: ('/e/' + document.getElementById('id_session').options[document.getElementById('id_session').selectedIndex].text)});
                        //window.location = this.$url + '#/e/' + document.getElementById('id_session').options[document.getElementById('id_session').selectedIndex].text;
                        //window.location = this.$url + '#/minha-conta';
                        window.location.reload();
                        this.$app.moderatorComponent = false;
                    }
                    else
                    {
                        this.$app.modal('success', 'Sucesso!', 'Status do grupo atualizado com sucesso.', null, null, null, "OK");
                    }
                } else {
                    let str = '';
                    for (let o in obj.data.errors) {
                        str += obj.data.errors[o] + '\n';
                    }
                    this.$app.modal('error', 'Erro!', str, null, null, null, "OK");
                }
            },
            saveError(obj) {
                console.log('saveError => ', obj);
                obj;
                let str = '';
                for (let o in obj.data.errors) {
                    str += obj.data.errors[o] + '\n';
                }
                this.$app.modal('error', 'Erro!', str, null, null, null, "OK");
            }
        },
        created() {
            
        },
        mounted() {
            for(let o in this.sessions)
            {
                //if (this.sessions[o].groups === 1)
                //{
                    this.sessions_groups.push(this.sessions[o]);
                //}
            }
        },
        updated() {
           
        },
        beforeDestroy() {
            
        },
    }
</script>

<style scoped>
    #group
    {
        background-color: rgba(0, 0, 0, .7);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }
    #group *
    {
        font-size: 16px !important;
    }
    #group-content
    {
        background-color: white;
        border-radius: 6px;
        box-sizing: border-box;
        height: auto;
        margin: 100px auto;
        padding: 20px;
        vertical-align: top;
        width: 450px;
    }
    label
    {
        display: inline-block;
        padding: 10px 0;
    }
    select
    {
        height: 40px;
        width: 100%;
    }
    .disabled_
    {
        opacity: .3;
        pointer-events: none;
    }
    #btn-save-g, #btn-go-to
    {
        background-color: black;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        box-sizing: border-box;
        color: white;
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        padding: 10px;
    }
    @media screen and (max-width: 500px)
    {
        
    }
</style>