const MODULE = {
    namespaced: true,
    state: {
        keepOpen: false,
        nicknameModelS_:'',
        cpfS_: '',
        phoneS_: '',
        emailS_: '',
        nameS_: '',
        birthS_:"",
        passwordS_: '',
        passwordConfS_: '',
        companyS_: '',
        stateS_: '',
        cityS_: '',
        regionS_: '',
        emailL_: '',
        passwordL_: '',
        nationalityS_: "",
        checks: []
    },
    mutations: {
        save (state,payload) {
            state[payload.key] = payload.value
        },
        updateKeepOpen(state, payload) {
            state.keepOpen = payload
        },
        reset(state){
            state.nicknameModelS_ = "";
            state.cpfS_ = "";
            state.phoneS_ = "";
            state.emailS_ = "";
            state.nameS_ = "";
            state.birthS_ = "";
            state.passwordS_ = "";
            state.passwordConfS_ = "";
            state.companyS_ = "";
            state.stateS_ = "";
            state.cityS_ = "";
            state.regionS_ = "";
            state.emailL_ = "";
            state.passwordL_ = "";
            state.nationalityS_ = "";
            state.checks = [];
            state.keepOpen = false;
        },
        resetKeepOpen(state) {
            state.keepOpen = false;
        },
    },
    getters: {
        cadastro: (state) => {
            return state
        },
    },
    actions:{
        reset: ({ commit }) => commit('reset'),
    }
}
export default MODULE
