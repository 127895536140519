export default {
    update_course_selected(state, id) {
        state.courseSelected = id;
    },
    update_course_data(state, data) {
        state.courseData = data;
    },
    update_course_sections(state, data) {
        state.courseSections[data.id] = data;
    },
    update_section_lessons(state, data) {
        state.lesson = data;
    },
    update_processing(state, process){
        state.processing = process;
    },
};