<template>
    <div v-if="ticket_actual" id="vip-room" ref="vip-room">
        <div ref="vip-room-content">

            <div class="vipRoom">
                <div class="vipRoom__title">
                    <h1>Camarote | Minhas Reuniões 1:1</h1>
                </div>

                <div class="content">

                    <div class="vipRoom__ticket">
                        <div class="vipRoom__ticket--tickets vipRoom__ticket--bordered">
                            <div>
                              <div class="vipRoom__ticket--tickets--date">
                                <h3>Início:</h3>
                                <span :key="index" 
                                  v-for="(item, index) in formatDate(ticket_actual.date_ini)"
                                  >{{item}}<br></span>
                              </div>
                              <div class="vipRoom__ticket--tickets--date">
                                <h3>Fim:</h3>
                                <span :key="index" 
                                  v-for="(item, index) in formatDate(ticket_actual.date_fin)"
                                  >{{ item }}<br></span>
                              </div>
                              <div class="vipRoom__ticket--tickets--enterprise">
                                <div>
                                  <h3>{{ ticket_actual.name }}</h3>
                                  <div class="edit">
                                    <img :src="$url + 'assets/images/vipRoom/edit.svg'">
                                    <span>{{ ticket_actual.local }}</span>
                                  </div>
                                </div>

                                  <button v-on:click="$app.chat_not_clear=true;$app.chat_owner=(nickname + ' ' + id);$app.updateUser('id_vip_actual', id);$app.updateUser('owner_vip_actual', nickname);$app.chat_room=('BarVip' + id);$app.link('BarVip')">Entrar</button>
                              </div>
                            </div>

                        </div>
                        <div class="vipRoom__ticket--crown">
                            <img :src="$url + 'assets/images/vipRoom/crown.svg'">
                            <div> </div>
                            <h3>{{ ticket_actual.name }}</h3>
                        </div>
                    </div>

                    <div class="vipRoom__list">

                        <div class="vipRoom__list--titulo">
                            <label>Seus Convidados</label>
                            <p style="margin-bottom: 0;">Capacidade da sala:&nbsp; 4 pessoas</p>
                            <a v-on:click="$app.link('Friends')" >Você ainda não tem amigos, <b>clique aqui</b> para convidar</a>
                        </div>
                        <div>
                        </div>
                        <div id="list-friend" ref="list-friend"></div>
                        <div class="list-friend">

                        <div v-for="(l, index) in list_invited_friend" :key="index" >
                            <div class="vipRoom__list--amigo">
                                    <div class="card__avatar">
                                        <img :src="$url + 'assets/icons/myAccount/perfil.svg'" alt="Minha conta">
                                    </div>
                                    <div class="card__name">
                                        <p>{{ l.nickname }}</p>
                                    </div>

                                    <div class="card__bnt">
                                        <div >
                                            <div v-if="l.invited == false">
                                                <button class="card__bnt--convidar" :data-index="index" data-v="0" :data-id="l.id" v-on:click="invitationHandler">Enviar Convite</button>
                                            </div>
                                            <div v-else>
                                                <button class="card__bnt--cancelar" :data-index="index" data-v="1" :data-id="l.id" v-on:click="invitationHandler">Cancelar Convite</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
                        </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";

export default {
    name: 'VipRoom',
    computed: {
        ...mapGetters([
            'token', 'ticket_actual', 'id', 'nickname','avatar'
        ]),
    },
    data() {
        return {
            dataset_actual: null,
            error: null,
            list_friend: [],
            list_invited_friend: [],
            total_friend: 0,
            total_invited_friend: 0,
            // ticket_actual: null,
          //   ticket_actual: 
          //  {"id":21,"name":"teste - vip","local":"teste","coin_price":1,"price":"0,00","event_date":"05/07/2021","event_time":"21:16","date_ini":"05/07/2021 21:33","date_fin":"06/07/2021 21:34","type":"vip","link_eventim":"https://www.eventim.com.br/event/nba-eventos-teste-local-de-teste-13729720/?affiliate=NBA","after_event_url":".","after_event_description":".","state":"Ao vivo","link_vimeo":"https://vimeo.com/showcase/8577767/embed","agenda":[{"color":"#f54842","name":"SHOW","description":""}],"bought":true} 
        }
    },
    components: {

    },
    methods: {
        //----- invitationHandler -----//
        invitationHandler(e) {
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            this.dataset_actual = e.currentTarget.dataset;
            let form_data = new FormData();
            form_data.append('friend_id', e.currentTarget.dataset.id);
            form_data.append('type_ticket', this.ticket_actual.id);
            axios.post(this.$url_api + 'api/VipRoomInvite', form_data, { headers:headers }).then(response => this.invitationHandlerResponse(response)).catch(error => this.invitationHandlerError(error.response));
        },
        invitationHandlerResponse(obj)
        {
            //console.log('invitationHandlerResponse => ', obj);
            if (obj.data.status == 'success') {
                if (obj.data.data.message == 'Convite enviado com sucesso.') {
                    this.list_invited_friend[this.dataset_actual.index].invited = true;
                } else if (obj.data.data.message == 'Convite cancelado com sucesso.') {
                    this.list_invited_friend[this.dataset_actual.index].invited = false;
                }
            }
            this.$app.wait(false);
        },
        invitationHandlerError(obj)
        {
            //console.log('invitationHandlerError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        //----- \invitationHandler\ -----//

        //----- load-friends -----//
        /*
        loadFriend() {
            //this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetFriends', { headers:headers }).then(response => this.loadFriendResponse(response)).catch(error => this.loadFriendError(error.response));
        },
        loadFriendResponse(obj)
        {
            console.log('loadFriendResponse => ', obj);
            if (obj.data.status == 'success') {
                console.log(obj.data.friends);
                this.total_friend = obj.data.friends.length;
                this.list_friend = obj.data.friends;
            }
            this.$app.wait(false);
        },
        loadFriendError(obj)
        {
            console.log('loadEventsError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        */
        //----- \load-friends\ -----//

        //----- load-invited-friends -----//
        loadInvitedFriend() {
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            let p = { type_ticket:this.ticket_actual.id };
            axios.get(this.$url_api + 'api/VipRoomInvitedFriends', { params: p, headers:headers }).then(response => this.loadInvitedFriendResponse(response)).catch(error => this.loadInvitedFriendError(error.response));
        },
        loadInvitedFriendResponse(obj)
        {
            //console.log('loadInvitedFriendResponse => ', obj);
            if (obj.data.status == 'success') {
                this.total_invited_friend = Object.keys(obj.data.data).length;
                this.list_invited_friend = Object.values(obj.data.data);

                //this.loadFriend();
            }
            this.$app.wait(false);
        },
        loadInvitedFriendError(obj)
        {
            //console.log('loadInvitedFriendError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        //----- \load-invited-friends\ -----//
        primeiraMaiuscula (s) 
        {
          return s.charAt(0).toUpperCase() + s.slice(1);
        },
        formatDate(data)
        {
          return new Intl.DateTimeFormat('pt-br', {day: 'numeric', month: 'long', weekday: 'short', hour: '2-digit'})
            .format(Date.parse(data.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')))
            .replace(/(\w{3})\.,\s(\d{1,2})\s(\w{2})\s(\w+)\s(\d{2})/, (match, p1, p2, p3, p4, p5) =>
              `${p2} ${p3} ${this.primeiraMaiuscula(p4)}
              ${this.primeiraMaiuscula(p1)} - ${p5}h`
            )
            .split('\n');
        }

    },
    created() {

    },
    mounted() {
        if (this.ticket_actual ==  null) {
            this.$app.link('Ticket');
        } else {
            // console.log('ticket_actual => ', this.ticket_actual);
            this.loadInvitedFriend();
        }

        window.scrollTo(0, 0);
    },
    updated() {

    },
    beforeDestroy() {

    },
}
</script>

<style scoped>
</style>