import axios from 'axios'

export default {
    AddFriend({rootState}, payload) {
        let url = this._vm.$url_api + 'api/AddFriend';
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization: `Bearer ${rootState.token}`
        };

        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    RemoveFriend({rootState}, payload) {
        let url = this._vm.$url_api + 'api/RemoveFriend';
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization: `Bearer ${rootState.token}`
        };

        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    AcceptRequest({rootState}, payload) {
        let url = this._vm.$url_api + 'api/AcceptRequestFriend';
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization: `Bearer ${rootState.token}`
        };

        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            })
        })
    },
    
    RejectRequest({rootState}, payload) {
        let url = this._vm.$url_api + 'api/RejectRequestFriend';
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization: `Bearer ${rootState.token}`
        };

        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            })
        })
    },

    BlockUser({rootState}, payload) {
        let url = this._vm.$url_api + 'api/blockUser/' + payload.friend_id;

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization: `Bearer ${rootState.token}`
        };

        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            })
        })
    },

    UnblockUser({rootState}, payload) {
        let url = this._vm.$url_api + 'api/unBlockUser/' + payload.friend_id;

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization: `Bearer ${rootState.token}`
        };

        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            })
        })
    },

    GetRequests({rootState}, payload) {
        let url = this._vm.$url_api + `api/GetRequestedFriends`;
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        
        return new Promise((resolve, reject) => {
            axios.get(url,
                {
                    headers:headers,
                    params: payload
                }).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            })
        });
    },

    GetFriends({rootState}, payload) {
        let url = this._vm.$url_api + `api/user/friends`;
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        
        return new Promise((resolve, reject) => {
            axios.get(url,
                {
                    headers:headers,
                    params: payload
                }).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            })
        });
    },

    LoadSearch({rootState}, payload) {
        let url = this._vm.$url_api + `api/GetAllUnblockedUsers`;
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        
        return new Promise((resolve, reject) => {
            axios.get(url,
                {
                    headers:headers,
                    params: payload
                }).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            })
        });
    },

    LoadInbox({rootState}, payload) {
        let url = this._vm.$url_api + `api/pm/loadInbox`;
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization: `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers: headers}).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            })
        })
    },
    /*
    SearchFriends({rootState}, payload) {
        let url = this._vm.$url_api + `api/SearchParticipants`
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params: {search: payload}}).then((res) => {
                res;
            }).catch((error) => {
                error;
            })
        })
    }
    */
}
