<template>
    <div v-if="getLmsConfigs.checkout_logo && getLmsConfigs.checkout"  :style="{ backgroundImage: `url('${getLmsConfigs.checkout_logo}')` }" class="full-page-image" @click="goTo">
    </div>
    <div v-else class="error-page">
        <div class="error-screen">
            <div class="error-icon">
                <svg width="90" height="90" viewBox="0 0 117 117" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title/>
                    <desc/>
                    <defs/>
                    <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
                        <g fill-rule="nonzero" id="correct">
                            <path d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z" fill="#17AB13" id="Shape"/>
                            <path d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z" fill="#4A4A4A" id="Shape"/>
                        </g>
                    </g>
                </svg>          
            </div>
            <h3 class="error-title">
                Compra realizada com sucesso!
            </h3>

            <p class="error-description">
                Você receberá um e-mail com as informações da compra, você já pode acessar seu curso.
            </p>
            
            <button class="error-button" @click="goTo">
                Voltar para a página inicial
            </button>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name: 'LmsCheckoutSuccess',
    data: () => ({

    }),
    computed: {
        ...mapGetters('LMS', [
            "getLmsConfigs"
        ]),
    },
    components: {

    },
    beforeMount() {

    },

    mounted() {

    },
    methods: {
        goTo() {
            let root_ = this;
            root_.$router.push({
                name: "LmsHome",
                params: {
                    lmsSlug: root_.$route.params.lmsSlug
                },
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .full-page-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: (100vh + 15vh);
        cursor: pointer;
    }

    
    .error-page {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90vh;

        .error-screen {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 64px 80px;
            border-radius: 50px;
            border: 1px solid #E1E1E1;
            background: #FFF;

            .error-icon {
                margin-bottom: 10px;
            }

            .error-title {
                font-size: 26px;
                font-weight: 600;
                color: var(--buttons_color);
            }

            .error-description {
                font-size: 18px;
                font-weight: 400;
                color: #706F6F;
                margin: 30px 0;
                line-height: 20px;
            }

            .error-button {
                width: 100%;
                max-width: 300px;
                height: 50px;
                border-radius: 50px;
                background: var(--buttons_color);
                color: #FFF;
                font-size: 16px;
                font-weight: 600;
                border: none;
                cursor: pointer;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                &:hover {
                    background: #5A47C9;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
                    transition: all .3s ease-in-out;
                }
            }
        }
    }
</style>