<template>
  <div id="lmsbasictemplate" class="container mb-5">

    <div class="row basic-header mb-5 d-flex justify-content-between">
      <!-- <div class="col-sm-12 col-md-3 mx-0"> -->
      <div class="col-md-3">
        <span class="current-page ">{{this.currentPage}}</span>
      </div>

      <div v-if="pageSubTitle.length && !searchField" class="explore col-md-9">
        <span v-html="pageSubTitle"></span>
      </div>
      <div v-if="pageSubTitle.length && searchField" class="explore col-sm-12 col-md-5"> <!--  ps-md-4 ps-xl-0 -->
        <span v-html="pageSubTitle"></span>
      </div>



      <SearchFields v-if="searchField" :ismob="isMobile" :searchPlaceholder="searchPlaceholder"></SearchFields>
      <!-- <div v-if="searchField" class="d-flex justify-content-end col-sm-12 col-md-4 float-right">

        <div class="input-group search">
          <span class="input-group-text bg-transparent border-end-0 py-3 ps-4">
            <i class="bi bi-search"></i>
          </span>
          <input type="text" class="form-control border-start-0" :placeholder="searchPlaceholder" v-model="searchText" @input="handleInput">
          <span :class="{'show': showSearchError}" class="text-danger">{{ $i18n.t('lms.basic_template.search_min') }}</span>
        </div>

      </div> -->


      <NavTabs :ismob="isMobile" :tabgroup="getTabGroup()" :gateways="gateways"></NavTabs>
      <!--
        <div v-if="showGateways" class="gateways col-sm-12 col-md-9">
          <div class="nav justify-content-center" id="nav-tab" role="tablist">

            <button v-for="(gate, idxGate) in gateways" class="nav-link align-items-center d-flex gap-2 px-5 py-3" :class="{'active': idxGate === 0 }"
                    :id="`nav-${gate}-tab`" data-bs-toggle="tab" :data-bs-target="`#nav-${gate}`" type="button"
                    role="tab" :aria-controls="`nav-${gate}`" :aria-selected="idxGate === 0" :key="idxGate">
              <span class="material-symbols-outlined">
                {{ getGatewayIcon(gate)}}
              </span>
              <span v-text="getGatewayName(gate)"></span>
            </button>

          </div>

        </div>
      -->
      <!--
        <div v-if="showEtalent" class="gateways col-md-9 ps-5">
          <div class="nav" id="nav-tab" role="tablist">

            <button v-for="(etalent, idxEtalent) in etalent" class="nav-link align-items-center d-flex gap-2 px-5 py-3" :class="{'active': idxEtalent === 0 }"
                    :id="`nav-${etalent}-tab`" data-bs-toggle="tab" :data-bs-target="`#nav-${etalent}`" type="button"
                    role="tab" :aria-controls="`nav-${etalent}`" :aria-selected="idxEtalent === 0" :key="idxEtalent">
              <span class="material-symbols-outlined">
                {{ getEtalentIcon(etalent)}}
              </span>
              <span v-text="getEtalentName(etalent)"></span>
            </button>

          </div>

        </div> 
      -->
      
    </div>
    <div class="row">
      <div class="col-md-3">
        <slot name="navigation-side">
        </slot>
      </div>
      <div class="col-md-9" :class="{'col-md-12': fullContent}">
        <slot name="content-side">
        </slot>
      </div>
    </div>

    <Loading v-if="getLoading"></Loading>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  </div>
</template>
<script>

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import {mapGetters} from "vuex";
import Loading from "@/components/Loading";
import NavTabs from "@/components/lms/components/LmsBasicTemplateComponents/NavTabs";
import SearchFields from "@/components/lms/components/LmsBasicTemplateComponents/SearchFields";


let emitter = require('tiny-emitter/instance');
export default {
  name: "LmsBasicTemplate",
  components: {
    Loading,
    NavTabs,
    SearchFields
  },
  props: {
    currentPage: {},
    searchField: {
      default: false
    },
    searchPlaceholder: {
      default: "Pesquisar..."
    },
    pageSubTitle: {
      default: ""
    },
    showGateways: {
      default: false
    },
    showEtalent: {
      default: false
    },
    // etalent: {
    //   default: function() {
    //     return [
    //       'courses',
    //       'etalent'
    //     ]
    //   }
    // },
    gateways: {
      default: function(){
        return []
      }
    },
    fullContent: {
      default: false
    }

  },
  data() {
    return {
      // searchText: '',
      // showSearchError: false,
      modal_private_messages: false,
      private_messages_friend_id: null,
      isMobile: false,
    }
  },
  beforeMount() {
    document.body.classList.add('lms');
    document.body.classList.add('transparent');
    let root_ = this;
    root_.isMobile = (root_.checkMobile || root_.checkIOS);
    let payload = {
      "slug": root_.$route.params.lmsSlug,
      "language": root_.$i18n.locale
    };
    this.$store.dispatch('LMS/getListLMS', payload);
  },
  mounted() {
    let root_ = this;

    emitter.on("private_message::open::modal", function(data) {
      root_.modal_private_messages = data.open_modal;
      root_.private_messages_friend_id = data.friend_id;
    });

    // emitter.on("clear-search-text", this.clearSearchText);
  },
  computed: {
    ...mapGetters('LMS', [
      "getCoinsName", "getLoading"
    ]),
    ...mapGetters(['checkMobile', 'checkIOS'])
  },
  methods: {
    wait(b, unity_ = null) {
      b;
      unity_;
    },
    // clearSearchText(){
    //   this.searchText = '';

    //   emitter.emit('filter-courses', this.searchText);
    // },
    // handleInput() {
    //   emitter.emit('filter-courses', this.searchText);

    //   this.showSearchError = this.searchText.trim().length < 4 && this.searchText.trim().length > 0;
    // },



    // getGatewayName(gatewayName) {
    //   let name = gatewayName;

    //   if(gatewayName.toLowerCase() === "pagseguro"){
    //     name = "Cartão de Crédito";
    //   } else if(gatewayName.toLowerCase() === "coins"){
    //     name = this.getCoinsName;
    //   } else if(gatewayName.toLowerCase() === "free"){
    //     name = "Gratuito";
    //   }

    //   return name;
    // },
    // getEtalentIcon(etalentName) {
    //   etalentName = etalentName.toLowerCase();
    //   var icon = "";

    //   if(etalentName === "courses") {
    //     icon = "local_library"
    //   } else if (etalentName === "etalent") {
    //     icon = "equalizer"
    //   }

    //   return icon;
    // },
    // getEtalentName(etalentName) {
    //   etalentName = etalentName.toLowerCase();
    //   var name = "";
    
    //   if(etalentName === "courses"){
    //     name = "Meus Cursos";
    //   } else if(etalentName === "etalent"){
    //     name = "Pessoal";
    //   }

    //   return name;
    // },
    // getGatewayIcon(gatewayName){

    //   gatewayName = gatewayName.toLowerCase();
    //   var icon = "";

    //   if(gatewayName === "pagseguro"){
    //     icon = "credit_card";
    //   } else if(gatewayName === "coins"){
    //     icon = "toll";
    //   } else if(gatewayName === "pix"){
    //     icon = "monetization_on";
    //   } else if(gatewayName === "debito"){
    //     icon = "payments";
    //   } else if(gatewayName === "free"){
    //     icon = "money_off";
    //   }


    //   return icon;

    // },
    getTabGroup() {
      if(this.showEtalent){
        return "showEtalent";
      } else if(this.showGateways){
        return "showGateways";
      }
      return;
    }
  },
  beforeDestroy() {
    emitter.off("private_message::open::modal");
    emitter.off("clear-search-text");
  },
}

</script>

<style lang="scss" scoped>
/*
body {
  overflow-y: scroll;
}
*/

#lmsbasictemplate {
  margin-top: 8rem;
  margin-bottom: 50px !important; /* adjust footer */

  .basic-header {
    align-items: center;

    .gateways {

      #nav-tab {
        button {
          border: 1px solid #F6F6F6;

          span {
            color: #B1B1B1;
          }

          &.active {

            background-color: #F6F6F6;

            .material-symbols-outlined {
              color: var(--buttons_color);
            }

            span {
              color: #706F6F;
            }
          }

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

        }
      }


    .material-symbols-outlined {
      font-variation-settings:
          'FILL' 0,
          'wght' 400,
          'GRAD' 0,
          'opsz' 48
    }


    }


    .search {
      position: relative;
      .text-danger {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(-40%, 120%);

        opacity: 0;
        transition: opacity 0.3s ease;

        &.show {
          opacity: 1;
        }
      }
    }

  }

  .explore {
    color: #706F6F;
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    padding-left: 0.6rem !important;
  }

  .current-page {
    color: var(--buttons_color);
    font-size: 38px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;

  }
  .input-group {
    .input-group-text {
      border-radius: 30px 0 0 30px;
    }

    .form-control {
      border-radius: 0 30px 30px 0;

      &:focus {
        box-shadow: none;
        border: 1px solid #dee2e6;
      }

    }



  }

  .bi.bi-search {
    color: var(--buttons_color);
  }

  .material-symbols-outlined {
    font-family: "Material Symbols Outlined";
  }

}

.container-fluid,
.row,
.col {
  height: 100%;
}


@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {
  #lmsbasictemplate.container,
  #lmsbasictemplate.container-md,
  #lmsbasictemplate.container-sm {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  #lmsbasictemplate .basic-header{
    margin-bottom: 40px !important;
  }
  #lmsbasictemplate .current-page{
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 768px) {
  #lmsbasictemplate .basic-header {
    margin-bottom: 20px !important;
  }
  .explore{
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  
}
</style>