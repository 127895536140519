<template>
    <div id="register" ref="register">
        <form class="box" id="register-form" method="post" ref="register-form" v-on:submit="register">
            <h3 v-text="$ml.get('register.title')"></h3>
            <ul>
                <li>
                    <label for="first_name" v-text="$ml.get('register.first_name')"></label>
                    <input id="first_name" name="first_name" :placeholder="$ml.get('register.first_name')" ref="first_name" required type="text">
                </li>
                <li>
                    <label for="last_name" v-text="$ml.get('register.last_name')"></label>
                    <input id="last_name" name="last_name" :placeholder="$ml.get('register.last_name')" ref="last_name" required type="text">
                </li>
                <li>
                    <label for="email" v-text="$ml.get('register.email')"></label>
                    <input id="email" name="email" :placeholder="$ml.get('register.email')" ref="email" required type="email">
                </li>
                <li>
                    <label for="password" v-text="$ml.get('register.password')"></label>
                    <input autocomplete="off" id="password" name="password" :placeholder="$ml.get('register.password')" ref="password" required type="password">
                </li>
                <li>
                    <div v-if="error" id="error">{{ error }}</div>
                    <input ref="btn-submit" type="submit" :value="btn_submit_value">
                </li>
                <li>
                    <!--<router-link class="btn-0" to="/" ref="btn-close" v-text="$ml.get('register.close')"></router-link>-->
                    <a class="btn-0" href="#" v-on:click="$parent.signup = false" v-text="$ml.get('register.close')"></a>
                </li>
            </ul>
        </form>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: 'Cadastro',
        data() {
            return {
                btn_submit      : false,
                btn_submit_value: this.$ml.get('register.send'),
                error           : null
            }
        },
        methods: {
            block(v) {
                this.btn_submit_value = (v ? (this.$ml.get('register.wait') + "...") : this.$ml.get('register.send'));
                this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
                this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
            },
            register(e) {
                this.error = null;
                if (this.$refs["first_name"].value.length < 2) {
                    this.error = this.$ml.get('register.form_0');
                } else if (this.$refs["last_name"].value.length < 3) {
                    this.error = this.$ml.get('register.form_1');
                } else if (!/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9.])+\.([A-Za-z]{2,})$/.test(this.$refs["email"].value)) {
                    this.error = this.$ml.get('register.form_2');
                } else if (this.$refs["password"].value.length < 3) {
                    this.error = this.$ml.get('register.form_3');
                }

                if (this.error) {
                    e.preventDefault();
                } else {
                    this.sendRegister(e);
                }
            },
            sendRegister(e) {
                if (!this.submit) {
                    this.block(true);
                    let form_data = new FormData(this.$refs["register-form"]);
                    axios.post(this.$url_api + 'store/oracle/register', form_data).then(response => this.registerResponse(response));
                }
                e.preventDefault();
            },
            registerResponse(obj) {
                if (obj.data.error) {
                    this.error = obj.data.error;
                } else if (obj.data.errorCode) {
                    this.error = obj.data.message;
                } else {
                    //this.$router.push('/entrar');
                    this.$parent.login = true;
                }
                this.block(false);
            }
        },
        mounted() {
            this.$refs['first_name'].focus();
        },
        updated() {
            this.btn_submit_value = this.$ml.get('register.send');
        }
    }
</script>

<style scoped>
    #register
    {
        background-color: rgba(0, 0, 0, .6);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }

    form
    {
        box-sizing: border-box;
        display: block;
        margin: 150px auto;
        max-width: 400px;
        padding: 30px;
    }
    form ul li
    {
        box-sizing: border-box;
        padding: 10px 0;
    }

    #error
    {
        box-sizing: border-box;
        color: red;
        display: block;
        padding: 10px;
        text-align: center;
    }

    @media screen and (max-width: 500px)
    {
        form
        {
            width: calc(100% - 40px);
        }
    }
</style>