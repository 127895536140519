<template>
  <div class='VuePagination' :class='props.theme.wrapper'>
    <nav :class='props.theme.nav'>
      <ul v-show="props.showPagination" :class="props.theme.list">

        <li v-for="page in props.pages" :key="page" class="mx-1" :class="props.pageClasses(page)"
            v-on="props.pageEvents(page)">
          <a v-bind="props.aProps" :class="props.theme.link">{{page}}</a>
        </li>

      </ul>
    </nav>
  </div>

</template>

<script>
export default {
  name: 'CustomPagination',
  props: ['props']
}
</script>
<style scoped lang="scss">

li {

  &.page-item {
    background-color: transparent;

    .page-link {
      border: none;
      border-radius: 7px;
      background-color: #F6F6F6;
      color: #706F6FB2;
    }

    &.active {

      .page-link {
        background-color: var(--buttons_color);
        color: #FFFFFF;
      }
    }

  }

}

</style>
