<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" :pageSubTitle="pageSubTitle" :searchField="true" searchPlaceholder="Pesquisar amigo...">
    <template v-slot:navigation-side>
      <ProfileNavigation></ProfileNavigation>

      <section class="mt-3">
        <span class="mycommunities">{{navigationConfig.navigationTitle}}</span>
        <div class="menu-buttons mt-3 d-flex flex-column gap-3">
          <button v-for="(button, idx) in navigationConfig.navigation" :key="idx" type="button" class="btn py-3" @click.prevent="goTo(button.slug)">
            <i v-if="button.icon" :class="button.icon"></i>
            {{ button.name }}
          </button>
        </div>
      </section>
    </template>
    <template v-slot:content-side>
      <div class="row">
        <div class="friends col-12 ">
          <div v-if="showNav" class="nav d-flex justify-content-center" id="nav-tab" role="tablist">

            <button v-for="(tab, idxTab) in tabs" class="nav-link align-items-center d-flex gap-2 px-5 py-3" :class="{'active': currentTab === tab }"
                    :id="`nav-${tab}-tab`" data-bs-toggle="tab" :data-bs-target="`#nav-${tab}`" type="button"
                    role="tab" :aria-controls="`nav-${tab}`" :aria-selected="idxTab === 0" :key="idxTab" @click="changeCurrentTab(tab)">
              <span class="material-symbols-outlined">
                {{ getTabIcon(tab) }}
              </span>
              <span v-text="getTabName(tab)"></span>
            </button>

            <div class="d-none"
                    :id="`nav-search-tab`" data-bs-toggle="tab" :data-bs-target="`#nav-search`" type="button"
                    role="tab" :aria-controls="`nav-search`" @click="changeCurrentTab('search')">
              <span class="material-symbols-outlined">
                search
              </span>
              <span>Search</span>
            </div>

          </div>

          <div id="nav-tabContent" class="tab-content" >
            <div class="tab-pane fade active show" id="nav-friends" role="tabpanel" aria-labelledby="nav-friends-tab" data-name="friends">
              <span style="color: #000">
                <div class="container gap-2">
                  <div class="row">
                    <FriendCard v-for="(friend, idx) in friendsToShow" :key="idx" :friend="friend" :friendAction="friendAction" :openMessage="openMessage" :goToUserPage="goToUserPage"></FriendCard>
                  </div>
                  <pagination ref="pagination" v-if="paginationOptions.show" v-model="paginationOptions.page" :records="paginationOptions.total" :per-page="paginationOptions.per_page" :options="paginationOptions" @paginate="paginateFriends"/>
                </div>
              </span>
            </div>

            <div class="tab-pane fade" id="nav-requests" role="tabpanel" aria-labelledby="nav-requests-tab" data-name="requests">
              <span style="color: #000">
                <div class="container gap-2">
                  <div class="row">
                    <RequestCard v-for="(request, idx) in requestsToShow" :key="idx" :request="request" :friendAction="friendAction" :goToUserPage="goToUserPage"></RequestCard>
                  </div>
                  <pagination ref="paginationReq" v-if="paginationOptionsReq.show" v-model="paginationOptionsReq.page" :records="paginationOptionsReq.total" :per-page="paginationOptionsReq.per_page" :options="paginationOptionsReq" @paginate="paginateReq"/>
                </div>
              </span>
            </div>

            <div class="tab-pane fade" id="nav-inbox" role="tabpanel" aria-labelledby="nav-inbox-tab" data-name="inbox">
              <Inbox ref="inbox" :wait="$parent.wait" :changeSubtitle="changeSubtitle" :goToUserPage="goToUserPage"></Inbox>
            </div>

            <div class="tab-pane fade" id="nav-search" role="tabpanel" aria-labelledby="nav-search-tab" data-name="search">
              <div class="row" style="color: #000">
                <SearchCard v-for="(search, idx) in searchToShow" :key="idx" :search="search" :friendAction="friendAction" :wait="$parent.wait" :privateMessage="openMessage" :goToUserPage="goToUserPage"></SearchCard>
              </div>
              <pagination ref="paginationSearch" v-if="paginationOptionsSearch.show" v-model="paginationOptionsSearch.page" :records="paginationOptionsSearch.total" :per-page="paginationOptionsSearch.per_page" :options="paginationOptionsSearch" @paginate="paginateSearch"/>
            </div>

          </div>

        </div>
      </div>      
    </template>
  </LmsBasicTemplate>
</template>
<script>

import { mapGetters } from "vuex";
import LmsBasicTemplate from "@/components/lms/LmsBasicTemplate.vue";
import ProfileNavigation from "@/components/lms/components/ProfileNavigation.vue";
import FriendCard from "@/components/lms/components/LmsFriendsComponents/FriendCard.vue";
import SearchCard from "@/components/lms/components/LmsFriendsComponents/SearchCard.vue";
import RequestCard from "@/components/lms/components/LmsFriendsComponents/RequestCard.vue";
import CustomPagination from "@/components/lms/components/LmsHomeComponents/CustomPagination.vue";
import Inbox from "@/components/lms/components/LmsFriendsComponents/Inbox.vue";
let emitter = require('tiny-emitter/instance');


export default {
  name: "LmsNotifications",
  components: {
    LmsBasicTemplate,
    ProfileNavigation,
    FriendCard,
    RequestCard,
    SearchCard,
    Inbox
  },
  data() {
    return {
      navigationConfig: [],
      friendsToShow: [],
      requestsToShow: [],
      searchToShow: [],
      currentTab: 'friends',
      lastTab: null,
      pageSubTitle: 'Meus Amigos',
      searchText: null,
      paginationOptions: {
        current_page: 1,
        paginationKey: 0,
        show: true,
        total: 10,
        page: 1,
        per_page: 6,
        template: CustomPagination
      },
      paginationOptionsReq: {
        current_page: 1,
        paginationKey: 0,
        show: true,
        total: 10,
        page: 1,
        per_page: 4,
        template: CustomPagination
      },
      paginationOptionsSearch: {
        current_page: 1,
        paginationKey: 0,
        show: true,
        total: 10,
        page: 1,
        per_page: 5,
        template: CustomPagination
      },
      tabs: [
        'friends',
        'requests',
        'inbox'
      ],
      showNav: true,
    }
  },
  beforeMount() {
    let root_ = this;

    root_.navigationConfig = root_.getPageByName("LmsFriends");
    root_.loadItens(1, 1);
  },
  mounted() {
    let root_ = this;

    emitter.on('filter-courses', function (searchText) {
      root_.searchText = searchText;
      if(root_.searchText.length > 3) {
        root_.$parent.wait(true);
        if(root_.currentTab != 'search') {
          if(root_.currentTab == 'friends' || root_.currentTab == 'requests') {
            root_.lastTab = root_.currentTab;
          } else if (root_.currentTab == 'inbox') {
            return root_.$refs['inbox'].loadInbox();
          }
          root_.currentTab = 'search';
          document.getElementById('nav-search-tab').click();
        }

        root_.loadSearch(1);
      } else if(root_.searchText.length <= 3 && root_.searchText.length > 0) {
        if(root_.currentTab != 'search') {
          if(root_.currentTab == 'friends' || root_.currentTab == 'requests') {
            root_.lastTab = root_.currentTab;
          } else if (root_.currentTab == 'inbox') {
            return;
          }
          root_.currentTab = 'search';
          document.getElementById('nav-search-tab').click();
        }
      } else {
        if(root_.currentTab == 'search') {
          root_.currentTab = root_.lastTab;
          root_.lastTab = null;
          document.getElementById(`nav-${root_.currentTab}-tab`).click();
          root_.loadItens(1, 1)
        } else if (root_.currentTab == 'inbox') {
          root_.$refs['inbox'].loadInbox()
        } else {
          return;
        }
      }
    });
  },
  methods: {
    goToUserPage(userId) {
        this.$router.push({path: `/lms/${this.$route.params.lmsSlug}/profile/${userId}`})
    },
    changeSubtitle(tab, text = null) {
      if(tab == 'friends') {
        this.pageSubTitle = 'Meus Amigos'
      } else if (tab == 'requests') {
        this.pageSubTitle = 'Solicitações de Amizade'
      } else if (tab == 'inbox') {
        if(text != null) {
          this.pageSubTitle = text
        } else {
          this.pageSubTitle = 'Conversas'
        }
      } else if (tab == 'search') {
        this.pageSubTitle = 'Pesquisa'
      }
    },
    changeCurrentTab(tab) {
      this.currentTab = tab;

      if(tab != 'search') {
        if(this.searchText != null) {
          if(this.currentTab == 'inbox') {
            this.searchText = null;
            this.$refs['inbox'].loadInbox(0, false, true)
          }
          emitter.emit('clear-search-text');
        }
      }

      this.changeSubtitle(tab)

      if(tab == 'friends') {
        this.searchText = null;
        return;
      } else if (tab == 'requests') {
        this.searchText = null;
        return;
      } else if (tab == 'inbox') {
        this.searchText = null;
        this.$refs['inbox'].openMessages = false;
        this.$refs['inbox'].privateMessagesUser = null;
      } else if (tab == 'search') {
        return;
      }

    },
    goTo(slug){
      if(slug !== "") {
        this.$router.push({ name: slug})
      }
    },
    loadSearch(page) {
      let root_ = this;

      root_.searchToShow = [];
      root_.getSearch(page);
    },
    loadItens(pageFriends, pageRequests) {
      let root_ = this;
      root_.friendsToShow = [];
      root_.requestsToShow = []
      root_.getFriends(pageFriends);
      root_.getRequests(pageRequests);
      root_.$parent.wait(false);
    },
    openMessage(friend_id) {
        this.$parent.modal_private_messages = true;
        this.$parent.private_messages_friend_id = Number(friend_id);
        this.$parent.refreshNotificationPrivateMessages(friend_id);
    },
    friendAction(action ,id) {
        this.$parent.wait(true);
        let root_ = this;
        let payload;


        payload = {
            "friend_id": parseInt(id)
        };


        switch (action) {
          case 'send':
              root_.$store.dispatch('Friends/AddFriend', payload).then( response => {
                  response;
                  if(root_.currentTab != 'search') {
                    root_.loadItens(1, 1);
                  } else {
                    root_.loadSearch(1);
                  }

                  setTimeout(() => {
                    this.$parent.wait(false);   
                  }, 500);
              }).catch( error => {
                  error;
                  if(root_.currentTab != 'search') {
                    root_.loadItens(1, 1);
                  } else {
                    root_.loadSearch(1);
                  }

                  setTimeout(() => {
                    this.$parent.wait(false);   
                  }, 500);
              });
              break;
          case 'cancel':
              if(confirm('Tem certeza que deseja cancelar o pedido de amizade?')) {
                  root_.$store.dispatch('Friends/RemoveFriend', payload).then( response => {
                      response;
                      if(root_.currentTab != 'search') {
                        root_.loadItens(1, 1);
                      } else {
                        root_.loadSearch(1);
                      }

                      setTimeout(() => {
                        this.$parent.wait(false);   
                      }, 500);
                  }).catch( error => {
                      error;
                      if(root_.currentTab != 'search') {
                        root_.loadItens(1, 1);
                      } else {
                        root_.loadSearch(1);
                      }

                      setTimeout(() => {
                        this.$parent.wait(false);   
                      }, 500);
                  });
              }
              break;
          case 'accept':
                root_.$store.dispatch('Friends/AcceptRequest', payload).then( response => {
                    response;
                    if(root_.currentTab != 'search') {
                      root_.loadItens(1, 1);
                    } else {
                      root_.loadSearch(1);
                    }

                    setTimeout(() => {
                      this.$parent.wait(false);   
                    }, 500);
                }).catch( error => {
                    error;
                    if(root_.currentTab != 'search') {
                      root_.loadItens(1, 1);
                    } else {
                      root_.loadSearch(1);
                    }

                    setTimeout(() => {
                      this.$parent.wait(false);   
                    }, 500);
                });
                break;
            case 'reject':
                root_.$store.dispatch('Friends/RejectRequest', payload).then( response => {
                    response;
                    if(root_.currentTab != 'search') {
                      root_.loadItens(1, 1);
                    } else {
                      root_.loadSearch(1);
                    }

                    setTimeout(() => {
                      this.$parent.wait(false);   
                    }, 500);
                }).catch( error => {
                    error;
                    if(root_.currentTab != 'search') {
                      root_.loadItens(1, 1);
                    } else {
                      root_.loadSearch(1);
                    }

                    setTimeout(() => {
                      this.$parent.wait(false);   
                    }, 500);
                });
                break;
          case 'remove':
              if(confirm('Tem certeza que deseja desfazer a amizade?')) {
                  root_.$store.dispatch('Friends/RemoveFriend', payload).then( response => {
                      response;
                      if(root_.currentTab != 'search') {
                        root_.loadItens(1, 1);
                      } else {
                        root_.loadSearch(1);
                      }

                      setTimeout(() => {
                        this.$parent.wait(false);   
                      }, 500);
                  }).catch( error => {
                      error;
                      if(root_.currentTab != 'search') {
                        root_.loadItens(1, 1);
                      } else {
                        root_.loadSearch(1);
                      }

                      setTimeout(() => {
                        this.$parent.wait(false);   
                      }, 500);
                  });
              }
            break;
          case 'block':
              root_.$store.dispatch('Friends/BlockUser', payload).then( response => {
                  response;
                  if(root_.currentTab != 'search') {
                    root_.loadItens(1, 1);
                  } else {
                    root_.loadSearch(1);
                  }

                  setTimeout(() => {
                    this.$parent.wait(false);   
                  }, 500);
              }).catch( error => {
                  error;
                  if(root_.currentTab != 'search') {
                    root_.loadItens(1, 1);
                  } else {
                    root_.loadSearch(1);
                  }

                  setTimeout(() => {
                    this.$parent.wait(false);   
                  }, 500);
              });
              break;
        }
    },
    getRequests(page) {
      let root_ = this;

      let payload = {
        "slug": root_.$route.params.lmsSlug,
        "language": root_.$i18n.locale,
        "page": page,
        "search" : root_.searchText
      };

      root_.$store.dispatch('Friends/GetRequests', payload).then( response => { 
          response = response.data.data.friends

          root_.requestsToShow = Object.values(response.data);

          root_.paginationOptionsReq.current_page = response.current_page;
          root_.paginationOptionsReq.total = response.total;
          root_.paginationOptionsReq.per_page = response.per_page;
          root_.paginationOptionsReq.page = response.current_page;
          root_.paginationOptionsReq.show = root_.paginationOptionsReq.total > root_.paginationOptionsReq.per_page;
      }).catch( error => {
          console.log('getRequestsError => ', error);
      });
    },
    getSearch(page) {
      let root_ = this;

      let payload = {
        "page": page,
        "search" : root_.searchText
      };

      root_.$store.dispatch('Friends/LoadSearch', payload).then( response => { 
          response = response.data.data.friends

          root_.searchToShow = Object.values(response.data);

          root_.paginationOptionsSearch.current_page = response.current_page;
          root_.paginationOptionsSearch.total = response.total;
          root_.paginationOptionsSearch.per_page = response.per_page;
          root_.paginationOptionsSearch.page = response.current_page;
          root_.paginationOptionsSearch.show = root_.paginationOptionsSearch.total > root_.paginationOptionsSearch.per_page;

          console.log('getSearch => ', response.data);

          root_.$parent.wait(false);
      }).catch( error => {
          console.log('getSearchError => ', error);
          root_.$parent.wait(false);
      });
    },
    getFriends(page) {
      let root_ = this;

      let payload = {
        "slug": root_.$route.params.lmsSlug,
        "language": root_.$i18n.locale,
        "page": page,
        "search" : root_.searchText
      };

      root_.$store.dispatch('Friends/GetFriends', payload).then( response => { 
          response = response.data.data.friends

          root_.friendsToShow = Object.values(response.data);

          root_.paginationOptions.current_page = response.current_page;
          root_.paginationOptions.total = response.total;
          root_.paginationOptions.per_page = response.per_page;
          root_.paginationOptions.page = response.current_page;
          root_.paginationOptions.show = root_.paginationOptions.total > root_.paginationOptions.per_page;
      }).catch( error => {
          console.log('getFriendsError => ', error);
      });
    },
    paginateSearch(page){
      let root_ = this;

      root_.getSearch(page);
    },
    paginateFriends(page){
      let root_ = this;

      root_.getFriends(page);
    },
    paginateReq(page){
      let root_ = this;

      root_.getRequests(page);
    },
    getTabIcon(tab){
      let icon = '';
      if(tab == 'friends') {
        icon = 'group'
      } else if (tab == 'requests') {
        icon = 'person_add'
      } else if (tab == 'inbox') {
        icon = 'forum'  
      }

      return icon;
    },
    getTabName(tab){
      let name = '';
      if(tab == 'friends') {
        name = 'Amigos'
      } else if (tab == 'requests') {
        name = 'Solicitações'
      } else if (tab == 'inbox') {
        name = 'Inbox'  
      }

      return name;
    },
  },
  computed:{
    ...mapGetters({
      getLms: "LMS/getLms",
      getPageByName: "LMS/getPageByName",
    }),
    ...mapGetters([
        'logged', 'token', 'switch', 'sessions'
    ])
  },
  beforeDestroy() {
    emitter.off('filter-courses');
  }

}

</script>

<style scoped lang="scss">
.VuePagination {
  display: flex;
  justify-content: center;
}
.tab-content {
  margin-top: 20px;
  .active {
    background-color: transparent !important;
  }
}

#dropdownMenuLink {
  color: #706F6F;
  font-size: 16px;
  font-family: "Hanken Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #E1E1E1;
  background: #FFF;

}

.mycommunities {
  color: #706F6F;
  font-size: 16px;
  font-family: "Hanken Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.menu-buttons {

  .btn {
    color: #706F6F;
    background-color: #F6F6F6;
    font-size: 18px;
    font-family: "Hanken Grotesk", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;

    .bi {
      color: var(--buttons_color);
    }
  }

}

.friends {

#nav-tab {
  button {
    border: 1px solid #F6F6F6;

    span {
      color: #B1B1B1;
    }

    &.active {

      background-color: #F6F6F6;

      .material-symbols-outlined {
        color: var(--buttons_color);
      }

      span {
        color: #706F6F;
      }
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

  }
}


.material-symbols-outlined {
font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
  .nav {
    margin-top: 10px;
    flex-direction: column;
    align-items: left;
    gap: 10px;
  }

  .tab-content {
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 480px) {
  
}


}
</style>