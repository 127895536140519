<template>
    <div>
        <div class="box" id="login" ref="login">
            <div class="box-content">
                <h3 class="box-tab">
                    {{this.library_name}}
                    <a class="fr" ref="btn-close" v-on:click="close">
                        <img :src="$url + 'assets/icons/header/x_1.png'" alt="Fechar" style="filter: brightness(0) invert(1);">
                    </a>
                </h3>
                <div class="box-body list">
                    <ul>
                        <li v-if="goBack" v-on:click="clickHandler('goBack')">
                            <ul style="display:flex; align-items:center; justify-content:center">
                                <li>
                                    <div style="height: 50px; width: 50px;background-color: #F1F1F1;border-radius: 5px;display: flex;justify-content: center;align-items: center">
                                        <svg width="25px" height="25px" viewBox="0 0 299.021 299.021">
                                            <path fill="var(--buttons_color)" d="M292.866,254.432c-2.288,0-4.443-1.285-5.5-3.399c-0.354-0.684-28.541-52.949-146.169-54.727v51.977
                                                c0,2.342-1.333,4.48-3.432,5.513c-2.096,1.033-4.594,0.793-6.461-0.63L2.417,154.392C0.898,153.227,0,151.425,0,149.516
                                                c0-1.919,0.898-3.72,2.417-4.888l128.893-98.77c1.87-1.426,4.365-1.667,6.461-0.639c2.099,1.026,3.432,3.173,3.432,5.509v54.776
                                                c3.111-0.198,7.164-0.37,11.947-0.37c43.861,0,145.871,13.952,145.871,143.136c0,2.858-1.964,5.344-4.75,5.993
                                                C293.802,254.384,293.34,254.432,292.866,254.432z"/>
                                        </svg>
                                    </div>
                                </li>
                                <li>
                                    {{ $t('biblioteca.voltar') }}
                                </li>
                            </ul>
                        </li>
                        <li v-for="(item, index) in library" :key="index" v-on:click="clickHandler(item.type == 'item'?item.destination:'folder',item)">
                            <ul style="display:flex; align-items:center; justify-content:center">
                                <li>
                                    <img :src="item.thumb" alt="" style="height: 50px; width: 50px;">
                                </li>
                                <li>
                                    <div>
                                        <span>{{item.name}}</span>
                                        <p v-if="item.type == 'item' && item.subtitle != null">{{item.subtitle}}</p>
                                    </div>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="empty_library">
                    <ul style="height: 50vh; display:flex;justify-content:center; align-items:center; list-style:none;">
                        <li>
                            <ul style="display:flex; align-items:center; justify-content:center;">
                                <div>
                                    <span>{{$t('biblioteca.semGaleria')}}</span>
                                </div>
                            </ul>
                        </li>
                    </ul>

                </div>

                <!-- <div v-if="!videos_active" class="box-body" id="list">
                    <ul>










                    </ul>
                </div> -->
            </div>
        </div>
        <Galeria v-if="library_gallery" ref="Galeria" id="Galeria" :library_gallery="library_gallery" :items="items"></Galeria>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import axios from 'axios';
    import Galeria from "@/components/Galeria";
    export default {
        name: 'Chat',
        computed: {
            ...mapGetters([
                    'logged', 'token', 'id', 'nickname', 'email', 'ref', 'age','switch'
            ]),
        },
        props: ['max', 'audio', 'room', 'participants', 'interactive'],
        data() {
            return {
                empty_library: false,
                library: {},
                goBack: false,
                currentFolder:null,
                items: {},
                library_gallery:false,
                library_name:this.$app.library_title
            }
        },
        components: {
            Galeria
        },
        methods: {
            close(e) {
                e;
                this.$app.conteudo = false;
            },
            getLibrary() {
                this.$app.wait(true)
                let root_ = this
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                }
                axios.get(this.$url_api + 'api/listLibrary', {
                    headers, params:{event_name: this.switch.Nome_Aba??""}
                }).then((response) => root_.getLibraryResponse(response))
                    .catch((error) => root_.getLibraryError(error.response))
            },
            getFolderItems(id) {
                this.$app.wait(true)
                let root_ = this
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                }
                axios.get(this.$url_api + 'api/listLibraryFolder/' + id,
                {headers}
                ).then((response) => root_.getFolderItemsResponse(response))
                .catch((error) => root_.getFolderItemsError(error))
            },
            getLibraryResponse(obj) {
                this.currentFolder = null;
                this.goBack = false;
                if (obj.data.items.length == 0) {
                    this.empty_library = true;
                } else {
                    this.library = obj.data.items
                    this.library_name = obj.data.lib_name
                }
                
                this.$app.wait(false)
            },
            getLibraryError(obj) {
                obj
                this.$app.wait(false)
            },
            getFolderItemsResponse(obj) {
                this.currentFolder = obj.data.folder;
                this.library_name = obj.data.folder_name;
                this.goBack = true;
                if (obj.data.items.length == 0) {
                    this.library = []
                } else {
                    this.library = obj.data.items
                }
                this.$app.wait(false)
            },
            getFolderItemsError(obj) {
                console.log(obj)
                obj
                this.$app.wait(false)
            },
            videosHandler(e) {
                console.log(e.currentTarget.dataset.v);
                if ( e.currentTarget.dataset.v.indexOf('http') !== -1)
                {
                    this.$app.unity_video = e.currentTarget.dataset.v;
                }
            },
            bodyOverflow(change = true) {
                let element = document.getElementsByTagName("body")[0];
                if (change) {
                    element.style.overflow = "hidden";
                    return;
                }
                element.style.overflow = "auto";
            },
            clickHandler(type,data) {
                switch(type)
                {
                    case 'blank':
                        window.open(data.url, '_blank');
                    break;
                    case 'self':
                        window.open(data.url, '_top');
                    break;
                    case 'iframe':
                        this.$app.modalI(data.name,data.url,null);
                    break;
                    case 'download':
                        fetch(data.url)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const a = document.createElement('a');
                            a.href = URL.createObjectURL(blob);
                            a.download = data.name;
                            a.click();
                        }).catch(console.error)
                        return;
                    case 'folder':
                        this.getFolderItems(data.id);
                    break;
                    case 'goBack':
                        if (this.currentFolder===null) {
                            this.getLibrary();
                        }else{
                            this.getFolderItems(this.currentFolder);
                        }
                    break;
                    case 'player':
                        this.getLibraryItemPlayer(data.id,data.name);
                    break;
                }
            },
            getLibraryItemPlayer(id,name) {
                this.$app.wait(true)
                let root_ = this
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                }
                axios.get(this.$url_api + 'api/getItemPlayer/' + id,
                    {headers}
                ).then((response) => root_.getItemPlayerResponse(response,name))
                    .catch((error) => root_.getItemPlayerError(error.response))
            },
            getItemPlayerResponse(obj,name) {
                this.items = obj.data.items;
                this.library_gallery = name;
                this.$app.wait(false);
            },
            getItemPlayerError(obj){
                obj
                this.$app.wait(false)
            },
        },
        created() {
                
        },
        mounted() {
            this.getLibrary()
        },
        updated() {
            
        },
        beforeDestroy() {
            
        },
    }
</script>

<style scoped>
    /*----- general -----*/
    h3
    {
        color: white !important;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        text-transform: uppercase;
    }
    .fr
    {
        float: right;
    }
    .mt-5
    {
        margin-top: 5px;
    }
    /*----- \general\ -----*/



    /*----- box -----*/
    .box
    {
        background-color: rgba(0, 0, 0, .7);
        height: 100%;
        left: 0;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }
    .box-content
    {
        background-color: white;
        border-radius: 4px;
        height: auto;
        left: calc(50% - 250px);
        position: absolute;
        top: 100px;
        width: 500px;
    }
    .box-tab
    {
        background-color: var(--buttons_color);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-sizing: border-box;
        color: #15b5eb;
        padding: 18px 25px;
    }
    .box-body
    {
        box-sizing: border-box;
        max-height: calc(100% - 400px);
        overflow-y: auto;
        padding: 15px 25px;
    }
    /*----- \box\ -----*/



    /*----- list -----*/
    ul, li
    {
        margin: 0;
        padding: 0;
    }
    .list > ul > li
    {
        border-bottom: 1px solid #E4E4E4;
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        padding: 15px 0;
    }
    .list > ul > li:last-child
    {
        border: none;
    }
    .list > ul > li > ul > li
    {
        display: inline-block;
        vertical-align: top;
    }
    .list > ul > li > ul > li:nth-child(1)
    {
        width: 50px;
    }
    .list > ul > li > ul > li:nth-child(2)
    {
        box-sizing: border-box;
        color: var(--buttons_color);
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        padding: 0 15px;
        width: calc(100% - 60px);
    }
    .list > ul > li > ul > li:nth-child(2) span
    {
        text-transform: uppercase;
    }

    .list > ul > li > ul > li:nth-child(2) p
    {
        margin: 0;
    }
    /*----- \list\ -----*/



    @media screen and (max-width: 730px)
    {
        .box-content
        {
            border-radius: 0;
            left: 0;
            min-height: 100%;
            top: 0;
            width: 100%;
        }
    }
</style>