    <template>
      <div class="dev-mode" :style="{ display: isDevMode ? 'block' : 'none' }">
        <h6 @click="devModeSwapColor">HEALTH MODE</h6>
        <div class="blink-wrapper">
          <div v-for="i in devTotalBoxes" :key="i" class="blinking-box" :style="devModeGetBoxStyle(i)"></div>
        </div>
        <div class="feedback">
          <span class="liner"></span>
          <span>Access Test</span>
          <code> Domain Access : <b>{{ currentDomain }}</b></code>
          <code> Virtual.Town Domain : <b>{{ isVirtualTown() }}</b></code>
          <div v-for="(url, key) in domains" :key="key" class="status">
            <code :class="getClass(key)">
              <div>
                {{ key.charAt(0).toUpperCase() + key.slice(1) }}: <b>{{ url }}</b>
              </div>
              <span style="float: right">
                <i :class="getStatusIcon(key)" class="status-icon"></i>
                <button v-if="statuses[key] !== 'loading'" class="ui mini compact icon button" @click="retryStatus(key)"><i class="redo icon"></i></button>
                <a :href="`https://${url}`" target="_blank">
                  <button v-if="statuses[key] !== 'loading'" class="ui mini compact icon button" @click="retryStatus(key)"><i class="external alternate icon"></i></button>
                </a>
              </span>
            </code>
          </div>
        </div>
        <span class="liner"></span>
        <div class="feedback">
          <span class="liner"></span>
          <span>Performance Test</span>
          <code class="success">
            Internet Speed : <b>{{ internetSpeed }}</b>
            <span style="float: right">
              <button v-if="internetSpeed !== 'loading'" class="ui mini compact icon button" @click="measureDownloadSpeed()"><i class="redo icon"></i></button>
              <a href="https://www.speedtest.net/pt" target="_blank">
                <button v-if="internetSpeed !== 'loading'" class="ui mini compact icon button"><i class="external alternate icon"></i></button>
              </a>
            </span>
          </code>
        </div>
      </div>
    </template>

    <script>
    import axios from "axios";
    const isReachable = require('is-reachable');

    export default {
      name: 'HeathMode',
      data() {
        return {
          currentDomain: window.location.hostname,
          isDevMode: true,
          devTotalBoxes: 39,
          domains: {
            chat: '',
            api: '',
            unity: '',
            RPM: 'readyplayer.me',
            AvatarSDK: 'avatarsdk.com'
          },
          statuses: {
            chat: 'loading',
            api: 'loading',
            unity: 'loading',
            RPM: 'loading',
            AvatarSDK: 'loading'
          },
          internetSpeed: 'loading'
        }
      },
      mounted() {
        this.updateURL();
        this.checkAllStatuses();
        this.measureDownloadSpeed();
        console.log('[Julilog] - Starting health Mode');
        window.addEventListener('keydown', this.toggleDevMode);
      },
      beforeDestroy() {
        window.removeEventListener('keydown', this.toggleDevMode);
      },
      methods: {
        async measureDownloadSpeed() {
          const startTime = new Date().getTime();
          let endTime;

          try {
            const response = await fetch(`/img/teste_image.png`, {
              method: 'GET'
            });

            const blob = await response.blob();
            endTime = new Date().getTime();

            const fileSize = blob.size; // Tamanho do arquivo em bytes
            const duration = (endTime - startTime) / 1000; // Tempo em segundos

            // Corrigindo o cálculo: de bytes para bits
            const speedBps = (fileSize * 8) / duration; // Bits por segundo
            const speedKbps = speedBps / 1024; // Kilobits por segundo
            const speedMbps = speedKbps / 1024; // Megabits por segundo

            this.internetSpeed = speedMbps.toFixed(2) + ' Mbps';

          } catch (error) {
            console.error('Erro ao medir a velocidade:', error.message || error);
            this.internetSpeed = 'Erro ao medir a velocidade';
          }
        },
        toggleDevMode(e) {
          if (e.ctrlKey && e.key === "'") {
            this.isDevMode = !this.isDevMode;
          }
        },
        updateURL() {
          if (this.isVirtualTown()) {
            if(this.currentDomain.includes("local.virtual.town")) {
              this.currentDomain = "metaverso.virtual.town"
            }
            this.domains.chat = `chat-${this.currentDomain}`;
            this.domains.api = `api-${this.currentDomain}`;
            this.domains.unity = `unity-${this.currentDomain}`;
          } else {
            this.domains.chat = `chat.${this.currentDomain}`;
            this.domains.api = `api.${this.currentDomain}`;
            this.domains.unity = `unity.${this.currentDomain}`;
          }
        },
        isVirtualTown() {
          return this.currentDomain.includes("virtual.town");
        },
        async checkAllStatuses() {
          for (let key in this.domains) {
            try {
              const result = await this.checkStatus(key, this.domains[key]);
              if (result) {
                this.$set(this.statuses, key, 'success');
              } else {
                this.$set(this.statuses, key, 'error');
              }
            } catch (error) {
              console.log(`${key}: ${error.response ? error.response.status : error.message}`);
              this.$set(this.statuses, key, 'error');
            }
          }
        },
        async checkStatus(key, url) {
          this.$set(this.statuses, key, 'loading');
          switch (key) {
            case 'api': {
              const apiResponse = await axios.get(`https://${url}/api/setup`);
              return apiResponse.status === 200;
            }
            case 'RPM': {
              const response = await axios.get(`https://models.readyplayer.me/65973004c4999dd3c89cd7db.glb`, { timeout: 5000 });
              if (response.status === 200 || response.status < 400) {
                console.log(`Fetch request successful to ${url}`);
                return true;
              } else {
                console.log(`Fetch request failed to ${url}`);
                return false;
              }
            }
          case 'unity':
          case 'chat': {
              // Usando fetch para lidar com problemas de CORS ou configuração do servidor
              const response = await fetch(`https://${url}`, { method: 'GET', mode: 'no-cors' });

              // response.ok verifica se o status é 200-299
              if (response.ok || response.status === 0) { // Status 0 pode acontecer por causa de no-cors, mas deve ser tratado como sucesso
                  console.log(`Fetch request successful to ${url}`);
                  this.$set(this.statuses, key, 'success'); // Atualiza o status para 'success'
                  return true;
              } else {
                  console.log(`Fetch request failed to ${url}`);
                  this.$set(this.statuses, key, 'error'); // Atualiza o status para 'error'
                  return false;
              }
          }
            default: {
              const genericResponse = await isReachable(`https://${url}`);
              return genericResponse;
            }
          }
        },
        retryStatus(key) {
          this.checkStatus(key, this.domains[key])
            .then(result => {
              if (result) {
                this.$set(this.statuses, key, 'success');
              } else {
                this.$set(this.statuses, key, 'error');
              }
            })
            .catch(error => {
              console.log(`${key}: ${error.response ? error.response.status : error.message}`);
              this.$set(this.statuses, key, 'error');
            });
        },
        getClass(key) {
          return {
            loading: this.statuses[key] === 'loading',
            success: this.statuses[key] === 'success',
            error: this.statuses[key] === 'error'
          };
        },
        getStatusIcon(key) {
          switch (this.statuses[key]) {
            case 'loading':
              return 'ui active inline mini loader';
            case 'success':
              return 'ui green check icon';
            case 'error':
              return 'ui negative close icon';
          }
        },
        devModeToggleBoolean(config) {
          if (Object.prototype.hasOwnProperty.call(this, config)) {
            this[config] = !this[config];
          }
        },
        devModeSwapColor() {
          const devModeElement = this.$el.querySelector('.dev-mode');
          if (devModeElement) {
            devModeElement.classList.toggle('amber');
          }
        },
        devModeGetBoxStyle(index) {
          return {
            animationDelay: `${0.1 * (index - 1)}s`
          };
        }
      }
    }
    </script>

    <style scoped lang="scss">
    .dev-mode {
      position: fixed;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .85);
      color: #00FF00;
      font-family: 'Press Start 2P', 'IBM VGA 8x16', 'Courier New', Courier, monospace;
      padding: 10px;
      font-size: 14px;
      line-height: 16px;
      border: 1px solid #00FF00;
      border-bottom: none;
      border-right: none;
      box-shadow: -1px -1px 0px 1px rgba(0, 0, 0, 1);
      width: 500px;
      z-index: 9999999999999999;

      .liner {
        height: 1px;
        background: lime;
        margin: 8px 0;
      }

      h6 {
        margin-bottom: 10px;
        font-weight: 700;
        border: 1px solid #00FF00;
        font-size: 16px;
        display: inline-block;
        padding: 1px 3px 0 5px;
        line-height: 16px;
        color: #000;
        background: #00FF00;
        cursor: default;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: relative;
      }

      ul {
        position: relative;

        li {
          display: flex;
          gap: 10px;
          margin: 2px 0;

          &.liner {
            height: 1px;
            background: lime;
            margin: 8px 0;
          }

          span {
            flex: 1;
          }
        }
      }

      a {
        cursor: pointer;
      }

      .feedback {
        display: flex;
        gap: 4px;
        flex-flow: column;

        .status {
            display: flex;
            flex-direction: column;
            height: 2.5em;
        }

        code {
          border: 1px solid lime;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          overflow-x: hidden;
          background: black;
          padding: 2px;
          color: white;
          scrollbar-width: thin;
          scrollbar-color: #000 lime;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          &.loading {
            position: relative;
            height: 2.5em;
          }

          &.success {
            background: lime;
            color: #000;
          }

          &.error {
            border: 1px solid red;
            background: red;
            color: #000;
          }

          &::-webkit-scrollbar {
            width: 12px;
          }

          &::-webkit-scrollbar-track {
            background: lime;
          }

          &::-webkit-scrollbar-thumb {
            background: #000;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #000;
          }
        }
      }

      &.amber {
        color: #FFBF00;
        border-color: #FFBF00;

        h6 {
          border-color: #FFBF00;
          background: #FFBF00;
        }

        ul {
          li {
            &.liner {
              background: #FFBF00;
            }
          }
        }

        .feedback {
          code {
            border: 1px solid #FFBF00;
            background: #FFBF00;
            scrollbar-color: #000 #FFBF00;

            &::-webkit-scrollbar-track {
              background: #FFBF00;
            }
          }
        }

        .blink-wrapper {
          .blinking-box {
            background: #FFBF00;
          }
        }
      }

      .blink-wrapper {
        display: flex;
        gap: 1px;
        position: absolute;
        top: 30px;

        .blinking-box {
          background: lime;
          width: 4px;
          height: 4px;
          animation: blink 3.5s ease infinite;
          width: 2px;
          height: 2px;
          animation: blink 2.5s ease infinite;
        }
      }

      @keyframes blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    </style>
