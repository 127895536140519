<template>
    <div ref="answerSheetModal" id="answerSheetModal" class="ui translucent suimodal background-transparent answer-sheet-modal"
         :class="{small:!checkMobile,overlay:checkMobile,fullscreen:checkMobile}" @click.stop>
                <i class="icon-header arrow left icon back" @click="backToTabs()" v-if="isQuestion" />
                <i class="icon-header close icon inside" />
                <div class="header terms-title">
                    {{ $t('answersheet.answer_sheet') }}
                </div>
                <div class="scrolling content">
                    <div v-if="isQuestion" class="question-sheet">
                        <div class="your-answer ui" :class="{red:correct_answer.id !== answer.id,green:correct_answer.id === answer.id}">
                            <i class="icon" :class="{check:correct_answer.id === answer.id,times:correct_answer.id !== answer.id}" />
                            {{ correct_answer.id !== answer.id ? $t('answersheet.your_answer_incorrect') : $t('answersheet.your_answer_correct') }}
                        </div>
                        <div class="question-warp">
                            <div class="question">
                                <span class="primary-ball">{{ questions_list[current_question].index+1 }}</span>
                                {{ questions_list[current_question].name }}
                            </div>
                            <div class="answer-sheet-warp">
                                <div class="ui title answer-sheet-title">
                                    {{ $t('answersheet.correct_answer') }}:
                                </div>
                                <div class="answer-sheet-description">
                                    {{ correct_answer.name }}
                                </div>
                                <div v-if="correct_answer.id !== answer.id" class="ui title answer-sheet-title">
                                    {{ $t('answersheet.your_answer') }}:
                                </div>
                                <div v-if="correct_answer.id !== answer.id" class="answer-sheet-description">
                                    {{ answer.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="ui top secondary pointing equal width fluid menu">
                            <a class="item" :class="{active:show_questions === 'all'}" @click="changeTab('all')">
                                {{ $t('answersheet.all') }}: {{ questions_list.length }}
                            </a>
                            <a class="item" :class="{active:show_questions === 'correct'}" @click="changeTab('correct')">
                                {{ $t('answersheet.correct') }}: {{ questionsListCorrect.length }}
                            </a>
                            <a class="item" :class="{active:show_questions === 'incorrect'}" @click="changeTab('incorrect')">
                                {{ $t('answersheet.incorrect') }}: {{ questionsListIncorrect.length }}
                            </a>
                        </div>
                        <div class="ui bottom attached segment basic">
                            <div class="d-flex alignVertical">
                                <div class="title-quantity" v-if="show_questions === 'all'">
                                    {{ $t('answersheet.all_list',{correct:questionsListCorrect.length,incorrect:questionsListIncorrect.length,total:questions_list.length}) }}
                                </div>
                                <div class="title-quantity" v-if="show_questions === 'correct'">
                                    {{ $t('answersheet.correct_list',{correct:questionsListCorrect.length,total:questions_list.length}) }}
                                </div>
                                <div class="title-quantity" v-if="show_questions === 'incorrect'">
                                    {{ $t('answersheet.incorrect_list',{incorrect:questionsListIncorrect.length,total:questions_list.length}) }}
                                </div>
                                <div v-if="questionList.length>0">
                                    {{ $t('answersheet.check_sheet')}}:
                                </div>
                            </div>
                            <div class="ui list list_questions">
                                <div class="item" v-for="(question,index) in questionListWithPage" :key="index" @click="current_question=question.index">
                                    <div class="content">
                                        <span class="primary-ball">{{ question.index+1 }}</span>
                                        <span class="question">{{ question.name }}</span>
                                        <i class="icon" :class="{check:question.question_correct,times:!question.question_correct}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <div v-if="isQuestion" class="question-sheet-actions">
                        <button class="ui primary button basic" :class="{disabled:current_question === 0}" @click="previousQuestion()">
                            <span class="material-icons" v-text="'chevron_left'" />
                            {{ $t('answersheet.previous') }}
                        </button>
                        <div class="ui divider" />
                        <button class="ui primary button basic" :class="{disabled:current_question === questions_list.length-1}" @click="nextQuestion()">
                            {{ $t('answersheet.next') }}
                            <span class="material-icons" v-text="'chevron_right'" />
                        </button>
                    </div>
                    <div v-else-if="maxPages>1">
                        <div class="ui pagination menu">
                            <a class="icon item" :class="{active:current_page === page}" v-for="page in maxPages" @click="current_page = page" :key="page">
                                {{ page }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
    name: "AnswerSheet",
    props:{
        form_selected:{
            type:Array,
            default:()=>[]
        },
        form_questions:{
            type:Array,
            default:()=>[]
        },
    },
    computed:{
        ...mapGetters(['checkMobile']),
        correct_answer(){
            if(this.current_question !== null)
                return this.questions_list[this.current_question].options.filter(option=>option.correta === true)[0];
            return {};
        },
        answer(){
            if(this.current_question !== null)
                return this.form_selected[this.current_question];
            return {};
        },
        questionsListCorrect(){
            return this.questions_list.filter((question,index)=>{
                let answer = this.form_selected[index];
                return answer.correta;
            });
        },
        questionsListIncorrect(){
            return this.questions_list.filter((question,index)=>{
                let answer = this.form_selected[index];
                return !answer.correta;
            });
        },
        questionList(){
            if(this.show_questions === 'all')
                return this.questions_list;
            if(this.show_questions === 'correct')
                return this.questionsListCorrect;
            if(this.show_questions === 'incorrect')
                return this.questionsListIncorrect;
            return [];
        },
        questionListWithPage(){
            let start = (this.current_page-1)*this.max_per_page;
            let end = start+this.max_per_page;
            return this.questionList.slice(start,end);
        },
        maxPages(){
            return Math.ceil(this.questionList.length/this.max_per_page);
        },
        isQuestion(){
            return this.current_question !== null;
        }
    },
    data(){
        return {
            current_question:null,
            show_questions: "all",//all,correct,incorrect
            questions_list:[],
            current_page:1,
            max_per_page: 5
        }
    },
    mounted() {
        let vm = this;
        this.questions_list = this.form_questions.map((question,index)=>{
            return {
                ...question,
                index,
                question_correct:Boolean(vm.form_selected[index].correta)
            }
        });
        window.$(this.$refs.answerSheetModal).suimodal({
            closable: false,
            onHidden:()=>{
                this.$emit('close');
            }
        }).suimodal('show');
    },
    methods:{
        nextQuestion(){
            if (this.current_question < this.questions_list.length-1)
                this.current_question++;
        },
        previousQuestion(){
            if (this.current_question > 0)
                this.current_question--;
        },
        backToTabs(){
            this.current_question = null;
        },
        close(){
            window.$(this.$el).suimodal('hide');
        },
        changeTab(tab){
            this.show_questions = tab;
            this.current_page = 1;
        }
    }
}
</script>
<style lang="scss" scoped>
.suimodal{
    padding: 4rem 2rem;
    .icon-header {
        cursor: pointer;
        color: white!important;
        &.close{
            top: 4rem;
            right: 3rem;
        }
        &.back{
            position: absolute;
            padding: .625rem 0 0 0;
            top: 4rem;
            left: 3rem;
            width: 2.25rem;
            height: 2.25rem;
            margin: 0 0 0 .25rem;
        }
    }
}
.ui.pointing.fluid.menu {
    .item {
        font-family: Exo, sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5;
        text-align: left;
        opacity: .5;
        color: white;
        &:hover {
            color: white;
            opacity: .8;
        }
        &.active {
            color: white;
            opacity: 1;
        }
    }
}
.ui.segment{
    font-family: Exo, sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
    background: transparent;
    color: white;
    .title-quantity{
        margin-right: .5rem;
        font-weight: 600;
        text-align: center;
    }
    .list_questions{
        .item{
            border-bottom: 1px solid #FFFFFF26;
            padding: 20px 12px 20px 12px;
            cursor: pointer;
            &:hover{
                border-bottom: 1px solid var(--buttons_color, #37B2DE);
                border-top: 1px solid var(--buttons_color, #37B2DE);
                background: rgba(var(--buttons_color-r), var(--buttons_color-g), var(--buttons_color-b), .1);

            }
            .content{
                color: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .primary-ball{
                    font-family: Exo, sans-serif;
                    font-size: .8rem;
                    font-weight: 600;
                    line-height: 1;
                    text-align: center;
                    margin-right: .75rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--fonts_color);
                    background: var(--buttons_color);
                }
                .question{
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .icon{
                    font-size: .5rem;
                    line-height: 1;
                    margin-left: 1.5rem;
                    width: 1rem;
                    height: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    border-radius: 50%;
                    &.check{
                        background: #4CAF50
                    }
                    &.times {
                        background: #EB3B3B;
                    }
                }
            }
        }
    }
}
.ui.pagination.menu{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: transparent;
    border-radius: 0;
    border:none;
    box-shadow: none;
    &:before,&:after{
        display: none;
    }
    .item {
        font-family: Exo, sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        color: white;
        border-radius: .5rem;
        padding: .5rem .8rem;
        min-width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border:none;
        &:before{
            display: none;
        }
        &:hover {
            color: white;
            opacity: .8;
        }

        &.active {
            color: black;
            opacity: 1;
            background: white;
        }
    }
}
.question-sheet{
    font-family: Exo, sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
    color: white;
    .your-answer{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;
        padding: .5rem .75rem;
        margin-bottom: 1.5rem;
        &.red{
            background: #EB3B3B1F;
        }
        &.green{
            background: #4CAF501F;
        }
        .icon{
            font-size: .5rem;
            line-height: 1;
            width: 1rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            border-radius: 50%;
            &.check{
                background: #4CAF50
            }
            &.times {
                background: #EB3B3B;
            }
        }
    }
    .question-warp{
        padding: .5rem .75rem;
        max-height: min(70vh,400px);
        overflow-y: auto;
        .question{
            display: flex;
            text-align: left;
            font-weight: 600;
            gap: .75rem;
            .primary-ball{
                font-size: .8rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                max-width: 1.5rem;
                min-width: 1.5rem;
                max-height: 1.5rem;
                min-height: 1.5rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--fonts_color);
                background: var(--buttons_color);
            }
        }
        .answer-sheet-warp{
            margin-left: 2.25rem; //.75rem + 1.5rem (gap + primary-ball)
            .answer-sheet-title{
                margin-top: 1rem;
                margin-bottom: .5rem;
                font-weight: 600;
                text-align: left;
            }
            .answer-sheet-description{
                text-align: left;
            }
        }
    }
}
.question-sheet-actions{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 0 1.5rem;
    .ui.divider{
        margin: 0;
        flex: 1;
    }
//    ui primary button basic disabled
    .ui.primary.button.basic{
        font-family: Exo, sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5;
        color: white;
        background: transparent;
        padding: .5rem 1.25rem;
        border-radius: 3rem;
        box-shadow: 0 0 0 1px white inset;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        span{
            font-size: 1rem;
            line-height: 1;
            color: white;
        }
        &.disabled{
            opacity: .5;
            span{
                background: transparent;
            }
        }
    }
}
</style>
<style lang="scss">
#answerSheetModal{
    scrollbar-width: initial;
    scrollbar-color: initial;
    * {
        scrollbar-width: initial;
        scrollbar-color: initial;
    }

    @-moz-document url-prefix() {
        scrollbar-width: thin;
        scrollbar-color: var(--buttons_color) rgba(0, 0, 0, 0.5);
        * {
            scrollbar-width: thin;
            scrollbar-color: var(--buttons_color) rgba(0, 0, 0, 0.5);
        }
    }
    &::-webkit-scrollbar,*::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track,*::-webkit-scrollbar-track {
        background: rgba(50, 50, 50, 0.5);
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb,*::-webkit-scrollbar-thumb {
        background-color: var(--buttons_color);
        border-radius: 5px;
    }
}
</style>