<template>
    <div class="box-bg" style="z-index: 9999;" id="privacidade" ref="privacidade">
        <div class="modal lightbox show">
            <div class="modal-contents">
                <div class="modal-header" style="width: 78%;justify-content: space-between;margin: 45px auto;">
                    <h3 class="text-red" style="
            font-family: 'Action NBA Cond Web light';
            font-weight: bold;
            font-style: italic;
            font-size: 6.3vmin;
            letter-spacing: 0.04em;
            line-height: 100%;
            text-transform: uppercase;">POLÍTICA DE PRIVACIDADE</h3>
                    <img v-on:click="$app.modal_PoliticaDePrivacidade=false" style="cursor: pointer;" class="close" :src="$url+'/assets/icons/modals/xizinnho.svg'" alt="Fechar">
                </div>
                <div class="modal-body" style="text-align: justify;">
            <span class="text">
                Última atualização: 11 de junho de 2021<br>
                Nós, a NBA BRASIL BASKETBALL EMPREENDIMENTOS ESPORTIVOS LTDA., pessoa jurídica de direito privado, inscrita no CPNJ sob o nº 15.418.905/0001-53 (“NBA” ou “nós”), desenvolvemos junto à VRGlass Tecnologia LTDA, sob licenciamento da Plataforma Virtual Town  a versão customizada da <strong>NBA HOUSE DIGITAL</strong> (“WebApp”), uma plataforma online voltada para o público brasileiro fã de basquetebol, que oferece um universo virtual para ser explorado por meio de games e atrações, de forma segura, por meio de um avatar customizado e interação com outros usuários (“Evento”).<br>
                A presente Política de Privacidade (“Política”) descreve a forma como a NBA trata os Dados Pessoais coletados, de forma direta ou indireta, dos usuários do Evento.<br>
                A NBA leva muito a sério a proteção dos Dados Pessoais dos usuários do Evento. Por esta razão, não haverá qualquer forma de tratamento dos Dados Pessoais dos usuários de forma diversa ou para outros fins que não aqueles explicitados mais adiante nesta Política e em conformidade com a Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados” ou “LGPD”). Assim, por meio desse documento, a NBA busca informar as medidas técnicas e organizacionais empregadas para garantir a mais completa proteção dos Dados Pessoais dos usuários do Evento e garantindo maior transparência.<br>
                <strong>RESPONSÁVEL PELO TRATAMENTO</strong><br>
                A NBA BRASIL BASKETBALL EMPREENDIMENTOS ESPORTIVOS LTDA será, nos termos da Legislação Aplicável de Proteção de Dados, o Controlador dos seus Dados Pessoais Tratados no âmbito desta Política, conforme explicaremos de forma detalhada adiante.<br>
                <strong>DADOS PESSOAIS</strong><br>
                São considerados Dados Pessoais todas as informações que possam identificar ou tornar identificável uma pessoa, como por exemplo, seu nome, e-mail, endereço IP e endereço residencial.<br>
                Os Dados Pessoais tratados pela NBA são limitados ao mínimo necessário para a correta e legítima realização do Evento, não havendo qualquer coleta de Dados Pessoais considerados excessivos. Ainda, a NBA se compromete com os seguintes princípios previstos na LGPD: finalidade, adequação, necessidade, livre acesso, qualidade dos Dados Pessoais, transparência, segurança, prevenção, não discriminação e prestação de contas.<br>
                <strong>COMO TRATAMOS OS DADOS PESSOAIS</strong><br>
                Durante o uso do WebApp, o usuário deverá informar seus Dados Pessoais única e exclusivamente para fins de compra de ingresso, cadastro, login no WebApp e para posterior participação no Evento.<br>
                Não há compras nem transações financeiras dentro do Evento, apenas redirecionamento por terceiros, que possuem suas respectivas políticas de privacidade. Nós recomendamos a leitura de tais políticas pelo usuário antes de realizar qualquer transação. Além disso, o usuário também poderá ter seus Dados Pessoais coletados indiretamente, por meio de ferramentas que geram análises estatísticas (analytics) sobre a usabilidade do WebApp, como informações sobre erro, quantidade de usuários on-line e funcionalidades mais utilizadas.<br>
                Prezando pela transparência e proteção de Dados Pessoais de seus usuários, encontram-se abaixo as finalidades para as quais a NBA trata Dados Pessoais, bem como as respectivas bases legais de acordo com a LGPD:<br>
                <table style="text-align: -moz-center;text-align: center;text-align: -webkit-center">
                    <thead>
                        <tr>
                            <th style="width: 30%">FINALIDADE</th>
                            <th style="width: 30%">DADO PESSOAL COLETADO</th>
                            <th style="width: 40%">BASE LEGAL<br>(em consonância com o art. 7º da LGPD)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Pagamento, cadastro e login/acesso ao Evento</td>
                            <td>Nome/apelido, e-mail, data de nascimento, informações financeiras</td>
                            <td>Execução de contrato ou de procedimentos preliminares.<br>
                                Em se tratando de menores de 12 anos, o consentimento do responsável deve ser obtido
                            </td>
                        </tr>
                        <tr>
                            <td>Envio de comunicações essenciais ou solicitadas pelo usuário</td>
                            <td>Nome e e-mail</td>
                            <td>Execução de contrato ou de procedimentos preliminares</td>
                        </tr>
                        <tr>
                            <td>Registro de acesso ao WebApp pelo usuário</td>
                            <td>Geolocalização, e-mail, data de nascimento, nome/apelido</td>
                            <td>Legítimo interesse</td>
                        </tr>
                        <tr>
                            <td>Permitir a criação de avatares pelo usuário</td>
                            <td>Nome/apelido e data de nascimento</td>
                            <td>Execução de contrato ou de procedimentos preliminares</td>
                        </tr>
                        <tr>
                            <td>Envio de material de marketing sobre o Evento e outras áreas de possível interesse do usuário, além de elaboração de analytics</td>
                            <td>Nome/apelido, e-mail, endereço IP, perfis comportamentais e histórico de uso</td>
                            <td>Consentimento</td>
                        </tr>
                    </tbody>
                </table>
                <strong>
                    A NBA se compromete a tratar Dados Pessoais de crianças em linha com a LGPD e sempre o fazer mediante o consentimento específico e em destaque de ao menos um dos pais ou responsável legal pela criança e somente para os usos e finalidades previstos nesta Política. Recomendamos a leitura dos
                    <a v-on:click="openTermos">Termos de Uso</a> para maior entendimento sobre a classificação etária do Evento.
                </strong><br>
                De toda forma, caso o usuário queira obter informações mais detalhadas sobre as atividades destacadas acima, poderá entrar em contato com a NBA por meio dos canais de contato aqui disponibilizados. <br>
                <strong>COMPARTILHAMENTO DE DADOS PESSOAIS</strong><br>
                A NBA poderá operar em conjunto com outras empresas em diversas atividades, inclusive para hospedagem de Dados Pessoais, desenvolvimento de estatísticas (analytics) e envio de comunicações. Adotaremos, sempre que for possível, mecanismos de anonimização desses Dados Pessoais, visando preservar ao máximo a privacidade e proteção dos mesmos. Desta forma, nos reservamos o direito de compartilhar os Dados Pessoais tão somente com os terceiros abaixo indicados:<br>
                <ul>
                    <li class="none-before"><strong>(1)	Fornecedores:</strong> empregamos outras empresas para realizar trabalhos em nosso nome e, para tanto, podemos compartilhar seus Dados Pessoais com eles para a integral disponibilização do Evento. Por exemplo, usamos serviços de hospedagem para armazenar a nossa base de Dados Pessoais e compartilhamos suas informações com provedores de tecnologia. Nossos fornecedores somente são autorizados a utilizar os Dados Pessoais para os fins específicos que eles foram contratados, portanto, eles não irão utilizar os seus Dados Pessoais para outras finalidades, além da prestação de serviços previstos contratualmente.</li>
                    <li class="none-before"><strong>(2) Parceiros da NBA:</strong> a NBA pode estabelecer parcerias para prestar seus serviços e, para tanto, poderá compartilhar Dados Pessoais dos usuários com a finalidade de oferecimento de produtos e serviços no Evento.</li>
                    <li class="none-before"><strong>(3)	Analytics:</strong> os Dados Pessoais armazenados pela NBA podem vir a ser utilizados para fins de estatísticas (analytics), com a finalidade de a NBA compreender como os usuários interagem com o WebApp. Estes Dados Pessoais são pseudonimizados e têm por fim compreender melhor como é a utilização do WebApp para melhorar os produtos e serviços oferecidos e direcioná-los ao interesse do usuário.</li>
                    <li class="none-before"><strong>(4)	Marketing e envio de comunicações não essenciais:</strong> a NBA utiliza empresas de marketing para o envio de comunicações e direcionamento de conteúdos e anúncios de acordo com os interesses do usuário. Para tanto, alguns Dados Pessoais podem ser compartilhados entre a NBA e essas empresas, principalmente identificadores únicos, endereços IP, cookies, dados comportamentais e dados sobre o histórico de uso, que podem ser utilizados para medir a eficiência da publicidade online. O usuário poderá optar por receber tal publicidade, por meio de consentimento prévio, expresso e informado no momento do cadastro, o qual poderá ser revogado a qualquer tempo, mediante solicitação pelo e-mail
                        <a href="mailto:ihungria@nba.com">ihungria@nba.com</a>. As agências e empresas contratadas pela NBA para essas finalidades estarão comprometidas no mesmo nível de proteção de Dados Pessoais adotado por nós; isso inclui a obrigação de não usarem seus Dados Pessoais para qualquer outra finalidade que não a finalidade contratada, além de obrigações de confidencialidade e padrões de segurança da informação. Sempre envidamos esforços comercialmente razoáveis para garantir que nossos prestadores de serviços mantenham seus Dados Pessoais protegidos e seguros.</li>
                    <li class="none-before"><strong>(5)	Para resguardar e proteger direitos da NBA:</strong> a NBA reserva-se ao direito de compartilhar Dados Pessoais quando necessários para (i) o cumprimento de obrigação legal ou ordem judicial; (ii) fazer cumprir esta Política, os <a v-on:click="openTermos">Termos de Uso</a> e outros acordos relevantes; e (iii) proteger os direitos, propriedades ou segurança da NBA, seus funcionários, usuários ou terceiros.</li>
                    <li class="none-before"><strong>(6)	Empresas Afiliadas:</strong> a NBA pode compartilhar Dados Pessoais entre empresas afiliadas para aprimorar os serviços e produtos da NBA. Todas as empresas estão sujeitas a obrigações contratuais nas quais se comprometem a tratar os Dados Pessoais dos usuários com o mesmo nível de segurança que a NBA e de acordo com as legislações aplicáveis de proteção de Dados Pessoais.</li>
                </ul>
                A NBA se esforça e age de boa-fé para proteger os seus Dados Pessoais. Quando compartilhamos seus Dados Pessoais com qualquer dos terceiros indicados acima, limitamos o compartilhamento apenas aos Dados Pessoais necessários para o exercício de suas funções e asseguramos contratualmente que os referidos Dados Pessoais sejam utilizados somente na extensão necessária para a prestação dos serviços em nosso nome ou para a observância dos requisitos legais. <br>
                Da mesma forma, exigimos que eles se comprometam ao mesmo nível de proteção para com os seus Dados Pessoais que a NBA teria se os estivesse tratando diretamente. Isso inclui, mas não se limita à obrigação de não usarem seus Dados Pessoais para qualquer outra finalidade que não a finalidade contratada, além de obrigações de confidencialidade e padrões de segurança da informação. Além disso, sempre envidamos esforços razoáveis para garantir que nossos prestadores de serviços mantenham seus Dados Pessoais protegidos e seguros.<br><br>
                <strong>TRANSFERÊNCIA INTERNACIONAL DE DADOS PESSOAIS</strong><br>
                Embora o Evento seja destinado a pessoas localizadas no Brasil, aplicando-se, portanto, as leis brasileiras relacionadas à proteção de Dados Pessoais, os Dados Pessoais que coletamos podem ser transferidos para os Estados Unidos da América, Alemanha e China. Essa transferência internacional ocorre, principalmente, para fins de hospedagem em nuvem dos servidores do WebApp.<br>
                Desta maneira, ao fornecer Dados Pessoais para a NBA para fins de participação no Evento, o usuário,       ou responsável legal quando aplicável, está da transferência internacional de tais Dados Pessoais para os bancos de dados localizados nos Estados Unidos da América, na Alemanha e na China. Estes Dados Pessoais poderão estar sujeitos à legislação local e às regras pertinentes relativas à proteção de Dados Pessoais de cada país.<br>
                <strong>DIREITOS DOS USUÁRIOS COMO TITULARES DE DADOS PESSOAIS</strong><br>
                O usuário, na condição de titular de Dados Pessoais, sempre poderá optar em não divulgar seus Dados Pessoais para a NBA, com a ressalva de que e,<br>
                sses Dados Pessoais podem ser necessários para que ele possa usufruir integralmente de alguns dos recursos do Evento. Em todo caso, o usuário sempre terá direitos relativos à privacidade e à proteção dos seus Dados Pessoais.<br>
                Nesse sentido, expomos abaixo uma breve explicação sobre os direitos do titular de Dados Pessoais previstos na LGPD:<br>
                <ul>
                    <li><strong>Confirmação da existência de tratamento:</strong> este direito permite que o usuário possa requisitar e receber uma confirmação sobre a existência de tratamento de seus Dados Pessoais.</li>
                    <li><strong>Acesso aos Dados Pessoais:</strong> este direito permite que o usuário possa requisitar e receber uma cópia dos Dados Pessoais tratados pela NBA. Para ajudar na sua privacidade e segurança, podemos adotar medidas razoáveis para verificar a sua identidade, como por exemplo, o uso de senha e identificação de usuário antes de concedermos acesso aos Dados Pessoais.</li>
                    <li><strong>Correção de Dados Pessoais incompletos, inexatos ou desatualizados:</strong> este direito permite que o usuário, a qualquer momento, solicite a correção e/ou retificação dos Dados Pessoais caso identifique que alguns deles estão incorretos. Contudo, para ser efetivada essa correção, a NBA precisará checar a validade dos Dados Pessoais que lhes são fornecidos.</li>
                    <li><strong>Anonimização, bloqueio ou eliminação dos Dados Pessoais desnecessários, excessivos ou tratados em desconformidade com a LGPD:</strong> este direito permite que seja solicitada a anonimização, o bloqueio ou a eliminação dos Dados Pessoais da base de dados da NBA. Todos os Dados Pessoais coletados serão anonimizados, bloqueados ou eliminados dos nossos servidores quando assim requisitado ou quando estes não forem mais necessários ou relevantes para o oferecimento dos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual necessidade de retenção dos Dados Pessoais para o cumprimento de uma obrigação legal para resguardo de direitos da NBA.</li>
                    <li><strong>Solicitação de portabilidade:</strong> a NBA permite ao usuário ou a terceiros por ele nomeados, obter acesso aos Dados Pessoais tratados pela NBA em formato estruturado e interoperável.</li>
                    <li><strong>Direito de não fornecer ou revogar o consentimento a qualquer momento:</strong> o usuário tem o direito de não fornecer ou revogar o seu consentimento, no entanto, isso não afetará a legalidade de qualquer tratamento anteriormente realizado. Se o usuário não fornecer ou revogar o seu consentimento, talvez a NBA não possa fornecer-lhe determinados produtos e serviços. E, se for este o caso, a NBA irá informar o usuário quando isso ocorrer.</li>
                    <li><strong>Direito a revisão de decisões automatizadas:</strong> o usuário também tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado dos Dados Pessoais que afetem seus interesses.</li>
                    <li><strong>Direito de se opor a determinado tratamento de Dados Pessoais:</strong> o usuário também tem o direito de se opor a determinadas finalidades de tratamento. Em alguns casos, a NBA pode demonstrar que tem motivos legítimos para tratar os Dados Pessoais, os quais se sobrepõem aos direitos do usuário, caso, por exemplo, sejam estritamente essenciais para a funcionalidade dos produtos ou serviços.</li>
                    <li><strong>Reclamação:</strong> Você tem o direito de entrar em contato e/ou peticionar à ANPD para apresentar uma reclamação contra nossas práticas de proteção de Dados e privacidade. Por favor, não hesite em entrar em contato conosco utilizando as informações abaixo antes de apresentar qualquer reclamação à autoridade de proteção de Dados competente.</li>
                </ul>
                Talvez seja necessário solicitar informações específicas do usuário, ou representante legal quando aplicável, para confirmar sua identidade e garantir os direitos acima elencados. Esta é uma medida de segurança para garantir que os Dados Pessoais não sejam divulgados a qualquer pessoa que não tenha direito de recebê-los.<br>
                A NBA tentará responder a todas as solicitações dentro dos prazos legais previstos pela LGPD. Ocasionalmente, a NBA poderá levar mais de 15 (quinze) dias úteis se a solicitação for particularmente complexa ou se o usuário tiver feito várias solicitações. Neste caso, iremos notificar o titular e mantê-lo atualizado sobre o andamento da solicitação.<br>
                Caso haja alguma dúvida sobre essas questões e sobre como o usuário pode exercer seus direitos, a NBA se compromete a saná-las por meio do seguinte canal de contato:  <a href="mailto:ihungria@nba.com">ihungria@nba.com</a><br>
                <strong>TEMPO DE ARMAZENAMENTO DOS DADOS PESSOAIS DOS USUÁRIOS</strong><br>
                A NBA manterá os Dados Pessoais dos usuários somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coleta, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, de prestação de contas ou requisição de autoridades competentes.<br>
                Para determinar o período de retenção adequado para os Dados Pessoais, a NBA considera a quantidade, a natureza e a sensibilidade de tais Dados Pessoais, o risco potencial de danos decorrentes do uso não autorizado ou da divulgação de seus Dados Pessoais, a finalidade do tratamento e se pode alcançar tais propósitos por outros meios, e os requisitos legais aplicáveis.<br>
                <strong>PROTEÇÃO DOS DADOS DOS USUÁRIOS</strong><br>
                A NBA adota medidas técnicas, administrativas e organizacionais para proteger os Dados Pessoais dos usuários contra perda, uso não autorizado ou outros abusos. Os Dados Pessoais são armazenados em um ambiente operacional seguro que não é acessível ao público.<br>
                A NBA se esforça para proteger a privacidade da conta e dos Dados Pessoais dos usuários que mantém em seus registros. Mantemos salvaguardas de caráter procedimental, técnico e físico que nos ajudam a evitar a perda, uso indevido ou acesso não autorizado, divulgação, alteração ou destruição dos Dados Pessoais fornecidos pelos usuários.<br>
                No entanto, não é garantido que uma transmissão de Dados Pessoais realizada via Internet seja completamente segura. É possível que terceiros, que não estejam sob o nosso controle, interceptem ou tenham acesso às transmissões ou aos Dados Pessoais de maneira ilegal. Portanto, não sendo possível garantir a completa segurança dos Dados Pessoais transmitidos ao WebApp, pedimos sua colaboração para nos ajudar a manter um ambiente seguro para todos. Além de adotar boas práticas de segurança em relação à conta e aos Dados Pessoais, tenha cuidado e atenção com a forma de transmissão e com a escolha dos Dados Pessoais que são compartilhados conosco.<br>
                Caso identifique ou tome conhecimento de algo que comprometa a segurança da informação, o usuário deve entrar em contato com a NBA por meio do seguinte canal de contato <a href="mailto:ihungria@nba.com">ihungria@nba.com</a><br>
                <strong>CONTATO COM A NBA</strong><br>
                Se o usuário acreditar que seus Dados Pessoais foram usados de maneira incompatível com esta Política de Privacidade ou com as suas escolhas enquanto titular destes Dados Pessoais, ou, ainda, se tiver outras dúvidas, comentários ou sugestões relacionadas a esta Política, o usuário pode entrar em contato com o time da NBA pelos seguintes meios:<br>
                Contato: <a href="mailto:ihungria@nba.com">ihungria@nba.com</a><br>
                <strong>VIGÊNCIA E ALTERAÇÕES NESTA POLÍTICA DE PRIVACIDADE</strong><br>
                Esta Política vigerá imediatamente e enquanto o Evento estiver acontecendo no WebApp. As previsões desta Política permanecerão sendo aplicadas mesmo após a conclusão do Evento, para os fins legais cabíveis e aqui previstos.<br>
                Como a NBA está sempre buscando melhorar seus serviços, essa Política pode passar por atualizações ao longo da realização do Evento. Desta forma, recomendamos que o usuário visite esta página para ter conhecimento sobre as modificações. Caso sejam feitas alterações materiais relevantes, iremos publicar essa atualização e notificá-lo quando necessário.<br>
            </span>
                </div>
                <div class="modal-footer">
                    <button v-if="$route.name !='PoliticaPage'" v-on:click="$app.modal_PoliticaDePrivacidade=false" type="submit" class="button blue close" style="min-width:100px">Ok</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    import { mapGetters } from "vuex";
    
    export default {
        name: 'Politica',
        computed: {
            ...mapGetters([
                'logged', 'token'
            ]),
        },
        data() {
            return {
                
            }
        },
        components: {
            
        },
        methods: {
            openTermos(){
                if (this.$route.name !="PoliticaPage") {
                    this.$app.modal_Termos = true;
                    this.$app.modal_PoliticaDePrivacidade = false;
                }else
                    this.$app.link("TermosPage")
            },
        },
        created() {
            
        },
        mounted() {
           this.$app.analytics('visit_terms');
        },
        beforeDestroy() {
            
        },
    }
</script>

<style scoped>
/* Works on Firefox */
#privacidade * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0,.2) rgba(0,0,0,.2);
}

/* Works on Chrome, Edge, and Safari */
#privacidade *::-webkit-scrollbar {
    width: 8px;
}

#privacidade *::-webkit-scrollbar-track {
    background: rgba(0,0,0,.2);
}

#privacidade *::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2);
}

#privacidade .blue {
    background-color: var(--buttons_color);;
    color: white;
}

#privacidade span {
    font-size: 2.4vmin;
    line-height: 148.5%;
}

#privacidade h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 3.75vmin;
    line-height: 118.5%;
}

#privacidade .button {
    cursor: pointer;
    padding: 2% 5%;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.5vmin;;
    text-transform: uppercase;
    transition: color 0.3s ease-in, background 0.3s ease-in;
    display: block;
}

#privacidade .button.blue:hover {
    color: var(--buttons_color);;
    background: white;
}


#privacidade .button.blue {
    border: var(--buttons_color) solid 1px;
}

#privacidade .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: none;
    align-items: center;
    justify-content: center;
}

#privacidade .modal.show {
    display: flex;
}

#privacidade .modal .modal-contents {
    background: white;
    width: 30%;
    border-radius: 1%;
    z-index: 10;
}

#privacidade .modal .modal-contents .modal-header {
    width: 100%;
    justify-content: flex-end;
    display: flex;
}

#privacidade .modal .modal-contents .modal-header .close {
    margin-right: 4%;
    margin-top: 4%;
    margin-bottom: -2%;
    width: 5%;
}

#privacidade .modal .modal-contents .modal-body {
    width: 80%;
    color: var(--buttons_color);
    margin: 0 auto;
    max-height: 40vh;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 3% 10px 8%;
}

#privacidade .modal .modal-contents .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4% 0;
    border-top: 1px solid #F0F0F0;
}

#privacidade .modal .modal-contents .modal-footer .button {
    margin: 0 5px;
}


@media screen and (max-width: 1023px) {
    #privacidade .modal .modal-contents .modal-body {
        width: 88.5%;
    }
    #privacidade .modal .modal-contents .modal-header h3 {
        font-size: 2rem !important;
    }

    #privacidade .button {
        padding: 5% 25%;
        font-size: 3.25vmin;
    }

    #privacidade span {
        font-size: 3.5vmin!important;;
    }
    #privacidade .modal .modal-contents {
        background: white;
        width: 80%;
        border-radius: 1%;
    }

    #privacidade .lightbox .modal-contents {
        width: 90% !important;
    }
}

#privacidade .lightbox .modal-contents {
    width: 60%;
}

#privacidade .text-red {
    color: #D91A32 !important;
}

#privacidade .modal .modal-contents span {
    font-size: 18px;
}
#privacidade .text{
    font-family:Roboto,serif;
    width: 100%;
}

#privacidade .modal > .close {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

#privacidade ol {
    counter-reset: item;
}

#privacidade li {
    display: block;
}

#privacidade ol > li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
    font-weight: bold;
}

#privacidade ul > li:before {
    content: "●";
}
#privacidade ul > li.none-before:before{
    content: '';
}
#privacidade th,#privacidade td{
    border: 1px solid;
}
#privacidade table{
    border-collapse: collapse;
}
#privacidade a{
    color: var(--buttons_color)!important;
}
</style>