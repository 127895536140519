<template>
    <div class="btn-promotional" ref="btnPromotional">
        <div v-if="btn_promotional.active" class="btn-promotional-position">
            <div style="cursor: pointer;" class="alignVertical col-12" v-on:click="openLink()">
                <div class="btn-promotionalnotification-img alignVertical">
                    <img :src="btn_promotional.image" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
        name: 'BtnPromotional',
        computed: {
            ...mapGetters([
                'switch', "checkIOS", "checkMobile",
            ]),
            btn_promotional(){
                return this.switch.btn_promotional;
            }
        },
        data() {
            return {}
        },  
        components: {
        },
        methods: {
            openLink(){
                let url = this.btn_promotional.url_desktop;
                let ios = this.checkIOS;
                let android = this.checkMobile;
                if (ios && this.btn_promotional.url_ios != "")
                    url = this.btn_promotional.url_ios;
                else if (android && this.btn_promotional.url_mobile != "")
                    url = this.btn_promotional.url_mobile;
                switch (this.btn_promotional.open_in) {
                    case"iframe":
                        this.$app.modalI("",url,null,"btnPromotional");
                        break;
                    case"same_page":
                        window.open(url,"_top")
                        break;
                    case"new_window":
                        window.open(url,"_blank")
                        break;
                }
            }
        },
        created() {
        },
        mounted() {
        },
        updated() {
        },
        beforeDestroy() {
        },
        watch: {
        }
    }
</script>

<style scoped>
    .btn-promotional{
        height: 100%;
    }
    .btn-promotional-position{
        position: absolute;
        left: 35%;
        top: 0;
        height: 100%;
        width: 25%;
        max-width: 350px;
    }
    .btn-promotional-position>div{
        cursor: pointer;
        position: relative;
        height: 100%;
    }
    .btn-promotionalnotification-img{
        width: 360px;
        height: 90%;
    }
    .btn-promotionalnotification-img img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @media( max-width: 1000px ) {
        .btn-promotional-position{
            left: 50%;
            transform: translateX(-50%);
        }
    }
</style>
