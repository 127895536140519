<template>
    <div id="mapa" ref="mapa">
        <section v-if="this.use_map == 1" class="menuMap"  ref="fullscreenContainer">
            <div  id="aerial-map" ref="aerial-map">
                <section class="container-iframe">
                    <iframe id="iframe" ref="iframe" :src="map_final" frameborder="0" width="100%" height="100%" allowvr="yes" allow="camera *;microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="no"></iframe>
                </section>
                <section class="menu-map">
                    <div class="map-content">
                        <div class="container-event-title">
                            <p class="title-event">{{ $t('map.avantPremiere') }}</p>
                        </div>
                        <img class="event-image" src="assets/icons/myAccount/mock-event-image.png" alt="imagem do evento"/>
                        <div class="container-description">
                            <p class="description-event">{{ $t('map.loremIpsumDolor') }}</p>
                            <button class="button-enter">{{ $t('map.entrar') }}</button>
                        </div>
                    </div>
                    <footer class="container-footer">
                        <img src="assets/images/footer.svg" alt="background do footer (menu mapa)">
                    </footer>
                </section>
            </div>
        </section>
        <MapMosaico v-else-if="this.use_map == 2" />
        <MapSession v-else-if="!this.switch.validate_user" />
        <ModalOptins v-if="this.switch.validate_user" ref="modalOptins" />

        <div v-if="isMiniGameHeader" class="fullscreen-button" @click="fullscreen()">
            <i v-if="modalFs" class="expand icon" ></i>
            <i v-else class="compress icon" ></i>
        </div>
    </div>
</template>

<script>
import {mapGetters,mapActions} from "vuex";
import MapMosaico from "@/components/pages/MapMosaico";
import MapSession from "@/components/pages/MapSession";
import ModalOptins from "@/components/Modals/ModalOptins.vue";
let emitter = require('tiny-emitter/instance');

export default {
    name: 'Map',
    components: {
        ModalOptins,
        // MapButton,
        MapMosaico,
        MapSession,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            swiper_map: null,
            swiper_wizard: null,
            img_map: {
                filter: 'brightness(1)'
            },
            sidebar: {
                on: false,
                title: "",
                description: "",
                thumb: "",
                url: "",
            },
            // sidebar: {
            //     on: false,
            //     title: "Ambiente 1",
            //     description: "Lorem ipsum dolor sit amet",
            //     thumb: "http://placehold.it/300",
            //     url: "www.google.com",
            // },
            // test : false,
            modalFs: false
        }
    },
    computed: {
        ...mapGetters([
            'sessions', 'switch', 'age','maps',"checkIOS", "checkMobile","checkSafari", "use_map", "isMiniGameHeader"
        ]),
        map_final: function () {
            if (this.checkIOS && this.maps.ios) {
                return this.maps.ios;
            } else if ((this.checkIOS || this.checkMobile) && this.maps.android) {
                return this.maps.android;
            } else if ((this.checkSafari) && this.maps.safari) {
                return this.maps.safari;
            }
            return this.maps.default;
        }
    },
    methods: {
        ...mapActions([
            'update_mini_game'
        ]),
        openSidebar(name) {
            this.$app.wait(true);
            // this.sidebar.description = "";
            // this.sidebar.thumb = "";

            if (this.sessions) {
                let session = this.sessions.filter(_ => _.name === name)[0];
                this.sidebar.description = session ? session.description : '';
                this.sidebar.thumb = session ? session.tumbnail : '';
            }
            this.sidebar.on = true;
            switch (name) {
                case 'Fan Zone':
                    this.sidebar.title = this.$i18n.t('map.fanZone');
                    this.sidebar.url = "";
                    break;
                case 'Feel Zone':
                    this.sidebar.title = this.$i18n.t('map.feelZone');
                    this.sidebar.url = "FeelZone";
                    break;
                case 'Play Zone':
                    this.sidebar.title = this.$i18n.t('map.playZone');
                    this.sidebar.url = "PlayZone";
                    break;
                case"NBA THEATER":
                    this.sidebar.title = this.$i18n.t('map.auditorio');
                    this.sidebar.url = "Theater";
                    break;
                case"NBA STADIUM":
                    this.sidebar.title = this.$i18n.t('map.arena');
                    this.sidebar.url = "Stadium";
                    break;
                case"NBA BAR":
                    this.sidebar.title = this.$i18n.t('map.bar');
                    this.sidebar.url = "Bar";
                    break;
                case"NBA MUSEUM":
                    this.sidebar.title = this.$i18n.t('map.museu');
                    this.sidebar.url = "Museum";
                    break;
                case"NBA STORE":
                    this.sidebar.title = this.$i18n.t('map.lojaNBA');
                    this.sidebar.url = "Store";
                    break;
                case"Locker Room Curiosities":
                    this.sidebar.title = this.$i18n.t('map.lockerRoom');
                    this.sidebar.url = "LockerRoom";
                    break;
                case"AVATAR SHOP":
                    this.sidebar.title = this.$i18n.t('map.avatarShop');
                    this.sidebar.url = "City";
                    break;
            }
            this.$app.wait(false);
        },
        onResize(){
            if (!this.$refs["mapa"])return;
            if(window.innerWidth>1000  && this.$app.$refs['copyright_footer']) {
                this.$refs["mapa"].style.height = `calc(100vh - ${this.$app.$refs['copyright_footer'].clientHeight}px)`;
                this.$app.$refs["copyright_footer"].style.position = `fixed`;
            }else {
                this.$refs["mapa"].style = "";
            }

            this.miniGameResize();
        },
        Appcheck() {
            this.is_minigame = true;
            this.isMiniGame();
        },
        isMiniGame() {
            // if (!this.checkIOS)

            // if (this.checkMobile)
            // {

            console.log('aqui dentro Map');
            this.update_mini_game(true);
            if(this.$refs["mapa"]) {
                this.$refs["mapa"].style.marginTop = '0 !important';
            }


            if (!this.checkIOS) {
                try {
                    document.getElementById('iframe').contentWindow.setFullScreen();
                } catch (e) {
                    document.getElementById('iframe').contentWindow.postMessage({type: 'setFullScreen'}, '*')
                }
            }

            if(this.isMiniGameHeader && this.$refs['btn-back']) {
                this.$refs['btn-back'].style.display = 'block';
            }

            // this.$app.$refs['header-main'].style.display = 'none';
            // this.$refs["iframe-view"].style.position = 'absolute';
            // this.$refs["iframe-view"].style.height = 'calc(100% - 40px)';
            // this.$refs["iframe-view"].style.width = '100%';
            // this.$refs["iframe-view"].style.left = '0';
            // this.$refs["iframe-view"].style.marginTop = '0 !important';

            // this.$refs["iframe-view"].style.minHeight =  '-webkit-fill-available';
            // this.$refs["iframe-view"].style.bottom = 'calc(10px + (100vh - 100%))';
            // this.$refs["iframe-view"].style.bottom = 'calc(100vh - 100%)';
            // this.$refs["iframe-view"].style.position = 'fixed';
            // this.$refs["iframe-view"].style.height = '100vh';
            // this.$refs["iframe-view"].style.width = '100%';

            // this.$refs["iframe"].style.position = 'absolute';
            // this.$refs["iframe"].style.top = '0 !important';
            // this.$refs["iframe"].style.height = '100%';

            //document.getElementById('iframe').contentWindow.getElementById('fullscreen-btn').style.display = 'none';
            // }

            this.onResize();
        },
        fullscreen() {

            const elem = this.$refs['mapa'];
            //on off full screen
            if (!document.fullscreenElement) {
                this.modalFs = true;
                let iframeView = document.querySelector('.iframe-view');
                iframeView.classList.add('minigame-fullscreen');
                const fullScreenFunction = elem.requestFullscreen || elem.webkitRequestFullscreen || elem.mozRequestFullScreen || elem.msRequestFullscreen;
                if(fullScreenFunction) {
                    fullScreenFunction.call(elem).then(() => {
                        // elem.classList.add('full');
                    }).catch(err => {
                        console.log(err)
                    });
                }
            } else {
                this.modalFs = false;
                let iframeView = document.querySelector('.iframe-view');
                iframeView.classList.remove('minigame-fullscreen');
                const exitFullScreenFunction = document.exitFullscreen() || document.webkitExitFullscreen() || document.mozCancelFullScreen() || document.msExitFullscreen();
                if (exitFullScreenFunction) {
                    exitFullScreenFunction.then(()=>{
                        // elem.classList.remove('full');
                    }).catch(err => {
                        console.log(err)
                    });
                }
            }

            this.onResize();
        },
        miniGameResize() {
            // console.log("MINIGAME RESIZE: ", this.is_minigame);

            if (this.is_minigame) {
                var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                var isPortrait = viewportHeight > viewportWidth;

                var newViewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                var newViewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                var hasOrientationChanged = (newViewportHeight > newViewportWidth) !== isPortrait;
                var addressbarHeight = 130;


                let final_height = newViewportHeight;

                if (!hasOrientationChanged && (newViewportHeight != viewportHeight)) {
                    addressbarHeight = Math.abs(newViewportHeight - viewportHeight);
                    if (newViewportHeight < viewportHeight) {
                        // Android Chrome address bar has appeared
                        final_height = (viewportHeight - addressbarHeight);
                    } else {
                        // Android Chrome address bar has disappeared
                        final_height = (viewportHeight + addressbarHeight);
                    }
                } else if (hasOrientationChanged) {
                    // Orientation change
                }

                viewportHeight = final_height;
                viewportWidth = newViewportWidth;
                console.log('viewportHeight, viewportWidth => ', viewportHeight, final_height, viewportWidth);
                isPortrait = viewportHeight > viewportWidth;

                this.$refs["mapa"].style.marginTop = '0px';
                this.$refs["mapa"].style.width = viewportWidth + 'px';
                this.$refs["mapa"].style.height = viewportHeight + 'px';
            }
        },
    },
    mounted() {

        let vm = this;
        vm.$nextTick(() => {
            this.onResize()
            window.addEventListener('resize', vm.onResize);
        })

        document.body.classList.remove('lms');

        emitter.on('Map::Appcheck', function() {
            vm.Appcheck();
        })

    },
    created()
    {
        /*
        console.log('LMS Map => ', this.lms);
        */
        // this.$app.LMSHandler(this.lms, 'map', '', '');
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
}
</script>

<style scoped lang="scss">
#mapa
{
    height: 100%;
    width: 100%;

    .fullscreen-button {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 180px;
        left: 10px;

        background-color: #00000080;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;

        .icon {
            color: #FFFFFF;
            font-size: 25px;
        }

        z-index: 10;
    }


    .iframe-view.minigame-fullscreen {
        height: 100vh;
        margin-top: 0;
    }

}
@media screen and (max-width: 1000px) {
    #mapa
    {
        height: 100%;
        /*
        height: calc(100% - 45px);
        margin-top: 45px;
        */
    }
}
</style>
