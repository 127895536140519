<template>
    <div class="modals bgAzul ticket-gate-modal-wrap" style="background-color: rgba(0, 0, 0, 0.6);z-index: 999;">
        <div style="z-index: -1;width: 100%; height: 100%; position: fixed; top: 0px; left: 0px;"></div>
        <div class="main_modal" style="max-width: 30%">
            <div class="main_modal_wrap">
                <div class="header_modal">
                    <span>{{ $t('modalOptins.Aceite') }}</span>
                </div>
                <div class="body_modal alignVertical" style="padding: 20px">
                    <div class="cadastro__termos flex flex-wrap col-12">{{ $t('modalOptins.aceiteOsTermos') }}</div>
                    <div v-for="op in this.switch.optins" :key="op.id" class="cadastro__termos flex flex-wrap col-12">
                        <div class="col-12 flex">
                            <div>
                                <input class="checkbox" v-model="optins[op.name]" :id="'_' + op.id" :name="op.name" :ref="op.name" type="checkbox"
                                       value="1" :data-id="op.id" :data-required="Boolean(op.required)"
                                       v-on:change="optinHandler">
                            </div>
                            <div>
                                <label :for="'_' + op.id" class="home__terms"
                                       v-html="String(op.title.substring(3, op.title.length))"></label>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap col-6">
                        <input v-if="(!isActive)" id="btn-register-confirm"
                               class="button__buttonForm button__red col-12 button__desactive" disabled="true"
                               ref="btn-submit" type="submit" :value="$t('modalOptins.Enviar')">
                        <input v-else class="button__buttonForm button__red col-12" ref="btn-submit"
                               id="btn-register-confirm" type="button" v-on:click="sendOptins"
                               :value="$t('modalOptins.Enviar')">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'ModalOptins',
    computed: {
        ...mapGetters([
            // 'logged', 'token','switch', 'nickname'
            'token', 'switch',"checkers"
        ]),
        isActive() {
            if (this.requireds === 0) {
                return true
            }
            return (this.checkers == this.requireds)
        },
        requireds() {
            return this.switch.optins.filter((op) => {
                return op.required == 1
            }).length
        }
    },
    data() {
        return {
            optins: {
                accept_terms:false,
                accept_policy:false,
                accept_notifications:false,
            },
        }
    },
    methods: {
        ...mapActions([
            'update_checkers', 'update_switch'
        ]),
        optinHandler(e) {
            if (this.checkers < 0) {
                this.update_checkers(0)
            }
            if (e.currentTarget.dataset.required) {
                if (e.currentTarget.checked)
                    this.update_checkers(this.checkers + 1);

                else
                    this.update_checkers(this.checkers - 1);
            }
        },
        sendOptins() {
            const _root = this;
            this.$app.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            }
            axios.post(this.$url_api + 'api/saveOptins', {
                optins: this.optins,
                eventmap: localStorage.getItem("eventmap")
            }, {
                headers
            }).then(() => {
                _root.$app.wait(false);
                _root.$app.modal('success', _root.$t("modalOptins.sucesso"), _root.$t("modalOptins.sucesso_enviar"), null, null, null, "OK");
                _root.update_switch({...this.switch, validate_user: false})
            }).catch((error) => {
                let errors = error.response.data.errors;
                let str = '';
                for (let o in errors) {
                    str += errors[o] + '\n';
                }
                _root.$app.modal('error', _root.$t("login.erro"), str, null, null, null, "OK");
                _root.$app.wait(false);
            });
        }
    },
    created() {
        this.$app.bodyOverflow(true)
        this.update_checkers(0)
    },
    mounted() {
    },
    beforeDestroy() {
        this.$app.bodyOverflow(false)
    },
}
</script>
