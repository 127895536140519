<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" pageSubTitle="Explore os cursos" :searchField="true" searchPlaceholder="Pesquisar na biblioteca...">
    <template v-slot:navigation-side>

      <div class="accordion" id="accordionCategories">
        <div class="accordion-item mb-3">
          <h2 class="accordion-header" >
            <button class="accordion-button collapsed col-12 d-flex justify-content-between" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseJourneys" aria-expanded="true" aria-controls="collapseJourneys" @click="toggleCategory">
              <span>Jornadas</span>
              <i class="bi bi-chevron-down rotate-180"></i>
            </button>
          </h2>
          <div id="collapseJourneys" class="accordion-collapse collapse show" aria-labelledby="headingJourneys"
               data-bs-parent="#accordionCategories" @click="selectItem($event)">
            <div class="accordion-body" :data-categoryid="category.id" :class="{'course-selected': categoryIdSelected === category.id}" v-for="(category, idxCategory) in categories" :key="idxCategory" >
              <span :data-categoryid="category.id">{{category.title}}</span>
            </div>
          </div>
        </div>

      </div>

    </template>
    <template v-slot:content-side>

      <div class="content-side">

        <div :class="showCourses.length ? 'show-results' : 'hide-results'">
          <div class="cards">
            <div class="card mb-5" style="width: 18rem;" v-for="(course, idxCourse) in showCourses" @click="viewCourse(course.slug)" :key="idxCourse">
              <img class="cards-img" :src="course.thumbnail" :alt="course.title">
              <div class="card-body">
                <p class="card-text">{{course.title}}</p>
              </div>
<!--              <div class="favorite" @click.stop="favoriteItem" :class="{ 'selected': course.is_fav }" :data-id="course.id" data-object="course">-->
<!--                <i class="bi bi-suit-heart-fill"></i>-->
<!--              </div>-->
            </div>
          </div>

          <pagination v-if="paginationOptions.show" v-model="paginationOptions.page" :records="paginationOptions.total"
                      :per-page="paginationOptions.per_page" :options="paginationOptions" @paginate="filterCourse"/>

        </div>
        </div>
        <div :class="(!showCourses.length && pageloaded) ? 'show-results' : 'hide-results'">
          <div class="no-results d-flex flex-column align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
              <path d="M128 56.0281C127.575 58.6521 127.275 61.3011 126.725 63.875C125.675 68.9731 123.8 73.7462 121.25 78.2694C119.775 80.8434 116.875 81.7431 114.4 80.3936C111.95 79.0441 111.075 76.0953 112.5 73.4963C116.275 66.624 118.15 59.3268 118 51.4799C117.65 31.8376 103.05 14.4943 83.7249 10.8208C62.5499 6.79735 42.0749 18.7677 35.1749 39.1847C26.9749 63.4502 42.6749 89.8399 67.9249 94.2882C77.8499 96.0375 87.2249 94.4631 96.0749 89.715C99.7999 87.7157 103.925 90.1148 103.75 94.1132C103.675 96.0625 102.675 97.4869 100.975 98.4116C94.7499 101.86 88.0749 104.009 80.9999 104.684C67.5999 105.984 55.4249 102.66 44.4999 94.788C43.7749 94.2632 43.0499 93.6884 42.2749 93.1136C41.9249 93.4385 41.6249 93.7134 41.3249 93.9883C30.7499 104.484 20.1749 115.005 9.5499 125.476C8.5499 126.476 7.2249 127.125 6.0249 127.95C5.3499 127.95 4.6999 127.95 4.0249 127.95C1.9999 127.3 0.674902 125.976 0.0249023 123.952C0.0249023 123.277 0.0249023 122.627 0.0249023 121.952C0.849902 120.778 1.5249 119.453 2.5249 118.454C13.0749 107.908 23.6499 97.4369 34.2249 86.941C34.5249 86.6412 34.7749 86.3413 35.0999 85.9914C34.7749 85.5666 34.4749 85.1667 34.1749 84.7669C27.2749 75.8954 23.4999 65.8243 23.0749 54.6037C22.5749 41.4838 26.4499 29.7134 34.7249 19.5174C42.7749 9.59625 53.1499 3.37368 65.7499 0.94963C67.8249 0.549786 69.9249 0.299883 72.0249 0C74.3499 0 76.6999 0 79.0249 0C79.2999 0.0749707 79.5749 0.174932 79.8499 0.199923C85.1249 0.574776 90.2499 1.72433 95.0749 3.79852C112.4 11.2706 123.15 24.2155 127.05 42.7083C127.475 44.7825 127.7 46.8817 128 48.9809C128 51.3549 128 53.704 128 56.0281Z" fill="#E1E1E1"/>
              <path d="M0 124.002C0.65 126.026 1.975 127.35 4 128C2.675 128 1.325 128 0 128C0 126.675 0 125.326 0 124.002Z" fill="#E1E1E1"/>
              <path d="M75.375 45.5822C78.725 42.2335 81.85 39.1097 84.975 35.9859C85.65 35.3112 86.3 34.6115 87.025 33.9617C89.125 32.0125 92.15 32.0375 94.1 34.0367C96.025 36.0109 96.05 39.0347 94 41.1089C90.55 44.6076 87.05 48.0562 83.6 51.5549C83.3 51.8547 83.025 52.1546 82.675 52.5295C83 52.8793 83.3 53.2292 83.625 53.5291C87.1 57.0027 90.6 60.4514 94.025 63.975C95.625 65.5994 95.95 67.6736 95.05 69.6228C94.2 71.4971 92.275 72.6716 90.225 72.5467C88.775 72.4467 87.625 71.772 86.625 70.7474C83.275 67.3737 79.925 64.025 76.55 60.6763C76.225 60.3514 75.9 60.0515 75.525 59.7017C75.15 60.0515 74.8 60.3514 74.5 60.6763C71.05 64.125 67.625 67.5736 64.15 70.9973C62.475 72.6466 60.375 72.9965 58.425 72.0968C56.55 71.2222 55.4 69.3229 55.55 67.2487C55.65 65.7993 56.35 64.6747 57.375 63.6751C61.05 60.0515 64.675 56.378 68.45 52.6294C68.125 52.2796 67.825 51.9547 67.525 51.6298C64.05 48.1562 60.55 44.7075 57.1 41.2089C55.45 39.5595 55.1 37.4354 56.05 35.4611C56.925 33.5869 58.85 32.4623 60.925 32.6123C62.325 32.7122 63.45 33.387 64.425 34.3616C67.775 37.7353 71.15 41.084 74.5 44.4326C74.725 44.6825 74.975 45.0824 75.375 45.5822Z" fill="#E1E1E1"/>
            </svg>
            <span class="w-50" style="margin-top:10px">Infelizmente, não conseguimos encontrar o que está procurando. Por favor, tente novamente.</span>
          </div>
        </div>


    </template>
  </LmsBasicTemplate>
</template>

<script>

import LmsBasicTemplate from "./LmsBasicTemplate.vue";
import {mapGetters} from "vuex";
import CustomPagination from "@/components/lms/components/LmsHomeComponents/CustomPagination.vue";
let emitter = require('tiny-emitter/instance');
import { gsap } from 'gsap';

export default {
  name: "LmsLibrary",
  components: {
    LmsBasicTemplate
  },
  data() {
    return {
      navigationConfig: [],
      allCourses: [],
      showCourses: [],
      swiper: {},
      searchText: "",
      pagination: [],
      categoryIdSelected: 0,
      pageloaded: false,
      paginationOptions: {
        paginationKey: 0,
        show: true,
        total: 10,
        page: 1,
        per_page: 10,
        template: CustomPagination
      }
    }
  },
  created(){

  },
  beforeMount() {
    let root_ = this;
    root_.navigationConfig = root_.getPageByName("LmsLibrary");

    root_.categoryIdSelected = this.$route.params.categoryId;


  },
  mounted(){
    let root_ = this;

    root_.filterCourse(1);
    emitter.on('filter-courses', function (searchText) {

      root_.searchText = searchText;
      root_.filterCourse(1);

    });
    window.scrollTo(0, 0);


  },
  beforeDestroy() {
    emitter.off('filter-courses');
  },
  methods: {
    toggleCategory(e) {

      let icon = e.currentTarget.querySelector(".bi");

      icon.classList.toggle("rotate-180");

    },
    selectItem({ currentTarget, target }) {
      let root_ = this;

      let accordionBody = currentTarget.querySelectorAll(".accordion-body");

      for (let body of accordionBody) {
        body.classList.remove("course-selected");
      }

      if(target.getAttribute("data-categoryid") === root_.categoryIdSelected){
        root_.categoryIdSelected = "";
      } else {
        root_.categoryIdSelected = target.getAttribute("data-categoryid");

        if (target.classList.contains("accordion-body")) {
          target.classList.add("course-selected");
        } else {
          target.parentElement.classList.add("course-selected");
        }
      }

      root_.filterCourse(1);
    },
    filterCourse(page){
      let root_ = this;

      let payload = {
        "slug"        : root_.$route.params.lmsSlug,
        "search"      : root_.searchText,
        "page"        : page,
        "category_id" : root_.categoryIdSelected ?? 0
      }
      root_.$store.dispatch("LMS/searchCourses", payload)
          .then(results => {
            root_.showCourses = results.data.courses.data;

            root_.paginationOptions.current_page  = results.data.courses.current_page;
            root_.paginationOptions.total         = results.data.courses.total;
            root_.paginationOptions.per_page      = results.data.courses.per_page;
            root_.paginationOptions.page          = results.data.courses.current_page;
          })
          .finally(() => {
            root_.pageloaded = true;
            const cards = gsap.utils.toArray('.cards-img');
            cards.forEach((card) => {
              card.addEventListener('mouseenter', () => {
                gsap.to(card, { scale: 1.1, duration: 0.5 });
              });
              // Adiciona um listener para o evento de mouseleave
              card.addEventListener('mouseleave', () => {
                gsap.to(card, { scale: 1, duration: 0.5 });
              });
            });
          })
    },
    viewCourse(courseSlug) {

      this.$router.push({
        name: "LMSCourses",
        params: {
          lmsSlug: this.getLms.slug,
          courseSlug: courseSlug,
          category: this.categoryIdSelected
        }
      });
    },
    favoriteItem(e) {
      const courseElement = e.currentTarget;
      const courseId = courseElement.getAttribute("data-id");
      const objectData = courseElement.getAttribute("data-object");

      let payload = {
        "object": objectData,
        "object_id": courseId
      }

      this.$store.dispatch("LMS/favoriteItem", payload)
          .then(response => {
              this.showCourses.forEach(course => {
                      if (course.id == courseId) {
                        course.is_fav = !course.is_fav;
                        response;
                      }
                })
          })
          .catch(error => {
            console.log('ERROR WHEN FAVORITE COURSE', error)
          });
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    }
  },
  computed: {
    ...mapGetters({
      getLms: "LMS/getLms",
      getPageByName: "LMS/getPageByName"
    }),
    categories: function () {
      return this.getLms.categories;
    },
  }
}

</script>

<style scoped lang="scss">

.content-side {
  height: 100vh;
}

.show-results {
  display: block;
  height: 100%;
  .cards {
    display:flex;
    width:100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 3rem;
  }
}
.hide-results {
  display: none;
}

#accordionCategories {
  .accordion {
    border-radius: 10px;
  }
  .accordion-button, .accordion-item {
    border-radius: 10px;
  }
  .accordion-button {
    background-color: #F6F6F6;
    color: #706F6F;
    font-family: "Hanken Grotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .bi-chevron-down {
      font-size: 24px;
      color: #706F6F;
      transition: 0.3s;
    }

    &:not(.collapsed) {
      box-shadow: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:after {
      content: none; /* Remove a seta padrão */
    }
  }

  .accordion-body {
    cursor: pointer;

    span {
      color: #706F6F;
      font-family: "Hanken Grotesk";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.course-selected {
      background-color: #F6F6F6;
    }

    &:hover {
      background-color: #F6F6F6;
    }
  }
  .accordion-item {
    border: 1px solid #F6F6F6;

    &:last-of-type {

    }
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

}

.card {
  position: relative;
  height: 180px;
  width: 320px;
  cursor: pointer;
  border: none;

  .cards-img {
    position: relative;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-blend-mode: saturation;
    min-height: 100%;
    width: 100%;
    border-radius: 30px;
    object-fit: cover;
    object-position: top center;
  }

  .card-text {
    color: #706F6F;
    text-align: center;
    font-family: Hanken Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .favorite {
    position: absolute;
    right: 25px;
    top: 15px;
    font-size: 28px;
    z-index: 1;
    cursor: pointer;
    color: #FFFFFF80;

    &.selected {
      color: #E82E2E;
    }
  }
}

.no-results {
  color: #706F6F;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.VuePagination {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 480px) {
  .show-results {
    .cards {
      justify-content: center !important;
    }
  }
}

</style>