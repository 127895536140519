<template>
    <LmsBasicTemplate :current-page="navigationConfig.pageName" :showGateways="true" :gateways="getGateways" class="checkout-page">
  
      <template v-slot:navigation-side>
  
        <div class="course-card card px-3" v-for="(cartItem, idxItem) in getCart" :key="idxItem">
          <h5 class="card-title mt-4 d-flex gap-3">
            <div>
              <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 14.5156V2.98438C19.9688 2.67187 18.7969 2.51562 17.4844 2.51562C15.4531 2.51562 13.625 3.01563 12 4.01562V15.5C13.625 14.5 15.4531 14 17.4844 14C18.7031 14 19.875 14.1719 21 14.5156ZM17.4844 0.5C19.8594 0.5 21.7031 1 23.0156 2V16.5781C23.0156 16.7031 22.9531 16.8281 22.8281 16.9531C22.7344 17.0469 22.625 17.0938 22.5 17.0938C22.4062 17.0938 22.3281 17.0781 22.2656 17.0469C20.9844 16.3594 19.3906 16.0156 17.4844 16.0156C15.4531 16.0156 13.625 16.5156 12 17.5156C10.6562 16.5156 8.82812 16.0156 6.51562 16.0156C4.82812 16.0156 3.23438 16.375 1.73438 17.0938C1.70312 17.0938 1.65625 17.1094 1.59375 17.1406C1.5625 17.1406 1.53125 17.1406 1.5 17.1406C1.375 17.1406 1.25 17.0938 1.125 17C1.03125 16.9062 0.984375 16.7969 0.984375 16.6719V2C2.32812 1 4.17188 0.5 6.51562 0.5C8.82812 0.5 10.6562 1 12 2C13.3438 1 15.1719 0.5 17.4844 0.5Z"/>
              </svg>
            </div>
            <span>Informações do Produto</span>
          </h5>
  
          <div class="card-body infos d-flex flex-column p-0">
            <div class="thumb-title row h-75">
              <div class="course-thumbnail col-6">
                <img class="thumbnail" width="108px" height="80px" :src="cartItem.item.thumbnail" :alt="cartItem.item.title">
              </div>
              <div class="title-author col-6 px-0">
                <!-- div class="course-title">{{ cartItem.item.title }}</div -->
                <div class="course-title">{{cartItem.item.title}}</div>
                <!--              <div class="course-author">Autor: Nome do autor</div>-->
              </div>
            </div>
            <div class="description-price row mt-3">
              <span class="description" :title="cartItem.item.description">{{ cartItem.item.description }}</span>
  
              <div class="d-flex justify-content-between">
                <div v-if="!cartItem.item.is_free" class="d-flex flex-column align-items-start my-2">
                  <span v-if="cartItem.item.price" class="price">R$ {{ maskMoney(cartItem.item.price) }}</span>
                  <span v-if="cartItem.item.price && cartItem.item.coins">ou</span>
                  <span v-if="cartItem.item.coins" class="price">{{ cartItem.item.coins }} {{ getCoinsName }}</span>
  
                </div>
                <span class="material-symbols-outlined remove-cart-item align-self-center" @click="removeCartItem(cartItem)"
                      title="Remove o item do carrinho">
                  cancel
                </span>
              </div>
            </div>
  
          </div>
  
        </div>
  
  
      </template>
  
      <template v-slot:content-side>
        <div id="nav-tabContent" class="tab-content ps-5" >
  
  
          <div class="tab-pane fade" :class="{'active show': getGateways[0] === 'pagseguro'}" id="nav-pagseguro" role="tabpanel" aria-labelledby="nav-pagseguro-tab">
  
  
            <form id="ccForm">
              <div class="row">
                <div class="mb-3 form-group">
                  <label for="cardholder" class="form-label">
                    Titular
                  </label>
                  <input v-model="ccForm.name" type="text" class="form-control" name="cardholder" id="inputCardholder"
                         aria-describedby="cardholderHelp" @input="onInputName" @focusout="onInputName">
                  <div id="emailHelp" class="form-text">Digite seu nome igual aparece impresso no cartão.</div>
                  <div v-if="ccFormError.name" class="form-text error d-flex align-items-center">
                    <span class="material-symbols-outlined me-2 text-danger">
                      warning
                    </span>
                    <span class="text-danger">Nome inválido.</span>
                  </div>
                </div>
  
                <div class="mb-3 form-group">
                  <label for="cpf" class="form-label">
                    CPF
                  </label>
                  <input v-model="ccForm.document" v-mask="'###.###.###-##'" @input="onInputDocument" @focusout="onInputDocument" type="text"
                         class="form-control" id="inputCpf" name="cardholderDocument" maxlength="14" aria-describedby="cpfHelp" >
                  <div v-if="ccFormError.document" id="cpfHelp" class="form-text error d-flex align-items-center">
                    <span class="material-symbols-outlined me-2 text-danger">
                      warning
                    </span>
                    <span class="text-danger">Número de CPF inválido.</span>
                  </div>
  
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group col-md-6">
                  <label for="birthday" class="form-label">
                    Data de Aniversário
                  </label>
                  <input v-model="ccForm.birthday" v-mask="'##/##/####'" type="text" class="form-control" @input="onInputBirthday"
                         @focusout="onInputBirthday"
                         id="inputBirthday" name="birthday" aria-describedby="birthdayHelp" maxlength="10">
                  <div id="birthdayHelp" class="form-text">Exemplo: 01/04/1995</div>
                  <div v-if="ccFormError.birthday" class="form-text error d-flex align-items-center">
                    <span class="material-symbols-outlined me-2 text-danger">
                      warning
                    </span>
                    <span class="text-danger">Data inválida ou titular menor de 18 anos.</span>
                  </div>
                </div>
  
                <div class="mb-3 form-group col-md-6">
                  <label for="cellphone" class="form-label">
                    Celular
                  </label>
                  <input v-model="ccForm.cellphone" v-mask="'(##) #.####-####'" type="text" class="form-control" maxlength="16"
                         id="inputCellphone" name="cellphone" aria-describedby="cellphoneHelp" @input="onInputCellphone"
                         @focusout="onInputCellphone">
                  <div id="cellphoneHelp" class="form-text">Exemplo: (11) 98567-6153</div>
                  <div v-if="ccFormError.cellphone" class="form-text error d-flex align-items-center">
                    <span class="material-symbols-outlined me-2 text-danger">
                      warning
                    </span>
                    <span class="text-danger">Telefone inválido.</span>
                  </div>
                </div>
              </div>
  
              <div class="row">
                <div class="mb-3 form-group">
                  <label for="ccNumber">Número do cartão</label>
                  <input v-model="ccForm.ccNumber" v-mask="'#### #### #### ####'" type="text" class="form-control"
                         id="inputCcNumber" name="ccnumber" aria-describedby="ccHelp" maxlength="19"
                         @input="onInputCcNumber" @focusout="onInputCcNumber">
                  <div id="ccHelp" class="form-text">Cartão de qualquer bandeira dentre as exibidas acima.</div>
                  <div v-if="ccFormError.ccNumber" class="form-text error d-flex align-items-center">
                    <span class="material-symbols-outlined me-2 text-danger">
                      warning
                    </span>
                    <span class="text-danger">Número do cartão inválido.</span>
                  </div>
                </div>
              </div>
  
              <div class="row">
  
                <div class="mb-3 form-group col-md-4">
                  <label for="month" class="form-label">Mês</label>
                  <select class="form-select" id="month" aria-describedby="monthHelp" v-model="ccForm.ccMonth"
                          @change="onInputMonth">
                    <option selected disabled>Selecione o mês...</option>
                    <option :value="monthNumber" v-for="(month, monthNumber) in getActiveMonths" :key="month">{{ month }}</option>
                  </select>
                  <div v-if="ccFormError.ccMonth" class="form-text error d-flex align-items-center">
                    <span class="material-symbols-outlined me-2 text-danger">
                      warning
                    </span>
                    <span class="text-danger">Selecionar o mês.</span>
                  </div>
                </div>
  
                <div class="mb-3 form-group col-md-4">
                  <label for="year" class="form-label">Ano</label>
                  <select class="form-select" id="month" aria-describedby="yearHelp" v-model="ccForm.ccYear"
                          @change="onInputYear">
                    <option selected disabled>Selecione o ano...</option>
                    <option :value="year" v-for="year in years" :key="year">{{ year }}</option>
                  </select>
                  <div v-if="ccFormError.ccYear" class="form-text error d-flex align-items-center">
                    <span class="material-symbols-outlined me-2 text-danger">
                      warning
                    </span>
                    <span class="text-danger">Selecionar o ano.</span>
                  </div>
                </div>
  
  
                <div class="mb-3 form-group col-md-4">
                  <label for="cvv" class="form-label">CVV</label>
                  <input v-model="ccForm.ccCvv" v-mask="'####'" type="text" class="form-control" id="inputCvv"
                         name="cvvnumber" maxlength="4" @input="onInputCvv" @focusout="onInputCvv">
                  <div v-if="ccFormError.ccCvv" class="form-text error d-flex align-items-center">
                    <span class="material-symbols-outlined me-2 text-danger">
                      warning
                    </span>
                    <span class="text-danger">Código de segurança inválido.</span>
                  </div>
                </div>
  
  
              </div>
  
              <div class="d-flex align-items-center justify-content-between mt-3 flex-wrap gap-3">
                <div v-if="false" class="voucher-input mb-3 form-group col-md-4">
                  <label for="installments">Opções de Pagamento</label>
                  <select class="form-select" id="installments">
                    <option>1x de R$ XX (Sem juros)</option>
                  </select>
                </div>
  
                <div class="d-flex align-items-center gap-3">
                  <label for="voucher" class="form-label my-0">
                    Cupom
                  </label>
                  <input v-model="ccForm.voucher" type="text" class="form-control voucher-input" name="voucher" id="inputVoucher"
                         aria-describedby="voucherHelp">
  
                  <span class="btn-apply-voucher" @click="applyVoucher">
                    Aplicar
                  </span>
                </div>
  
                <div>
  
                  <span v-if="showFiduciaryDiscount" class="me-3 discount">{{ discountErrorMessageFiduciary }}</span>
  
                  <span class="" v-html="getFiduciaryPrice"></span>
                </div>
              </div>
            </form>
            <div class="buy-buttons mt-5 d-flex justify-content-end align-items-center gap-3">
              <span>
                Cancelar
              </span>
  
              <div class="btn-checkout" @click="sendCheckoutCc('pagseguro')">
                <span class="btn-buy-buttons">
                  Concluir compra
                </span>
              </div>
            </div>
  
          </div>
  
  
          <div class="tab-pane fade" :class="{'active show': getGateways[0] === 'coins'}" id="nav-coins" role="tabpanel" aria-labelledby="nav-coins-tab">
  
            <h3>Pague com suas {{ getCoinsName }}!</h3>
            <p class="my-5">
              Praticidade e segurança: use as moedas que você adquiriu na plataforma pra comprar produtos.
              Caso não tenha saldo o suficiente, clique em <span>Comprar mais moedas</span>. Para ter uma visão completa da sua carteira,
              acesse o <span>Financeiro</span>.
            </p>
  
            <div class="price col-12">
  
              <div class="available-coins d-flex align-items-center mb-4">
                <div class="info d-flex justify-content-between align-items-center p-0 col-5">
                  <span>Moedas disponíveis:</span>
                  <img class="me-2" width="30px" height="30px" src="@/assets/img/lms/coin.png" alt="Coin">
                </div>
  
                <span class="coins">{{ TotalCoins }} {{ getCoinsName }}</span>
              </div>
  
              <div class="coins-to-pay d-flex align-items-center mb-4">
                <div class="info d-flex justify-content-between align-items-center p-0 col-5">
                  <span>Total do carrinho:</span>
                  <img class="me-2" width="30px" height="30px" src="@/assets/img/lms/coin.png" alt="Coin">
                </div>
  
                <span class="coins">{{ getCoinsPrice }} {{getCoinsName}}</span>
  
              </div>
  
              <div class="discount-coins d-flex align-items-center mb-4" :class="{'discount-coins-show' : discountCoins}">
                <div class="info d-flex justify-content-between align-items-center p-0 col-5">
                  <span>Desconto:</span>
                  <img class="me-2" width="30px" height="30px" src="@/assets/img/lms/coin.png" alt="Coin">
                </div>
  
                <span class="coins-off">{{ discountCoins }} {{ getCoinsName }}</span>
  
              </div>
  
              <div class="discount-coins-error" :class="{'discount-coins-error-show' : discountError}">
                <span>{{ discountErrorMessage }}</span>
              </div>
  
  
            </div>
  
            <div class="d-flex align-items-center justify-content-between mt-5 flex-wrap gap-4">
              <div class="d-flex align-items-center gap-3">
                <label for="voucher" class="form-label">
                  Cupom
                </label>
                <input v-model="ccForm.voucher" type="text" class="form-control voucher-input" name="voucher" id="inputVoucher"
                       aria-describedby="voucherHelp">
  
                <span class="btn-apply-voucher" @click="applyVoucher('coins')">
                  Aplicar
                </span>
              </div>
              <div class="buy-buttons d-flex justify-content-end align-items-center gap-3 flex-wrap">
                <span :class="{'btn-buy-buttons': needToBuyMoreCoins }" @click="openPackage">
                  Comprar mais moedas
                </span>
  
                <div v-if="!needToBuyMoreCoins" class="btn-checkout" @click="sendCheckoutCoins()">
                  <span class="btn-buy-buttons">
                    {{ canUseOnlyCoins ? 'Comprar produtos disponíveis com ' + getCoinsName : 'Concluir compra'}}
                  </span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="tab-pane fade" :class="{'active show': getGateways[0] === 'free'}" id="nav-free" role="tabpanel" aria-labelledby="nav-free-tab">
              
            <h3>Nenhum produto no carrinho!</h3>
  
  
            <!-- <div class="d-flex align-items-center justify-content-between mt-5 flex-wrap gap-4">
  
              <div class="buy-buttons d-flex justify-content-end align-items-center gap-3 flex-wrap">
  
                <div v-if="!needToBuyMoreCoins" class="btn-checkout" @click="sendCheckoutCoins()">
                  <span class="btn-buy-buttons">
                    Finalizar 'compra' gratuita
                  </span>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </template>
  
    </LmsBasicTemplate>
  </template>
  
  <script >
  import LmsBasicTemplate from "@/components/vt/BasicTemplate.vue";
  import {mapActions, mapGetters} from "vuex";
  
  // let emitter = require('tiny-emitter/instance');
  export default {
    name: "Checkout",
    components: {
      LmsBasicTemplate
    },
    watch: {
      getCart: {
        handler(newVal) {
          
          if(newVal?.length === 0) {
  
          //   this.$router.push({
          //     name: "LmsHome",
          //     params: {
          //       lmsSlug: this.$route.params.lmsSlug
          //     }
          //   });
          }
        }
      }
    },
    data(){
      return {
        navigationConfig: [],
        course: [],
        canUseOnlyCoins: false,
        modal_coins_buy: false,
        months: {
          "1": "Janeiro",
          "2": "Fevereiro",
          "3": "Março",
          "4": "Abril",
          "5": "Maio",
          "6": "Junho",
          "7": "Julho",
          "8": "Agosto",
          "9": "Setembro",
          "10": "Outubro",
          "11": "Novembro",
          "12": "Dezembro"
        },
        years: [],
        ccForm: {
          name: "",
          document: "",
          birthday: "",
          cellphone: "",
          ccNumber: "",
          ccMonth: "",
          ccYear: "",
          ccCvv: "",
          cardType: "",
          voucher: ""
        },
        ccFormError: {
          name: false,
          document: false,
          birthday: false,
          cellphone: false,
          ccNumber: false,
          ccMonth: false,
          ccYear: false,
          ccCvv: false
        },
        voucherInfo: "",
        discount: 0,
        discountCoins: 0,
        discountFiduciary: 0,
        discountError: false,
        discountErrorMessage: "",
        showFiduciaryDiscount: false,
        discountErrorMessageFiduciary: "",
      }
    },
    beforeMount() {
      let root_ = this;
      root_.navigationConfig = root_.getPageByName("LmsCheckout");
  
    },
    mounted() {
  
      let pagseguro_js = document.createElement("script");
      pagseguro_js.setAttribute("id", "pagseguro_security");
      pagseguro_js.setAttribute("src", "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js");
      if (document.getElementById('pagseguro_security')) {
        document.getElementById('pagseguro_security').remove();
      }
      document.head.appendChild(pagseguro_js);
      this.$store.dispatch("LmsCart/getCart");
  
    },
    created() {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear + 10; i >= currentYear; i--) {
        this.years.push(i);
      }
    },
    methods: {
      ...mapActions([
          'update', 'update_cart', 'update_cart_products', 'del_product_cart', 'update_points'
      ]),
      generateInstallments() {
      let root_ = this;
      let installments = [];
      let total = this.getCartTotals.price;
      let minPrice = this.getGatewaysMinValues?.pagseguro ?? 500;
      let maxInstallments = 12;

      if(root_.voucherInfo?.active) {
        if (root_.voucherInfo?.is_percentage) {
          let percent = String(root_.voucherInfo.price).replace(",", ".");
          root_.discountFiduciary = parseInt(total * (percent / 100));
          total = total - root_.discountFiduciary;
        } else {
          if (root_.voucherInfo.price > total) {
            root_.discountFiduciary = total;
            total = 0;
          } else {
            total = total - root_.voucherInfo.price;
            root_.discountFiduciary = root_.voucherInfo.price;
          }
        }
      }

      for(let i = 1; i <= maxInstallments; i++) {
        let valueInstallment = (total / i);
        let installment = {
          installment: i,
          value: parseFloat((total / i) / 100).toFixed(2).replace('.', ',')
        }

        if(valueInstallment > minPrice) {
          installments.push(installment);
        }
      }

      return this.installments = installments;
    },
      removeCartItem(cartItem) {
  
        let root_ = this;
        
        console.log('cartItem => ', cartItem);

        let l_ = { cart_id: cartItem.id };

        console.log('l_ => ', l_);
        console.log(this.products);
        this.del_product_cart(l_);
        // if (this.products_n.length > 0)
        // {
        //     this.updateCartLocal(null);
        // } else {
        //     this.clearCart(null);
        // }

        root_.$store.dispatch("LmsCart/removeItemFromCart", cartItem)
  
      },
      sendCheckoutCoins(){
  
        let root_ = this;
  
        let payload = {
          "gateway": "coins"
        }
  
        payload.voucher = root_.voucherInfo?.code ?? "";
  
        root_.sendCheckout(payload);
      },
      sendCheckoutCc(gateway){
  
        let root_ = this;
        if(root_.hasCcFormAnyError()){
          return;
        }
  
        let payload = {
          "gateway": gateway
        }
  
        this.$store.dispatch("LmsCart/getPublicKey")
            .then(resolve => {
              resolve;
              let card = window.PagSeguro.encryptCard({
                publicKey: root_.getPublicKey,
                holder: root_.ccForm.name,
                number: parseInt(root_.ccForm.ccNumber.replace(/\s/g, "")),
                expMonth: parseInt(root_.ccForm.ccMonth),
                expYear: root_.ccForm.ccYear,
                securityCode: parseInt(root_.ccForm.ccCvv)
              });
  
  
              if(card.hasErrors) {
                root_.$swal({
                  title: "Dados inválidos",
                  text: "Favor verificar os dados do cartão de crédito e tentar novamente.",
                })
                return;
              }
  
              payload.hash_cc = card.encryptedCard;
              payload.tax_id = root_.ccForm.document.replace(/\D/g, '');
              payload.name = root_.ccForm.name; // adicionado para colocar o nome no invoice
  
              root_.sendCheckout(payload);
  
            });
  
  
      },
      sendCheckout(payload) {
  
        let root_ = this;
  
        this.$store.dispatch("LMS/toggleLoading", true)
            .then(() => {
  
              this.$store.dispatch("LmsCart/cartCheckout", payload)
                  .then(response => {
                    response;
  
                    this.update_cart_products(null);
  
                    let title = "Compra realizada com sucesso!";
                    let text = "Você já pode acessar seu conteúdo!";
  
                    
  
                    this.$swal({
                      title: title,
                      text: text,
                    })
                        .then(() => {
                          //direciona aqui
                          // root_.$router.push({
                          //   name: "LmsStudentProgress",
                          //   params: {
                          //     lmsSlug: root_.$route.params.lmsSlug
                          //   }
                          // });

                          if (this.$route.name == 'Checkout')
                            {
                            this.$router.push({name: 'Home'});
                            }
  
                        });
                    

                  })
                  .catch(error => {
  
                    let title = "Compra não processada.";
                    let text = "Favor tente novamente mais tarde ou entre em contato com o suporte.";
  
                    if(error?.cart === "All items from cart doesn't accept this gateway."){
                      title = "Método de pagamento não aceito";
                      text = "A forma de pagamento selecionada não é aceita por todos os produtos do carrinho."
                    }
  
                    this.$swal({
                      title: title,
                      text: text,
                    });
                  })
                  .finally(() => {
                    root_.$store.dispatch("LMS/toggleLoading", false);
                  });
  
  
            });
  
  
      },
      openPackage() {
        // emitter.emit("buy-coins");
        this.$parent.openPackage();
      },
      onInputDocument(e) {
        let document = e.target.value;
  
        if(e.type === "focusout" && document.length < 14){
          this.ccFormError.document = true;
          return;
        }
  
        if(document.length < 14){
          return;
        }
  
        this.ccFormError.document = !this.checkCpfValid(document);
      },
      checkCpfValid(strCPF) {
        strCPF = strCPF.replace(/\D/g, '');
  
        if (strCPF.length !== 11 || /^(\d)\1{10}$/.test(strCPF)) {
          return false;
        }
  
        let sum = 0;
        let rest = 0;
  
        for (let i = 0; i < 9; i++) {
          sum += parseInt(strCPF.charAt(i)) * (10 - i);
        }
        rest = (sum * 10) % 11;
        if (rest === 10 || rest === 11) rest = 0;
        if (rest !== parseInt(strCPF.charAt(9))) {
          return false;
        }
  
        sum = 0;
        for (let i = 0; i < 10; i++) {
          sum += parseInt(strCPF.charAt(i)) * (11 - i);
        }
        rest = (sum * 10) % 11;
        if (rest === 10 || rest === 11) rest = 0;
        return rest === parseInt(strCPF.charAt(10));
  
  
      },
      onInputBirthday(e) {
  
        let birthday = e.target.value;
  
        if(e.type === "focusout" && birthday.length < 10){
          this.ccFormError.birthday = true;
          return;
        }
  
        if(birthday.length < 10) {
          return;
        }
  
        let format = 'DD/MM/YYYY';
        let parsedDate = this.$moment(e.target.value, format, true);
  
        if(!parsedDate.isValid()) {
          this.ccFormError.birthday = true;
          return;
        }
  
        let age = this.$moment().diff(parsedDate, 'years');
  
        this.ccFormError.birthday = age < 18;
      },
      onInputCellphone(e) {
        let cellphone = e.target.value;
  
        if(e.type === "focusout" && cellphone.length < 16){
          this.ccFormError.cellphone = true;
          return;
        }
  
        if(cellphone.length < 16){
          return;
        }
  
        let cellphoneParsed = cellphone.replace(/\D/g, '');
  
        this.ccFormError.cellphone = this.hasSameDigits(cellphoneParsed);
  
      },
      hasSameDigits(input) {
        const digitsOnly = input.replace(/\D/g, '');
        const firstDigit = digitsOnly.charAt(0);
  
        for (let i = 1; i < digitsOnly.length; i++) {
          if (digitsOnly.charAt(i) !== firstDigit) {
            return false;
          }
        }
  
        return true;
      },
      onInputCcNumber(e) {
        let ccNumber = e.target.value;
  
        if(e.type === "focusout" && ccNumber.length < 19){
          this.ccFormError.ccNumber = true;
          return;
        }
  
        if(ccNumber.length < 19){
          return;
        }
  
        this.ccFormError.ccNumber = !this.isCreditCardValid(ccNumber);
  
      },
      isCreditCardValid(cardNumber) {
        const cleanNumber = cardNumber.replace(/\s/g, '');
  
        if (!/^\d+$/.test(cleanNumber)) {
          return false;
        }
  
        const cardTypes = {
          visa: /^4\d{12}(?:\d{3})?$/,
          mastercard: /^5[1-5]\d{14}$/,
          amex: /^3[47]\d{13}$/,
          discover: /^6(?:011|5\d{2})\d{12}$/,
          dinersclub: /^3(?:0[0-5]|[68]\d)\d{11}$/,
          jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
          maestro: /^(?:5[0678]\d{14}|6(?:2[0-9]|5[0-9]\d{2})\d{12})$/,
          unionpay: /^(62\d{14,17})$/,
          elo: /^6(?:301|305|309|311|312|315|323|324|325|326|327|328|329|333|336|343|346|348|351|352|353|354|355|356|357|361|362|363|364|365|366|367|368|369|37|38)\d{12}$/,
          hipercard: /^(?:3841|6062|6370|6371|6372|6373|6374|6375|6376)\d{12}$/,
          aura: /^50\d{14,17}$/,
          elo_dinersclub: /^(6304|6706|6771|6709)\d{8}(?:\d{4})?$/,
          americanexpress_carteira: /^(6062|6063|6064|6065|6066|6067|6068|6069|607|608|609|610|611|612|613|614|615|616|617|618|619|620|621|622|623|624|625|626|627|628|629|630|631|632|633|634|635|636|637|638|639|640|641|642|643|644|645|646|647|648|649|650|651|652|653|654|655|656|657|658|659|660|661|662|663|664|665|666|667|668|669|670|671|672|673|674|675|676|677|678|679|680|681|682|683|684|685|686|687|688|689|690|691|692|693|694|695|696|697|698|699|700|701|702|703|704|705|706|707|708|709|710|711|712|713|714|715|716|717|718|719|720|721|722|723|724|725|726|727|728|729|730|731|732|733|734|735|736|737|738|739|740|741|742|743|744|745|746|747|748|749|750|751|752|753|754|755|756|757|758|759|760|761|762|763|764|765|766|767|768|769|770|771|772|773|774|775|776|777|778|779|780|781|782|783|784|785|786|787|788|789|790|791|792|793|794|795|796|797|798|799|800|801|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|820|821|822|823|824|825|826|827|828|829|830|831|832|833|834|835|836|837|838|839|840|841|842|843|844|845|846|847|848|849|850|851|852|853|854|855|856|857|858|859|860|861|862|863|864|865|866|867|868|869|870|871|872|873|874|875|876|877|878|879|880|881|882|883|884|885|886|887|888|889|890|891|892|893|894|895|896|897|898|899|900|901|902|903|904|905|906|907|908|909|910|911|912|913|914|915|916|917|918|919|920|921|922|923|924|925|926|927|928|929|930|931|932|933|934|935|936|937|938|939|940|941|942|943|944|945|946|947|948|949|950|951|952|953|954|955|956|957|958|959|960|961|962|963|964|965|966|967|968|969|970|971|972|973|974|975|976|977|978|979|980|981|982|983|984|985|986|987|988|989|990|991|992|993|994|995|996|997|998|999)\d{10,15}$/,
          cabal: /^604[1-9]\d{12}$/,
          elo_credsystem: /^(636368|636369)\d{10}$/,
          hiper: /^(637095|637612|637599|637609|637568)\d{10}$/,
          sorocred: /^(637596)\d{10}$/,
          alelo: /^(5067|3841|6362|6364|6365|6368|6003|6060|6061)\d{0,10}$/,
          creducard: /^6(?:0(?:\d{0,14})|5(?:\d{0,14}|99999(?:9(?:9(?:9(?:9(?:9(?:9(?:9(?:99?)?)?)?)?)?)?)?)?)?)|2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:2(?:22?)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?$/,
        }
  
        const cardType = Object.keys(cardTypes).find(type => cardTypes[type].test(cleanNumber));
  
        if (!cardType) {
          return false;
        }
  
        this.ccForm.cardType = cardType;
  
        // Algoritmo de Luhn
        let sum = 0;
        let alternate = false;
        for (let i = cleanNumber.length - 1; i >= 0; i--) {
          let digit = parseInt(cleanNumber.charAt(i), 10);
          if (alternate) {
            digit *= 2;
            if (digit > 9) {
              digit -= 9;
            }
          }
          sum += digit;
          alternate = !alternate;
        }
  
        return sum % 10 === 0;
      },
      onInputCvv(e) {
  
        let cvv = e.target.value;
  
        if(e.type === "focusout" && cvv.length < 3){
          this.ccFormError.ccCvv = true;
          return;
        }
  
        if(cvv.length < 3){
          return;
        }
  
        const cvvRegex = /^\d{3,4}$/;
  
        this.ccFormError.ccCvv = !cvvRegex.test(cvv);
  
      },
      onInputName(e) {
        let name = e.target.value;
  
        if(e.type === "focusout" && name.length < 5){
          this.ccFormError.name = true;
          return;
        }
  
        if(name.length < 5){
          return;
        }
  
        this.ccFormError.name = name.length < 5;
      },
      isDate(date) {
        let format = 'DD/MM/YYYY';
        let parsedDate = this.$moment(date, format, true);
  
        return parsedDate.isValid();
      },
      modalsHandler() {
        this.modal_cadastro_ = false;
        this.notification = false;
        this.camera = false;
        this.modal_login_ = false;
        this.modal_validacao_ = false;
        this.modal_RememberPassword = false;
        this.modal_ResetPassword = false;
        this.modal_PoliticaDePrivacidade = false;
        this.modal_Termos = false;
        this.modal_package = false;
        this.modal_package_buy = false;
        this.modal_guest_buy = false;
        this.modal_package_payments = false;
        this.modal_coins_buy = false;
        this.modal_ticket_gate = false;
        this.modal_package_single = false;
        this.modal_private_messages = false;
        this.modal_quest = false;
  
        switch (this.$route.name) {
          case "Signup":
            this.modal_cadastro_ = true;
            break;
          case "Login":
            this.modal_login_ = true;
            break;
          case "EmailConfirmation":
            this.modal_validacao_ = true;
            break;
          case "RememberPassword":
            this.modal_RememberPassword = true;
            break;
          case "ResetPassword":
            this.modal_ResetPassword = true;
            break;
          case "PoliticaPage":
            this.modal_PoliticaDePrivacidade = true;
            break;
          case "TermosPage":
            this.modal_Termos = true;
            break;
          default:
            var dados = this.$store.getters['signup/cadastro'];
            for (const dadosKey in dados) {
              if(dados[dadosKey] && dados[dadosKey].length>0){
                this.modal_cadastro_=true;
              }
            }
        }
      },
      hasCcFormAnyError() {
  
        let error = false;
  
        for (let field in this.ccForm) {
          if( field === 'voucher') { continue; }
          if (!this.ccForm[field] && this.ccForm[field] !== 0) {
            error = true;
            this.ccFormError[field] = true;
          }
        }
  
        return error;
      },
      maskMoney(value) {
        let v = String(value).replace(/\D/g, '');
        v = (v / 100).toFixed(2);
        v = v.replace(".", ",");
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
        return v;
      },
      applyVoucher() {
  
        let root_ = this;
  
        if(!root_.ccForm.voucher.length){
          root_.voucherInfo = [];
          root_.discount = 0;
          root_.discountCoins = 0;
          root_.discountError = false;
          root_.discountErrorMessage = "";
          root_.discountErrorMessageFiduciary = "";
          return;
        }
  
        this.$store.dispatch("LmsCart/validateVoucher", this.ccForm.voucher)
            .then(data => {
  
              root_.voucherInfo = data;
              root_.discountError = false;
            })
            .catch(error => {
  
              root_.discountErrorMessage = "Cupom não existe.";
              root_.discountErrorMessageFiduciary = "Cupom não existe.";
  
              if(error === "User already used this Cart Voucher.") {
                root_.discountErrorMessage = "Cupom já utilizado.";
                root_.discountErrorMessageFiduciary = "Cupom já utilizado.";
              }
  
              root_.discountError = true;
              root_.showFiduciaryDiscount = true;
              root_.voucherInfo = [];
              root_.discount = 0;
              root_.discountCoins = 0;
  
            });
  
      },
      onInputMonth() {
        this.ccFormError.ccMonth = false;
      },
      onInputYear() {
        this.ccFormError.ccYear = false;
      }
    },
    computed:{
      ...mapGetters('LMS', [
        "getPageByName", "getCoinsName"
      ]),
      ...mapGetters('LmsCart', [
        "getCart", "getCartTotals", "getGateways", "getPublicKey", "getGatewaysMinValues"
      ]),
      ...mapGetters([
        "TotalCoins", "products",  "products_n"
      ]),
      needToBuyMoreCoins() {
        return this.getCoinsPrice > this.TotalCoins;
      },
      getActiveMonths() {
        let today = new Date();
        let currentYear = today.getFullYear();
  
        if (currentYear === this.ccForm.ccYear) {
          let currentMonth = today.getMonth() + 1;
          let activeMonths = {};
  
          for (let i = currentMonth; i <= 12; i++) {
            activeMonths[i] = this.months[i];
          }
  
          return activeMonths;
        }
  
        return this.months;
      },
      getCoinsPrice(){
  
        let root_ = this;

      if(!root_.getCart?.length){
        return 0;
      }

      let originalPrice = root_.getCartTotals.coins;

      let price = originalPrice;

      if(root_.voucherInfo?.active && root_.voucherInfo?.is_percentage) {
        let percent = String(root_.voucherInfo.coins).replace(",", ".");
        root_.discountCoins = parseInt(price * ( percent / 100));

        price = price - root_.discountCoins;

      } else if(root_.voucherInfo?.active && root_.voucherInfo.coins) {

        if(root_.voucherInfo.coins > price) {

          root_.discountCoins = price;
          price = 0;

          return price;

        } else {

          price = price - root_.voucherInfo.coins;
          root_.discountCoins = root_.voucherInfo.coins;

          let minPrice = this.getGatewaysMinValues?.coins ?? 1;

          if(price >= 1 && price < minPrice) {

            root_.discountCoins = originalPrice - minPrice;
            price = minPrice;

          }

        }

      }

      return price;
      },
  
      getFiduciaryPrice() {
  
        let root_ = this;

      if(!root_.getCart?.length){
        return 0;
      }

      let originalPrice = root_.getCartTotals.price;
      let price = originalPrice;

      if(root_.voucherInfo?.active) {

        if (root_.voucherInfo?.is_percentage) {
          let percent = String(root_.voucherInfo.price).replace(",", ".");
          root_.discountFiduciary = parseInt(price * (percent / 100));

          price = price - root_.discountFiduciary;
        } else {

          if (root_.voucherInfo.price > price) {

            root_.discountFiduciary = price;
            price = 0;
          } else {

            price = price - root_.voucherInfo.price;
            root_.discountFiduciary = root_.voucherInfo.price;

          }

        }

        root_.discountErrorMessageFiduciary = `Desconto de R$ ${root_.maskMoney(root_.discountFiduciary)}`;
        root_.showFiduciaryDiscount = true;
      }

      let minPrice = this.getGatewaysMinValues?.pagseguro ?? 100;

      if(price !== 0 && price < minPrice) {

        root_.discountFiduciary = originalPrice - minPrice;
        price = minPrice;

        root_.discountErrorMessageFiduciary = "Valor mínimo do pedido.";
      }
      
      if(isNaN(root_.getCartTotals.price)) {
        root_.showFiduciaryDiscount = true;
        root_.discountErrorMessageFiduciary += " Produtos disponíveis em reais.";

      } else {
        this.generateInstallments();
      }

      return `Total: <strong>R$ ${root_.maskMoney(price)}</strong>`;
  
      }
  
    },
  
  }
  </script>
  
  <style scoped lang="scss">
  
  .checkout-page {
  
    #nav-tabContent {
  
      h3 {
        color: #706F6F;
        font-family: "Hanken Grotesk";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
  
      p {
        color: #706F6F;
        font-family: "Hanken Grotesk";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
  
        span {
          color: #706F6F;
          font-family: "Hanken Grotesk";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
  
      }
  
      .price {
  
        color: #706F6F;
        font-family: "Hanken Grotesk";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
  
  
        .coins {
          color: #706F6F;
          font-family: "Hanken Grotesk";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
  
        .discount-coins {
  
          visibility:hidden;
          opacity:0;
          transition:visibility 0.3s linear,opacity 0.3s linear;
  
          &.discount-coins-show {
            visibility:visible;
            opacity:1;
  
          }
  
        }
  
        .coins-off {
          font-weight: 400;
        }
  
  
  
      }
  
      .buy-buttons {
        color: #706F6F;
        span {
          color: #706F6F;
          font-family: "Hanken Grotesk";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
  
          &.btn-buy-buttons {
            border-radius: 20px;
            background: var(--buttons_color);;
            padding: 10px;
            color: #FFFFFF;
          }
  
        }
  
        button {
  
          border-radius: 20px;
          background: var(--buttons_color);;
          font-family: "Hanken Grotesk";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
  
      }
  
  
      .active {
        color: #706F6F !important;
        background-color: transparent;
      }
    }
  
    #ccForm {
  
      .discount {
        font-size: 14px;
        transition: 0.3s;
      }
  
      .voucher-input {
        width: 40%;
      }
  
      .error {
        color: #E82E2E;
        font-family: "Hanken Grotesk";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  
    .btn-apply-voucher {
      border-radius: 20px;
      background: var(--buttons_color);;
      padding: 10px;
      color: #FFFFFF;
      cursor: pointer;
    }
  
  }
  
  
  .course-card {
    height: auto !important;
    &:not(:first-child) {
      margin-top: 10px;
    }
  
  
    .card-title {
      cursor: default;
  
      svg {
        fill: var(--buttons_color);;
      }
  
      span {
        color: #706F6F;
        font-family: "Hanken Grotesk";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: none;
      }
    }
  
    .card-body {
  
      .thumb-title {
        position: relative;
        align-items: center;
        padding: 15px 0 20px 0;
  
        .course-thumbnail {
  
          img {
            width: 100%;
            object-fit: cover;
            object-position: top center;
          }
        }
  
        .title-author {
          .course-title {
            color: #706F6F;
            font-family: "Hanken Grotesk";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
  
          .course-author {
            color: #706F6F;
            font-family: "Hanken Grotesk";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
  
        }
  
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 4%;
          width: 93%;
          height: 1px;
          background-color: #E1E1E1;
        }
  
      }
  
      .description-price {
        .description {
          color: #706F6F;
          font-family: "Hanken Grotesk";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: left;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-direction: column;
          max-height: 5.4em;
          overflow: hidden;
          text-overflow: ellipsis;
  
        }
  
        .price {
          color: var(--buttons_color);;
          font-family: "Hanken Grotesk";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
  
        .instalments {
          color: #706F6F;
          font-family: "Hanken Grotesk";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
  
        .remove-cart-item {
          cursor: pointer;
          font-size: 25px;
  
          &:hover {
            color: #DC3545 !important;
          }
        }
  
      }
  
  
  
    }
  
  
  }
  
  .discount-coins-error {
    visibility:hidden;
    opacity:0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  
    &.discount-coins-error-show {
      visibility:visible;
      opacity:1;
    }
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  
  </style>