import axios from 'axios'

export default {
    welcomeCommunity({rootState}, payload) {
        let url = this._vm.$url_api + 'api/lms/welcome/community/' + payload.communitySlug
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        
        let params = {
            slug: payload.lmsSlug
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params}).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error.response)
            })
        })
    },
    getLmsSlideShow({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/slides'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        
        let params = {
            lms_slug: payload
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params}).then((res) => {
                console.log(res)
                resolve(res)
            }).catch((error) => {
                reject(error.response)
            })
        })
    },
    getComments({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/comments'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };
        let params = {
            post_id: payload.post_id,
            offset: payload.offset
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params}).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error.response)
            })
        })
    },
    getLmsPosts({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/posts'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };

        let params;
        if(payload.only){
            params = {
                lms_slug: payload.slug,
                offset: payload.offset,
                only: payload.only
            };
            if(payload.communitySlug){
                params.community_slug = payload.communitySlug
            }
        } else {
            params = {
                lms_slug: payload.slug,
                offset: payload.offset
            };
            if(payload.communitySlug){
                params.community_slug = payload.communitySlug
            }
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params}).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error.response)
            })
        })
    },

    getMetaData({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/getMetaDatas'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        };

        let params = {
            url: payload.url,
        };


        return new Promise((resolve, reject) => {
            axios.get(url, {headers: headers, params}).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error.response)
            })
        })
    },

    likeComment({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/like-comment'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }

        const requestData = {
            comment_id: payload.comment_id,
        };

        return new Promise((resolve, reject) => {
            axios.post(url, requestData, {headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        })
    },

    likePost({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/like-post'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }

        const requestData = {
            post_id: payload.post_id,
        };

        return new Promise((resolve, reject) => {
            axios.post(url, requestData, {headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        })
    },

    publishPost({ rootState }, payload) {
        let url = this._vm.$url_api + 'api/feed/store-post';
        let headers = {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${rootState.token}`
        };
      
        const formData = new FormData();
        formData.append('event_id', rootState.event_id);
        formData.append('detail', payload.detail);
        formData.append('file_type', payload.file_type);
      
        if (payload.photo_url instanceof File) {
          formData.append('photo_url', payload.photo_url);
        } else {
          formData.append('photo_url', payload.photo_url);
        }

        if (payload.community_slug) {
            formData.append('community_slug', payload.community_slug);
        }
      
        formData.append('lms_slug', payload.lms_slug);
      
        console.log(formData);
      
        return new Promise((resolve, reject) => {
          axios.post(url, formData, { headers: headers })
            .then(response => {
              resolve(response);
            }).catch(error => {
              reject(error);
            });
        });
    },

    reactPost({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/react-post'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }

        const requestData = {
            post_id: payload.post_id,
            reaction: payload.reaction,
        };

        return new Promise((resolve, reject) => {
            axios.post(url, requestData, {headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        })
    },

    deletePost({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/delete-post'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }

        const requestData = {
            post_id: payload.post_id,
        };

        return new Promise((resolve, reject) => {
            axios.post(url, requestData, {headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        })
    },

    reportPost({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/report'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }

        const requestData = {
            post_id: payload.post_id,
        };

        return new Promise((resolve, reject) => {
            axios.post(url, requestData, {headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        })
    },

    storeComment({rootState}, payload) {
        let url = this._vm.$url_api + 'api/feed/store-comment'
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }

        const requestData = {
            comment: payload.comment,
            post_id: payload.post_id,
        };

        return new Promise((resolve, reject) => {
            axios.post(url, requestData, {headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        })
    }
}
