<template>
    <div id="user-address">
        <div class="box" id="user-address-container">
            <a href="#" id="btn-close" ref="btn-close" v-on:click="$parent.user_address=false"><img :src="$url + 'assets/img/oracle/x_24.png'"></a>
            <br>
            <iframe v-show="iframe" :src="iframe" frameborder="0" id="iframe_checkout" ref="iframe_checkout" width="600" height="400"></iframe>
            <h3>Dados pessoais</h3>
            <hr>
            <form id="user-address-form" ref="user-address-form">
                <input type="hidden" name="total" id="total" :value="total">
                <input type="hidden" name="items" id="items" :value="JSON.stringify(products)">
                <ul>
                    <li>
                        <label for="name">Nome completo *</label>
                        <input type="text" maxlength="191" name="name" id="name" ref="name" placeholder="João da Silva">
                    </li>
                    <li>
                        <label for="birth">Nascimento *</label>
                        <input type="text" maxlength="10" name="birth" id="birth" ref="birth" placeholder="DD/MM/AAAA" v-on:keyup="mask_birth">
                    </li>
                    <li>
                        <label for="cpf">CPF *</label>
                        <input type="text" name="cpf" id="cpf" ref="cpf" v-on:keyup="mask_cpf" placeholder="000.000.000-00">
                    </li>
                    <li>
                        <label for="gender">Sexo</label>
                        <select name="gender" id="gender" ref="gender">
                            <option value="">Sexo</option>
                            <option value="1">Masculino</option>
                            <option value="2">Feminino</option>
                        </select>
                    </li>
                    <li>
                        <label for="phone">Telefone</label>
                        <input type="text" name="phone" id="phone" ref="phone" v-on:keyup="mask_phone" placeholder="(00) 0000000000">
                    </li>
                    <li>
                        <label for="cep">CEP *</label>
                        <input type="text" name="cep" id="cep" ref="cep" v-on:keyup="mask_cep" placeholder="00000-000">
                    </li>
                    <li>
                        <label for="uf">UF</label>
                        <select name="uf" id="uf" ref="uf" v-on:change="cityHandler">
                            <option value="">UF</option>
                            <option value="1">Acre</option>
                            <option value="2">Alagoas</option>
                            <option value="3">Amapá</option>
                            <option value="4">Amazonas</option>
                            <option value="5">Bahia</option>
                            <option value="6">Ceará</option>
                            <option value="7">Distrito Federal</option>
                            <option value="8">Espírito Santo</option>
                            <option value="9">Goiás</option>
                            <option value="10">Maranhão</option>
                            <option value="11">Mato Grosso</option>
                            <option value="12">Mato Grosso do Sul</option>
                            <option value="13">Minas Gerais</option>
                            <option value="14">Pará</option>
                            <option value="15">Paraíba</option>
                            <option value="16">Paraná</option>
                            <option value="17">Pernambuco</option>
                            <option value="18">Piauí</option>
                            <option value="19">Rio de Janeiro</option>
                            <option value="20">Rio Grande do Norte</option>
                            <option value="21">Rio Grande do Sul</option>
                            <option value="22">Rondônia</option>
                            <option value="23">Roraima</option>
                            <option value="24">Santa Catarina</option>
                            <option value="25">São Paulo</option>
                            <option value="26">Sergipe</option>
                            <option value="27">Tocantins</option>
                        </select>
                    </li>
                    <li>
                        <label for="city">Cidade</label>
                        <select name="city" id="city" ref="city">
                            <option value="">Escolha a cidade por favor</option>
                            <option v-for="(item, index) in cities" :key="index" :value="item.id" :selected="item.id == id_city_temp">{{ item.name }}</option>
                        </select>
                    </li>
                    <li>
                        <label for="address">Endereço</label>
                        <input type="text" name="address" id="address" ref="address" placeholder="Seu endereço">
                    </li>
                    <li>
                        <label for="number">Número</label>
                        <input type="text" name="number" id="number" ref="number" placeholder="Número">
                    </li>
                    <li>
                        <label for="complement">Complemento</label>
                        <input type="text" name="complement" id="complement" ref="complement" placeholder="Complemento">
                    </li>
                    <li>
                        <br>
                        * = Dados obrigatórios
                        <br><br>
                        <button class="btn-0" type="button" v-on:click="$parent.user_address=false">Cancelar</button>
                    </li>
                    <li>
                        <br>
                        <input class="btn-1" type="button" name="btn-submit" id="btn-submit" value="Enviar" v-on:click="save">
                    </li>
                </ul>
            </form>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
    export default {
        name: "UserAddress",
        computed: {
            ...mapGetters([
                'counter', 'token', 'logged', 'products', 'products_ids', 'products_n', 'points', 'id', 'email', 'first_name', 'last_name', 'store_urls'
            ])
        },
        data() {
            return {
                id_city_temp: 0,
                cities: [],
                iframe: null,
                total: 0
            }
        },
        methods: {
            ...mapActions([
                'update', 'update_cart', 'update_cart_products', 'del_product_cart', 'update_points'
            ]),
            
            check(e)
            {
                if ((e.key !== "Backspace") || (e.key !== "ArrowLeft") || (e.key !== "ArrowRight") || (e.key !== "Tab") || (e.key !== "Delete"))
                {
                    if (!/^[0-9]*$/.test(e.key))
                    {
                        e.preventDefault();
                        return false;
                    }
                }
            },
            just_number(v)
            {
                return v.replace(/\D/g, '');
            },
            just_alphanumeric(v)
            {
                return v.replace(/[^0-9a-z]+/gi, '');
            },
            mask_cep(e, value)
            {
                if (e)
                {
                    this.check(e);
                    let v = this.just_number(e.currentTarget.value).match(/(\d{0,5})(\d{0,3})/);
                    e.currentTarget.value = !v[2] ? v[1] : v[1] + '-' + v[2];
                }
                else if (value)
                {
                    let v = this.just_number(value).match(/(\d{0,5})(\d{0,3})/);
                    return !v[2] ? v[1] : v[1] + '-' + v[2];
                }
                else
                {
                    return null;
                }
            },
            mask_birth(e, value)
            {
                if (e)
                {
                    this.check(e);
                    let x = this.just_number(e.currentTarget.value).match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
                    e.currentTarget.value = !x[2] ? x[1] : x[1] + '/' + x[2] + (x[3] ? '/' + x[3] : '');
                }
                else if (value)
                {
                    let x = this.just_number(value).match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
                    return !x[2] ? x[1] : x[1] + '/' + x[2] + (x[3] ? '/' + x[3] : '');
                }
                else
                {
                    return null;
                }
            },
            mask_phone(e, value)
            {
                if (e)
                {
                    this.check(e);
                    let x = this.just_number(e.currentTarget.value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                    e.currentTarget.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
                }
                else if (value)
                {
                    let x = this.just_number(value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
                }
                else
                {
                    return null;
                }
            },
            mask_cpf(e, value)
            {
                if (e)
                {
                    this.check(e);
                    let v = this.just_number(e.currentTarget.value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                    e.currentTarget.value = !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
                }
                else if (value)
                {
                    let v = this.just_number(value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                    return !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
                }
                else
                {
                    return null;
                }
            },

            cityHandler(e)
            {
                let form_data = new FormData();
                form_data.append('uf', e.currentTarget.value);
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.store_urls.url_api + 'api/store/get_cities', form_data, { headers: headers }).then(response => this.cityHandlerResponse(response));
                e.preventDefault();
            },
            cityHandlerResponse(r)
            {
                this.cities = r.data;
            },
            get_user_info()
            {
                this.$app.wait(true);
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.store_urls.url_api + 'api/store/userinfo', null, { headers: headers }).then(response => this.userInfoResponse(response));
            },
            userInfoResponse(r)
            {
                this.$app.wait(false);
                this.$refs['name'].value = r.data.name;
                /*
                $r['cpf']        = $last_order->cpf;
                $r['phone']      = $last_order->phone;
                $r['cep']        = $last_order->cep;
                $r['uf']         = $last_order->uf;
                $r['city']       = $last_order->city;
                $r['address']    = $last_order->address;
                $r['number']     = $last_order->number;
                $r['complement'] = $last_order->complement;
                */
                if (r.data.cpf)
                {
                    this.$refs['cpf'].value = this.mask_cpf(null, r.data.cpf);
                }
                if (r.data.phone)
                {
                    this.$refs['phone'].value = this.mask_phone(null, r.data.phone);
                }
                if (r.data.cep)
                {
                    this.$refs['cep'].value = this.mask_cep(null, r.data.cep);
                }
                if (r.data.city)
                {
                    this.id_city_temp = r.data.city;
                }
                if (r.data.uf)
                {
                    this.$refs['uf'].value = r.data.uf;
                    this.$refs['uf'].dispatchEvent(new Event('change'));
                }
                
                if (r.data.address)
                {
                    this.$refs['address'].value = r.data.address;
                }
                if (r.data.number)
                {
                    this.$refs['number'].value = r.data.number;
                }
                if (r.data.uf)
                {
                    this.$refs['complement'].value = r.data.complement;
                }
                if (r.data.gender)
                {
                    this.$refs['gender'].value = r.data.gender;
                }
                if (r.data.birth)
                {
                    console.log(this.just_number(r.data.birth));
                    this.$refs['birth'].value = this.mask_birth(null, r.data.birth);
                }
            },

            save(e)
            {
                this.$app.wait(true);
                let form_data = new FormData(this.$refs['user-address-form']);

                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.store_urls.url_api + 'api/store/user-address-save', form_data, { headers: headers }).then(response => this.saveResponse(response));
                e.preventDefault();
            },
            saveResponse(r)
            {
                console.log('r pay => ', r);
                this.$app.wait(false);
                if (r.data.errors)
                {
                    let st = '';
                    if ((typeof r.data.errors === 'object') && (r.data.errors !== null))
                    {
                        for(let o in r.data.errors)
                        {
                            st += (r.data.errors[o] + '\n');
                        }
                    }
                    else
                        st = r.data.errors;
                    this.$app.modal("error", "Atenção!", st , null, null, null, "OK");
                }
                else if(r.data.success == 1)
                {
                    this.update_cart_products(null);
                    this.$app.modal("error", "OK!", "Ok. Deu tudo certo." , null, null, null, "OK");
                    this.$parent.user_address = false;

                    window.open(r.data.checkout_url, '_blank');

                    //window.location.href = r.data.checkout_url;

                    //this.$parent.$refs['cart'].clearCart();
                    //this.$app.modal("error", "OK!", "Compra efetuada com sucesso." , null, null, null, "OK");

                
                    //this.$parent.$refs['kash-store'].login();
                }

            },
            
        },
        mounted() {
            this.total = 0;
            /*
            for (let o in this.products)
            {
                this.total += this.products[o].price;
            }
            */
            let c = 0;
            for (let o in this.products) {
                c += ((this.products[o].sale_price ? this.products[o].sale_price : this.products[o].price) * this.products[o].quantity);
            }
            this.total = Number(c).toFixed(2);
            this.get_user_info();
        }
    }
</script>

<style scoped>
    #user-address
    {
        background-color: rgba(0, 0, 0, .6);
        color: black;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    #user-address-container
    {
        box-sizing: border-box;
        display: block;
        margin: 150px  auto;
        max-height: 80%;
        max-width: 600px;
        overflow-y: auto;
        padding: 20px;
    }
</style>