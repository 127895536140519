<template>
    <div class="scrolling">
<!--        <div id="dimmerLogin" class="ui modals page dimmer">-->
            <div id="login" ref="login" class="ui translucent suimodal"
                 :class="{mini:!checkMobile,overlay:checkMobile,fullscreen:checkMobile}">
                <i class="close icon"/>
                <div class="header">
                    {{ $t('login.login') }}
                </div>
                <div class="content">
                    <div v-if="!webview">
                        <div v-if="this.switch.Login_Azure">
                            <LoginAzure origin="login" :active="true"/>
                        </div>
                    </div>
                    <form id="login-form" ref="login-form" class="ui form" @submit.prevent="login_">
                        <div class="field">
                            <input id="email" ref="email" v-model="logIn.emailL_" name="email" class="text" type="text"
                                   :placeholder="$t('login.email')">
                        </div>
                        <div class="field">
                            <input v-model="logIn.passwordL_" id="password" name="password" ref="password" class="text"
                                   type="password" :placeholder="$t('login.senha')">
                        </div>
                        <div class="field text-center resetPasswordLink" @click="navigateResetPassword" style="cursor: pointer;">
                            <!--                    esqueceu a senha-->
                            <svg width="18" height="21" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_855_127)">
                                    <path
                                        d="M10.8047 4.17908V3.0033C10.8047 2.51111 10.6224 2.09184 10.2578 1.74548C9.91146 1.3809 9.49219 1.19861 9 1.19861C8.50781 1.19861 8.07943 1.3809 7.71484 1.74548C7.36849 2.09184 7.19531 2.51111 7.19531 3.0033V4.17908H10.8047ZM8.17969 9.07361C8.41667 9.31059 8.6901 9.42908 9 9.42908C9.3099 9.42908 9.58333 9.31059 9.82031 9.07361C10.0573 8.83663 10.1758 8.56319 10.1758 8.2533C10.1758 7.9434 10.0573 7.66996 9.82031 7.43298C9.58333 7.196 9.3099 7.07751 9 7.07751C8.6901 7.07751 8.41667 7.196 8.17969 7.43298C7.94271 7.66996 7.82422 7.9434 7.82422 8.2533C7.82422 8.56319 7.94271 8.83663 8.17969 9.07361ZM12.5 4.17908C12.8099 4.17908 13.0833 4.29757 13.3203 4.53455C13.5573 4.7533 13.6758 5.01762 13.6758 5.32751V11.1791C13.6758 11.489 13.5573 11.7624 13.3203 11.9994C13.0833 12.2181 12.8099 12.3275 12.5 12.3275H5.5C5.1901 12.3275 4.91667 12.2181 4.67969 11.9994C4.44271 11.7624 4.32422 11.489 4.32422 11.1791V5.32751C4.32422 5.01762 4.44271 4.7533 4.67969 4.53455C4.91667 4.29757 5.1901 4.17908 5.5 4.17908H6.07422V3.0033C6.07422 2.20121 6.35677 1.51762 6.92188 0.952515C7.50521 0.369181 8.19792 0.0775146 9 0.0775146C9.80208 0.0775146 10.4857 0.369181 11.0508 0.952515C11.6341 1.51762 11.9258 2.20121 11.9258 3.0033V4.17908H12.5Z"
                                        fill="white"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_855_127" x="0.324219" y="0.0775146" width="17.3516"
                                            height="20.25" filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                       result="hardAlpha"/>
                                        <feOffset dy="4"/>
                                        <feGaussianBlur stdDeviation="2"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix"
                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                 result="effect1_dropShadow_855_127"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_855_127"
                                                 result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                            {{ $t('login.titleEsqueciSenha') }}
                        </div>
                        <div v-if="show_captcha && this.switch.captcha_client" class="field" ref="recaptcha_parent">
                            <vue-recaptcha ref="recaptcha" :sitekey="this.switch.captcha_client"
                                           @render="checkCaptcha = false" @verify="checkCaptcha = true;"
                                           @expired="checkCaptcha = false;"/>
                        </div>
                        <button v-if="!checkCaptcha" ref="btn-submit" class="fluid ui button positive" type="button"
                                disabled v-text="btn_submit_value"/>
                        <button v-else ref="btn-submit" class="fluid ui button positive" type="submit"
                                v-text="btn_submit_value"/>
                    </form>
                    <div v-if="!webview" class="socials">
                        <div v-if="this.switch.Login_Gmail">
                            <LoginGoogle origin="login"/>
                        </div>
                        <div v-if="this.switch.Login_Facebook">
                            <LoginFacebook origin="login"/>
                        </div>
                    </div>
                    <p class="ainda">{{ $t('login.aindaNaoE') }} <a
                        @click="openModal(false);$app.$refs.SignupModal.openModal(true);">{{
                            $t('login.cadastreSeAqui')
                        }}</a></p>
                </div>
            </div>
<!--        </div>-->
    </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from "vuex";
import {VueRecaptcha} from 'vue-recaptcha';
import LoginFacebook from '@/components/login/LoginFacebook';
import LoginGoogle from '@/components/login/LoginGoogle';
import LoginAzure from '@/components/login/LoginAzure';

export default {
    name: 'Login',
    components: {
        LoginFacebook, LoginGoogle, VueRecaptcha, LoginAzure
    },
    data() {
        return {
            btn_submit: false,
            btn_submit_value: this.$i18n.t('login.entrar'),
            error: null,
            captcha: null,
            checkCaptcha: false
        }
    },
    computed: {
        ...mapGetters([
            'webview', 'switch', 'reward_hash', 'checkMobile', 'securityContainer'
        ]),
        logIn() {
            var response = this.$store.getters['signup/cadastro']
            return response
        },
        show_captcha() {
            return (this.switch && this.switch.captcha && this.switch.captcha == 1)
        }
    },
    mounted() {
        $(window).on('resize', this.rescaleCaptcha);
    },
    updated() {
        this.btn_submit_value = this.$i18n.t('login.entrar');
        if (!this.show_captcha)
            this.checkCaptcha = true
    },
    destroyed() {
    },
    beforeDestroy() {
        window.$('#login').suimodal('hide').remove();
        $(window).off('resize', this.rescaleCaptcha);
    },
    methods: {
        ...mapActions([
            'update_email', 'update_email_verified', 'update_type_validation', 'update_logged', 'update_token', 'update_id', 'update_login_social'
        ]),
        navigateResetPassword() {
            this.$router.push({ name: 'RememberPassword'});
        },
        analytics(event_name, data) {
            event_name, data;
            //page, event, data;
            if (this.$gtag && this.securityContainer.admin_config.analytics) {
                this.$gtag.event(event_name, data);
            }
            //console.log('analytics => ', event_name, data);
        },
        block(v) {
            this.$app.wait(v);
            this.btn_submit_value = (v ? (this.$i18n.t('login.aguarde')) : this.$i18n.t('login.entrar'));
            this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
            this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
        },
        loginSocial(data) {
            this.$app.loginSocial(data, this.block, this.loginResponse, this.loginError)
        },
        login_(e) {
            this.error = null;
            if (this.logIn.emailL_.length <= 0) {
                this.error = this.$i18n.t('login.erroEmailNulo');
            } else if (this.logIn.passwordL_.length <= 0) {
                this.error = this.$i18n.t('login.erroSenhaNula');
            }
            if (this.error) {
                window.$.suimodal({
                    title: this.$i18n.t('login.erro'),
                    content: this.error,
                    className: {
                        suimodal: 'ui suimodal mini translucent background-transparent'
                    },
                    autoShow: true,
                    closable: true,
                    allowMultiple: true,
                    onDeny: function () {
                        return true;
                    },
                    onApprove: function () {
                        return true;
                    },
                    actions: [
                        {
                            text: this.$i18n.t('OK'),
                            className: 'ui button positive',
                            on: function () {
                                return true;
                            }
                        }
                    ]
                })
                e.preventDefault();
            } else {
                this.sendLogin(e);
            }
        },
        sendLogin(e) {
            if (!this.submit) {
                this.block(true);
                let form_data = new FormData(this.$refs["login-form"]);
                form_data.append('event_name', this.switch.Nome_Aba ?? this.getCookie("switch_Nome_Aba") ?? localStorage.getItem("eventmap") ?? "");
                axios.post(this.$url_api + 'api/login', form_data, {
                    headers: {'language': this.$i18n.locale,}})
                  .then(response => this.loginResponse(response)).catch(error => this.loginError(error.response));
            }
            e.preventDefault();
        },
        async loginResponse(obj, social = false, type = 'email', from = "login", email = null) {
            this.block(false);
            email = obj.data.email;
            this.$app.loginResponse(obj, social, type, from, email)
        },
        loginError(obj) {
            this.block(false);
            let str = '';
            for (let o in obj.data.errors) {
                str += obj.data.errors[o] + '\n';
            }
            //this.error = str;
            window.$.suimodal({
                title: this.$i18n.t('login.erro'),
                content: str,
                className: {
                    suimodal: 'ui suimodal mini translucent background-transparent'
                },
                autoShow: true,
                closable: true,
                allowMultiple: true,
                onDeny: function () {
                    return true;
                },
                onApprove: function () {
                    return true;
                },
                actions: [
                    {
                        text: this.$i18n.t('OK'),
                        className: 'ui button positive',
                        on: function () {
                            return true;
                        }
                    }
                ]
            })
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
                this.checkCaptcha = false
            }
            if (obj.data.errors["register_first"]) {
                this.$app.$refs.SignupModal.openModal(true);
                this.openModal(false)
            }
        },
        async openModal(open) {
            let root = this;
            /* global $ */
            if (open) {
                if (!this.show_captcha)
                    this.checkCaptcha = true
                $('#login').suimodal({
                    dimmerSettings: {opacity: 0.5},
                    onShow() {
                    },
                    onHidden() {
                    },
                    onVisible() {
                        root.analytics('page_view', {
                            page_title: 'Login Page',
                            page_location: '/#/login'
                        })
                        root.$refs['email'].focus();
                        let eventName = new Event('closeMenu');
                        document.dispatchEvent(eventName);
                        if (root.show_captcha && root.$refs.recaptcha) {
                            root.$refs.recaptcha.reset();
                            root.rescaleCaptcha(true);
                            root.checkCaptcha = false
                        }
                    }
                }).suimodal('show');
            } else {
                $('#login').suimodal('hide');
            }
        },
        rescaleCaptcha() {
            if (this.$refs.recaptcha) {
                let width = this.$refs.recaptcha_parent?.offsetWidth??300;
                let scale = width / 302;
                this.$refs.recaptcha.$refs.root.style.transform = 'scale(' + scale + ')';
                this.$refs.recaptcha.$refs.root.style.transformOrigin = '0 0';
                this.$refs.recaptcha.$refs.root.style.webkitTransform = 'scale(' + scale + ')';
                this.$refs.recaptcha.$refs.root.style.webkitTransformOrigin = '0 0';
            }
        }
    },
}
</script>
<style lang="scss" scoped>
#login.suimodal {
    padding: 64px 44px;

    .icon.close {
        top: 54px;
        right: 44px;
        color: #fff;
    }

    .text-left {
        text-align: left;
    }
    .resetPasswordLink {
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>