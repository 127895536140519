<template>
    <div id="ranking" ref="ranking">

        <div id="ranking-modal" class="modals" :class="{'bgAzul': showAsModal}" >
            <div v-on:click="closeModal" style="z-index: -1;width: 100%; height: 100%; position: fixed; top: 0px; left: 0px;"></div>
            <div class="main_modal" :class="{'w-100' : showAsModal}">
                <div class="main_modal_wrap">
<!--                    <div class="header_modal alignVertical">-->
<!--                        <div class="title-container">-->
<!--                            <span>Ranking</span>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="body_modal">
                        <div v-if="isMiniGameHeader" class="minigame-ranking-container">

                            <div class="ui center aligned">
                                <div class="ui header">
                                    {{ $t('ranking.ranking') }}
                                </div>

                                <div class="tab-menu">
                                    <button data-tab="ranking-general" data-active="true" @click="handleTab" class="active">{{ $t('ranking.ranking_general') }}</button>
                                    <button data-tab="ranking-region" data-active="false" @click="handleTab">{{ $t('ranking.ranking_region') }}</button>
                                </div>

                                <div class="ui tab active d-flex flex-column">
                                    <div v-for="(rank, idxRank) in selectedRanking" :key="idxRank"
                                         class="rank-item d-flex justify-content-between"
                                         :class="{'rank-selected': rank.user_id == id}"
                                    >
                                        <div class="left d-flex align-items-center">
                                    <span class="material-symbols-outlined">
                                        emoji_events
                                    </span>
                                            <div class="rank-name">
                                                <span class="rank">{{ rank.ranking }}º</span>
                                                <span class="name">{{ rank.user_name }}</span>
                                            </div>
                                        </div>
                                        <div class="right d-flex align-items-center justify-content-between">
                                            <div class="total_time">
                                                <span>{{rank.total_time}} {{ $t('ranking.sec') }}</span>
                                            </div>
                                            <div class="total_corrects">
                                                <span>{{rank.corrects}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="showAsModal && selectedUserRanking.ranking > 10">
                                        <div
                                             class="rank-item d-flex justify-content-between rank-selected"
                                        >
                                            <div class="left d-flex align-items-center">
                                                <span class="material-symbols-outlined">
                                                    emoji_events
                                                </span>
                                                <div class="rank-name">
                                                    <span class="rank">{{selectedUserRanking.ranking}}º</span>
                                                    <span class="name">{{ selectedUserRanking.user_name }}</span>
                                                </div>
                                            </div>
                                            <div class="right d-flex align-items-center justify-content-between">
                                                <div class="total_time">
                                                    <span>{{ selectedUserRanking.total_time }} {{ $t('ranking.sec') }}</span>
                                                </div>
                                                <div class="total_corrects">
                                                    <span>{{ selectedUserRanking.corrects }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="!showAsModal"  class="user-rank-position d-flex justify-content-between mt-4">
                                        <span>{{ $t('ranking.your_position')}}<span class="bold">{{ selectedTab }}</span></span>
                                        <span class="bold">{{ selectedPosition }}º{{ $t('ranking.place')}}</span>
                                    </div>
                                </div>
                            </div>

                            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,1,0" />
                        </div>

                        <div v-if="showAsModal" class="close-modal" @click="closeModal()">
                            <i class="times icon"></i>
                        </div>
                    </div>
                    <!-- <div class="footer_modal">
                    </div> -->
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import { mapGetters, mapActions } from "vuex";
let emitter = require('tiny-emitter/instance');
import $ from "jquery";

export default {
    name: 'RankingModal',
    props: {
      "showAsModal": {
          type: Boolean,
          default: true
      }
    },
    computed: {
        ...mapGetters([
            'id', 'isMiniGameHeader'
        ]),
    },
    data() {
        return {
            rankingGeneralPosition: 0,
            rankingGeneral: [],
            rankingRegion: [],
            rankingRegionPosition: 0,
            selectedRanking: [],
            selectedPosition: 0,
            selectedTab: '',
            userGeneral: [],
            userRegion: [],
            selectedUserRanking: [],
        }
    },
    components: {

    },
    methods: {
        ...mapActions([
        ]),
        handleTab(e) {

            $('.tab-menu').first().find('button').removeClass('active');
            e.currentTarget.classList.add('active');

            $('.ui.tab.segment[data-tab="ranking-general"], .ui.tab.segment[data-tab="ranking-region"]').removeClass('active');

            let tab = e.target.dataset.tab;
            var targetDiv = $('.ui.tab.segment[data-tab="' + tab + '"]');
            targetDiv.addClass('active');

            this.selectedRanking = this.rankingGeneral;
            this.selectedPosition = this.rankingGeneralPosition;
            this.selectedUserRanking = this.userGeneral;
            this.selectedTab = this.$t('ranking.ranking_general');
            if(tab === 'ranking-region') {
                this.selectedRanking = this.rankingRegion
                this.selectedPosition = this.rankingRegionPosition;
                this.selectedUserRanking = this.userRegion;
                this.selectedTab = this.$t('ranking.ranking_region');
            }


        },
        closeModal() {
            emitter.emit('Minigames::ranking::modal', false);
        },
    },
    beforeMount() {

        let vm = this;
        let minigameId = localStorage.getItem('minigame_id');

        vm.selectedTab = vm.$t('ranking.ranking_general');

        let payload = {
            "user_id": this.id,
            "minigame_id": minigameId
        }

        vm.$store.dispatch("Minigames/getMinigamesRanking", payload)
            .then(response => {

                vm.rankingGeneralPosition = response.user_ranking_general;
                vm.rankingRegionPosition = response.user_ranking_region;
                vm.rankingGeneral = response.general;
                vm.rankingRegion = response.region;

                vm.selectedRanking = vm.rankingGeneral;
                vm.selectedPosition = vm.rankingGeneralPosition;

                vm.userGeneral = response.user_general;
                vm.userRegion = response.user_region;
                vm.selectedUserRanking = vm.userGeneral;

            });


    }
}
</script>
<style lang="scss" scoped>

.minigame-content {
    width: 562px;
}

#ranking {

    .ranking-height {
        min-height: unset;
    }

    .minigame-ranking-container {

        .background-image {
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-blend-mode: saturation;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .ui.header {
            color: #FFFFFF;
            font-family: Exo, sans-serif;
            font-weight: 600;
            font-size: 28px;
            line-height: 32px;
            text-align: center;
            margin-bottom: 30px;
        }

        .ui.center.aligned {
            border-radius: 16px;
            padding: 36px;

            background-color: #00000099;

            .ui.tab.active {
                gap: 10px;


                .user-rank-position {
                    font-family: Exo, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                    color: #FFFFFF;

                    .bold {
                        font-weight: 700;
                    }
                }


            }

        }

        .tab-menu {
            display: flex;
            align-content: center;
            justify-content: center;
            margin: 0 0 50px 0;
            button{
                position: relative;
                font-family: Exo, sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                transition: all .3s ease;
                background: none;
                border: none;
                margin: 0;
                padding:0;
                cursor: pointer;
                border-radius: 0;
                padding: 0 30px 5px 30px;
                color: #FFFFFF66;
                outline: none;
                width: 245px;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    height: 3px;
                    width: 100%;
                    //background: #59A5B5;
                    background: #FFFFFF66;
                    transition: all .3s ease;
                }
                &.active{
                    color: #59A5B5;
                    font-weight: 700;
                    &::after{
                        background: #59A5B5;
                    }
                }
                &:hover{
                    color: #59A5B5;
                }
            }
        }

        .rank-item {

            padding: 8px;

            .left, .right {
                gap: 12px;
            }

            .right {
                width: 30%;
            }

            .rank-name {
                display: flex;
                gap: 12px;
                font-size: 16px;
                line-height: 21px;
                font-family: Exo, sans-serif;
                color: #FFFFFF;
                .rank {
                    font-weight: 700;
                }
                .name {
                    font-weight: 400;
                }
            }

            .total_time {
                font-family: Exo, sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #FFFFFF66;
                text-align: left;
            }

            .total_corrects {
                font-family: Exo, sans-serif;
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                text-align: right;
                color: #FFFFFF;

            }

            &.rank-selected {
                background-color: #59A5B51A;
            }

            .material-symbols-outlined {
                font-size: 18px;
                color: #59A5B5;
            }
        }
    }

    .body_modal {
        position: relative;

        .close-modal {
            position: absolute;
            color: #FFFFFF;
            font-size: 20px;
            top: 15px;
            right: 30px;
            cursor: pointer;
        }
    }

}







</style>