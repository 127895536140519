<template>
    <div>
        <div v-if="false" id="ambient-transition" ref="ambient-transition">
            <ul>
                <li v-if="!avatarActive"><a v-on:click="changeAmbientHandler"><img :src="$url + 'assets/img/avatar_transition/eye_ambient.png'" ></a></li>
                <li v-else><a v-on:click="changeAmbientHandler"><img :src="$url + 'assets/img/avatar_transition/avatar_ambient.png'"></a></li>
            </ul>
        </div>
        <div v-if="(quiz && this.current_session.quiz!==0 && this.id_form_actual != null)" id="quiz-transition" ref="quiz-transition">
            <ul>
                <li v-if="!quiz_active"><a v-on:click="questionHandler"><img class="imgquiz" :src="$url + 'assets/img/quiz/quiz.jpeg'" ></a></li>
                <li v-else><a v-on:click="questionHandler"><img class="imgquiz" :src="$url + 'assets/img/quiz/quiz.jpeg'" ></a></li>
            </ul>
        </div>


        <!--
        <div v-if="switch_avatar && this.$app.checkLive()" id="ambient-transition" ref="ambient-transition">
            <ul>
                <li v-if="!avatarActive"><a v-on:click="changeAmbientHandler"><img :src="$url + 'assets/img/avatar_transition/eye_ambient.png'" ></a></li>
                <li v-else><a v-on:click="changeAmbientHandler"><img :src="$url + 'assets/img/avatar_transition/avatar_ambient.png'"></a></li>
            </ul>
        </div>
        <div v-if="((quiz) && (id_form_actual != null))" id="quiz-transition" ref="quiz-transition">
            <ul>
                <li v-if="!quiz_active"><a v-on:click="questionHandler"><img :src="$url + 'assets/img/quiz/quiz_white.png'" ></a></li>
                <li v-else><a v-on:click="questionHandler"><img :src="$url + 'assets/img/quiz/quiz_red.png'" ></a></li>
            </ul>
        </div>-->
    </div>
</template>

<script>
    
    import { mapGetters } from "vuex";
    
    export default {
        name: 'AmbientTransition',
        computed: {
            ...mapGetters([
                'logged', 'token', 'switch', 'id_form_actual', 'sessions', 'current_session'
            ]),
        },
        data() {
            return {
                avatarActive : false,
                quiz_active  : false,
                quiz         : true,
                switch_avatar: true
            }
        },
        components: {
            
        },
        methods: {
            update_avatar() {
                switch(this.$route.name)
                {
                    case 'Iframe':
                        this.avatarActive = false;
                        this.$app.iframe_temp = false;
                    break;
                }
            },
            questionHandler(e) {
                e;
                this.quiz_active = !this.quiz_active;
                this.$app.modalIframe(this.$route.name);
            },
            changeAmbientHandler(e) {
                e;
                console.log(this.current_session, ', ', this.sessions);

                for (let o in this.sessions)
                {
                    if (this.sessions[o].template_id == this.current_session.switch_avatar_template)
                    {
                        window.location.href = this.$url + '#/e' +  this.sessions[o].url.substring(this.sessions[o].url.lastIndexOf('/'), this.sessions[o].url.length);
                        break;
                    }
                   //
                }

                /*
                switch(this.$route.name)
                {
                    case 'Stadium':
                        this.$app.link('StadiumAvatarShow');
                    break;
                    case 'StadiumUnderage':
                        this.$app.link('StadiumAvatarShow');
                    break;
                    case 'StadiumAvatarShow':
                        if (this.$app.checkAge())
                            this.$app.link('Stadium');
                        else
                            this.$app.link('StadiumUnderage');
                    break;
                    case 'StadiumGame':
                        this.$app.link('StadiumAvatarGame');
                    break;
                    case 'StadiumGameUnderage':
                        this.$app.link('StadiumAvatarGame');
                    break;
                    case 'StadiumAvatarGame':
                    case 'StadiumAvatarGameUnderage':
                        if (this.$app.checkAge())
                            this.$app.link('StadiumGame');
                        else
                            this.$app.link('StadiumGameUnderage');
                    break;
                    case 'Bar':
                        this.$app.link('BudBar');
                    break;
                    case 'BudBar':
                        this.$app.link('Bar');
                    break;
                    case 'BarVip':
                        this.$app.link('BudBarVip');
                    break;
                    case 'BudBarVip':
                        this.$app.link('BarVip');
                    break;
                    case 'Theater':
                        this.$app.link('TheaterAvatar');
                    break
                    case 'TheaterAvatar':
                        this.$app.link('Theater');
                    break;
                }
                this.update_avatar();
                */
            }
            
        },
        created() {
            
        },
        mounted() {
            this.quiz = this.switch.quiz;
            this.update_avatar();
        },
        beforeDestroy() {
            this.$app.iframe_temp = false;
        },
    }
</script>

<style scoped>
.imgquiz{
    border-radius: 50%;
}
.imgquiz:hover{
    filter: hue-rotate(12deg);
}
    #ambient-transition
    {
       position: fixed;
       bottom: 10px;
    }
    #ambient-transition > ul
    {
        margin: 10px;
        padding: 0;
    }
    #ambient-transition > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        padding: 0;
    }
    #ambient-transition img
    {
        width: 110px;
    }
    #quiz-transition
    {
        left: 0;
        position: fixed;
        top: calc(100% - 180px);
    }
    #quiz-transition img
    {
        width: 67px;
    }
    #quiz-transition > ul
    {
        margin: 10px;
        padding: 0;
    }
    #quiz-transition > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        padding: 0;
    }

    @media screen and (max-width: 600px)
    {
        #ambient-transition
        {
            bottom: 100px;
        }
        #ambient-transition img
        {
            width: 80px;
        }
        #quiz-transition
        {
           top: calc(100% - 220px);
        }
        #quiz-transition img
        {
            width: 50px;
        }
    }
    @media screen and (max-width: 1050px)
    {
        #quiz-transition
        {
            /*top: 170px;*/
        }
    }
</style>