<template>
    <div id="product">
        <div v-if="product" class="box" id="product-container">
            <!-- <a href="#" id="btn-close" ref="btn-close" v-on:click="$parent.product=false"><img :src="$url + 'assets/img/oracle/x_24.png'"></a> -->
            <span v-on:click="$parent.product=false" class="material-icons md-grey" style="color: #696A70;font-size: 24px;font-style: normal;font-weight: 400;line-height: normal;float:right;cursor: pointer;">close</span>

            

            <div>
                <img v-if="product_actual" :src="product_actual" style="max-width: 100%;max-height:270px">
            </div>
            <hr v-show="product.files.length > 1">
            <div v-show="product.files.length > 1" id="thumbs" ref="thumbs">
                <ul>
                    <li v-for="(item, index) in this.product.files" :key="index" :data-index="index" v-on:click="image_handler">
                        <img :src="store_urls.url_api + 'storage/product/' + product.id + '/' + item.name + '_100.' + item.ext" alt="Thumb">
                    </li>
                </ul>
            </div>
            <!-- <hr> -->
            <div id="product-info">
                <ul>
                    <li>
                        <h3>{{ product.title }}</h3>
                    </li>
                    <li>
                        {{ product.description }}
                    </li>
                    <li v-if="product.price">
                        <h3 style="padding: 10px;">R$ <span style="font-size: 24px;">{{ Number(product.price).toFixed(2).replace(".", ",") }}</span></h3>
                    </li>
                    <li v-if="product.price && product.coins" id="or" style="height: 20px;padding: 0;text-decoration: ;">ou</li>
                    <li v-if="product.coins">
                        <h3 style="padding: 10px;">
                        <svg width="30" height="30" style="fill: var(--buttons_color);vertical-align: middle;" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        &nbsp;
                        <span style="font-size: 24px;vertical-align: middle;">{{ product.coins }}</span></h3>
                    </li>
                    <li>
                        <ul id="product-count-controller">
                            <li><a class="less" v-on:click="decrement">-</a></li>
                            <li id="product-count">{{ count }}</li>
                            <li><a class="more" v-on:click="increment">+</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div id="product-button">
                <ul>
                    <li>
                        <a class="btn-0" href="#" v-on:click="$parent.product = null">Cancelar</a>
                    </li>
                    <li><button class="btn-1" ref="btn-submit" type="button" v-on:click="add">Adicionar</button></li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import axios from "axios";
    // import CloseIcon from '@mui/icons-material/Close';
    export default {
        name: "Product",
        props: ['product_sku'],
        computed: {
            ...mapGetters([
                'token', 'logged', "store_urls"
            ]),
        },
        data() {
            return {
                count: 1,
                product: null,
                product_actual: null
            }
        },
        methods: {
            ...mapActions([
                'update_cart_products'
            ]),

            image_handler(e)
            {
                console.log(e.currentTarget.dataset.index);
                this.product_actual = this.store_urls.url_api + 'storage/product/' + this.product.id + '/' + this.product.files[e.currentTarget.dataset.index].name + '_600.' + this.product.files[e.currentTarget.dataset.index].ext;
                e.preventDefault();
            },

            increment(e) {
                this.count++;

                e.preventDefault();
            },
            decrement (e) {
                if (this.count > 1)
                    this.count--;

                e.preventDefault();
            },

            add()
            {
                // let l_ = { id:this.product.id, quantity:this.count, description:this.product.description, price:this.product.price, amount:(this.count * this.product.price), files:this.product.files };
                // this.update_cart_products(l_);

                // this.$parent.add_success = true;
                // this.$parent.product = null;
                // this.$parent.cart = true;

                let form_data = new FormData();
                form_data.append('object', 'storeProduct');
                console.log('add => ', this.product);
                form_data.append('object_id', this.product.id);
                form_data.append('quantity', this.count);
                //quantity

                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.store_urls.url_api + 'api/cart/add', form_data, { headers: headers }).then(response => this.addResponse(response)).catch(error => this.addError(error));
            },
            addResponse(obj)
            {
                console.log('addResponse => ', obj);

                let l_ =
                {
                    id:this.product.id, 
                    cart_id:obj.data.data.cart[obj.data.data.cart.length - 1].id,
                    quantity:this.count, coins:this.product.coins, 
                    description:this.product.description, 
                    price:this.product.price, 
                    promo: this.product.promo, 
                    promo_price:this.product.promo_price, 
                    promo_coins:this.product.promo_coins, 
                    amount:(this.count * this.product.price), 
                    amount_coins:(this.count * this.product.coins), 
                    files:this.product.files
                };
                this.update_cart_products(l_);

                // this.$parent.add_success = true;
                this.$parent.product = null;
                this.$parent.cart = true;

                // this.$router.push({ path: '/checkout'})
            },
            addError(obj)
            {
                console.log('addError => ', obj);
                alert('Produto já foi adicionado no carrinho.');
            },

            get()
            {
                this.$app.wait(true);
                let form_data = new FormData();
                form_data.append('sku', this.product_sku);
                form_data.append('object', 'storeProduct');
                form_data.append('object_id', '');

                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                // axios.post(this.store_urls.url_api + 'api/store/product', form_data, { headers: headers }).then(response => this.getResponse(response));

                axios.post(this.store_urls.url_api + 'api/store/product', form_data, { headers: headers }).then(response => this.getResponse(response));
            },
            getResponse(obj)
            {
                console.log('get product response => ', obj);
                if (obj.data)
                {
                    this.product = obj.data;

                    console.log(this.product);
                    let files_final = [];
                    for (let o in obj.data.files)
                    {
                        files_final.push({ name: obj.data.files[o].split("_")[0], ext:obj.data.files[o].split(".")[1] });
                    }
                    this.product.files = files_final;
                    this.product_actual = this.store_urls.url_api + 'storage/product/' + this.product.id + '/' + this.product.files[0].name + '_600.' + this.product.files[0].ext;
                }
                this.$app.wait(false);
                
            }
        },
        mounted() {
            // console.log('product mounted => ', this.product);
            
            this.get();
        }
    }
</script>

<style scoped>
    #product
    {
        background-color: rgba(0, 0, 0, .6);
        font-family: inherit;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    h1
    {
        box-sizing: border-box;
        text-align: center;
        padding: 10px 0;
    }
    h3
    {
        color: #696A70;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }
    #product #product-container
    {
        display: block;
        /* margin: 150px  auto; */
        max-height: 80%;
        max-width: 500px;
        overflow-y: auto;
    }
    #product #product-container div
    {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
    }
    @media screen and (max-width: 500px)
    {
        #product-container
        {
            width: calc(100% - 40px);
        }
    }

    #product ul
    {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    #product ul > li > ul > li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 10px;
    }

    #product-info
    {
        font-size: 16px;
        margin: 20px 0;
    }
    #product-info ul li
    {
        padding: 7px 0;
    }
    #product .less, #product .more
    {
        border-radius: 7px;
        border: 1px solid #E1E1E1;
        background: #F6F6F6;
        color: #B1B1B1 !important;
        display: block;
        font-family: inherit;
        font-size: 20px;
        height: 38px;
        /* font-weight: bold; */
        outline: none;
        /* height: 50px; */
        /* line-height: 50px; */
        /* padding: 4px; */
        text-align: center;
        user-select: none;
        width: auto;
    }
    #product #product-count
    {
        /* color: var(--buttons_color); */
        font-family: inherit;
        /* font-size: 35px; */
        font-weight: bold;
        /* height: 50px; */
        text-align: center;
        /* width: 50px; */
    }


    #product-button
    {
        width: 100%;
    }
    #product-button > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        display: inline-block;
        padding: 5px;
        width: calc(100% / 2);
    }

    #thumbs
    {
        border-radius: 10px;
        box-sizing: border-box;
        display: block;
        margin: 5px 0;
    }
    #thumbs > ul > li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 2px 5px;
    }

    #product-count-controller
    {
        width: 100%;
    }
    #product-count-controller li
    {
        height: 38px;
        line-height: 38px;
        padding: 0 6px !important;
        vertical-align: top;
    }
    #product-count-controller > li:nth-child(1)
    {
        width: 20%;
    }
    #product-count-controller > li:nth-child(2)
    {
        border-radius: 7px;
        border: 1px solid #E1E1E1;
        font-weight: normal;
        text-align: center;
        width: 50%;
    }
    #product-count-controller > li:nth-child(3)
    {
        width: 20%;
    }

    #or
    {
        display: flex; 
        flex-direction: row; 
        gap: 20px;
    }
    #or:before, 
    #or:after { 
            content: ""; 
            flex: 1 1; 
            border-bottom: 2px solid #f1f1f1; 
            margin: auto; 
        } 
</style>