<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" :pageSubTitle="selectedCourse.title" :searchField="false" searchPlaceholder="Pesquisar curso...">
    <template v-slot:navigation-side>
      <div class="category-item"  v-for="(course, index) in courses" :key="index" :class="selectedCourse.id == course.id ? 'selected' : ''" @click="selectCourse(index)">
        <CourseCard :coursename="course.title" :coursedata="course"></CourseCard>
      </div>
    </template>
    <template v-slot:content-side>
      <div v-for="(section, index) in selectedCourse.sections" :key="index" >
        <div class="section-title" :class="section.expanded ? 'expanded' : ''" @click="expandSection(index)">
          <div class="d-flex align-items-center">
            <svg v-if="section.user_progress == '100'" width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 14.625L22.125 0.4375L24 2.3125L8 18.3125L0.5625 10.875L2.375 9L8 14.625Z" fill="var(--buttons_color)"/>
            </svg>
            <svg v-else width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 0.6875C15.8333 0.6875 17.5417 1.04167 19.125 1.75C20.75 2.41667 22.1667 3.375 23.375 4.625C24.625 5.83333 25.5833 7.25 26.25 8.875C26.9583 10.4583 27.3125 12.1667 27.3125 14C27.3125 15.8333 26.9583 17.5625 26.25 19.1875C25.5833 20.7708 24.625 22.1875 23.375 23.4375C22.1667 24.6458 20.75 25.6042 19.125 26.3125C17.5417 26.9792 15.8333 27.3125 14 27.3125C12.1667 27.3125 10.4375 26.9792 8.8125 26.3125C7.22917 25.6042 5.8125 24.6458 4.5625 23.4375C3.35417 22.1875 2.39583 20.7708 1.6875 19.1875C1.02083 17.5625 0.6875 15.8333 0.6875 14C0.6875 12.1667 1.02083 10.4583 1.6875 8.875C2.39583 7.25 3.35417 5.83333 4.5625 4.625C5.8125 3.375 7.22917 2.41667 8.8125 1.75C10.4375 1.04167 12.1667 0.6875 14 0.6875ZM10.6875 20L20 14L10.6875 8V20Z" fill="var(--buttons_color)"/>
            </svg>

            <span class="d-block ml-2" style="margin-left: 10px;">{{section.title}}</span>
          </div>
          <div class="d-flex align-items-center">
            <div class="section-progress d-flex align-items-center">
              <div class="progress-circle">
                <svg viewBox="0 0 36 36" class="circular-chart">
                  <path class="circle-bg"
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path class="circle"
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    :stroke-dasharray="circumference"
                    :stroke-dashoffset="circumference - section.user_progress / 100 * circumference"
                  />
                  <text x="18" y="20.35" class="percentage">{{section.user_progress}}%</text>
                </svg>
              </div>
            </div>
            <div style="margin-left: 10px;">
              <i v-if="section.expanded" class="bi bi-chevron-down"></i>
              <i v-if="!section.expanded" class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>
        <div class="section-lessons" v-if="section.expanded && section.hasLesson">
          <div class="lesson-item" :class="lesson.user_progress == '100' ? 'done': ''" v-for="(lesson, index) in section.lessons" :key="index" >
            <div class="d-flex align-items-center">
              <svg v-if="lesson.user_progress == '100'" width="20" height="15" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 14.625L22.125 0.4375L24 2.3125L8 18.3125L0.5625 10.875L2.375 9L8 14.625Z" fill="var(--buttons_color)"/>
              </svg>
              <svg v-else width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 0.6875C15.8333 0.6875 17.5417 1.04167 19.125 1.75C20.75 2.41667 22.1667 3.375 23.375 4.625C24.625 5.83333 25.5833 7.25 26.25 8.875C26.9583 10.4583 27.3125 12.1667 27.3125 14C27.3125 15.8333 26.9583 17.5625 26.25 19.1875C25.5833 20.7708 24.625 22.1875 23.375 23.4375C22.1667 24.6458 20.75 25.6042 19.125 26.3125C17.5417 26.9792 15.8333 27.3125 14 27.3125C12.1667 27.3125 10.4375 26.9792 8.8125 26.3125C7.22917 25.6042 5.8125 24.6458 4.5625 23.4375C3.35417 22.1875 2.39583 20.7708 1.6875 19.1875C1.02083 17.5625 0.6875 15.8333 0.6875 14C0.6875 12.1667 1.02083 10.4583 1.6875 8.875C2.39583 7.25 3.35417 5.83333 4.5625 4.625C5.8125 3.375 7.22917 2.41667 8.8125 1.75C10.4375 1.04167 12.1667 0.6875 14 0.6875ZM10.6875 20L20 14L10.6875 8V20Z" fill="var(--buttons_color)"/>
              </svg>
              <span class="d-block ml-2" style="margin-left: 10px;">{{lesson.title}}</span>
            </div>
            <div class="d-flex align-items-center" v-if="lesson.credit_coins">
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span class="d-block ml-2" :style="lesson.user_lessons_finished ? 'color: #706F6F; margin-left: 10px;' : 'color: #B1B1B1; margin-left: 10px;'">{{lesson.credit_coins}}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LmsBasicTemplate>
</template>
<script>

import { mapGetters } from "vuex";
import LmsBasicTemplate from "@/components/lms/LmsBasicTemplate.vue";
import CourseCard from "./components/LmsMyJourneyComponents/CourseCard.vue";

export default {
  name: "LmsMyJourney",
  components: {
    LmsBasicTemplate,
    CourseCard
  },
  data() {
    return {
      selectedCourse: {
        id: 0,
        title: ""
      },
      circumference: 0,
      courses: [],
      navigationConfig: [],
    }
  },
  beforeMount() {
    let root_ = this;

    root_.navigationConfig = root_.getPageByName("LmsMyJourney");
    root_.circumference = 2 * Math.PI * 15;
  },
  mounted() {
    this.fetchCourses(true);
  },
  methods: {
    goToLesson(courseSlug, sectionSlug, lessonSlug) {
      this.$router.push({
          name: "LmsLessonContent",
          params: {
            lmsSlug: this.$route.params.lmsSlug,
            courseSlug: courseSlug,
            sectionSlug: sectionSlug,
            lessonSlug: lessonSlug
          }
        });
    },
    expandSection(index) {
      let vm = this;
      if(vm.selectedCourse.sections[index].expanded) {
        vm.selectedCourse.sections[index].expanded = false;
        return;
      } else {
        vm.selectedCourse.sections.forEach((section) => {
          section.expanded = false;
        });
        vm.$parent.wait(true);
      }
      if(vm.selectedCourse.sections[index].hasLesson) {
        vm.selectedCourse.sections[index].expanded = vm.selectedCourse.sections[index].expanded ? !vm.selectedCourse.sections[index].expanded : true;
        vm.$parent.wait(false);
      } else {
        let payload = {
          id: vm.selectedCourse.id,
          section_id: vm.selectedCourse.sections[index].id
        }
        vm.$store.dispatch("Journey/fetchSectionLessons", payload).then((res) => {
            vm.selectedCourse.sections[index].lessons = res.data.section.lessons;

            vm.$parent.wait(false);
            vm.selectedCourse.sections[index].hasLesson = true;

            vm.selectedCourse.sections[index].expanded = vm.selectedCourse.sections[index].expanded ? !vm.selectedCourse.sections[index].expanded : true;

            let idx = vm.courses.findIndex((course) => course.id == vm.selectedCourse.id);

            vm.courses[idx].sections[index].lessons = vm.selectedCourse.sections[index].lessons;
            vm.courses[idx].sections[index].hasLesson = true;
          }).catch((err) => {
            vm.$parent.wait(false);
            
            console.log(err);
          });
      }
    
    },
    selectCourse(index) {
      let vm = this;
      vm.$parent.wait(true);

      //verify vm.courses index exists and is not null
      if (vm.courses[index] == null) {
        vm.$router.push({ name: "LmsMyJourney" });
        return;
      } else {
        vm.selectedCourse = vm.courses[index];

        if(vm.selectedCourse.hasSection) {
          if(vm.selectedCourse.sections.length) {
            vm.selectedCourse.sections.forEach((section) => {
              section.expanded = false;
            });
          }
          vm.$parent.wait(false);
        } else {
          vm.$store.dispatch("Journey/fetchCourseSections", vm.selectedCourse.id).then((res) => {
            vm.selectedCourse.sections = res.data.course.sections;
            vm.selectedCourse.sections.forEach((section) => {
              section.expanded = false;
            });
            vm.$parent.wait(false);
            vm.selectedCourse.hasSection = true;

            vm.courses[index].sections = vm.selectedCourse.sections;
            vm.courses[index].hasSection = true;
          }).catch((err) => {
            vm.$parent.wait(false);
            
            console.log(err);
          });
        }
      }
    },
    fetchCourses(first=false) {
      let vm = this;
      vm.$parent.wait(true);
      let lmsSlug = this.$route.params.lmsSlug;                 
      vm.$store.dispatch("Journey/fetchCourses", lmsSlug).then((res) => {
        vm.$parent.wait(false);
        vm.courses = res.data.courses;

        if(first && vm.courses.length > 0) {
          vm.selectCourse(0)
        } 
      }).catch((err) => {
        vm.$parent.wait(false);
        console.log(err);
      });
    },
  },
  computed:{
    ...mapGetters({
      getLms: "LMS/getLms",
      getPageByName: "LMS/getPageByName"
    }),
  },
  beforeDestroy() {

  }

}

</script>

<style scoped lang="scss">

.section-lessons {
  background-color: #F6F6F6;
  border-radius: 0 0 10px 10px;
  padding: 10px 20px;
  border: 1px solid #F6F6F6;
  transition: all 0.5s ease-in-out;
}
.lesson-item {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  color: #706F6F;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  svg {
    color: #706F6F;
    fill: #706F6F;
  }

  &.done {
    svg {
      color: var(--buttons_color);
      fill: var(--buttons_color);
    }
  }
}
.section-title {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #F6F6F6;
  padding: 20px;
  margin: 10px 0;
  gap: 24px;
  cursor: pointer;
  color: #706F6F;
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.expanded {
    margin: 10px 0 0 0;
    border: 1px solid #F6F6F6;
    border-radius: 10px 10px 0 0;
    background-color: #F2EEFF !important;
    color: var(--buttons_color);
    font-family: Hanken Grotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.rotate {
  transform: rotate(90deg);
}
.category-item {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #F6F6F6;
  padding: 20px;
  margin: 10px 0;
  gap: 24px;
  cursor: pointer;
  color: #706F6F;
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.selected {
  background-color: #F2EEFF !important;
  cursor: default;
  color: var(--buttons_color);
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.section-progress {
  width: 40px;
  height: 40px;
  position: relative;
}

.progress-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
}
.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #5F5F5F;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 4.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
  stroke: #A18AE5;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.percentage {
  fill: #5F5F5F;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>