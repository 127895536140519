<template>
  <section v-if="getContinueJourney.length" class="container" id="course-section">
    <div class="category mt-5 journey">
      <div class="d-flex justify-content-start align-items-center mb-4">
        <h3 class="section-title">Continue Estudando</h3>
      </div>

      <div class="container mb-3 px-0">
        <div class="swiper-container course-sections journey-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(course, idxCourse) in createShow(getContinueJourney)" v-for-callback="{key: idxCourse, array: getContinueJourney, slidesToShow: slidesToShow, callback: endVforCourses}" :key="idxCourse">

              <div class="cards" @click="nextLesson(course)">
                <div class="cards-img"
                     :style="{ 'background-image': 'url(' + course.thumbnail + ')' }"
                     :data-src="course.thumbnail"
                     :data-hover="course.thumbnail_hover"
                >
                </div>
              </div>
              <span class="course-title text-center">{{ course.title }}</span>

            </div>

          </div>
        </div>

      </div>

    </div>

  </section>
</template>

<script>

import {mapGetters} from "vuex";
import Swiper from "swiper";
import $ from "jquery";
import { gsap } from 'gsap';
const emitter = require('tiny-emitter/instance');
// eslint-disable-next-line no-unused-vars
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "CourseSections",
  computed: {
    ...mapGetters('LMS', [
      'getLms', 'getContinueJourney'
    ]),
  },
  data() {
    return {
      swiper: {},
      slidesToShow: 4,
      showCourses: []
    }
  },
  mounted() {
    setTimeout(() => {
      $().ready(function () {
        $('.cards-img').hover(function () {
          $(this).css('background-image', 'url(' + $(this).data('hover') + ')');
        }, function () {
          $(this).css('background-image', 'url(' + $(this).data('src') + ')');
        });
      });
    }, 500);

    emitter.on('lms-loaded::finished', () => {
      const journey = gsap.utils.toArray('.journey');
      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          journey.forEach((item, i) => {
            gsap.set(item, { opacity: 0 })
            gsap.to(item, { scrollTrigger: item, opacity: 1, duration: 1.5, delay: i * 0.2 })
          });
        },
        all: () => {
          journey.forEach((item, i) => {
            gsap.set(item, { opacity: 0 })
            gsap.to(item, { scrollTrigger: item, opacity: 1, duration: 1.5, delay: i * 0.2 })
          });
        }
      });
    });

  },
  directives: {
    forCallback(el, binding, vnode) {
      let element = binding.value;
      let category = binding.value;
      let key = category.key;
      let coursesLength = category.array.length;
      let slidesToShow = category.slidesToShow;
      let categoryslug = category.categoryslug;
      if (coursesLength >= slidesToShow && key === slidesToShow - 1) {
          (element.callback.bind(vnode.context))(categoryslug);
      } else if(key === coursesLength - 1){
        (element.callback.bind(vnode.context))(categoryslug)
      }
    }
  },
  methods: {
    createShow(courses) {
      for(let course of courses) {
        if (!course.thumbnail_hover) {
          course.thumbnail_hover = course.thumbnail;
        }
      }
      return courses;
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    buildSwiper() {
      let root_ = this;

      let options = {
        slidesPerView: 4,
        spaceBetween: 20,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5
          },
          640: {
            slidesPerView: 2.5
          },
          1024: {
            slidesPerView: 4.5
          }
        },
        autoplay: false,
        loop: false,
        allowTouchMove: true,
        observer: true,
      };

      root_.$nextTick(() => {

        root_.swiper = new Swiper(".journey-swiper", options);
      });

    },
    nextLesson(lesson) {
      let vm = this;
      if(lesson.nextLesson == null) {
        vm.$router.push({
          name: "LMSCourses",
          params: {
            lmsSlug: vm.$route.params.lmsSlug,
            courseSlug: lesson.slug
          },
          query: {
            lesson_id: lesson.lesson_id,
            section_id: lesson.section_id,
            course_id: lesson.course_id
          }
        });
      } else {
        vm.$router.push({
          name: "LmsLessonContent",
          params: {
            lmsSlug: vm.$route.params.lmsSlug,
            courseSlug: lesson.slug,
            sectionSlug: lesson.nextLesson.slug,
            lessonSlug: lesson.nextLesson.lesson_slug
          },
          query: {
            lesson_id: lesson.nextLesson.lesson_id,
            section_id: lesson.nextLesson.section_id,
            course_id: lesson.nextLesson.course_id
          }
        });
      }
    },
    endVforCourses() {
      this.buildSwiper();
    },
  }
}

</script>

<style lang="scss" scoped>

#course-section {
  overflow-x: hidden;
  padding-right: 0;
  padding-left: 0;
}

.swiper-slide {
  width: 20%;
}

.swiper-container.course-sections {
  // height: 300px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.category {
  opacity: 0;
}

.cards {
  position: relative;
  // height: 242px;
  cursor: pointer;
  flex: 1 0 auto;

  .cards-img {
    position: relative;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-blend-mode: saturation;
    height: 242px;
    width: 100%;
    border-radius: 30px;
    transition: all 0.5s ease-in-out;
  }

  .favorite {
    position: absolute;
    right: 25px;
    top: 15px;
    font-size: 28px;
    z-index: 1;
    cursor: pointer;
    color: #FFFFFF80;

    &.selected {
      color: #E82E2E;
    }
  }


}

.course-sections {
  padding-left: 0;
  padding-right: 0;
}

span {
  font-size: 20px;
  color: #706F6F;
  text-align: center;
  display: block;
  margin-top: 15px;
}

.see-all {
  min-width: 70px;
  border-bottom: 1px solid rgba(0, 0, 0 ,0);
  transition: border-bottom 300ms;
  font-size: 16px;
  &:hover {
    border-bottom: 1px solid var(--buttons_color);
  }
}

.all-courses {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #706F6F;
  cursor: pointer;
}

.swiper-button-next-course-sections, .swiper-button-prev-course-sections {
  position: absolute;
  height: 100%;
  top: 0;
  background: #000;
  opacity: .3;

  .bi {
    color: #FFFFFF;
    font-size: 35px;
  }

}

.swiper-button-prev-course-sections {
  left: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.swiper-button-next-course-sections {
  right: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.course-title {
  font-family: "Open Sans";
  line-height: 27px;
  text-align: center;
  font-size: 20px;
  color: #706f6f;
}

.section-title{
  font-family: "Open Sans";
  font-size: 36px;
  font-style: normal;
  color: var(--buttons_color);
  display: inline-block;
  width: 100%;
  line-height: 48px;
  margin-bottom: 0;
  font-weight: 600;
}

@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {
  // .container#course-section,
  // .container-md#course-section,
  // .container-sm#course-section {
  //   max-width: 100%;
  //   width: 100%;
  // }
}

@media only screen and (max-width: 992px) {
  .container#course-section,
  .container-md#course-section,
  .container-sm#course-section,
  .container#course-section .container,
  .container-md#course-section .container,
  .container-sm#course-section .container {
    max-width: 100%;
    width: 100%;
  }

  .section-title {
    padding-left: 7px;
  }

}
@media only screen and (max-width: 768px) {
  .section-title{
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 0;
  }
  .swiper-slide .section-title{
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .see-all{
    font-size: 16px;
    margin-top: 6px;
  }
}
@media only screen and (max-width: 480px) {

}

</style>