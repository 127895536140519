<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" :searchField="false" searchPlaceholder="Pesquisar book..."
    fullContent="true">


    <template v-slot:content-side>
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div class="books-created">
            <span>{{ paginationOptions.total }} {{ $i18n.tc('lms.student_book.books_created', paginationOptions.total) }}</span>
          </div>

<!--          <div class="books-sort ms-3">-->
<!--            <span>Ordenar por</span>-->
<!--            <span>Mais recentes</span>-->
<!--          </div>-->
        </div>

        <div class="create">
<!--          <div class="btn-create" @click="openModal">-->
          <div class="btn-create" @click="createBook">
            <span class="material-icons-sharp add_circle">
              add_circle
            </span>
            <span>
              {{ $i18n.t('lms.student_book.create_book') }}
            </span>
          </div>
        </div>

      </div>

      <div class="container d-flex gap-3 flex-wrap p-0 mt-4 books">
        <LmsBook v-for="(book, idxBook) in books"
                 :key="book.id"
                 :book="book"
                 :class="{'break-line': idxBook % 4 === 3, 'flex-grow-1': true}">
        </LmsBook>
      </div>

      <pagination class="mt-4" v-if="paginationOptions.show" v-model="paginationOptions.page" @paginate="paginateStudentBooks"
                  :records="paginationOptions.total" :per-page="paginationOptions.per_page" :options="paginationOptions" />


      <!-- Modal -->
      <div class="modal modal-create-template" id="modal-template-create" tabindex="-1" role="dialog"
           @click="closeModalOverlay($event)">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <!-- Cabeçalho do modal -->
            <div class="modal-header">

              <h5 class="modal-title">{{ $i18n.t('lms.student_book.choose_an_template')}}</h5>
              <span class="material-icons-sharp close" @click="closeModal('modal-template-create')">
                close
              </span>

            </div>

            <!-- Corpo do modal -->
            <div class="modal-body">
              <div class="ui cards d-flex gap-4 justify-content-start align-content-around">
                <div class="template" @click="chooseTemplate({id: 0})">
                  <div class="card">

                    <div class="card-body d-flex align-items-center justify-content-center">
                      <div class="thumbnail">
                      </div>
                    </div>
                  </div>
                  <div class="footer text-center">
                    Em Branco
                  </div>
                </div>
                <div class="template"
                     v-for="(template, idxTemplate) in templates"
                     :key="idxTemplate"
                     @click="chooseTemplate(template)"
                  >
                  <div class="card">

                    <div class="card-body d-flex align-items-center justify-content-center">
                      <div class="thumbnail" :class="{'thumbnail-img': !template.configs.isPdf && getThumbImg(template)}">
                        <div v-if="template.configs.isPdf" class="thumbnail-placeholder">

                          <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                            <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                            <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                          </svg>
                        </div>
                        <div class="thumb-img" v-else-if="getThumbImg(template)" :style="'background-image: url(' + getThumbImg(template) + ')'"></div>
                        <div v-else class="thumbnail-placeholder">

                          <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="footer text-center">
                    {{ template.pages.name }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Rodapé do modal com os botões -->
            <div class="modal-footer">
              <span class="close-modal" @click="closeModal('modal-template-create')">
                {{ $i18n.t('lms.student_book.cancel') }}
              </span>
<!--              <div class="btn-accept">-->
<!--                <span class="accept">{{ $i18n.t('lms.student_book.accept') }}</span>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>

    </template>
  </LmsBasicTemplate>
</template>





<script>
import LmsBasicTemplate from "@/components/lms/LmsBasicTemplate.vue";
import {mapGetters} from "vuex";
import LmsBook from "@/components/lms/components/LmsStudentBookComponents/LmsBook.vue";
import CustomPagination from "@/components/lms/components/LmsHomeComponents/CustomPagination.vue";
let emitter = require('tiny-emitter/instance');
export default {
  name: "LmsStudentBook",
  components: {
    LmsBasicTemplate,
    LmsBook,

  },
  data() {
    return {
      navigationConfig: [],
      books: [],
      paginationOptions: {
        show: true,
        total: 1,
        current_page: 1,
        page: 1,
        per_page: 8,
        template: CustomPagination,
        loading: false
      },
      templates: []

    }
  },
  beforeMount() {
    let root_ = this;

    root_.navigationConfig = root_.getPageByName("LmsStudentBook");

    root_.getStudentBooks(1);

  },
  mounted() {
    let root_ = this;

    emitter.on("studentbook::book_deleted", function(bookId) {

      const index = root_.books.findIndex(book => book.id === bookId);
      if (index !== -1) {
        root_.books.splice(index, 1);
        if(root_.books.length === 0){
          root_.getStudentBooks((1));
        } else {
          root_.paginationOptions.total = root_.paginationOptions.total - 1;
        }

      }

    })


  },
  methods: {
    getThumbImg(template) {
      return template.pages.scenes.scenes[0]?.ppt?.src ?? false;
    },
    chooseTemplate(template) {
      // console.log("chooseTemplate:", template);

      let root_ = this;

      let payload = {
        "slug": root_.$route.params.lmsSlug,
        "template_id": template.id
      }

      root_.$parent.wait(true);

      root_.$store.dispatch("Whiteboard/createBook", payload)
        .then(() => {

          root_.$router.push({
            name: 'Whiteboard',
            params: {
              lmsSlug: root_.$route.params.lmsSlug
            }
          });

        })
        .catch(error => {
          console.error("create book error => ", error);
          root_.$parent.wait(false);
        });



    },
    getStudentBooks(page) {

      let root_ = this;

      if(root_.paginationOptions.loading) {
        return;
      }

      let payload = {
        "slug"    : root_.$route.params.lmsSlug,
        "page"    : page,
        "per_page": root_.paginationOptions.per_page
      }

      root_.paginationOptions.loading = true;
      root_.$store.dispatch("Whiteboard/getStudentBooks", payload)
        .then(response => {
          root_.books = response.data;
          root_.totalBooks = response.total;

          root_.paginationOptions.current_page  = response.current_page;
          root_.paginationOptions.total         = response.total;
          root_.paginationOptions.per_page      = parseInt(response.per_page);
          root_.paginationOptions.page          = response.current_page;


        })
        .finally(() => {
          root_.paginationOptions.loading = false;
        });

    },
    paginateStudentBooks(page){

      this.getStudentBooks(page);

    },
    openModal() {
      // Função para abrir o modal
      document.getElementById('modal-template-create').style.display = 'block';
    },
    closeModal(modalId) {
      // Função para fechar o modal
      document.getElementById(modalId).style.display = 'none';
    },
    closeModalOverlay(e){
      if(e.target.id === "modal-template-create") {
        this.closeModal('modal-template-create');
      }
    },
    createBook() {

      let root_ = this;

      let lmsId = root_.getLms.id;

      root_.$store.dispatch("Whiteboard/fetchTemplates", lmsId)
          .then( templates => {
            root_.templates = templates.templates;

            console.log("fetch Templates: ", root_.templates);

            root_.openModal();
          })
    },
    dataURLtoBlob(dataURL) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
  computed:{
    ...mapGetters("LMS", [
      "getPageByName", "getLms"
    ]),

  },

}

</script>

<style lang="scss" scoped>

.modal.modal-create-template {

  background-color: #00000080;

  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .modal-content {
    position: unset !important;
    width: 955px;
    min-height: fit-content;
    max-height: 550px;
  }

  .card {
    width: 200px;
    height: 150px;
    cursor: pointer;
  }

  .modal-content {
    position: unset !important;
    width: 955px;
    min-height: fit-content;
    max-height: 550px;
    .cards {
      justify-content: start;
      align-content: space-around;
    }
  }

  .modal-header {

    border-bottom: unset;

    .material-icons-sharp.close {
      color: #706F6F;
      font-family: Material Icons;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }

    .modal-title {
      color: var(--buttons_color);
      font-family: "Open Sans", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .modal-body {
    width: 100%;
    overflow-y: auto;

    .ui.cards {
      flex-wrap: wrap;
    }

    .template {
      width: 200px;
      height: 150px;
      margin-bottom: 20px;

      .thumbnail {
        overflow: hidden;

        &.thumbnail-img {
          width: 100%;
          height: 100%;

           .thumb-img {
             width: 100%;
             height: 100%;
             background-position: top center;
             background-repeat: no-repeat;
             background-size: cover;

           }
        }

      }

      .card-body {
        overflow: hidden;
        height: 100%;
        padding: 0;
      }

      .footer {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }


  }

  .modal-content {

    .modal-footer {

      border-top: unset;
      justify-content: center;

      .close-modal {
        color: #706F6F;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }



      .btn-accept {

        padding: 10px 20px;
        align-items: center;
        border-radius: 20px;
        background: var(--buttons_color);
        cursor: pointer;

        .accept {
          color: #ffffff !important;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;


        }

      }


    }

  }


}

.books-created {
  color: #706F6F;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.break-line {
  flex-basis: 100%;
  width: 100%;
}

.create {
  .btn-create {
    cursor: pointer;
    display: inline-flex;
    padding: 10px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: var(--buttons_color);
    color: #FFF;

    .add_circle {
      color: #FFF;
      font-family: Material Icons;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    span {
      color: #FFF;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

  }

}

.VuePagination {
  display: flex;
  justify-content: center;
}


@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
  .books {
    justify-content: center;
  }


  .modal.modal-create-template {

    background-color: #00000080;

    .modal-dialog {
      position: absolute;
      top: 50%;
      left: 48% !important;
    }

    .modal-header {
      .modal-title {
        font-size: 22px;
      }
    }

    .modal-content {
      position: unset !important;
      width: 100vw;
      min-height: fit-content;
      padding: 15px 15px;
      justify-content: center;

      .cards {
        align-items: center;
        flex-direction: row;
        overflow-y: auto;
        flex-wrap: nowrap !important;
        height: 200px;

      }
    }
  }

}
@media only screen and (max-width: 480px) {
  
}

</style>