<template>
    <div v-if="logged" id="vtex">
        <div class="cart-controller-header">
            <div class="cart-icon" style="filter:none !important" v-on:click="cart=true" v-html="header_buttons.cart_button"/>
            <span class="cart-info">{{ counter }}</span>
        </div>

        <Product v-if="product" :product_sku="product"></Product>
        <Cart v-if="cart" ref="cart"></Cart>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Product                  from '@/components/vtex/Product';
import Cart                  from '@/components/vtex/Cart';
    export default {
        name: "Vtex",
        computed: {
            ...mapGetters([
                'token', 'logged', 'counter', 'id', 'first_name', 'last_name',
                'email', 'points', 'level', 'level_next', 'score_needed_to_reach', 'thumb', 'check_log',
                'cart_token'
            ]),
        },
        data() {
            return {
                add_success: null,
                cart: null,
                orders: null,
                product: null,
                user_address: null
            }
        },
        components: {
            Product,
            Cart
        },
        methods: {
            ...mapActions([
                'update', 'update_cart_products', 'update_profile', 'update_points', 'update_check_log','update_cart_token'
            ]),

            setProduct(sku)
            {
                this.product = sku;
                console.log('this.product => ', sku);
                this.$forceUpdate();
            },

            teste()
            {
                this.$refs['kash-store'].login();
            }
        },
        mounted() {
            //this.product = '78623';
            //this.product = '7e4ecbff62';
            let cartToken =  localStorage.getItem('cart_token');
            this.update_cart_token(cartToken);
            let cart_store = JSON.parse(localStorage.getItem('cart_store'));
            for (let o in cart_store)
            {
                this.update_cart_products(cart_store[o]);
            }
        }
    }
</script>

<style lang="css">
    #vtex
    {
        color: black;
        display: block;
        font-family: 'Roboto', sans-serif;;
        height: auto;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 1000;
        width: auto;
    }

    #vtex a         { color:#000; text-decoration:none; transition: color .2s;}
    #vtex a:link    { color:#000; text-decoration:none; }
    #vtex a:visited { color:#000; text-decoration:none; }
    #vtex a:active  { color:#000; text-decoration:none; }
    #vtex a:focus   { color:#000; outline-color:var(--buttons_color); text-decoration:none; }
    #vtex a:hover   { color: var(--buttons_color); text-decoration:none; transition: color .2s;}
    #vtex ul {
        font-family: inherit;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }
    #vtex li, #vtex main, #vtex header, #vtex footer, #vtex menu, #vtex a
    {
        display: block;
        font-family: inherit;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }
    #vtex h1, #vtex h2, #vtex h3
    {
        margin: 0;
        padding: 0;
    }
    #vtex hr
    {
        border: none;
        border-top: dashed 1px #ccc;
        box-sizing: border-box;
        padding: 10px 0;
    }

    /*---- class -----*/
    #vtex .box
    {
        background-color: #fff;
        border-radius: 14px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .1);
        box-sizing: border-box;
        display: block;
        padding: 10px;
    }
    #vtex .riscado
    {
        text-decoration: line-through;
    }
    #vtex .fm
    {
        font-size: 13px;
    }
    #vtex .boldt
    {
        font-weight: bold !important;
    }
    /*---- \class\ -----*/


    /*----- cart -----*/
    #vtex .cart-controller-header
    {
        color: var(--fonts_color);
        position: relative;
        .cart-info
        {
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: var(--buttons_color);
            border-radius: 50%;
            box-sizing: border-box;
            color: white;
            font-weight: bold;
            font-size: .75rem;
            padding: 5px;
            height: 1.5rem;
            width: 1.5rem;
            text-align: center;
        }
        .cart-icon
        {
            cursor: pointer;
            svg{
                width: 1.5rem;
                height: 1.5rem;
                fill: var(--fonts_color);
                path{
                    fill: var(--fonts_color);
                }
            }
        }
    }
    /*----- \cart\ -----*/

    /*----- form -----*/
    /*----- placeholder -----*/
    #vtex ::-webkit-input-placeholder
    {
        /* Edge */
        color: #666;
    }

    #vtex :-ms-input-placeholder
    {
        /* Internet Explorer 10-11 */
        color: #666;
    }

    #vtex ::placeholder
    {
        color: #666;
    }
    /*----- \placeholder\ -----*/


    #vtex button, #vtex input, #vtex label, #vtex textarea, #vtex a
    {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        outline: none;
    }

    #vtex textarea
    {
        resize: vertical;
    }


    #vtex label
    {
        box-sizing: border-box;
        color: #000;
        display: inline-block;
        font-family: inherit;
        letter-spacing: 1px;
        padding: 10px 0;
    }


    #vtex input[type="date"],
    #vtex input[type="datetime-local"],
    #vtex input[type="email"],
    #vtex input[type="password"],
    #vtex input[type="text"],
    #vtex input[type="number"],
    #vtex textarea
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        border-radius: 25px;
        box-shadow: inset 2px 2px 0 rgba(0, 0, 0, .03), 0 1px 0 rgba(255, 255, 255, .5);
        box-sizing: border-box;
        color: #000;
        font-family: inherit;
        font-size: inherit;
        padding: 16px 14px 16px 14px;
        width: 100%;
    }

    #vtex input[type="date"]:focus,
    #vtex input[type="datetime-local"]:focus,
    #vtex input[type="email"]:focus,
    #vtex input[type="password"]:focus,
    #vtex input[type="text"]:focus,
    #vtex input[type="number"]:focus,
    #vtex textarea:focus
    {
        background-color: #fff;
    }


    /*----- btns -----*/
    #vtex input[type="submit"], #vtex .btn-0, #vtex .btn-1, #vtex .btn-2, #vtex .btn-3
    {
        background-color: var(--buttons_color);
        border: none;
        border-radius: 29px;
        box-sizing: border-box;
        color: #fff !important;
        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        font-size: 15px;
        font-weight: bold;
        padding: 17px 12px;
        text-align: center;
        width: 100%;
    }
    #vtex .btn-0
    {
        background-color: #fff;
        border: solid 1px #999;
        color: #000 !important;
    }
    #vtex .btn-2
    {
        background-color: #4e4e4e;
        border: none;
        color: white !important;
        padding: 6px;
    }
    #vtex .btn-2 img
    {
        float: left;
        height: 42px;
    }
    #vtex .btn-2 span
    {
        display: inline-block;
        margin: 15px 0 0 -20px;
    }

    #vtex .btn-3
    {
        background-color: rgba(0, 0, 0, .7);
        border: none;
        color: white !important;
    }

    #vtex input[type="submit"]:focus, #vtex .btn-0:focus, #vtex .btn-facebook:focus
    {
        opacity: 1;
    }
    /*----- \btns\ -----*/


    /*----- select -----*/
    #vtex select
    {
        -webkit-appearance:none;
        appearance:none;
        background: url('../../assets/img/seta_1.png') calc(100% - 10px) calc(100% - 14px) no-repeat #f9f9f9;
        border: solid 1px #ccc;
        border-radius: 20px;
        box-shadow: 2px 2px 0 rgba(0, 0, 0, .03);
        box-sizing: border-box;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: normal;
        
        outline: none;
        padding: 16px 40px 16px 20px;
        width: 100%;
    }
    #vtex select:focus
    {
        background-color: #fff;
        /*border: solid 1px rgba(135, 206, 235, 1);*/
    }
    /*----- \select\ -----*/



    /*----- checkbox -----*/
    #vtex .checkbox-container
    {
        box-sizing: border-box;
        padding: 10px 0;
    }
    #vtex .styled-checkbox
    {
        opacity: 0;
        position: absolute;
    }
    #vtex .styled-checkbox + label
    {
        cursor: pointer;
        letter-spacing: 0;
        padding: 0;
        position: relative;
    }
    #vtex .styled-checkbox + label:before
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, .06);
        content: '';
        display: inline-block;
        height: 16px;
        margin-right: 10px;
        vertical-align: bottom;
        width: 16px;
    }
    #vtex .styled-checkbox:focus + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    #vtex .styled-checkbox:hover + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    #vtex .styled-checkbox:checked + label:after
    {
        content: '';
        background-color: #fea044;
        position: absolute;
        left: 5px;
        top: 5px;
        height: 8px;
        width: 8px;
    }
    #vtex .styled-checkbox:checked + label:before
    {
        background-color: #fff;
    }
    #vtex .styled-checkbox:disabled + label
    {
        color: #b8b8b8;
        cursor: auto;
    }
    #vtex .styled-checkbox:disabled + label:before
    {
        background-color: #f5f5f5;
        border-color: #e5e5e5;
        box-shadow: none;
    }
    /*----- \checkbox\ -----*/



    /*----- radio -----*/
    #vtex .styled-radio
    {
        opacity: 0;
        position: absolute;
    }
    #vtex .styled-radio + label
    {
        cursor: pointer;
        letter-spacing: 0;
        padding: 0;
        position: relative;
    }
    #vtex .styled-radio + label:before
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        border-radius: 50%;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, .06);
        content: '';
        display: inline-block;
        height: 16px;
        margin-right: 10px;
        vertical-align: bottom;
        width: 16px;
    }
    #vtex .styled-radio:focus + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    #vtex .styled-radio:hover + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    #vtex .styled-radio:checked + label:before
    {
        background-color: #fff;
    }
    #vtex .styled-radio:checked + label:after
    {
        content: '';
        background-color: #bbb;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 5px;
        height: 8px;
        width: 8px;
    }
    #vtex .styled-radio:disabled + label
    {
        color: #b8b8b8;
        cursor: auto;
    }
    #vtex .styled-radio:disabled + label:before
    {
        background-color: #f5f5f5;
        border-color: #e5e5e5;
        box-shadow: none;
    }
    /*----- \radio\ -----*/

    /*----- \form\ -----*/
</style>