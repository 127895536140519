<template>
    <div class="scrolling">
        <div id="signup" ref="signup" class="ui translucent suimodal"
            :class="{ small: !checkMobile, overlay: checkMobile, fullscreen: checkMobile }">
            <i class="close icon" />
            <div class="header">
                {{ $t('signup.cadastreSe') }}
            </div>
            <div class="content">
                <div v-if="!webview" class="socials">
                    <div v-if="this.switch.Login_Azure" class="col">
                        <LoginAzure origin="register"
                            :active="!((this.switch.waiting_mode && signUp.emailS_.length == 0) || !checkCaptcha || (!this.switch.waiting_mode && !isActive))" />
                    </div>
                    <div v-if="this.switch.Login_Gmail">
                        <LoginGoogle origin="register" />
                    </div>
                    <div v-if="this.switch.Login_Facebook">
                        <LoginFacebook origin="register" />
                    </div>
                </div>
                <form id="signup-form" ref="signup-form" class="ui form" @submit.prevent="signup">
                    <input type="hidden" name="friend_code" id="ref" :value="ref">
                    <div v-if="this.switch.header_signup_terms" class="fields">
                        <div v-for="op in this.switch.optins" :key="op.id" class="inline field sixteen wide">
                            <div class="ui checkbox">
                                <input class="hidden" v-model="signUp.checks[op.id]" :id="'header_' + op.id"
                                    :ref="'header_' + op.name" type="checkbox" value="1" :data-id="op.id"
                                    :data-required="Boolean(op.required)" v-on:change="optinHandler">
                                <label v-on:click="handleLabelClick" :for="'header_' + op.id"
                                    v-html="String(op.title.substring(3, op.title.length))"></label>
                            </div>
                        </div>
                    </div>
                    <div class="fields"
                        v-if="!this.switch.waiting_mode && (this.switch.Login_Gmail || this.switch.Login_Facebook || this.switch.Login_Azure)">
                        <hr>
                        <p style="text-align: center;font-size: 1.2rem;padding: 0px 34px;">
                            {{ $t('signup.ouCadastreSeComEmail') }}
                        </p>
                    </div>
                    <div v-if="!this.switch.waiting_mode" class="fields">
                        <div class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input class="text" :placeholder="$t('signup.nomeCompleto')" id="name"
                                v-model="signUp.nameS_" name="name" ref="name" type="text">
                            <p class="small">{{ $t('signup.ate255Caracteres') }}</p>
                        </div>
                        <div class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input class="text" :placeholder="$t('signup.apelido')" v-model="signUp.nicknameModelS_"
                                v-mask="'NNNNNNNNNNNNN'" maxlength="13" id="nickname" name="nickname" ref="nickname"
                                type="text">
                            <p class="small">{{ $t('signup.de5a13Caracteres') }}</p>
                        </div>
                        <div class="field sixteen wide">
                            <input class="text" :placeholder="$t('signup.meuEmail')" v-model="signUp.emailS_"
                                id="email" name="email" ref="email" type="email">
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['phone'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input class="text" :placeholder="$t('signup.phone')" v-model="signUp.phoneS_"
                                @keyup="mask_phone" id="phone" name="phone" ref="phone" type="text">
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['cpf'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input class="text" :placeholder="$t('signup.cpf')" v-model="signUp.cpfS_"
                                @keyup="mask_cpf" id="cpf" name="cpf" ref="cpf" type="text">
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['enrollment'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }"
                            >
                            <input id="enrollment" ref="enrollment" class="text" :placeholder="getEnrollmentPlaceholder()"
                                    name="enrollment" type="text"
                            >
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['birth'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <div class="ui calendar" id="birth_date">
                                <div class="ui fluid input left icon">
                                    <i class="calendar icon"></i>
                                    <input type="text" id="birth_date" name="birth_date" ref="birth_date"
                                        v-model="signUp.birthS_" v-mask="'##/##/####'"
                                        :placeholder="$t('signup.dataDeNascimento')">
                                </div>
                            </div>
                            <p class="small" v-if="this.only_major">*Este evento é para maiores de idade</p>
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['company'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input class="text" :placeholder="$t('signup.company')" v-model="signUp.companyS_"
                                id="company" name="company" ref="company" type="text">
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['state'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select class="ui suidropdown" v-model="signUp.stateS_" name="state" id="state" ref="state"
                                @change="cityHandler">
                                <option value="">{{ $t('signup.state') }}</option>
                                <option :key="state.id" v-for="state in states" :value="state.value"
                                    :data-value="state.id">{{ state.value }}</option>
                            </select>
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['state'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select class="ui suidropdown" v-model="signUp.cityS_" name="city" id="city" ref="city">
                                <option value="">{{ $t('signup.city') }}</option>
                                <option v-for="(item, index) in cities" :key="index" :value="item.name">{{ item.name
                                    }}</option>
                            </select>
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['region'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select class="ui suidropdown" v-model="signUp.regionS_" name="region" id="region"
                                ref="region">
                                <option value="" selected>{{ this.$i18n.t('signup.region') }}</option>
                                <option :key="region.id" v-for="region in regions" :value="region.id">
                                    {{ region.region_name }}</option>
                            </select>
                        </div>
                        <div v-if="Boolean(this.switch.signup_extra_fields['nationality'])" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select class="ui suidropdown" v-model="signUp.nationalityS_" name="nationality"
                                id="nationality" ref="nationality">
                                <option value="" selected>{{ this.$i18n.t('signup.nacionalidade') }}</option>
                                <option v-for="(country, index) in nacionality" :key="index" :value="country.sigla"
                                    :data-value="country.sigla">{{ country.nome_pais_int }}</option>
                            </select>
                        </div>
                        <div v-if="this.switch.group_name" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select class="ui suidropdown" name="group_name" id="group_name">
                                <option value="">{{ $t('signup.grupo') }}</option>
                                <option v-for="(gn, index) in this.switch.group_name" :key="index" :value="gn.id">{{
                gn.group_name }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="!this.switch.waiting_mode" class="fields">
                        <div class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input class="text" :placeholder="$t('signup.senha')" v-model="signUp.passwordS_"
                                id="password" name="password" ref="password" type="password">
                            <p class="small">{{ $t('signup.de8a20Caracteres') }}</p>
                        </div>
                        <div class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input class="text" :placeholder="$t('signup.confirmeASenha')"
                                v-model="signUp.passwordConfS_" id="password_confirmation"
                                name="password_confirmation" ref="password_confirm" type="password">
                        </div>
                    </div>
                    <div v-if="this.switch.iframe_signup_terms">
                        <iframe :src="this.switch.iframe_signup_terms_url" frameborder="0" width="100%"
                            height="100%" style="min-height: 200px;"></iframe>
                    </div>
                    <div class="fields">
                        <div v-for="op in this.switch.optins" :key="op.id" class="inline field sixteen wide">
                            <div class="ui checkbox">
                                <input class="hidden" v-model="signUp.checks[op.id]" :id="'_' + op.id"
                                    :name="op.name" :ref="op.name" type="checkbox" value="1" :data-id="op.id"
                                    :data-required="Boolean(op.required)" v-on:change="optinHandler">
                                <label v-on:click="handleLabelClick" :for="'_' + op.id"
                                    v-html="String(op.title.substring(3, op.title.length))"></label>
                            </div>
                        </div>
                    </div>
                    <div v-if="this.switch.waiting_mode" class="field">
                        <p style="text-align: center;font-size: 20px;">Pré-Cadastro Metaverso Fazenda</p>
                        <p class="text-gray" style="text-align: center;font-size: 15px;padding: 0px 34px;">
                            No momento nossa capacidade máxima foi atingida.<br><br>
                            Por favor informe seu email para entrar na fila e enviaremos uma mensagem assim que sua
                            vez chegar. Agradecemos pela compreensão!
                        </p>
                    </div>
                    <div class="fields">
                        <div v-if="!this.switch.waiting_mode" class="field wide"
                            :class="{ eight: !checkMobile && show_captcha && this.switch.captcha_client, sixteen: checkMobile || !show_captcha || !this.switch.captcha_client }">
                            <p class="small">
                                {{ $t('signup.essesDadosSerao') }}
                            </p>
                        </div>

                        <div v-if="show_captcha && this.switch.captcha_client" class="field wide"
                            :class="{ eight: !checkMobile, sixteen: checkMobile }" ref="recaptcha_parent">
                            <vue-recaptcha ref="recaptcha" :sitekey="this.switch.captcha_client"
                                @render="checkCaptcha = false" @verify="checkCaptcha = true;"
                                @expired="checkCaptcha = false;" />
                        </div>
                    </div>
                    <button
                        v-if="(this.switch.waiting_mode && signUp.emailS_.length == 0) || !checkCaptcha || (!this.switch.waiting_mode && !isActive)"
                        ref="btn-submit" class="ui button positive fluid" type="button" disabled
                        v-text="btn_submit_value" />
                    <button v-else ref="btn-submit" class="ui button positive fluid" type="submit"
                        v-text="btn_submit_value" />
                </form>
                <p class="ainda">{{ $t('signup.jaECadastrado') }} <a
                        @click="openModal(false); $app.$refs.LoginModal.openModal(true);">{{
                $t('signup.entreAqui') }}</a></p>



                <p v-if="this.switch.cookie_policies">
                    <a href="#" @click="$app.political = true;">{{ $t('security.cookie_policies') }}</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import countries from '../../../public/js/countries.js';
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VueRecaptcha } from 'vue-recaptcha';
import LoginFacebook from '@/components/login/LoginFacebook';
import LoginGoogle from '@/components/login/LoginGoogle';
import LoginAzure from '@/components/login/LoginAzure';

export default {
    name: 'Signup',

    computed: {
        ...mapGetters([
            'webview', 'switch', 'checkers', 'isGuest', "checkMobile", "securityContainer"
        ]),

        isActive() {
            if (this.requireds === 0) {
                return true
            }
            return (this.checkers == this.requireds)
        },
        signUp() {
            var response = this.$store.getters['signup/cadastro']
            response.cpfS_ = this.mask_cpf(false, response.cpfS_)
            response.phoneS_ = this.mask_phone(false, response.phoneS_)
            return response
        },
        show_captcha() {
            return (this.switch && this.switch.captcha && this.switch.captcha == 1)
        },
        requireds() {
            let count = 0;
            for (let o in this.switch.optins)
                if (this.switch.optins[o].required == 1)
                    count++;

            return count;
        },
        only_major() {
            return this.switch.only_major ?? false;
        }
    },

    data() {
        return {
            btn_submit: false,
            btn_submit_value: this.$i18n.t('signup.cadastrar'),
            error: null,
            ref: null,
            captcha: null,
            checkCaptcha: false,
            cities: null,
            user_token: null,
            user_guest: false,
            regions: null,
            states: [
                { id: 1, value: "Acre", capital: "Rio Branco" }, { id: 2, value: "Alagoas", capital: "Maceió" }, { id: 3, value: "Amapá", capital: "Macapá" },
                { id: 4, value: "Amazonas", capital: "Manaus" }, { id: 5, value: "Bahia", capital: "Salvador" }, { id: 6, value: "Ceará", capital: "Fortaleza" },
                { id: 7, value: "Distrito Federal", capital: "Brasília" }, { id: 8, value: "Espírito Santo", capital: "Vitória" },
                { id: 9, value: "Goiás", capital: "Goiânia" }, { id: 10, value: "Maranhão", capital: "São Luís" },
                { id: 11, value: "Mato Grosso", capital: "Cuiabá" }, { id: 12, value: "Mato Grosso do Sul", capital: "Campo Grande" },
                { id: 13, value: "Minas Gerais", capital: "Belo Horizonte" }, { id: 14, value: "Pará", capital: "Belém" },
                { id: 15, value: "Paraíba", capital: "João Pessoa" }, { id: 16, value: "Paraná", capital: "Curitiba" },
                { id: 17, value: "Pernambuco", capital: "Recife" }, { id: 18, value: "Piauí", capital: "Teresina" },
                { id: 19, value: "Rio de Janeiro", capital: "Rio de Janeiro" },
                { id: 20, value: "Rio Grande do Norte", capital: "Natal" }, { id: 21, value: "Rio Grande do Sul", capital: "Porto Alegre" },
                { id: 22, value: "Rondônia", capital: "Porto Velho" }, { id: 23, value: "Roraima", capital: "Boa Vista" },
                { id: 24, value: "Santa Catarina", capital: "Florianópolis" }, { id: 25, value: "São Paulo", capital: "São Paulo" },
                { id: 26, value: "Sergipe", capital: "Aracaju" }, { id: 27, value: "Tocantins", capital: "Palmas" },
            ],
            nacionality: countries
        }
    },

    components: {
        LoginFacebook, LoginGoogle, VueRecaptcha, LoginAzure
    },

    methods: {
        ...mapMutations('signup', ['updateKeepOpen']),
        ...mapActions([
            'update_logged', 'update_token', 'update_email_verified', 'update_type_validation', 'update_id', 'update_login_social', "update_age_verified", "update_checkers", "update_guest",
            "update_started"
        ]),
        analytics(event_name, data) {
            event_name, data;
            //page, event, data;
            if (this.$gtag && this.securityContainer.admin_config.analytics) {
                this.$gtag.event(event_name, data);
            }
            //console.log('analytics => ', event_name, data);
        },
        handleLabelClick() {
            this.updateKeepOpen(true);
        },
        block(v) {
            this.$app.wait(v);
            this.btn_submit_value = (v ? (this.$i18n.t('signup.aguarde')) : this.$i18n.t('signup.cadastrar'));
            this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
            this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
        },
        just_number(v) {
            return v.replace(/\D/g, '');
        },
        check(e) {
            if ((e.key !== "Backspace") || (e.key !== "ArrowLeft") || (e.key !== "ArrowRight") || (e.key !== "Tab") || (e.key !== "Delete")) {
                if (!/^[0-9]*$/.test(e.key)) {
                    e.preventDefault();
                    return false;
                }
            }
        },
        mask_phone(e, value) {
            if (e) {
                this.check(e);
                let x = this.just_number(e.currentTarget.value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                e.currentTarget.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
            }
            else if (value) {
                let x = this.just_number(value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
            }
            else {
                return null;
            }
        },
        mask_cpf(e, value) {
            if (e) {
                this.check(e);
                let v = this.just_number(e.currentTarget.value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                e.currentTarget.value = !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
            }
            else if (value) {
                let v = this.just_number(value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                return !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
            }
            else {
                return null;
            }
        },
        check_captcha() {

        },
        cityHandler(e) {
            let form_data = new FormData();
            let root = this;
            if (e) {
                form_data.append('uf', this.$refs['state'].options[e.currentTarget.selectedIndex].dataset.value);
                this.signUp.cityS_ = "";
            } else if (root.signUp.stateS_) {
                let state = this.states.find(_ => _.value == root.signUp.stateS_)
                if (state) form_data.append('uf', this.states.find(_ => _.value == root.signUp.stateS_).id);
                else return;
            } else return;
            let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
            axios.post(this.$url_api + 'api/store/get_cities', form_data, { headers: headers }).then(response => this.cityHandlerResponse(response));
            if (e)
                e.preventDefault();
        },
        cityHandlerResponse(r) {
            let root = this;
            let data = r.data;
            let capital = this.states.find(_ => _.value === root.signUp.stateS_).capital;
            const capitalArray = data.filter(_ => _.name === capital)
            data = data.filter(_ => _.name !== capital)
            this.cities = [...capitalArray, ...data];
        },
        optinHandler(e) {
            if (this.checkers < 0) {
                this.update_checkers(0)
            }
            if (e.currentTarget.dataset.required) {
                if (e.currentTarget.checked)
                    this.update_checkers(this.checkers + 1);

                else
                    this.update_checkers(this.checkers - 1);
            }
        },
        loginSocial(data) {
            if (data.type === 'azure') {
                for (const optin of this.switch.optins) {
                    data[optin.name] = this.signUp.checks[optin.id]
                }
            }
            if (window.localStorage.getItem('query_tracking')) {
                data['query_tracking'] = window.localStorage.getItem('query_tracking');
            }
            this.$app.loginSocial(data, this.block, this.signupResponse, this.signupError)
        },
        checkPassword(pass) {
            let regex = /^(?=.*[!@#$%^&*()\-_+=\\[\]{}<>,.;:])(?=.*[0-9])(?=.*[A-Z]).{8,20}$/.test(pass);
            if (regex) {
                let only_numbers = pass.replace(/\D/g, "");
                if (String(only_numbers).length < 2)
                    return false;
            }
            return regex;
        },
        signup(e) {
            if ((this.securityContainer) && (this.securityContainer.event_security) && (this.securityContainer.event_security.strong_password)) {
                if (!this.checkPassword(this.$refs['password'].value)) {
                    this.error = this.$i18n.t('security.password_info') + '<br><br>!@#$%^&*()-_+=[]{}<>,.;:';
                    this.$app.modal('error', this.$i18n.t('signup.erro'), this.error, null, null, null, "OK");
                    return false;
                }
            }

            this.error = null;
            if (this.switch.waiting_mode) {
                if (this.show_captcha && this.checkCaptcha)
                    this.sendWaitingSignup();
            }
            else {
                if (this.switch.signup_extra_fields['birth']) {
                    let date_birth = this.$refs['birth_date'].value.split('/')
                    let birthday = new Date(date_birth[2] + "-" + date_birth[1] + "-" + date_birth[0])

                    let result = Math.floor((new Date() - birthday.getTime()) / 3.15576e+10)
                    if (this.only_major && (result < 18)) {
                        this.error = 'Idade não permitida'
                    }
                }





                if (this.error) {
                    this.$app.modal('error', this.$i18n.t('signup.erro'), this.error, null, null, null, "OK");
                } else {
                    this.sendSignup(e);
                }
            }

            e.preventDefault();
            return false;
        },
        sendSignup() {
            if (!this.submit) {
                this.block(true);

                let form_data = new FormData(this.$refs["signup-form"]);
                if (this.switch.signup_extra_fields['birth']) {
                    let birth_date = this.$refs["birth_date"].value.split('/').reverse().join('-')
                    form_data.append('birth_date', birth_date)
                }
                if (window.localStorage.getItem('query_tracking')) {
                    form_data.append('query_tracking', window.localStorage.getItem('query_tracking'));
                }
                form_data.append('event_name', this.switch.Nome_Aba ?? this.getCookie("switch_Nome_Aba") ?? localStorage.getItem("eventmap") ?? "");

                if (this.user_guest) {
                    if (localStorage.getItem("user")) {
                        let user = JSON.parse(localStorage.getItem("user"));
                        this.user_token = user.token;
                    } else {
                        this.user_token = null;
                    }
                    this.block(true);
                    let headers = {
                        "Content-Type": "application/json;charset=UTF-8",
                        'Access': "application/json",
                        'language': this.$i18n.locale,
                        'Authorization': `Bearer ${this.user_token}`
                    };
                    this.update_started(false);
                    axios
                        .post(this.$url_api + "api/updateGuest", form_data, { headers: headers })
                        .then((response) => this.signupResponse(response))
                        .catch((error) => this.signupError(error.response));
                } else {
                    axios.post(this.$url_api + 'api/registerWithAge', form_data, { headers: { 'language': this.$i18n.locale, } }).then(response => this.signupResponse(response)).catch(error => this.signupError(error.response));
                }
            }
        },
        sendWaitingSignup() {
            if (!this.submit) {
                this.block(true);
                let form_data = new FormData(this.$refs["signup-form"]);
                axios.post(this.$url_api + 'api/waiting_signup', form_data, { headers: { 'language': this.$i18n.locale, } }).then(response => this.waitingSignupResponse(response)).catch(error => this.waitingSignupError(error));
            }
        },
        signupResponse(obj, social = false, type = 'email', ref = '', email = null) {
            ref;
            if (this.ref != '') {
                this.$app.analytics('signup_by_friend_invite');
            }
            email = obj.data.email;
            this.$app.loginResponse(obj, social, type, 'signup', email)
            this.block(false);
        },
        signupError(obj) {
            this.block(false);
            let str = '<ul style="text-align: left;">';

            for (let o in obj.data.errors) {
                if (Array.isArray(obj.data.errors[o])) {
                    obj.data.errors[o].forEach((r) => {
                        str += `<li style="margin-bottom: 5px;">${r.charAt(0).toUpperCase() + r.slice(1)}</li>`
                    })
                } else {
                    str += `<li style="margin-bottom: 5px;">${obj.data.errors[o].charAt(0).toUpperCase() + obj.data.errors[o].slice(1)}</li>`;
                }
            }
            str += '</ul>';
            this.$app.modal('error', this.$i18n.t('signup.erro'), str, null, null, null, "OK");
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
                this.checkCaptcha = false
            }
        },
        waitingSignupResponse() {
            var root = this;
            this.$store.dispatch("signup/reset")
            this.$app.modal('success', 'Confirmação', 'Pré-cadastro realizado com sucesso!', function () {
                root.openModal(false);
            }, null, null, "OK");
        },
        waitingSignupError() {
            this.block(false);
            this.$app.modal('error', this.$i18n.t('signup.erro'), 'Erro ao tentar registrar seu pré-cadastro', null, null, null, "OK");
        },
        // getSetupResponse(data){
        //     this.optins = ((data && data.data && data.data.optins) ? data.data.optins : null);
        //     this.only_major = ((data && data.data && data.data.only_major) ? data.data.only_major : null);
        // },
        openModal(open) {
            let root = this;
            /* global $ */
            if (open) {
                if (!this.show_captcha)
                    this.checkCaptcha = true
                $("#dimmerSignup").dimmer();
                $('#signup').suimodal({
                    dimmerSettings: { opacity: 0.5 },
                    onShow() {
                    },
                    onHidden() {
                    },
                    onVisible() {
                        root.regions = root.switch.regions;
                        if (localStorage.getItem("user")) {
                            let user = JSON.parse(localStorage.getItem("user"));
                            root.user_guest = user.is_guest;
                        } else {
                            root.user_guest = false;
                        }
                        if (root.$route.query._ === "84502530") {
                            root.signUp.emailS_ = root.$route.query.email ?? "";
                        }
                        if (root.switch.waiting_mode)
                            root.$refs['email'].focus();
                        else
                            root.$refs['name'].focus();
                        root.ref = root.$route.query.ref ? root.$route.query.ref : '';
                        $("#birth_date").calendar({
                            type: 'date',
                            formatter: {
                                date: 'DD/MM/YYYY',
                            },
                            monthFirst: false,
                        })
                        let eventName = new Event('closeMenu');
                        document.dispatchEvent(eventName);
                        root.cityHandler()

                        if (root.show_captcha && root.$refs.recaptcha) {
                            root.$refs.recaptcha.reset();
                            root.rescaleCaptcha();
                            root.checkCaptcha = false
                        }

                        let totalCheckers = 0
                        for (const optinKey in root.switch.optins) {
                            const optin = root.switch.optins[optinKey];
                            if (optin.required && root.signUp.checks[optin.id]) {
                                totalCheckers++
                            }
                        }
                        root.update_checkers(totalCheckers);
                        root.analytics('page_view', {
                            page_title: 'Register Page',
                            page_location: '/#/cadastro'
                        })

                        if (root.show_captcha && root.$refs.recaptcha) {
                            root.$refs.recaptcha.reset();
                            root.rescaleCaptcha();
                            root.checkCaptcha = false
                        }
                    }
                }).suimodal('show');
            } else {
                $('#signup').suimodal('hide');
            }
        },
        rescaleCaptcha() {
            let cs = getComputedStyle(this.$refs.recaptcha_parent);
            let paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
            let borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
            let width = (this.$refs.recaptcha_parent?.offsetWidth??300) - paddingX - borderX;
            let scale = width / 302;
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.$refs.root.style.transform = 'scale(' + scale + ')';
                this.$refs.recaptcha.$refs.root.style.transformOrigin = '0 0';
                this.$refs.recaptcha.$refs.root.style.webkitTransform = 'scale(' + scale + ')';
                this.$refs.recaptcha.$refs.root.style.webkitTransformOrigin = '0 0';
            }
        },
        getEnrollmentPlaceholder() {
            return this.switch.enrollment_custom_field ?? this.$t('signup.enrollmentOrCPF');
        }
    },
    mounted() {

    },
    beforeDestroy() {
        window.$('#signup').suimodal('hide').remove();
    },
    updated() {
        if (!this.show_captcha)
            this.checkCaptcha = true;
        this.btn_submit_value = this.$i18n.t('signup.cadastrar');
    },
}

</script>
<style lang="scss" scoped>
#signup.suimodal {
    padding: 54px 44px;

    hr {
        border-top: rgba(255, 255, 255, 0.20) 1px solid;
        height: 1px;
        width: 100%;

        &~p {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    .icon.close {
        top: 44px;
        right: 44px;
        color: #fff;
    }

    .ui.checkbox {
        margin-top: 0;
        float: left;

        label {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
        }
    }

    .ui.checkbox input:checked~label::after {
        color: var(--buttons_color);
    }

    .small {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-top: 5px;
    }

    .fields {
        flex-wrap: wrap;
        margin-bottom: 0;

        .field.wide {
            padding: 0.5em;
        }
    }

    .socials {
        margin-top: 0;
    }

    .ui.button.positive {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}
</style>