<template>
    <div class="error-page">
        <div class="error-screen">
            <div class="error-icon">
                <svg width="91" height="90" viewBox="0 0 91 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.2129 70.2871C27.3184 77.2533 35.7474 80.7363 45.5 80.7363C55.2526 80.7363 63.612 77.2533 70.5781 70.2871C77.6836 63.1816 81.2363 54.7526 81.2363 45C81.2363 35.2474 77.6836 26.888 70.5781 19.9219C63.612 12.8164 55.2526 9.26367 45.5 9.26367C35.7474 9.26367 27.3184 12.8164 20.2129 19.9219C13.2467 26.888 9.76367 35.2474 9.76367 45C9.76367 54.7526 13.2467 63.1816 20.2129 70.2871ZM13.9434 13.6523C22.7207 4.875 33.2396 0.486328 45.5 0.486328C57.7604 0.486328 68.2096 4.875 76.8477 13.6523C85.625 22.2904 90.0137 32.7396 90.0137 45C90.0137 57.2604 85.625 67.7793 76.8477 76.5566C68.2096 85.1947 57.7604 89.5137 45.5 89.5137C33.2396 89.5137 22.7207 85.1947 13.9434 76.5566C5.30534 67.7793 0.986328 57.2604 0.986328 45C0.986328 32.7396 5.30534 22.2904 13.9434 13.6523ZM41.1113 22.6387H49.8887V49.3887H41.1113V22.6387ZM41.1113 58.375H49.8887V67.3613H41.1113V58.375Z" fill="var(--buttons_color)"/>
                </svg>
            </div>
            <h3 class="error-title">
                Página não encontrada
            </h3>

            <p class="error-description">
                Desculpe, mas a página que você está procurando não está disponível ou você não tem permissão para acessá-la.
            </p>
            
            <button class="error-button" @click="goTo">
                Voltar para a página inicial
            </button>
        </div>
    </div>
</template>
  
  <script>
  
  export default {
    name: 'LmsErrorScreen',
    computed: {

    },
    data() {
      return {

      }
    },
    components: {

    },
    created() {

    },
  
  
    methods: {
        goTo() {
            this.$router.push({name: 'LmsHome', params: {lmsSlug: this.$route.params.lmsSlug}});
        }
    },
    beforeMount() {

    },
    mounted() {
    },
    unmounted() {
    },
    beforeDestroy() {
  
    },
  }
  </script>
  
  <style lang="scss" scoped>
    .error-page {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90vh;

        .error-screen {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 64px 80px;
            border-radius: 50px;
            border: 1px solid #E1E1E1;
            background: #FFF;

            .error-icon {
                margin-bottom: 10px;
            }

            .error-title {
                font-size: 26px;
                font-weight: 600;
                color: var(--buttons_color);
            }

            .error-description {
                font-size: 18px;
                font-weight: 400;
                color: #706F6F;
                margin: 30px 0;
                line-height: 20px;
            }

            .error-button {
                width: 100%;
                max-width: 300px;
                height: 50px;
                border-radius: 50px;
                background: var(--buttons_color);
                color: #FFF;
                font-size: 16px;
                font-weight: 600;
                border: none;
                cursor: pointer;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                &:hover {
                    background: #5A47C9;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
                    transition: all .3s ease-in-out;
                }
            }
        }
    }
  </style>
  