<template>
    <div class="campaigns-wrapper">
        <div v-if="campaign !== null && typeof campaign === 'object' && Object.keys(campaign).length > 0">

            <!-- HEAD -->
            <header class="header">
                <img :src="campaign.image_desk" />
                <img class="mobi" :src="campaign.image_mobi" />
                <!-- <div class="img-wrapper" :style="{'background-image': 'url(' + campaign.image_desk + ')'}"></div>
                <div class="img-wrapper mobi" :style="{'background-image':'url('+(campaign.image_mobi ? campaign.image_mobi : campaign.image_desk)+')'}" ></div> -->
            </header>
            <!-- /HEAD -->

            <!-- MAIN -->
            <section class="main padding-v">
              <div class="container">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div class="main-wrapper">

                      <h1>INSCRIÇÃO DO ALUNO</h1>

                      <div v-if="!success" class="form-wrapper">
                        <form ref="subscribe" id="subscribe" method="post" @submit.prevent="submitter">

                          <div class="form__input icons">
                            <span class="icon-wrap"><i class="fas fa-user"></i></span>
                            <input v-model="form.name" @focusout="errorHandler" required data-check="1" type="text" name="name" placeholder="Nome Completo" value="" />
                            <span class="form__error"></span>
                          </div>

                          <div class="form__input icons">
                            <span class="icon-wrap"><i class="fas fa-envelope"></i></span>
                            <input v-model="form.email" @focusout="errorHandler" required data-check="1" type="email" name="email" placeholder="E-mail" value="" />
                            <span class="form__error"></span>
                          </div>

                          <div class="form__input icons">
                            <span class="icon-wrap"><i class="fas fa-venus-mars"></i></span>
                            <select v-model="form.gender" required data-check="1" name="gender">
                              <option value="" disabled selected hidden>Gênero?</option>
                              <option value="Masculino">Masculino</option>
                              <option value="Feminino">Feminino</option>
                              <option value="Prefiro não responder">Prefiro não responder</option>
                            </select>
                            <span class="form__error"></span>
                          </div>

                          <div class="form__input icons">
                            <span class="icon-wrap"><i class="fas fa-calendar-alt"></i></span>
                            <input v-model="form.birth" @focusout="errorHandler" required data-check="1" type="text" v-mask="'##/##/####'" class="form-birth" name="birth" placeholder="Data de Nascimento" value="" />
                            <span class="form__error"></span>
                          </div>

                          <div class="form__input w-100 icons">
                            <span class="icon-wrap"><i class="fas fa-basketball-ball"></i></span>
                            <select v-model="form.team" name="team">
                              <option value="" disabled selected hidden>Para qual time você torce?</option>
                              <option v-for="(team, index) in teams" :key="index" :value="team.label">{{ team.label }}</option>
                            </select>
                            <span class="form__error"></span>
                          </div>

                          <div class="form__checkbox">
                            <input type="checkbox" required data-check="1" name="accept_optin" value="1" />
                            <!-- <p>Eu aceito os <a @click="openModal('#terms')">JR. NBA* e seus Termos e Política de Privacidade.</a></p> -->
                            <p>Eu aceito os Termos JR. NBA* e seus <a href="https://www.nba.com/termsofuse" target="_blank">Termos</a> e <a href="https://www.nba.com/privacy-policy" target="_blank">Política de Privacidade</a>.</p>
                            <span class="form__error"></span>
                          </div>

                          <div class="form__checkbox">
                            <input type="checkbox" name="newsletter" value="1" />
                            <p>Use minhas informações pessoais registradas na JR. NBA e me envie notificação sobre produtos e iniciativas da JR. NBA.</p>
                          </div>

                          <div id="form__response"></div>

                          <div class="form__submit">
                            <button type="submit" id="form-subscribe-submitter" class="btn w-loader">
                              <span>FINALIZAR</span>
                              <div class="loader-spinner"></div>
                            </button>
                          </div>

                        </form>
                      </div>

                      <div v-else class="success-wrapper">
                        <div class="icon-wrapper"><i class="fas fa-check"></i></div>
                        <div class="info-wrapper">
                          <h4>SUCESSO</h4>
                          <p>Tudo certo na sua inscrição!</p>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>

              <!-- MODAL PP & TERMS -->
              <div id="terms" class="ui suimodal">
                  <div class="header">
                    Termos e Condições
                  </div>
                  <div class="content">
                    Lorem Ipsum
                  </div>
              </div>

            </section>

            <section v-if="configuration.sponsors.length > 0" class="sponsors padding-v">
                <div class="container">
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <h2>PATROCINADOR OFICIAL</h2>
                            <div class="sponsors-wrapper">
                                <div class="img-wrapper" v-for="(sponsor, index) in configuration.sponsors" :key="index">
                                    <img :src="sponsor.url" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </section>

            <footer v-if="configuration.footer_email" class="footer">
                <p>PRECISA DE AJUDA? <span class="theButton" @click.prevent="openEmail">FALE CONOSCO</span></p>
            </footer>

        </div>

        <div v-else-if="campaign === null">
          <section class="not-found">
            <div class="icon-wrapper"><i class="fas fa-exclamation-triangle"></i></div>
            <div class="info-wrapper">
              <h1>OPS...</h1>
              <p>Não achamos a campanha que você está procurando.</p>
            </div>
          </section>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
    name: "Campaign",
    computed: {},
    data() {
      return {
        hash: null,
        campaign: {},
        configuration: {
          footer_email    : '',
          bg              : [],
          banner_desk     : [],
          banner_mobi     : [],
          sponsors        : [],
        },
        success: false,
        form: {
          name: '',
          email: '',
          gender: '',
          birth: '',
          team: '',
        },
        fieldErrors: {
          name          : 'Escreva seu nome completo.',
          email         : 'Escreva um e-mail válido.',
          birth         : 'Escreva uma data válida (dd/mm/aaaa).',
          gender        : 'Selecione seu gênero.',
          accept_optin  : 'Você deve concordar com os termos e política de privacidade.',
          team          : 'Selecione seu time favorito.',
        },
        teams: [
          { "label": "Atlanta Hawks" },
          { "label": "Boston Celtics" },
          { "label": "Brooklyn Nets" },
          { "label": "Charlotte Hornets" },
          { "label": "Chicago Bulls" },
          { "label": "Cleveland Cavaliers" },
          { "label": "Dallas Mavericks" },
          { "label": "Denver Nuggets" },
          { "label": "Detroit Pistons" },
          { "label": "Golden State Warriors" },
          { "label": "Houston Rockets" },
          { "label": "Indiana Pacers" },
          { "label": "LA Clippers" },
          { "label": "Los Angeles Lakers" },
          { "label": "Memphis Grizzlies" },
          { "label": "Miami Heat" },
          { "label": "Milwaukee Bucks" },
          { "label": "Minnesota Timberwolves" },
          { "label": "New Orleans Pelicans" },
          { "label": "New York Knicks" },
          { "label": "Oklahoma City Thunder" },
          { "label": "Orlando Magic" },
          { "label": "Philadelphia 76ers" },
          { "label": "Phoenix Suns" },
          { "label": "Portland Trail Blazers" },
          { "label": "Sacramento Kings" },
          { "label": "San Antonio Spurs" },
          { "label": "Toronto Raptors" },
          { "label": "Utah Jazz" },
          { "label": "Washington Wizards" }
        ]
      };
    },
    beforeMount() {
      let vm = this;
      if (!vm.$route.params || !vm.$route.params.campaign) {
          console.log("redirecting to home");
          vm.$router.push({ name: "Home" });
      } else {
          vm.hash = vm.$route.params.campaign;
      }
      document.body.classList.add('campaigns');
    },
    mounted() {
      let vm = this;
      vm.$app.wait(true);
      vm.checkCampaign();
    },
    methods: {
      checkCampaign() {
          let vm = this;
          let headers = {
              "Content-Type": "application/json;charset=UTF-8",
              Accept: "application/json",
              language: this.$i18n.locale,
          };
          let params = {
              hash: vm.hash,
          };
          axios.post(vm.$url_api + "api/campaigns/check", params, headers)
            .then((response) => {
                vm.campaign       = response.data.data.campaign;
                vm.configuration  = response.data.data.config;
                // console.log("CAMPAIGN => ", vm.campaign);
                // console.log("CONFIG => ", vm.configuration);
            })
            .catch((error) => {
                console.log("ERROR CAMPAIGN => ", error);
                vm.campaign = null;
            })
            .finally(() => {
                vm.$app.wait(false);
            });
      },
      submitter() {

        const vm            = this;
        const form          = document.getElementById('subscribe');
        const formSubmitter = document.getElementById('form-subscribe-submitter');
        const formResponse  = $('#form__response');

        if( form && !form.classList.contains('busy') ) {

          form.classList.add('busy');                           // add busy class to show loading
          formSubmitter.classList.add('disabled');              // prevent multiple clicks
          formResponse.html('');                                // clean api response errors

          // check for errors
          if( vm.submitterCheckError(form) > 0 ) {              // check for errors
            form.classList.remove('busy');                      // remove busy to hide the loading
            formSubmitter.classList.remove('disabled');         // remove class so user can try again
            return false;
          }

          const formData = new FormData(this.$refs.subscribe);  // get the form data
          formData.append('hash', vm.hash);                     // append hash for API
          // console.log('FORM DATA =>', formData);
          const headers = {
              "Content-Type": "application/json;charset=UTF-8",
              Accept: "application/json",
              language: vm.$i18n.locale,
          };
          axios.post(vm.$url_api + "api/campaigns/subscriber", formData, headers)
            .then((response) => {
              response;
              // console.log("RESPONSE => ", response.data);
              vm.success = true;
            })
            .catch((errors) => {
              // console.log("ERROR SUBSCRIBER => ", errors);
              // console.log("ERROR RESPONSE ERRORS => ", errors.response.data.errors);  // good
              // console.log("ERROR RESPONSE DATA => ", errors.response.data.data);      // good
              vm.showApiResponseErrors(formResponse, errors);
            })
            .finally(() => {
                form.classList.remove('busy');                      // remove busy to hide the loading
                formSubmitter.classList.remove('disabled');         // remove class so user can try again 
            });

        }

      },
      submitterCheckError(form) {
        const fields      = form.querySelectorAll('[data-check="1"]');  // get all required fields
        let   formErrors  = 0;
        fields.forEach(field => {
          if (field) {
            let event = { target: field };      // Create a synthetic event object with the field as target
            if (!this.errorHandler(event)) {    // Call the errorHandler() function for each field
              formErrors++;                     // Increment formErrors if an error is found
            }
          }
        });
        // console.log('TOTAL ERRORS', formErrors);
        return formErrors;
      },
      errorHandler(e) {

        let vm            = this;
        let fieldName     = e.target.name;
        // let fieldValue    = e.target.value;
        let fieldParent   = e.target.closest('.form__input, .form__checkbox');
        let fieldError    = fieldParent.querySelector('.form__error');
        let fieldFunction = 'validate' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);  // name of the function
        // console.log('FNAME',fieldName, fieldValue, fieldFunction);

        if( typeof vm[fieldFunction] === 'function' ) {         // check if it's a function
          if( !vm[fieldFunction](e.target) ) {                  // call the function (always bollan)
            fieldParent.classList.add('error');                 // on error add error class
            fieldError.textContent = vm.fieldErrors[fieldName]; // on error place the text
            return false;
          } else {
            fieldParent.classList.remove('error');              // on success remove error class
            fieldError.textContent = '';                        // on success remove the text
            return true;
          }
        }
        
      },
      validateName(target){
        return Boolean(String(target.value.trim()).length > 4 && String(target.value.trim()).split(' ').length > 1);
      },
      validateEmail(target) {
        return Boolean(/\S+@\S+\.\S+/.test(target.value));
      },
      validateBirth(target) {
        // Regular expression to match date in format dd/mm/yyyy
        var dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

        // Check if the date string matches the regular expression
        if (!dateRegex.test(target.value)) {
          return false; // Date format is incorrect
        }

        // Extract day, month, and year parts from the date string
        var [, day, month, year] = target.value.match(dateRegex);

        // Convert day, month, and year to numbers
        day = parseInt(day, 10);
        month = parseInt(month, 10);
        year = parseInt(year, 10);

        // Check if month is valid (between 1 and 12)
        if (month < 1 || month > 12) {
          return false;
        }

        // Check if day is valid for the given month and year
        var maxDay = new Date(year, month, 0).getDate();
        if (day < 1 || day > maxDay) {
          return false;
        }

        // Check if year is valid
        if (year < 0 || year > 9999) {
          return false;
        }

        // Date is valid
        return true;
      },
      validateGender(target) {
        return Boolean(String(target.value).length > 1);
      },
      validateTeam(target) {
        return Boolean(String(target.value).length > 1);
      },
      validateAccept_optin(target) {
        return target.checked;
      },
      showApiResponseErrors(formResponse, errors) {

        formResponse.append('<div class="response-wrapper"><div class="icon-wrapper"><i class="fas fa-exclamation-triangle"></i></div><div class="response"></div></div>');
        
        if( errors.response && errors.response.data && errors.response.data.errors ) {
          const errorData = errors.response.data.errors;
          for (let field in errorData) {
            const errorMessages = errorData[field];
            errorMessages.forEach(errorMessage => {
              // console.log(`Error in field ${field}: ${errorMessage}`);
              formResponse.find('.response').append('<p>'+errorMessage+'</p>');
            });
          }
        }

        else if( errors.response && errors.response.data && errors.response.data.data ) {
          const errorData = errors.response.data.data;
          for (let field in errorData) {
            formResponse.find('.response').append('<p>'+errorData[field]+'</p>');
          }
        }
        // else {
        //   // console.error("Unknown error occurred:", errors);
        //   formResponse.find('.response').append('<p>'+errors+'</p>');
        // }

      },
      openModal(modal){
        window.$(modal).suimodal('show');
      },
      openEmail() {
        let vm = this;
        window.location.href = "mailto:"+vm.configuration.footer_email;
      },
    },
};
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
  @font-face {
    font-family: "Action NBA Condensed";
    src: url("/assets/fonts/bolditalic/ACTIONNBACONDENSED-BOLD ITALIC.OTF") format("opentype");
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: "Action NBA Condensed";
    src: url("/assets/fonts/bold/ACTIONNBACONDENSED-BOLD.OTF") format("opentype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Action NBA Condensed";
    src: url("/assets/fonts/lightitalic/ACTIONNBACONDENSED-LIGHT ITALIC.OTF") format("opentype");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Action NBA Condensed";
    src: url("/assets/fonts/lightitalic/ACTIONNBACONDENSED-LIGHT.OTF") format("opentype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Action NBA Condensed";
    src: url("/assets/fonts/mediumitalic/ACTIONNBACONDENSED-MEDIUM ITALIC.OTF") format("opentype");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Action NBA Condensed";
    src: url("/assets/fonts/medium/ACTIONNBACONDENSED-MEDIUM.OTF") format("opentype");
    font-weight: 500;
    font-style: normal;
  }
  body.campaigns{
    background: #000 !important;
    position: relative;
    min-height: 100vh;
    height: auto !important;
    &.pushable > .pusher {        // lgpd fix
      background: transparent;
      position: inherit !important;
    }
    .header-main{
      display: none;
    }
    .padding-v { padding: 50px 0; }
    .padding-v-t { padding: 50px 0 0 0; }
    .padding-v-b { padding: 0 0 50px 0; }
    .padding-v-half{ padding: 25px 0; }
    .padding-v-t-half { padding: 25px 0 0 0; }
    .padding-v-b-half { padding: 0 0 25px 0; }
    .disabled * {
      background-color: transparent;
    }
    &:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(23, 42, 91, 0.70) 0%, rgba(129, 20, 37, 0.70) 100%);
    }
    .campaigns-wrapper {
      min-height: 100vh;
      position: relative;
      // background: orange;
      .header {
        position: relative;
        background: #ddd;
        height: 330px;
        height: auto !important;
        box-shadow: inset 0 0px 10px 0 rgba(0, 0, 0, 0.6);
        transition: all .3s ease;
        img{
          height: auto;
          width: 100%;
          &.mobi{
            display: none;
          }
        }
        .img-wrapper {
          background-size: cover;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-position: center;
          &.mobi {
              display: none;
          }
        }
      }
      .main{
        .main-wrapper{
          h1{
            color: #fff;
            text-align: center;
            font-size: 38px;
            line-height: 1.1;
            margin-bottom: 50px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-family: "Action NBA Condensed", sans-serif;
            font-size: 90px;
            line-height: 80px;
            letter-spacing: 0.01em;
          }
        }
      }
    }

    // FORM
    .form-wrapper{
      form{
        display: flex;
        gap: 15px;
        gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .form__input,
    .form__checkbox {
      transition: all .3s ease;
      margin-bottom: 10px;
      position: relative;
      // margin-bottom: 10px;
      position: relative;
      flex-basis: calc(50% - 7.5px);
      flex-basis: calc(50% - 10px);
      &.w-100{
        flex-basis: 100%;
      }
    }
    .form__input .icon-wrap {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      color: #858585;
      font-size: 22px;
      line-height: 22px;
    }
    .form__input input:-webkit-autofill,
    .form__input input:-webkit-autofill:hover, 
    .form__input input:-webkit-autofill:focus, 
    .form__input input:-webkit-autofill:active,
    .form__input input[type="password"]:-webkit-autofill,
    .form__input input[type="password"]:-webkit-autofill:hover, 
    .form__input input[type="password"]:-webkit-autofill:focus, 
    .form__input input[type="password"]:-webkit-autofill:active{
      // -webkit-background-clip: text;
      background: #FFFFFF !important;
      // -webkit-text-fill-color: #ffffff;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: 0 0 0 30px white inset !important;
      box-shadow: none !important;
    }
    // .form__input input:-webkit-autofill,
    // .form__input input:-webkit-autofill:focus {
    //     transition: background-color 600000s 0s, color 600000s 0s;
    // }
    // .form__input input[data-autocompleted] {
    //     background-color: transparent !important;
    //     box-shadow: 0 0 0 30px white inset !important;
    // }
    .form__input input[type="text"],
    .form__input input[type="email"],
    .form__input input[type="date"],
    .form__input input[type="password"],
    .form__input textarea,
    .form__input select,
    .form__input span.disabled {
      background: #FFFFFF;
      border: 2px solid #EDEDED;
      border-radius: 10px;
      padding: 0;
      box-sizing: border-box;
      color: #8E8E8E;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      padding: 15px 20px;
      outline: none;
      display: inline-block;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -ms-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all .3s ease;
    }

    .form__input.icons input[type="text"],
    .form__input.icons input[type="email"],
    .form__input.icons input[type="date"],
    .form__input.icons input[type="password"],
    .form__input.icons textarea,
    .form__input.icons select,
    .form__input.icons span.disabled {
      padding: 15px 20px 15px 50px;
    }

    .form__input input[type="text"]:focus,
    .form__input input[type="email"]:focus,
    .form__input input[type="date"]:focus,
    .form__input input[type="password"]:focus,
    .form__input textarea:focus,
    .form__input select:focus {
      border-color: #555;
    }
    .form__checkbox {
      width: 100%;
      // margin: 8px 0;
      display: inline-flex;
      flex-flow: row nowrap;
    }
    .form__checkbox p {
      margin: 0;
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      white-space: break-spaces;
      margin-top: 2px;
      color: #fff;
      font-family: "Montserrat", sans-serif;
    }
    .form__checkbox p span,
    .form__checkbox p a {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      transition: 300ms color;
      color: #fff;
    }
    .form__checkbox p span:hover,
    .form__checkbox p a:hover {
      color: #fff;
    }
    .form__checkbox input[type='checkbox'] {
      height: 22px;
      min-height: 22px;
      width: 22px;
      min-width: 22px;
      margin: auto 16px auto 0px;
      margin: 5px 16px auto 0px;
      display: grid;
      place-content: center;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      border: 2px solid #CACACA;
      border-radius: 6px;
      transform: translateY(-0.075em);
    }
    .form__checkbox input[type='checkbox']::before {
      content: '';
      height: 12px;
      width: 12px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: #0E3B83;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    .form__checkbox input[type='checkbox']:checked {
      border: 2px solid #0E3B83;
    }
    .form__checkbox input[type='checkbox']:checked::before {
      transform: scale(1);
    }

    .form__submit {
      margin: 10px 0 0 0;
      text-align: center;
      width: 100%;
    }
    .form__submit button,
    .form__submit button:active,
    .form__submit button:focus,
    .form__submit button:hover {
      position: relative;
      border: 2px solid rgba(0, 0, 0, 0);
      border-radius: 6px;
      padding: 15px 60px;
      text-align: center;
      margin: 0;
      line-height: 20px;
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      background: #FE1230;
      outline: none;
      box-shadow: none;
      transition: all .3s ease;
      font-family: "Action NBA Condensed", sans-serif;
      // font-size: 90px;
      // line-height: 80px;
      // letter-spacing: 0.01em;
    }
    .form__submit button span{
      transition: all 0.3s ease;
      font-family: "Action NBA Condensed", sans-serif;
      font-size: 34px;
      line-height: 26px;
      line-height: 1;
      letter-spacing: 0.03em;
    }
    .form__submit button.w-loader .loader-spinner {
      opacity: 0;
    }
    .busy .form__submit button{
      cursor: default;
    }
    .busy .form__submit button.w-loader span {
      opacity: 0;
    }
    .busy .form__submit button.w-loader .loader-spinner {
      opacity: 1;
    }

    // error
    .form__error{
      opacity: 0;
      position: absolute;
      bottom: -20px;
      left: 0;
      color: #FE1230;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      transition: all .3s ease;
    }
    .form__input.error,
    .form__checkbox.error {
      .form__error{
        opacity: 1;
      }
      input[type="text"],
      input[type="email"],
      select{
        border-color: #FE1230;
      }
    }
    // end error

    #form__response{
      width: 500px;
      margin: 0 auto;
      // text-align: center;
      .response-wrapper{
        display: flex;
        align-items: center;
        padding: 15px;
        background: #f0f0f0;
        border-radius: 6px;
        width: 100%;
        box-shadow: 0px 3px 5px 0 rgba(0,0,0,.3);
        .icon-wrapper{
          font-size: 30px;
          color: #fe1230;
          margin-right: 15px;
        }
        .response {
          // margin-top: 5px;
          p{
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            line-height: 1.1;
            margin: 5px 0;
          }
        }
      }
    }
    // END FORMS


    // NOT FOUND
    .not-found{
      display: flex;
      width: 500px;
      position:absolute;
      background: #fff;
      padding: 20px;
      top: 50%;
      left: 50%;
      border-radius: 6px;
      box-shadow: 3px 0 8px 0 rgba(0,0,0,.5);
      transform: translate(-50%, -50%);
      .icon-wrapper{
        font-size: 30px;
        color: #fe1230;
        margin-right: 15px;
      }
      .info-wrapper {
        margin-top: 5px;
        h1{
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-family: "Action NBA Condensed", sans-serif;
          font-size: 60px;
          line-height: 50px;
        }
        p{
          font-size: 16px;
          font-family: 'Montserrat', sans-serif;
          line-height: 1.1;
        }
      }
    }
    // END NOT FOUND

    // SUCCESS
    .success-wrapper{
      display: flex;
      margin: 40px auto;
      margin: 60px auto 40px auto;
      width: 500px;
      background: #fff;
      padding: 20px;
      border-radius: 6px;
      box-shadow: 3px 0 8px 0 rgba(0,0,0,.5);
      .icon-wrapper{
        font-size: 40px;
        color: #29c829;
        margin-right: 15px;
      }
      .info-wrapper {
        margin-top: 5px;
        h4{
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-family: "Action NBA Condensed", sans-serif;
          font-size: 60px;
          line-height: 50px;
        }
        p{
          font-size: 16px;
          font-family: 'Montserrat', sans-serif;
          line-height: 1.1;
        }
      }
    }
    // END SUCCESS

    // animations (loader)
    .loader-spinner {
      // z-index: 1999999;
      border-width: 4px;
      border-style: solid;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      position: absolute;
      border-radius: 100%;
      margin-left: -15px;
      margin-top: -15px;
      border-color: #fff;
      border-top-color: #ffa9a9;
      border-right-color: #ffa9a9;
      border-bottom-color: #ffa9a9;
      -webkit-animation: ldr-spin 0.6s linear infinite;
      animation: ldr-spin 0.5s linear infinite;
    }
    @keyframes ldr-spin {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }

      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
    }
    @-moz-keyframes ldr-spin {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }

      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
    }
    @-webkit-keyframes ldr-spin {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }

      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
    }




}


  // media queries (mobile last)
  @media only screen and (max-width: 1470px) {
  }

  @media only screen and (max-width: 1200px) {
    body.campaigns{
      .container{
        width: 100%;
        max-width: 100%;
      }
      .campaigns-wrapper {
        .header {
          height: 330px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    body.campaigns{
      .campaigns-wrapper {
        .header {
          height: 330px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    body.campaigns{
      .campaigns-wrapper {
        .header {
          height: 330px;
          img{
            display: none;
            &.mobi{
              display: block;
            }
          }
          .img-wrapper {
            display: none;
            &.mobi {
              display: block;
            }
          }
        }
        .main{
          .main-wrapper{
            h1{
              font-size: 32px;
              font-size: 62px;
              font-size: 56px;
              line-height: 56px;
            }
          }
        }
      }
      .form-wrapper{
        max-width: 500px;
        margin: 0 auto;
      }
      .form__input,
      .form__checkbox {
        flex-basis: 100%;
      }
      #form__response{
        width: 100%;
      }
      .not-found,
      .success-wrapper{
        width: calc(100% - 40px);
      }
    }
  }

  @media only screen and (max-width: 480px) {

  }
</style>