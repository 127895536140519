<template>
  <div class="d-flex justify-content-between" style="width: 100%;">
    <div class="card-title  d-flex align-items-center">
      {{ coursename }}
    </div>
    <div class="content-progress d-flex align-items-center">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36" class="circular-chart">
          <path class="circle-bg"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path class="circle"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="circumference - progress / 100 * circumference"
          />
          <g class="icon" transform="translate(18, 18)">
            <!-- Compensar a largura e altura do ícone -->
            <g transform="translate(-4, -7)"> <!-- Ajuste esses valores conforme necessário -->
              <!-- Seu ícone SVG aqui -->
              <path d="M0.15625 12.5L5.625 7L0.15625 1.5L1.34375 0.34375L8 7L1.34375 13.6562L0.15625 12.5Z" fill="#5F5F5F"/>
            </g>
          </g>

        </svg>
      </div>
    </div>
  </div>
  
  </template>
  
  <script>
  
  
  export default {
    name: "CourseCard",
    props: [
      "coursename",
      "coursedata"
    ],
    watch:{

    },
    data() {
      return {
        circumference: 0,
        progress: 0,
      }
    },
    mounted(){
      this.progress = this.coursedata.user_progress;
      this.circumference = 2 * Math.PI * 15;
    },
    methods: {

    }
  }
  </script>
  
  <style lang="scss" scoped>

.content-progress {
  width: 40px;
  height: 40px;
  position: relative;
}

.progress-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
}
.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #5F5F5F;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 4.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
  stroke: #A18AE5;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.percentage {
  fill: #5F5F5F;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title {
  color: #706F6F;
  font-size: 18px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-wrap: nowrap;
  overflow: hidden;
  cursor: pointer;
  padding: 0 20px;
  text-align: left;
}
 
  
  @media only screen and (max-width: 1400px) {
  
  }
  
  @media only screen and (max-width: 1200px) {

  }
  
  @media only screen and (max-width: 992px) {
    
    
  }
  @media only screen and (max-width: 768px) {

  }
  @media only screen and (max-width: 480px) {

  }
  
  
  
  </style>