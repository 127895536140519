<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" :searchField="false" searchPlaceholder="Pesquisar no feed...">
    <template v-slot:navigation-side v-if="!isMobile">
      <ProfileNavigation></ProfileNavigation>

      <section class="mt-3">
        <span class="mycommunities">{{navigationConfig.navigationTitle}}</span>
        <div class="menu-buttons mt-3 d-flex flex-column gap-3">
          <button v-for="(button, idx) in navigationConfig.navigation" :key="idx" type="button" class="btn py-3" @click.prevent="goTo(button.slug)">
            <i v-if="button.icon" :class="button.icon"></i>
            {{ button.name }}
          </button>
        </div>
      </section>
    </template>
    <template v-slot:content-side>
      <div class="loading-overlay" v-if="loadingAction">
          <div class="loader"></div>
      </div>
      <div class="row d-flex justify-content-center align-items-center">
          <div class="col-12">

              <div v-if="pinned" class="container d-flex justify-content-center align-items-center" style="margin-top: 36px;">
                  <LmsFeedPost :loading="loading" :post="pinned" :removePost="removePost" :removeUser="removeUser" :isMobile="isMobile"></LmsFeedPost>  
              </div>

              <div v-for="(post, idx) in postsToShow" :key="idx" class="container d-flex justify-content-center align-items-center" style="margin-top: 36px; margin-bottom: 40px;">
                  <LmsFeedPost :loading="loading" :post="post" :removePost="removePost" :removeUser="removeUser" :isMobile="isMobile"></LmsFeedPost>
              </div>

              <div class="centered-loading" v-if="loadingPosts">
                <div class="loader"></div>
              </div>
          </div>
      </div>
    </template>
  </LmsBasicTemplate>
</template>
<script>

import { mapGetters } from "vuex";
import LmsBasicTemplate from "@/components/lms/LmsBasicTemplate.vue";
import ProfileNavigation from "@/components/lms/components/ProfileNavigation.vue";
import LmsFeedPost from "@/components/lms/feed/components/LmsFeedPost.vue";

export default {
  name: "LmsMyFeedSocial",
  components: {
    LmsBasicTemplate,
    ProfileNavigation,
    LmsFeedPost
  },
  data() {
    return {
      pinned: null,
      postsToShow: [],          // posts para exibir
      loadingPosts: true,      // pagination: para checar se está carregando mais mensagens
      endPosts: false,          // pagination: para checar se chegou ao fim do total de mensagens
      limitPosts: null,         // pagination: para saber qual é o limit vindo do back
      offsetPosts: 0,         // pagination: para o total de mensagens carregadas ate o momento
      loadingAction: false, 
      isMobile: false,
    }
  },
  beforeMount() {
    let root_ = this;
    root_.navigationConfig = root_.getPageByName("LmsMyFeedSocial");
    root_.getPosts(root_.offsetPosts, true);

    root_.isMobile = (root_.checkMobile || root_.checkIOS);
  },
  mounted() {
    let root_ = this;

    window.addEventListener('scroll', root_.onScroll);
  },
  unmounted() {
    let root_ = this;
    window.removeEventListener('scroll', root_.onScroll);
  },
  methods: {
    loading(status) {
      this.loadingAction = status;
    },
    onScroll() {
      let root_ = this;
      if(!root_.loadingPosts) {
        const { innerHeight } = window;
        const {scrollTop, scrollHeight} = window.document.documentElement;
        if (scrollTop + innerHeight >= scrollHeight) {
          if(!root_.endPosts) {
            root_.getPosts(root_.offsetPosts, false);
          }
        }
      }
    },

    removeUser(id) {
      let root_ = this;
      root_.postsToShow = root_.postsToShow.filter( post => post.user_id != id);
    },

    removePost(id) {
      let root_ = this;
      root_.postsToShow = root_.postsToShow.filter( post => post.id != id);
    },

    postListAdd() {
      this.getPosts(0, true);
    },

    goTo(slug){
      console.log("goTo: ", slug);
      if(slug !== "") {
        this.$router.push({ name: slug})
      }
    },

    getPosts(offset = 0, first = false) {
      let root_ = this;

      let payload = {
        "only": true,
        "slug": root_.$route.params.lmsSlug,
        "offset": offset,
      };

      if(first || !root_.loadingPosts) {
        root_.loadingPosts = true;

        root_.$store.dispatch('Feed/getLmsPosts', payload).then( response => {
          response = response.data;
          console.log(response.posts[0]);
          if (first) {
            root_.postsToShow = response.posts;
            root_.pinned = response.pinned;
          } else {
            root_.postsToShow.push(...response.posts);
          }

          root_.offsetPosts         = response.pagination.offset;
          root_.limitPosts          = (root_.limitPosts) ? root_.limitPosts : response.pagination.limit;
          root_.endPosts            = response.pagination.is_end;
          root_.loadingPosts = false;
        }).catch( error => {
          error;
          root_.loadingPosts = false;
        });
      }
    },
  },
  computed:{
    ...mapGetters({
      getLms: "LMS/getLms",
      getPageByName: "LMS/getPageByName",
    }),
    ...mapGetters([
        'logged', 'token', 'switch', 'sessions', 'checkIOS', 'checkMobile'
    ])
  },
  beforeDestroy() {
    let root_ = this;
    window.removeEventListener('scroll', root_.onScroll);
  },

}

</script>

<style scoped lang="scss">


.custom-div {
  width: 100%;
  height: 420px;
  position: relative;
  background-image: url('https://cdn.discordapp.com/attachments/931328487011270696/1147223633991835678/isam0ntingelli_vector_art_flat_colors_jetta_2019_volkswagen_dar_be9c4a69-efef-4965-b86c-8ccce3d8075f.png');
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 100px;
}

.custom-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: inherit;
}


.title {
  font-size: 24px;
  font-weight: bold;
}

.description {
  font-size: 18px;
  margin-top: 10px;
}

.VuePagination {
  display: flex;
  justify-content: center;
}

.mycommunities {
  color: #706F6F;
  font-size: 16px;
  font-family: "Hanken Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.menu-buttons {

  .btn {
    color: #706F6F;
    background-color: #F6F6F6;
    font-size: 18px;
    font-family: "Hanken Grotesk", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;

    .bi {
      color: var(--buttons_color);
    }
  }

}

.centered-loading {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Cor de fundo preto semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Certifique-se de que a div esteja na parte superior */
}

.loader {
  border: 4px solid var(--buttons_color);
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>