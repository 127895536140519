<template>
    <div id="product">
        
        <div v-if="product && product.prices != undefined" class="box" id="product-container">
                <a href="#" id="btn-close" ref="btn-close" v-on:click="$parent.product=false"><img :src="$url + 'assets/img/oracle/x_24.png'"></a>
                <div>
                    <img v-if="product_actual" :src="product_actual" style="max-width: 100%;max-height:270px">
                </div>

                <hr v-show="product && product.files.length > 0">
                <div v-show="product && product.files.length > 0" id="thumbs" ref="thumbs">
                    <ul>
                        <li v-for="(item, index) in this.product.files" :key="index" :data-index="index" :data-archive-id="item.ArchiveId" v-on:click="image_handler">
                            <img :src="'https://payottatix.vteximg.com.br/arquivos/ids/' + item.ArchiveId + '-70-70/' + item.ArchiveId + '.jpg'" alt="Thumb">
                        </li>
                    </ul>
                </div>

                <hr>
                <div>
                    <ul>
                        <li>
                            <h3>{{ product.product.Name }}</h3><br>
                        </li>
                        <li v-html="product.product.Description" style="text-align:justify;padding: 0 20px">
                        </li>
                        <li>
                            <h3 style="padding: 20px 10px 10px 10px"><b>R$ {{ Number(product.prices.listPrice).toFixed(2).replace(".", ",") }}</b></h3>
                        </li>
                        <li>
                            <ul>
                                <li><a class="less" v-on:click="decrement">-</a></li>
                                <li id="product-count">{{ count }}</li>
                                <li><a class="more" v-on:click="increment">+</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="product.skus.length > 0" id="skus" ref="sku">
                    <hr>
                    <h3>Produtos relacionados</h3>
                    <br>
                    <ul>
                        <li v-for="(item, index) in this.product.skus" :key="index" :data-index="index" :data-sku="item.Id" v-on:click="sku_handler">
                            {{ item.Name }}
                        </li>
                    </ul><br>
                    <hr>
                </div>
                <div id="product-button">
                <ul>
                    <li>
                        <a class="btn-0" href="#" v-on:click="$parent.product = null">Cancelar</a>
                    </li>
                    <li><button class="btn-1" ref="btn-submit" type="button" v-on:click="add">Adicionar</button></li>
                </ul>
            </div>
        </div>

    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import axios from "axios";
    export default {
        name: "Product",
        props: ['product_sku'],
        computed: {
            ...mapGetters([
                'token', 'logged', "store_urls","cart_token"
            ]),
        },
        data() {
            return {
                count: 1,
                product: null,
                product_actual: null,
                thumb_actual: null
            }
        },
        methods: {
            ...mapActions([
                'update_cart_products',"update_cart_token"
            ]),

            
            image_handler(e)
            {
                //console.log(e.currentTarget.dataset.index);
                //this.product_actual = this.store_urls.url_api + 'storage/product/' + this.product.id + '/' + this.product.files[e.currentTarget.dataset.index].name + '_600.' + this.product.files[e.currentTarget.dataset.index].ext;
                console.log(e.currentTarget.dataset.archiveId);
                this.product_actual = 'https://payottatix.vteximg.com.br/arquivos/ids/' + e.currentTarget.dataset.archiveId + '-400-400/' + e.currentTarget.dataset.archiveId + '.jpg';
                console.log('this.product_actual => ', this.product_actual);
                e.preventDefault();
            },

            sku_handler(e)
            {
                //SkuId
                e.preventDefault();
                console.log(e.currentTarget.dataset.sku);
                this.getSKUFiles(e.currentTarget.dataset.sku);
            },
            

            increment(e) {
                this.count++;

                e.preventDefault();
            },
            decrement (e) {
                if (this.count > 1)
                    this.count--;

                e.preventDefault();
            },

            add()
            {
                this.$app.wait(true);
                let form_data = new FormData();
                if (this.cart_token){
                    console.log(!!this.cart_token)
                    form_data.append('cart_token', this.cart_token);
                }
                form_data.append('seller', this.product.seller);
                form_data.append('product', this.product.skus[0].Id);
                form_data.append('quantity', this.count);
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.$url_api + 'api/store/vtex-add-product', form_data, { headers: headers })
                    .then(response => this.addProductResponse(response));
            },
            addProductResponse({data}){
                data = data.data;
                this.update_cart_token(data.orderFormId);
                let l_ = { id:data.product.id, quantity:data.product.quantity, description:"", price:data.product.price/100, amount:data.product.priceDefinition.total/100, files:data.product.imageUrl };
                this.update_cart_products(l_);
                this.$parent.add_success = true;
                this.$parent.product = null;
                this.$parent.cart = true;
                this.$app.wait(false);
            },
            getProduct()
            {
                this.$app.wait(true);
                let form_data = new FormData();
                form_data.append('product', this.product_sku);
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.$url_api + 'api/store/vtex-product', form_data, { headers: headers })
                    .then(response => this.getProductResponse(response))
            },
            getProductResponse(obj)
            {
                if (obj.data.data)
                {
                    this.product =  obj.data.data;
                    this.product_actual = 'https://payottatix.vteximg.com.br/arquivos/ids/' + this.product.files[0].ArchiveId + '-400-400/' + this.product.files[0].ArchiveId + '.jpg';
                    this.$forceUpdate();
                    this.$app.wait(false);
                }
            },
            getSKUFiles(sku_)
            {
                this.$app.wait(true);
                let form_data = new FormData();
                form_data.append('sku', sku_);
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.$url_api + 'api/store/vtex-sku-files', form_data, { headers: headers }).then(response => this.getSKUFilesResponse(response));
            },
            getSKUFilesResponse(obj)
            {
                this.product_actual = 'https://payottatix.vteximg.com.br/arquivos/ids/' + obj.data[0].ArchiveId + '-400-400/' + obj.data[0].ArchiveId + '.jpg';
                this.product['files'] = obj.data;
                this.getSKUPrices(obj.data[0].SkuId);
            },
            getSKUPrices(sku_)
            {
                let form_data = new FormData();
                form_data.append('sku', sku_);
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.$url_api + 'api/store/vtex-sku-prices', form_data, { headers: headers }).then(response => this.getSKUPricesResponse(response));
            },
            getSKUPricesResponse(obj)
            {
                this.product['prices'] = obj.data;
                this.$forceUpdate();
                this.$app.wait(false);
            }
        },
        mounted() {
            console.log('product mounted => ', this.product_sku);
            
            this.getProduct();
        }
    }
</script>

<style scoped>
    #product
    {
        background-color: rgba(0, 0, 0, .6);
        font-family: inherit;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
    }
    h1
    {
        box-sizing: border-box;
        text-align: center;
        padding: 10px 0;
    }
    h3
    {
        font-weight: normal;
    }
    #product #product-container
    {
        background: white;
        border-radius: 0.5rem;
        display: block;
        margin: 0  auto;
        max-height: 80%;
        max-width: 600px;
        overflow-y: auto;
    }
    #product #product-container::-webkit-scrollbar {
        width: 8px;
    }

    #product #product-container::-webkit-scrollbar-track {
        background: rgba(0,0,0,.2);
    }

    #product #product-container::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.2);
    }
    #product #product-container div
    {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
    }
    @media screen and (max-width: 500px)
    {
        #product-container
        {
            width: calc(100% - 40px);
        }
    }

    #product ul
    {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    #product ul > li > ul > li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 10px;
    }
    #product .less, #product .more
    {
        background-color: black;
        border-radius: 10px;
        color: white !important;
        display: block;
        font-family: inherit;
        font-size: 30px;
        font-weight: bold;
        outline: none;
        height: 50px;
        line-height: 50px;
        text-align: center;
        user-select: none;
        width: 70px;
    }
    #product #product-count
    {
        color: var(--buttons_color);
        font-family: inherit;
        font-size: 35px;
        font-weight: bold;
        height: 50px;
        text-align: center;
        width: 50px;
    }


    #product-button
    {
        width: 100%;
    }
    #product-button > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        display: inline-block;
        padding: 5px;
        width: calc(100% / 2);
    }

    #thumbs
    {
        border-radius: 10px;
        box-sizing: border-box;
        display: block;
        margin: 5px 0;
    }
    #thumbs > ul > li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 2px 5px;
    }

    #skus
    {
        box-sizing: border-box;
        display: block;
        padding: 20px 0;
    }
    #skus > ul > li
    {
        border: solid 1px #ccc;
        border-radius: 20px;
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        padding: 10px;
    }
</style>