import axios from "axios";

export default {


    getStudentBooks({ rootState }, payload) {

        let endpoint = this._vm.$url_api + 'api/lms/user/books/books';

        return new Promise((resolve, reject) => {


            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: payload
                })
                .then(response => {
                    resolve(response.data.data.books);
                })
                .catch(error => {
                    console.error(error.response.data);

                    reject(error.response.data);
                });
        });

    },

    initWhiteboard({commit, rootState}, payload) {

        let endpoint = this._vm.$url_api + 'api/lms/user/books/init';

        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.post(
                endpoint,
                payload, { headers: headers })
                .then(response => {
                    commit("update_init", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error.response.data.errors || error.response.data.data);
                    reject(error.response.data.errors || error.response.data.data);
                });

        });

    },

    setSelectedBook({ commit }, book) {
        commit("update_selected_book", book);
    },

    createBook({ commit, rootState }, payload) {

        let endpoint = this._vm.$url_api + 'api/lms/user/books/create';

        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.post(
                endpoint,
                payload, { headers: headers })
                .then(response => {
                    response.data.data.book.book = JSON.parse(response.data.data.book.book);

                    commit("update_selected_book", response.data.data.book);
                    commit("update_book_created_now", true);
                    resolve(response.data.data.book);
                })
                .catch(error => {
                    console.error(error.response.data.errors || error.response.data.data);
                    reject(error.response.data.errors || error.response.data.data);
                });

        });


    },

    updateSelectedBook({commit, rootState}, payload) {


        let url = this._vm.$url_api + 'api/lms/user/books/update';

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }

        return new Promise((resolve, reject) => {

            axios.put(
                url,
                payload,
                { headers:headers })
                .then((response) => {
                    response.data.data.book.book = JSON.parse(response.data.data.book.book);

                    commit('update_selected_book', response.data.data.book);
                    resolve(response.data.data.book.book);
                }).catch((error) => {
                    console.error(error.response)
                    reject(error)
                });
        });


    },

    deleteBook({ commit, rootState }, payload) {
        commit;
        let url = this._vm.$url_api + 'api/lms/user/books/delete';

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            Authorization : `Bearer ${rootState.token}`
        }

        return new Promise((resolve, reject) => {
            axios.delete(url, {
                headers: headers,
                params: {
                    book_id: payload.book_id
                }
                })
                .then((response) => {
                resolve(response);
            }).catch((error) => {
                console.log(error.message)
                reject(error)
            })
        })
    },

    fetchTemplates({ rootState }, lmsId) {

        let endpoint = this._vm.$url_api + 'api/lms/user/books/templates/' + lmsId;

        return new Promise((resolve, reject) => {


            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    console.error(error.response.data);

                    reject(error.response.data);
                });
        });


    },

    setBookCreatedNow({commit}, status) {
        commit("update_book_created_now", status);
    }


}