<template>
    <div id="age" ref="age">
        <div class="ageGate flex flex-wrap"
             style="background-size:cover;background-position:center center;
             background-attachment: fixed;background-repeat: no-repeat;  height: 100%;min-height: 100vh;">
            <form action="" id="age-form" ref="age-form" method="POST">
                <div class="age-form__content" style="background-color:white">
                    <h2 v-if="Habilitar_Age_Gate" class="title">Você é maior de 18 anos?</h2>
                    <div class="card-age" style="margin:0 120px;z-index:2;position:relative">
                        <div style="background-color:white;margin-top:-5px;padding:0px 10px">
                            <div v-if="Habilitar_Age_Gate" class="buttonmaior btncontainer flex alignVerticalEvenly">
                                <div class="maior-menor">
                                    <input id="maior"
                                           ref="maior" type="radio" name="maio-menor" value="1">
                                    <label for="maior" v-on:click="maior(true)">sim</label>
                                </div>
                                <div class="maior-menor">
                                    <input id="menor"
                                           ref="menor" type="radio" name="maio-menor" value="0">
                                    <label for="menor" v-on:click="maior(false)">não</label>
                                </div>
                            </div>

                            <div v-if="Habilitar_Age_Gate" class="fields">
                                <p>Data de nascimento</p>
                                <div>
                                    <input :disabled="maior_value==null" v-model="dd" v-mask="'###'" maxlength="3"
                                           size=2 ref="dd" type="text" placeholder="DD">
                                    <input :disabled="maior_value==null" v-model="mm" v-mask="'###'" maxlength="3"
                                           size=2 ref="mm" type="text" placeholder="MM">
                                    <input :disabled="maior_value==null" v-model="aaaa" v-mask="'####'" maxlength="4"
                                           size=4 ref="aaaa" type="text" placeholder="AAAA">
                                </div>
                            </div>
                            <div v-else class="fields">
                                <input v-model="dd" ref="dd" type="hidden">
                                <input v-model="mm" ref="mm" type="hidden">
                                <input v-model="aaaa" ref="aaaa" type="hidden">
                            </div>

                            <div class="col-12 alignVerticalBetween">
                                <input class="text" type="text" name="name" ref="name" placeholder="Nome completo"
                                       v-on:keyup="activeBtn">
                                <input class="text" type="text" name="nickname" ref="nickname" placeholder="Apelido"
                                       v-on:keyup="activeBtn">
                                <input class="text" type="password" name="password" ref="password" placeholder="Senha"
                                       v-on:keyup="activeBtn">
                                <input class="text" type="password" name="password_confirmation"
                                       ref="password_confirmation" placeholder="Repetir senha" v-on:keyup="activeBtn">
                            </div>
                            <div class="btncontainer">
                                <div v-if="this.login_social" class="flex flex-wrap col-12">
                                    <div class="col-12 flex">
                                        <div style="padding-top: 10px;">
                                            <input :disabled="maior_value==null" class="checkbox" id="autorizo"
                                                   name="accept_notifications" ref="autorizo" type="checkbox" value="1">
                                        </div>
                                        <div>
                                            <label for="autorizo" style="font-size: 12px;text-align: left">Autorizo o
                                                uso de minhas informações pessoais para que  anúncios de produtos e iniciativas.</label>
                                        </div>
                                    </div>
                                    <div class="col-12 flex">
                                        <div style="padding-top: 10px;">
                                            <input :disabled="maior_value==null" class="checkbox" id="terms"
                                                   name="terms" ref="terms" type="checkbox" value="1"
                                                   v-on:change="activeBtn()">
                                        </div>
                                        <div>
                                            <label for="terms" style="font-size: 12px;text-align: left;">
                                                Eu aceito os <a v-on:click="$app.modal_Termos=true">termos e condições</a>.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    :disabled="ActiveButton"
                                    ref="btn-submit" v-on:click="sendAge">{{ btn_submit_value }}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'Age',
    computed: {
        ...mapGetters([
            'logged', 'token', 'login_social', 'name', 'nickname'
        ]),
    },
    data() {
        return {
            btn_submit_value: 'ENTRAR',
            ActiveButton: false,
            dd: '',
            mm: '',
            aaaa: '',
            maior_value: null,
            Habilitar_Age_Gate: null
        }
    },
    components: {},
    methods: {
        ...mapActions([
            'update_age_verified', 'update_age'
        ]),
        block(v) {
            this.btn_submit_value = (v ? ("Aguarde...") : 'ENTRAR');
            this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
            this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
        },
        maior(m) {
            this.maior_value = m;
        },
        sendAge(e) {
            if (!this.submit) {
                this.block(true);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                let form_data = new FormData(this.$refs["age-form"]);
                let s = this.$refs['aaaa'].value + '-' + this.$refs['mm'].value + '-' + this.$refs['dd'].value;
                if(s != '--'){
                    form_data.append("birth_date", s);
                }
                axios.post(this.$url_api + 'api/user/changeBirth', form_data, {headers: headers}).then(response => this.ageResponse(response)).catch(error => this.ageError(error.response));
            }
            e.preventDefault();
        },
        ageResponse(obj) {
            if (obj.data.status == 'success') {
                
                this.$app.age_get_user = true;
                this.$app.getUser();
                this.update_age_verified(true);
                let date = new Date(this.aaaa, this.mm - 1, this.dd);
                let difference = Math.abs(new Date() - date);
                difference = new Date(difference).getFullYear() - 1970;
                // console.log('difference => ', difference);
                if (difference < 18) {
                    this.$app.analytics('agegate_under18');
                } else {
                    this.$app.analytics('agegate_over18');
                }
                this.update_age(difference);
                this.$app.updateUser('age_verified', true);
                this.$app.updateUser('birth_day',  this.$refs['aaaa'].value + '-' + this.$refs['mm'].value + '-' + this.$refs['dd'].value ?? '0000-00-00'); //"birth_date",
                // this.$app.modal('success', 'Sucesso!', obj.data.message, null, null, null, "OK");
                this.$app.link('Map');
            }
            this.block(false);
        },
        ageError(obj) {
            this.block(false);
            let str = '';
            for (let o in obj.data.errors) {
                str += obj.data.errors[o] + '\n';
            }
            this.$app.modal('error', 'Erro!', str, null, null, null, "OK");
        },
        activeBtn() {
            this.ActiveButton = true;
            if (
                // (this.dd.length == 2) &&
                // (this.mm.length == 2) &&
                // (this.aaaa.length == 4) &&
                // (this.aaaa.length == 4) &&
                (this.$refs['name'].value.length > 0) &&
                (this.$refs['nickname'].value.length > 0) &&
                (this.$refs['password'].value.length > 0) &&
                (this.$refs['password_confirmation'].value.length > 0)
            ) {
                if (this.login_social && !this.$refs['terms'].checked) return;
                this.ActiveButton = false;
            }
        },
        habilitarAgeGateLoad(){
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/setup', { headers:headers })
            .then(response => this.habilitarAgeGateLoadResponse(response))
            .catch(error => this.habilitarAgeGateLoadError(error.response));
        },
        habilitarAgeGateLoadResponse(obj){
            obj;
            
            this.Habilitar_Age_Gate = obj.data.Habilitar_Age_Gate;
            this.$app.wait(false);
        },
        habilitarAgeGateLoadError(obj){
            obj;
            // console.log('habilitarAgeGateLoadError -> ', obj);
            this.$app.wait(false);
        }
    },
    created() {

    },
    mounted() {
        /*if(this.login_social){
            this.$app.modal_Termos=true;
            this.$app.modal_PoliticaDePrivacidade = true;
        }*/
        this.activeBtn();
        window.scrollTo(0, 0);
        this.habilitarAgeGateLoad();
    },
    updated() {
        this.btn_submit_value = 'ENTRAR';
    },
    beforeDestroy() {

    },
    watch: {
        dd(to, from) {
            to, from;
            let number = Number(to);
            this.dd = number > 31 ? '31' : (number < 1 ? '' : (number < 10 ? `0${number}` : `${number}`));
            this.activeBtn();
        },
        mm(to, from) {
            to, from;
            let number = Number(to);
            this.mm = number > 12 ? '12' : (number < 1 ? '' : (number < 10 ? `0${number}` : `${number}`));
            this.activeBtn();
        },
        aaaa(to, from) {
            to, from;
            let number = Number(to);
            this.aaaa = number > 2020 ? '2020' : (number == 0 ? '' : `${number}`);
            this.activeBtn();
        },
    }
}
</script>

<style lang="scss" scoped>
#age {
    padding: 0 !important;

    .title {
        text-transform: uppercase;
        color: #333D47;
        position: relative;
        font-size: 40px;
        margin: 30px 120px;
        @media screen and (max-width: 700px) {
            padding-top: 60px !important;
        }
    }
}

.ageGate {
    position: relative;
    z-index: 1000 !important;

    .fields {
        text-align: center;
        padding-top: 10px;

        p {
            color: var(--buttons_color);
            font-weight: bold;
            font-size: 16px;
            text-transform: uppercase;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
                background-color: white !important;
                text-indent: -8px;
                font-size: 40px;
                width: 32%;
                color: var(--buttons_color);
                border: 2px solid rgba(0, 66, 139, 0.15);
                box-sizing: border-box;
                border-radius: 5px;
                text-align: center;
                display: inline-block;
                font-style: italic;
                font-family: 'Action NBA Cond Web light';
            }
        }
    }

    .btncontainer {
        text-align: center;
        margin-top: 30px;
        padding-bottom: 20px;

        p {
            margin-top: 25px;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 148.5%;
            text-align: center;

            color: #333D47;
        }

        a {
            color: var(--buttons_color);
            font-weight: bold;
        }
    }

    button {
        background: #D91A32;
        border-radius: 5px;
        padding: 12px 50px;
        margin-top: 5px;
        border: 0px;

        font-weight: bold;
        font-size: 16px;
        line-height: 148.5%;
        text-transform: uppercase;

        color: #FFFFFF;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}

.maior-menor input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.maior-menor label {
    cursor: pointer;
    display: inline-block;
    background-color: white;
    padding: 12px 50px;
    font-family: Roboto, serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    border: 2px solid #D91A32;
    border-radius: 4px;
    color: #D91A32;
}

.maior-menor input[type="radio"]:checked + label {
    background-color: #D91A32;
    color: white;
}

</style>
