<template>
    <div class="modalGerais" style="display: flex;">
        <div class="modals__content alignVertical">
            <div class="modals__content--header alignVertical">
                <div class="header-image">
                    <div>
                    </div>
                    <div>
                        <p>{{ this.$i18n.t('posts.menu') }}</p>
                    </div>
                    <div class="cursor-pointer close" style="align-self: center;" data-response="0" @click="$parent.currentScreen='home'; $parent.open(false);">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2848 1.42188L8.7067 7L14.2848 12.5781L12.8786 13.9844L7.30045 8.40625L1.72232 13.9844L0.316072 12.5781L5.8942 7L0.316072 1.42188L1.72232 0.015625L7.30045 5.59375L12.8786 0.015625L14.2848 1.42188Z" fill="white"/>
                            <path d="M14.2848 1.42188L14.9919 2.12898L15.699 1.42188L14.9919 0.714768L14.2848 1.42188ZM8.7067 7L7.99959 6.29289L7.29248 7L7.99959 7.70711L8.7067 7ZM14.2848 12.5781L14.9919 13.2852L15.699 12.5781L14.9919 11.871L14.2848 12.5781ZM12.8786 13.9844L12.1715 14.6915L12.8786 15.3986L13.5857 14.6915L12.8786 13.9844ZM7.30045 8.40625L8.00755 7.69914L7.30045 6.99204L6.59334 7.69914L7.30045 8.40625ZM1.72232 13.9844L1.01522 14.6915L1.72232 15.3986L2.42943 14.6915L1.72232 13.9844ZM0.316072 12.5781L-0.391034 11.871L-1.09814 12.5781L-0.391034 13.2852L0.316072 12.5781ZM5.8942 7L6.6013 7.70711L7.30841 7L6.6013 6.29289L5.8942 7ZM0.316072 1.42188L-0.391034 0.714768L-1.09814 1.42188L-0.391034 2.12898L0.316072 1.42188ZM1.72232 0.015625L2.42943 -0.691482L1.72232 -1.39859L1.01522 -0.691482L1.72232 0.015625ZM7.30045 5.59375L6.59334 6.30086L7.30045 7.00796L8.00755 6.30086L7.30045 5.59375ZM12.8786 0.015625L13.5857 -0.691482L12.8786 -1.39859L12.1715 -0.691482L12.8786 0.015625ZM13.5777 0.714768L7.99959 6.29289L9.4138 7.70711L14.9919 2.12898L13.5777 0.714768ZM7.99959 7.70711L13.5777 13.2852L14.9919 11.871L9.4138 6.29289L7.99959 7.70711ZM13.5777 11.871L12.1715 13.2773L13.5857 14.6915L14.9919 13.2852L13.5777 11.871ZM13.5857 13.2773L8.00755 7.69914L6.59334 9.11336L12.1715 14.6915L13.5857 13.2773ZM6.59334 7.69914L1.01522 13.2773L2.42943 14.6915L8.00755 9.11336L6.59334 7.69914ZM2.42943 13.2773L1.02318 11.871L-0.391034 13.2852L1.01522 14.6915L2.42943 13.2773ZM1.02318 13.2852L6.6013 7.70711L5.18709 6.29289L-0.391034 11.871L1.02318 13.2852ZM6.6013 6.29289L1.02318 0.714768L-0.391034 2.12898L5.18709 7.70711L6.6013 6.29289ZM1.02318 2.12898L2.42943 0.722732L1.01522 -0.691482L-0.391034 0.714768L1.02318 2.12898ZM1.01522 0.722732L6.59334 6.30086L8.00755 4.88664L2.42943 -0.691482L1.01522 0.722732ZM8.00755 6.30086L13.5857 0.722732L12.1715 -0.691482L6.59334 4.88664L8.00755 6.30086ZM12.1715 0.722732L13.5777 2.12898L14.9919 0.714768L13.5857 -0.691482L12.1715 0.722732Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="modals__content alignVertical">
                <div class="modals__content--body alignVertical">
                    <div class="modalGerais__semMoedas alignVertical">
                        <div class="alignVertical col-12" ref="text-info">
                            <div class="cards">
                                <div :class="{'card-disabled': !current_session.can_self, 'card-enabled': current_session.can_self, 'alignVertical': true}" :title="(current_session.can_self ? $i18n.t('posts.takeSelfie') : $i18n.t('posts.cantTakeSelfie'))" @click="checkCanOpen(current_session.can_self ,'newSelfie')">
                                    <svg width="50" height="50" viewBox="0 0 62 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.8591 41.9844C22.9424 44.9714 26.6039 46.4648 30.8435 46.4648C35.083 46.4648 38.6963 44.9714 41.6833 41.9844C44.7666 38.901 46.3083 35.2396 46.3083 31C46.3083 26.7604 44.7666 23.1471 41.6833 20.1602C38.6963 17.0768 35.083 15.5352 30.8435 15.5352C26.6039 15.5352 22.9424 17.0768 19.8591 20.1602C16.8721 23.1471 15.3786 26.7604 15.3786 31C15.3786 35.2396 16.8721 38.901 19.8591 41.9844ZM21.5935 0.214844H40.0935L45.7302 6.28516H55.5583C57.1963 6.28516 58.5935 6.91146 59.7497 8.16406C61.0023 9.41667 61.6286 10.862 61.6286 12.5V49.5C61.6286 51.138 61.0023 52.5833 59.7497 53.8359C58.5935 55.0885 57.1963 55.7148 55.5583 55.7148H6.12862C4.4906 55.7148 3.04528 55.0885 1.79268 53.8359C0.636429 52.5833 0.0583038 51.138 0.0583038 49.5V12.5C0.0583038 10.862 0.636429 9.41667 1.79268 8.16406C3.04528 6.91146 4.4906 6.28516 6.12862 6.28516H15.9567L21.5935 0.214844ZM23.906 37.9375C21.9789 36.0104 21.0153 33.6979 21.0153 31C21.0153 28.3021 21.9789 25.9896 23.906 24.0625C25.833 22.1354 28.1455 21.1719 30.8435 21.1719C33.5414 21.1719 35.8539 22.1354 37.781 24.0625C39.708 25.9896 40.6716 28.3021 40.6716 31C40.6716 33.6979 39.708 36.0104 37.781 37.9375C35.8539 39.8646 33.5414 40.8281 30.8435 40.8281C28.1455 40.8281 25.833 39.8646 23.906 37.9375Z" :fill="(current_session.can_self ? 'var(--fonts_color)' : '#E3E8E7')"/>
                                    </svg>
                                    <span class="col-12 alignVertical" style="margin-top: -40px;">{{ this.$i18n.t('posts.takeSelfie') }}</span>
                                    <div id="cantTakeSelfies">
                                        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.73829 9.51172V6.48828H8.26173V9.51172H9.73829ZM9.73829 12.5V10.9883H8.26173V12.5H9.73829ZM0.738289 14.75L9.00001 0.511719L17.2617 14.75H0.738289Z" fill="white"/>
                                        </svg>
                                        <span style="align-self: center;">
                                            {{ this.$i18n.t('posts.cantTakeSelfie') }}
                                        </span>
                                    </div>
                                </div>
                                <div :class="{'card-disabled': !$parent.can_open_self, 'card-enabled': $parent.can_open_self, 'alignVertical': true}" :title="($parent.can_open_self ? $i18n.t('posts.mySelfies') : $i18n.t('posts.mySelfiesNull'))" @click="checkCanOpen($parent.can_open_self, 'selfies')">
                                    <svg width="50" height="50" viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.714554 12.5H6.78487V55.7148H49.9997V61.7852H6.78487C5.14685 61.7852 3.70153 61.207 2.44893 60.0508C1.29268 58.7982 0.714554 57.3529 0.714554 55.7148V12.5ZM28.4646 31L19.2146 43.2852H56.2146L43.7849 27.9648L34.6794 39.3828L28.4646 31ZM62.2849 43.2852C62.2849 44.9232 61.6586 46.3685 60.406 47.6211C59.2497 48.8737 57.8526 49.5 56.2146 49.5H19.2146C17.5765 49.5 16.1312 48.8737 14.8786 47.6211C13.626 46.3685 12.9997 44.9232 12.9997 43.2852V6.28516C12.9997 4.64714 13.626 3.25 14.8786 2.09375C16.1312 0.841146 17.5765 0.214844 19.2146 0.214844H56.2146C57.8526 0.214844 59.2497 0.841146 60.406 2.09375C61.6586 3.25 62.2849 4.64714 62.2849 6.28516V43.2852Z" :fill="($parent.can_open_self ? 'var(--fonts_color)' : '#E3E8E7')"/>
                                    </svg>
                                    <span class="col-12 alignVertical" style="margin-top: -40px;">{{ this.$i18n.t('posts.mySelfies') }}</span>
                                    <div id="cantOpenSelfies">
                                        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.73829 9.51172V6.48828H8.26173V9.51172H9.73829ZM9.73829 12.5V10.9883H8.26173V12.5H9.73829ZM0.738289 14.75L9.00001 0.511719L17.2617 14.75H0.738289Z" fill="white"/>
                                        </svg>
                                        <span style="align-self: center;">
                                            {{ this.$i18n.t('posts.mySelfiesNull') }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="$parent.blogEnabled" class="card-enabled alignVertical" :title="$i18n.t('posts.blog')" @click="$parent.openInsideModal('blog')" >
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M31.3718 31.2148V18.7852H18.9421V31.2148H31.3718ZM49.8718 18.7852H37.4421V31.2148H49.8718V37.2852H37.4421V49.7148H31.3718V37.2852H18.9421V49.7148H12.8718V37.2852H0.442093V31.2148H12.8718V18.7852H0.442093V12.7148H12.8718V0.285156H18.9421V12.7148H31.3718V0.285156H37.4421V12.7148H49.8718V18.7852Z" fill="var(--fonts_color)"/>
                                    </svg>
                                    <span class="col-12 alignVertical" style="margin-top: -40px;">{{ this.$i18n.t('posts.blog') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
    name: 'MenuBlog',
    computed:{
        ...mapGetters([
            'logged', 'token', 'switch','event_id', 'current_session', 'checkIOS', 'checkMobile','use_map'
        ]),
    },
    data() {
        return {
        }
    },
    methods: {
        checkCanOpen(can, side)
        {
            if(can) {
                switch (side) {
                    case 'newSelfie':
                        this.print()
                        break;
                    default:
                        this.$parent.openInsideModal(side);
                        break;
                }
            } else {
                const takeSelfie = document.getElementById('cantTakeSelfies');
                const openSelfie = document.getElementById('cantOpenSelfies');
                takeSelfie.style.display = 'none';
                openSelfie.style.display = 'none';
                var ios = this.checkIOS;
                var android = this.checkMobile;
                if(ios || android) {
                    switch (side) {
                        case 'newSelfie':
                            takeSelfie.style.display = 'flex';
                            break;
                        default:
                            openSelfie.style.display = 'flex';
                            break;
                    }
                }
            }
        },
        // Functions for print
        print() {
            /*let canvas;
            let img;*/
            if(this.$route.name ==='Iframe' || (this.$route.name === 'Map' && this.use_map === 3)){
                if(this.current_session !== null && this.current_session.can_self){
                    this.$app.sendToUnity('TakeSnapshot');
                }
            }
        },

        async selfie(img_) {
        // selfie(img_) {
            this.$app.wait(true);
            const response = await axios.post(this.$url_api+'api/selfie', {
                selfie: img_
            },{
                headers:{'language': this.$i18n.locale, Authorization: `Bearer ${this.token}`},
            })
            if (response.data) {
                this.$parent.openInsideModal('selfies', true, response.data.selfie.id);
            }
        },
    },
    mounted() {

    }
}
</script>
<style scoped>
.modal_header {
    display: flex;
    justify-content: space-between;
    background-color: var(--headers_color_2nd);
    padding: 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.modals__content--header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    flex-direction: column;
}
.btn{
    padding: .7rem .5rem;
    border-radius: .5rem;
    font-weight: bold;
    font-size: .7rem;
    text-transform: initial;
}
.btn--primary{
    background-color: var(--buttons_color);
    color: var(--fonts_color);
}
.btn--secondary{
    background-color: var(--buttons_color_2nd);
    color: var(--fonts_color_2nd);
}
.modals__content--body {
    padding: 0;
    min-height: 230px!important;
    margin-bottom: 10px;
    position: relative;
}

@media screen and (max-width: 600px){
    .modalGerais{
        max-width:100% !important;
        width:100% !important;
        padding-top:var(--headers_height);
    }
    .modals__content {
        width:100%;
        margin-top:0px;
    }
}

.modalGerais__semMoedas {
    min-width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: unset;
}
.modals__content--header p{
    color: white;
}
.modalGerais__semMoedas--text{
    max-width: 60%;
    text-align: center;
}
.header-image{
    width: calc(100% - 2rem);
    display: flex;
    justify-content: space-between;
}

#cantTakeSelfies, #cantOpenSelfies {
    font-family: 'Roboto', sans-serif;
    background: #404040;
    color: white;
    padding: 7px;
    position: absolute;
    font-size: 17px;
    font-weight: 500;
    border-radius: 9px;
    bottom: 10%;
    display: none;
}

@media screen and (min-width: 1100px)
    {
        .modals__content {
            min-width: 800px;
        }
        .cards {
            display: flex;
            justify-items: center;
            justify-content: center;
            align-self: center;
        }
        .cards .card-disabled{
            cursor: not-allowed;
            display: flex;
            justify-items: center;
            justify-content: center;
            align-self: center;
            border: 2px solid #E3E8E7;
            border-radius: 10px;
            min-width: 200px;
            min-height: 150px;
            margin: 0 8px;
            font-weight: 700;
            font-size: 20px;
            color: #E3E8E7;
        }
        .cards .card-enabled{
            cursor: pointer;
            display: flex;
            justify-items: center;
            justify-content: center;
            align-self: center;
            border: 2px solid var(--buttons_color);
            background-color: var(--buttons_color);
            border-radius: 10px;
            min-width: 200px;
            min-height: 150px;
            margin: 0px 8px;
            font-weight: 700;
            font-size: 20px;
            color: var(--fonts_color);
        }
    }
    @media screen and (max-width: 1099px)
    {
        .modals__content {
            min-width: 0;
        }
        .cards {
            display: block;
            justify-items: center;
            justify-content: center;
            align-self: center;
        }
        .cards .card-disabled{
            display: flex;
            justify-items: center;
            justify-content: center;
            align-self: center;
            border: 2px solid #E3E8E7;
            border-radius: 10px;
            min-width: 150px;
            min-height: 150px;
            margin: 12px 0px;
            font-weight: 700;
            font-size: 20px;
            color: #E3E8E7;
            position: relative;
        }
        .cards .card-enabled{
            display: flex;
            justify-items: center;
            justify-content: center;
            align-self: center;
            background-color: var(--buttons_color);
            border: 2px solid var(--buttons_color);
            border-radius: 10px;
            min-width: 150px;
            min-height: 150px;
            margin: 12px 0px;
            font-weight: 700;
            font-size: 20px;
            color: var(--fonts_color);
        }
    }
</style>