<template>
    <div class="modals bgBlack" id="wizard" ref="wizard" v-on:click="$app.modal_Wizard=false" >
        <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0" v-on:click="$app.modal_Wizard=false"></div>
        <div class="wizard">
            <div class="modals__content">
                <div class="modals__content--body alignVertical">
                    <div class="alignVertical col-11" style="align-items: flex-start;">
                        <div class="col-10">

                            <div v-if="this.$route.name == 'Map'" class="flex flex-wrap col-12">
                                <p>
                                    <b>
                                        {{ $t('wizard.cliqueNosBales') }} 
                                    </b>
                                </p>
                                <p>{{ $t('wizard.noCabealhoVeja') }}</p>
                                <p>{{ $t('wizard.seLigueNas') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Account'" class="flex flex-wrap col-12">
                                <p>
                                    <b>
                                        {{ $t('wizard.gerencieSeuPerfil') }}
                                    </b>
                                </p>
                                <p>{{ $t('wizard.precisouDeAjuda') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Badges'" class="flex flex-wrap col-12">
                                <p>
                                    <b>
                                        {{ $t('wizard.confiraSeusBadges') }}
                                    </b>
                                </p>
                                <p>{{ $t('wizard.badgeVideo') }}</p>
                            </div>


                            <div v-else-if="this.$route.name == 'Profile'" class="flex flex-wrap col-12">
                                <p>
                                    <b>
                                        {{ $t('wizard.confiraSeusDados') }}
                                    </b>
                                </p>
                                <p>{{ $t('wizard.aoClicarNo') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'TicketPurchased'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.vejaAquiTodos') }}</p>
                                <p>{{ $t('wizard.paraMaioresDe') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'VipRoom'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.gerencieSuaSala') }}</p>
                                <p><b>{{ $t('wizard.atenoEspaoExclusivo') }}</b></p>
                            </div>

                            <div v-else-if="this.$route.name == 'Support'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.tireSuasDvidas') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Friends'" class="flex flex-wrap col-12">
                                <p><b>{{ $t('wizard.procureSeusAmigos') }}</b></p>
                                <p>{{ $t('wizard.seusPedidosDe') }}</p>
                                <p>{{ $t('wizard.aquiVoceTambem') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'FriendsInvited'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.copieOCdigo') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'City'" class="flex flex-wrap col-12">
                                <p><b>{{ $t('wizard.customizeCorpoRosto') }}</b></p>
                                <p>{{ $t('wizard.algunsItensS') }}</p>
                                <p>{{ $t('wizard.noEsqueaDe') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Ranking'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.vejaSuaPosio') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Ticket'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.confiraAProgramao') }}</p>
                                <p><b>{{ $t('wizard.atenoSalasVip') }}</b></p>
                            </div>

                            <div v-else-if="this.$route.name == 'Partners'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.visiteAsPginas') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Iframe' && this.$route.params == 'auditorio'" class="flex flex-wrap col-12">
                                    <p>
                                        {{ $t('wizard.noComputador') }}
                                    </p>
                                    <p>{{ $t('wizard.paraGirarACamera') }}</p>
                                    <p>{{ $t('wizard.comOCelularClique') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Iframe' && this.current_session && this.current_session.template_type == 'whiteboard'" class="flex flex-wrap col-12">
                                <p>
                                    <b>{{ $t('wizard.whiteboard') }}</b>
                                </p>
                                <p>{{ $t('wizard.whiteboardTxt') }}</p>
                            </div>

                            <div v-else-if="this.$route.path == '/e/Auditorio'" class="flex flex-wrap col-12">
                                <p>
                                    <b>{{ $t('wizard.assistaAoEvento') }}</b>
                                </p>
                                <p>{{ $t('wizard.utilizeOsControles') }}</p>
                                <p>{{ $t('wizard.useOMouseDoPC') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Iframe'" class="flex flex-wrap col-12">
                                <div v-if="!this.current_session.isForm && !this.current_session.isLive">
                                    <p>
                                        {{ $t('wizard.noComputador') }}
                                    </p>
                                    <p>{{ $t('wizard.paraGirarACamera') }}</p>
                                    <p>{{ $t('wizard.comOCelularClique') }}</p>
                                </div>
                                <p v-else-if="this.current_session.isLive">
                                    {{ $t('wizard.inLive') }}
                                </p>
                                <p v-else-if="this.current_session.isForm">
                                    {{ $t('signup.aguarde') }}
                                </p>
                            </div>

                            <div v-else-if="this.$route.name == 'FanZone'" class="flex flex-wrap col-12">
                                <p><b>{{ $t('wizard.tudoSobreO') }}</b></p>
                                <p>{{ $t('wizard.cliqueNoMenu') }}</p>
                                <p>{{ $t('wizard.paraNavegarUtilize') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'PlayZone'" class="flex flex-wrap col-12">
                                <p><b>{{ $t('wizard.encontreOsCones') }}</b></p>
                            </div>

                            <div v-else-if="this.$route.name == 'FeelZone'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.cliqueNoMenu') }}</p>
                                <p>{{ $t('wizard.paraNavegarUtilize') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Store'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.cliqueNosContainers') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'Museum'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.naveguePelosHot') }}</p>
                            </div>

                            <div v-else-if="this.$route.name == 'LockerRoom'" class="flex flex-wrap col-12">
                                <p>{{ $t('wizard.exploreOAmbiente') }}</p>
                                <p>{{ $t('wizard.exploreOAmbiente') }}</p>
                            </div>

                            <div v-else class="flex flex-wrap col-12">
                            </div>


                        </div>
                        <div class="col-2">
                            <a v-on:click="$app.modal_Wizard=false;" class="close alignVertical">
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5716 1.42859L1.42871 13.5714M1.42871 1.42859L13.5716 13.5714" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    import { mapGetters } from "vuex";
    
    export default {
        name: 'Wizard',
        computed: {
            ...mapGetters([
                'logged', 'token', 'current_session', 'switch'
            ]),
        },
        data() {
            return {

            }
        },
        components: {
            
        },
        methods: {
            currentSessionSend() {
                console.log("Session =>")
                console.log(this.current_session.isLive)
            },
            // openPolitica(){
            //     if (this.$route.name !="WizardPage") {
            //         this.$app.modal_PoliticaDePrivacidade = true;
            //         this.$app.modal_Wizard = false;
            //     }else
            //         this.$app.link("PoliticaPage")
            // },
        },
        created() {

        },
        mounted() {
           //this.$app.analytics('visit_terms', {});
        },
        beforeDestroy() {
        },
    }
</script>
<style scoped lang="scss">
#wizard{
    z-index: 10000 !important;
    background-color: transparent;
    .modals__content{
        background-color: rgba(0,0,0,0.5);
        border-radius: 20px;
        backdrop-filter: blur(5px);
        padding: 20px;
        color: #fff;
        .modals__content--header{
            background-color: transparent;
            p{
                font-size: 2rem;
                font-weight: 700;
                line-height: 1.5;
                color: #fff;
                letter-spacing: 0.5px;
            }
        }
        .modals__content--body{
            background-color: transparent;
            .modal-container{
                p{
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #fff;
                    letter-spacing: 0.5px;
                }
                img,video,iframe{
                    min-height: 400px;
                }
            }
            .modal-footer{
                display: flex;
                justify-content: center;
                margin-top: 20px;
                button{
                    margin: 0 10px;
                }
            }
        }
    }
}
</style>