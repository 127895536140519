<template>

    <div class="modals bgAzul" id="modal-package-single">
        <div v-on:click="closeModal"></div>
        <div class="package">
            <div class="modals__content">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('package_buy.title') }}</p>
                    <a v-on:click="closeModal" ref="btn-close">
                        <img src="assets/icons/modals/x-black.svg" alt="">
                    </a>
                </div>

                <div v-if="$app.package_doesnt_exist" class="item-wrapper package-non">
                    <p>{{ $t('package.nonExist') }}</p>
                    <button type="button" v-on:click="closeModal" >{{ $t('package.back') }}</button>
                </div>

                <form v-else class="modals__content--body flex flex-wrap" id="package-form">
                    <div class="item-wrapper">
                        <img :src="$url_api + 'storage/package/' + $app.package_single.image" alt="Thumb" style="width:100px;" />
                        <p class="item-name">{{ $app.package_single.name }}</p>
                        <p class="item-coin">{{ $app.package_single.amount }} {{ $t('package.coins') }}</p>
                        <p class="item-price">
                            <span v-if="$app.package_single.promotional">{{ $t('package.in') }}</span>
                            R$ {{ maskMoney($app.package_single.value) }}
                            <span v-if="$app.package_single.promotional">
                                {{ $t('package.per') }} 
                                {{ maskMoney($app.package_single.promotional_value) }}
                            </span>
                        </p>
                        <button type="button" 
                            :data-id="$app.package_single.id"
                            :data-name="$app.package_single.name"
                            :data-image="$app.package_single.image"
                            :data-type="$app.package_single.type"
                            :data-amount="$app.package_single.amount"
                            :data-value="$app.package_single.promotional ? $app.package_single.promotional_value : $app.package_single.value" v-on:click="buy">{{
                                $t('package.buy')
                            }}
                        </button>
                    </div>
                </form>

                <div class="modals__content--footer flex">
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";
// import Swiper from "swiper/bundle";
export default {
    name: 'PackageSingle',
    data() {
        return {
            // packages: null,
            // package_view: false,
            // started_component: false,
            // swiper_package: null
        }
    },
    computed: {
        ...mapGetters(['token']),
    },
    components: {},
    methods:
    {
        ...mapActions(['update_package']),
        just_number(v) {
            return String(v).replace(/\D/g, '');
        },
        closeModal(e) {
            e.preventDefault();
            // console.log(this.$app.modal_coins_buy);
            this.$app.modal_package = false;
            this.$app.modal_coins_buy = false;

            this.$app.modal_package_single = false;
            this.$app.package_doesnt_exist = false;
        },
        maskMoney(value) {
            let v = this.just_number(value);
            v = (v / 100).toFixed(2);
            v = v.replace(".", ",");
            v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
            v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
            return v;
        },
        buy(e) {
            e.preventDefault();
            this.$app.wait(true);
            let package_ =
            {
                id: e.currentTarget.dataset.id,
                name: e.currentTarget.dataset.name,
                image: e.currentTarget.dataset.image,
                type: e.currentTarget.dataset.type,
                amount: e.currentTarget.dataset.amount,
                value: e.currentTarget.dataset.value
            };
            this.update_package(package_);
            this.$app.modal_package = false;
            this.$app.modal_package_buy = true;
            this.$app.modal_coins_buy = false;
            this.$app.modal_package_single = false;
            this.$app.package_doesnt_exist = false;
        },
        buy_old(e) {
            e.preventDefault();

            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
            let form_data = new FormData();
            form_data.append("package_id", e.currentTarget.dataset.id);
            form_data.append("value", e.currentTarget.dataset.value);
            form_data.append("type", '1');//1 = cartão de crédito, 2 = pix
            form_data.append("payment_type", '0');//0 = normal, 1 = recurrent
            headers;
            axios.post(this.$url_api + "api/package/buy", form_data, { headers: headers }).then((response) => this.buyResponse(response)).catch((error) => this.buyError(error.response));
        },
        buyResponse(obj) {
            this.$app.wait(false);
            console.log('buy response => ', obj);
            if (obj.data == 1) {
                alert('Compra realizada com sucesso.');
            }
        },
        buyError(obj) {
            this.$app.wait(false);
            console.log('buyError => ', obj);
        },
    },
    mounted() {
    }
}
</script>

<style scoped>
    #modal-package-single .package{
        box-shadow: 0px 10px 60px rgb(0, 0, 0);
        border-radius: 30px;
        width: auto;
    }
    #modal-package-single .item-wrapper{
        width: 230px;
        box-sizing: border-box;
        padding: 15px;
        border-radius: 30px;
        border: 2px solid var(--buttons_color);
        text-align: center;
        margin: 0 40px;
    }
    #modal-package-single .item-name {
        font-size: 20px;
        font-weight: 900;
    }
    #modal-package-single  .item-coin,
    #modal-package-single  .item-price {
        font-size: 15px;
        font-weight: 600;
    }
    #modal-package-single button {
        background-color: var(--buttons_color);
        color: #fff;
        border: none;
        padding: 10px 20px 10px 20px;
        border-radius: 20px;
        font-weight: 800;
    }

    #modal-package-single .item-wrapper.package-non{
        border: none;
        width: auto;
        max-width: 300px;
    }
    #modal-package-single .item-wrapper.package-non p{
        font-size: 15px;
        line-height: 20px;
        margin-top: 0;
    }


    @media screen and (max-width: 500px) {
        #modal-package-single .package{
            width: 95%;
        }
        #modal-package-single .item-wrapper{
            margin: 0 auto;
        }
    }
</style>