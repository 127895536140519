import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
 
Vue.use(MLInstaller)
 
export default new MLCreate({
    initial: 'portuguese',
    save: process.env.NODE_ENV === 'production',
    languages: [

        new MLanguage('portuguese').create({
            home: {
                nivel_atual: 'Nível atual',
                ola: 'Olá',
                meus_pontos: 'Meus pontos',
                info_points: 'Faltam <b>{score_needed_to_reach} pontos</b> para atingir nível <b>{level_next}</b>',
                provador: 'Provador',
                minhas_compras: 'Minhas compras',
                entrar: 'Entrar',
                cadastrar: 'Cadastrar',
                sair: 'Sair',
                menu_pano_inicio:'Início',
                menu_pano_1:'Moda Feminina',
                menu_pano_2:'Moda Masculina',
                menu_pano_3:'Calçados',
            },
            login: {
                title: 'Entrar',
                email: 'E-mail',
                password: 'Senha',
                send: 'Entrar',
                wait: 'Aguarde',
                register: 'Cadastre-se',
                close: 'Fechar',
                form_0: 'O campo "E-mail" deve ser um endereço de e-mail válido.',
                form_1: 'Mínimo de 3 letras para sua senha.',
                login_error: 'Não foi possível logar com esse e-mail e senha.'
            },
            register: {
                title: 'Cadastro',
                first_name: 'Nome',
                last_name: 'Sobrenome',
                email: 'E-mail',
                password: 'Senha',
                send: 'Cadastrar',
                wait: 'Aguarde',
                close: 'Fechar',
                form_0: 'O primeiro nome é obrigatório. Mínimo de 2 letras para seu primeiro nome.',
                form_1: 'O último nome é obrigatório. Mínimo de 3 letras para seu último nome.',
                form_2: 'O campo "E-mail" deve ser um endereço de e-mail válido.',
                form_3: 'Mínimo de 3 letras para sua senha.'
            },
            add_success: {
                success: 'O produto foi adicionado.'
            },
            cart: {
                title: 'Carrinho',
                meus_itens: 'Meus itens',
                nenhum_produto: 'Nenhum produto no seu carrinho.',
                carregando_itens: 'Carregando itens do carrinho...',
                excluir_item: 'Excluir esse item da minha lista',
                descartar: 'Descartar modificações no carrinho',
                confirmar: 'Confirmar modificações no carrinho',
                tipo_entrega: 'Tipo de entrega:',
                em_casa: 'Em casa',
                retirada_loja: 'Retirada na loja',
                pickup_in_locker: 'Retirar em Locker',
                escolha_cidade: 'Escolha a loja de sua preferência, por favor',
                cancel: 'Cancelar',
                choice: 'Selecionar',


                alert_locker: 'Retirar em Locker não está disponível para sua região.',
                alert1_stock: 'Estoque insuficiente.\nA loja mais próxima(',
                alert2_stock: ') tem uma quantidade total de ',
                alert3_stock: ' itens do produto "',
                alert_points: 'Você não tem pontos suficientes ou não pode incluir mais pontos que o total da compra.',
                alert1_points: 'Você não tem pontos suficientes para pagar essa compra.',
                alert_error_cart_clear: 'Ocorreu algum erro ao limpar o carrinho de compras.',
                alert_value_pay: 'O valor a ser pago deve ser maior que zero.',
                alert_error_buy:'A compra não pode ser efetuada.',
                alert_info_error_discount_points: 'A compra foi efetuada, mas o desconto dos pontos não foi efetivado.\nErro:\n',


                pagamento: 'Pagamento:',
                cartao_credito: 'Cartão',
                utilizar_pontos: 'Utilizar pontos',
                total_compra: 'Total da compra:',
                total_compra_desconto: 'Total da compra (com desconto):',
                subtotal_compra: 'Total dos produtos:',
                utilizacao_de_pontos: 'Utilização de pontos:',
                total_pagar: 'Total a pagar:',
                esvaziar_carrinho: 'Esvaziar carrinho',
                pagar: 'Pagar',
                info_no_points_0: 'Você não tem pontos suficientes ou não pode incluir mais pontos que o total da compra.',
                info_no_points_1: 'Você não tem pontos suficientes para pagar essa compra.',
                info_no_points_2: 'O valor a ser pago deve ser maior que zero.',
                error_0: 'Ocorreu algum erro ao limpar o carrinho de compras.',
                error_1: 'A compra não pode ser efetuada.'
            },
            compras: {
                title: 'Minhas compras',
                loading: 'Carregando lista de compras efetuadas...',
                nenhuma_compra: 'Você ainda não realizou nenhuma compra.',
                order_id: 'ID da ordem: ',
                total: 'Total: ',
                status: 'Status: ',
                close: 'Fechar'
            },
            produto: {
                carregando: 'Carregando informações do produto...',
                quantidade: 'Quantidade',
                cancelar: 'Cancelar',
                add: 'Adicionar',
                wait: 'Aguarde',
                provador_virtual: 'Provador virtual',
                ver_em_3d: 'Ver em 3D',
            },
            sucesso: {
                title: 'Sucesso',
                success: 'Sua compra foi realizada com sucesso.',
                back: 'Voltar as compras'
            },
            lightbox: {
                title_0: 'Produto 3D',
                title_1: 'Provador virtual'
            }
        }),

        new MLanguage('english').create({
            home: {
                nivel_atual: 'Current level',
                ola: 'Hello',
                meus_pontos: 'My points',
                info_points: '{score_needed_to_reach} points left to reach <b>{level_next}</b> level',
                provador: 'Fitting Room',
                minhas_compras: 'My orders',
                entrar: 'Login',
                cadastrar: 'Register',
                sair: 'Exit',
                menu_pano_inicio:'Start',
                menu_pano_1:'Women\'s fashion',
                menu_pano_2:'Men\'s fashion',
                menu_pano_3:'Shoes',
            },
            login: {
                title: 'Login',
                email: 'Email',
                password: 'Password',
                send: 'Send',
                wait: 'Please wait',
                register: 'Register',
                close: 'Close',
                form_0: 'The "Email" field must be a valid email address.',
                form_1: 'Enter at least 3 letters for your password',
                login_error: 'Unable to login with this email and password'
            },
            register: {
                title: 'Register',
                first_name: 'First name',
                last_name: 'Last name',
                email: 'Email',
                password: 'Password',
                send: 'Send',
                wait: 'Wait',
                close: 'Close',
                form_0: 'The first name is required. Minimum of 2 letters for your first name.',
                form_1: 'The last name is mandatory. Minimum of 3 letters for your last name.',
                form_2: 'The "Email" field must be a valid email address.',
                form_3: 'Minimum of 3 letters for your password.'
            },
            add_success: {
                success: 'Product added to your shopping cart.'
            },
            cart: {
                title: 'Cart',
                meus_itens: 'My items',
                nenhum_produto: 'You have no products in your cart yet',
                carregando_itens: 'Loading cart items...',
                excluir_item: 'Delete this item from my list',
                descartar: 'Discard cart changes',
                confirmar: 'Confirm cart changes',
                tipo_entrega: 'Delivery Type:',
                em_casa: 'At home',
                retirada_loja: 'Pick up in Store',
                pickup_in_locker: 'Pick up in Locker',
                escolha_cidade: 'Choose your preferred store, please.',
                cancel: 'Cancel',
                choice: 'Choose',

                alert_locker: 'Withdraw to Locker is not available for your region.',
                alert1_stock: 'Insufficient stock.\nThe nearest store(',
                alert2_stock: ') has a total amount of ',
                alert3_stock: ' product items "',
                alert_points: 'You don\'t have enough points or you can\'t add more points than your purchase total',
                alert1_points: 'You do not have enough points to pay for this purchase.',
                alert_error_cart_clear: 'There was an error clearing the shopping cart.',
                alert_value_pay: 'The amount to be paid must be greater than zero.',
                alert_error_buy:'The purchase cannot be made.',
                alert_info_error_discount_points: 'The purchase was made, but the points discount was not effective.\nError:\n',


                pagamento: 'Payment:',
                cartao_credito: 'Credicard',
                utilizar_pontos: 'Use points',
                total_compra: 'Total purchase:',
                total_compra_desconto: 'Total purchase (with discount):',
                subtotal_compra: 'Total products:',
                utilizacao_de_pontos: 'Using Points:',
                total_pagar: 'Total payable:',
                esvaziar_carrinho: 'Empty cart',
                pagar: 'Pay',
                info_no_points_0: 'You do not have enough points or cannot include more points than the total purchase.',
                info_no_points_1: 'You do not have enough points to pay for this purchase.',
                info_no_points_2: 'The amount to be paid must be greater than zero.',
                error_0: 'There was an error while cleaning the shopping cart.',
                error_1: 'Purchase cannot be made.'
            },
            compras: {
                title: 'My orders',
                loading: 'Loading your shopping list...',
                nenhuma_compra: 'You haven\'t made any purchases yet.',
                order_id: 'Order ID: ',
                total: 'Total: ',
                status: 'Status: ',
                close: 'Close'
            },
            produto: {
                carregando: 'Loading product information...',
                quantidade: 'Quantity',
                cancelar: 'Cancel',
                add: 'Add',
                wait: 'Please wait',
                provador_virtual: 'Fitting room',
                ver_em_3d: 'See in 3d',
            },
            sucesso: {
                title: 'Success',
                success: 'Your purchase was successful.',
                back: 'Back to shopping'
            },
            lightbox: {
                title_0: '3D product',
                title_1: 'Fitting room'
            },

            title: 'Oi {0}!',
            msg: 'Você tem {f} amigos e {l} curtidas'
        }),

        new MLanguage('spanish').create({
            home: {
                nivel_atual: 'Nivel actual',
                ola: 'Hola',
                meus_pontos: 'Mis puntos',
                info_points: 'Quedan <b>{score_needed_to_reach} puntos</b> para alcanzar el nivel <b>{level_next}</b>',
                provador: 'Probador',
                minhas_compras: 'Mis ordenes',
                entrar: 'Iniciar sesión',
                cadastrar: 'Registrarse',
                sair: 'cerrar sesión',
                menu_pano_inicio:'Comienzo',
                menu_pano_1:'Moda Feminina',
                menu_pano_2:'Moda Masculina',
                menu_pano_3:'Zapatos',
            },
            login: {
                title: 'Iniciar sesión',
                email: 'Correo electrónico',
                password: 'Contraseña',
                send: 'Iniciar sesión',
                wait: 'Espere...',
                register: 'Registrarse',
                close: 'Cerrar',
                form_0: 'El campo "Correo electrónico" debe ser una dirección de correo electrónico válida.',
                form_1: 'Ingrese al menos 3 letras para su contraseña',
                login_error: 'No se puede iniciar sesión con este correo electrónico y contraseña'
            },
            register: {
                title: 'Registrarse',
                first_name: 'Nombre ',
                last_name: 'Apellido',
                email: 'Correo electrónico',
                password: 'Contraseña',
                send: 'Registrarse',
                wait: 'Espere',
                close: 'Cerrar',
                form_0: 'Se requiere el primer nombre. Mínimo de 2 letras para su nombre.',
                form_1: 'El apellido es obligatorio. Mínimo de 3 letras para su apellido.',
                form_2: 'El campo "Correo electrónico" debe ser una dirección de correo electrónico válida.',
                form_3: 'Mínimo de 3 letras para su contraseña.'
            },
            add_success: {
                success: 'Producto añadido a su carrito.'
            },
            cart: {
                title: 'Carrito',
                meus_itens: 'Mis productos',
                nenhum_produto: 'No tienes productos en tu carrito.',
                carregando_itens: 'Carga de productos desde el carrito...',
                excluir_item: 'Eliminar este producto de mi lista',
                descartar: 'Descartar cambios/modificaciones en el carrito',
                confirmar: 'Confirmar cambios en el carrito',
                tipo_entrega: 'Tipo de Entrega:',
                em_casa: 'En casa',
                retirada_loja: 'Recoger en tienda',
                pickup_in_locker: 'Recoger en Locker',
                escolha_cidade: 'Elija su tienda preferida, por favor.',
                cancel: 'Cancelar',
                choice: 'Seleccione',

                alert_locker: 'Retirar a Locker no está disponible para su región.',
                alert1_stock: 'Stock insuficiente.\nLa tienda más cercana(',
                alert2_stock: ') tiene una cantidad total de',
                alert3_stock: ' artículos del producto "',
                alert_points: 'No tiene suficientes puntos o no puede agregar más puntos que el total de su compra.',
                alert1_points: 'No tienes suficientes puntos para pagar esta compra.',
                alert_error_cart_clear: 'Hubo un error al vaciar el carrito de compras.',
                alert_value_pay: 'El monto a pagar debe ser mayor a cero.',
                alert_error_buy:'No se puede realizar la compra.',
                alert_info_error_discount_points: 'Se realizó la compra, pero el descuento de puntos no fue efectivo.\nError:\n',



                pagamento: 'Pago:',
                cartao_credito: 'Tarjeta de credito',
                utilizar_pontos: 'Usar puntos',
                total_compra: 'Compra total (con descuento):',
                subtotal_compra: 'Produtos total:',
                utilizacao_de_pontos: 'Usar puntos/Cangear puntos:',
                total_pagar: 'Total a pagar:',
                esvaziar_carrinho: 'Vaciar carrito',
                pagar: 'Pagar',
                info_no_points_0: 'No tienes suficientes puntos o no puedes incluir más puntos que la compra total.',
                info_no_points_1: 'No tienes suficientes puntos para pagar esta compra.',
                info_no_points_2: 'El monto a pagar debe ser mayor a cero.',
                error_0: 'Hubo un error al limpiar el carrito de compras..',
                error_1: 'No se puede realizar la compra.'
            },
            compras: {
                title: 'Mis ordenes',
                loading: 'Cargando su lista de compras...',
                nenhuma_compra: 'Aún no has realizado ninguna compra.',
                order_id: 'Número de pedido: ',
                total: 'Total: ',
                status: 'Status: ',
                close: 'Cerrar'
            },
            produto: {
                carregando: 'Cargando información de producto...',
                quantidade: 'Cantidad',
                cancelar: 'Cancelar',
                add: 'Añadir',
                wait: 'Espere',
                provador_virtual: 'Probador virtual',
                ver_em_3d: 'Ver en 3D',
            },
            sucesso: {
                title: 'Pago Exitoso',
                success: 'Tu compra fue exitosa.',
                back: 'Volver a las compras'
            },
            lightbox: {
                title_0: 'Producto 3D',
                title_1: 'Probador virtual'
            }
        })
    ]
})