<template>
    <div v-if="!check_log && !logged" id="login" ref="login">
        <form class="box" id="login-form" method="post" ref="login-form" v-on:submit="login">
            <h3 v-text="$ml.get('login.title')"></h3>
            <ul>
                <li>
                    <label for="email" v-text="$ml.get('login.email')"></label>
                    <input id="email" name="email" :placeholder="$ml.get('login.email')" ref="email" required type="email">
                </li>
                <li>
                    <label for="password" v-text="$ml.get('login.password')"></label>
                    <input autocomplete="off" id="password" name="password" :placeholder="$ml.get('login.password')" ref="password" required type="password">
                </li>
                <li>
                    <div v-if="error" id="error">{{ error }}</div>
                    <input ref="btn-submit" type="submit" :value="btn_submit_value">
                </li>
                <li>
                    <!--<router-link class="btn-1" to="/cadastro" ref="btn-close" v-text="$ml.get('login.register')"></router-link>-->
                    <a class="btn-0" href="#" v-on:click="$parent.login = false;$parent.signup = true;" v-text="$ml.get('login.register')"></a>
                </li>
                <li>
                    <!--<router-link class="btn-0" to="/" ref="btn-close" v-text="$ml.get('login.close')"></router-link>-->
                    <a class="btn-0" href="#" v-on:click="$parent.login = false" v-text="$ml.get('login.close')"></a>
                </li>
            </ul>
        </form>
    </div>
</template>
<script>
    import axios from 'axios';
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "Entrar",
        computed: {
            ...mapGetters([
                'token', 'logged', 'check_log'
            ])
        },
        data() {
            return {
                btn_submit      : false,
                btn_submit_value: this.$ml.get('login.send'),
                error           : null
            }
        },
        methods: {
            ...mapActions([
                'update', 'update_profile'
            ]),
            block(v) {
                this.btn_submit_value = (v ? (this.$ml.get('login.wait') + "...") : this.$ml.get('login.send'));
                this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
                this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
            },
            login(e) {
                //this.$parent.wait(true);
                this.error = null;
                if (!/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9.])+\.([A-Za-z]{2,})$/.test(this.$refs["email"].value)) {
                    this.error = this.$ml.get('login.form_0');
                } else if (this.$refs["password"].value.length < 3) {
                   this.error = this.$ml.get('login.form_1');
                }
                if (this.error) {
                    e.preventDefault();
                } else {
                    this.sendLogin(e);
                }
            },
            sendLogin(e) {
                if (!this.submit) {
                    this.block(true);

                    
                    //let headers = {'Content-Type': 'application/json;charset=UTF-8',"Access-Control-Allow-Origin": "*",'Access': 'application/json', };
                    let form_data = new FormData(this.$refs["login-form"]);
                    axios.post(this.$url_api + 'store/oracle/login', form_data).then(response => this.loginResponse(response));
                    

                    //
                    //
                    //
                    /*
                    $headers = array("Content-Type: application/x-www-form-urlencoded");
                    $p = http_build_query(array(
                        'token_type' => 'bearer',
                        'grant_type' => 'password',
                        'username'   => $email,
                        'password'   => $password
                    ));
                    */

                    /*
                    console.log('novo');
                    let myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                    let form_data = new FormData();
                    form_data.append("token_type", 'bearer');
                    form_data.append("grant_type", 'password');
                    form_data.append("username", this.$refs['email'].value);
                    form_data.append("password", this.$refs['password'].value);
                    let data = { method: 'POST', headers : myHeaders, body: form_data, redirect: 'follow' };
                    fetch('https://ademosrv0033c1dev-store.demoservices011.commece.oraclepdemos.com/ccstoreui/v1/login', data).then(r => r.json()).then(r => console.log("Response => ", r)).catch(e => console.log("Error => ", e));
                    */
                }
                e.preventDefault();
            },
            loginResponse(obj) {
                if (obj.data.access_token) {
                    this.update(obj.data.access_token);
                    this.update_profile({ id: obj.data.id, email: obj.data.email, first_name: obj.data.firstName, last_name: obj.data.lastName });
                    this.$parent.user_info = obj.data.firstName + " " + obj.data.lastName;
                    this.$parent.getCart();
                    this.$parent.getPoints();
                    //this.$router.push('/');
                    this.$parent.login = false;
                } else if (obj.data.error) {
                    this.error = obj.data.error;
                }
                else if ((obj.data === "") || (obj.data === null) || (obj.data === "null")) {
                     this.error = this.$ml.get('login.login_error');
                }
                this.block(false);
            }
        },
        mounted() {
            this.$refs['email'].focus()
        },
        updated() {
            this.btn_submit_value = this.$ml.get('login.send');
        }
    }
</script>

<style scoped>
    #login
    {
        background-color: rgba(0, 0, 0, .6);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }

    form
    {
        box-sizing: border-box;
        display: block;
        margin: 150px auto;
        max-width: 400px;
        padding: 30px;
    }
    form ul li
    {
        box-sizing: border-box;
        padding: 10px 0;
    }

    #error
    {
        box-sizing: border-box;
        color: red;
        display: block;
        padding: 10px;
        text-align: center;
    }

    @media screen and (max-width: 500px)
    {
        form
        {
            width: calc(100% - 40px);
        }
    }

</style>