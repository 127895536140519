export default {
    returnFiles(state) {
        return state.files;
    },
    returnPages(state) {
        return state.pagination;
    },
    returnSelectedFiles(state) {
        return state.selectedFiles;
    }
}