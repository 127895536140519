export default {
    userInformation: {
        friend_relation: {
            isFriend: false,
            isRequested: false,
            isRequester: false,
            isBlocker: false,
            isBlocked: false,
        },
        profile: {
            about_you: '',
            company: '',
            cpf: '',
            email: '',
            id: '',
            linkedin: '',
            name: '',
            nationality: '',
            nickname: '',
            phone: '',
            profile_photo_url: '',
            state: ''
        },
        friends_suggestions: [],
        courses: [],
        certificates: [],
    },
}