<template>
  <div class="col-12 mb-3">
    <div class="course-card card text-center">
      <div class="card-body infos d-flex align-items-center flex-row p-0 my-4" style="margin-left: 29px;">
        <div class="d-flex align-items-center">
          <img :src="showingSearch.avatar" class="img-fluid" style="height: 93px; width: 93px; border-radius: 50%; object-fit: contain; object-position: center;">
        </div>
        <div class="d-flex align-items-start flex-column" style="margin-left: 30px;">
          <span class="title" @click="goToUserPage(showingSearch.id)"  style="cursor: pointer;">{{ showingSearch.name }}</span>
          <span class="request-message" v-if="showingSearch.friend">Você e <span class="nickname">@{{ showingSearch.nickname }}</span> são amigos.</span>
          <span class="request-message" v-else-if="!showingSearch.requested && showingSearch.request && !showingSearch.friend"><span class="nickname">@{{ showingSearch.nickname }}</span> enviou um pedido de amizade</span>
          <span class="request-message" v-else-if="showingSearch.requested && !showingSearch.friend && showingSearch.request">Você já enviou um pedido de amizade para <span class="nickname">@{{ showingSearch.nickname }}</span></span>
          <span class="request-message" v-else-if="!showingSearch.requested && !showingSearch.friend && !showingSearch.request">Você e <span class="nickname">@{{ showingSearch.nickname }}</span> ainda não são amigos.</span>
          <div class="d-flex" v-if="!showingSearch.requested && showingSearch.request && !showingSearch.friend">
              <button class="accept" @click="handleAction('accept', showingSearch.id)">Aceitar</button>
              <button class="reject" @click="handleAction('reject', showingSearch.id)">Remover</button>
          </div>
          <div class="d-flex" v-else-if="showingSearch.friend">
              <button class="accept" @click="handleMessage(showingSearch.id)">Enviar mensagem</button>
          </div>
          <div class="d-flex" v-else-if="showingSearch.requested && !showingSearch.friend && !showingSearch.request">
              <button class="reject" style="margin-left: 0 !important;" @click="handleAction('cancel', showingSearch.id)">Cancelar solicitação</button>
          </div>
          <div class="d-flex" v-else-if="!showingSearch.requested && !showingSearch.friend && !showingSearch.request">
              <button class="accept" @click="handleAction('send', showingSearch.id)">Enviar solicitação de amizade</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "SearchCard",
  props: ['search', 'friendAction', 'wait', 'privateMessage', 'goToUserPage'],
  
  watch:{

  },
  data() {
    return {
      showingSearch: null,
    }
  },
  beforeMount(){
    let root_ = this;

    root_.showingSearch = root_.search;
  },
  mounted(){

  },
  methods: {
    handleMessage(id) {
      this.privateMessage(id);
    },
    handleAction(action, id) {
      this.friendAction(action, id);
    },
  }
}
</script>

<style lang="scss">

.course-card {
  height: 100%;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 0 10px 5px #706F6F30;
  }

  &.card {
    border-radius: 20px;
    border: 2px solid #F6F6F6;
    background: #FFF;
    transition: all .3s ease;
  }


  .card-desc {
    font-family: Hanken Grotesk;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #706F6F;
  }

  .card-body {

    .title {
      font-family: Hanken Grotesk;
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      color: var(--buttons_color);
      margin-bottom: 8px;
    }

    .request-message{
      font-family: Hanken Grotesk;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #706F6F;
      margin-bottom: 8px;

      .nickname {
        font-weight: 700;
      }
    }

    .accept {
      height: 38px;
      padding: 10px 28px 10px 28px;
      border-radius: 20px;
      font-family: Hanken Grotesk;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      border: none;
      background-color: var(--buttons_color);
      color: #FFF;

      &:hover {
        color: #FFF;
        background-color: #5037da;
      }
    }

    .reject {
      margin-left: 10PX;
      font-family: Hanken Grotesk;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      border: none;
      background-color: transparent;
      color: #B1B1B1;

      &:hover {
        color: #706F6F;
      }
    }

    .material-symbols-outlined {
      font-family: "Material Symbols Outlined";
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0em;
      margin-right: 4px;
      color: #B1B1B1;
      display: flex;
      justify-content: flex-end;
      margin-right: 7px;

      &:hover {
        color: var(--buttons_color);
        cursor: pointer;
      }
    }

    .material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
    }

    .card-action {

      .material-symbols-outlined {
        font-family: "Material Symbols Outlined";
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        margin-right: 4px;
        color: var(--buttons_color);
      }

      .material-symbols-outlined {
      font-variation-settings:
          'FILL' 0,
          'wght' 400,
          'GRAD' 0,
          'opsz' 48
      }
      
      .action-text {
        font-family: Hanken Grotesk;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }

      &:hover {
        cursor: pointer;

        .material-symbols-outlined {
          font-weight: 700;
        }
        .action-text {
          font-weight: 700;
        }
      }
    }
    .content-progress {
      background-color: #F6F6F6;
      height: 100%;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      .content-completed {
        //margin-left: -55px;
        width: 50%;
        color: #706F6F;
        font-size: 16px;
        font-family: "Hanken Grotesk";
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-left: -8rem;
      }

    }

  }


  .bi {
    font-size: 2rem;
  }

  .checklist {
    color: #706F6F;

  }

  .notebook {
    color: #706F6F;

  }

  .infos {
    span {
      color: #706F6F;
      text-align: center;
      font-size: 14px;
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

  }

  .apexcharts-canvas {
    right: 4rem;
  }

  @media(min-width: 768px){
    .card-body {
      .content-progress {
        .content-completed {
          font-size: 12px;
        }
      }
    }
  }
  @media(min-width: 1024px){
    .card-body {
      .content-progress {
        .content-completed {
          font-size: 16px;
        }
      }
    }
  }

}
</style>