<template>
    <LmsBasicTemplate :current-page="navigationConfig.pageName" pageSubTitle="Download de certificados" :searchField="false">
        <template v-slot:navigation-side>
            <ProfileNavigation :ismob="true"></ProfileNavigation>
            <div class="dropdown">
                <span class="btn btn-secondary w-100 p-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleMenu">
                    {{ selectedCategory || 'Escolha uma categoria' }}
                    <i v-if="dropdownMenuToggle" class="bi bi-chevron-down float-right"></i>
                    <i v-if="!dropdownMenuToggle" class="bi bi-chevron-up float-right"></i>
                </span>
        
                <ul class="dropdown-menu desk w-100" aria-labelledby="dropdownMenuLink" data-bs-target="#dropdownMenuLink"
                    aria-hidden="true">
                    <li><a class="dropdown-item" href="#" @click.prevent="selectCategory('')">Escolha uma categoria</a></li>
                    <li v-for="category in categories" :key="category.id">
                        <a class="dropdown-item" href="#" @click.prevent="selectCategory(category)">
                        {{ category.title }}
                        </a>
                    </li>
                </ul>
            </div>
            <ProfileNavigation :ismob="false"></ProfileNavigation>

            <section class="mt-3">
                <div class="menu-buttons mt-3 d-flex flex-column gap-3">
                    <button v-for="(button, idx) in navigationConfig.navigation" :key="idx" type="button" class="btn py-3" @click.prevent="goTo(button.slug)">
                        <i v-if="button.icon" :class="button.icon"></i>
                        {{ button.name }}
                    </button>
                </div>
            </section>
        </template>
        <template v-slot:content-side>
            <h2 class="explore">Download de certificados</h2>
            <div class="dropdown-wrapper">
                <span class="btn btn-secondary w-100 p-3" type="button" id="dropdownMenuLink2" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleMenu">
                    {{ selectedCategory || 'Escolha uma categoria' }}
                    <i v-if="dropdownMenuToggle" class="bi bi-chevron-down float-right"></i>
                    <i v-if="!dropdownMenuToggle" class="bi bi-chevron-up float-right"></i>
                </span>
                <ul class="dropdown-menu mob w-100" aria-labelledby="dropdownMenuLink2" data-bs-target="#dropdownMenuLink2"
                    aria-hidden="true">
                    <li><a class="dropdown-item" href="#" @click.prevent="selectCategory('')">Escolha uma categoria</a></li>
                    <li v-for="category in categories" :key="category.id">
                        <a class="dropdown-item" href="#" @click.prevent="selectCategory(category)">
                        {{ category.title }}
                        </a>
                    </li>
                </ul>
            </div>
            
            <div class="certificates-container">
                <div class="course" v-for="(course, index) in coursesToShow" :key="index">
                    <span class="title-course">{{ course.title }}</span>
                    <div class="action">
                        <div v-show="course.is_finished">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#b1b1b1" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
                            {{   course.finished_date }}
                        </div>
                        <button class="download-btn" v-show="course.certificate_id && course.is_finished" @click="downloadCertificate(course.id)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="var(--buttons_color)" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                            Download
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </LmsBasicTemplate>
</template>
<script>
    import LmsBasicTemplate from "./LmsBasicTemplate.vue";
    import {mapGetters} from "vuex";
    import ProfileNavigation from "@/components/lms/components/ProfileNavigation.vue";
    import CustomPagination from "@/components/lms/components/LmsHomeComponents/CustomPagination.vue";
    export default {
        name: 'LmsCertificates',
        data() {
            return {
                dropdownMenuToggle: true,
                categories: [],
                selectedCategoryId: 0,
                selectedCategory: null,
                navigationConfig: [],
                coursesToShow: [],
                paginationOptions: {
                    paginationKey: 0,
                    show: true,
                    total: 10,
                    page: 1,
                    per_page: 10,
                    template: CustomPagination
                },

            }
        },
        components: {
            LmsBasicTemplate,
            ProfileNavigation
        },
        computed: {
            ...mapGetters({
                getLms: "LMS/getLms",
                getPageByName: "LMS/getPageByName"
            }),
        },
        beforeMount() {
            let root_ = this;
            root_.navigationConfig = root_.getPageByName("LmsCertificates");
        },
        mounted() {
            let _root = this
            _root.getUserCertificates(1)
        },
        methods: {
            toggleMenu() {
                this.dropdownMenuToggle = !this.dropdownMenuToggle;
            },
            selectCategory(category) {
                let root_ = this;
                root_.selectedCategory = category.title;
                root_.selectedCategoryId = category.id;
                root_.getUserCertificates(1)
            },
            getUserCertificates(page) {
                let root_ = this;

                let payload = {
                    "slug": root_.$route.params.lmsSlug,
                    "language": root_.$i18n.locale,
                    "page": page,
                    "category_id" : root_.selectedCategoryId ?? 0
                };
                root_.$parent.wait(true)

                root_.$store.dispatch("LMS/getUserCertificates", payload)
                    .then(res => {
                        root_.allCourses = Object.values(res.courses.data);
                        root_.coursesToShow = Object.values(res.courses.data);
                        root_.categories = res.categories;

                        root_.paginationOptions.current_page = res.courses.current_page;
                        root_.paginationOptions.total = res.courses.total;
                        root_.paginationOptions.per_page = res.courses.per_page;
                        root_.paginationOptions.page = res.courses.current_page;

                        root_.paginationOptions.show = root_.paginationOptions.total > root_.paginationOptions.per_page;
                        
                        root_.$parent.wait(false)
                    });

            },
            downloadCertificate(courseId) {
                let root_ = this
                root_.$store.dispatch('LMS/downloadCertificate', {course_id: courseId}).then((res) => {
                    window.open(res.data.data.certificate.url, '_blank')
                })
            },
            goTo(slug){
                console.log("goTo: ", slug);
                if(slug !== "") {
                    this.$router.push({ name: slug})
                }
            }
        }

    }
</script>
<style lang="scss" scoped>
.content-side {
    height: 100vh;
}

.mycommunities {
    color: #706F6F;
    font-size: 16px;
    font-family: "Hanken Grotesk", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.menu-buttons {  
    .btn {
        color: #706F6F;
        background-color: #F6F6F6;
        font-size: 18px;
        font-family: "Hanken Grotesk", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        .bi {
            color: var(--buttons_color);
        }
    }
}
.certificates-container {

    // padding-left: 36px;

    .course {
        padding: 15px 0px;
        border-bottom: 1px solid #E1E1E1;
        color: #706F6F;
        font-weight: 700;
        size: 18px;
        font-family: Hanken Grotesk;
        display: flex;
        justify-content: space-between;
        .action {
            width: 35%;
            display: flex;
            justify-content: space-between;
            .download-btn {
                border: none;
                background-color: transparent;
                color: var(--buttons_color);
                cursor: pointer;
            }
            div {
                display: flex;
                align-items: center;
                width: 35%;
                color: #b1b1b1;
                font-weight: 400;
                justify-content: space-between;
            }
        }
    }
    // margin-left: 50px;
}
.dropdown-wrapper{
    position: relative;
    // margin-bottom: 30px
}
.dropdown-menu.mob,
#dropdownMenuLink2{
    display: none;
}
.explore {
    color: #706F6F;
    font-family: "Space Grotesk", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    display: none;
    margin: 30px 0 20px;
    font-size: 24px;
}

@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
    .dropdown-menu.mob.show,
    #dropdownMenuLink2{
        display: block;
    }
    .dropdown-menu.desk,
    #dropdownMenuLink{
        display: none;
    }
    .explore {
        display: block;
    }
    .certificates-container{
        padding: 0;
    }
    .certificates-container .course{
        display: inline-block;
        width: 100%;
    }
    .title-course{
        width:100%;
        display: inline-block;
        margin-bottom: 5px;
    }
    .certificates-container .course .action{
        width:100%;
    }
    .certificates-container .course .action div{
        width:auto;
    }
}
@media only screen and (max-width: 480px) {
  
}
</style>