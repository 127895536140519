export default {
    opened_transcription(state) {
        return state.opened_transcription;
    },
    messages_chat(state) {
        return state.messages_chat;
    },
    loader(state) {
        return state.loader;
    }
};